import cn from "classnames";
import { Text as TextPrimitive } from "react-aria-components";

import { Typography } from "@/components/Typography/Typography";

import styles from "./FormHelperText.module.scss";

export type FormHelperTextProps = React.HTMLAttributes<HTMLSpanElement> & {
  error?: boolean;
};

export const FormHelperText: React.FC<FormHelperTextProps> = ({ children, error, ...props }) => {
  return (
    <TextPrimitive
      slot={error ? "errorMessage" : "description"}
      className={cn(styles.formHelperText, { [styles.error]: error })}
      {...props}
    >
      <Typography variant="b2">{children}</Typography>
    </TextPrimitive>
  );
};

FormHelperText.displayName = "FormHelperText";

import * as React from "react";
import { TextField } from "../TextField/TextField";
import { phoneNumberPattern } from "../../../utilities/validations";

interface MobileNumberProps {
    value: string;
    isError: boolean;
    errorText: string;
    instructions: string;
}

type Reducer = (a: MobileNumberProps, b: Partial<MobileNumberProps>) => MobileNumberProps;
const reducer: Reducer = (a, b) => ({ ...a, ...b });

type errorTypes = "This field is required" | "Please enter a valid phone number";

interface InputFields {
    value: string;
}

interface Props {
    setPhoneInput: (a: InputFields) => void;
    mobileNumber?: string;
    className?: string;
    externalError?: string;
    disabled?: boolean;
}

const numberCheck = RegExp(/^[0-9]*$/);
const validAustralianNumberDigits = 10;

const MobileNumberFieldInternal: React.FC<Props> = ({
    setPhoneInput,
    mobileNumber,
    externalError,
    className,
    disabled,
}) => {
    const mobileNumberInitialProps = {
        value: mobileNumber || "",
        isError: false,
        errorText: "",
        instructions: "Only Australian numbers are accepted",
    };

    const [inputProps, setInputProps] = React.useReducer(reducer, mobileNumberInitialProps);

    const setError = (type: errorTypes) =>
        setInputProps({
            isError: true,
            errorText: type,
        });

    React.useEffect(() => {
        if (externalError) {
            setInputProps({
                isError: true,
                errorText: externalError,
            });
        }
    }, [externalError]);

    const isValidPhoneNumber = (number: string) => phoneNumberPattern.test(number);

    const changeHandler = (number: string) => {
        const numberOfDigits = number.length;
        if ((numberCheck.test(number) || !number) && numberOfDigits <= validAustralianNumberDigits) {
            setInputProps({ value: number });
        } else {
            return;
        }

        if (number && phoneNumberPattern.test(number)) {
            setInputProps({ isError: false });
            setPhoneInput({ value: number });
        } else {
            setInputProps({ isError: false });
            setPhoneInput({ value: "" });
        }
    };

    const blurHandler = () => {
        if (!inputProps.value) {
            setError("This field is required");
            setPhoneInput({ value: "" });
        } else if (mobileNumber === inputProps.value) {
            setInputProps({ isError: false });
            setPhoneInput({ value: inputProps.value });
        } else if (!isValidPhoneNumber(inputProps.value)) {
            setError("Please enter a valid phone number");
            setPhoneInput({ value: "" });
        } else if (inputProps.value) {
            setInputProps({ isError: false });
            setPhoneInput({ value: inputProps.value });
        }
    };

    const focusHandler = () => {
        setInputProps({ isError: false });
        if (!inputProps.value) {
            setPhoneInput({ value: "" });
        }
    };

    return (
        <TextField
            {...inputProps}
            label="mobile phone*"
            type="tel"
            onChange={changeHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
            className={className}
            disabled={disabled}
        />
    );
};

const MobileNumberField = React.memo(MobileNumberFieldInternal);

export { MobileNumberField };

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGExpand: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7.43899C2 7.99099 1.553 8.43899 1 8.43899C0.447 8.43899 0 7.99099 0 7.43899V1.01799C0 0.465988 0.447 0.0179885 1 0.0179885H1.405C1.465 0.0129885 1.523 0.0119885 1.583 0.0179885H8C8.553 0.0179885 9 0.465988 9 1.01799C9 1.56999 8.553 2.01799 8 2.01799H3.861L12.847 11.305C13.231 11.702 13.22 12.335 12.824 12.719C12.63 12.907 12.38 13 12.129 13C11.867 13 11.606 12.898 11.41 12.695L2 2.97099V7.43899ZM31 0.0179885C31.553 0.0179885 32 0.465988 32 1.01799V7.43899C32 7.99099 31.553 8.43899 31 8.43899C30.447 8.43899 30 7.99099 30 7.43899V2.97099L20.59 12.695C20.393 12.898 20.133 13 19.871 13C19.62 13 19.37 12.907 19.175 12.719C18.779 12.335 18.768 11.702 19.152 11.305L28.139 2.01799H24C23.447 2.01799 23 1.56999 23 1.01799C23 0.465988 23.447 0.0179885 24 0.0179885H30.417C30.478 0.0119885 30.536 0.0129885 30.597 0.0179885H31ZM23 30.982C23 31.534 23.447 31.982 24 31.982H30.427C30.4403 31.9833 30.4531 31.987 30.4661 31.9907C30.4824 31.9953 30.4987 32 30.516 32C30.5332 32 30.5501 31.9954 30.567 31.9908L30.567 31.9908C30.5806 31.987 30.5942 31.9833 30.608 31.982H31C31.553 31.982 32 31.534 32 30.982V24.561C32 24.009 31.553 23.561 31 23.561C30.447 23.561 30 24.009 30 24.561V29.029L20.59 19.305C20.206 18.909 19.573 18.898 19.176 19.282C18.78 19.666 18.769 20.299 19.153 20.696L28.14 29.983H24C23.447 29.982 23 30.43 23 30.982ZM1.392 31.982H1C0.447 31.982 0 31.535 0 30.982V24.561C0 24.009 0.447 23.561 1 23.561C1.553 23.561 2 24.009 2 24.561V29.029L11.411 19.304C11.795 18.908 12.429 18.897 12.825 19.281C13.221 19.665 13.232 20.298 12.848 20.695L3.861 29.982H8C8.553 29.982 9 30.43 9 30.982C9 31.534 8.553 31.982 8 31.982H1.572C1.5586 31.9833 1.5456 31.9871 1.53264 31.9908C1.51658 31.9954 1.5006 32 1.484 32C1.46681 32 1.44993 31.9954 1.43302 31.9908C1.41943 31.987 1.40581 31.9833 1.392 31.982Z"
            fill={color}
        />
    </svg>

    /* eslint-enable max-len */
);
SVGExpand = React.memo(SVGExpand);

export { SVGExpand };

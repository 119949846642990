export enum AuthenticationErrorType {
    PhoneNotUnique = "PhoneNotUnique",
    PhoneMissing = "PhoneMissing",
    PhoneInvalid = "PhoneInvalid",
    PasswordInvalid = "PasswordInvalid",
    NewPasswordInvalid = "NewPasswordInvalid",
    PasswordIsTheSame = "PasswordIsTheSame",
    PasswordNotMatchingPolicy = "PasswordNotMatchingPolicy",
    UserNotFound = "UserNotFound",
    UserExists = "UserExists",
    OtpCodeRequired = "OtpCodeRequired",
    OtpInvalid = "OtpInvalid",
    EmailNotUnique = "EmailNotUnique",
    EmailInvalid = "EmailInvalid",
    TnCsInvalid = "TnCsInvalid",
    InvalidInput = "InvalidInput",
    AccountLocked = "AccountLocked",
    SessionTimeout = "SessionTimeout",
}

export class AuthenticationError extends Error {
    private _types: Set<AuthenticationErrorType>;
    private _authId?: string;

    public constructor(types: Set<AuthenticationErrorType>, authId?: string) {
        super(Array.from(types).join(" \n"));
        this._types = types;
        this._authId = authId;

        // Set the prototype explicitly.
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, AuthenticationError.prototype);
    }

    public hasType(type: AuthenticationErrorType) {
        return this._types.has(type);
    }

    public hasAnyType(types: AuthenticationErrorType[]) {
        return types.some((type) => this._types.has(type));
    }

    public get types() {
        return this._types;
    }

    public get authId() {
        return this._authId;
    }
}

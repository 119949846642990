import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import jump from "jump.js";
import debounce from "lodash/debounce";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Responsive } from "@/components/Responsive/Responsive";
import { ScrollIndicator } from "@/components/ScrollIndicator/ScrollIndicator";
import { useScrollbarWidthOfElement } from "@/hooks/useScrollbarWidthOfElement";
import type { ImageData } from "@/types/graphqlResponse";
import { ValuesOf } from "@/types/helpers";
import { AuthenticationContext } from "@/utils/Authentication/authenticationContext";
import { SilentAuthentication } from "@/utils/Authentication/SilentAuthentication";

import { menuItemDataToMenuItemTyped } from "./common/mappers";
import { PrimaryNavModalDealer } from "./modals/PrimaryNavModalDealer";
import { PrimaryNavModalEncore } from "./modals/PrimaryNavModalEncore";
import { PrimaryNavModalGeneric } from "./modals/PrimaryNavModalGeneric";
import { PrimaryNavModalMobileMenu } from "./modals/PrimaryNavModalMobileMenu";
import { PrimaryNavModalModels } from "./modals/PrimaryNavModalModels";
import styles from "./PrimaryNav.module.scss";
import { PrimaryNavCloseButton } from "./PrimaryNavCloseButton";
import { PrimaryNavNavbar } from "./PrimaryNavNavbar";
import { PrimaryNavNavbarItemAuthenticated } from "./PrimaryNavNavbarItemAuthenticated";
import { PrimaryNavNavbarItemSpecial } from "./PrimaryNavNavbarItemSpecial";
import { PrimaryNavNavbarLevel1Items } from "./PrimaryNavNavbarLevel1Items/PrimaryNavNavbarLevel1Items";
import { SVGLogo } from "./svg/SVGLogo";
import { SVGPrimaryNavMenu } from "./svg/SVGPrimaryNavMenu";
import type {
  Level1MenuItemData,
  MainMenuItem,
  MenuItemTyped,
  MenuItemWithChildren,
  MenuItemWithIconData,
  ModalData,
  ModalGenericData,
  PrimaryNavData,
  StaticLinksItem,
  ModalMobileMenuData,
} from "./types/types";
import { MainMenuType, MenuItemType, ModalDataType } from "./types/types";

const MODAL_TRANSITION_DURATION_MS = 300;
const MODAL_CONTENT_TRANSITION_DURATION_MS = 300;
const MODAL_SCROLL_OFFSET = 10;

const MODAL_OPEN_CLOSE_CSS_CLASSNAMES = {
  enter: styles.modalOpenCloseEnter,
  enterActive: styles.modalOpenCloseEnterActive,
  enterDone: styles.modalOpenCloseEnterDone,
  exit: styles.modalOpenCloseExit,
  exitActive: styles.modalOpenCloseExitActive,
  exitDone: styles.modalOpenCloseExitDone,
  appear: styles.modalOpenCloseEnterDone,
  appearActive: styles.modalOpenCloseEnterDone,
  appearDone: styles.modalOpenCloseEnterDone,
};

const MODAL_CONTENT_SWITCH_CSS_CLASSNAMES = {
  enter: styles.modalContentSwitchEnter,
  enterActive: styles.modalContentSwitchEnterActive,
  enterDone: styles.modalContentSwitchEnterDone,
  exit: styles.modalContentSwitchExit,
  exitActive: styles.modalContentSwitchExitActive,
  exitDone: styles.modalContentSwitchExitDone,
  appear: styles.modalContentSwitchEnterDone,
  appearActive: styles.modalContentSwitchEnterDone,
  appearDone: styles.modalContentSwitchEnterDone,
};

const MODAL_CONTENT_SWITCH_BACK_CSS_CLASSNAMES = {
  enter: styles.modalContentSwitchBackEnter,
  enterActive: styles.modalContentSwitchBackEnterActive,
  enterDone: styles.modalContentSwitchBackEnterDone,
  exit: styles.modalContentSwitchBackExit,
  exitActive: styles.modalContentSwitchBackExitActive,
  exitDone: styles.modalContentSwitchBackExitDone,
  appear: styles.modalContentSwitchBackEnterDone,
  appearActive: styles.modalContentSwitchBackEnterDone,
  appearDone: styles.modalContentSwitchBackEnterDone,
};

export type PrimaryNavProps = {
  initialOpenModalId?: string;
  mainMenuLogoHref?: string;
  overrideDataMainMenuLogoMobile?: ImageData;
  overrideDataMainMenuLogo?: ImageData;
  data: PrimaryNavData;
};

const ModalContent: React.FC<{
  modalData: ModalData | undefined;
  primaryNavData: PrimaryNavData;
  pushActiveModalById: (modalId: string) => void;
  popActiveModal: () => void;
  clearActiveModalAndHistory?: () => void;
  closeButton: React.ReactNode;
  activeModalId: string | undefined;
  staticLinks: MenuItemWithIconData[];
  modalContentScrollContainerRef: React.RefObject<HTMLDivElement>;
}> = ({
  modalData,
  primaryNavData,
  pushActiveModalById,
  popActiveModal,
  clearActiveModalAndHistory,
  closeButton,
  activeModalId,
  staticLinks,
  modalContentScrollContainerRef,
}) => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const hasVerticalScrollBar = useCallback(
    (modalContentRef: React.RefObject<HTMLDivElement>) =>
      modalContentRef.current
        ? modalContentRef.current.scrollHeight >
          modalContentRef.current.clientHeight +
            modalContentRef.current.scrollTop +
            MODAL_SCROLL_OFFSET
        : false,
    []
  );

  useEffect(() => {
    if (activeModalId === "models") {
      setShowScrollIndicator(hasVerticalScrollBar(modalContentScrollContainerRef));

      const handleScroll = debounce(() => {
        setShowScrollIndicator(hasVerticalScrollBar(modalContentScrollContainerRef));
      }, 100);

      window.addEventListener("scroll", handleScroll, true);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setShowScrollIndicator(false);
    }
  }, [modalData, hasVerticalScrollBar, activeModalId, modalContentScrollContainerRef]);

  let modalContent;

  switch (modalData?.type) {
    case ModalDataType.MOBILE_MENU:
      const level1MenuItemsExceptEncore = getLevel1MenuItemsExceptEncore(primaryNavData);
      modalContent = (
        <PrimaryNavModalMobileMenu
          closeButton={closeButton}
          level1MenuItems={level1MenuItemsExceptEncore}
          staticLinks={staticLinks}
          pushActiveModalById={pushActiveModalById}
        />
      );
      break;
    case ModalDataType.GENERIC:
      modalContent = (
        <PrimaryNavModalGeneric
          closeButton={closeButton}
          backLinkText={modalData.mobileLabel || modalData.label}
          level2MenuItems={modalData.menuItems}
          staticLinks={staticLinks}
          pushActiveModalById={pushActiveModalById}
          popActiveModal={popActiveModal}
        />
      );
      break;
    case ModalDataType.MODELS:
      modalContent = (
        <>
          {activeModalId === "models" && <ScrollIndicator isVisible={showScrollIndicator} />}
          <PrimaryNavModalModels
            closeButton={closeButton}
            bodyTypes={modalData.availableBodyTypes}
            staticLinks={staticLinks}
            backLinkText={modalData.mobileLabel || modalData.label}
            popActiveModal={popActiveModal}
          />
        </>
      );
      break;
    case ModalDataType.DEALER:
      modalContent = (
        <PrimaryNavModalDealer
          closeButton={closeButton}
          menuItems={modalData.menuItems.map(menuItemDataToMenuItemTyped)}
          staticLinks={staticLinks}
        />
      );
      break;
    case ModalDataType.ENCORE:
      modalContent = (
        <PrimaryNavModalEncore
          closeButton={closeButton}
          onLogout={clearActiveModalAndHistory}
          logoutLabel={modalData.logoutLabel}
          encoreAdvert={modalData.encoreAdvert}
          menuItems={modalData.menuItems.map(menuItemDataToMenuItemTyped)}
        />
      );
      break;
    default:
  }
  return (
    <div id={`modal_content_${modalData?.modalId || ""} `} className={styles.modalContent}>
      {modalContent}
    </div>
  );
};

const getMainMenuData = (data: PrimaryNavData) => {
  return data.menuDetails.find((r) => r.menuType === MainMenuType.MAINMENU) as MainMenuItem;
};

const getStaticLinks = (data: PrimaryNavData, usedByEncore = false) => {
  return (
    data.menuDetails.find(
      (r) => r.menuType === MainMenuType.STATICLINKS && r.usedByEncore === usedByEncore
    ) as StaticLinksItem
  ).staticLinks;
};

const getModalData = (data: ModalData[], modalId?: string) => {
  return data.find((modalData) => modalData.modalId === modalId);
};

const getMenuItemsByType = (data: PrimaryNavData, type: ValuesOf<typeof ModalDataType>) => {
  return data.level1MenuItems.filter(
    (i) => i.modalId && getModalData(getMainMenuData(data).modalData, i.modalId)?.type === type
  );
};

const getEncoreItems = (data: PrimaryNavData) => {
  return getMenuItemsByType(data, ModalDataType.ENCORE);
};

const getLevel1MenuItemsExceptEncore = (data: PrimaryNavData) => {
  return data.level1MenuItems.filter((i) => getEncoreItems(data).indexOf(i) === -1);
};

const constructMobileMenuItem = (mainMenuData: MainMenuItem): Level1MenuItemData => {
  const label = mainMenuData.menuTextMobile || "Menu";

  return {
    label: label,
    modalId: "mobile-menu",
  };
};

const constructMobileMenuModal = (mainMenuData: MainMenuItem): ModalMobileMenuData => {
  const label = mainMenuData.menuTextMobile || "Menu";

  return {
    type: ModalDataType.MOBILE_MENU,
    modalId: "mobile-menu",
    label: label,
  };
};

const extractTertiaryNavAsMobileModals = (mainMenuModalData: ModalData[]): ModalGenericData[] => {
  const genericModals = mainMenuModalData.filter(
    (modalData) => modalData.type === ModalDataType.GENERIC
  ) as ModalGenericData[];

  const allSecondaryNavItems = genericModals.reduce(
    (acc, modalData) => [...acc, ...modalData.menuItems],
    [] as MenuItemTyped[]
  );

  const allSecondaryNavItemsWithChildren = allSecondaryNavItems.filter(
    (menuItem) => menuItem.type === MenuItemType.WITHCHILDREN
  ) as MenuItemWithChildren[];

  return allSecondaryNavItemsWithChildren.map((secondaryNavItem) => ({
    type: ModalDataType.GENERIC,
    modalId: secondaryNavItem.name,
    label: secondaryNavItem.name,
    menuItems: (secondaryNavItem.menuItems || []).map(menuItemDataToMenuItemTyped),
  }));
};

const PrimaryNav: React.FC<PrimaryNavProps> = ({
  initialOpenModalId,
  overrideDataMainMenuLogoMobile,
  overrideDataMainMenuLogo,
  mainMenuLogoHref,
  data,
}) => {
  const modalHistory = useRef<string[]>([]);
  const [isPushing, setIsPushing] = useState<boolean>(true);
  const [activeModalId, setActiveModalId] = useState<string | undefined>(initialOpenModalId);
  const [lastNonNullActiveModalId, setLastNonNullActiveModalId] = useState<string | undefined>(
    initialOpenModalId
  );
  const [allModalContentsRemountKey, setAllModalContentsRemountKey] = useState<number>(0);
  const { current } = useContext(AuthenticationContext);
  const isAuthenticated = !!current.user;

  const mobileMenuItem = constructMobileMenuItem(getMainMenuData(data));
  const encoreMenuItems = getEncoreItems(data);
  const encoreMenuItem = encoreMenuItems.length ? encoreMenuItems[0] : undefined;
  const level1MenuItemsExceptEncore = getLevel1MenuItemsExceptEncore(data);
  const activeLevel1MenuItem = data.level1MenuItems.find((item) => item.modalId === activeModalId);
  const activeLevel1MenuItemExceptEncore = level1MenuItemsExceptEncore.find(
    (item) => item.modalId === activeModalId
  );

  const primaryNavRootRef = useRef<HTMLDivElement>(null);

  const getModalOpenScrollToTarget = useCallback(
    () =>
      primaryNavRootRef.current?.closest("[data-primary-nav-modal-open-scrollto-target]") ??
      primaryNavRootRef.current,
    []
  );

  const setActiveModalByIdEx = useCallback(
    (modalId: string | undefined) => {
      setActiveModalId(modalId);
      if (modalId) {
        setLastNonNullActiveModalId(modalId);
        if (!activeModalId) {
          setAllModalContentsRemountKey((prevAllModalContentsRemountKey) => {
            return prevAllModalContentsRemountKey + 1;
          });
        }
        const modalOpenScrollToTarget = getModalOpenScrollToTarget();
        if (modalOpenScrollToTarget) {
          jump(modalOpenScrollToTarget, {
            duration: 300,
          });
        }
      }
    },
    [activeModalId, getModalOpenScrollToTarget]
  );

  const clearActiveModalAndHistory = useCallback(() => {
    modalHistory.current = [];
    setActiveModalByIdEx(undefined);
  }, [setActiveModalByIdEx]);

  const popActiveModal = useCallback(() => {
    modalHistory.current.pop();
    const newActiveModal = modalHistory.current.length
      ? modalHistory.current[modalHistory.current.length - 1]
      : undefined;
    setIsPushing(false);
    setActiveModalByIdEx(newActiveModal);
  }, [setActiveModalByIdEx]);

  const pushActiveModalById = useCallback(
    (modalId: string) => {
      modalHistory.current.push(modalId);
      setIsPushing(true);
      setActiveModalByIdEx(modalId);
    },
    [setActiveModalByIdEx]
  );

  const handleNavbarItemClick = useCallback(
    (level1MenuItemData: Level1MenuItemData) => {
      if (level1MenuItemData.modalId === activeModalId) {
        clearActiveModalAndHistory();
        return;
      }

      pushActiveModalById(level1MenuItemData.modalId);
    },
    [activeModalId, pushActiveModalById, clearActiveModalAndHistory]
  );

  const modalContentScrollContainerRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const mainMenuData = getMainMenuData(data);
  const mainMenuRawModalData = mainMenuData.modalData;
  const closeButtonText = mainMenuData.closeText;
  const mainMenuModalData = [
    constructMobileMenuModal(mainMenuData),
    ...mainMenuRawModalData,
    ...extractTertiaryNavAsMobileModals(mainMenuRawModalData),
  ];

  const { addScrollbarWidthWatcher, removeScrollbarWidthWatcher } = useScrollbarWidthOfElement({
    measureScrollbarTarget: modalContentScrollContainerRef,
    resizeObserverTarget: modalContentRef,
    onScrollbarWidthChange: (scrollbarWidthPx) => {
      primaryNavRootRef.current?.style.setProperty(
        "--primary-nav-modal-scrollbar-width-if-present",
        `${scrollbarWidthPx}px`
      );
    },
  });

  useEffect(() => {
    const elScrollContainer = modalContentScrollContainerRef.current;
    if (!elScrollContainer) {
      return;
    }
    if (activeModalId) {
      disableBodyScroll(elScrollContainer);
      addScrollbarWidthWatcher();
    } else {
      enableBodyScroll(elScrollContainer);
      removeScrollbarWidthWatcher();
    }

    return () => {
      if (activeModalId) {
        removeScrollbarWidthWatcher();
        enableBodyScroll(elScrollContainer);
      }
    };
  }, [activeModalId, addScrollbarWidthWatcher, removeScrollbarWidthWatcher]);

  const desktopFooter = (encoreMenuItem: Level1MenuItemData | undefined) => {
    if (!encoreMenuItem || !current.isLoginButtonVisible) {
      return <></>;
    }

    return isAuthenticated ? (
      <PrimaryNavNavbarItemAuthenticated
        menuItem={encoreMenuItem}
        isMenuItemActive={activeLevel1MenuItem === encoreMenuItem}
        onClick={handleNavbarItemClick}
      />
    ) : (
      <PrimaryNavNavbarItemSpecial
        menuItem={encoreMenuItem}
        isMenuItemActive={activeLevel1MenuItem === encoreMenuItem}
        onClick={handleNavbarItemClick}
      />
    );
  };

  const mainMenuLogoMobile = overrideDataMainMenuLogoMobile || mainMenuData?.logoMobile;
  const mainMenuLogo = overrideDataMainMenuLogo || mainMenuData?.logo;

  return (
    <div
      ref={primaryNavRootRef}
      className={styles.primaryNav}
      data-primary-nav-container-resize-observer-target=""
    >
      <SilentAuthentication></SilentAuthentication>
      <div className={styles.navbarWrapper}>
        <PrimaryNavNavbar
          header={
            <div className={styles.logo}>
              <Responsive breakpoint="small">
                {mainMenuLogoMobile?.src && (
                  <a href={mainMenuLogoHref ?? "/"}>
                    <SVGLogo src={mainMenuLogoMobile.src} description={mainMenuLogoMobile.alt} />
                  </a>
                )}
              </Responsive>
              <Responsive breakpoint="large">
                {mainMenuLogo?.src && (
                  <a href={mainMenuLogoHref ?? "/"}>
                    <SVGLogo src={mainMenuLogo.src} description={mainMenuLogo.alt} />
                  </a>
                )}
              </Responsive>
            </div>
          }
          footer={
            <>
              <Responsive breakpoint="small">
                {mobileMenuItem && (
                  <PrimaryNavNavbarItemSpecial
                    menuItem={mobileMenuItem}
                    icon={<SVGPrimaryNavMenu />}
                    isMenuItemActive={activeLevel1MenuItem === mobileMenuItem}
                    onClick={handleNavbarItemClick}
                  />
                )}
              </Responsive>
              {desktopFooter(encoreMenuItem)}
            </>
          }
        >
          <Responsive breakpoint="large">
            <PrimaryNavNavbarLevel1Items
              level1MenuItems={level1MenuItemsExceptEncore}
              activeLevel1MenuItem={activeLevel1MenuItemExceptEncore}
              onItemClick={handleNavbarItemClick}
            />
          </Responsive>
        </PrimaryNavNavbar>
      </div>

      <CSSTransition
        in={!!activeModalId}
        appear={true}
        timeout={MODAL_TRANSITION_DURATION_MS}
        classNames={MODAL_OPEN_CLOSE_CSS_CLASSNAMES}
      >
        <div className={styles.modal}>
          <div
            className={styles.allModalsContentScrollContainer}
            ref={modalContentScrollContainerRef}
          >
            <div className={styles.allModalsContent} ref={modalContentRef}>
              {mainMenuModalData.map((modalData) => (
                <CSSTransition
                  key={`${allModalContentsRemountKey} ${modalData.modalId}`}
                  in={lastNonNullActiveModalId === modalData.modalId}
                  appear={true}
                  timeout={MODAL_CONTENT_TRANSITION_DURATION_MS}
                  classNames={
                    isPushing
                      ? MODAL_CONTENT_SWITCH_CSS_CLASSNAMES
                      : MODAL_CONTENT_SWITCH_BACK_CSS_CLASSNAMES
                  }
                >
                  <ModalContent
                    modalData={modalData}
                    primaryNavData={data}
                    pushActiveModalById={pushActiveModalById}
                    popActiveModal={popActiveModal}
                    activeModalId={activeModalId}
                    clearActiveModalAndHistory={clearActiveModalAndHistory}
                    closeButton={
                      <PrimaryNavCloseButton
                        onClick={clearActiveModalAndHistory}
                        buttonLabel={closeButtonText}
                      />
                    }
                    staticLinks={getStaticLinks(data, modalData.type === ModalDataType.ENCORE)}
                    modalContentScrollContainerRef={modalContentScrollContainerRef}
                  />
                </CSSTransition>
              ))}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

PrimaryNav.displayName = "PrimaryNav";

export { PrimaryNav };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGCart: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <g stroke="currentColor" strokeWidth={1.314} strokeLinecap="round" strokeLinejoin="round">
      <path d="M6.029 14.568a.657.657 0 1 0 0-1.314.657.657 0 0 0 0 1.314ZM13.255 14.568a.657.657 0 1 0 0-1.314.657.657 0 0 0 0 1.314ZM.776.777h2.628l1.76 8.795a1.314 1.314 0 0 0 1.314 1.058h6.384a1.314 1.314 0 0 0 1.314-1.058l1.05-5.51H4.062" />
    </g>
  </svg>
  /* eslint-enable max-len */
);
SVGCart = memo(SVGCart);

export { SVGCart };

import { lxColor } from "../../colors/variables";
import { Theme } from "../shared/Theme";

export const mapStyles = (theme: Theme = Theme.DARK) => [
    {
        featureType: "all",
        elementType: "geometry.fill",
        stylers: [
            { color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "dark") },
            { saturation: 0 },
        ],
    },
    {
        featureType: "administrative",
        elementType: "all",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "dark") : lxColor("deep-blue", "light") }],
    },
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "dark") }],
    },
    {
        featureType: "administrative",
        elementType: "labels.icon",
        stylers: [{ color: lxColor("inari") }],
    },
    {
        featureType: "administrative.country",
        elementType: "all",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "dark") }],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "dark") : lxColor("deep-blue", "dark") }],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "dark") : lxColor("deep-blue") }],
    },
    {
        featureType: "landscape.natural.landcover",
        elementType: "geometry.fill",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "dark") }],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "geometry.fill",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "dark") }],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.icon",
        stylers: [{ saturation: -100 }, { invert_lightness: false }],
    },
    {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "light") }],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "light") }],
    },
    {
        elementType: "labels.text.fill",
        stylers: [{ color: theme === Theme.LIGHT ? lxColor("deep-blue") : lxColor("smoke") }],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            { visibility: "off" },
            { color: theme === Theme.LIGHT ? lxColor("smoke", "light") : lxColor("deep-blue", "light") },
        ],
    },
    {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit.station.rail",
        elementType: "all",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            { color: theme === Theme.LIGHT ? lxColor("deep-blue", "default") : lxColor("black", "absolute") },
            { visibility: "on" },
        ],
    },
];

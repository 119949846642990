import styles from "./PrimaryNavModalMobileMenu.module.scss";
import { PrimaryNavMobileItemsContainer } from "../PrimaryNavMobileItemsContainer";
import { PrimaryNavMobilePage } from "../PrimaryNavMobilePage";
import { PrimaryNavStaticLinks } from "../PrimaryNavStaticLinks/PrimaryNavStaticLinks";
import { Level1MenuItemData, MenuItemWithIconData } from "../types/types";

type PrimaryNavModalMobileMenuProps = {
  closeButton: React.ReactNode;
  level1MenuItems: Level1MenuItemData[];
  staticLinks: MenuItemWithIconData[];
  pushActiveModalById: (modalId: string) => void;
};

const PrimaryNavModalMobileMenu: React.FC<PrimaryNavModalMobileMenuProps> = ({
  closeButton,
  level1MenuItems,
  staticLinks,
  pushActiveModalById,
}) => {
  return (
    <PrimaryNavMobilePage
      closeButton={closeButton}
      footerChildren={<PrimaryNavStaticLinks items={staticLinks} />}
    >
      <PrimaryNavMobileItemsContainer parentIsFlexbox={true} fillParentHeight={true}>
        <ul className={styles.primaryNavMobileMenuItems}>
          {level1MenuItems.map((i) => (
            <li
              key={i.modalId}
              className={styles.primaryNavMobileMenuItem}
              onClick={() => pushActiveModalById(i.modalId)}
            >
              {i.mobileLabel || i.label}
            </li>
          ))}
        </ul>
      </PrimaryNavMobileItemsContainer>
    </PrimaryNavMobilePage>
  );
};

export { PrimaryNavModalMobileMenu };

import * as React from "react";
import "./Sticker.scss";
import classNames from "classnames";

enum StickerType {
    DEFAULT = "default",
    ROUND1 = "round1",
    ROUND2 = "round2",
}

const Sticker: React.FC<LXS.StickerProp> = ({ line1, line2, style = StickerType.DEFAULT }) => (
    <div
        className={classNames(
            "lxs-sticker",
            { "lxs-sticker--round": style !== StickerType.DEFAULT },
            { "lxs-sticker--alt-bg": style === StickerType.ROUND2 },
        )}
    >
        <div className="lxs-sticker__text">
            <span
                className={classNames("lxs-sticker__line", {
                    "lxs-sticker__line--strong": !line2 || style === StickerType.DEFAULT,
                })}
            >
                {line1}
            </span>
            {line2 && (
                <span
                    className={classNames("lxs-sticker__line", {
                        "lxs-sticker__line--strong": style !== StickerType.DEFAULT,
                    })}
                >
                    {line2}
                </span>
            )}
        </div>
    </div>
);

export { Sticker, StickerType };

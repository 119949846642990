import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGSearch: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.4142 31.4144"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M23.4977 20.6694C25.0711 18.5195 26 15.8682 26 13c0-7.1797-5.8203-13-13-13S0 5.8203 0 13s5.8203 13 13 13c2.8682 0 5.5194-.9288 7.6693-2.5021l7.9165 7.9165 2.8284-2.8285-7.9164-7.9165ZM13 22c-4.9705 0-9-4.0294-9-9s4.0295-9 9-9 9 4.0294 9 9-4.0294 9-9 9Z"
    />
  </svg>
);

SVGSearch = memo(SVGSearch);

export { SVGSearch };

import * as React from "react";
import "./ModelsMenuMobile.scss";
import { SVGIconButton, SVGIconButtonIconPositions } from "../../../SVGIcons/SVGIconButton";
import { Theme } from "../../../shared/Theme";
import { ModelsMenuMobileBodyTypes } from "./ModelsMenuMobileBodyTypes";
import { ModelsMenuMobileLinks } from "./ModelsMenuMobileLinks";
import { PrimaryNavContext } from "../../PrimaryNav";
import { ModelsMenuVehiclesFilter } from "../ModelsMenuVehiclesFilter";
import { GTMComponentContext } from "../../../GTM/GTMComponentContext";
import { SVGCross } from "../../../SVGIcons/icons/static/SVGCross";
import { lxColor } from "../../../../colors/variables";
import { useSelectedTags } from "../shared";

const ModelsMenuMobile: React.FC = () => {
    const { handleModelClose } = React.useContext(PrimaryNavContext);
    const [selectedTagIds, onTagSelected, bodyTypeRef] = useSelectedTags();

    return (
        <GTMComponentContext.Provider value={{ component: "ModelsMenuMobile" }}>
            <div className="lxs-models-menu-mobile">
                <div className="lxs-models-menu-mobile-section-header">
                    <div className="lxs-model-menu-mobile-close-buttons">
                        <SVGIconButton
                            title="Close"
                            iconPosition={SVGIconButtonIconPositions.LEFT}
                            stackOnMobile={true}
                            onClick={handleModelClose}
                            theme={Theme.DARK}
                            data-gtm-target={"CloseButton"}
                        >
                            <SVGCross height={20} width={20} color={lxColor("black", "absolute")} />
                        </SVGIconButton>
                    </div>
                    <div className="lxs-model-menu-mobile-filter">
                        <ModelsMenuVehiclesFilter selectedTagIds={selectedTagIds} onSelect={onTagSelected} />
                    </div>
                </div>
                <div className="lxs-models-menu-mobile-section-body-types" ref={bodyTypeRef}>
                    <ModelsMenuMobileBodyTypes selectedTagIds={selectedTagIds} />
                </div>
                <div className="lxs-models-menu-mobile-section-discover">
                    <ModelsMenuMobileLinks />
                </div>
            </div>
        </GTMComponentContext.Provider>
    );
};

export { ModelsMenuMobile };

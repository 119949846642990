import { useContext } from "react";
import {
  ComboBoxProps as AutocompletePropsPrimitiveProps,
  ComboBox as AutocompletePrimitive,
  ListBox as ListBoxPrimitive,
  Popover as PopoverPrimitive,
  Button,
} from "react-aria-components";

import { ThemeContext } from "@/theming/ThemeContext";

import styles from "./Autocomplete.module.scss";
import { FormContainer } from "../FormContainer/FormContainer";
import { FormHelperText } from "../FormHelperText/FormHelperText";
import { GlobalStylesScope } from "../GlobalStylesScope/GlobalStylesScope";
import { InputLabel } from "../InputLabel/InputLabel";
import { LoadingSpinner } from "../LoadingSpinner";
import { Stack } from "../Stack";
import { TextInput } from "../TextInput/TextInput";

export interface AutocompleteProps<T extends object>
  extends Omit<AutocompletePropsPrimitiveProps<T>, "children"> {
  /**
   * The content to display as the label.
   */
  label?: React.ReactNode;
  /**
   * A description for the field. Provides a hint such as specific requirements for what to choose.
   */
  description?: React.ReactNode;
  /**
   * An error message for the field.
   */
  errorMessage?: React.ReactNode;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  /**
   * The list of ComboBox items (uncontrolled).
   */
  defaultItems?: AutocompletePropsPrimitiveProps<T>["defaultItems"];
  /**
   * The list of ComboBox items (controlled).
   */
  items?: AutocompletePropsPrimitiveProps<T>["items"];
  /**
   * The value of the ComboBox input (controlled).
   */
  inputValue?: AutocompletePropsPrimitiveProps<T>["inputValue"];
  /**
   * The default value of the ComboBox input (uncontrolled).
   */
  defaultInputValue?: AutocompletePropsPrimitiveProps<T>["defaultInputValue"];
  /**
   * The currently selected key in the collection (controlled).
   */
  selectedKey?: AutocompletePropsPrimitiveProps<T>["selectedKey"];
  /**
   * The initial selected key in the collection (uncontrolled).
   */
  defaultSelectedKey?: AutocompletePropsPrimitiveProps<T>["defaultSelectedKey"];
  /**
   * Whether the input is disabled.
   */
  isDisabled?: AutocompletePropsPrimitiveProps<T>["isDisabled"];
  /**
   * Whether the input can be selected but not changed by the user.
   */
  isReadOnly?: AutocompletePropsPrimitiveProps<T>["isReadOnly"];
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * Whether the autocomplete is fetching data
   */
  isLoading?: boolean;
}

/**
 * An Autocomplete combines a text input with a listbox, allowing users to filter a list of options to items matching a query.
 *
 * ## State
 * ### Uncontrolled
 * A Autocomplete's `inputValue` is empty by default, but an initial, uncontrolled, inputValue can be provided using the `defaultInputValue` prop.
 * ```
 * <Autocomplete defaultInputValue="Hello" />
 * ```
 *
 * ### Controlled
 * The `inputValue` prop can be used to make the `inputValue` controlled. The `onChange` event is fired when the user edits the text, and receives the new `inputValue`.
 *
 */
export function Autocomplete<T extends object>({
  children,
  label,
  description,
  errorMessage,
  placeholder,
  isLoading,
  ...props
}: AutocompleteProps<T>) {
  const hasDescription = Boolean(description);
  const showErrorMessage = Boolean(errorMessage);
  const showDescription = hasDescription && !showErrorMessage;
  const themeDefinition = useContext(ThemeContext);

  return (
    <AutocompletePrimitive {...props} className={styles.autocomplete}>
      <Stack direction="column" spacing="3xs">
        {label && <InputLabel>{label}</InputLabel>}
        <div>
          <div className={styles.textInputContainer}>
            <TextInput placeholder={placeholder} />
            {isLoading && <LoadingSpinner className={styles.loadingSpinner} />}
          </div>
          {/* Hidden button - do not remove. React Aria Component requires this button to be present for full autocomplete functionality. Position set to absolute so it doesn't interfere with the document flow  */}
          <Button style={{ position: "absolute" }} />
        </div>

        <PopoverPrimitive className={styles.popover}>
          <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
            <FormContainer>
              <ListBoxPrimitive className={styles.listbox}>{children}</ListBoxPrimitive>
            </FormContainer>
          </GlobalStylesScope>
        </PopoverPrimitive>

        {showDescription && <FormHelperText>{description}</FormHelperText>}
        {showErrorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Stack>
    </AutocompletePrimitive>
  );
}

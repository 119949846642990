import classnames from "classnames";
import * as React from "react";
import { lxsFormLabelScale, lxsFormNotchLeft, lxsFormNotchPadding } from "../variables";
import "./NotchBorderAndLabel.scss";

/*
    Parent must be position relative.
*/

interface NotchBorderAndLabelProps {
    label?: string;
    isFocused?: boolean;
    isActive?: boolean;
    isValid?: boolean;
    isError?: boolean;
}

const NotchBorderAndLabelInternal = ({ label, isFocused, isActive, isValid, isError }: NotchBorderAndLabelProps) => {
    const [labelWidth, setLabelWidth] = React.useState<number | undefined>(undefined);

    const labelRef = React.useRef<HTMLLabelElement>(null);

    React.useLayoutEffect(() => {
        if (labelRef.current) {
            setLabelWidth(labelRef.current.clientWidth);
        }

        // label could change size on resize, need to update labelWidth state
        const windowResizeHandler = () => {
            if (labelRef.current) {
                setLabelWidth(labelRef.current.clientWidth);
            }
        };
        window.addEventListener("resize", windowResizeHandler);

        return () => {
            window.removeEventListener("resize", windowResizeHandler);
        };
    }, [label]);

    const borderNotchStyle = React.useMemo(
        () => ({
            width:
                labelWidth === undefined
                    ? "auto" // NOTE: auto so that when labelWidth is first set, it wont transition.
                    : isActive && label
                    ? `${labelWidth * lxsFormLabelScale + 2 * lxsFormNotchPadding}px`
                    : "0.01px",
        }),
        [isActive, labelWidth],
    );

    const labelScaleMultiplier = 0.5;
    const borderStyle = React.useMemo(
        () => ({
            paddingLeft:
                labelWidth !== undefined && !isActive
                    ? `${
                          labelWidth * lxsFormLabelScale * labelScaleMultiplier + lxsFormNotchLeft + lxsFormNotchPadding
                      }px`
                    : undefined,
        }),
        [isActive, labelWidth],
    );

    return (
        <div
            className={classnames(
                "lxs-notch-border-and-label",
                { "lxs-notch-border-and-label--focus": isFocused },
                { "lxs-notch-border-and-label--active": isActive },
                { "lxs-notch-border-and-label--valid": isValid },
                { "lxs-notch-border-and-label--error": isError },
            )}
        >
            <label ref={labelRef} className="lxs-notch-border-and-label__label">
                {label}
            </label>
            <fieldset className="lxs-notch-border-and-label__border" style={borderStyle}>
                <legend className={classnames("lxs-notch-border-and-label__border-notch")} style={borderNotchStyle}>
                    <span>&#8203;</span>
                </legend>
            </fieldset>
        </div>
    );
};

const NotchBorderAndLabel = React.memo(NotchBorderAndLabelInternal);

export default NotchBorderAndLabel;

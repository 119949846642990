import cn from "classnames";
import { useState } from "react";

import { OpeningHours } from "@/types/dealer";

import styles from "./DealerOpeningHours.module.scss";
import { DaysNames, getCurrentDay, openingHoursDisplayString } from "./DealerOpeningHours.utils";
import { DealerOpeningHoursTitle } from "./DealerOpeningHoursTitle";

type DealerOpeningHoursProps = {
  openingHours: OpeningHours[];
  /**
   * When provided, it shows the opening hours of the selected value. Should rarely be used outside VRT.
   */
  dayOfWeek?: keyof typeof DaysNames;
  /**
   * When true, opening hours details is displayed. Should rarely be used outside VRT.
   */
  showOpenHoursDefaultValue?: boolean;
};

const scheduleRowHighlightClass = (
  todayOpeningHours: OpeningHours | undefined,
  operatingDay: string
) =>
  todayOpeningHours && {
    [styles.dealerOpeningHoursMoreDetailsHighlight]: todayOpeningHours.day === operatingDay,
  };

const DealerOpeningHours: React.FC<DealerOpeningHoursProps> = ({
  openingHours,
  dayOfWeek,
  showOpenHoursDefaultValue = false,
}) => {
  const [showOpeningHoursDetails, setShowOpeningHoursDetails] = useState(showOpenHoursDefaultValue);

  const todayOpeningHours = openingHours.find(
    (o) => o.day.toLowerCase() === (dayOfWeek || getCurrentDay()).toLowerCase()
  );

  const toggleOpeningHoursDetails = () => {
    setShowOpeningHoursDetails(!showOpeningHoursDetails);
  };

  return (
    <div className={styles.dealerOpeningHours}>
      <DealerOpeningHoursTitle
        showOpeningHoursDetails={showOpeningHoursDetails}
        todayOpeningHours={todayOpeningHours}
        toggleOpeningHoursDetails={toggleOpeningHoursDetails}
      />

      <div
        className={cn(styles.dealerOpeningHoursMoreDetails, {
          [styles.dealerOpeningHoursMoreDetailsVisible]: showOpeningHoursDetails,
        })}
      >
        {openingHours.map((operatingHour) => {
          return (
            <div
              key={`day-${operatingHour.day}`}
              className={cn(
                styles.dealerOpeningHoursItems,
                styles.dealerOpeningHoursDetails,
                scheduleRowHighlightClass(todayOpeningHours, operatingHour.day)
              )}
            >
              <span>{operatingHour.day}</span>
              <span>{openingHoursDisplayString(operatingHour)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { DealerOpeningHours };
export type { DealerOpeningHoursProps };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGBento: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg version="1.1" width={width} height={height} x="0px" y="0px" viewBox="0 0 128 128" id="el_IKXveaTy5" {...rest}>
        <g id="el_QpqSs2t2m-_an_JDuNXaYrm" data-animator-group="true" data-animator-type="0">
            <g id="el_QpqSs2t2m-_an_i6aW4JhyU" data-animator-group="true" data-animator-type="1">
                <g id="el_QpqSs2t2m-">
                    <g id="el_EryYtdQXnn_an_DiKJea5DL" data-animator-group="true" data-animator-type="0">
                        <polygon
                            id="el_EryYtdQXnn"
                            fill={color}
                            points="108,100 108,68 68,68 68,108 108,108 108,100 76,100 76,76 100,76 100,100 &#9;"
                        />
                    </g>
                    <g id="el_nDfD72sddD_an_DdcEnEHsU" data-animator-group="true" data-animator-type="0">
                        <polygon
                            id="el_nDfD72sddD"
                            fill={color}
                            points="60,100 60,68 20,68 20,108 60,108 60,100 28,100 28,76 52,76 52,100 &#9;"
                        />
                    </g>
                    <g id="el_G0nNJcCLjC_an_kmayPM912" data-animator-group="true" data-animator-type="0">
                        <polygon
                            id="el_G0nNJcCLjC"
                            fill={color}
                            points="108,52 108,20 68,20 68,60 108,60 108,52 76,52 76,28 100,28 100,52 &#9;"
                        />
                    </g>
                    <g id="el_EBwCd5k4r__an_Md2ws28Zz" data-animator-group="true" data-animator-type="0">
                        <polygon
                            id="el_EBwCd5k4r_"
                            fill={color}
                            points="60,52 60,20 20,20 20,60 60,60 60,52 28,52 28,28 52,28 52,52 &#9;"
                        />
                    </g>
                </g>
            </g>
        </g>
        <g id="el_CX_j7ruqR9_an_eTNCsuTT7" data-animator-group="true" data-animator-type="0">
            <g id="el_CX_j7ruqR9_an_R1zZn3Roa" data-animator-group="true" data-animator-type="1">
                <g id="el_CX_j7ruqR9">
                    <g id="el_JAJ0s_BWb8_an_AhKs_GDz1" data-animator-group="true" data-animator-type="0">
                        <path id="el_JAJ0s_BWb8" fill={color} d="M108,68H68v40h8V76h32V68z" />
                    </g>
                    <g id="el_nZhptVjLUN_an_osEx3nf1f" data-animator-group="true" data-animator-type="0">
                        <path id="el_nZhptVjLUN" fill={color} d="M20,68v8h32v32h8V68H20z" />
                    </g>
                    <g id="el_djo-q9OiNNB_an_-Rlc-BjL6" data-animator-group="true" data-animator-type="0">
                        <path id="el_djo-q9OiNNB" fill={color} d="M76,20h-8v40h40v-8H76V20z" />
                    </g>
                    <g id="el_95EcvrmdFju_an_7lPbZKoNz" data-animator-group="true" data-animator-type="0">
                        <path id="el_95EcvrmdFju" fill={color} d="M60,20h-8v32H20v8h40V20z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
    /* eslint-enable max-len */
);
SVGBento = React.memo(SVGBento);

export { SVGBento };

import cn from "classnames";

import styles from "./CardWithHeader.module.scss";
import { Box } from "../Box";

export type CardWithHeaderType = "topElevate" | "topEncore" | "topPlatinum" | "topElectrified";

export interface CardWithHeaderTopProps {
  /**
   * header background will be transparent if ommited
   */
  background?: CardWithHeaderType;
}

export const CardWithHeaderTop: React.FC<React.PropsWithChildren<CardWithHeaderTopProps>> = ({
  background,
  children,
}) => (
  <Box px="s" py="l" className={cn(styles.cardWithHeaderTop, background && styles[background])}>
    {children}
  </Box>
);

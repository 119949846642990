export enum BookingStatus {
    Cancelled = "cancelled",
    Expired = "expired",
    Pending = "pending",
    Approved = "approved",
    Inuse = "inuse",
    Inspection = "inspection",
    Complete = "complete",
}

export enum BookingGrouping {
    Active = "active",
    Upcoming = "upcoming",
    Previous = "previous",
}

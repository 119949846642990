import * as React from "react";
import { RouteComponentProps } from "react-router";
import Toast from "../../components/Toast/Toast";
import VehicleUnavailableCard from "../../components/errorCards/VehicleUnavailableCard/VehicleUnavailableCard";
import { TOAST_PARAM_ON_UPDATE } from "../../pages/Encore/EncoreBookingDetailsPage/constant";

const getExtraMessage = (key: string): string | undefined => {
    switch (key) {
        case TOAST_PARAM_ON_UPDATE:
            return "No booking changes have been saved.";
        default:
            return undefined;
    }
};

const VehicleUnavailableToast: React.FC<RouteComponentProps> = props => (
    <Toast>
        <VehicleUnavailableCard extraFinePrint={getExtraMessage(props.match.params["toastParam"])} {...props} />
    </Toast>
);

export default VehicleUnavailableToast;

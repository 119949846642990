interface DynamicScriptParams {
    url: string;
    uniqueId: string;
    async?: boolean;
    onLoad?: () => void;
    onError?: () => void;
}

enum Status {
    LOADING = "loading",
    LOADED = "loaded",
}

const ATTR_STATUS = "status";

/**
 * Returns a cleanup function
 * @param params: DynamicScriptParams
 */
export const loadDynamicScript = (params: DynamicScriptParams) => {
    if (typeof document === "undefined") {
        return;
    }

    const existingScript = document.getElementById(params.uniqueId);
    let loadingScript: HTMLElement;

    if (existingScript) {
        if (existingScript.getAttribute(ATTR_STATUS) === Status.LOADED) {
            params.onLoad && params.onLoad();
            return;
        } else {
            loadingScript = existingScript;
        }
    } else {
        const script = document.createElement("script");
        script.src = params.url;
        script.id = params.uniqueId;
        script.async = params.async || false;
        script.setAttribute(ATTR_STATUS, Status.LOADING);
        document.body.appendChild(script);
        loadingScript = script;
    }

    const videoLoaded = () => {
        loadingScript.setAttribute(ATTR_STATUS, Status.LOADED);
        params.onLoad && params.onLoad();
    };

    const videoFailed = () => {
        loadingScript.setAttribute(ATTR_STATUS, Status.LOADED);
        params.onError && params.onError();
    };

    loadingScript.addEventListener("load", videoLoaded);

    loadingScript.addEventListener("error", videoFailed);

    return () => {
        if (loadingScript) {
            loadingScript.removeEventListener("load", videoLoaded);
            loadingScript.removeEventListener("error", videoFailed);
        }
    };
};

export class SalesforceError extends Error {
    private _errorCode?: string;
    private _caseNumber?: string;

    public constructor(errorCode: string, caseNumber?: string) {
        super(errorCode);
        this._errorCode = errorCode;
        this._caseNumber = caseNumber;

        // Set the prototype explicitly.
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, SalesforceError.prototype);
    }

    public get errorCode() {
        return this._errorCode;
    }

    public get caseNumber() {
        return this._caseNumber;
    }
}

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGInfo: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm0 15c3.86 0 7-3.14 7-7s-3.14-7-7-7-7 3.14-7 7 3.14 7 7 7zM6.5 6.125h2.625v5.25h.75V12.5H6.5v-1.125h.75V7.25H6.5V6.125zM8.188 3.5a.937.937 0 110 1.875.937.937 0 010-1.875z"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGInfo = memo(SVGInfo);

export { SVGInfo };

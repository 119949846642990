import * as React from "react";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import { RouteSection } from "../../../helpers/routes";
import ErrorCard from "../ErrorCard/ErrorCard";
import { RouteComponentProps } from "react-router";
import { ButtonStyle } from "lexus-style-guide/buttons/shared";
import withCurrentBooking, {
    CurrentBookingProps,
} from "../../../pages/Encore/EncoreBookingDetailsPage/withCurrentBooking";

interface CardProps extends RouteComponentProps {
    extraFinePrint?: string;
}

const VehicleUnavailableCard: React.FC<CardProps & CurrentBookingProps> = ({ history, baseRoute, extraFinePrint }) => (
    <ErrorCard title={"selected vehicle unavailable"}>
        <ErrorCardFinePrint>
            Your selected vehicle is not available during your newly selected time and date.
            {extraFinePrint && (
                <>
                    <br />
                    <br />
                    {extraFinePrint}
                </>
            )}
        </ErrorCardFinePrint>
        <ErrorCardButton onClick={() => history.push(`${baseRoute}/${RouteSection.Vehicles}`)}>
            select new vehicle
        </ErrorCardButton>
        <ErrorCardButton
            buttonStyle={ButtonStyle.Secondary}
            onClick={() => history.push(`${baseRoute}/${RouteSection.TimeDate}`)}
        >
            edit time and date
        </ErrorCardButton>
    </ErrorCard>
);

export default withCurrentBooking(VehicleUnavailableCard);

import { MutableRefObject, useCallback, useRef } from "react";

const observeScrollbarWidth = (
  elScrollContainer: HTMLElement,
  elContent: HTMLElement,
  onScrollbarWidthChange: (scrollbarWidthPx: number) => void
) => {
  const getScrollbarWidth = () => elScrollContainer.offsetWidth - elScrollContainer.clientWidth;
  let lastScrollbarWidth: number;

  const updateScrollWidth = () => {
    const scrollbarWidth = getScrollbarWidth();

    if (scrollbarWidth === lastScrollbarWidth) {
      return;
    }

    onScrollbarWidthChange(scrollbarWidth);

    lastScrollbarWidth = scrollbarWidth;
  };

  const resizeObserver = new ResizeObserver(updateScrollWidth);
  resizeObserver.observe(elContent);
  return resizeObserver;
};

export const useScrollbarWidthOfElement = ({
  measureScrollbarTarget,
  resizeObserverTarget,
  onScrollbarWidthChange,
}: {
  measureScrollbarTarget: MutableRefObject<HTMLElement | null>;
  resizeObserverTarget: MutableRefObject<HTMLElement | null>;
  onScrollbarWidthChange: (scrollbarWidthPx: number) => void;
}) => {
  const scrollbarWidthObserver = useRef<ResizeObserver | null>(null);

  const addScrollbarWidthWatcher = useCallback(() => {
    if (!measureScrollbarTarget.current || !resizeObserverTarget.current) {
      return;
    }

    scrollbarWidthObserver.current = observeScrollbarWidth(
      measureScrollbarTarget.current,
      resizeObserverTarget.current,
      onScrollbarWidthChange
    );
  }, [measureScrollbarTarget, onScrollbarWidthChange, resizeObserverTarget]);

  const removeScrollbarWidthWatcher = useCallback(() => {
    scrollbarWidthObserver.current?.disconnect();
  }, []);

  return { addScrollbarWidthWatcher, removeScrollbarWidthWatcher };
};

import cn from "classnames";
import { memo, useCallback, useEffect } from "react";

import {
  FilterActionTypes,
  useFilter,
  useFilterDispatch,
} from "./modals/PrimaryNavModalModels.context";
import styles from "./PrimaryNavModalModelsFilters.module.scss";
import { BodyTypeData, FilterTypes, ModelData } from "./types/types";

type PrimaryNavModalModelsFiltersProps = {
  bodyTypes: BodyTypeData[];
};

type FilterPillProps = {
  item: FilterTypes;
  handleClick: () => void;
};

enum FilterType {
  BodyTypeTitle = "BODY TYPE",
  FuelTypeTitle = "FUEL TYPE",
  BodyType = "bodyType",
  FuelType = "fuelType",
}

/**
 * Create filter list data based on the filter type and filter value
 *
 * If filterValue is not provided, it will return all the filter types based on body type or fuel type
 *
 * If filterValue is provided, it will return filterValue as the base type
 * and the other types that support the base type as supportTypes
 */
const createFilterListData = (
  bodyTypes: BodyTypeData[],
  filterType: FilterType.BodyType | FilterType.FuelType,
  filterValue?: string
) => {
  const models: ModelData[] = bodyTypes.flatMap((bodyType) => bodyType.models);

  const filterFn = (model: ModelData) => {
    if (!filterValue) {
      return true;
    }

    const combinedFilterTypes = [model.bodyType, ...model.fuelTypes];

    return combinedFilterTypes.some((type) => type === filterValue);
  };

  const getSupportTypes = (model: ModelData) => {
    return filterType === FilterType.BodyType ? model.fuelTypes : [model.bodyType];
  };

  return models.reduce<FilterTypes[]>((acc, model) => {
    if (filterFn(model)) {
      const types = ([] as string[]).concat(
        filterValue
          ? [filterValue]
          : filterType === FilterType.BodyType
          ? [model.bodyType]
          : model.fuelTypes
      );
      types.forEach((type) => {
        const typeObj = acc.find((item) => item.baseType === type);
        if (typeObj) {
          typeObj.models.push(model.displayName);
          typeObj.supportTypes = [
            ...new Set([...(typeObj.supportTypes || []), ...getSupportTypes(model)]),
          ];
        } else {
          acc.push({
            baseType: type,
            models: [model.displayName],
            isSelected: false,
            isDisabled: false,
            supportTypes: getSupportTypes(model),
          });
        }
      });
    }
    return acc;
  }, []);
};

const getFilterStatus = (bodyTypesFilter: FilterTypes[], fuelTypesFilter: FilterTypes[]) => {
  let bothNotSelected = false;
  let bothSelected = false;
  let oneSelected = false;

  //no bodyType selected && no fuelType selected
  if (
    bodyTypesFilter.every((item) => !item.isSelected) &&
    fuelTypesFilter.every((item) => !item.isSelected)
  ) {
    bothNotSelected = true;
  }

  //both bodyType selected && fuelType selected
  if (
    bodyTypesFilter.some((item) => item.isSelected) &&
    fuelTypesFilter.some((item) => item.isSelected)
  ) {
    bothSelected = true;
  }

  //only one of the bodyType or fuelType is selected
  if (
    (bodyTypesFilter.every((item) => !item.isSelected) &&
      fuelTypesFilter.some((item) => item.isSelected)) ||
    (bodyTypesFilter.some((item) => item.isSelected) &&
      fuelTypesFilter.every((item) => !item.isSelected))
  ) {
    oneSelected = true;
  }

  return { bothNotSelected, bothSelected, oneSelected };
};

const FilterPill: React.FC<FilterPillProps> = ({ item, handleClick }) => (
  <button
    className={cn(
      styles.filterPill,
      { [styles.filterPillSelected]: item.isSelected },
      { [styles.filterPillDisabled]: item.isDisabled }
    )}
    key={item.baseType}
    aria-disabled={item.isDisabled}
    onClick={handleClick}
  >
    {item.baseType}
  </button>
);

const PrimaryNavModalModelsFiltersInternal: React.FC<PrimaryNavModalModelsFiltersProps> = ({
  bodyTypes,
}) => {
  const { bodyTypesFilter, fuelTypesFilter } = useFilter();
  const filterDispatch = useFilterDispatch();

  const handleClicked = (
    baseType: string,
    clickedFilterType: FilterType.FuelType | FilterType.BodyType
  ) => {
    const [selfFilter, anotherFilter] =
      clickedFilterType === FilterType.BodyType
        ? [bodyTypesFilter, fuelTypesFilter]
        : [fuelTypesFilter, bodyTypesFilter];
    clickedFilterType === FilterType.BodyType ? bodyTypesFilter : fuelTypesFilter;
    const [selfFilterType, anotherFilterType] =
      clickedFilterType === FilterType.BodyType
        ? ([FilterActionTypes.UPDATE_BODY, FilterActionTypes.UPDATE_FUEL] as const)
        : ([FilterActionTypes.UPDATE_FUEL, FilterActionTypes.UPDATE_BODY] as const);

    const newFilters = selfFilter.map((item) => {
      if (item.baseType === baseType) {
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }
      return { ...item, isSelected: false };
    });
    filterDispatch({
      type: selfFilterType,
      payload: newFilters,
    });
    const selectedBaseType = newFilters.find((item) => item.isSelected)?.baseType;
    const supportTypes = createFilterListData(bodyTypes, clickedFilterType, selectedBaseType);
    const newAnotherFilter = anotherFilter.map((item) => {
      const combinedSupportTypes = supportTypes.flatMap((item) => item.supportTypes);
      if (combinedSupportTypes?.includes(item.baseType)) {
        return {
          ...item,
          isDisabled: false,
        };
      } else {
        return {
          ...item,
          isDisabled: true,
        };
      }
    });
    filterDispatch({
      type: anotherFilterType,
      payload: newAnotherFilter,
    });
  };

  const getFilteredBodyTypes = useCallback(
    (filteredModels: string[] | undefined) =>
      bodyTypes
        .filter(({ models }) =>
          models.some(({ displayName }) => (filteredModels ?? []).includes(displayName))
        )
        .map(({ models, ...rest }) => ({
          ...rest,
          models: models.filter(({ displayName }) => (filteredModels ?? []).includes(displayName)),
        })),
    [bodyTypes]
  );

  useEffect(() => {
    const { bothNotSelected, bothSelected, oneSelected } = getFilterStatus(
      bodyTypesFilter,
      fuelTypesFilter
    );

    if (bothNotSelected) {
      filterDispatch({
        type: FilterActionTypes.UPDATE_MODEL_CARD_RESULTS,
        payload: bodyTypes,
      });
    }

    if (bothSelected) {
      const selectedBodyType = bodyTypesFilter.find((item) => item.isSelected)?.models;
      const selectedFuelType = fuelTypesFilter.find((item) => item.isSelected)?.models;
      const filteredModels = selectedBodyType?.filter((item) => selectedFuelType?.includes(item));

      filterDispatch({
        type: FilterActionTypes.UPDATE_MODEL_CARD_RESULTS,
        payload: getFilteredBodyTypes(filteredModels),
      });
    }

    if (oneSelected) {
      const filteredModels =
        bodyTypesFilter.find((item) => item.isSelected)?.models ||
        fuelTypesFilter.find((item) => item.isSelected)?.models;

      filterDispatch({
        type: FilterActionTypes.UPDATE_MODEL_CARD_RESULTS,
        payload: getFilteredBodyTypes(filteredModels),
      });
    }
  }, [bodyTypes, bodyTypesFilter, filterDispatch, fuelTypesFilter, getFilteredBodyTypes]);

  useEffect(() => {
    if (bodyTypesFilter.length === 0 && fuelTypesFilter.length === 0) {
      const bodyDefault = createFilterListData(bodyTypes, FilterType.BodyType);
      const fuelDefault = createFilterListData(bodyTypes, FilterType.FuelType);

      filterDispatch({
        type: FilterActionTypes.INITIALISE_FILTERS,
        payload: { bodyDefault, fuelDefault },
      });
    }
  }, [bodyTypes, bodyTypesFilter.length, filterDispatch, fuelTypesFilter.length]);

  return (
    <div className={styles.tagFilters}>
      <div className={styles.bodyType}>
        <div className={styles.tagTitle}>{FilterType.BodyTypeTitle}</div>
        <div className={styles.filterPills} role="vehicle_selection" aria-label="body_type_filter">
          {bodyTypesFilter?.map((item) => (
            <FilterPill
              handleClick={() => handleClicked(item.baseType, FilterType.BodyType)}
              item={item}
              key={item.baseType}
            />
          ))}
        </div>
      </div>
      <div className={styles.fuelType}>
        <div className={styles.tagTitle}>{FilterType.FuelTypeTitle}</div>
        <div className={styles.filterPills} role="vehicle_selection" aria-label="fuel_type_filter">
          {fuelTypesFilter?.map((item) => (
            <FilterPill
              handleClick={() => handleClicked(item.baseType, FilterType.FuelType)}
              item={item}
              key={item.baseType}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const PrimaryNavModalModelsFilters = memo(PrimaryNavModalModelsFiltersInternal);

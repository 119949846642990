import styles from "./GlobalFooter.module.scss";
import type { GraphqlChildrenWrapper, LinkData, GenericData } from "../../types/graphqlResponse";
import { Typography } from "../Typography/Typography";

export type GlobalFooterItemData = {
  link: LinkData;
};

export type GlobalFooterData = {
  copyrightText?: GenericData;
  details: GraphqlChildrenWrapper<GlobalFooterItemData>;
};

export type GlobalFooterProps = {
  data: GlobalFooterData;
};

const GlobalFooter: React.FC<GlobalFooterProps> = ({ data }) => {
  const items = data.details.children.results;

  return (
    <div className={styles.globalFooter}>
      <div className={styles.globalFooterBody}>
        <div className={styles.globalFooterBlock}>
          <Typography component="span" variant="b2" fontWeight="regular">
            {data.copyrightText?.value}
          </Typography>
        </div>
        <div className={styles.globalFooterBlock}>
          {items.map((item) => (
            <a
              className={styles.globalFooterLink}
              href={item.link.url}
              key={item.link.label}
              target={item.link.target || ""}
            >
              <Typography component="span" variant="b2">
                {item.link.label}
              </Typography>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export { GlobalFooter };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGSearch: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6693 23.4979C18.5194 25.0712 15.8682 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 15.8682 25.0711 18.5195 23.4977 20.6694L31.4142 28.5859L28.5858 31.4144L20.6693 23.4979ZM22 13C22 17.9706 17.9706 22 13 22C8.02944 22 4 17.9706 4 13C4 8.02944 8.02944 4 13 4C17.9706 4 22 8.02944 22 13Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGSearch = React.memo(SVGSearch);

export { SVGSearch };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGGrille: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M17 10C17 10.3556 16.5482 11 15.5 11C14.4518 11 14 10.3556 14 10C14 9.64441 14.4518 9 15.5 9C16.5482 9 17 9.64441 17 10Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M4.625 5L7 9.28571C6.1875 9.83517 1 14.8571 1 20H30C30 14.8571 25.8125 11.0714 24 9.28571L26.375 5H4.625Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
SVGGrille = memo(SVGGrille);

export { SVGGrille };

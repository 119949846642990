import { Image } from "@sitecore-jss/sitecore-jss-react";
import { toPercentage } from "../helpers/number";
import classnames, { Argument as ClassNameArgument } from "classnames";
import * as React from "react";
import "./FocalpointPicture.scss";
import { omit } from "lodash";

interface FocalpointPictureProps {
    defaultImage: LXS.FocalpointPictureImage;
    sources?: LXS.FocalpointPictureSource[];
    className?: string;
    imageClassName?: ClassNameArgument;
    cover?: boolean;
    onImageLoaded?: (e: React.SyntheticEvent<HTMLImageElement>) => void;
    style?: React.CSSProperties;
}

const calculateFocalStyle = (focalpoint: LXS.Focalpoint, currentStyle?: React.CSSProperties): React.CSSProperties => {
    const cropX = toPercentage(focalpoint.cropx, 0);
    const cropY = toPercentage(focalpoint.cropy, 0);
    return {
        ...currentStyle,
        objectPosition: `${cropX}% ${cropY}%`,
        left: `${cropX}%`,
        top: `${cropY}%`,
        transform: `translate(-${cropX}%, -${cropY}%)`,
    };
};

const FocalpointPicture: React.FC<FocalpointPictureProps> = ({
    defaultImage,
    className,
    sources,
    cover,
    onImageLoaded,
    style,
}) => {
    const classes = classnames("lxs-focalpoint-picture", { "lxs-focalpoint-picture--cover": cover }, className);
    const pictureRef = React.useRef<HTMLPictureElement>(null);
    const [currentStyle, setCurrentStyle] = React.useState(() => calculateFocalStyle(defaultImage, style));
    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        onImageLoaded && onImageLoaded(e);
        const currentSrc = e.currentTarget && e.currentTarget.currentSrc;
        if (!currentSrc) {
            return;
        }

        const matchingSource =
            sources &&
            sources.find(
                (source) =>
                    source.srcSet &&
                    currentSrc.length >= source.srcSet.length &&
                    source.srcSet === currentSrc.substr(currentSrc.length - source.srcSet.length),
            );
        if (!matchingSource) {
            return;
        }

        setCurrentStyle(calculateFocalStyle(matchingSource));
    };
    React.useEffect(() => {
        if (!pictureRef || !pictureRef.current || typeof document === "undefined") {
            return;
        }

        const img = pictureRef.current.querySelector("img.lxs-focalpoint-picture") as HTMLImageElement | null;

        if (img && img.complete) {
            //Calling artificial onLoad event if image already changed and loaded at the time of hydration (SSR only)
            let event: Event;
            if (document.createEvent) {
                event = document.createEvent("Event");
                event.initEvent("load", false, false);
            } else {
                event = new Event("load", { bubbles: false, cancelable: false });
            }
            img.dispatchEvent(event);
        }
    }, [pictureRef]);

    return (
        <picture ref={pictureRef}>
            {sources &&
                sources.map((sourceProps) => (
                    <source
                        suppressHydrationWarning={true}
                        // to avoid warning in console due to lazy loading that changes srcSet value
                        key={sourceProps.srcSet + (sourceProps.media || "")}
                        {...omit(sourceProps, [
                            "src",
                            "data-src",
                            "cropx",
                            "cropy",
                            "focusx",
                            "focusy",
                            "style",
                            "cover",
                        ])}
                    />
                ))}
            <Image
                className={classes}
                style={currentStyle}
                field={omit(defaultImage, "cover")}
                onLoad={imageLoaded}
                suppressHydrationWarning={true}
            />
        </picture>
    );
};

export { FocalpointPicture };

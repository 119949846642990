import type { AvailableIcons } from "@/components/SVGIcon/static/SVGSelector";
import type { LinkData, ImageData } from "@/types/graphqlResponse";

export const MainMenuType = {
  MAINMENU: "MainMenu",
  STATICLINKS: "StaticLinks",
} as const;

export const ModalDataType = {
  MOBILE_MENU: "mobile-menu",
  DEALER: "DealerMenu",
  MODELS: "ModelsMenu",
  GENERIC: "C__MainMenuEntryLevel1",
  ENCORE: "EncoreMenu",
} as const;

export const MenuItemType = {
  WITHCHILDREN: "MainMenuEntryLevel2",
  LEAF: "NavigationLeafEntry",
} as const;

export const NavTagGroup = {
  BODY: "body",
  FUEL: "fuel",
} as const;

export type MenuTagData = {
  name: string;
  group: typeof NavTagGroup;
};

export type PrimaryNavData = {
  level1MenuItems: Level1MenuItemData[];
  menuDetails: MainListItem[];
};

export type MainListItem = MainMenuItem | StaticLinksItem;

export type MainMenuItem = {
  menuType: typeof MainMenuType.MAINMENU;
  logo: ImageData;
  logoMobile: ImageData;
  menuTextMobile?: string;
  closeText?: string;
  modalData: ModalData[];
};

export type StaticLinksItem = {
  menuType: typeof MainMenuType.STATICLINKS;
  usedByEncore: boolean;
  staticLinks: MenuItemWithIconData[];
};

export type DealerData = {
  label: string;
  map: string; // TODO: Update to object { lat, lng } etc...
};

export type Level1MenuItemData = {
  label: string;
  mobileLabel?: string;
  modalId: string;
};

export type MenuItemData = {
  link?: LinkData;
  onClick?: () => void;
};

export type EncoreAdvertData = {
  title: string;
  description: string;
  googlePlayImageUrl?: string;
  googlePlayImageAlt?: string;
  googlePlayLinkUrl?: string;
  googlePlayLinkTarget?: string;
  appStoreImageUrl?: string;
  appStoreImageAlt?: string;
  appStoreLinkUrl?: string;
  appStoreLinkTarget?: string;
  heroImageUrl?: string;
  heroImageAlt?: string;
  ctaUrl?: string;
  ctaLabel?: string;
  ctaTarget?: string;
};

export type MenuItemWithChildren = {
  type: typeof MenuItemType.WITHCHILDREN;
  name: string;
  menuItems?: MenuItemData[];
};

export type MenuItemWithoutChildren = MenuItemData & {
  type: typeof MenuItemType.LEAF;
};

export type MenuItemWithIconData = MenuItemData & {
  icon?: AvailableIcons | string;
};

export type BodyTypeData = {
  displayName: string;
  models: ModelData[];
};

export type ModelData = {
  displayName: string;
  bodyType: string;
  modelName?: string;
  fuelTypes: string[];
  menuImage: ImageData;
  menuHoverImage: ImageData;
  contentPath?: string;
  hideFromMenu?: boolean;
};

export type ModalBaseData = {
  modalId: string;
  label: string;
  mobileLabel?: string;
};

export type ModalMobileMenuData = ModalBaseData & {
  type: typeof ModalDataType.MOBILE_MENU;
};

export type ModelsModalData = ModalBaseData & {
  type: typeof ModalDataType.MODELS;
  filterButtonText?: string;
  clearCtaText?: string;
  showCtaText?: string;
  scrollIndicatorDescription?: string;
  availableBodyTypes: BodyTypeData[];
};

export type ModalGenericData = ModalBaseData & {
  type: typeof ModalDataType.GENERIC;
  menuItems: MenuItemTyped[];
};

export type ModalEncoreData = ModalBaseData & {
  logoutLabel: string;
  type: typeof ModalDataType.ENCORE;
  menuItems: MenuItemData[];
  encoreAdvert: EncoreAdvertData;
};

export type ModalDealerData = ModalBaseData & {
  type: typeof ModalDataType.DEALER;
  menuItems: MenuItemData[];
};

export type ModalData =
  | ModelsModalData
  | ModalGenericData
  | ModalEncoreData
  | ModalDealerData
  | ModalMobileMenuData;

export type MenuItemTyped = MenuItemWithChildren | MenuItemWithoutChildren;

export type FilterTypes = {
  baseType: string;
  supportTypes: string[];
  models: string[];
  isSelected: boolean;
  isDisabled: boolean;
};

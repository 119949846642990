import * as React from "react";

interface TimeoutRef {
    forState: number;
    timeout?: NodeJS.Timeout;
}

export const useTransitionStates = (states: LXS.TransitionState[]) => {
    const [transitionStateIndex, setTransitionStateIndex] = React.useState<number | undefined>(undefined);
    const timeoutRef = React.useRef<TimeoutRef>();
    const isTransitioning = () => timeoutRef.current !== undefined;
    const next = () => {
        setTransitionStateIndex((current) =>
            current === undefined && states.length > 0
                ? 0
                : current !== undefined && states.length > current + 1
                ? current + 1
                : undefined,
        );
    };
    const guardedNext = () => {
        if (!isTransitioning()) {
            next();
        }
    };
    const startTransition = () => {
        if (!isTransitioning()) {
            setTransitionStateIndex((current) => (states.length > 0 ? 0 : current));
        }
    };
    const resetTransition = () => {
        // clear any timeouts, and set transitionStateIndex to undefined
        timeoutRef.current !== undefined &&
            timeoutRef.current.timeout !== undefined &&
            clearTimeout(timeoutRef.current.timeout);
        timeoutRef.current = undefined;
        setTransitionStateIndex(undefined);
    };
    React.useEffect(() => {
        if (transitionStateIndex !== undefined && timeoutRef.current === undefined) {
            // no timeout is set, but one needs to be set, if there is a time on this state
            const timeoutTime = states[transitionStateIndex].time;
            if (timeoutTime !== undefined) {
                timeoutRef.current = {
                    forState: transitionStateIndex,
                    timeout: setTimeout(next, timeoutTime),
                };
            }
        } else if (
            transitionStateIndex !== undefined &&
            timeoutRef.current !== undefined &&
            timeoutRef.current.forState !== transitionStateIndex
        ) {
            // timeout is set, but a new timeout needs to be set
            const timeoutTime = states[transitionStateIndex].time;
            if (timeoutTime !== undefined) {
                timeoutRef.current.forState = transitionStateIndex;
                timeoutRef.current.timeout = setTimeout(next, timeoutTime);
            } else {
                // no time for this state, remove timeout
                timeoutRef.current = undefined;
            }
        } else if (transitionStateIndex === undefined && timeoutRef.current !== undefined) {
            // just changed to no transition by timeout, remove timeout
            timeoutRef.current = undefined;
        }

        return () => {
            if (timeoutRef.current !== undefined && timeoutRef.current.timeout !== undefined) {
                clearTimeout(timeoutRef.current.timeout);
            }
        };
    }, [transitionStateIndex]);

    return {
        transitionState: transitionStateIndex !== undefined ? states[transitionStateIndex].state : undefined,
        startTransition,
        resetTransition,
        nextTransition: guardedNext,
    };
};

export const useTransitionComponents = (states: LXS.TransitionComponent[]) => {
    const { transitionState, startTransition, resetTransition, nextTransition } = useTransitionStates(
        states.map((state, index) => ({ time: state.time, state: index })),
    );
    const transitionOr = (component: React.ReactNode) =>
        typeof transitionState === "number" ? states[transitionState].component : component;
    return { transitionState, startTransition, resetTransition, nextTransition, transitionOr };
};

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGDownload: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.73911 5.91981L7.48303 7.99437V0.524925C7.48303 0.234697 7.72156 0 8.01666 0C8.31176 0 8.55029 0.234697 8.55029 0.524925V8.01428L10.3108 5.91981C10.5194 5.71288 10.8588 5.71288 11.0685 5.91981C11.2772 6.1257 11.2772 6.46046 11.0685 6.66686L8.43076 9.80541C8.3187 9.91543 8.17088 9.96257 8.0252 9.95472C7.87898 9.96257 7.73117 9.91543 7.61964 9.80541L4.98189 6.66686C4.7727 6.46046 4.7727 6.1257 4.98189 5.91981C5.19107 5.71288 5.53046 5.71288 5.73911 5.91981ZM14.9333 8.73333V12.4667H1.06667V8.73333C1.06667 8.43893 0.828267 8.2 0.533333 8.2C0.2384 8.2 0 8.43893 0 8.73333V13C0 13.2949 0.2384 13.5333 0.533333 13.5333H15.4667C15.7616 13.5333 16 13.2949 16 13V8.73333C16 8.43893 15.7616 8.2 15.4667 8.2C15.1717 8.2 14.9333 8.43893 14.9333 8.73333Z"
            fill={color}
        />
    </svg>
);
SVGDownload = React.memo(SVGDownload);

export { SVGDownload };

import "./Checkbox.scss";
import * as React from "react";
import classnames from "classnames";
import { createBemFn } from "../../../utilities/bem";

interface CheckboxProps {
    text?: string;
    isChecked?: boolean;
    onChange?: () => void;
}

const bem = createBemFn("lxs-checkbox");

const Checkbox: React.FC<CheckboxProps> = ({ text, isChecked, onChange }) => {
    return (
        <div className={bem()}>
            <input
                className={classnames(bem("input"), isChecked && bem("input", "checked"))}
                onChange={onChange}
                type="checkbox"
            />
            <label className={bem("label")}>
                <span>{text}</span>
            </label>
        </div>
    );
};

export { Checkbox };

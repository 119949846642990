import * as React from "react";
import Modal from "react-modal/lib/components/Modal";
import "./ModelsMenu.scss";
import { ModelsMenuDesktop } from "./desktop/ModelsMenuDesktop";
import { ModelsMenuMobile } from "./mobile/ModelsMenuMobile";
import { PrimaryNavContext } from "../PrimaryNav";

const ModelsMenu: React.FC<LXS.MenuProps> = ({ isOpen }) => {
    const { handleModelClose, handleModalAfterOpen } = React.useContext(PrimaryNavContext);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleModelClose}
            onAfterOpen={handleModalAfterOpen}
            className="lxs-models-menu-content"
            overlayClassName={{
                base: "lxs-models-menu-overlay",
                afterOpen: "lxs-menu-overlay--after-open",
                beforeClose: "lxs-menu-overlay--before-close",
            }}
            closeTimeoutMS={500}
        >
            <ModelsMenuDesktop />
            <ModelsMenuMobile />
        </Modal>
    );
};

export { ModelsMenu };

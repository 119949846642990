enum DataAnalyticsEvents {
  CLICK = "Click",
  INTERACTION = "Interaction",
  SCROLL = "Scroll",
}

enum DataAnalyticsEventActions {
  VideoPlay = "play",
  VideoPause = "pause",
  VideoEnded = "ended",
  VerticalScrollUpdate = "scroll",
}

enum DataAnalyticsEventTargets {
  Unknown = "unknown",
  VerticalScroll = "verticalScroll",
}

export { DataAnalyticsEvents, DataAnalyticsEventActions, DataAnalyticsEventTargets };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGCheckMark: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.3694 9.45787L12.4987 26.2455L2.62927 16.9566L5.37073 14.0438L12.5013 20.7549L27.6307 6.54248L30.3694 9.45787Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGCheckMark = React.memo(SVGCheckMark);

export { SVGCheckMark };

import { useEffect, useState } from "react";

import { CurrentUser } from "@/types/auth";

import { getUser, IDTOKEN_STORAGE_EVENT } from "../utils";

export const AUTH_CHECK_MS = 60000;

export const useCurrentUser = (): CurrentUser | undefined => {
  const [user, setUser] = useState<CurrentUser>();

  useEffect(() => {
    setUser(getUser());

    const handleAuthentication = () => {
      setUser(getUser());
    };

    window.addEventListener(IDTOKEN_STORAGE_EVENT, handleAuthentication);
    const intervalId = setInterval(handleAuthentication, AUTH_CHECK_MS);

    return () => {
      window.removeEventListener(IDTOKEN_STORAGE_EVENT, handleAuthentication);
      clearInterval(intervalId);
    };
  }, []);

  return user;
};

import * as React from "react";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { SessionTimeoutError } from "../Errors/SessionTimeoutError";
import { IdentityProvider, OtpType } from "../Providers/IdentityProvider";
import { BackButton } from "../SharedComponents/BackButton";
import { SetPasswordForm } from "../SharedComponents/SetPasswordForm";
import { SetPasswordSuccess } from "../SharedComponents/SetPasswordSuccess";
import { TermsAndConditionsForm } from "../SharedComponents/TermsAndConditionsForm";
import { VerificationForm } from "../SharedComponents/VerificationForm";
import { ChangePasswordGuide } from "./ChangePasswordGuide";

const strings = {
    timeoutMessage: "Your session has timed out. Press okay to continue to change password.",
} as const;

enum ChangeScreen {
    Main,
    Otp,
    New,
    TermsAndConditions,
    Success,
    Previous,
}

interface BaseCredentialsState {
    email: string;
}

interface GenericScreenState {
    type: ChangeScreen.Main | ChangeScreen.Previous;
}

interface PostLoginScreenState {
    type: ChangeScreen.TermsAndConditions | ChangeScreen.Success;
    guest: LXS.Guest;
}

interface OtpScreenState extends BaseCredentialsState {
    type: ChangeScreen.Otp;
    authId: string;
}

interface NewScreenState extends BaseCredentialsState {
    type: ChangeScreen.New;
    authId: string;
}

type ForgotScreenState = GenericScreenState | OtpScreenState | NewScreenState | PostLoginScreenState;

type AllPossibleProps = Partial<
    Omit<OtpScreenState, "type"> & Omit<NewScreenState, "type"> & Omit<PostLoginScreenState, "type">
>;

type ForgotState = AllPossibleProps & ForgotScreenState;

type SignInRequiredHandler = (e?: React.MouseEvent<HTMLAnchorElement>) => void;

interface ChangePasswordProps {
    onExit?: () => void;
    onLogin?: (guest: LXS.Guest) => void;
    termsAndPrivacyConfig: LXS.TermsAndPrivacyConfig;
    onTermsAccepted?: () => void;
    email: string;
}

const changeReducer =
    (onExitRef: React.MutableRefObject<SignInRequiredHandler | undefined>) =>
    (state: ForgotState, action: ForgotScreenState): ForgotState => {
        if (action.type === ChangeScreen.Previous) {
            if (state.type === ChangeScreen.Main) {
                onExitRef.current?.();
            }
            return { ...state, type: ChangeScreen.Main };
        } else {
            return { ...state, ...action };
        }
    };

const useHandleChange = (dispatch: React.Dispatch<ForgotScreenState>, email: string) =>
    React.useCallback(async (): Promise<void> => {
        const authId = await IdentityProvider.current.resetPasswordAsync(email);
        dispatch({ type: ChangeScreen.Otp, authId, email });
    }, [email]);

const useHandleOtp = (state: ForgotState, dispatch: React.Dispatch<ForgotScreenState>, handleRestart: () => void) =>
    React.useCallback(
        async (code: string): Promise<void> => {
            if (state.type !== ChangeScreen.Otp) {
                throw new Error(`Invalid state of component, expected ${ChangeScreen.Otp}, got ${state.type}`);
            }
            try {
                const authId = await IdentityProvider.current.submitOtpAsync(false, state.authId, code, OtpType.Other);
                dispatch({ type: ChangeScreen.New, authId, email: state.email });
            } catch (err) {
                if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                    throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
                }
                throw err;
            }
        },
        [state.type, state.email, state.authId],
    );

const useHandleNewPassword = (
    state: ForgotState,
    dispatch: React.Dispatch<ForgotScreenState>,
    handleRestart: () => void,
) =>
    React.useCallback(
        async (password: string): Promise<void> => {
            if (state.type !== ChangeScreen.New || !state.authId) {
                throw new Error(
                    `Invalid state of component, expected ${ChangeScreen.New}, ` +
                        `got ${state.type} and authId ${!!state.authId}`,
                );
            }
            try {
                const guest = await IdentityProvider.current.setNewPasswordAsync(state.authId, password, false);
                dispatch({ type: ChangeScreen.Success, guest });
            } catch (err) {
                if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                    throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
                }
                throw err;
            }
        },
        [state.type, state.email, state.authId],
    );

const useHandleOtpResend = (
    state: ForgotState,
    dispatch: React.Dispatch<ForgotScreenState>,
    handleRestart: () => void,
) =>
    React.useCallback(async () => {
        if (state.type !== ChangeScreen.Otp) {
            throw new Error(`Invalid state of component, expected ${ChangeScreen.Otp}, got ${state.type}`);
        }
        try {
            const authId = await IdentityProvider.current.requestOtpAsync(state.authId);
            dispatch({ type: state.type, authId, email: state.email });
        } catch (err) {
            if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
            }
            throw err;
        }
    }, [state.type, state.email]);

const useHandleTnCs = (
    state: ForgotState,
    termsAndConditionsVersion: string,
    privacyPolicyVersion: string,
    onLogin?: (guest: LXS.Guest) => void,
    onTermsAccepted?: () => void,
) =>
    React.useCallback(async () => {
        if (state.type !== ChangeScreen.TermsAndConditions || !state.guest) {
            throw new Error(
                `Invalid state of component, expected ${ChangeScreen.TermsAndConditions}, ` +
                    `got ${state.type} and guest: ${!state.guest}`,
            );
        }

        const details = await state.guest.getOrUpdateDetailsAsync({
            termsAndConditionsVersion,
            privacyPolicyVersion,
        });

        if (
            details &&
            details.termsAndConditionsVersion === termsAndConditionsVersion &&
            details.privacyPolicyVersion === privacyPolicyVersion
        ) {
            onTermsAccepted?.();
            onLogin?.(state.guest);
        } else {
            throw new AuthenticationError(new Set([AuthenticationErrorType.TnCsInvalid]));
        }
    }, [state.type, state.guest, termsAndConditionsVersion, privacyPolicyVersion, onLogin, onTermsAccepted]);

const useHandleComplete = (
    state: ForgotState,
    dispatch: React.Dispatch<ForgotScreenState>,
    termsVersion: string,
    privacyVersion: string,
    onLogin?: (guest: LXS.Guest) => void,
) =>
    React.useCallback(async () => {
        if (state.type !== ChangeScreen.Success || !state.guest) {
            throw new Error(
                `Invalid state of component, expected ${ChangeScreen.Success}, ` +
                    `got ${state.type} and guest: ${!state.guest}`,
            );
        }
        const details = await state.guest.getOrUpdateDetailsAsync();

        if (details.termsAndConditionsVersion !== termsVersion || details.privacyPolicyVersion !== privacyVersion) {
            dispatch({ type: ChangeScreen.TermsAndConditions, guest: state.guest });
        } else {
            onLogin?.(state.guest);
        }
    }, [state.type, state.guest, termsVersion, privacyVersion, onLogin]);

const ChangePassword: React.FC<ChangePasswordProps> = ({
    onExit,
    onLogin,
    onTermsAccepted,
    termsAndPrivacyConfig: { privacyPolicyUrl, privacyPolicyVersion, termsAndConditionsUrl, termsAndConditionsVersion },
    email,
}) => {
    const onExitRef = React.useRef(onExit);
    const [state, dispatch] = React.useReducer(changeReducer(onExitRef), { type: ChangeScreen.Main, email });
    const handleBack = React.useCallback(() => dispatch({ type: ChangeScreen.Previous }), []);
    const handleRestart = React.useCallback(() => dispatch({ type: ChangeScreen.Main }), []);
    const handleOtp = useHandleOtp(state, dispatch, handleRestart);
    const handleContinue = useHandleChange(dispatch, email);
    const handleOtpResend = useHandleOtpResend(state, dispatch, handleRestart);
    const handleNewPassword = useHandleNewPassword(state, dispatch, handleRestart);
    const handleTnCs = useHandleTnCs(state, termsAndConditionsVersion, privacyPolicyVersion, onLogin, onTermsAccepted);
    const handleComplete = useHandleComplete(state, dispatch, termsAndConditionsVersion, privacyPolicyVersion, onLogin);

    switch (state.type) {
        case ChangeScreen.Main:
            return (
                <>
                    <BackButton onBack={onExit && handleBack} />
                    <ChangePasswordGuide onContinue={handleContinue} email={email} />
                </>
            );
        case ChangeScreen.New:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <SetPasswordForm onSubmit={handleNewPassword} />
                </>
            );
        case ChangeScreen.Otp:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <VerificationForm email={state.email} onSubmit={handleOtp} onResendRequired={handleOtpResend} />
                </>
            );
        case ChangeScreen.TermsAndConditions:
            return (
                <>
                    <BackButton />
                    <TermsAndConditionsForm
                        onAccept={handleTnCs}
                        buttonText="accept"
                        privacyTitle="Privacy Policy"
                        termsTitle="Terms of Use"
                        privacyUrl={privacyPolicyUrl}
                        termsUrl={termsAndConditionsUrl}
                    />
                </>
            );
        case ChangeScreen.Success:
            return (
                <>
                    <BackButton />
                    <SetPasswordSuccess onContinue={handleComplete} />
                </>
            );
        default:
            return <h2>Unknown error</h2>;
    }
};

export { ChangePassword };

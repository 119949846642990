import { isPassiveSupported } from "../../utilities/feature-detection";

const addObservers = (observerOptionsSet: LXS.ObserverProps[]) => {
    const verticalScrollObservers: IntersectionObserver[] = [];
    observerOptionsSet
        .filter((option) => option.target || option.targets)
        .map((option) => {
            const observer = new IntersectionObserver(
                (entries) => entries && entries.length > 0 && option.callback(entries),
                { threshold: option.threshold },
            );
            option.target && observer.observe(option.target);
            option.targets && option.targets.map((element) => observer.observe(element));
            verticalScrollObservers.push(observer);
        });
    return verticalScrollObservers;
};

const removeObservers = (verticalScrollObservers: IntersectionObserver[]) => {
    if (verticalScrollObservers) {
        verticalScrollObservers.forEach((observer) => observer.disconnect());
        verticalScrollObservers.length = 0;
    }
};

const attachEventListeners = (
    handleKeyDown: (event: KeyboardEvent) => void,
    handleMouseWheel: (event: WheelEvent) => void,
) => {
    if (typeof document === "undefined") {
        return;
    }
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("wheel", handleMouseWheel, isPassiveSupported() ? { passive: false } : false);
};

const removeEventListeners = (
    handleKeyDown: (event: KeyboardEvent) => void,
    handleMouseWheel: (event: WheelEvent) => void,
) => {
    if (typeof document === "undefined") {
        return;
    }
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("wheel", handleMouseWheel);
};

const findVisibleTileFromIntersectionEntry = (entries: IntersectionObserverEntry[]) => {
    const visibleTiles = entries.filter((entry) => entry.isIntersecting);
    if (visibleTiles.length <= 0) {
        return null;
    }
    const maxIntersection = entries.reduce((prev, current) =>
        prev.intersectionRatio > current.intersectionRatio ? prev : current,
    );
    return maxIntersection.target;
};

const isScrollingHorizontally = (event: WheelEvent) => event && Math.abs(event.deltaY) === 0;

export {
    addObservers,
    removeObservers,
    attachEventListeners,
    removeEventListeners,
    findVisibleTileFromIntersectionEntry,
    isScrollingHorizontally,
};

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import { withSwapOnChromatic } from "@/utils/withSwapOnChromatic";

import styles from "./LexusMap.module.scss";
import { LexusMapChromaticMock } from "./LexusMapChromaticMock";
import { useLexusGoogleMapScriptsReady } from "./LexusMapHooks";
import { LexusMapParams, MapTheme, MapState } from "./types";
import {
  useCreateGoogleMap,
  useMarkersWithDirectionPoints,
  useSyncMapCenter,
  useSyncMapZoom,
  useSyncMapStyle,
  useSyncClusterStyle,
  useSyncDirections,
  useSyncMarkers,
} from "./utils";

type LexusMapContainerProps = LexusMapParams & {
  className?: string;
  theme?: MapTheme;
};

export type LexusMapProps = LexusMapContainerProps & {
  apiKey: string;
};

const LexusMapContainer: React.FC<LexusMapContainerProps> = ({
  className,
  mapDetails,
  markers,
  directions,
  clusterStyles,
  shouldResize,
  markerCallback,
  theme,
  ...mapParams
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hasMapRef = useRef(false);
  const [state, setState] = useState<MapState>();
  const [clusterState, setClusterState] = useState<MarkerClusterer>();
  const allMarkers = useMarkersWithDirectionPoints(markers, directions);

  useCreateGoogleMap(containerRef, setState, mapDetails, mapParams.mapStyle);

  useSyncMapCenter(hasMapRef, state, mapDetails?.center);
  useSyncMapZoom(hasMapRef, state, mapDetails?.zoom);
  useSyncMapStyle(state, mapParams.mapStyle, hasMapRef, theme);
  useSyncClusterStyle(state, setClusterState, clusterStyles);
  useSyncDirections(state, directions?.directions, hasMapRef);
  useSyncMarkers(state?.map, clusterState, allMarkers, markerCallback, shouldResize);

  useEffect(() => {
    if (state) {
      google.maps.event.trigger(state, "resize");
    }

    hasMapRef.current = !!state; // Set after the first iteration of useEffects passed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!state]);

  return (
    <div className={classNames(styles.map, className)} ref={containerRef} data-testid="lk-map" />
  );
};

const LexusMapInternal: React.FC<LexusMapProps> = ({ apiKey, ...mapContainerProps }) =>
  useLexusGoogleMapScriptsReady(apiKey) ? <LexusMapContainer {...mapContainerProps} /> : null; // We can do loading state here later once required

export const LexusMap = withSwapOnChromatic(LexusMapInternal, LexusMapChromaticMock);

import { identity } from "lodash";
import { settingsPromise, Settings } from "../settings";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ErrorConstants } from "Helpers/ErrorConstants";
import { AuthSection, setAuthSectionAction } from "ReduxSlices/user";

export const usePromiseState = <T, V>(
    promise: Promise<T>,
    selector: (data: T) => V = identity,
    errorHandler?: (e: unknown) => void
): V | undefined => {
    const [value, setValue] = useState<V | undefined>(undefined);
    useEffect(() => {
        const processedPromise = promise.then(data => {
            setValue(selector(data));
        });
        if (errorHandler) {
            processedPromise.catch(errorHandler);
        }
    }, []);
    return value;
};

export const useSettingsPromise = <V>(selector: (settings: Settings) => V = identity): V | undefined => {
    const dispatch = useDispatch();
    return usePromiseState(settingsPromise(), selector, e => {
        if (e instanceof Error && e.message?.includes(ErrorConstants.BFFDownError)) {
            dispatch(setAuthSectionAction(AuthSection.BFFError));
        } else {
            dispatch(setAuthSectionAction(AuthSection.Error));
        }
    });
};

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGPlay: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM24.5 44.9904L47 32L24.5 19.0096L24.5 44.9904Z"
            fill={color}
        />
    </svg>
);
SVGPlay = React.memo(SVGPlay);

export { SVGPlay };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGChevronRight: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M8.67157 7.00019L11.5 4.17176L23.3284 16.0002L11.5 27.8286L8.67157 25.0002L17.6716 16.0002L8.67157 7.00019Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGChevronRight = React.memo(SVGChevronRight);

export { SVGChevronRight };

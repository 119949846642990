/*
    enum MyEnum { key = value }
*/

type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: string;
};

/**
 * Places objects containing the key and value into an array, order is maintained
 * @param e Any string-value enum
 * @returns Array of key-value objects ordered according to enum declaration
 */
export const stringEnumToArray = <T extends StandardEnum<string>>(e: T): LXS.EnumArrayElement<T[keyof T], keyof T>[] =>
    (Object.keys(e) as Array<keyof T>).map((key) => ({ key, value: e[key] }));

/**
 * Places objects containing the key and value into an array, order is maintained
 * @param e Any number-value enum
 * @returns Array of key-value objects ordered according to enum declaration
 */
export const numberEnumToArray = <T extends StandardEnum<number>>(e: T): LXS.EnumArrayElement<number, keyof T>[] => {
    const keys = Object.entries(e) as Array<[Extract<keyof T, string>, number]>;
    keys.splice(0, keys.length / 2); //taking only the second part of enum without back references
    return keys.map(([key, value]) => ({ key, value }));
};

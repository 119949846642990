const VerticalScrollPagerClasses = {
    PAGER_HIDE: "lxs-vertical-scroll__pager--hide",
    BUTTON_ACTIVE: "lxs-vertical-scroll__pager__button--active",
    BUTTON_HIDE: "lxs-vertical-scroll__pager__button--hide",
    BUTTON_NOT_ACTIVE: "lxs-vertical-scroll__pager__button--not-active",
    BUTTON_NOT_ACTIVE_TRANSITION: "lxs-vertical-scroll__pager__button--not-active--transition",
    BUTTON_NOT_ACTIVE_REVERSE_TRANSITION: "lxs-vertical-scroll__pager__button--not-active--reverse-transition",
};

const WheelScrollConstants = {
    threshold: 50,
    eventLapseTimeInMs: 225,
};

enum VerticalScrollDirection {
    UP,
    DOWN,
}

export { VerticalScrollPagerClasses, WheelScrollConstants, VerticalScrollDirection };

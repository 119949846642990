import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGInfoCircle: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="el_R83X2SrZK"
        {...rest}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
            id="el_mzt_M0ZxD2"
            fill={color}
        />
        <g id="el_jmHK5trHns_an_anWn31MKH" data-animator-group="true" data-animator-type="0">
            <g id="el_jmHK5trHns_an_39zHbxqec" data-animator-group="true" data-animator-type="1">
                <g id="el_jmHK5trHns_an_A0eM93gt1" data-animator-group="true" data-animator-type="2">
                    <path d="M18 26V12L14 12V26H18Z" id="el_jmHK5trHns" fill={color} />
                </g>
            </g>
        </g>
        <g id="el_1fUqEs7ngG_an_PpSfaeqmh" data-animator-group="true" data-animator-type="0">
            <g id="el_1fUqEs7ngG_an_4pOv3FSUw" data-animator-group="true" data-animator-type="1">
                <g id="el_1fUqEs7ngG_an_teAff_UH2" data-animator-group="true" data-animator-type="2">
                    <path
                        d="M18 9.99997V6.00001C16.6667 6 15.3333 6 14 6.00001V9.99997C15.3333 10 16.6667 10 18 9.99997Z"
                        id="el_1fUqEs7ngG"
                        fill={color}
                    />
                </g>
            </g>
        </g>
    </svg>
    /* eslint-enable max-len */
);
SVGInfoCircle = React.memo(SVGInfoCircle);

export { SVGInfoCircle };

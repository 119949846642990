import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGMail: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M15.059 2.5h-14c-.552 0-1 .449-1 1v9c0 .552.448 1 1 1h14c.551 0 1-.448 1-1v-9c0-.551-.449-1-1-1Zm-14 2.329 4.943 3.515-4.943 3.972V4.829Zm5.366 3.817 1.489 1.058a.252.252 0 0 0 .29 0l1.483-1.055 4.842 3.851H1.627l4.798-3.854Zm3.686-.299 4.948-3.518v7.455l-4.948-3.937ZM15.059 3.5v.715l-7 4.978-7-4.978V3.5h14Z"
      clipRule="evenodd"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGMail = memo(SVGMail);

export { SVGMail };

import cn from "classnames";
import { useCallback, useEffect, useRef, useState, RefObject, forwardRef } from "react";
import { useCookies } from "react-cookie";

import { SVGCross } from "@/components/SVGIcon/static/SVGCross";
import { iconRenderer } from "@/components/SVGIcon/static/SVGSelector";

import styles from "./StickyPageBanner.module.scss";

interface StickyBannerProps extends React.PropsWithChildren {
  bgColor?: BackgroundColor;
  icon?: string;
}

interface ExpandedBannerProps extends StickyBannerProps {
  maxHeight: string;
  bannerType: string;
  icon?: string;
  handleClose: () => void;
}

const BANNER_COOKIE = "lexus-hide-stickyBanner";
const MAX_HEIGHT = "500px";
const COLLAPSED_HEIGHT = "46px";

type BackgroundColor =
  | "inari"
  | "smoke"
  | "smokeLight"
  | "smokeDark"
  | "deepBlue"
  | "deepBlueLight"
  | "deepBlueDark"
  | "deepBlueGradient";

const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
      if (!entry.isIntersecting && ref.current) {
        entry.target.parentNode?.removeChild(ref.current);
      }
    });
  }, [ref]);

  useEffect(() => {
    if (ref.current) observerRef.current?.observe(ref.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
};

export const StickyPageBanner: React.FC<StickyBannerProps> = ({
  bgColor = "smoke",
  icon = "warning",
  children,
}) => {
  const [maxHeight, setMaxHeight] = useState("0");
  const [collapsedHeight, setcollapsedHeight] = useState("0");
  const [cookies, setCookie] = useCookies([BANNER_COOKIE]);
  const [showOverlay, setshowOverlay] = useState(false);

  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  const handleClose = useCallback(() => {
    setCookie(BANNER_COOKIE, "1", { path: "/" });
    setMaxHeight("0");
    setcollapsedHeight("0");
  }, [setCookie]);

  useEffect(() => {
    const isClosed = cookies[BANNER_COOKIE] === "1";
    setMaxHeight(isClosed ? "0" : MAX_HEIGHT);
    setcollapsedHeight(isClosed ? "0" : COLLAPSED_HEIGHT);
  }, [cookies]);

  return (
    <>
      <ExpandedBanner
        maxHeight={maxHeight}
        icon={icon}
        bgColor={bgColor}
        bannerType="expanded"
        handleClose={handleClose}
        ref={elementRef}
      >
        {children}
      </ExpandedBanner>
      {!isOnScreen && !showOverlay && (
        <div
          style={{ height: collapsedHeight }}
          className={cn(styles.bannerCollapsed, styles[`${bgColor}LighterBg`])}
          onClick={() => setshowOverlay(!showOverlay)}
        >
          <div className={styles.body}>
            <span className={styles.iconCollapsed}>
              {iconRenderer(icon, {
                className: styles.iconCollapsed,
              })}
            </span>
            <span className={styles.content}>READ MESSAGE</span>
            <div>
              <button
                className={cn(styles.closeBtn, styles.closeBtnCollapsed)}
                onClick={handleClose}
              >
                <SVGCross height={10} width={10} />
              </button>
            </div>
          </div>
        </div>
      )}
      {showOverlay && (
        <ExpandedBanner
          maxHeight={maxHeight}
          icon={icon}
          bgColor={bgColor}
          bannerType="overlay"
          handleClose={handleClose}
        >
          {children}
        </ExpandedBanner>
      )}
    </>
  );
};

const ExpandedBannerInternal = (
  { maxHeight, icon, bgColor = "smoke", bannerType, children, handleClose }: ExpandedBannerProps,
  ref: React.Ref<HTMLDivElement>
) => (
  <div
    ref={ref}
    style={{ maxHeight: maxHeight }}
    className={cn(
      styles.bannerExpanded,
      {
        [styles.bannerOverlay]: bannerType === "overlay",
      },
      styles[`${bgColor}Bg`]
    )}
  >
    <div className={styles.body}>
      <span className={styles.iconExpanded}>
        {iconRenderer(icon, {
          className: styles.iconExpanded,
        })}
      </span>
      <span className={styles.content}>{children}</span>
      <div>
        <button
          className={cn(styles.closeBtn, styles.closeBtnExpanded, styles.iconExpanded)}
          onClick={handleClose}
        >
          <SVGCross />
        </button>
      </div>
    </div>
  </div>
);

const ExpandedBanner = forwardRef(ExpandedBannerInternal);

import { MutableRefObject, useEffect, useRef } from "react";

export const useResizeObserver = <T extends HTMLElement = HTMLDivElement>(
    targetRef: MutableRefObject<T | null>,
    callback: (contentRect: DOMRectReadOnly | null) => void,
    suppressArgsToCallback = false,
) => {
    const resizeObserver = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            if (entries && targetRef.current) {
                callback(!suppressArgsToCallback ? targetRef.current.getBoundingClientRect() : null);
            }
        });
        if (targetRef.current) {
            resizeObserver.current.observe(targetRef.current);
        }

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    }, [callback, suppressArgsToCallback, targetRef]);
};

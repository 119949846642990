import * as React from "react";
import { Theme } from "../../../shared/Theme";
import { SVGIconButton, SVGIconButtonIconPositions } from "../../../SVGIcons/SVGIconButton";
import "./MainMenuDesktopHeader.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { SVGCross } from "../../../SVGIcons/icons/static/SVGCross";
import { SVGIcon } from "../../../SVGIcons/SVGIcon";
import { lxColor } from "../../../../colors/variables";

const MainMenuDesktopHeader: React.FC = () => {
    const { handleMenuClose } = React.useContext(PrimaryNavContext);
    return (
        <div className="lxs-main-menu-desktop-header lxs-grid-row">
            <div className="lxs-grid lxs-grid--with-margin">
                <div>
                    <SVGIconButton
                        title="Close"
                        iconPosition={SVGIconButtonIconPositions.RIGHT}
                        stackOnMobile={true}
                        onClick={handleMenuClose}
                        theme={Theme.DARK}
                        data-gtm-target={"CloseButton"}
                    >
                        <SVGIcon hasBackground={true}>
                            <SVGCross height={20} width={20} color={lxColor("black", "absolute")} />
                        </SVGIcon>
                    </SVGIconButton>
                </div>
            </div>
        </div>
    );
};

export { MainMenuDesktopHeader };

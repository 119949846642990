import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGAddCircle: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
            fill={color}
        />
        <path d="M14 18V24H18V18H24V14H18V8H14V14H8V18H14Z" fill={color} />
    </svg>
    /* eslint-enable max-len */
);
SVGAddCircle = React.memo(SVGAddCircle);

export { SVGAddCircle };

import { MILLISECONDS_PER_SECOND } from "../../../utilities/time";
import { UnauthorizedError } from "../Errors/UnauthorizedError";
import { IdentityProvider } from "./IdentityProvider";

type GuestRequiredEventListener = () => Promise<LXS.Guest | undefined>;
type LoggedInEventListener = (guest: LXS.Guest) => void;
type LoggedOutEventListener = () => void;

const KEEP_SIGNED_IN_COOKIE = "keep_signed_in";
const ID_TOKEN_STORAGE = "id_token";
const REFRESH_TOKEN_STORAGE = "refresh_token";

enum AccountManagerEvent {
    GuestRequired = "guestRequired",
    LoggedIn = "loggedIn",
    LoggedOut = "loggedOut",
}

interface EventListenerMap {
    [AccountManagerEvent.GuestRequired]: GuestRequiredEventListener;
    [AccountManagerEvent.LoggedIn]: LoggedInEventListener;
    [AccountManagerEvent.LoggedOut]: LoggedOutEventListener;
}

type EventListenerCollection = { [K in keyof EventListenerMap]: Set<EventListenerMap[K]> };

const paramExtractRegex = /^(?:[^=]+)=(.+)$/;

const findCookiePair = (name: string) =>
    typeof document !== "undefined" ? document.cookie.split("; ").find((row) => row.startsWith(`${name}=`)) : undefined;

const getCookieValue = (name: string) => paramExtractRegex.exec(findCookiePair(name) || "")?.[1];

const removeCookie = (name: string) => {
    if (typeof document !== "undefined") {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure=true; SameSite=none`;
    }
};

const getStorageValue = (name: string, getPersisted?: boolean) => {
    if (getPersisted) {
        return window.localStorage.getItem(name) ?? getCookieValue(name);
    }

    return window.sessionStorage.getItem(name) ?? window.localStorage.getItem(name) ?? getCookieValue(name);
};

const setStorageValue = (name: string, value: string, persist?: boolean) => {
    if (persist) {
        window.localStorage.setItem(name, value);
    } else {
        window.sessionStorage.setItem(name, value);
    }
};

const removeStorageValue = (name: string) => {
    if (typeof window === "undefined") {
        return;
    }

    window.sessionStorage.removeItem(name);
    window.localStorage.removeItem(name);
};

/**
 * Handles current authenticated guest account and session.
 * This class is designed to be used as singleton (@see AccountManager.current.<...>) unless you need to
 * have many instances with different @see IdentityProvider
 */
class AccountManager {
    private _guest?: LXS.Guest;
    private _identityProvider: IdentityProvider;
    private _registeredEvents: EventListenerCollection = {
        [AccountManagerEvent.GuestRequired]: new Set<GuestRequiredEventListener>(),
        [AccountManagerEvent.LoggedIn]: new Set<LoggedInEventListener>(),
        [AccountManagerEvent.LoggedOut]: new Set<LoggedOutEventListener>(),
    } as const;

    public constructor(identityProvider: IdentityProvider) {
        this._identityProvider = identityProvider;
    }

    /**
     * Returns the current Guest or undefined. You should not use this and prefer {@link guestAsync} instead
     * as {@link guestAsync} will attempt to silently log in the guest
     */
    public get guest() {
        return IdentityProvider.isTokenExpired(IdentityProvider.getInfoFromToken(this._guest?.idToken))
            ? undefined
            : this._guest;
    }

    public set guest(guest: LXS.Guest | undefined) {
        if (!guest) {
            throw new Error("`guest` is undefined, please set a correct value!");
        }
        this._guest = guest;
        setStorageValue(ID_TOKEN_STORAGE, guest.idToken);
        if (guest.refreshToken) {
            setStorageValue(REFRESH_TOKEN_STORAGE, guest.refreshToken, guest.keepSignedIn);
        }
        this.fireLoggedInEvent(guest);
    }

    /**
     * Resets the state of current object to initial. This object is capable to recover
     * appropriate state from external storages after reset. So ensure those updated before interacting with
     * AccountManager after reset. You do not need to reattach events.
     */
    public resetState() {
        this._guest = undefined;
    }

    /**
     * Returns a Guest asynchronously, if there is a logged in guest it will be returned,
     * otherwise {@link AccountManagerEvent.GuestRequired} event will be fired to attempt to login
     * The event may throw anr error which needs to be handled appropriately
     */
    public getGuestAsync(mustReturnOrThrow: true): Promise<LXS.Guest>;
    public getGuestAsync(mustReturnOrThrow: false): Promise<LXS.Guest | undefined>;
    public async getGuestAsync(mustReturnOrThrow?: boolean): Promise<LXS.Guest | undefined> {
        const guest =
            this.guest ?? (await this.tryGetGuestSilentlyAsync()) ?? (await this.fireGuestRequiredEventAsync());
        if (mustReturnOrThrow && !guest) {
            this._guest = undefined;
            throw new UnauthorizedError("Unable to obtain guest information!");
        }
        if (guest && guest !== this._guest) {
            this.guest = guest; // We use this._guest here as we want to trigger LoggedIn event for this action
        }
        return guest;
    }

    /**
     * Returns the only singleton instance of this class
     */
    public static get current() {
        return currentManager;
    }

    /**
     * Adds a listener for events happening in this provider
     * @param event - event type
     * @param listener - a listener function which will be called when event is fired
     */
    public addEventListener<T extends AccountManagerEvent>(event: T, listener: EventListenerMap[T]) {
        if (event === AccountManagerEvent.GuestRequired) {
            // We only support one listener for GuestRequired event
            const list = this.getEventSet(event);
            list.clear();
            list.add(listener);
        }
        this.getEventSet(event).add(listener);
    }

    /**
     * Removes a listener
     * @param event - event type
     * @param listener - a listener function instance which was sent to @see addEventListener
     */
    public removeEventListener<T extends AccountManagerEvent>(event: T, listener: EventListenerMap[T]) {
        this.getEventSet(event).delete(listener);
    }

    /**
     * Updates guest details in back-end systems
     * @param guestDetailsToUpdate - details to update. Specify only details you need to update
     * @returns all updated and existing details
     */
    public updateGuestDetailsAsync(guestDetailsToUpdate: Partial<LXS.GuestDetails>): Promise<LXS.GuestDetails> {
        if (!this.guest?.idToken) {
            throw new Error("Unauthorized request");
        }
        return this.guest.getOrUpdateDetailsAsync(guestDetailsToUpdate);
    }

    /**
     * Logs guest out and cleanup storage
     */
    public logOut() {
        removeStorageValue(ID_TOKEN_STORAGE);
        removeStorageValue(REFRESH_TOKEN_STORAGE);
        removeCookie(ID_TOKEN_STORAGE);
        removeCookie(REFRESH_TOKEN_STORAGE);
        removeCookie(KEEP_SIGNED_IN_COOKIE);
        this.fireLoggedOutEvent();
        this.resetState();
    }

    private isTokenExpired(token: string | undefined) {
        const jwToken = IdentityProvider.getInfoFromToken(token);

        if (!jwToken?.exp || Date.now() >= jwToken.exp * MILLISECONDS_PER_SECOND) {
            return true;
        }

        return false;
    }

    private get cachedIdToken() {
        if (typeof window === "undefined") {
            return undefined;
        }

        const idToken = getStorageValue(ID_TOKEN_STORAGE);

        if (this.isTokenExpired(idToken)) {
            return undefined;
        }

        return idToken;
    }

    private get cachedRefreshToken() {
        if (typeof window === "undefined") {
            return undefined;
        }

        const refreshToken = getStorageValue(REFRESH_TOKEN_STORAGE);

        if (this.isTokenExpired(refreshToken)) {
            return undefined;
        }

        return refreshToken;
    }

    private get cachedKeepSignedIn() {
        if (typeof window === "undefined" || typeof document === "undefined") {
            return false;
        }

        if (
            getStorageValue(REFRESH_TOKEN_STORAGE, true) ||
            getCookieValue(KEEP_SIGNED_IN_COOKIE)?.toLowerCase() === "true"
        ) {
            return true;
        }

        return false;
    }

    private async tryGetGuestSilentlyAsync(): Promise<LXS.Guest | undefined> {
        const idToken = this.cachedIdToken;
        const refreshToken = this.cachedRefreshToken || undefined;
        const keepSignedIn = this.cachedKeepSignedIn || undefined;

        try {
            return await this._identityProvider.getGuestFromTokenAsync(keepSignedIn || false, refreshToken, idToken);
        } catch {
            return undefined;
        }
    }

    private async fireGuestRequiredEventAsync() {
        const listenerIterator = this._registeredEvents[AccountManagerEvent.GuestRequired].values();
        let next = listenerIterator.next();
        while (!next.done) {
            const guest = await next.value();
            if (guest) {
                return guest;
            }
            next = listenerIterator.next();
        }
        return undefined;
    }

    private fireLoggedInEvent(guest: LXS.Guest) {
        const listeners = Array.from(this._registeredEvents[AccountManagerEvent.LoggedIn].values());
        return listeners.forEach((listener) => listener(guest));
    }

    private fireLoggedOutEvent() {
        const listeners = Array.from(this._registeredEvents[AccountManagerEvent.LoggedOut].values());
        return listeners.forEach((listener) => listener());
    }

    private getEventSet<T extends AccountManagerEvent>(event: T & AccountManagerEvent): Set<EventListenerMap[T]> {
        // We are using `as` keyword here because TS still not able to
        // validate generics properly https://github.com/microsoft/TypeScript/issues/24085
        return this._registeredEvents[event] as Set<EventListenerMap[T]>;
    }
}

const currentManager = new AccountManager(IdentityProvider.current);

export { AccountManagerEvent, AccountManager };

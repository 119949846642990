import * as React from "react";
import classnames from "classnames";
import { MainMenuDesktopNav } from "./MainMenuDesktopNav";
import "./MainMenuDesktopContent.scss";
import { MainMenuFooter } from "../MainMenuFooter";
import { MainMenuDesktopSearch } from "./MainMenuDesktopSearch";
import { SocialMedia } from "../../SocialMedia/SocialMedia";
import { MainMenuDesktopContext } from "./MainMenuDesktop";

const MainMenuDesktopContent: React.FC = () => {
    const { isMainMenuNavListItemActive } = React.useContext(MainMenuDesktopContext);
    return (
        <div
            className={classnames("lxs-main-menu-desktop-content", {
                "lxs-main-menu-desktop-content--list-item-active": isMainMenuNavListItemActive,
            })}
        >
            <div className="lxs-main-menu-desktop-content__header">
                <MainMenuDesktopSearch />
            </div>
            <div className="lxs-main-menu-desktop-content__body">
                <MainMenuDesktopNav />
                <div className="lxs-main-menu-desktop-content__body-sidebar">
                    <SocialMedia />
                </div>
            </div>
            <div className="lxs-main-menu-desktop-content__footer">
                <MainMenuFooter desktop />
            </div>
        </div>
    );
};

export { MainMenuDesktopContent };

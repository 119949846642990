import {
    FetchAction,
    FetchActionPayload,
    FetchContainer,
    PromiseThunk,
    Thunk,
    fetchActionPayloadToContainer,
    getFetchContainerValue,
    initialFetchContainer,
    isLoadCompleted,
    json,
    status,
} from "../helpers/fetch";
import { ReducerMapValue, createAction, handleActions } from "redux-actions";

import { LoadStatus } from "../types/loadStatus";
import { PlaceholderProps } from "@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon";
import { PrimaryNavRawData } from "@tmca/lexus-kit/css-in-js";
import { RootLevelAction } from "./rootLevelAction";
import { StateSelector } from "../types/general";
import WebFont from "webfontloader";
import { createSelector } from "reselect";
import { hasPath } from "Helpers/object";
import { makeLinksAbsolute } from "../components/PrimaryNavigation/makeLinksAbsolute";
import { settingsPromise } from "../settings";

const collapseOpenTime = 600;
const collapseCloseTime = 600;
let collapseTimeout: NodeJS.Timeout | undefined;

export enum CollapseState {
    Closed = "Closed",
    Opening = "Opening",
    Open = "Open",
    Closing = "Closing",
}

type SetFontsLoadStatusPayload = LoadStatus;
type SetMobileSecondaryNavOpenPayload = CollapseState;
type FooterIntersectingPayload = boolean;
type IsNativeAppPayload = boolean;
type IsIFramePayload = boolean;

type Rendering = PlaceholderProps["rendering"];
type GetPrimaryNavigationDataFetchActionPayload = FetchActionPayload<Rendering | PrimaryNavRawData>;
type GetPrimaryNavigationDataFetchContainer = FetchContainer<Rendering | PrimaryNavRawData>;

type OpenPopTartPayload = string;

//#region actions
export enum GeneralAction {
    SetFontsLoadStatus = "SetFontsLoadStatus",
    SetMobileSecondaryNavOpen = "SetMobileSecondaryNavOpen",
    FooterIntersecting = "FooterIntersecting",
    GetPrimaryNavigationData = "GetPrimaryNavigationData",
    OpenPopTart = "OpenPopTart",
    ClosePopTart = "ClosePopTart",
    IsNativeApp = "IsNativeApp",
    IsIFrame = "IsIFrame",
    ToggleBenefitModal = "ToggleBenefitsModal",
}

const setFontsLoadStatusAction = createAction<SetFontsLoadStatusPayload>(GeneralAction.SetFontsLoadStatus);
const fontsLoadStartedAction = () => setFontsLoadStatusAction(LoadStatus.InProgress);
const fontsLoadSuccessAction = () => setFontsLoadStatusAction(LoadStatus.Success);
const fontsLoadFailureAction = () => setFontsLoadStatusAction(LoadStatus.Failure);

export const footerIntersectingAction = createAction<FooterIntersectingPayload>(GeneralAction.FooterIntersecting);
export const isNativeAppAction = createAction<IsNativeAppPayload>(GeneralAction.IsNativeApp);
export const isIFrameAction = createAction<IsIFramePayload>(GeneralAction.IsIFrame);

const setMobileSecondaryNavigationOpenAction = createAction<SetMobileSecondaryNavOpenPayload>(
    GeneralAction.SetMobileSecondaryNavOpen
);

const getPrimaryNavigationDataAction = createAction<GetPrimaryNavigationDataFetchActionPayload>(
    GeneralAction.GetPrimaryNavigationData
);

export const openPopTartAction = createAction<OpenPopTartPayload>(GeneralAction.OpenPopTart);
export const toggleBenefitsModalAction = createAction<boolean>(GeneralAction.ToggleBenefitModal);
export const closePopTartAction = createAction(GeneralAction.ClosePopTart);
//#endregion

//#region state
export interface GeneralState {
    fontsLoadStatus: LoadStatus;
    mobileSecondaryNavOpen: CollapseState;
    footerIntersecting: boolean;
    primaryNavigation: GetPrimaryNavigationDataFetchContainer;
    popTart: string[];
    isNativeApp: boolean;
    isIFrame: boolean;
    showBenefitsModal: boolean;
}

export const initialGeneralState: GeneralState = {
    fontsLoadStatus: LoadStatus.NotStarted,
    mobileSecondaryNavOpen: CollapseState.Closed,
    footerIntersecting: true,
    primaryNavigation: initialFetchContainer,
    popTart: [],
    isNativeApp: false,
    isIFrame: false,
    showBenefitsModal: false,
};
//#endregion

//#region thunks
export const closeMobileSecondaryNavigationThunk: Thunk<void> = (dispatch, getState) => {
    dispatch(setMobileSecondaryNavigationOpenAction(CollapseState.Closing));
    if (collapseTimeout !== undefined) {
        clearTimeout(collapseTimeout);
    }
    collapseTimeout = setTimeout(() => {
        const state = getState();
        const openState = mobileSecondaryNavigationOpenSelector(state);
        if (openState === CollapseState.Closing) {
            dispatch(setMobileSecondaryNavigationOpenAction(CollapseState.Closed));
        }
    }, collapseCloseTime);
};

export const openMobileSecondaryNavigationThunk: Thunk<void> = (dispatch, getState) => {
    dispatch(setMobileSecondaryNavigationOpenAction(CollapseState.Opening));
    if (collapseTimeout !== undefined) {
        clearTimeout(collapseTimeout);
    }
    collapseTimeout = setTimeout(() => {
        const state = getState();
        const openState = mobileSecondaryNavigationOpenSelector(state);
        if (openState === CollapseState.Opening) {
            dispatch(setMobileSecondaryNavigationOpenAction(CollapseState.Open));
        }
    }, collapseOpenTime);
};

export const loadFontsThunk: Thunk<void> = dispatch => {
    dispatch(fontsLoadStartedAction());

    WebFont.load({
        custom: {
            families: ["Nobel:n3,n4,n7"],
        },
        active: () => dispatch(fontsLoadSuccessAction()),
        inactive: () => dispatch(fontsLoadFailureAction()),
    });
};

const renderingDataSelector = (d: any): Rendering | undefined => {
    try {
        return d.sitecore.route;
    } catch {
        return;
    }
};

type PrimaryNavContainerRendering = {
    componentName: string;
    placeholders: unknown;
};

type PrimaryNavRendering = {
    fields: {
        data: PrimaryNavRawData;
    };
};

const isPrimaryNavContainerRendering = (rendering: unknown): rendering is PrimaryNavContainerRendering =>
    hasPath(rendering, "componentName") && rendering.componentName === "NavigationContainer";

const isPrimaryNavRendering = (rendering: unknown): rendering is PrimaryNavRendering =>
    hasPath(rendering, "fields.data.details") && !!rendering.fields.data.details;

const renderingDataSelectorSC10 = (d: unknown): PrimaryNavRawData | undefined => {
    try {
        const navPlaceholder = hasPath(
            d,
            "data.layout.item.rendered.sitecore.route.placeholders.headless-lexus-navigation"
        )
            ? d.data.layout.item.rendered.sitecore.route.placeholders["headless-lexus-navigation"]
            : undefined;

        const container = Array.isArray(navPlaceholder)
            ? navPlaceholder.find<PrimaryNavContainerRendering>(isPrimaryNavContainerRendering)
            : undefined;

        const placeholder = hasPath(container, "placeholders.headless-navigation-container")
            ? container.placeholders["headless-navigation-container"]
            : undefined;

        return Array.isArray(placeholder)
            ? placeholder.find<PrimaryNavRendering>(isPrimaryNavRendering)?.fields.data
            : undefined;
    } catch {
        return;
    }
};

export const getPrimaryNavigationDataThunk: PromiseThunk<void> = async dispatch => {
    dispatch(
        getPrimaryNavigationDataAction({
            action: FetchAction.Fetch,
        })
    );
    try {
        const settings = await settingsPromise();

        let value: Rendering | PrimaryNavRawData | undefined;

        if (settings.general.primaryNavigationSC10ContentPath) {
            const mainsiteBaseUrl = settings.general.mainsiteBaseUrl
                ? settings.general.mainsiteBaseUrl
                : settings.general.lexusBrandBaseUrl;

            const data = await fetch(settings.general.bffBaseUrl + settings.general.primaryNavigationSC10ContentPath)
                .then((response: any) => response.json())
                .catch((error: Error) => {
                    throw new Error("Failed to fetch Primary Navigation Data");
                });

            const renderingData = renderingDataSelectorSC10(data);
            if (renderingData === undefined) {
                throw new Error("Failed to load Primary Navigation Data");
            }
            value = makeLinksAbsolute(mainsiteBaseUrl, renderingData);
        } else {
            const data = await fetch(settings.general.lexusBrandBaseUrl + settings.general.primaryNavigationContentPath)
                .then(status)
                .then(json);
            const renderingData = renderingDataSelector(data);
            if (renderingData === undefined) {
                throw new Error("Failed to load Primary Navigation Data");
            }
            value = makeLinksAbsolute(settings.general.lexusBrandBaseUrl, renderingData);
        }

        dispatch(
            getPrimaryNavigationDataAction({
                action: FetchAction.Success,
                value,
            })
        );
    } catch (e) {
        dispatch(
            getPrimaryNavigationDataAction({
                action: FetchAction.Failure,
                value: e,
            })
        );
    }
};
//#endregion

//#region selectors
export const mobileSecondaryNavigationOpenSelector: StateSelector<CollapseState> = state =>
    state.general.mobileSecondaryNavOpen;

const fontsLoadStatusSelector: StateSelector<LoadStatus> = state => state.general.fontsLoadStatus;

export const fontLoadFinishedSelector = createSelector(fontsLoadStatusSelector, isLoadCompleted);

export const footerIntersectingSelector: StateSelector<boolean> = state => state.general.footerIntersecting;

const primaryNavigationContainerSelector: StateSelector<GetPrimaryNavigationDataFetchContainer> = state =>
    state.general.primaryNavigation;

export const primaryNavigationDataSelector: StateSelector<Rendering | PrimaryNavRawData | undefined> = createSelector(
    primaryNavigationContainerSelector,
    getFetchContainerValue
);

export const currentPopTartSelector: StateSelector<string | undefined> = state => state.general.popTart[0];

export const isNativeAppSelector: StateSelector<boolean> = state => state.general.isNativeApp;

export const isIFrameSelector: StateSelector<boolean> = state => state.general.isIFrame;

export const currentBenefitModalStatusSelector: StateSelector<boolean> = state => state.general.showBenefitsModal;

//#endregion

//#region reducers
type GeneralReducerPayload =
    | Error
    | undefined
    | SetFontsLoadStatusPayload
    | SetMobileSecondaryNavOpenPayload
    | GetPrimaryNavigationDataFetchActionPayload
    | OpenPopTartPayload
    | FooterIntersectingPayload;

type GeneralReducer<T = undefined> = ReducerMapValue<GeneralState, T>;

const setFontsLoadStatusReducer: GeneralReducer<SetFontsLoadStatusPayload> = (state, action) => ({
    ...state,
    fontsLoadStatus: action.payload,
});

const setMobileSecondaryNavOpenReducer: GeneralReducer<CollapseState> = (state, action) => ({
    ...state,
    mobileSecondaryNavOpen: action.payload,
});

const footerIntersectingReducer: GeneralReducer<boolean> = (state, action) => ({
    ...state,
    footerIntersecting: action.payload,
});

const isNativeAppReducer: GeneralReducer<boolean> = (state, action) => ({
    ...state,
    isNativeApp: action.payload,
});

const isIFrameReducer: GeneralReducer<boolean> = (state, action) => ({
    ...state,
    isIFrame: action.payload,
});

const getPrimaryNavigationDataReducer: GeneralReducer<GetPrimaryNavigationDataFetchActionPayload> = (
    state,
    action
) => ({
    ...state,
    primaryNavigation: fetchActionPayloadToContainer(action.payload),
});

const openPopTartReducer: GeneralReducer<OpenPopTartPayload> = (state, action) => ({
    ...state,
    popTart: [...state.popTart, action.payload],
});

const closePopTartReducer: GeneralReducer = state => ({
    ...state,
    popTart: state.popTart.slice(1),
});

const toggleBenefitModalReducer: GeneralReducer<boolean> = (state, action) => ({
    ...state,
    showBenefitsModal: action.payload,
});

const resetReducer: GeneralReducer = state => ({
    ...initialGeneralState,
    fontsLoadStatus: state.fontsLoadStatus,
    primaryNavigation: state.primaryNavigation,
});

export const generalReducer = handleActions<GeneralState, GeneralReducerPayload>(
    {
        [GeneralAction.SetFontsLoadStatus]: setFontsLoadStatusReducer,
        [GeneralAction.SetMobileSecondaryNavOpen]: setMobileSecondaryNavOpenReducer,
        [GeneralAction.FooterIntersecting]: footerIntersectingReducer,
        [GeneralAction.GetPrimaryNavigationData]: getPrimaryNavigationDataReducer,
        [GeneralAction.OpenPopTart]: openPopTartReducer,
        [GeneralAction.ClosePopTart]: closePopTartReducer,
        [GeneralAction.IsNativeApp]: isNativeAppReducer,
        [GeneralAction.IsIFrame]: isIFrameReducer,
        [GeneralAction.ToggleBenefitModal]: toggleBenefitModalReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialGeneralState
);
//#endregion

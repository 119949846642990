import { useRef } from "react";

import { LexusMap, LexusMapProps } from "@/components/LexusMap/LexusMap";
import { MapTheme, LexusMapStyle, Marker } from "@/components/LexusMap/types";
import { useCssPropertyValue } from "@/hooks/useCssPropertyValue";
import { dataUriEncodeSvg } from "@/utils/dataUriEncodeSvg";

import styles from "./DealerMap.module.scss";

const anchorLightSvg = dataUriEncodeSvg(`
  <svg width="52" height="73" viewBox="0 0 52 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#161825"
      d="M52 26C52 41.4103 32.9431 65.2496 27.4597 71.7902C26.686 72.7131 25.314 72.7131 24.5403 71.7902C19.0569 65.2496 0 41.4103 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26Z" />
    <path fill="#e4e1d7" fill-rule="evenodd" clip-rule="evenodd"
      d="M40.5053 28.7049C40.3079 28.7952 39.9412 28.82 39.9412 28.82H24.3076C24.3076 28.82 21.7187 28.6801 24.3076 25.6781L31.8799 14.8215C31.8799 14.8215 32.1871 14.5353 32.4033 14.162C32.6948 13.6549 32.8202 12.9861 31.8298 12.6408C30.0401 12.2271 28.0843 12 25.9907 12C15.0396 11.9938 8.03462 18.0692 8.00014 25.1618C7.96567 31.9744 14.1119 38.0124 25.9938 38C32.2372 38 37.2896 36.0557 40.4426 33.0196H37.3554C34.6412 35.1816 30.7171 36.5255 25.9938 36.5255C15.8075 36.5255 10.5608 31.4455 10.5608 25.1618C10.5608 18.8873 16.613 13.3967 25.9938 13.3874C26.3981 13.3812 26.7805 13.4061 27.1566 13.4216C27.4136 13.5803 27.4199 14.0314 26.3104 15.2477C26.3104 15.2477 24.0976 17.9385 21.1514 21.9732C18.1551 26.1043 17.7884 27.0096 17.7884 27.0096C17.7884 27.0096 14.5664 31.8033 22.2076 32.0522H41.361C43.0503 30.0612 44 27.7033 44 25.1618C44 20.0103 40.3141 15.425 34.0707 13.2879L33.3812 14.4544C39.0322 16.3582 41.6305 20.5453 41.6305 25.1555C41.6305 26.2754 41.4895 27.1682 40.9222 28.0735C40.9222 28.0735 40.7059 28.6147 40.5053 28.7049" />
  </svg>
`);

export const DealerMap: React.FC<LexusMapProps> = (mapProps) => {
  const myRef = useRef<HTMLDivElement>(null);
  const smokeLightestColor = useCssPropertyValue(myRef, "--color-legacy-smoke-lightest");

  const markers: Marker[] = mapProps.markers.map((m, index) => ({
    ...m,
    icon: {
      default: anchorLightSvg,
    },
    label:
      mapProps.markers.length > 1
        ? {
            className: styles.mapMarkerNumber,
            text: `${index + 1}`,
            color: smokeLightestColor,
            fontSize: "12px",
          }
        : undefined,
  }));

  return (
    <div ref={myRef}>
      <LexusMap
        className={styles.container}
        shouldResize={true}
        theme={MapTheme.LIGHT}
        mapStyle={LexusMapStyle.Lexus}
        {...mapProps}
        markers={markers}
      />
    </div>
  );
};

/**
 * Get mouse position relative to an element and element's with and height value.
 */
export const getEleInfo = (e: React.MouseEvent) => {
  const rect = e.currentTarget.getBoundingClientRect();
  const mouseX = `calc( ${e.clientX - rect.left}px - (${rect.width}px - 100%) / 2 )`;
  const mouseY = `calc( ${e.clientY - rect.top}px - (${rect.height}px - 100%) / 2 )`;
  const width = rect.width;
  const height = rect.height;
  return [mouseX, mouseY, width, height];
};

/**
 * Set custom css variables for elements to update their styles
 */
export const setCustomVariablesForEle = (name: string, value: string) => {
  const root = document.documentElement;
  root.style.setProperty(name, value);
};

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGLogin: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M16 30C19.1762 30 22.1054 28.9423 24.4546 27.1598V26.2459C24.3405 25.3827 24.0515 24.2379 23.0132 23.2809C21.947 22.2982 19.9254 21.3638 16 21.3638C12.0746 21.3638 10.053 22.2982 8.98682 23.2809C7.94852 24.2379 7.65955 25.3827 7.54547 26.2459V27.1599C9.89459 28.9423 12.8238 30 16 30ZM24.3686 21.8103C25.658 22.9986 26.1418 24.3817 26.3473 25.4306C28.6163 22.9424 30 19.6328 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 19.6328 3.38366 22.9424 5.65273 25.4306C5.85827 24.3818 6.34205 22.9986 7.63139 21.8103C9.19549 20.3687 11.78 19.3638 16 19.3638C20.2201 19.3638 22.8045 20.3687 24.3686 21.8103ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM19.8182 11.6365C19.8182 13.7453 18.1087 15.4547 16 15.4547C13.8913 15.4547 12.1818 13.7453 12.1818 11.6365C12.1818 9.52782 13.8913 7.81836 16 7.81836C18.1087 7.81836 19.8182 9.52782 19.8182 11.6365ZM21.8182 11.6365C21.8182 14.8498 19.2133 17.4547 16 17.4547C12.7867 17.4547 10.1818 14.8498 10.1818 11.6365C10.1818 8.42325 12.7867 5.81836 16 5.81836C19.2133 5.81836 21.8182 8.42325 21.8182 11.6365Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGLogin = React.memo(SVGLogin);

export { SVGLogin };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGAdd: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M14 32.5V0.5H18V32.5H14Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.74846e-07 14.5L32 14.5V18.5L0 18.5L1.74846e-07 14.5Z"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGAdd = memo(SVGAdd);

export { SVGAdd };

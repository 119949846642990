import { useContext, useMemo } from "react";

import { DataAnalyticsComponentContext } from "./DataAnalyticsComponentContext";
import { DataAnalyticsContext } from "./DataAnalyticsContext";
import {
  DataAnalyticsEventActions,
  DataAnalyticsEventTargets,
  DataAnalyticsEvents,
} from "./models/DataAnalyticsDefinitions";
import { DataAnalyticsMethods } from "./models/DataAnalyticsMethods";
import { AttributesObject, extractDataAnalyticAttributes } from "./utils";

/**
 * Definition for internal function
 */
interface DataAnalyticsRegisterEventInternal {
  /**
   * @param {DataAnalyticsEvents} eventType
   * @param {DataAnalyticsCustomProps} customProps
   * @param {AttributesObject} dataAnalyticsAttributes
   * @param {DataAnalyticsEventTargets} target
   * @param {DataAnalyticsEventActions} action
   */
  (
    eventType: DataAnalyticsEvents,
    customProps?: DataAnalyticsCustomProps,
    dataAnalyticsAttributes?: AttributesObject,
    target?: DataAnalyticsEventTargets,
    action?: DataAnalyticsEventActions
  ): void;
}

/**
 * Get all available methods to interact with DataAnalytics framework.
 * @param {DataAnalyticsCustomProps} hookCustomProps
 * @returns {{ registerInteraction: (target: string, action: string, customProps?: DataAnalyticsCustomProps) => void, registerClick: <T extends Element>(event: React.MouseEvent<T, MouseEvent>) => void, registerEvent: (type: string, customProps: DataAnalyticsCustomProps) => void }}
 */
const useAnalytics: (hookCustomProps?: DataAnalyticsCustomProps) => DataAnalyticsMethods = (
  hookCustomProps
) => {
  const { plugins } = useContext(DataAnalyticsContext);
  const { component, customProps: componentCustomProps } = useContext(
    DataAnalyticsComponentContext
  );

  return useMemo(() => {
    const registerInteraction: DataAnalyticsRegisterInteractionAction = (
      target,
      action,
      customProps?
    ) => {
      registerEventInternal(
        DataAnalyticsEvents.INTERACTION,
        customProps,
        undefined,
        target,
        action
      );
    };

    const registerClick = <T extends Element>(event: React.MouseEvent<T, MouseEvent>) => {
      const dataAnalyticsAttributes =
        event && event.currentTarget && extractDataAnalyticAttributes(event.currentTarget);
      registerEventInternal(DataAnalyticsEvents.CLICK, undefined, dataAnalyticsAttributes);
    };

    const registerEvent: DataAnalyticsRegisterEventAction = (type, customProps) => {
      registerEventInternal(type, customProps);
    };

    const registerEventInternal: DataAnalyticsRegisterEventInternal = (
      eventType,
      customProps?,
      dataAnalyticsAttributes?,
      action?,
      target?
    ) => {
      plugins &&
        plugins.forEach((p) =>
          p.push({
            event: eventType,
            target,
            action,
            component,
            ...dataAnalyticsAttributes,
            ...hookCustomProps,
            ...customProps,
            ...componentCustomProps,
          })
        );
    };

    return { registerInteraction, registerClick, registerEvent };
  }, [plugins, component, componentCustomProps, hookCustomProps]);
};

export { useAnalytics };

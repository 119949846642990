import React, { memo, useState } from "react";
import { Button } from "../../..";
import { createBemFn } from "../../../utilities/bem";
import { phoneNumberPattern } from "../../../utilities/validations";
import { MobileNumberField } from "../../Form/MobileNumberField/MobileNumberField";
import { Spinner } from "../../Spinner/Spinner";
import { AccountToast } from "../SharedComponents/AccountToast";
import "./AccountSecurityForm.scss";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { PasswordRequired } from "../SharedComponents/PasswordRequired";
import { GenericError } from "./GenericError";

const bem = createBemFn("lxs-account-security-form");

export enum AccountSecurityFormType {
    PasswordOnly,
    MobileOnly,
    PasswordAndMobile,
}

interface AccountSecurityFormProps {
    email: string;
    type: AccountSecurityFormType;
    onSubmit?: (mobile?: string, password?: string) => Promise<void>;
}

export const strings = {
    passwordText: "new password ",
    mobileText: "mobile number ",
    passwordAndMobileText: "mobile number and new password ",
    mobileNotUnique: "Mobile number already exists",
    genericErrorTitle: "Something went wrong",
    buttonText: "Okay",
    loginErrorToast: "There is a problem when logging into your account. We apologise for any inconvenience.",
    loginErrorInstructions: "Please call the Lexus Customer Assistance Centre for more information.",
    loginErrorPhone: "1800 023 009",
} as const;

export const mapErrorToSecurityFormType = (types: Set<AuthenticationErrorType>) => {
    const phoneRequired =
        types.has(AuthenticationErrorType.PhoneMissing) || types.has(AuthenticationErrorType.PhoneNotUnique);
    const passwordRequired = types.has(AuthenticationErrorType.PasswordNotMatchingPolicy);
    if (phoneRequired && passwordRequired) {
        return AccountSecurityFormType.PasswordAndMobile;
    } else if (phoneRequired) {
        return AccountSecurityFormType.MobileOnly;
    } else if (passwordRequired) {
        return AccountSecurityFormType.PasswordOnly;
    }
    return undefined;
};

const AccountSecurityForm: React.FC<AccountSecurityFormProps> = memo(({ email, type, onSubmit }) => {
    const [mobile, setMobile] = useState("");
    const [mobileError, setMobileError] = useState<string>();
    const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>();
    const [error, setError] = useState<Error>();
    const [inputErrorToast, setInputErrorToast] = useState(false);
    const [processing, setProcessing] = useState(false);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (onSubmit) {
            setProcessing(true);
            onSubmit(mobile, newPasswordRepeat)
                .then(() => setError(undefined))
                .catch((reason) => {
                    setProcessing(false);
                    if (reason instanceof AuthenticationError) {
                        if (reason.hasType(AuthenticationErrorType.PhoneNotUnique)) {
                            setMobileError(strings.mobileNotUnique);
                            return;
                        }
                        if (reason.hasType(AuthenticationErrorType.InvalidInput)) {
                            setInputErrorToast(true);
                            return;
                        }
                    }
                    setError(reason instanceof Error ? reason : new Error());
                });
        }
    };

    const setIfValidPhoneNumber = React.useCallback(({ value }: { value: string }) => {
        setMobileError(undefined);
        setMobile(phoneNumberPattern.test(value) ? value : "");
    }, []);

    const handleToastClose = React.useCallback(() => {
        setError(undefined);
        setInputErrorToast(false);
    }, []);

    const submitDisabled =
        type === AccountSecurityFormType.PasswordAndMobile
            ? !newPasswordRepeat || !mobile || !!mobileError
            : type === AccountSecurityFormType.PasswordOnly
            ? !newPasswordRepeat
            : !mobile || !!mobileError;

    const requiredText =
        type === AccountSecurityFormType.PasswordAndMobile
            ? strings.passwordAndMobileText
            : type === AccountSecurityFormType.PasswordOnly
            ? strings.passwordText
            : strings.mobileText;

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>Account Security Upgrade</h2>
            <p>
                At Lexus, your account security is important to us.&nbsp; That's why we've upgraded security on all your
                accounts.
            </p>
            <p>
                We now require a {requiredText} for your account <strong>{email}</strong>.
            </p>
            {(type === AccountSecurityFormType.MobileOnly || type === AccountSecurityFormType.PasswordAndMobile) && (
                <MobileNumberField
                    setPhoneInput={setIfValidPhoneNumber}
                    className={bem("text-field")}
                    externalError={mobileError}
                />
            )}
            {(type === AccountSecurityFormType.PasswordOnly || type === AccountSecurityFormType.PasswordAndMobile) && (
                <PasswordRequired setPassword={setNewPasswordRepeat} blockedPasswords={[]} />
            )}
            {error && <GenericError isOpen={!!error} error={error} onClose={handleToastClose} />}
            {inputErrorToast && (
                <AccountToast
                    title={strings.genericErrorTitle}
                    message={strings.loginErrorToast}
                    isOpen={true}
                    buttonText={strings.buttonText}
                    onClose={handleToastClose}
                    secondMessage={strings.loginErrorInstructions}
                    phoneNumber={strings.loginErrorPhone}
                />
            )}
            <Button className={bem("button")} disabled={submitDisabled || processing} type="submit">
                Continue
            </Button>
            {processing && <Spinner />}
        </form>
    );
});

export { AccountSecurityForm };

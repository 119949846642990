import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGTickCircle: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.5 92C71.629 92 92 71.629 92 46.5C92 21.371 71.629 1 46.5 1C21.371 1 1 21.371 1 46.5C1 71.629 21.371 92 46.5 92Z"
            stroke={color}
            strokeWidth="2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.2196 46.6415C28.7738 47.0699 28.7738 47.7546 29.2196 48.183L39.6619 58.2171C40.1206 58.6578 40.8732 58.6578 41.3319 58.2171L63.9569 36.4765C64.4027 36.0482 64.4027 35.3634 63.9569 34.935C63.4983 34.4943 62.7456 34.4943 62.287 34.935L40.4969 55.8732L30.8895 46.6415C30.4309 46.2008 29.6782 46.2008 29.2196 46.6415Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGTickCircle = React.memo(SVGTickCircle);

export { SVGTickCircle };

import { useEffect, useState } from "react";

function useCssPropertyValue(elementRef: React.RefObject<HTMLElement>, property: string): string {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const styles = window.getComputedStyle(element);
      const propertyValue = styles.getPropertyValue(property);
      setValue(propertyValue);
    }
  }, [elementRef, property]);

  return value;
}
export { useCssPropertyValue };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGCheckMark: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3694 9.45787L12.4987 26.2455L2.62927 16.9566L5.37073 14.0438L12.5013 20.7549L27.6307 6.54248L30.3694 9.45787Z"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGCheckMark = memo(SVGCheckMark);

export { SVGCheckMark };

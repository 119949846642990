import { GuestAccountType } from "./enums";

type GetOrUpdateDetails = (jwtToken: string, detailsToUpdate: Partial<LXS.GuestDetails>) => Promise<LXS.GuestDetails>;

class Guest {
    private _email: string;
    private _idToken: string;
    private _refreshToken?: string;
    private _type: GuestAccountType;
    private _keepSignedIn: boolean;
    private _cachedDetails: LXS.GuestDetails | undefined;
    private _getOrUpdateDetailsAsync: GetOrUpdateDetails;

    public constructor(
        email: string,
        idToken: string,
        refreshToken: string | undefined,
        type: GuestAccountType,
        keepSignedIn: boolean,
        getOrUpdateDetailsAsync: GetOrUpdateDetails,
    ) {
        this._email = email;
        this._idToken = idToken;
        this._refreshToken = refreshToken;
        this._type = type;
        this._keepSignedIn = keepSignedIn;
        this._getOrUpdateDetailsAsync = getOrUpdateDetailsAsync;
    }

    public get email() {
        return this._email;
    }

    public get idToken() {
        return this._idToken;
    }

    public get refreshToken() {
        return this._refreshToken;
    }

    public get type() {
        return this._type;
    }

    public get keepSignedIn() {
        return this._keepSignedIn;
    }

    /**
     * Updates guest details and return the latest values. If @param detailsToUpdate is not defined
     * the details may be returned from cache, to avoid cache pass empty object for @param detailsToUpdate
     */
    public getOrUpdateDetailsAsync = async (detailsToUpdate?: Partial<LXS.GuestDetails>): Promise<LXS.GuestDetails> => {
        if (detailsToUpdate || !this._cachedDetails) {
            this._cachedDetails = await this._getOrUpdateDetailsAsync(this.idToken, detailsToUpdate ?? {});
        }

        return this._cachedDetails;
    };
}

export { Guest };

import cn from "classnames";
import { CSSProperties } from "react";

import styles from "./Stack.module.scss";
import { SpacingScale, mapSpacingToCSSVar } from "./utils";

export interface StackProps<T> {
  children: React.ReactNode;
  component?: string | React.ComponentType<T>;
  spacing?: SpacingScale;
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  alignItems?: "stretch" | "flex-start" | "center" | "flex-end" | "baseline";
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  className?: string;
}

/**
 * Stack is a layout component that helps create components quickly. Stack is essentially a flexbox container with a set amount of gap between all elements within.
 * Spacing scale has both a static and dynamic scales:
 *
 * Static: least, even-less, less, etc
 *
 * Dynamic: T-shirt sizing (xs, sm, md, etc)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Stack: React.FC<StackProps<any>> = ({
  children,
  component: Component = "div",
  spacing = "default",
  flexWrap,
  direction,
  alignItems,
  justifyContent,
  className,
  ...rest
}) => {
  const spacingVar = mapSpacingToCSSVar(spacing);

  const stackStyles: CSSProperties = {
    flexWrap,
    flexDirection: direction,
    alignItems,
    justifyContent,
    gap: `var(${spacingVar})`,
    display: "flex",
  };

  return (
    <Component style={stackStyles} {...rest} className={cn(styles.stack, className)}>
      {children}
    </Component>
  );
};

export default Stack;

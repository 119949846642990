import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGQuestionMark: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C24.822 0 32 7.178 32 16C32 24.822 24.822 32 16 32C7.178 32 0 24.822 0 16C0 7.178 7.178 0 16 0ZM16 30C23.72 30 30 23.72 30 16C30 8.28 23.72 2 16 2C8.28 2 2 8.28 2 16C2 23.72 8.28 30 16 30ZM15.4 21.4C16.3942 21.4 17.2 22.2059 17.2 23.2C17.2 24.1941 16.3942 25 15.4 25C14.4059 25 13.6 24.1941 13.6 23.2C13.6 22.2059 14.4059 21.4 15.4 21.4ZM15.76 7C18.9412 7 21.52 9.05764 21.52 12.04C21.52 13.12 20.8 14.92 19.36 15.64C17.92 16.36 17.2 17.3271 17.2 18.52V19.96H13.6V18.88C13.6 16.4314 15.76 14.92 16.48 14.2C17.92 12.76 17.92 12.5602 17.92 12.04C17.92 10.8471 16.9529 9.87999 15.76 9.87999C14.5671 9.87999 13.6 10.8471 13.6 12.04V13.12H10V12.4C10 9.41766 12.5789 7 15.76 7Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGQuestionMark = React.memo(SVGQuestionMark);

export { SVGQuestionMark };

import { SitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import Layout from "Components/Layout/Layout";
import MobileAddPaymentCardIFrame from "Components/MobileAddPaymentCardIFrame";
import PopTart from "Components/PopTart/PopTart";
import LexusComponentFactory from "Components/sitecore/LexusComponentFactory/LexusComponentFactory";
import Spinner from "Components/Spinner/Spinner";
import { RouteSection, routeString } from "Helpers/routes";
import { useSettingsPromise } from "Hooks/usePromiseState";
import Account from "Pages/Account/Account";
import { allAccountRoutes } from "Pages/Account/accountScreens";
import ChangePasswordPage from "Pages/ChangePasswordPage/ChangePasswordPage";
import SignOut from "Pages/SignOut/SignOut";
import * as React from "react";
import { Suspense } from "react";
import Media from "react-media";
import { Redirect, Route, Switch } from "react-router-dom";
import { breakpointNumeric } from "../../constants";
import { BreakpointContext } from "../../context/BreakpointContext";
import MaintenanceErrorToast from "../../toast/MaintenanceErrorToast/MaintenanceErrorToast";
import AccountStatusGuard from "../AccountStatusGuard/AccountStatusGuard";
import AuthenticationGuard from "../AuthenticationGuard/AuthenticationGuard";
import ErrorGuard from "../ErrorGuard/ErrorGuard";
import PageRoutes from "../PageRoutes/PageRoutes";
import ToastRoutes from "../ToastRoutes/ToastRoutes";

const Routes: React.FC = () => {
    const hasOutage = useSettingsPromise(
        settings => settings.general.webHasOutage && !!settings.general.webOutageMessage
    );

    return (
        <SitecoreContext componentFactory={LexusComponentFactory as any}>
            <Suspense fallback={<Spinner />}>
                <Switch>
                    {hasOutage && (
                        <>
                            <Route
                                path={routeString(RouteSection.MaintenanceError)}
                                render={() => (
                                    <>
                                        <Layout />
                                        <MaintenanceErrorToast />
                                    </>
                                )}
                            />
                            <Redirect to={routeString(RouteSection.MaintenanceError)} />
                        </>
                    )}
                    <Route
                        path={routeString(RouteSection.MobileAddPaymentCardIFrame, RouteSection.TokenParamOptional)}
                        component={MobileAddPaymentCardIFrame}
                    />
                    <AuthenticationGuard>
                        <PopTart />
                        <Switch>
                            <Route path={routeString(RouteSection.ChangePassword)} component={ChangePasswordPage} />
                            <Route path={allAccountRoutes} component={Account} />
                            <Route path={routeString(RouteSection.SignOut)} component={SignOut} />
                            <Route
                                render={() => (
                                    <ErrorGuard>
                                        <AccountStatusGuard>
                                            <Media query={{ maxWidth: breakpointNumeric - 1 }}>
                                                {isMobile => (
                                                    <BreakpointContext.Provider value={isMobile}>
                                                        <PageRoutes />
                                                    </BreakpointContext.Provider>
                                                )}
                                            </Media>
                                        </AccountStatusGuard>
                                    </ErrorGuard>
                                )}
                            />
                        </Switch>
                        <Route path={`/${RouteSection.WildcardPath}/${RouteSection.Notify}`} component={ToastRoutes} />
                    </AuthenticationGuard>
                </Switch>
            </Suspense>
        </SitecoreContext>
    );
};

export default Routes;

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGPerson: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.91304 25.7084C8.45992 28.354 12.0377 30 16 30C19.9623 30 23.5401 28.354 26.0869 25.7085C26.0829 24.2623 25.0947 23.1722 23.3918 22.6466L15.9964 21.256L8.68466 22.577C6.98523 23.0155 5.91304 24.1991 5.91304 25.6522V25.7084ZM4.31596 23.7153C4.97441 22.2242 6.39629 21.1028 8.25664 20.6247L16.0034 19.2223L23.8152 20.6911L23.9178 20.7161C25.6901 21.2478 27.0343 22.3009 27.6741 23.7303C29.1439 21.5152 30 18.8576 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.8511 2.85228 21.5032 4.31596 23.7153ZM16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32ZM21.3328 11.1432C21.3102 11.2669 21.3102 11.2669 21.2875 11.3894C21.2296 11.7004 21.1677 12.0206 21.1059 12.3325C21.0688 12.5197 21.0398 12.6629 21.0291 12.7124C20.5342 15.5994 18.6393 17.6522 16 17.6522C13.3789 17.6522 11.4745 15.5664 10.9769 12.7444C10.9602 12.6629 10.9312 12.5197 10.8941 12.3325C10.8323 12.0206 10.7704 11.7004 10.7125 11.3894C10.6898 11.2669 10.6898 11.2669 10.6672 11.1432C10.5164 10.3128 10.4348 9.77169 10.4348 9.47826V9.38043C10.4348 9.33152 10.4348 9.33152 10.4351 9.2563C10.515 6.22105 13.0007 3.83377 16 3.91304H16.0978C16.1467 3.91304 16.1467 3.91304 16.222 3.91339C19.2536 3.99317 21.6389 6.47312 21.5651 9.49386C21.5623 9.79068 21.481 10.3273 21.3328 11.1432ZM19.5652 9.47826L19.5656 9.45195C19.616 7.53607 18.1058 5.96366 16.1957 5.91304H16.0978C16.0489 5.91304 16.0489 5.91304 15.9737 5.9127C14.0578 5.86228 12.4854 7.37241 12.4348 9.28261V9.47826C12.4348 9.59578 12.5125 10.1116 12.635 10.7857C12.6568 10.9049 12.6568 10.9049 12.6788 11.0236C12.7351 11.3261 12.7955 11.6387 12.8559 11.9434C12.8921 12.126 12.9202 12.2651 12.9413 12.3697C13.2911 14.3519 14.4783 15.6522 16 15.6522C17.5446 15.6522 18.7129 14.3865 19.0639 12.3425C19.0798 12.2651 19.1079 12.126 19.1441 11.9434C19.2045 11.6387 19.2649 11.3261 19.3212 11.0236C19.3432 10.9049 19.3432 10.9049 19.365 10.7857C19.4875 10.1116 19.5652 9.59578 19.5652 9.47826Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGPerson = React.memo(SVGPerson);

export { SVGPerson };

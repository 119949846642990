import cn from "classnames";

import { SVGScrollIndicatorAnimated } from "@/components/SVGIcon/animated/SVGScrollIndicatorAnimated";
import { SVGVerticalScroll } from "@/components/SVGIcon/static/SVGVerticalScroll";

import styles from "./ScrollIndicator.module.scss";

type ScrollIndicatorProps = {
  isVisible?: boolean;
  onClick?: () => void;
  swipeIconOnMobile?: boolean;
};

const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({
  isVisible = true,
  onClick,
  swipeIconOnMobile = false,
}) => {
  return (
    <div className={cn(styles.scrollIndicator, { [styles.scrollShow]: isVisible })}>
      <button type="button" className={styles.scrollLink} onClick={onClick}>
        {!!swipeIconOnMobile && (
          <div className={styles.swipeIcon}>
            <SVGVerticalScroll width={29} height={30} />
            Swipe to view more
          </div>
        )}
        <SVGScrollIndicatorAnimated height={40} width={25} />
      </button>
      <div className={styles.scrollGradient} />
    </div>
  );
};

ScrollIndicator.displayName = "ScrollIndicator";

export { ScrollIndicator };

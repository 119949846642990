import styles from "./FormInputBorder.module.scss";

type FormInputBorderProps = React.HTMLProps<HTMLDivElement>;

/**
 * FormInputBorder component offers border and focus states with predefined styles. For error states, please pass `aria-invalid="true"`
 * This component should only be used for `form elements` and should be used in conjunction with the `FormContainer`
 * component.
 */
export const FormInputBorder: React.FC<FormInputBorderProps> = ({ children, ...props }) => {
  return (
    <div className={styles.formInputBorder} {...props}>
      {children}
    </div>
  );
};

import isChromatic from "chromatic/isChromatic";
import { ComponentType } from "react";

/*
 * This is a HOC that swaps out a component for a fallback component when running in Chromatic.
 * This is useful for components that are not supported by Chromatic, such as the <canvas> element.
 */
export function withSwapOnChromatic<T extends object>(
  WrappedComponent: ComponentType<T>,
  FallbackComponent?: ComponentType<T>
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithSwapOnChromatic = (props: T) => {
    return isChromatic() ? (
      FallbackComponent ? (
        <FallbackComponent {...props} />
      ) : null
    ) : (
      <WrappedComponent {...props} />
    );
  };

  ComponentWithSwapOnChromatic.displayName = `withSwapOnChromatic(${displayName})`;

  return ComponentWithSwapOnChromatic;
}

export const splitHalf = (text: string) => {
    const trimmedText = text.trim();
    const splitterPos = trimmedText.indexOf(" ");
    const line1 = splitterPos > -1 ? trimmedText.substr(0, splitterPos) : trimmedText;
    const line2 = splitterPos > -1 ? trimmedText.substr(splitterPos).trim() : undefined;
    return { line1, line2 };
};

export const replaceNewline = (str?: string, linebreak = "<br/>") =>
    (str && str.replace(/(?:\r\n|\n\r|\r|\n)/g, linebreak)) || str;

export const sanitizeText = (str?: string) => (str && str.replace(/(?:<)/g, "&lt;").replace(/(?:>)/g, "&gt;")) || str;

export const multilineTextToSafeHTML = (str?: string) => replaceNewline(sanitizeText(str));

export const convertToHTMLId = (str?: string) => (str && str.replace(/([^A-Za-z0-9-_.:])/g, "")) || str;

export const convertToPascalStr = (str?: string) =>
    (str && str.replace(/\w+/g, (str) => str[0].toUpperCase() + str.slice(1).toLowerCase())) || str;

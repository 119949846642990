import { useEffect, useState } from "react";

import { loadDynamicScript } from "../utils/scriptLoader";

export enum Status {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

/**
 * 'useScript' hook can be used to load any dynamic script.
 * It will trigger the render cycle of a react component on script load or error.
 */
export const useScript = (src: string, uniqueId: string) => {
  const [status, setStatus] = useState(Status.LOADING);

  useEffect(
    () =>
      loadDynamicScript({
        url: src,
        uniqueId: uniqueId,
        onLoad: () => setStatus(Status.LOADED),
        onError: () => setStatus(Status.ERROR),
      }),
    [src, uniqueId]
  );

  return status;
};

import "../../../icons/index.scss";
import "../../../icons/load-icons.scss";
import "./TextField.scss";

import * as React from "react";

import { SVGCheckMark, SVGSearch } from "../../SVGIcons/icons/";
import { lxColor, lxColorTransparent } from "../../../colors/variables";

import { FieldBelow } from "../FieldBelow/FieldBelow";
import NotchBorderAndLabel from "../NotchBorderAndLabel/NotchBorderAndLabel";
import classnames from "classnames";
import { lxsFormPaddingHorizontal } from "../variables";
import { useIsFocused } from "../helpers";

interface TextFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
    value?: string;
    label?: string;
    isValid?: boolean;
    validText?: string;
    instructions?: string;
    showSuccessTick?: boolean;
    showSearch?: boolean;
    isError?: boolean;
    errorText?: React.ReactNode;
    clear?: () => void;
    maxLetterCount?: number;
    onChange?: (value: string) => void;
}

const TextFieldInternal = (
    {
        value = "",
        type = "text",
        pattern,
        onChange,
        label,
        clear,
        showSuccessTick,
        showSearch,
        maxLetterCount,
        isError,
        errorText,
        isValid,
        validText,
        instructions,
        className,
        onBlur,
        onFocus,
        ...remainingProps
    }: TextFieldProps,
    ref: React.Ref<HTMLInputElement>,
) => {
    // tickSize matches $tick-size in ./TextField.scss
    const tickSize = 16;
    const searchOpacity = 0.5;

    const onChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange],
    );

    const { isFocused, setFocus, setBlur } = useIsFocused();

    const clearRef = React.useRef<HTMLLabelElement>(null);
    const internalInputRef = React.useRef<HTMLInputElement>(null);
    const inputRef = ref ? (ref as React.RefObject<HTMLInputElement>) : internalInputRef;

    const [clearWidth, setClearWidth] = React.useState<number>(0);
    React.useEffect(() => {
        if (clear && clearRef.current) {
            if (inputRef.current) {
                setClearWidth(clearRef.current.clientWidth);
            }
        }
    }, []);

    const isActive = value.length > 0 || isFocused;

    const horizontalMultiplier = 3;
    const inputStyle = {
        paddingRight:
            clear && (showSuccessTick || showSearch) && isValid
                ? `${lxsFormPaddingHorizontal * horizontalMultiplier + clearWidth + tickSize}px`
                : clear
                ? `${lxsFormPaddingHorizontal * 2 + clearWidth}px`
                : (showSuccessTick || showSearch) && isValid
                ? `${lxsFormPaddingHorizontal * 2 + tickSize}px`
                : `${lxsFormPaddingHorizontal}px`,
    };

    const mergedOnBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (e) => {
            onBlur?.(e);
            setBlur();
        },
        [onBlur],
    );

    const mergedOnFocus: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (e) => {
            onFocus?.(e);
            setFocus();
        },
        [onFocus],
    );

    return (
        <div
            className={classnames(
                "lxs-text-field",
                { "lxs-text-field--active": isActive },
                { "lxs-text-field--empty": value.length === 0 },
                { "lxs-text-field--error": isError },
                { "lxs-text-field--valid": isValid },
                { "lxs-text-field--show-success-tick": showSuccessTick },
                { "lxs-text-field--show-search": showSearch },
                className,
            )}
        >
            <div className="lxs-text-field__input">
                <NotchBorderAndLabel {...{ isFocused, isActive, isValid, isError, label }} />
                {clear && (
                    <label ref={clearRef} onClick={clear} className="lxs-text-field__clear">
                        clear
                    </label>
                )}
                <SVGCheckMark
                    width={tickSize}
                    height={tickSize}
                    color={lxColor("success")}
                    className="lxs-text-field__tick"
                />
                <SVGSearch
                    width={tickSize}
                    height={tickSize}
                    color={lxColorTransparent("smoke", "default", searchOpacity)}
                    className="lxs-text-field__search"
                />
                <input
                    ref={inputRef}
                    style={inputStyle}
                    onBlur={mergedOnBlur}
                    onFocus={mergedOnFocus}
                    type={type}
                    pattern={pattern}
                    className="lxs-text-field__input-element"
                    value={value}
                    onChange={onChangeHandler}
                    {...remainingProps}
                />
            </div>
            <FieldBelow
                text={{ validText, isValid, errorText, isError, instructions }}
                letterCount={maxLetterCount !== undefined ? { max: maxLetterCount, current: value.length } : undefined}
            />
        </div>
    );
};

const TextField = React.forwardRef(TextFieldInternal);

export { TextField };

import * as React from "react";
import { MainMenuDesktopNavListItem } from "./MainMenuDesktopNavListItem";
import "./MainMenuDesktopNav.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { isNotGetInTouchItem } from "../../shared";

const MainMenuDesktopNav: React.FC = () => {
    const { menuData } = React.useContext(PrimaryNavContext);
    const menuItems = menuData && menuData.menuItems;

    if (!menuItems || menuItems.length === 0) {
        return null;
    }

    return (
        <ul className="lxs-main-menu-desktop-nav">
            {menuItems.filter(isNotGetInTouchItem).map((item, index) => (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <MainMenuDesktopNavListItem key={`${item.link!.value.text}-${index}`} item={item} />
            ))}
        </ul>
    );
};

export { MainMenuDesktopNav };

import * as React from "react";

import {
    attemptLoginThunk,
    encoreTierSelector,
    salesforceAccountIdSelector,
    verifyVehicleThunk,
} from "ReduxSlices/user";

import { AccountManager } from "lexus-style-guide/Components/Account/Providers/AccountManager";
import { ChangePassword } from "lexus-style-guide/Components/Account/ChangePassword/ChangePassword";
import { UserEncoreTiers } from "Helpers/users";
import { isNativeAppSelector } from "ReduxSlices/general";
import { pushToGTMTermsAndConditionFormSubmitted } from "Helpers/gtm";
import { redirectToApp } from "Helpers/routes";
import { useSelector } from "react-redux";
import { useSettingsPromise } from "Hooks/usePromiseState";
import { useThunkDispatch } from "Hooks/thunk";

interface Props {
    email: string;
    onExit: () => void;
}

const ChangePasswordWizard: React.FC<Props> = ({ email, onExit }) => {
    const generalSettings = useSettingsPromise(settings => settings.general);
    const dispatch = useThunkDispatch();
    const isApp = useSelector(isNativeAppSelector);
    const handleLogin = React.useCallback(
        (guest: LXS.Guest) => {
            AccountManager.current.guest = guest;
            if (isApp) {
                redirectToApp(guest);
            } else {
                dispatch(attemptLoginThunk)
                    .then(onExit)
                    .catch(reason => {
                        throw reason;
                    });
            }
        },
        [isApp, onExit]
    );

    const encoreTier = useSelector(encoreTierSelector);
    const accountId = useSelector(salesforceAccountIdSelector);

    const handleTnCs = React.useCallback(() => {
        pushToGTMTermsAndConditionFormSubmitted(encoreTier || UserEncoreTiers.NONE, accountId ?? "");
        dispatch(verifyVehicleThunk);
    }, [encoreTier]);

    return generalSettings ? (
        <ChangePassword
            onLogin={handleLogin}
            onExit={undefined}
            termsAndPrivacyConfig={generalSettings}
            onTermsAccepted={handleTnCs}
            email={email}
        />
    ) : null;
};

export { ChangePasswordWizard };

import { noop } from "lodash";

export const isPassiveSupported = () => {
    if (typeof document === "undefined") {
        return false;
    }

    let supportsPassive = false;
    const testListener = noop;
    document.addEventListener("test", testListener, {
        get passive() {
            supportsPassive = true;
            return false;
        },
    });
    document.removeEventListener("test", testListener);
    return supportsPassive;
};

import { OpeningHours } from "@/types/dealer";

enum DaysNames {
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
}

const daysInWeek = Object.keys(DaysNames).filter(
  (key) => typeof DaysNames[key as keyof typeof DaysNames] !== "string"
);

const getCurrentDay = () => daysInWeek[new Date().getDay()];

const convert24HrTo12HrTimeString = (timeString?: string) => {
  if (!timeString) {
    return "";
  }
  const H = +timeString.slice(0, 2);
  const h = H % 12 || 12;
  const hourSuffix = H < 12 || H === 24 ? "am" : "pm";
  return `${h}${timeString.slice(2, 5)}${hourSuffix}`;
};

const openingHoursDisplayString = (operatingHour: OpeningHours) =>
  operatingHour.isClosed
    ? "Closed"
    : convert24HrTo12HrTimeString(operatingHour.openingTime) +
      " - " +
      convert24HrTo12HrTimeString(operatingHour.closingTime);

export { getCurrentDay, openingHoursDisplayString, DaysNames };

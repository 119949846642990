import * as React from "react";
import "./SocialMedia.scss";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import { PrimaryNavContext } from "../PrimaryNav";
import classNames from "classnames";
import { MainMenuDesktopContext } from "../MainMenu/desktop/MainMenuDesktop";
import { useGTM } from "../../GTM/useGTM";

const SocialMedia: React.FC = () => {
    const { socialMediaLinks } = React.useContext(PrimaryNavContext);
    const sliceEnd = 5;

    return (
        <>
            {socialMediaLinks &&
                socialMediaLinks
                    .slice(0, sliceEnd)
                    .map((mediaLink) => <SocialMediaLink key={mediaLink.iconCss} mediaLink={mediaLink} />)}
        </>
    );
};

const SocialMediaLink: React.FC<{ mediaLink: LXS.SocialMediaEntry }> = ({ mediaLink }) => {
    const { setMainMenuNavListItemActive } = React.useContext(MainMenuDesktopContext);
    const [active, setActive] = React.useState(false);
    const { gtmClick } = useGTM({ linktype: "SocialMediaLink" });

    const activate = () => {
        setActive(true);
        setMainMenuNavListItemActive && setMainMenuNavListItemActive(true);
    };

    const deactivate = () => {
        setActive(false);
        setMainMenuNavListItemActive && setMainMenuNavListItemActive(false);
    };

    if (!mediaLink || !mediaLink.link) {
        return null;
    }

    return (
        <Link
            className={classNames("lxs-social-media__link", { "lxs-social-media__link--active": active })}
            field={mediaLink.link}
            onMouseEnter={activate}
            onMouseLeave={deactivate}
            onClick={gtmClick}
            data-gtm-action={mediaLink.link.value && mediaLink.link.value.href}
        >
            <span className={classNames("lxs-social-media__link-icon", mediaLink.iconCss)} />
        </Link>
    );
};

export { SocialMedia };

import * as React from "react";
import { RouteComponentProps } from "react-router";
import Toast from "../../components/Toast/Toast";
import BookVehicleClearProgressErrorCard from "../../components/errorCards/BookVehicleClearProgressErrorCard/BookVehicleClearProgressErrorCard";

const BookVehicleClearProgressToast: React.FC<RouteComponentProps> = props => (
    <Toast>
        <BookVehicleClearProgressErrorCard destinationRouteSection={props.match.params["toastParam"]} {...props} />
    </Toast>
);

export default BookVehicleClearProgressToast;

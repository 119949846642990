import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGStar: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m30.698 10.838-9.55-1.362L16.883.992c-.33-.656-1.403-.656-1.733 0l-4.266 8.484-9.55 1.362a.97.97 0 0 0-.533 1.66l6.898 6.6L6.07 28.41a.969.969 0 0 0 .382.948.965.965 0 0 0 1.02.08l8.543-4.408 8.545 4.409a.963.963 0 0 0 1.02-.08.97.97 0 0 0 .381-.948l-1.628-9.313 6.899-6.6a.97.97 0 0 0-.534-1.661Zm-8.08 7.216a.968.968 0 0 0-.285.868l1.38 7.9-7.252-3.743a.978.978 0 0 0-.89 0l-7.252 3.742 1.38-7.899a.968.968 0 0 0-.285-.868l-5.831-5.577 8.075-1.152a.972.972 0 0 0 .73-.525l3.627-7.214 3.629 7.214c.142.283.416.48.729.525l8.075 1.152-5.83 5.577Z"
      clipRule="evenodd"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGStar = memo(SVGStar);

export { SVGStar };

import cn from "classnames";
import { forwardRef } from "react";
import {
  Input as InputPrimitive,
  InputProps as InputPrimitiveProps,
  TextFieldProps as TextFieldPrimitiveProps,
} from "react-aria-components";

import { SVGCheckMark } from "@/components/SVGIcon/static/SVGCheckMark";
import { SVGCrossCircle } from "@/components/SVGIcon/static/SVGCrossCircle";
import { SVGWarning } from "@/components/SVGIcon/static/SVGWarning";

import styles from "./TextInput.module.scss";

const iconProps = {
  "aria-hidden": true,
  focusable: false,
  role: "img",
};

export type TextInputProps = Omit<InputPrimitiveProps, "onChange"> & {
  isInvalid?: TextFieldPrimitiveProps["isInvalid"];
  clearable?: boolean;
  onClearInput?: () => void;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ isInvalid, clearable, onClearInput, ...props }, ref) => {
    const showValidationIcon = isInvalid !== undefined;
    const validationIcon = isInvalid ? (
      <SVGWarning className={cn(styles.icon, styles.inValidIcon)} {...iconProps} />
    ) : (
      <SVGCheckMark className={cn(styles.icon, styles.validIcon)} {...iconProps} />
    );

    const showClearButton = clearable && !showValidationIcon;

    return (
      <div className={styles.textInput}>
        <InputPrimitive
          {...props}
          ref={ref}
          className={cn(styles.input, { [styles.isValid]: isInvalid === false })}
        />

        {showValidationIcon && (
          <div className={styles.iconContainer}>{showValidationIcon && validationIcon}</div>
        )}

        {showClearButton && (
          <div className={cn(styles.iconContainer, styles.clearIconButton)}>
            <button onClick={onClearInput} className={cn(styles.icon)} aria-label="clear">
              <SVGCrossCircle className={cn(styles.icon, styles.clearIcon)} />
            </button>
          </div>
        )}
      </div>
    );
  }
);

TextInput.displayName = "TextInput";

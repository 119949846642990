import * as React from "react";
import { defaultIconColor } from "../constants";

const defaultWidth = 22;
const defaultHeight = 28;

let SVGLocatorPin: React.FC<LXS.SVGStaticProps> = ({
    width = defaultWidth,
    height = defaultHeight,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M11.2495 12.25C13.1317 12.25 14.6717 10.675 14.6717 8.75C14.6717 6.825 13.1317 5.25 11.2495 5.25C9.3673 5.25 7.8273 6.825 7.8273 8.75C7.8273 10.675 9.3673 12.25 11.2495 12.25ZM11.2495 7C12.1906 7 12.9606 7.7875 12.9606 8.75C12.9606 9.7125 12.1906 10.5 11.2495 10.5C10.3084 10.5 9.53841 9.7125 9.53841 8.75C9.53841 7.7875 10.3084 7 11.2495 7Z"
            fill={color}
        />
        <path
            d="M0.983032 22.75C0.983032 25.725 5.43192 28 11.2497 28C17.0675 28 21.5164 25.725 21.5164 22.75C21.5164 20.65 19.3775 18.9 15.9553 18.025C18.5219 13.825 19.8053 10.675 19.8053 8.75C19.8053 3.9375 15.9553 0 11.2497 0C6.54414 0 2.69414 3.9375 2.69414 8.75C2.69414 10.675 3.97748 13.825 6.54414 18.025C3.12192 18.9 0.983032 20.65 0.983032 22.75ZM11.2497 1.75C15.0141 1.75 18.0941 4.9 18.0941 8.75C18.0941 10.5 16.5541 13.825 13.9019 18.2C12.8753 19.8625 11.9341 21.2625 11.3353 22.1375C10.7364 21.2625 9.7097 19.8625 8.76859 18.2C5.94525 13.825 4.40525 10.5 4.40525 8.75C4.40525 4.9 7.48525 1.75 11.2497 1.75ZM7.48525 19.6C9.11081 22.1375 10.4797 24.0625 10.5653 24.0625C10.7364 24.325 10.993 24.4125 11.2497 24.4125C11.5064 24.4125 11.763 24.325 11.9341 24.0625C11.9341 24.0625 13.3886 22.05 15.0141 19.6C18.1797 20.3 19.8053 21.6125 19.8053 22.6625C19.8053 24.325 16.1264 26.1625 11.2497 26.1625C6.37303 26.1625 2.69414 24.4125 2.69414 22.75C2.69414 21.7 4.3197 20.3 7.48525 19.6Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGLocatorPin = React.memo(SVGLocatorPin);

export { SVGLocatorPin };

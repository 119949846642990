import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGInstagram: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M12.001 0h-.004c3.26 0 3.668.014 4.948.072 1.277.059 2.15.261 2.915.558.79.306 1.457.716 2.125 1.384A5.89 5.89 0 0 1 23.37 4.14c.295.763.497 1.635.557 2.912C23.985 8.332 24 8.741 24 12c0 3.259-.015 3.667-.073 4.947-.06 1.277-.262 2.15-.557 2.913a5.89 5.89 0 0 1-1.385 2.125 5.872 5.872 0 0 1-2.124 1.385c-.764.297-1.637.499-2.914.557-1.28.059-1.688.073-4.947.073-3.26 0-3.668-.014-4.948-.073-1.277-.058-2.15-.26-2.913-.557a5.876 5.876 0 0 1-2.125-1.385A5.88 5.88 0 0 1 .63 19.859c-.296-.763-.499-1.635-.557-2.912C.015 15.667 0 15.259 0 12c0-3.259.015-3.668.072-4.948.058-1.277.26-2.15.558-2.913a5.89 5.89 0 0 1 1.385-2.125A5.876 5.876 0 0 1 4.14.63C4.904.333 5.776.131 7.053.073 8.333.014 8.743 0 12.001 0Zm-1.076 2.163V2.16c-2.237.002-2.666.017-3.773.067-1.17.054-1.805.249-2.228.414-.56.218-.96.478-1.38.898-.42.42-.68.82-.898 1.38-.164.423-.36 1.058-.413 2.228-.058 1.265-.07 1.643-.07 4.847 0 3.205.012 3.585.07 4.85.053 1.17.249 1.805.413 2.227.218.56.478.96.898 1.38.42.42.82.68 1.38.897.423.164 1.058.36 2.228.414 1.265.057 1.645.07 4.85.07 3.203 0 3.583-.013 4.848-.07 1.17-.054 1.806-.249 2.228-.414a3.71 3.71 0 0 0 1.38-.897c.42-.42.68-.819.897-1.379.165-.422.36-1.057.413-2.227.058-1.265.07-1.645.07-4.848 0-3.202-.012-3.582-.07-4.847-.053-1.17-.248-1.805-.413-2.228a3.724 3.724 0 0 0-.898-1.38 3.71 3.71 0 0 0-1.379-.897c-.423-.164-1.058-.36-2.228-.413-1.265-.058-1.645-.07-4.849-.07h-1.076Zm7.427 1.656a1.443 1.443 0 1 1 0 2.885 1.443 1.443 0 0 1 0-2.885ZM12.162 6a6.162 6.162 0 1 1-.001 12.324A6.162 6.162 0 0 1 12.163 6Zm.096 2.25a4.008 4.008 0 1 0 0 8.016 4.008 4.008 0 0 0 0-8.016Z" />
  </svg>

  /* eslint-enable max-len */
);
SVGInstagram = memo(SVGInstagram);

export { SVGInstagram };

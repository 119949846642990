import cn from "classnames";

import styles from "./BarGraph.module.scss";
import { getOpacityList, getTotalValue } from "./barGraphUtils";
import { Typography } from "../Typography/Typography";

export type BarGraphItem = { text: string; value: number };

export type BarGraphProps = {
  /**
   * List of the items or labels.
   * The item value must be unique.
   * The summation of the values should be equal to 100 or if not,
   * the component will scale them to become equal to `100`.
   *
   * e.g. If the numbers are provided as `[100, 80, 20]` with a summation of `200`,
   * the component will change them to `[50, 40, 10]` with a summation of `100`.
   */
  items: BarGraphItem[];
};

/**
 * Bar Graph component generate a bar with legend.
 */
export const BarGraph: React.FC<BarGraphProps> = ({ items }) => {
  const opacityList = getOpacityList(items);

  return (
    <div>
      <div className={styles.dynamicPriceBarContainer} role="dynamicPriceBar">
        <div className={styles.chart}>
          {items.map((item, index) => (
            <span
              key={`${item.value}-${index}`}
              className={styles.block}
              style={{
                opacity: `${opacityList[index]}`,
                width: `${(item.value / getTotalValue(items)) * 100}%`,
              }}
            />
          ))}
        </div>
      </div>
      <div className={styles.legendContainer} role="legend">
        <ul className={styles.legend}>
          {items.map((item, index) => (
            <li key={`${item.value}-${index}`} role="legendItem" className={styles.legendItem}>
              <div
                className={cn(styles.indicator, {
                  [styles.emptyStateIndicator]: getTotalValue(items) === 0,
                })}
                style={{ opacity: `${opacityList[index]}` }}
              />
              <Typography variant="b1" className={styles.label}>
                {item.text}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

import classnames from "classnames";
import * as React from "react";
import "./FieldBelow.scss";

interface FieldBelowProps {
    text?: {
        validText?: string;
        isValid?: boolean;
        errorText?: React.ReactNode;
        isError?: boolean;
        instructions?: string;
    };
    letterCount?: {
        max: number;
        current: number;
    };
}

export const FieldBelow = ({ text, letterCount }: FieldBelowProps) => {
    const showText =
        text !== undefined &&
        ((text.isValid && text.validText !== undefined) ||
            (text.isError && text.errorText !== undefined) ||
            text.instructions !== undefined);
    const showLetterCount = letterCount !== undefined;

    return !showText && !showLetterCount ? null : (
        <div className="lxs-field-below">
            {text !== undefined && (
                <span
                    className={classnames("lxs-field-below__message", {
                        "lxs-field-below__message--show": text.isValid || text.isError || text.instructions,
                    })}
                >
                    {text.isValid ? text.validText : text.isError ? text.errorText : text.instructions || ""}
                </span>
            )}
            {letterCount !== undefined && (
                <span
                    className={classnames("lxs-field-below__letter-count", {
                        "lxs-field-below__letter-count--show": letterCount.current > 0,
                    })}
                >
                    {letterCount.current} / {letterCount.max}
                </span>
            )}
        </div>
    );
};

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGLocatorPin: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      d="M18.29 30.35c-5.133 0-9.058-2.006-9.058-4.63 0-1.853 1.887-3.397 4.906-4.17-2.264-3.704-3.396-6.483-3.396-8.181 0-4.246 3.396-7.72 7.547-7.72s7.548 3.474 7.548 7.72c0 1.698-1.132 4.477-3.397 8.182 3.02.772 4.906 2.316 4.906 4.168 0 2.625-3.924 4.631-9.057 4.631Zm6.037-16.981c0-3.396-2.717-6.175-6.038-6.175-3.32 0-6.038 2.779-6.038 6.175 0 1.544 1.359 4.477 3.85 8.336.675 1.194 1.4 2.233 1.932 2.995l.332.479a88.846 88.846 0 0 0 2.264-3.474c2.34-3.86 3.698-6.792 3.698-8.336Zm-6.642 13.508c-.075 0-1.282-1.698-2.717-3.937-2.792.618-4.226 1.853-4.226 2.78 0 1.466 3.245 3.01 7.547 3.01s7.548-1.622 7.548-3.088c0-.926-1.434-2.084-4.227-2.702-1.434 2.162-2.717 3.937-2.717 3.937-.15.232-.377.309-.604.309-.226 0-.453-.077-.604-.309Zm3.623-13.508c0 1.698-1.358 3.088-3.018 3.088-1.66 0-3.02-1.39-3.02-3.088 0-1.698 1.36-3.088 3.02-3.088s3.018 1.39 3.018 3.088Zm-1.509 0c0-.85-.68-1.544-1.51-1.544s-1.509.695-1.509 1.544c0 .85.68 1.544 1.51 1.544s1.509-.695 1.509-1.544Z"
      clipRule="evenodd"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGLocatorPin = memo(SVGLocatorPin);

export { SVGLocatorPin };

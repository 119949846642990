import classnames from "classnames";
import * as React from "react";
import "../../../icons/index.scss";
import "../../../icons/load-icons.scss";
import { FieldBelow } from "../FieldBelow/FieldBelow";
import { useIsFocused } from "../helpers";
import NotchBorderAndLabel from "../NotchBorderAndLabel/NotchBorderAndLabel";
import "./TextAreaField.scss";
import TextareaAutosize from "react-autosize-textarea";

interface TextAreaFieldProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "value" | "onChange"> {
    value?: string;
    label?: string;
    isValid?: boolean;
    validText?: string;
    isError?: boolean;
    errorText?: string;
    maxLetterCount?: number;
    onChange?: (value: string) => void;
}

const TextAreaFieldInternal = (
    {
        value = "",
        onChange,
        label,
        maxLetterCount,
        isError,
        errorText,
        isValid,
        validText,
        className,
        onBlur,
        ...remainingProps
    }: TextAreaFieldProps,
    ref: React.Ref<HTMLTextAreaElement>,
) => {
    const onChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange],
    );

    const { isFocused, setFocus, setBlur } = useIsFocused();
    const isActive = value.length > 0 || isFocused;

    const mergedOnBlur: React.FocusEventHandler<HTMLTextAreaElement> = React.useCallback(
        (e) => {
            if (onBlur) {
                onBlur(e);
            }
            setBlur();
        },
        [onBlur],
    );

    return (
        <div
            className={classnames(
                "lxs-textarea-field",
                { "lxs-textarea-field--active": isActive },
                { "lxs-textarea-field--empty": value.length === 0 },
                { "lxs-textarea-field--error": isError },
                { "lxs-textarea-field--valid": isValid },
                className,
            )}
        >
            <div className="lxs-textarea-field__input">
                <NotchBorderAndLabel {...{ isFocused, isActive, isValid, isError, label }} />
                <TextareaAutosize
                    ref={ref}
                    onBlur={mergedOnBlur}
                    onFocus={setFocus}
                    className="lxs-textarea-field__input-element"
                    value={value}
                    onChange={onChangeHandler}
                    {...remainingProps}
                />
            </div>
            <FieldBelow
                text={{ validText, isValid, errorText, isError }}
                letterCount={maxLetterCount !== undefined ? { max: maxLetterCount, current: value.length } : undefined}
            />
        </div>
    );
};

const TextAreaField = React.forwardRef(TextAreaFieldInternal);

export { TextAreaField };

import cn from "classnames";
import { useContext } from "react";

import { AuthenticationContext } from "@/utils/Authentication/authenticationContext";

import styles from "./PrimaryNavNavbarItemAuthenticated.module.scss";
import { Level1MenuItemData } from "./types/types";

interface PrimaryNavItemEncoreProps {
  isMenuItemActive?: boolean;
  menuItem: Level1MenuItemData;
  onClick: (level1MenuItem: Level1MenuItemData) => void;
}

const PrimaryNavNavbarItemAuthenticated: React.FC<PrimaryNavItemEncoreProps> = ({
  menuItem,
  onClick,
  isMenuItemActive,
}) => {
  const { current } = useContext(AuthenticationContext);
  const currentUser = current.user;

  return (
    <button
      className={cn(styles.navbarItemAuthenticated, { [styles.isActive]: isMenuItemActive })}
      onClick={() => onClick(menuItem)}
    >
      <span className={styles.label}>
        {currentUser?.givenName?.charAt(0)}
        {currentUser?.familyName?.charAt(0)}
      </span>
    </button>
  );
};

export { PrimaryNavNavbarItemAuthenticated };

import jump from "jump.js";
import throttle from "lodash/throttle";
import { useEffect, useRef, useState } from "react";

import { ScrollDirection, WheelScrollConstants } from "./types";
import type {
  ActiveSlideIndex,
  CurrentScrollDirection,
  HandleScrollToNextSlide,
  IsMouseWheelMoving,
  IsProgressPastLastSlide,
  IsScrollAnimating,
  MouseWheelTimer,
  RegisterInteraction,
  SetActiveSlideIndex,
  SetIsMouseWheelMoving,
  SetIsProgressPastLastSlide,
  SetIsScrollAnimating,
  SetMouseWheelTimer,
  SlideNavItems,
  SlideRefs,
} from "./types";
import {
  DataAnalyticsEventActions,
  DataAnalyticsEventTargets,
} from "../../utils/DataAnalytics/models/DataAnalyticsDefinitions";

export const useBodyOverflowHidden = () => {
  const mutationObserver = useRef<MutationObserver | null>(null);
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);

  useEffect(() => {
    mutationObserver.current = new MutationObserver((mutations) => {
      mutations.forEach(function () {
        setIsOverflowHidden(document.body.style.overflow === "hidden");
      });
    });

    if (document?.body) {
      mutationObserver.current.observe(document.body, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    return () => {
      if (mutationObserver.current) {
        mutationObserver.current.disconnect();
      }
    };
  }, []);

  return isOverflowHidden;
};

export const useScrollToNextSlide = ({
  activeSlideIndex,
  isProgressPastLastSlide,
  registerInteraction,
  scrollDirection,
  setIsProgressPastLastSlide,
  setIsScrollAnimating,
  slideNavItems,
  slideRefs,
}: {
  activeSlideIndex: ActiveSlideIndex;
  isProgressPastLastSlide: IsProgressPastLastSlide;
  registerInteraction: RegisterInteraction;
  scrollDirection: CurrentScrollDirection;
  setIsProgressPastLastSlide: SetIsProgressPastLastSlide;
  setIsScrollAnimating: SetIsScrollAnimating;
  slideNavItems: SlideNavItems;
  slideRefs: SlideRefs;
}) => {
  const handleScrollToNextSlide = () => {
    let targetIndex =
      scrollDirection.current === ScrollDirection.up ? activeSlideIndex - 1 : activeSlideIndex + 1;

    // If the user is scrolling up and the progress bar is past the end, set the target index to the last slide
    if (isProgressPastLastSlide && scrollDirection.current === ScrollDirection.up) {
      setIsProgressPastLastSlide(false);

      targetIndex = slideNavItems.length - 1;
    }

    // If the target index is out of bounds, return early
    if (targetIndex < 0) {
      return;
    }

    if (targetIndex > slideNavItems.length - 1) {
      const isAtBottomOfPage =
        window.innerHeight + window.pageYOffset >= document.documentElement.scrollHeight;

      // Scroll to the bottom of the page
      if (!isAtBottomOfPage) {
        setIsScrollAnimating(true);
        setIsProgressPastLastSlide(true);

        jump(document.body.scrollHeight, {
          callback: () => setIsScrollAnimating(false),
        });
      }
    }

    // Scroll to the target slide
    if (targetIndex >= 0 && targetIndex <= slideNavItems.length - 1) {
      setIsScrollAnimating(true);

      // Scroll to the target slide
      jump(slideRefs.current[targetIndex], {
        callback: () => setIsScrollAnimating(false),
      });
    }

    // Track analytics
    registerInteraction(
      DataAnalyticsEventTargets.VerticalScroll,
      DataAnalyticsEventActions.VerticalScrollUpdate,
      {
        action: `Showing ${isProgressPastLastSlide ? "Footer" : activeSlideIndex + 1} of ${
          slideRefs.current.length
        }`,
        currentTile: activeSlideIndex + 1,
        totalTiles: slideRefs.current.length,
        direction: scrollDirection.current === ScrollDirection.up ? -1 : 1,
      }
    );
  };

  return handleScrollToNextSlide;
};

export const useMouseWheelScroll = ({
  isMouseWheelMoving,
  isScrollAnimating,
  mouseWheelTimer,
  handleScrollToNextSlide,
  scrollDirection,
  setIsMouseWheelMoving,
  setMouseWheelTimer,
}: {
  isMouseWheelMoving: IsMouseWheelMoving;
  isScrollAnimating: IsScrollAnimating;
  mouseWheelTimer: MouseWheelTimer;
  handleScrollToNextSlide: HandleScrollToNextSlide;
  scrollDirection: CurrentScrollDirection;
  setIsMouseWheelMoving: SetIsMouseWheelMoving;
  setMouseWheelTimer: SetMouseWheelTimer;
}) => {
  const handleMouseWheelScroll = (e: WheelEvent) => {
    const isScrollAmountOverThreshold = Math.abs(e.deltaY) > WheelScrollConstants.threshold;

    if (isScrollAnimating || isMouseWheelMoving || !isScrollAmountOverThreshold) {
      e.preventDefault();
      return;
    }

    setIsMouseWheelMoving(true);

    if (mouseWheelTimer) {
      clearTimeout(mouseWheelTimer);
    }

    // Update the scroll direction
    scrollDirection.current = e.deltaY > 0 ? ScrollDirection.down : ScrollDirection.up;

    handleScrollToNextSlide();

    setMouseWheelTimer(
      setTimeout(() => setIsMouseWheelMoving(false), WheelScrollConstants.eventLapseTimeInMs)
    );
  };

  return handleMouseWheelScroll;
};

export const useKeyDown = ({
  isMouseWheelMoving,
  isScrollAnimating,
  handleScrollToNextSlide,
  scrollDirection,
}: {
  isMouseWheelMoving: IsMouseWheelMoving;
  isScrollAnimating: IsScrollAnimating;
  handleScrollToNextSlide: HandleScrollToNextSlide;
  scrollDirection: CurrentScrollDirection;
}) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    const isDownKey = e.key === "Down" || e.key === "ArrowDown";
    const isUpKey = e.key === "Up" || e.key === "ArrowUp";

    if (isScrollAnimating || isMouseWheelMoving || (!isDownKey && !isUpKey)) {
      return;
    }

    // Update the scroll direction
    scrollDirection.current = isDownKey ? ScrollDirection.down : ScrollDirection.up;

    handleScrollToNextSlide();
  };

  return handleKeyDown;
};

export const useScroll = ({
  setActiveSlideIndex,
  slideRefs,
}: {
  setActiveSlideIndex: SetActiveSlideIndex;
  slideRefs: SlideRefs;
}) => {
  const getElementPercentageInVerticalView = (element: HTMLElement) => {
    // Get the relevant measurements and positions
    const viewportHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const elementOffsetTop = element.offsetTop;
    const elementHeight = element.offsetHeight;

    // Calculate percentage of the element that's been seen
    const distance = scrollTop + viewportHeight - elementOffsetTop;
    const percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100));

    return percentage;
  };

  const handleScroll = throttle(() => {
    slideRefs.current.forEach((slideEl, index) => {
      const percentageInVerticalView = getElementPercentageInVerticalView(slideEl);

      // If the slide is more than 25% in the viewport, set it as the active slide
      if (percentageInVerticalView > 25) {
        setActiveSlideIndex(index);
      }
    });
  }, 100);

  return handleScroll;
};

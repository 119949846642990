import { useEffect, useState } from "react";

import { CurrentUser } from "@/types/auth";

import { AuthenticationConfig } from "../authentication.types";

export const useLoginButtonVisible = (
  user?: CurrentUser,
  config?: AuthenticationConfig
): boolean => {
  const [isLoginButtonVisible, setIsLoginButtonVisible] = useState(true);

  useEffect(() => {
    const isAuthenticated = !!user;
    setIsLoginButtonVisible(!config?.isAuthenticationHost || isAuthenticated);
  }, [user, config?.isAuthenticationHost]);

  return isLoginButtonVisible;
};

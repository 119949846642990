import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGModelsMenu: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg viewBox="0 0 25 25" id="el_bKU2T1WB6" height={height} width={width} {...rest}>
        <g id="el_URHPR8IxoN" fillRule="evenodd">
            <g id="el_fiJb0_03so_an_d4g09L0GY" data-animator-group="true" data-animator-type="2">
                <rect id="el_fiJb0_03so" x="5" y="5" width="3" height="3" fill={color} />
            </g>
            <rect id="el_szQRTcTe3W" x="5" y="11" width="3" height="3" fill={color} />
            <g id="el_6cM7c6walG_an_MXnL7DVSt" data-animator-group="true" data-animator-type="0">
                <rect id="el_6cM7c6walG" x="11" y="5" width="3" height="3" fill={color} />
            </g>
            <g id="el_MGRlQzwtu7_an_vSLHRnglV" data-animator-group="true" data-animator-type="2">
                <rect id="el_MGRlQzwtu7" x="11" y="11" width="3" height="3" fill={color} />
            </g>
            <rect id="el_1ciB9_igbX" x="17" y="5" width="3" height="3" fill={color} />
            <g id="el_FTdRbssb7z_an_xpo5OPl9L" data-animator-group="true" data-animator-type="0">
                <rect id="el_FTdRbssb7z" x="17" y="11" width="3" height="3" fill={color} />
            </g>
            <rect id="el_8bwC8GKAPo" x="17" y="17" width="3" height="3" fill={color} />
            <g id="el_WTI4cQAjR7_an_wrxlBx6XZ" data-animator-group="true" data-animator-type="2">
                <rect id="el_WTI4cQAjR7" x="5" y="17" width="3" height="3" fill={color} />
            </g>
            <g id="el_mDqDRE3p2q_an_2Vo32rqcj" data-animator-group="true" data-animator-type="0">
                <rect id="el_mDqDRE3p2q" x="11" y="17" width="3" height="3" fill={color} />
            </g>
        </g>
    </svg>
);
SVGModelsMenu = React.memo(SVGModelsMenu);

export { SVGModelsMenu };

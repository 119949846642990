import * as React from "react";
import jump from "jump.js";
import "./VerticalScroll.scss";
import { ScrollIndicator } from "../ScrollIndicator/ScrollIndicator";
import { isExperienceEditorActive } from "../../utilities/Sitecore/SitecoreUtilities";
import { VerticalScrollDirection } from "./Constants";
import { hidePager, showPager, changeActivePager } from "./PagerHelpers";
import {
    addObservers,
    attachEventListeners,
    removeEventListeners,
    removeObservers,
    findVisibleTileFromIntersectionEntry,
    isScrollingHorizontally,
} from "./Utils";
import { delayScroll } from "./ScrollHelper";
import { useGTM } from "../GTM/useGTM";

//5% less from complete visibility
const distanceFromCompleteVisibility = 0.05;

const pagerFractionDigits = 3;

const VerticalScroll = (props: LXS.VerticalScrollProps) => {
    const imageContainerRef = React.useRef<HTMLDivElement>(null);
    const listRef = React.useRef<HTMLUListElement>(null);
    const pagersRef = React.useRef<LXS.PagerRefType>({});
    const { gtmEvent } = useGTM({ component: "VerticalScroll" });
    const [displayScroll, setDisplayScroll] = React.useState(false);
    const wheelScrollRef = React.useRef<LXS.WheelScrollRefType>({
        timeout: undefined,
        scrolling: false,
        lastDeltaY: 0,
    });
    const verticalScrollRef = React.useRef<LXS.VerticalScrollRefType>({
        lastScrollIndex: 0,
        isInViewport: true,
        isScrolling: false,
        tiles: [],
    });
    let verticalScrollObservers: IntersectionObserver[] = [];

    const setLastScrollIndex = (index: number) => {
        const difference = index - verticalScrollRef.current.lastScrollIndex;
        verticalScrollRef.current.lastScrollIndex = index;
        const isFooter = index >= verticalScrollRef.current.tiles.length;
        const action = `Showing ${index + 1}${isFooter ? "(Footer)" : ""} of ${verticalScrollRef.current.tiles.length}`;
        gtmEvent("Scroll", {
            target: "VerticalScroll",
            action,
            currentTile: index + 1,
            totalTiles: verticalScrollRef.current.tiles.length,
            direction: difference > 0 ? 1 : difference < 0 ? -1 : 0,
        });
        setDisplayScroll(index === 0 && verticalScrollRef.current.tiles.length > 1);
    };

    const scrollToTileIndex = (index: number, direction: VerticalScrollDirection) => {
        const currentRef = verticalScrollRef.current;
        if (listRef.current && !currentRef.isScrolling) {
            if (index < currentRef.tiles.length && index > -1) {
                currentRef.isScrolling = true;
                jumpToElement(currentRef.tiles[index], index);
                changeActivePager(
                    pagersRef.current,
                    index,
                    currentRef.lastScrollIndex,
                    currentRef.tiles.length,
                    direction,
                );
            }
        }
    };

    const jumpToElement = (elementOrOffset: Element | number, index: number) => {
        jump &&
            jump(elementOrOffset, {
                callback: () => {
                    verticalScrollRef.current.isScrolling = false;
                    setLastScrollIndex(index); //just to be safe - as last scroll index can be manipulated from observers
                },
            });
    };

    const handleEventByScrollDirection = (event: UIEvent, direction: VerticalScrollDirection) => {
        if (isExperienceEditorActive()) {
            return;
        }
        const currentRef = verticalScrollRef.current;
        if (currentRef.tiles && currentRef.tiles.length > 0 && currentRef.isInViewport) {
            if (direction === VerticalScrollDirection.DOWN) {
                if (currentRef.lastScrollIndex < currentRef.tiles.length - 1 && event.cancelable) {
                    event.preventDefault();
                    scrollToTileIndex(currentRef.lastScrollIndex + 1, direction);
                } else if (currentRef.lastScrollIndex === currentRef.tiles.length - 1 && !currentRef.isScrolling) {
                    event.preventDefault();
                    currentRef.isScrolling = true;

                    //jump to the footer OR in other words, scroll the last tile up
                    jumpToElement(currentRef.tiles[currentRef.lastScrollIndex].clientHeight, currentRef.tiles.length);
                }
            } else if (currentRef.lastScrollIndex > 0 && event.cancelable) {
                event.preventDefault();
                scrollToTileIndex(currentRef.lastScrollIndex - 1, direction);
            }
        }
    };

    const isModalOpen = () => typeof document !== "undefined" && document.body.classList.contains("lxs-modal-open");

    const handleKeyDown = (event: KeyboardEvent) => {
        const isDown = () => event.key === "Down" || event.key === "ArrowDown";
        const isUp = () => event.key === "Up" || event.key === "ArrowUp";
        if (isDown() || isUp()) {
            handleEventByScrollDirection(event, isDown() ? VerticalScrollDirection.DOWN : VerticalScrollDirection.UP);
        }
    };

    const handleMouseWheel = (event: WheelEvent) => {
        if (
            isExperienceEditorActive() ||
            isScrollingHorizontally(event) ||
            isModalOpen() ||
            !verticalScrollRef.current.isInViewport
        ) {
            return;
        }
        delayScroll(
            event.deltaY,
            wheelScrollRef.current,
            verticalScrollRef.current.isScrolling,
            (direction, shouldScroll) =>
                shouldScroll ? handleEventByScrollDirection(event, direction) : event.preventDefault(),
        );
    };

    const visibleTileObserverCallback = (visibleTile: Element | null) => {
        const currentRef = verticalScrollRef.current;
        if (visibleTile && !currentRef.isScrolling) {
            const idx = currentRef.tiles.indexOf(visibleTile);
            if (currentRef.lastScrollIndex < currentRef.tiles.length && currentRef.lastScrollIndex !== idx) {
                changeActivePager(
                    pagersRef.current,
                    idx,
                    currentRef.lastScrollIndex,
                    currentRef.tiles.length,
                    idx < currentRef.lastScrollIndex ? VerticalScrollDirection.UP : VerticalScrollDirection.DOWN,
                );
                setLastScrollIndex(idx);
            }
        }
    };

    const pagerClicked = (event: React.MouseEvent<HTMLLIElement>) => {
        const currentIndex = parseInt(event.currentTarget.getAttribute("data-target") || "0");
        verticalScrollRef.current.lastScrollIndex !== currentIndex &&
            scrollToTileIndex(
                currentIndex,
                currentIndex < verticalScrollRef.current.lastScrollIndex
                    ? VerticalScrollDirection.UP
                    : VerticalScrollDirection.DOWN,
            );
    };

    const scrollIndicatorClicked = () => {
        scrollToTileIndex(1, VerticalScrollDirection.DOWN);
    };

    const attachObservers = () => {
        const tiles = verticalScrollRef.current.tiles;
        const pagerObserverThreshold =
            tiles.length > 0 &&
            imageContainerRef.current &&
            imageContainerRef.current.offsetHeight > 0 &&
            typeof window !== "undefined"
                ? window.innerHeight / imageContainerRef.current.offsetHeight - distanceFromCompleteVisibility
                : 1;
        return addObservers([
            {
                callback: (entries: IntersectionObserverEntry[]) =>
                    (verticalScrollRef.current.isInViewport = entries[0].isIntersecting),
                threshold: 0,
                target: imageContainerRef.current,
                targets: null,
            },
            {
                callback: (entries: IntersectionObserverEntry[]) => {
                    const visibleTile = findVisibleTileFromIntersectionEntry(entries);
                    visibleTileObserverCallback(visibleTile);
                },
                threshold: 0.6,
                targets: tiles,
                target: null,
            },
            {
                callback: (entries: IntersectionObserverEntry[]) => {
                    const shouldShowPager =
                        entries[0].isIntersecting &&
                        parseFloat(entries[0].intersectionRatio.toFixed(pagerFractionDigits)) > pagerObserverThreshold;
                    shouldShowPager ? showPager(listRef.current) : hidePager(listRef.current);
                },
                threshold: pagerObserverThreshold,
                target: imageContainerRef.current,
                targets: null,
            },
        ]);
    };

    React.useEffect(() => {
        verticalScrollRef.current.tiles = imageContainerRef.current
            ? Array.from(imageContainerRef.current.children).filter((elem) => elem.clientHeight > 0)
            : [];
        setDisplayScroll(verticalScrollRef.current.tiles.length > 1);
        verticalScrollObservers = attachObservers();
        attachEventListeners(handleKeyDown, handleMouseWheel);
        return () => {
            removeObservers(verticalScrollObservers);
            removeEventListeners(handleKeyDown, handleMouseWheel);
        };
    }, []);

    React.useEffect(() => {
        if (listRef.current) {
            pagersRef.current.buttons = listRef.current.getElementsByTagName("li");
        }
        changeActivePager(
            pagersRef.current,
            0,
            -1,
            verticalScrollRef.current.tiles.length,
            VerticalScrollDirection.DOWN,
        );
    }, [listRef.current]);

    return (
        <div className="lxs-vertical-scroll__container">
            <div className="lxs-vertical-scroll__pager-wrapper">
                <ul className="lxs-vertical-scroll__pager" ref={listRef}>
                    {verticalScrollRef.current.tiles.map((_, index) => {
                        const gridStyle = {
                            gridColumn: 1,
                            msGridColumn: 1,
                            gridRow: index + 1,
                            msGridRow: index + 1,
                        };

                        return (
                            <li
                                onClick={pagerClicked}
                                key={`li-${index}`}
                                className="lxs-vertical-scroll__pager__button"
                                data-target={index}
                                style={gridStyle}
                            />
                        );
                    })}
                </ul>
            </div>
            <div ref={imageContainerRef}>{props.children}</div>
            {displayScroll && <ScrollIndicator onClick={scrollIndicatorClicked} />}
        </div>
    );
};

export { VerticalScroll };

import cn from "classnames";

import { iconRenderer } from "@/components/SVGIcon/static/SVGSelector";
import { Tab, TabTitle, TabList, TabPanel } from "@/components/Tab/Tab";
import { DealerLocation } from "@/types/dealer";

import styles from "./DealerBranch.module.scss";
import { DealerService } from "../DealerService/DealerService";
export type DealerBranchProps = {
  dealerLocation: DealerLocation;
  branchNumber?: number;
};

const DealerBranch: React.FC<DealerBranchProps> = ({ dealerLocation, branchNumber }) => (
  <Tab>
    <div className={styles.dealerBranchTitle}>
      <div className={styles.dealerBranchLocationPinIconContainer}>
        {iconRenderer("mapAnchor", {
          width: 64,
          height: 64,
          className: styles.dealerBranchLocationPinIcon,
        })}
        {branchNumber && (
          <div className={styles.dealerBranchLocationPinIconNumber}>{branchNumber}</div>
        )}
      </div>
      <div className={styles.dealerBranchTitleText}>
        <span className={styles.dealerBranchLocationName}>{dealerLocation.locationName}</span>
        <TabList>
          {dealerLocation.serviceTypes.map((s) => (
            <TabTitle
              id={`${dealerLocation.branchCode}-${s.name}-title`}
              key={`${dealerLocation.branchCode}-${s.name}-title`}
              className={cn("react-tabs__tab", styles.dealerBranchTabTitle)}
              selectedClassName={styles.dealerBranchTabTitleSelected}
            >
              {s.name}
            </TabTitle>
          ))}
        </TabList>
      </div>
    </div>

    {dealerLocation.serviceTypes.map((s) => (
      <TabPanel key={`${dealerLocation.branchCode}-${s.name}-panel`}>
        <DealerService dealerLocation={dealerLocation} serviceType={s.name} />
      </TabPanel>
    ))}
  </Tab>
);

export { DealerBranch };

import * as React from "react";
import { toggleArrayElement } from "../../../utilities/array";

export const useSelectedTags = (scrollOnSelection = true) => {
    const bodyTypeRef = React.createRef<HTMLDivElement>();
    const [selectedTagIds, setSelectedTagIds] = React.useState<string[]>([]);
    const onTagSelected = (tagId: string) => {
        if (scrollOnSelection) {
            if (bodyTypeRef.current) {
                bodyTypeRef.current.scrollIntoView({ behavior: "smooth" });
            } else {
                if (typeof document !== "undefined") {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            }
        }
        setSelectedTagIds((ids) => (tagId ? toggleArrayElement(ids, tagId) : []));
    };
    return [selectedTagIds, onTagSelected, bodyTypeRef] as const;
};

export const hasAnyMatchingModel = (bodyType: LXS.BodyType, selectedTags: string[]): boolean =>
    bodyType.vehicleModels.some((model) => doesModelHaveAllTags(selectedTags, model));

export const doesModelHaveAllTags = (tags: string[], model: LXS.VehicleModel): boolean =>
    tags.length === 0 || tags.every((tag) => model.tags?.some((modelTag) => modelTag.id === tag));

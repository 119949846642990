import IframeResizer from "iframe-resizer-react";
import { useContext, useState, useEffect } from "react";

import { Button } from "@/components/Button/Button";
import { Responsive } from "@/components/Responsive/Responsive";
import { Typography } from "@/components/Typography/Typography";
import { TypographyGroup } from "@/components/TypographyGroup/TypographyGroup";
import { AuthenticationContext } from "@/utils/Authentication/authenticationContext";

import { PrimaryNavItems } from "./PrimaryNavItems/PrimaryNavItems";
import styles from "./PrimaryNavModalEncore.module.scss";
import { PrimaryNavDesktopLayout } from "../PrimaryNavDesktopLayout";
import { PrimaryNavMobileItemsContainer } from "../PrimaryNavMobileItemsContainer";
import { PrimaryNavMobilePage } from "../PrimaryNavMobilePage";
import { PrimaryNavModalContainer } from "../PrimaryNavModalContainer";
import { PrimaryNavStaticLinks } from "../PrimaryNavStaticLinks/PrimaryNavStaticLinks";
import { EncoreAdvertData, MenuItemTyped, MenuItemWithIconData } from "../types/types";

type PrimaryNavTabEncoreProps = {
  closeButton: React.ReactNode;
  logoutLabel: string;
  menuItems: MenuItemTyped[];
  encoreAdvert: EncoreAdvertData;
  onLogout?: () => void;
};

const PrimaryNavModalEncore: React.FC<PrimaryNavTabEncoreProps> = ({
  closeButton,
  logoutLabel,
  menuItems,
  encoreAdvert,
  onLogout,
}) => {
  const { config, current, doLogout } = useContext(AuthenticationContext);
  const [isClient, setIsClient] = useState(false);
  const isAuthenticated = !!current.user;
  const isLoggingOut = current.isLoggingOut;

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!config || (config.isAuthenticationHost && !isAuthenticated)) {
    return null;
  }

  const primaryNavItems = (
    <PrimaryNavItems items={menuItems} dataTestId="lk-primary-nav-modal-encore-nav-items" />
  );

  const loginIFrame = isClient ? (
    <IframeResizer
      log={false}
      data-testid="lk-primary-nav-modal-encore-login-iframe"
      className={styles.loginFormIFrame}
      heightCalculationMethod="bodyOffset"
      resizeFrom="child"
      scrolling={false}
      autoResize={true}
      minHeight={420}
      src={config.loginFormUrl}
    />
  ) : null;

  const pageContentLarge = isLoggingOut ? null : isAuthenticated ? primaryNavItems : loginIFrame;

  const level2Layout =
    isAuthenticated || !encoreAdvert ? null : (
      <div className={styles.encoreAdvert}>
        <div className={styles.encoreAdvertTextContainer}>
          <TypographyGroup>
            <Typography variant={"h5"} className={styles.encoreAdvertTitle}>
              {encoreAdvert.title}
            </Typography>
            <Typography variant={"b2"}>{encoreAdvert.description}</Typography>
          </TypographyGroup>
          <div className={styles.encoreAdvertAppIconContainer}>
            {encoreAdvert.googlePlayLinkUrl && encoreAdvert.googlePlayImageUrl && (
              <div className={styles.encoreAdvertAppIcon}>
                <a
                  href={encoreAdvert.googlePlayLinkUrl}
                  target={encoreAdvert.googlePlayLinkTarget || "_self"}
                >
                  <img
                    src={encoreAdvert.googlePlayImageUrl}
                    alt={encoreAdvert.googlePlayImageAlt}
                  ></img>
                </a>
              </div>
            )}
            {encoreAdvert.appStoreLinkUrl && encoreAdvert.appStoreImageUrl && (
              <div className={styles.encoreAdvertAppIcon}>
                <a
                  href={encoreAdvert.appStoreLinkUrl}
                  target={encoreAdvert.appStoreLinkTarget || "_self"}
                >
                  <img
                    src={encoreAdvert.appStoreImageUrl}
                    alt={encoreAdvert.appStoreImageAlt}
                  ></img>
                </a>
              </div>
            )}
          </div>
        </div>
        {encoreAdvert.heroImageUrl && (
          <div className={styles.encoreAdvertImageContainer}>
            <img src={encoreAdvert.heroImageUrl} alt={encoreAdvert.heroImageAlt}></img>
          </div>
        )}
        {encoreAdvert.ctaLabel && encoreAdvert.ctaUrl && (
          <div className={styles.encoreAdvertCtaContainer}>
            <Button
              href={encoreAdvert.ctaUrl}
              component="a"
              target={encoreAdvert.ctaTarget || "_self"}
              className={styles.encoreAdvertCta}
              variant="secondary"
              themeVariant="alt"
            >
              {encoreAdvert.ctaLabel}
            </Button>
          </div>
        )}
      </div>
    );

  const pageContentSmall = isLoggingOut ? null : isAuthenticated ? (
    <PrimaryNavMobileItemsContainer parentIsFlexbox={true} fillParentHeight={true}>
      {primaryNavItems}
    </PrimaryNavMobileItemsContainer>
  ) : (
    <div className={styles.encoreSmall}>
      <PrimaryNavModalContainer>{loginIFrame}</PrimaryNavModalContainer>
      {level2Layout}
    </div>
  );

  const logoutLink: MenuItemWithIconData = {
    link: {
      label: logoutLabel,
      url: "",
    },
    icon: "key",
    onClick: () => {
      doLogout();

      if (onLogout) {
        onLogout();
      }
    },
  };

  const footerChildren = isAuthenticated ? (
    <PrimaryNavStaticLinks
      items={[logoutLink]}
      dataTestId="lk-primary-nav-modal-encore-static-links"
    />
  ) : undefined;

  return (
    <>
      <Responsive breakpoint="large">
        <PrimaryNavModalContainer parentIsFlexbox={true} fillParentHeight={true}>
          <PrimaryNavDesktopLayout
            closeButton={closeButton}
            isLevel2LayoutAvailable={true}
            level2LayoutChildren={level2Layout}
            isLevel2LayoutBackgroundSuppressed={true}
            isLevel2LayoutPaddingSuppressed={true}
            level1LayoutChildren={pageContentLarge}
            level1LayoutFooterChildren={footerChildren}
          />
        </PrimaryNavModalContainer>
      </Responsive>
      <Responsive breakpoint="small">
        <PrimaryNavMobilePage closeButton={closeButton} footerChildren={footerChildren}>
          {pageContentSmall}
        </PrimaryNavMobilePage>
      </Responsive>
    </>
  );
};

export { PrimaryNavModalEncore };

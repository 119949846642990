import * as React from "react";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";
import ErrorCard from "../ErrorCard/ErrorCard";
import { RouteComponentProps, withRouter } from "react-router";
import { useSettingsPromise } from "Hooks/usePromiseState";

const MaintenanceErrorCard: React.FC<RouteComponentProps> = ({ history }) => {
    const generalSettings = useSettingsPromise(settings => settings.general);

    return (
        <ErrorCard title={generalSettings?.webOutageTitle}>
            <ErrorCardFinePrint>{generalSettings?.webOutageMessage}</ErrorCardFinePrint>
        </ErrorCard>
    );
};

export default withRouter(MaintenanceErrorCard);

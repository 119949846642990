import cn from "classnames";
import { Children, isValidElement, useState } from "react";
import {
  withGroupedChildren,
  type WithGroupedChildrenComponent,
  type GroupedChildrenProps,
} from "react-grouped-children";

import { Oriented } from "@/components/Oriented/Oriented";
import { ToolTip, ToolTipPosition, ToolTipState } from "@/components/ToolTip/ToolTip";

import styles from "./DynamicMedia.module.scss";
import { getCustomCssProps } from "./helpers";
import { ContainerCustomProps, ToolTipLocation } from "./sharedTypes";
import { VideoOrImage, VideoOrImageProps } from "./VideoOrImage";

type VideoTypes = "Landscape" | "Portrait";
type VideoProps = { [P in `video${VideoTypes}`]?: VideoOrImageProps["videoInformation"] } & {
  toolTipLocation?: ToolTipLocation;
};

const shouldTooltipBeDisplayed = (tooltip?: React.ReactNode | React.ReactNode[] | null) =>
  Children.count(tooltip) > 0;

export type DynamicMediaProps = VideoProps &
  ContainerCustomProps & {
    /**
     * Use to render all variants of component. Helpful in CMS editing mode
     */
    showAll?: boolean;
    showContentGradient?: boolean;
    /**
     * Use for VRT to open the tooltip by default
     */
    toolTipInitialState?: ToolTipState;
  };

const DynamicMediaInternal = ({
  content,
  contentFooter,
  imageLandscape,
  imagePortrait,
  videoLandscape,
  videoPortrait,
  videoLandscapeDisclaimer,
  videoPortraitDisclaimer,
  imageLandscapeDisclaimer,
  imagePortraitDisclaimer,
  showContentGradient,
  showAll,
  toolTipLocation = ToolTipLocation.BOTTOM_RIGHT,
  toolTipInitialState,
  ...rest
}: GroupedChildrenProps<typeof childrenSpec> & DynamicMediaProps) => {
  const [landscapeToolTipContent, setLandscapeToolTipContent] =
    useState<React.ReactNode>(imageLandscapeDisclaimer);
  const [portraitToolTipContent, setPortraitToolTipContent] = useState<React.ReactNode | null>(
    imagePortraitDisclaimer
  );

  const imageLandscapeSingle = Children.toArray(imageLandscape).filter(isValidElement)[0];
  const imagePortraitSingle = Children.toArray(imagePortrait).filter(isValidElement)[0];
  const hasPortrait = !!(imagePortraitSingle || videoPortrait);
  const hasLandscape = !!(imageLandscapeSingle || videoLandscape);

  if (!hasPortrait && !hasLandscape) {
    return null;
  }

  const hasOnlyOne = hasPortrait ? !hasLandscape : hasLandscape;
  const minHeightCSSProperties = getCustomCssProps(rest);

  return (
    <div
      className={cn(styles.dynamicMedia, {
        [styles.aspect]: typeof rest.aspectRatio === "number" && rest.aspectRatio > 0,
      })}
      style={minHeightCSSProperties}
    >
      <div className={styles.container}>
        <div className={styles.background}>
          <Oriented defaultOrientation="portrait" showAll={hasOnlyOne || showAll}>
            {hasPortrait && (
              <Oriented.Portrait>
                <VideoOrImage
                  className={styles.videoOrImage}
                  image={imagePortraitSingle}
                  videoInformation={videoPortrait}
                  onVideoLoaded={() => setPortraitToolTipContent(videoPortraitDisclaimer)}
                />
              </Oriented.Portrait>
            )}
            {hasLandscape && (
              <Oriented.Landscape>
                <VideoOrImage
                  className={styles.videoOrImage}
                  image={imageLandscapeSingle}
                  videoInformation={videoLandscape}
                  onVideoLoaded={() => setLandscapeToolTipContent(videoLandscapeDisclaimer)}
                />
              </Oriented.Landscape>
            )}
          </Oriented>
        </div>

        <div className={styles.contentWrapper}>
          <div
            className={cn(styles.contentInner, {
              [styles.contentInnerWithGradient]: showContentGradient,
            })}
          >
            {content}
          </div>
          <div
            className={cn(styles.toolTipContainer, styles[`${toolTipLocation}ToolTipContainer`])}
          >
            <Oriented defaultOrientation="portrait" showAll={hasOnlyOne || showAll}>
              {hasPortrait && shouldTooltipBeDisplayed(portraitToolTipContent) && (
                <Oriented.Portrait>
                  <ToolTip
                    className={styles.toolTip}
                    position={
                      toolTipLocation === ToolTipLocation.BOTTOM_RIGHT
                        ? ToolTipPosition.LEFT_TOP
                        : ToolTipPosition.LEFT_BOTTOM
                    }
                    addShadow={true}
                    initialState={toolTipInitialState}
                  >
                    {portraitToolTipContent}
                  </ToolTip>
                </Oriented.Portrait>
              )}
              {hasLandscape && shouldTooltipBeDisplayed(landscapeToolTipContent) && (
                <Oriented.Landscape>
                  <ToolTip
                    className={styles.toolTip}
                    position={
                      toolTipLocation === ToolTipLocation.BOTTOM_RIGHT
                        ? ToolTipPosition.LEFT_TOP
                        : ToolTipPosition.LEFT_BOTTOM
                    }
                    addShadow={true}
                    initialState={toolTipInitialState}
                  >
                    {landscapeToolTipContent}
                  </ToolTip>
                </Oriented.Landscape>
              )}
            </Oriented>
          </div>
        </div>

        {contentFooter && <div className={styles.footer}>{contentFooter}</div>}
      </div>
    </div>
  );
};

const childrenSpec = {
  ImageLandscape: null,
  ImagePortrait: null,
  ImageLandscapeDisclaimer: null,
  ImagePortraitDisclaimer: null,
  VideoLandscapeDisclaimer: null,
  VideoPortraitDisclaimer: null,
  Content: null,
  ContentFooter: null,
} as const;

const DynamicMedia: WithGroupedChildrenComponent<typeof childrenSpec, DynamicMediaProps> =
  withGroupedChildren({ childrenSpec })(DynamicMediaInternal);

export { DynamicMedia };

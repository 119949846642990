import * as React from "react";

import { ButtonShade, ButtonStyle, buttonClassName } from "./shared";
import { getSitecoreFieldValue, isExperienceEditorActive } from "../utilities/Sitecore/SitecoreUtilities";

import { Link } from "@sitecore-jss/sitecore-jss-react";
import { useGTM } from "../Components/GTM/useGTM";

let LinkButton: React.FunctionComponent<LXS.Button.LinkButtonProps> = ({
    children,
    history,
    buttonStyle = ButtonStyle.Primary,
    shade = ButtonShade.Dynamic,
    onMouseDown,
    ...rest
}) => {
    const { gtmClick } = useGTM();
    const value =
        getSitecoreFieldValue<LXS.Button.LinkButtonProps, SCJSS.LinkFieldValue>({ ...rest, buttonStyle, shade }) ||
        (rest.href && { href: rest.href }) ||
        (isExperienceEditorActive() ? ({} as SCJSS.LinkFieldValue) : undefined);

    if (!value) {
        return null;
    }

    const passOnProps = {
        ...rest,
        field: rest.field || value,
        className: buttonClassName({ ...rest, buttonStyle, shade }, value.className),
        showLinkTextWithChildrenPresent: false,
    };

    if (history) {
        passOnProps.onClick = (e) => {
            e.preventDefault();
            history && history.push(rest.href || value.href);
        };
    }

    delete passOnProps.selected;
    delete passOnProps.ref;
    delete passOnProps.key;

    return (
        <Link
            data-gtm-target={value.text}
            data-gtm-action={value.href}
            {...(passOnProps as SCJSS.LinkProps)}
            onMouseDown={(e) => {
                gtmClick(e);
                e.preventDefault();
                onMouseDown?.(e);
            }}
        >
            {!(
                (typeof passOnProps.editable === "undefined" || passOnProps.editable) &&
                passOnProps.field &&
                passOnProps.field.editableFirstPart
            ) &&
                (children || value.text)}
        </Link>
    );
};

LinkButton = React.memo(LinkButton);

export { LinkButton };

interface TypedResponse<T> extends Omit<Response, "json"> {
    json(): Promise<T>;
}

export const typedFetch = async <T>(
    input: RequestInfo,
    validator: (value: T) => value is T,
    init?: RequestInit,
): Promise<TypedResponse<T>> => {
    const response = await fetch(input, init);
    const jsonValue = response.body !== null ? await response.json() : null;
    if (validator(jsonValue)) {
        const handler: ProxyHandler<Response> = {
            get: (target, prop, receiver) =>
                prop === "json" ? () => Promise.resolve(jsonValue) : Reflect.get(target, prop, receiver),
        };
        return new Proxy(response, handler);
    } else {
        // eslint-disable-next-line no-console
        console.error("Invalid response type", jsonValue);
        throw new Error("Invalid response type");
    }
};

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGVerticalScroll: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  color,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...rest}>
    <path
      fill={color || "#fff"}
      d="M8.713 5.16a.67.67 0 1 0-1.341 0h1.341Zm-1.341 0v6.357h1.341V5.159H7.372ZM8.713 20.106v-.67H7.372v.67h1.341Zm-1.341 0v4.81h1.341v-4.81H7.372Z"
    />
    <path stroke={color || "#fff"} strokeWidth="1.341" d="m10.997 22.892-3.02 2.024-2.89-2.024" />
    <path
      fill={color || "#fff"}
      fillRule="evenodd"
      d="M17.11 16.615a8.21 8.21 0 0 0-1.881 1.127 1.324 1.324 0 0 0-.475.883c-.036.346.066.692.284.964a1.338 1.338 0 0 0 1.853.228c.853-.676 1.582-.905 2.353-1.147l.002-.001c.706-.221 1.506-.473 2.308-1.057.06-.042.115-.09.166-.14.224-.14 1.253-1.292 1.75-1.857a.89.89 0 0 1 .688-.301l.354.007a.554.554 0 0 0 .542-.397c.198-.684.557-2.11.499-3.284-.055-1.106-.49-2.397-.734-3.047a.569.569 0 0 0-.603-.365l-.44.054a.685.685 0 0 1-.486-.126c-.478-.347-1.77-1.138-3.72-1.21a1.34 1.34 0 0 0-.38-.028l-5.11.344a1.33 1.33 0 0 0-1.163.883 1.318 1.318 0 0 0 .269 1.323 1.33 1.33 0 0 0-1.18.844 1.323 1.323 0 0 0 .307 1.417 1.327 1.327 0 0 0-.954.969 1.319 1.319 0 0 0 .408 1.298l-4.686-.01c-.353.003-.69.145-.938.395a1.319 1.319 0 0 0 .018 1.875c.252.247.592.384.946.382l10.003-.023Zm-3.583 1.35a2.66 2.66 0 0 0 .464 2.462 2.68 2.68 0 0 0 3.714.456l.01-.007.01-.008c.645-.512 1.169-.682 1.922-.92l.038-.011c.699-.22 1.674-.525 2.654-1.237.085-.061.167-.127.244-.198.07-.055.126-.105.159-.135.073-.067.151-.143.227-.22.153-.153.33-.341.509-.534.297-.322.616-.678.86-.954l.146.003a1.896 1.896 0 0 0 1.859-1.365m-12.816 2.668c.053-.17-6.412.015-6.412.015h-.003a2.681 2.681 0 0 1-1.89-.766 2.66 2.66 0 0 1-.033-3.776 2.672 2.672 0 0 1 1.883-.793h.012l2.921.006a2.646 2.646 0 0 1 .617-1.375 2.658 2.658 0 0 1 .886-2.511 2.658 2.658 0 0 1 1.099-2.343 2.672 2.672 0 0 1 1.383-.498l5.112-.345c.208-.013.417-.003.622.031 2.009.097 3.428.84 4.137 1.31l.194-.023a1.913 1.913 0 0 1 2.02 1.224c.25.664.751 2.123.817 3.453.07 1.414-.348 3.028-.55 3.723"
      clipRule="evenodd"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGVerticalScroll = memo(SVGVerticalScroll);

export { SVGVerticalScroll };

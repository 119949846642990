import "./LoginForm.scss";

import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import React, { useCallback, useState } from "react";

import { AccountToast } from "../SharedComponents/AccountToast";
import { Button } from "../../../buttons/Button";
import { Checkbox } from "../../Form/Checkbox/Checkbox";
import { GenericError } from "../SharedComponents/GenericError";
import { Spinner } from "../../Spinner/Spinner";
import { TextField } from "../../Form";
import { createBemFn } from "../../../utilities/bem";
import { isEmailValid } from "../../../utilities/validations";

const bem = createBemFn("lxs-login-form");

interface LoginFormProps {
    title: string;
    keepLoggedInText: string;
    btnText: string;
    onLogin?: (keepSignedIn: boolean, email: string, password: string) => Promise<void>;
    initialEmail?: string;
    enablePermanentSignIn?: boolean;
    onRegistrationRequired?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    registrationHref?: string;
    onForgotRequired?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
    forgotHref?: string;
    guestEmailRef?: React.MutableRefObject<string | undefined>;
}

const strings = {
    requiredField: "This field is required",
    improperEmail: "Please use a valid email address",
    loginError: "Wrong email and password combination",
    genericErrorTitle: "Something went wrong",
    genericErrorMessage: "We can't contact our servers right now. Please try signing in later.",
    lockedErrorMessage:
        "Your account has reached the maximum number of failed login attempts and has been " +
        "temporarily locked. To unlock please reset your password.",
    loginErrorToast: "There is a problem when logging into your account. We apologise for any inconvenience.",
    loginErrorInstructions: "Please call the Lexus Customer Assistance Centre for more information.",
    loginErrorPhone: "1800 023 009",
    buttonText: "Okay",
    resetButtonText: "Reset your password",
} as const;

const LoginForm: React.FC<LoginFormProps> = ({
    title,
    keepLoggedInText,
    btnText,
    onLogin,
    initialEmail,
    enablePermanentSignIn,
    onRegistrationRequired,
    registrationHref,
    onForgotRequired,
    forgotHref,
    guestEmailRef,
}) => {
    const [emailValue, setEmailValue] = useState(initialEmail || guestEmailRef?.current || "");
    const [passwordValue, setPasswordValue] = useState("");
    const [checkboxSelected, setCheckboxSelected] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>();
    const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>();
    const [inputErrorToast, setInputErrorToast] = useState(false);

    const handleCheckbox = () => setCheckboxSelected((checkboxSelected) => !checkboxSelected);

    const [error, setError] = useState<Error>();
    const [processing, setProcessing] = useState(false);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isEmailValid(emailValue)) {
            setEmailErrorMsg(strings.improperEmail);
            guestEmailRef && (guestEmailRef.current = undefined);
            return;
        }
        guestEmailRef && (guestEmailRef.current = emailValue);
        if (onLogin) {
            setProcessing(true);
            onLogin(checkboxSelected, emailValue, passwordValue)
                .then(() => setError(undefined))
                .catch((reason) => {
                    setProcessing(false);
                    if (reason instanceof AuthenticationError) {
                        if (
                            reason.hasAnyType([
                                AuthenticationErrorType.UserNotFound,
                                AuthenticationErrorType.PasswordInvalid,
                            ])
                        ) {
                            setPasswordErrorMsg(strings.loginError);
                            return;
                        }
                        if (reason.hasType(AuthenticationErrorType.InvalidInput)) {
                            setInputErrorToast(true);
                            return;
                        }
                        if (reason.hasType(AuthenticationErrorType.AccountLocked)) {
                            setError(reason);
                            return;
                        }
                    }
                    setError(reason instanceof Error ? reason : new Error(strings.genericErrorMessage));
                });
        }
    };

    const onEmailClear = () => {
        setEmailValue("");
        guestEmailRef && (guestEmailRef.current = undefined);
        setEmailErrorMsg(strings.requiredField);
    };

    const onPasswordClear = () => {
        setPasswordValue("");
        setPasswordErrorMsg(strings.requiredField);
    };

    const handleEmailBlur = () => {
        guestEmailRef && (guestEmailRef.current = isEmailValid(emailValue) ? emailValue : undefined);
        if (!emailValue) {
            setEmailErrorMsg(strings.requiredField);
            return;
        }
        if (!isEmailValid(emailValue)) {
            setEmailErrorMsg(strings.improperEmail);
        }
    };

    const handlePasswordBlur = () => !passwordValue && setPasswordErrorMsg(strings.requiredField);
    const emailOnFocus = () => emailErrorMsg && setEmailErrorMsg(undefined);
    const passwordOnFocus = () => passwordErrorMsg && setPasswordErrorMsg(undefined);

    const handleClose = useCallback(() => {
        setError(undefined);
        setInputErrorToast(false);
    }, []);

    const isLockedError = error instanceof AuthenticationError && error.hasType(AuthenticationErrorType.AccountLocked);

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("header-title", "no-senkei")}>{title}</h2>
            {(registrationHref || onRegistrationRequired) && (
                <p className={bem("header-text")}>
                    Don't have an account?&nbsp;
                    <a className={bem("header-link")} href={registrationHref || "#"} onClick={onRegistrationRequired}>
                        Register here
                    </a>
                </p>
            )}
            <TextField
                label="email*"
                value={emailValue}
                isError={!!emailErrorMsg}
                errorText={emailErrorMsg}
                onChange={setEmailValue}
                clear={onEmailClear}
                className={bem("text-field")}
                onFocus={emailOnFocus}
                onBlur={handleEmailBlur}
                autoComplete="username"
            />
            <TextField
                label="password*"
                type="password"
                value={passwordValue}
                isError={!!passwordErrorMsg}
                errorText={passwordErrorMsg}
                onChange={setPasswordValue}
                clear={onPasswordClear}
                className={bem("text-field")}
                onFocus={passwordOnFocus}
                onBlur={handlePasswordBlur}
                autoComplete="current-password"
            />
            <div className={bem("footer")}>
                {enablePermanentSignIn && (
                    <Checkbox text={keepLoggedInText} onChange={handleCheckbox} isChecked={checkboxSelected} />
                )}
                {(forgotHref || onForgotRequired) && (
                    <p className={bem("footer-link")}>
                        <a className={bem("footer-link")} href={forgotHref || "#"} onClick={onForgotRequired}>
                            Forgot password?
                        </a>
                    </p>
                )}
            </div>
            {/* This is an error placeholder right now to demonstrate both Toast boxes. */}
            {error ? (
                isLockedError ? (
                    <AccountToast
                        title={strings.genericErrorTitle}
                        message={strings.lockedErrorMessage}
                        isOpen={!!error}
                        onClose={onForgotRequired || handleClose}
                        buttonText={onForgotRequired ? strings.resetButtonText : strings.buttonText}
                    />
                ) : (
                    <GenericError isOpen={!!error} error={error} onClose={handleClose} />
                )
            ) : null}
            {inputErrorToast && (
                <AccountToast
                    title={strings.genericErrorTitle}
                    message={strings.loginErrorToast}
                    isOpen={true}
                    buttonText={strings.buttonText}
                    onClose={handleClose}
                    secondMessage={strings.loginErrorInstructions}
                    phoneNumber={strings.loginErrorPhone}
                />
            )}
            <Button className={bem("button")} disabled={processing || !emailValue || !passwordValue} type="submit">
                {btnText}
            </Button>
            {processing && <Spinner />}
        </form>
    );
};

export { LoginForm };

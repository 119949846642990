import * as React from "react";
import { noop } from "lodash";
import "./MainMenuDesktop.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { MainMenuDesktopHeader } from "./MainMenuDesktopHeader";
import { MainMenuDesktopContent } from "./MainMenuDesktopContent";
import { GTMComponentContext } from "../../../GTM/GTMComponentContext";
import { toPercentage } from "../../../helpers/number";

interface MainMenuDesktopContextProps {
    isMainMenuNavListItemActive: boolean;
    setMainMenuNavListItemActive: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayImage: React.Dispatch<React.SetStateAction<MainMenuImageState | undefined>>;
}

const MainMenuDesktopContext = React.createContext<MainMenuDesktopContextProps>({
    isMainMenuNavListItemActive: false,
    setMainMenuNavListItemActive: noop,
    setDisplayImage: noop,
});

interface MainMenuImageState {
    image?: LXS.FocalpointImageFieldValue;
    active: boolean;
}

const MainMenuDesktop: React.FC = () => {
    const { menuData } = React.useContext(PrimaryNavContext);
    const [isMainMenuNavListItemActive, setMainMenuNavListItemActive] = React.useState(false);
    const [displayImage, setDisplayImage] = React.useState<MainMenuImageState>();
    const bgImage = menuData && menuData.defaultImage && menuData.defaultImage.value;

    const getBgImageUrl = (image?: LXS.FocalpointImageFieldValue) => (image ? `url(${image.src})` : "");

    const defaultPosition = 0.5;
    const getBgImagePosition = (image?: LXS.FocalpointImageFieldValue) =>
        image
            ? `${toPercentage(image.cropx || defaultPosition)}% ${toPercentage(image.cropy || defaultPosition)}%`
            : "";

    return (
        <MainMenuDesktopContext.Provider
            value={{ isMainMenuNavListItemActive, setMainMenuNavListItemActive, setDisplayImage }}
        >
            <GTMComponentContext.Provider value={{ component: "MainMenuDesktop" }}>
                <div className="lxs-main-menu-desktop">
                    <MainMenuDesktopHeader />
                    <div className="lxs-main-menu-desktop-layout">
                        <div className="lxs-main-menu-desktop-layout__left">
                            <div
                                className="lxs-main-menu-desktop-layout__image
                                    lxs-main-menu-desktop-layout__image--back"
                                style={{
                                    backgroundImage: getBgImageUrl(bgImage),
                                    backgroundPosition: getBgImagePosition(bgImage),
                                }}
                            />
                            <div
                                className="lxs-main-menu-desktop-layout__image
                                    lxs-main-menu-desktop-layout__image--front"
                                style={
                                    displayImage
                                        ? {
                                              backgroundImage: getBgImageUrl(displayImage.image),
                                              backgroundPosition: getBgImagePosition(displayImage.image),
                                              opacity: displayImage.active ? 1 : 0,
                                          }
                                        : undefined
                                }
                            />
                        </div>
                        <div className="lxs-main-menu-desktop-layout__right">
                            <MainMenuDesktopContent />
                        </div>
                    </div>
                </div>
            </GTMComponentContext.Provider>
        </MainMenuDesktopContext.Provider>
    );
};

export { MainMenuDesktop, MainMenuDesktopContext };

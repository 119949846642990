import * as React from "react";
import { MainMenuMobileNavListItem } from "./MainMenuMobileNavListItem";
import "./MainMenuMobileNav.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { hasLink } from "../../shared";

const MainMenuMobileNav: React.FC = () => {
    const { menuData } = React.useContext(PrimaryNavContext);
    const menuItems = menuData && menuData.menuItems;

    if (!menuItems || menuItems.length === 0) {
        return null;
    }

    return (
        <div className="lxs-main-menu-mobile-nav">
            <ul className="lxs-main-menu-mobile-nav-list">
                {menuItems.filter(hasLink).map((item) => (
                    <MainMenuMobileNavListItem
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        key={item.link.value.text || item.link.value.querystring}
                        item={item}
                    />
                ))}
            </ul>
        </div>
    );
};

export { MainMenuMobileNav };

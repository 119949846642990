import * as React from "react";

interface DataLayer {
    push: (data: object) => void;
}

interface GTMContextProps {
    dataLayer?: DataLayer;
}

interface GTMProps {
    dataLayerName: string;
}

const GTMContext = React.createContext<GTMContextProps>({});

const GTM: React.FC<GTMProps> = ({ children, dataLayerName }) => {
    const dataLayer: DataLayer = (global as any)[dataLayerName];
    return <GTMContext.Provider value={{ dataLayer }}>{children}</GTMContext.Provider>;
};

export { GTM, GTMContext, DataLayer };

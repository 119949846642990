import * as React from "react";

import {
    AccountSecurityForm,
    AccountSecurityFormType,
    mapErrorToSecurityFormType,
} from "../SharedComponents/AccountSecurityForm";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";

import { BackButton } from "../SharedComponents/BackButton";
import { IdentityProvider } from "../Providers/IdentityProvider";
import { LoginForm } from "./LoginForm";
import { SessionTimeoutError } from "../Errors/SessionTimeoutError";
import { TermsAndConditionsForm } from "../SharedComponents/TermsAndConditionsForm";
import { VerificationForm } from "../SharedComponents/VerificationForm";

const strings = {
    timeoutMessage: "Your session has timed out. Press okay to continue back to log in again.",
} as const;

enum LoginScreen {
    Previous,
    Main,
    Otp,
    AdditionalInformation,
    TermsAndConditions,
    Success,
}

interface BaseCredentialsState {
    email: string;
    password: string;
    keepSignedIn: boolean;
}

interface GenericScreenState {
    type: LoginScreen.Previous | LoginScreen.Main | LoginScreen.Success;
}

interface TnCsScreenState {
    type: LoginScreen.TermsAndConditions;
    guest: LXS.Guest;
}

interface OtpScreenState extends BaseCredentialsState {
    type: LoginScreen.Otp;
    authId: string;
    newMobile?: string;
}

interface AdditionalInfoScreenState extends BaseCredentialsState {
    type: LoginScreen.AdditionalInformation;
    requiredInfo: AccountSecurityFormType;
}

type LoginScreenState = GenericScreenState | OtpScreenState | TnCsScreenState | AdditionalInfoScreenState;

type AllPossibleProps = Partial<
    Omit<GenericScreenState, "type"> &
        Omit<OtpScreenState, "type"> &
        Omit<TnCsScreenState, "type"> &
        Omit<AdditionalInfoScreenState, "type">
>;

type LoginState = AllPossibleProps & LoginScreenState;

interface LoginProps {
    title?: string;
    keepLoggedInText?: string;
    btnText?: string;
    onLogin: (guest: LXS.Guest) => void;
    enablePermanentSignIn?: boolean;
    guestEmailRef?: React.MutableRefObject<string | undefined>;
    onTermsAccepted?: () => void;
    termsAndPrivacyConfig: LXS.TermsAndPrivacyConfig;
    onRegistrationRequired?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
    registrationHref?: string;
    knownBasicDetails?: Partial<LXS.BasicGuestDetails>;
    onForgotRequired?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
    forgotHref?: string;
}

const useHandleLogin = (dispatch: React.Dispatch<LoginScreenState>, onLogin: (guest: LXS.Guest) => Promise<void>) =>
    React.useCallback(
        async (keepSignedIn: boolean, email: string, password: string): Promise<void> => {
            try {
                const guest = await IdentityProvider.current.loginAsync(keepSignedIn, email, password);
                await onLogin(guest);
            } catch (err) {
                if (err instanceof AuthenticationError) {
                    const requiredInfo = mapErrorToSecurityFormType(err.types);
                    if (requiredInfo !== undefined) {
                        dispatch({
                            type: LoginScreen.AdditionalInformation,
                            requiredInfo,
                            email,
                            password,
                            keepSignedIn,
                        });
                        return;
                    }
                    if (err.hasType(AuthenticationErrorType.OtpCodeRequired) && err.authId) {
                        dispatch({ type: LoginScreen.Otp, email, password, authId: err.authId, keepSignedIn });
                        return;
                    }
                }
                throw err;
            }
        },
        [onLogin],
    );

const useHandleMoreInfo = (
    state: LoginState,
    dispatch: React.Dispatch<LoginScreenState>,
    onLogin: (guest: LXS.Guest) => Promise<void>,
) =>
    React.useCallback(
        async (newMobile?: string, newPassword?: string): Promise<void> => {
            if (state.type !== LoginScreen.AdditionalInformation) {
                throw new Error(
                    `Invalid state of component, expected ${LoginScreen.AdditionalInformation}, got ${state.type}`,
                );
            }
            try {
                const guest = await IdentityProvider.current.loginAsync(
                    state.keepSignedIn,
                    state.email,
                    state.password,
                    newMobile,
                    newPassword,
                );
                await onLogin(guest);
            } catch (err) {
                if (err instanceof AuthenticationError) {
                    if (err.hasType(AuthenticationErrorType.OtpCodeRequired) && err.authId) {
                        dispatch({
                            type: LoginScreen.Otp,
                            authId: err.authId,
                            email: state.email,
                            password: state.password,
                            keepSignedIn: state.keepSignedIn,
                            newMobile,
                        });
                        return;
                    }
                }
                throw err;
            }
        },
        [onLogin, state.type, state.email, state.password, state.keepSignedIn],
    );

const useHandleOtpResend = (state: LoginState, dispatch: React.Dispatch<LoginScreenState>, handleRestart: () => void) =>
    React.useCallback(async () => {
        if (state.type !== LoginScreen.Otp) {
            throw new Error(`Invalid state of component, expected ${LoginScreen.Otp}, got ${state.type}`);
        }
        try {
            const authId = await IdentityProvider.current.requestOtpAsync(state.authId);
            dispatch({
                type: LoginScreen.Otp,
                authId,
                email: state.email,
                password: state.password,
                keepSignedIn: state.keepSignedIn,
            });
        } catch (err) {
            if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
            }
            throw err;
        }
    }, [state.type, state.email, state.password, state.authId]);

const useHandleOtp = (
    state: LoginState,
    dispatch: React.Dispatch<LoginScreenState>,
    onLogin: (guest: LXS.Guest) => Promise<void>,
    handleRestart: () => void,
) =>
    React.useCallback(
        async (code: string) => {
            if (state.type !== LoginScreen.Otp) {
                // Check is required for type validation
                throw new Error(`Invalid state of component, expected ${LoginScreen.Otp}, got ${state.type}`);
            }
            try {
                const guest = await IdentityProvider.current.submitOtpAsync(state.keepSignedIn, state.authId, code);
                await onLogin(guest);
            } catch (err) {
                if (err instanceof AuthenticationError) {
                    const requiredInfo = mapErrorToSecurityFormType(err.types);
                    if (requiredInfo !== undefined) {
                        dispatch({
                            type: LoginScreen.AdditionalInformation,
                            requiredInfo,
                            email: state.email,
                            password: state.password,
                            keepSignedIn: state.keepSignedIn,
                        });
                        return;
                    }
                    if (err.hasType(AuthenticationErrorType.OtpCodeRequired) && err.authId) {
                        dispatch({
                            type: LoginScreen.Otp,
                            email: state.email,
                            password: state.password,
                            authId: err.authId,
                            keepSignedIn: state.keepSignedIn,
                        });
                        return;
                    }
                    if (err.hasType(AuthenticationErrorType.SessionTimeout)) {
                        throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
                    }
                }
                throw err;
            }
        },
        [onLogin, state.type, state.email, state.password, state.authId, state.keepSignedIn],
    );

const useHandleTnCs = (
    state: LoginState,
    onLogin: (guest: LXS.Guest) => void,
    termsAndConditionsVersion: string,
    privacyPolicyVersion: string,
    onTermsAccepted?: () => void,
) =>
    React.useCallback(async () => {
        if (state.type !== LoginScreen.TermsAndConditions || !state.guest) {
            throw new Error(
                `Invalid state of component, expected ${LoginScreen.TermsAndConditions},` +
                    `got ${state.type} and guest: ${!state.guest}`,
            );
        }

        const details = await state.guest.getOrUpdateDetailsAsync({
            termsAndConditionsVersion,
            privacyPolicyVersion,
        });

        if (
            details &&
            details.termsAndConditionsVersion === termsAndConditionsVersion &&
            details.privacyPolicyVersion === privacyPolicyVersion
        ) {
            onTermsAccepted?.();
            onLogin(state.guest);
        } else {
            throw new AuthenticationError(new Set([AuthenticationErrorType.TnCsInvalid]));
        }
    }, [state.type, state.guest, onLogin, termsAndConditionsVersion, privacyPolicyVersion, onTermsAccepted]);

const useTnCsFilter = (
    state: LoginState,
    dispatch: React.Dispatch<LoginScreenState>,
    onLogin: (guest: LXS.Guest) => void,
    termsVersion: string,
    privacyVersion: string,
    knownDetails?: Partial<LXS.BasicGuestDetails>,
) =>
    React.useCallback(
        async (guest: LXS.Guest) => {
            const details = await guest.getOrUpdateDetailsAsync({
                ...(knownDetails?.accountId || knownDetails?.vehicleOwnershipId
                    ? {
                          salesforceAccountId: knownDetails?.accountId,
                          vehicleOwnershipId: knownDetails?.vehicleOwnershipId || undefined,
                      }
                    : {}),
                ...(state.newMobile ? { mobileNumber: state.newMobile } : {}),
            });

            if (details.termsAndConditionsVersion !== termsVersion || details.privacyPolicyVersion !== privacyVersion) {
                dispatch({ type: LoginScreen.TermsAndConditions, guest });
            } else {
                onLogin(guest);
            }
        },
        [
            onLogin,
            state.newMobile,
            termsVersion,
            privacyVersion,
            knownDetails?.accountId,
            knownDetails?.vehicleOwnershipId,
        ],
    );

const loginReducer = (state: LoginState, action: LoginScreenState): LoginState => {
    if (action.type === LoginScreen.Previous) {
        switch (state.type) {
            case LoginScreen.AdditionalInformation:
                return { ...state, type: LoginScreen.Main };
            default:
                return state;
        }
    }
    return { ...state, ...action };
};

const Login: React.FC<LoginProps> = ({
    title = "ENCORE LOG IN",
    keepLoggedInText = "Stay logged in?",
    btnText = "LOG IN",
    onLogin,
    onTermsAccepted,
    termsAndPrivacyConfig: { termsAndConditionsVersion, termsAndConditionsUrl, privacyPolicyVersion, privacyPolicyUrl },
    knownBasicDetails,
    enablePermanentSignIn,
    onRegistrationRequired,
    registrationHref,
    onForgotRequired,
    forgotHref,
    guestEmailRef,
}) => {
    const [state, dispatch] = React.useReducer(loginReducer, {
        type: LoginScreen.Main,
        email: knownBasicDetails?.email || guestEmailRef?.current,
    });

    const handleBack = React.useCallback(() => dispatch({ type: LoginScreen.Previous }), []);
    const handleRestart = React.useCallback(() => dispatch({ type: LoginScreen.Main }), []);
    const onLoginWithFilter = useTnCsFilter(
        state,
        dispatch,
        onLogin,
        termsAndConditionsVersion,
        privacyPolicyVersion,
        knownBasicDetails,
    );
    const handleLogin = useHandleLogin(dispatch, onLoginWithFilter);
    const handleMoreInfo = useHandleMoreInfo(state, dispatch, onLoginWithFilter);
    const handleOtp = useHandleOtp(state, dispatch, onLoginWithFilter, handleRestart);
    const handleOtpResend = useHandleOtpResend(state, dispatch, handleRestart);
    const handleTnCs = useHandleTnCs(state, onLogin, termsAndConditionsVersion, privacyPolicyVersion, onTermsAccepted);

    switch (state.type) {
        case LoginScreen.Main:
            return (
                <>
                    <BackButton />
                    <LoginForm
                        title={title}
                        keepLoggedInText={keepLoggedInText}
                        btnText={btnText}
                        onLogin={handleLogin}
                        initialEmail={state.email}
                        enablePermanentSignIn={enablePermanentSignIn}
                        registrationHref={registrationHref}
                        forgotHref={forgotHref}
                        onRegistrationRequired={onRegistrationRequired}
                        onForgotRequired={onForgotRequired}
                        guestEmailRef={guestEmailRef}
                    />
                </>
            );
        case LoginScreen.AdditionalInformation:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <AccountSecurityForm email={state.email} type={state.requiredInfo} onSubmit={handleMoreInfo} />
                </>
            );
        case LoginScreen.Otp:
            return (
                <>
                    <BackButton />
                    <VerificationForm email={state.email} onSubmit={handleOtp} onResendRequired={handleOtpResend} />
                </>
            );
        case LoginScreen.TermsAndConditions:
            return (
                <>
                    <BackButton />
                    <TermsAndConditionsForm
                        onAccept={handleTnCs}
                        buttonText="accept"
                        privacyTitle="Privacy Policy"
                        termsTitle="Terms of Use"
                        privacyUrl={privacyPolicyUrl}
                        termsUrl={termsAndConditionsUrl}
                    />
                </>
            );
        default:
            return <h1>Unknown error</h1>;
    }
};

export { Login };

import * as React from "react";
import "./ScrollIndicator.scss";

interface ScrollIndicatorProp {
    onClick(): void;
}

export const ScrollIndicator = (props: ScrollIndicatorProp) => {
    return (
        <div className="lxs-scroll-indicator">
            <a className="lxs-scroll-indicator__link" onClick={props.onClick}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 76 120"
                    preserveAspectRatio="xMidYMid meet"
                    width="25px"
                >
                    <g fill="none" fillRule="evenodd">
                        <rect width="70" height="118" x="1.5" y="1.5" stroke="#FFF" strokeWidth="3" rx="36" />
                        <circle
                            className="lxs-scroll-indicator__link__svg__circle"
                            cx="36.5"
                            cy="31.5"
                            r="4.5"
                            fill="#FFF"
                        />
                    </g>
                </svg>
            </a>
            <div className="lxs-scroll-indicator__gradient" />
        </div>
    );
};

import * as React from "react";
import "./MainMenuMobileContact.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { LinkButton } from "../../../../buttons";
import { lxColor } from "../../../../colors/variables";
import { SVGPhone } from "../../../SVGIcons/icons/static/SVGPhone";
import { ButtonStyle, ButtonShade } from "../../../../buttons/shared";
import { createBemFn } from "../../../../utilities/bem";
import { convertToPascalStr } from "../../../../utilities/string-helpers";

const bem = createBemFn("lxs-main-menu-mobile-contact");

const MainMenuMobileContact: React.FC = () => {
    const { dealerContact } = React.useContext(PrimaryNavContext);

    const renderButton = (text: string, phoneNumber: string, buttonStyle: ButtonStyle, shade: ButtonShade) => (
        <LinkButton
            href={`tel:${phoneNumber}`}
            buttonStyle={buttonStyle}
            shade={shade}
            data-gtm-target={text}
            data-gtm-action={phoneNumber}
        >
            <SVGPhone
                width={12}
                height={12}
                color={
                    (buttonStyle === ButtonStyle.Primary && shade === ButtonShade.Dark) ||
                    (buttonStyle === ButtonStyle.Secondary && shade === ButtonShade.Light)
                        ? lxColor("deep-blue")
                        : lxColor("smoke")
                }
            />
            {text}
        </LinkButton>
    );

    const locations = dealerContact?.locations;

    return !locations?.length ? null : (
        <div className={bem()}>
            {locations.map((location) => (
                <div key={location.name} className={bem("list-item")}>
                    <div className={bem("location-name")}>
                        {locations.length > 1 && convertToPascalStr(location.name)}
                    </div>
                    <div className={bem("btn-group")}>
                        {location.servicePhone?.length > 0 &&
                            renderButton("Services", location.servicePhone, ButtonStyle.Secondary, ButtonShade.Dark)}
                        {location.salesPhone?.length > 0 &&
                            renderButton("Sales", location.salesPhone, ButtonStyle.Primary, ButtonShade.Dark)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export { MainMenuMobileContact };

import * as React from "react";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import ErrorCardLcac from "../ErrorCard/ErrorCardLcac/ErrorCardLcac";
import { StateSelector } from "../../../types/general";
import { useError } from "../ErrorCard/useError";
import ErrorCard from "../ErrorCard/ErrorCard";
import ErrorCardCaseNumber from "../ErrorCard/ErrorCardCaseNumber/ErrorCardCaseNumber";
import { RouteComponentProps, withRouter } from "react-router";

const getDescription = (to: string) =>
    `We can't make that action right now. Clicking the button below will send you to ${to}.`;

interface Props extends RouteComponentProps {
    errorSelector?: StateSelector<Error | undefined>;
    destinationName: string;
    destinationRoute: string;
}

const RedirectErrorCard: React.FC<Props> = ({ errorSelector, destinationName, destinationRoute, history }) => {
    //#region hooks
    const error = useError(errorSelector);
    //#endregion

    const redirect = () => history.push(destinationRoute);

    return (
        <ErrorCard>
            <ErrorCardFinePrint>{getDescription(destinationName)}</ErrorCardFinePrint>
            <ErrorCardButton onClick={redirect}>okay</ErrorCardButton>
            <ErrorCardCaseNumber error={error} />
            <ErrorCardLcac />
        </ErrorCard>
    );
};

export default withRouter(RedirectErrorCard);

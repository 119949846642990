import * as React from "react";
import Toast from "../../components/Toast/Toast";
import CancelBookingErrorCard from "../../components/errorCards/CancelBookingErrorCard/CancelBookingErrorCard";
import { RouteComponentProps } from "react-router";

const CancelBookingErrorToast: React.FC<RouteComponentProps> = props => (
    <Toast>
        <CancelBookingErrorCard {...props} />
    </Toast>
);

export default CancelBookingErrorToast;

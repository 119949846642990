import classnames, { Argument as ClassNameArgument } from "classnames";

export enum ButtonStyle {
    Primary = "primary",
    Secondary = "secondary",
}

export enum ButtonShade {
    Dynamic = "dynamic",
    Light = "light",
    Dark = "dark",
}

export enum ButtonIconPosition {
    Left = "left",
    LeftCenter = "left-center",
    RightCenter = "right-center",
    Right = "right",
}

export const defaultButtonIconPosition = ButtonIconPosition.LeftCenter;

interface ButtonClassNameData {
    buttonStyle: ButtonStyle;
    shade: ButtonShade;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
}

export const buttonClassName: (data: ButtonClassNameData, ...classes: ClassNameArgument[]) => string = (
    { disabled, selected, buttonStyle, className, shade },
    ...classes
) =>
    classnames(
        "lxs-button",
        { [`lxs-button--${buttonStyle}`]: buttonStyle },
        { [`lxs-button--shade-${shade}`]: buttonStyle },
        { "lxs-button--disabled": disabled },
        { "lxs-button--selected": selected },
        className,
        ...classes,
    );

const getShade = (val?: string): ButtonShade | undefined =>
    val === ButtonShade.Dynamic || val === ButtonShade.Light || val === ButtonShade.Dark ? val : undefined;

const getStyle = (val?: string): ButtonStyle | undefined =>
    val === ButtonStyle.Primary || val === ButtonStyle.Secondary ? val : undefined;

export const stringToButtonShadeStyle = (value?: string): [ButtonShade, ButtonStyle] => {
    const styles = value && value.split("-", 2);
    if (!styles) {
        return [ButtonShade.Dynamic, ButtonStyle.Primary];
    }

    return styles.length === 1
        ? [getShade(styles[0]) || ButtonShade.Dynamic, getStyle(styles[0]) || ButtonStyle.Primary]
        : [
              getShade(styles[0]) || getShade(styles[1]) || ButtonShade.Dynamic,
              getStyle(styles[1]) || getStyle(styles[0]) || ButtonStyle.Primary,
          ];
};

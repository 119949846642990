import classnames from "classnames";
import * as React from "react";
import "./SVGIcon.scss";

export enum SVGAnimationEvents {
    HOVER,
    CLICK,
    NONE,
}

type SVGIconElementProps = React.HTMLAttributes<HTMLSpanElement> & LXS.SVGAnimatedBaseProps;

const SVGIcon = ({
    className,
    hasBackground = false,
    containerRef,
    animationEvent = SVGAnimationEvents.CLICK,
    active = false,
    ...rest
}: SVGIconElementProps) => {
    const iconWrapperRef = React.useRef<HTMLSpanElement>(null);
    const [isActive, setIsActive] = React.useState(active);
    const classes = classnames(className, "lxs-svg-icon", {
        "lxs-svg-icon--has-background": hasBackground,
        "lxs-svg-icon--is-active": isActive,
    });

    React.useEffect(() => {
        setIsActive(active);
    }, [active]);

    React.useEffect(() => {
        const element = containerRef && containerRef.current ? containerRef.current : iconWrapperRef.current;

        if (!element) {
            return;
        }

        function toggleActive() {
            setIsActive((currentIsActive) => !currentIsActive);
        }

        switch (animationEvent) {
            case SVGAnimationEvents.HOVER:
                element.addEventListener("mouseenter", toggleActive);
                element.addEventListener("mouseleave", toggleActive);
                break;
            case SVGAnimationEvents.CLICK:
                element.addEventListener("click", toggleActive);
                break;
        }

        return () => {
            if (element) {
                element.removeEventListener("mouseenter", toggleActive);
                element.removeEventListener("mouseleave", toggleActive);
                element.removeEventListener("click", toggleActive);
            }
        };
    }, [isActive, containerRef, animationEvent]);

    return <span ref={iconWrapperRef} className={classes} {...rest} />;
};

export { SVGIcon };

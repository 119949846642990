import Toast from "../../components/Toast/Toast";
import React from "react";
import { routeString, RouteSection } from "../../helpers/routes";
import { Switch, Route } from "react-router-dom";
import RedirectErrorCard from "../../components/errorCards/RedirectErrorCard/RedirectErrorCard";

const createToast: (closable: boolean) => React.ReactNode = closable => (
    <Toast close={closable}>
        <RedirectErrorCard destinationName="the dashboard" destinationRoute={routeString(RouteSection.Dashboard)} />
    </Toast>
);

const OnDemandErrorToast: React.FC = () => {
    return (
        <Switch>
            <Route exact={true} path={routeString(RouteSection.Encore)} render={() => createToast(true)} />
            <Route render={() => createToast(false)} />
        </Switch>
    );
};

export default OnDemandErrorToast;

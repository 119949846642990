import styles from "./PrimaryNavCloseButton.module.scss";
import { SVGPrimaryNavClose } from "./svg/SVGPrimaryNavClose";

type CloseButtonProps = {
  onClick: React.MouseEventHandler;
  buttonLabel?: string;
};

const PrimaryNavCloseButton: React.FC<CloseButtonProps> = ({ onClick, buttonLabel }) => {
  return (
    <button className={styles.closeButton} onClick={onClick} aria-label="Close">
      <span className={styles.closeButtonIcon}>
        <SVGPrimaryNavClose />
      </span>
      <span className={styles.closeButtonLabel}>{buttonLabel || "Close"}</span>
    </button>
  );
};

export { PrimaryNavCloseButton };

import * as React from "react";
import "./SVGAccordionAnimated.scss";
import { SVGIcon } from "../../SVGIcon";
import { defaultIconSize, defaultIconColor } from "../constants";
import { SVGAccordion } from "../static/SVGAccordion";
import classnames from "classnames";

const SVGAccordionAnimated: React.FC<LXS.SVGAnimatedProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    className,
    ...rest
}) => (
    /* eslint-disable max-len */
    <SVGIcon {...rest} className={classnames(className, "lxs-svg-accordion-animated")}>
        <SVGAccordion width={width} height={height} color={color} />
    </SVGIcon>
    /* eslint-enable max-len */
);

export { SVGAccordionAnimated };

import * as React from "react";
import { isGetInTouchItem, isMenuItemWithSubItems } from "../../shared";
import { MainMenuMobileContact } from "./MainMenuMobileContact";
import { MainMenuMobileNavItem } from "./MainMenuMobileNavItem";
import { MainMenuMobileSecondaryNav } from "./MainMenuMobileSecondaryNav";
import { MainMenuMobileSecondaryScreen } from "./MainMenuMobileSecondaryScreen";

const MainMenuMobileNavListItem: React.FC<{ item: LXS.AnyMenuItem }> = ({ item }) => {
    const [active, setActive] = React.useState(false);

    const activate = () => {
        setActive(true);
    };

    const deactivate = () => {
        setActive(false);
    };

    return (
        (item.link && item.link.value && (
            <>
                <li className="lxs-main-menu-mobile-nav__list-item">
                    <MainMenuMobileNavItem item={item} onClick={activate} />
                </li>
                <MainMenuMobileSecondaryScreen
                    active={active}
                    title={item.link.value.text}
                    onBack={deactivate}
                    headerImage={item.image && item.image.value}
                >
                    {isGetInTouchItem(item) ? (
                        <MainMenuMobileContact />
                    ) : (
                        isMenuItemWithSubItems(item) && <MainMenuMobileSecondaryNav items={item.menuItems} />
                    )}
                </MainMenuMobileSecondaryScreen>
            </>
        )) ||
        null
    );
};

export { MainMenuMobileNavListItem };

import { replaceInTree } from "@tmca/react-element-replace";

import { getValidReactChildrenOrDefault } from "../../../utils/reactExtensions";
import { TypographyPlugin } from "../../common/typographyTypes";
import { Electrified } from "../Electrified";

const electrifiedTagReplacer = (children: React.ReactNode) =>
  replaceInTree(children, {
    matchElement: "electrified",
    replace: (item: React.ReactElement) => (
      <Electrified>{getValidReactChildrenOrDefault(item, null)}</Electrified>
    ),
  });

export const electrifiedReplacer: TypographyPlugin = (children: React.ReactNode) => ({
  stopProcessing: false,
  result: replaceInTree(electrifiedTagReplacer(children), {
    match: (x) => typeof x === "string",
    replace: (content: string) =>
      // IMPORTANT: Use of parentheses allow to include splitter text in the array
      // if there was no text before tag it will be split as empty string
      content.split(/<electrified>(.*?)<\/electrified>/gi).map((token, i) =>
        // Every even (second, forth) value will be the content of parentheses in regex
        i % 2 === 1 ? (
          <Electrified key={token}>{token}</Electrified>
        ) : (
          token // in case of tags inside tags
        )
      ),
  }),
});

import React, { memo, useState } from "react";
import { Button } from "../../../buttons/Button";
import { createBemFn } from "../../../utilities/bem";
import { isEmailValid } from "../../../utilities/validations";
import { TextField } from "../../Form";
import { Spinner } from "../../Spinner/Spinner";
import { AccountToast } from "../SharedComponents/AccountToast";
import "./ForgotPassword.scss";

interface ForgotPasswordProps {
    onSubmit: (email: string) => Promise<void>;
    guestEmailRef?: React.MutableRefObject<string | undefined>;
}

const bem = createBemFn("lxs-forgot-password");

const strings = {
    requiredField: "This field is required",
    improperEmail: "Please use a valid email address",
    genericErrorTitle: "Something went wrong",
    genericErrorMessage: "We can't contact our servers right now. Please try signing in later.",
    buttonText: "Okay",
} as const;

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onSubmit, guestEmailRef }) => {
    const [email, setEmailValue] = useState(guestEmailRef?.current || "");
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>();
    const [error, setError] = useState<Error>();
    const [processing, setProcessing] = useState(false);
    const emailOnFocus = () => emailErrorMsg && setEmailErrorMsg(undefined);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true);
        guestEmailRef && (guestEmailRef.current = email);
        onSubmit(email).catch((err) => {
            setError(err);
            setProcessing(false);
        });
    };

    const handleEmailBlur = () => {
        guestEmailRef && (guestEmailRef.current = isEmailValid(email) ? email : undefined);
        if (!email) {
            setEmailErrorMsg(strings.requiredField);
            return;
        }
        if (!isEmailValid(email)) {
            setEmailErrorMsg(strings.improperEmail);
        }
    };

    const handleClose = () => setError(undefined);

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>Forgot Password</h2>
            <p className={bem("text")}>Please enter your email below and click the reset password button:</p>
            <TextField
                label="email*"
                value={email}
                isError={!!emailErrorMsg}
                errorText={emailErrorMsg}
                onChange={setEmailValue}
                className={bem("text-field")}
                onFocus={emailOnFocus}
                onBlur={handleEmailBlur}
            />
            {error && (
                <AccountToast
                    title={strings.genericErrorTitle}
                    message={strings.genericErrorMessage}
                    isOpen={!!error}
                    onClose={handleClose}
                    buttonText={strings.buttonText}
                />
            )}
            <Button className={bem("button")} disabled={processing || !isEmailValid(email)} type="submit">
                Reset Password
            </Button>
            {processing && <Spinner />}
        </form>
    );
};

const ForgotPasswordMemoised = memo(ForgotPassword);

export { ForgotPasswordMemoised as ForgotPassword };

import * as React from "react";
import Toast from "../../components/Toast/Toast";
import NonRecoverableErrorCard from "../../components/errorCards/NonRecoverableErrorCard/NonRecoverableErrorCard";

const NonRecoverableErrorToast: React.FC = () => {
    return (
        <Toast>
            <NonRecoverableErrorCard />
        </Toast>
    );
};

export default NonRecoverableErrorToast;

import typographyStyles from "../common/typography.module.scss";

type ElectrifiedProps = React.PropsWithChildren<{
  children: React.ReactNode;
}>;

export const Electrified: React.FC<ElectrifiedProps> = ({ children }) => {
  return (
    <span data-testid="lk-tg-electrified" className={typographyStyles.electrified}>
      {children}
    </span>
  );
};

Electrified.displayName = "Electrified";

import cn from "classnames";
import { useContext } from "react";

import { ToolTip, ToolTipPosition } from "@/components/ToolTip/ToolTip";
import { Typography } from "@/components/Typography/Typography";
import { DealerContext } from "@/utils/DealerContext";

import styles from "./DealerService.module.scss";

type DealerServicePublicHolidaysProps = {
  publicHolidayDetails?: string;
};

const DealerServicePublicHolidays: React.FC<DealerServicePublicHolidaysProps> = ({
  publicHolidayDetails,
}) => {
  const { dealerConfig } = useContext(DealerContext);

  if (!dealerConfig?.publicHolidayHoursLabel?.value || !publicHolidayDetails) {
    return null;
  }

  return (
    <div className={cn(styles.dealerService, styles.dealerServicePublicHolidays)}>
      <span>{dealerConfig.publicHolidayHoursLabel.value}</span>
      <ToolTip position={ToolTipPosition.TOP} addShadow>
        <Typography component="p" variant="b2">
          {publicHolidayDetails}
        </Typography>
      </ToolTip>
    </div>
  );
};

export { DealerServicePublicHolidays };

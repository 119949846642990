import { useState, useEffect } from "react";

export const useIsInsideIframe = () => {
    const [isInsideIframe, setIsInsideIframe] = useState(false);

    useEffect(() => {
        if (window.self !== window.parent) {
            setIsInsideIframe(true);
        }
    }, []);

    return isInsideIframe;
};

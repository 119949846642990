/* eslint-disable no-restricted-imports */
import * as React from "react";
import { Tab as TabTitle, Tabs, TabList, TabPanel, TabsProps } from "react-tabs";
import { Theme } from "../shared/Theme";
import "./Tab.scss";

export type TabProps = TabsProps & {
    theme?: Theme;
};
const Tab: React.FC<TabProps> = ({ theme = Theme.DARK, ...props }) => {
    const selectedClassName = `lxs-tab__tab--selected--theme-${theme === Theme.DARK ? "dark" : "light"}`;

    return <Tabs {...props} selectedTabClassName={selectedClassName} />;
};

export { Tab, TabList, TabPanel, TabTitle };

/*
    These should match ./_variables.scss
*/

export const lxColors = {
    "ocean": {
        default: "#232429",
        light: "#32333a",
        dark: "#16171b",
    },
    "smoke": {
        default: "#e4e1d7",
        light: "#f0eee7",
        dark: "#d7d3c6",
    },
    "mortar": {
        default: "#beb4b2",
    },
    "storm": {
        default: "#3a404a",
    },
    "deep-blue": {
        default: "#232635",
        light: "#313649",
        lighter: "#4d5268",
        dark: "#161825",
    },
    "white": {
        default: "#ffffff",
    },
    "black": {
        default: "#111111",
        absolute: "#000000",
    },
    "ochre": {
        default: "#ba161a",
    },
    "green": {
        default: "#1e8a15",
    },
    "yellow": {
        default: "#f88f39",
    },
    "inari": {
        default: "#ca4b19",
    },
    "grey": {
        default: "#a9a9a9",
        dark: "#333333",
    },
    "miso": {
        default: "#5c534a",
        light: "#796e64",
        dark: "#4f443a",
    },
    "moss": {
        default: "#4b4d4b",
        light: "#656865",
        dark: "#3b3d3b",
    },
    "iron": {
        default: "#524c4c",
        light: "#6d6666",
        dark: "#433c3c",
    },
    "clay": {
        default: "#d5b461",
    },
    "stone": {
        default: "#ceced0",
    },
    "error": {
        default: "#e42126",
    },
    "success": {
        default: "#43a93a",
    },
};

type Color = typeof lxColors;
type Variant<T extends keyof Color> = keyof Color[T];

export const lxColor = <T extends keyof Color>(
    color: T,
    variant: Variant<T> = "default",
): Color[T][keyof Color[T]] | undefined => lxColors[color] && lxColors[color][variant];

export const lxColorTransparent = <T extends keyof Color>(
    color: T,
    variant: Variant<T> = "default",
    transparent: number,
) => {
    const hex = lxColor(color, variant);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [r, g, b] = hex?.match(/\w\w/g).map((x: string) => parseInt(x, 16));

    return `rgba(${r}, ${g}, ${b}, ${transparent})`;
};

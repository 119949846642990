import classNames from "classnames";
import { useContext } from "react";
import {
  Dialog,
  Modal as ModalPrimitive,
  ModalOverlay as ModalOverlayPrimitive,
  ModalOverlayProps as ModalOverlayPrimitiveProps,
} from "react-aria-components";

import { ThemeContext } from "@/theming/ThemeContext";

import styles from "./Modal.module.scss";
import { GlobalStylesScope } from "../GlobalStylesScope/GlobalStylesScope";

interface DialogRenderProps {
  close: () => void;
}

export interface ModalProps extends Omit<ModalOverlayPrimitiveProps, "children"> {
  /**
   * Whether to close the modal when the user interacts outside it.
   * @default false
   */
  isDismissable?: ModalOverlayPrimitiveProps["isDismissable"];
  /**
   * Whether pressing the escape key to close the modal should be disabled.
   * @default false
   */
  isKeyboardDismissDisabled?: ModalOverlayPrimitiveProps["isKeyboardDismissDisabled"];
  /** Whether the overlay is open by default (controlled). */
  isOpen?: ModalOverlayPrimitiveProps["isOpen"];
  /** Whether the overlay is open by default (uncontrolled). */
  defaultOpen?: ModalOverlayPrimitiveProps["defaultOpen"];
  /** Handler that is called when the overlay's open state changes. */
  onOpenChange?: ModalOverlayPrimitiveProps["onOpenChange"];
  /** Children of the modal. A function may be provided to access a function to close the dialog. */
  children?: React.ReactNode | ((opts: DialogRenderProps) => React.ReactNode);
  ariaLabel?: string;
  /** When there is no interaction with the modal */
  isPopover?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { children, ariaLabel = "Dialog", isPopover = false, ...rest } = props;
  const themeDefinition = useContext(ThemeContext);

  return (
    <ModalOverlayPrimitive className={styles.modalOverlay} {...rest}>
      <ModalPrimitive>
        <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
          <Dialog
            className={classNames(styles.dialog, {
              [styles.popover]: isPopover,
            })}
            aria-label={ariaLabel}
          >
            {children}
          </Dialog>
        </GlobalStylesScope>
      </ModalPrimitive>
    </ModalOverlayPrimitive>
  );
};

export { Modal };

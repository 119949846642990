import * as React from "react";
import { BackButton } from "../SharedComponents/BackButton";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { SessionTimeoutError } from "../Errors/SessionTimeoutError";
import { IdentityProvider } from "../Providers/IdentityProvider";
import { RegistrationForm } from "./RegistrationForm";
import { TermsAndConditionsForm } from "../SharedComponents/TermsAndConditionsForm";
import { VerificationForm } from "../SharedComponents/VerificationForm";

const strings = {
    timeoutMessage: "Your session has timed out. Press okay to continue back to log in again.",
} as const;

enum RegistrationScreen {
    Main,
    Otp,
    TermsAndConditions,
    Success,
    Previous,
}

interface BaseCredentialsState {
    email: string;
    password: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
}

interface GenericScreenState {
    type: RegistrationScreen.Previous | RegistrationScreen.Success;
}

interface MainScreenState {
    type: RegistrationScreen.Main;
    error?: Error;
}

interface TnCsScreenState extends BaseCredentialsState {
    type: RegistrationScreen.TermsAndConditions;
}

interface OtpScreenState {
    type: RegistrationScreen.Otp;
    authId: string;
}

type RegistrationScreenState =
    | GenericScreenState
    | MainScreenState
    | (OtpScreenState & BaseCredentialsState)
    | TnCsScreenState;

type AllPossibleProps = Partial<Omit<OtpScreenState, "type"> & Omit<TnCsScreenState, "type">>;

type RegistrationState = AllPossibleProps & RegistrationScreenState;
type RegistrationAction = GenericScreenState | MainScreenState | OtpScreenState | TnCsScreenState;

interface RegistrationProps {
    onLogin: (guest: LXS.Guest) => void;
    onTermsAccepted?: () => void;
    termsAndPrivacyConfig: LXS.TermsAndPrivacyConfig;
    onSignInRequired?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
    signInHref?: string;
    knownBasicDetails?: Partial<LXS.BasicGuestDetails>;
    guestEmailRef?: React.MutableRefObject<string | undefined>;
}

const registrationReducer = (state: RegistrationState, action: RegistrationAction): RegistrationState => {
    switch (action.type) {
        case RegistrationScreen.Previous:
            return state.type === RegistrationScreen.TermsAndConditions
                ? { ...state, type: RegistrationScreen.Main }
                : state;
        case RegistrationScreen.Otp:
            return state.type === RegistrationScreen.TermsAndConditions || state.type === RegistrationScreen.Otp
                ? { ...state, ...action }
                : state;
        default:
            return { ...state, ...action };
    }
};

const useHandleRegister = (
    dispatch: React.Dispatch<RegistrationAction>,
    setError: (error?: Error | undefined) => void,
    knownPhone?: string,
) =>
    React.useCallback(
        (email: string, password: string, phoneNumber: string, firstName: string, lastName: string): void => {
            setError(undefined);
            dispatch({
                type: RegistrationScreen.TermsAndConditions,
                email,
                password,
                phoneNumber,
                firstName,
                lastName,
            });
        },
        [setError, knownPhone],
    );

const useHandleTnCs = (state: RegistrationState, dispatch: React.Dispatch<RegistrationAction>) =>
    React.useCallback(async (): Promise<void> => {
        if (state.type !== RegistrationScreen.TermsAndConditions) {
            // Check is required for type validation
            throw new Error(
                `Invalid state of component, expected ${RegistrationScreen.TermsAndConditions}, ` + `got ${state.type}`,
            );
        }
        try {
            await IdentityProvider.current.registerAsync(
                state.email,
                state.password,
                state.phoneNumber,
                state.firstName,
                state.lastName,
            );
        } catch (err) {
            if (err instanceof AuthenticationError) {
                if (err.hasType(AuthenticationErrorType.OtpCodeRequired) && err.authId) {
                    dispatch({ type: RegistrationScreen.Otp, authId: err.authId });
                    return;
                }
                if (
                    err.hasAnyType([
                        AuthenticationErrorType.PhoneNotUnique,
                        AuthenticationErrorType.EmailNotUnique,
                        AuthenticationErrorType.PhoneInvalid,
                        AuthenticationErrorType.EmailInvalid,
                    ])
                ) {
                    dispatch({ type: RegistrationScreen.Main, error: err });
                    return;
                }
            } else {
                throw err;
            }
        }
    }, [state.type, state.email, state.password, state.phoneNumber, state.firstName, state.lastName]);

const useHandleOtp = (
    state: RegistrationState,
    onLogin: (guest: LXS.Guest) => void,
    termsAndConditionsVersion: string,
    privacyPolicyVersion: string,
    handleRedirect: () => void,
    knownDetails?: Partial<LXS.BasicGuestDetails>,
    onTermsAccepted?: () => void,
) =>
    React.useCallback(
        async (code: string): Promise<void> => {
            if (state.type !== RegistrationScreen.Otp) {
                // Check is required for type validation
                throw new Error(`Invalid state of component, expected ${RegistrationScreen.Otp}, got ${state.type}`);
            }
            try {
                const guest = await IdentityProvider.current.submitOtpAsync(false, state.authId, code);

                const details = await guest.getOrUpdateDetailsAsync({
                    termsAndConditionsVersion,
                    privacyPolicyVersion,
                    ...(knownDetails?.accountId || knownDetails?.vehicleOwnershipId
                        ? {
                              salesforceAccountId: knownDetails?.accountId,
                              vehicleOwnershipId: knownDetails?.vehicleOwnershipId || undefined,
                          }
                        : {}),
                    ...(knownDetails && knownDetails?.mobileNumber !== state.phoneNumber
                        ? { mobileNumber: state.phoneNumber }
                        : {}),
                });

                if (
                    details &&
                    details.termsAndConditionsVersion === termsAndConditionsVersion &&
                    details.privacyPolicyVersion === privacyPolicyVersion
                ) {
                    onTermsAccepted?.();
                    onLogin(guest);
                } else {
                    throw new AuthenticationError(new Set([AuthenticationErrorType.TnCsInvalid]));
                }
            } catch (err) {
                if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                    throw new SessionTimeoutError(handleRedirect, strings.timeoutMessage);
                }
                throw err;
            }
        },
        [
            state.type,
            state.authId,
            state.phoneNumber,
            termsAndConditionsVersion,
            privacyPolicyVersion,
            knownDetails?.accountId,
            knownDetails?.vehicleOwnershipId,
            knownDetails?.mobileNumber,
        ],
    );

const useHandleOtpResend = (
    state: RegistrationState,
    dispatch: React.Dispatch<RegistrationAction>,
    handleRedirect: () => void,
) =>
    React.useCallback(async () => {
        if (state.type !== RegistrationScreen.Otp) {
            throw new Error(`Invalid state of component, expected ${RegistrationScreen.Otp}, got ${state.type}`);
        }
        try {
            const authId = await IdentityProvider.current.requestOtpAsync(state.authId);
            dispatch({ type: RegistrationScreen.Otp, authId });
        } catch (err) {
            if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                throw new SessionTimeoutError(handleRedirect, strings.timeoutMessage);
            }
            throw err;
        }
    }, [state.type, state.authId]);

const Registration: React.FC<RegistrationProps> = ({
    onLogin,
    onTermsAccepted,
    termsAndPrivacyConfig: { termsAndConditionsVersion, termsAndConditionsUrl, privacyPolicyVersion, privacyPolicyUrl },
    onSignInRequired,
    signInHref,
    knownBasicDetails,
    guestEmailRef,
}) => {
    const [state, dispatch] = React.useReducer(registrationReducer, {
        type: RegistrationScreen.Main,
        firstName: knownBasicDetails?.firstname,
        lastName: knownBasicDetails?.lastname,
        email: knownBasicDetails?.email || guestEmailRef?.current,
        phoneNumber: knownBasicDetails?.mobileNumber || undefined,
    });
    const setError = React.useCallback((error?: Error) => dispatch({ type: RegistrationScreen.Main, error }), []);
    const handleBack = React.useCallback(() => dispatch({ type: RegistrationScreen.Previous }), []);
    const handleRedirectToSignIn = React.useCallback(() => onSignInRequired && onSignInRequired(), []);
    const handleTnCs = useHandleTnCs(state, dispatch);
    const handleOtp = useHandleOtp(
        state,
        onLogin,
        termsAndConditionsVersion,
        privacyPolicyVersion,
        handleRedirectToSignIn,
        knownBasicDetails,
        onTermsAccepted,
    );
    const handleRegister = useHandleRegister(dispatch, setError, knownBasicDetails?.mobileNumber || undefined);
    const handleOtpResend = useHandleOtpResend(state, dispatch, handleRedirectToSignIn);

    switch (state.type) {
        case RegistrationScreen.Main:
            return (
                <>
                    <BackButton />
                    <RegistrationForm
                        error={state.error}
                        setError={setError}
                        onSubmit={handleRegister}
                        signInHref={signInHref}
                        onSignInRequired={onSignInRequired}
                        initialFirstName={state.firstName}
                        initialLastName={state.lastName}
                        initialEmail={state.email}
                        initialMobile={state.phoneNumber}
                        lockKnownFields={!!knownBasicDetails}
                        guestEmailRef={guestEmailRef}
                    />
                </>
            );
        case RegistrationScreen.TermsAndConditions:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <TermsAndConditionsForm
                        onAccept={handleTnCs}
                        buttonText="accept"
                        privacyTitle="Privacy Policy"
                        termsTitle="Terms of Use"
                        privacyUrl={privacyPolicyUrl}
                        termsUrl={termsAndConditionsUrl}
                    />
                </>
            );
        case RegistrationScreen.Otp:
            return (
                <>
                    <BackButton />
                    <VerificationForm email={state.email} onSubmit={handleOtp} onResendRequired={handleOtpResend} />
                </>
            );
        default:
            return <h2>Unknown error</h2>;
    }
};

export { Registration };

import { useEffect } from "react";

export interface MessageWithType<T extends string> {
  type: T;
  message: string;
}

export const useWindowMessage = <T extends string, M extends MessageWithType<T>>(
  type: T,
  callback?: (message?: M) => void
): void => {
  useEffect(() => {
    const handleMessage = (ev: MessageEvent<M>) => {
      if (ev.data.type === type) {
        if (callback) {
          callback(ev.data);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [type, callback]);
};

const defaultAnalyticDataAttributesPrefix: AnalyticAttributePrefix = "data-analytics-";
type AnalyticAttributePrefix = `data-${string}-`;
interface AttributesObject {
  [key: string]: string;
}

const extractDataAnalyticAttributes = (
  element: Element,
  prefix: AnalyticAttributePrefix = defaultAnalyticDataAttributesPrefix
): AttributesObject =>
  Array.from(element.attributes)
    .filter(
      (attr) =>
        attr && attr.name && attr.value && attr.name.slice(prefix.length).toLowerCase() === prefix
    )
    .reduce<AttributesObject>((result, attr) => {
      result[attr.name.slice(prefix.length)] = attr.value;
      return result;
    }, {});

export { extractDataAnalyticAttributes };
export type { AttributesObject };

import "../wdyr.config";
import "../sfPersonalisation.config";
import "../newrelic.config";
import "lexus-style-guide/icons/load-icons.scss";
import "lexus-style-guide/typography/load-fonts.scss";
import "./index.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./App/App";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import { store } from "./reduxInit";

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById("root")
);

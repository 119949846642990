import * as React from "react";
import classnames from "classnames";
import "./MainMenuDesktopSecondaryNav.scss";
import { useGTM } from "../../../GTM/useGTM";
import { MainMenuDesktopContext } from "./MainMenuDesktop";

const delayMultiplier = 50;

interface MainMenuSecondaryNavProps {
    active: boolean;
    items: LXS.SecondaryMenuItem[];
    image?: LXS.FocalpointImageFieldValue;
}

const MainMenuDesktopSecondaryNav: React.FunctionComponent<MainMenuSecondaryNavProps> = ({ active, items, image }) => {
    const { gtmClick } = useGTM();
    const { setDisplayImage } = React.useContext(MainMenuDesktopContext);

    const handleMouseEnter = () => setDisplayImage({ image, active: true });

    const handleMouseLeave = () => setDisplayImage({ image, active: false });

    return (
        <ul
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={classnames("lxs-main-menu-desktop-secondary-nav", {
                "lxs-main-menu-desktop-secondary-nav--active": active,
            })}
        >
            {items.map((item, index) => {
                return (
                    <li
                        key={`${item.title}-${index}`}
                        className="lxs-main-menu-desktop-secondary-nav__list-item"
                        style={{ animationDelay: `${index * delayMultiplier}ms` }}
                    >
                        <a
                            className="lxs-main-menu-desktop-secondary-nav__item"
                            href={item.href}
                            onClick={gtmClick}
                            data-gtm-target={item.title}
                            data-gtm-action={item.href}
                            data-gtm-linktype="SecondaryLink"
                        >
                            <span className="lxs-main-menu-desktop-secondary-nav__item-text">{item.title}</span>
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export { MainMenuDesktopSecondaryNav };

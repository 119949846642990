import isChromatic from "chromatic/isChromatic";
import cn from "classnames";
import { Tab as TabTitle, Tabs, TabList, TabPanel, TabsProps } from "react-tabs";
import "./Tab.scss";

export type TabProps = TabsProps;
const Tab: React.FC<TabProps> = ({ selectedTabPanelClassName, ...props }) => {
  return (
    <Tabs
      selectedTabPanelClassName={cn(
        selectedTabPanelClassName,
        "react-tabs__tab-panel--selected",
        !isChromatic() && "react-tabs__tab-panel--selected--transition"
      )}
      {...props}
    />
  );
};

export { Tab, TabList, TabPanel, TabTitle };

import cn from "classnames";

import styles from "./ProgressStepper.module.scss";
import { Chip } from "../Chip";
import { Divider } from "../Divider";
import { Stack } from "../Stack";
import { Typography } from "../Typography/Typography";

type ProgressStepperProps = React.HTMLAttributes<HTMLDivElement>;

const ProgressStepper: React.FC<ProgressStepperProps> = ({ children, className, ...rest }) => {
  return (
    <Stack
      direction="row"
      spacing="none"
      flexWrap="wrap"
      className={cn(styles.progressStepper, className)}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export type ProgressStepProps = React.HTMLAttributes<HTMLDivElement> & {
  step?: number;
  selected?: boolean;
  success?: boolean;
};

export const ProgressStep: React.FC<ProgressStepProps> = ({
  children,
  className,
  step,
  selected,
  success,
  ...rest
}) => {
  return (
    <>
      <Stack
        className={cn(styles.progressStep, className)}
        direction="column"
        spacing="4xs"
        {...rest}
      >
        <Stack
          spacing="4xs"
          justifyContent="center"
          alignItems="center"
          className={styles.progressStepperInner}
        >
          <div className={styles.chipContainer}>
            <Chip
              state={success ? "success" : selected ? "selected" : "enabled"}
              size="large"
              type="ghost"
              className={styles.chip}
            >
              {step}
            </Chip>
          </div>
        </Stack>

        <div className={cn(styles.text, { [styles.disabled]: !selected })}>
          <Typography variant="l1" className={styles.text}>
            {children}
          </Typography>
        </div>
      </Stack>
      <div className={styles.divider}>
        <Divider />
      </div>
    </>
  );
};

export { ProgressStepper };

import cn from "classnames";

import { SVGIconWrapper } from "@/components/SVGIcon/SVGIconWrapper";

import styles from "./SVGIconLink.module.scss";

export enum SVGIconLinkIconPosition {
  LEFT = "left",
  RIGHT = "right",
  LEFT_TO_RIGHT = "leftToRight",
}

type SVGIconLinkProps = React.PropsWithChildren<{
  /**
   * The label for the link
   */
  children: React.ReactNode;
  /**
   * Extra class to apply to the link component
   */
  className?: string;
  /**
   * Href attribute for the link component
   */
  href?: string;
  /**
   * The icon to display
   */
  icon: React.ReactNode;
  /**
   * Extra class to apply to the icon
   */
  iconClass?: string;
  /**
   * The position of the icon relative to the text
   */
  iconPosition: SVGIconLinkIconPosition;
  /**
   * The component to use for the link. Defaults to "a".
   */
  linkComponent?: React.ComponentType;
  onClick?: () => void;
  /**
   * Rel attribute for the link component
   */
  rel?: string;
  /**
   * Target attribute for the link component
   */
  target?: string;
  /**
   * Extra class to apply to the text wrapper
   */
  textClass?: string;
  /*
   * Variant changes the style of the icon link
   */
  variant?: "default" | "alt" | "static";
}>;

const SVGIconLink: React.FC<SVGIconLinkProps> = ({
  className,
  children,
  icon,
  iconClass,
  iconPosition,
  linkComponent,
  onClick,
  textClass,
  variant = "default",
  ...rest
}) => {
  const Link = linkComponent || "a";

  const posSVGIcon = (iconPos: SVGIconLinkIconPosition) => (
    <SVGIconWrapper
      className={cn(
        {
          [styles.iconLeft]:
            iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT &&
            iconPos === SVGIconLinkIconPosition.LEFT,
          [styles.iconRight]:
            iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT &&
            iconPos === SVGIconLinkIconPosition.RIGHT,
        },
        iconClass
      )}
    >
      {icon}
    </SVGIconWrapper>
  );

  return (
    <Link
      className={cn(
        styles.link,
        {
          [styles.linkLeftToRight]: iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT,
          [styles[`${variant}Variant`]]: variant,
        },
        className
      )}
      onClick={onClick}
      data-testid="lk-svg-icon-link"
      {...rest}
    >
      {(iconPosition === SVGIconLinkIconPosition.LEFT ||
        iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT) &&
        posSVGIcon(SVGIconLinkIconPosition.LEFT)}

      <span className={cn(styles.text, textClass)}>{children}</span>

      {(iconPosition === SVGIconLinkIconPosition.RIGHT ||
        iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT) &&
        posSVGIcon(SVGIconLinkIconPosition.RIGHT)}
    </Link>
  );
};

export { SVGIconLink };

import * as React from "react";
import classNames from "classnames";
import { SVGIcon } from "../SVGIcons/SVGIcon";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import "./index.scss";
import { getSitecoreFieldValue } from "../../utilities/Sitecore/SitecoreUtilities";
import { useGTM } from "../GTM/useGTM";

export enum SVGIconLinkIconPosition {
    LEFT = "left",
    RIGHT = "right",
    LEFT_TO_RIGHT = "leftToRight",
}

const SVGIconLink: React.FC<LXS.SVGIconLinkProps> = ({
    iconPosition,
    textClass,
    containerClass,
    onClick,
    iconClass,
    ...rest
}) => {
    const { gtmClick } = useGTM();
    if (!rest.field) {
        return null;
    }

    const onClickWithGtm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        gtmClick(e);
        if (onClick !== undefined) {
            onClick(e);
        }
    };

    const linkFieldValue = getSitecoreFieldValue<SCJSS.LinkProps, SCJSS.LinkFieldValue>(rest);
    const posSVGIcon = (iconPos: SVGIconLinkIconPosition) => (
        <SVGIcon
            hasBackground={false}
            key={iconPos}
            className={classNames(
                {
                    "lxs-svgicon-link__icon-left-to-right--left":
                        iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT &&
                        iconPos === SVGIconLinkIconPosition.LEFT,
                },
                {
                    "lxs-svgicon-link__icon-left-to-right--right":
                        iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT &&
                        iconPos === SVGIconLinkIconPosition.RIGHT,
                },
                iconClass,
            )}
        >
            {rest.children}
        </SVGIcon>
    );
    const renderLinkText = () => (
        <span
            key="LinkText"
            className={classNames(
                {
                    "lxs-svgicon-link__icon-left-to-right--text":
                        iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT,
                },
                textClass,
            )}
        >
            {linkFieldValue && linkFieldValue.text}
        </span>
    );
    const renderLinkContent = () => [
        (iconPosition === SVGIconLinkIconPosition.LEFT || iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT) &&
            posSVGIcon(SVGIconLinkIconPosition.LEFT),
        renderLinkText(),
        (iconPosition === SVGIconLinkIconPosition.RIGHT || iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT) &&
            posSVGIcon(SVGIconLinkIconPosition.RIGHT),
    ];

    return (
        <Link
            data-gtm-target={linkFieldValue && linkFieldValue.text}
            data-gtm-action={linkFieldValue && linkFieldValue.href}
            {...(rest as SCJSS.LinkProps)}
            showLinkTextWithChildrenPresent={false}
            onClick={onClickWithGtm}
            className={classNames(
                { "lxs-svgicon-link__icon-left-to-right": iconPosition === SVGIconLinkIconPosition.LEFT_TO_RIGHT },
                "lxs-svgicon-link",
                containerClass,
            )}
        >
            {!((typeof rest.editable === "undefined" || rest.editable) && rest.field && rest.field.editableFirstPart) &&
                renderLinkContent()}
        </Link>
    );
};

export { SVGIconLink };

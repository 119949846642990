import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGArrowReturn: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.428 2.77324L7.32857 8.76469H20.7391V10.7647C20.7391 8.76469 20.7404 8.76469 20.7417 8.7647L20.7445 8.7647L20.7505 8.76472L20.7642 8.76483L20.7989 8.76538C20.8251 8.76593 20.8576 8.76689 20.8958 8.76854C20.9722 8.77184 21.0722 8.77793 21.1926 8.78907C21.4326 8.81129 21.7575 8.85404 22.1398 8.93665C22.8987 9.10064 23.9226 9.43025 24.9575 10.1012C27.1485 11.5216 29 14.2166 29 18.8823C29 23.548 27.1485 26.2431 24.9575 27.6635C23.9226 28.3344 22.8987 28.664 22.1398 28.828C21.7575 28.9106 21.4326 28.9534 21.1926 28.9756C21.0722 28.9868 20.9722 28.9928 20.8958 28.9961C20.8576 28.9978 20.8251 28.9988 20.7989 28.9993L20.7642 28.9999L20.7505 29L20.7445 29L20.7417 29C20.7404 29 20.7391 29 20.7391 27V29H0.999998V25H20.7203C20.7211 25 20.7221 24.9999 20.7231 24.9999C20.7384 24.9992 20.7729 24.9974 20.8237 24.9927C20.9261 24.9832 21.0903 24.9625 21.295 24.9183C21.71 24.8286 22.2514 24.6508 22.7816 24.3071C23.721 23.6981 25 22.3343 25 18.8823C25 15.4304 23.721 14.0666 22.7816 13.4576C22.2514 13.1138 21.71 12.9361 21.295 12.8464C21.0903 12.8022 20.9261 12.7815 20.8237 12.772C20.7729 12.7673 20.7384 12.7655 20.7231 12.7648C20.7221 12.7648 20.7211 12.7647 20.7203 12.7647H7.20721L12.4224 19.1706L9.32039 21.696L0.397869 10.7363L9.38194 0.180664L12.428 2.77324Z"
        />
    </svg>
    /* eslint-enable max-len */
);
SVGArrowReturn = React.memo(SVGArrowReturn);

export { SVGArrowReturn };

import * as React from "react";
import Toast from "../../components/Toast/Toast";
import OnDemandVehicleAlreadyBookedErrorCard from "../../components/errorCards/OnDemandVehicleAlreadyBookedErrorCard/OnDemandVehicleAlreadyBookedErrorCard";

const OnDemandVehicleAlreadyBookedErrorToast: React.FC = () => {
    return (
        <Toast>
            <OnDemandVehicleAlreadyBookedErrorCard />
        </Toast>
    );
};

export default OnDemandVehicleAlreadyBookedErrorToast;

import { isValidElement } from "react";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

/**
 * Returns true if an object contains a property, using Object.prototype.hasOwnProperty.
 * Unlike Object.prototype.hasOwnProperty, accepts types that are not object, preventing the need to type check.
 * Note: This method MAY return `false` for `Proxy` object if it does not implement
 * `getOwnPropertyDescriptor()`, be mindful of this when creating unit tests!
 */
export const hasProperty = <K extends PropertyKey, T extends { [P in K]?: T[P] }>(
  obj: T | unknown,
  propName: K
): obj is T extends { [P in K]?: T[P] } ? T : never =>
  !!(
    obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    Object.prototype.hasOwnProperty.call(obj, propName)
  );

/**
 * Validates if @param s is an injectable object (having using `inject()` method)
 * @param s unknown type to check
 * @returns `true` if @param s has `inject()` method, `false` otherwise
 */
export const isInjectable = (s: unknown): s is { inject: () => void } =>
  (typeof s === "object" && s && "inject" in s && typeof s.inject === "function") || false;

/**
 * Checks if @param s has defined truthy value
 * @param s parameter to check
 * @returns `true` if @param s is a valid truthy JS value, `false` otherwise
 */
export const hasValue = <T>(s: T | undefined | null): s is T => !!s;

export const isDefinedUnknownObject = <T>(value: unknown): value is DeepPartial<T> =>
  typeof value === "object" && value !== null;

export const isDefinedObject = <T extends object>(value: T | undefined | null): value is T =>
  isDefinedUnknownObject(value);

/**
 * Deeply checks if provided @param node is a valid React node (fully compatible with `React.ReactNode` type).
 * This type guard is extension to built-in React.isValidElement which checks if node is a component,
 * but doesn't check if it is a simple type.
 * @param node An `unknown` value to validate
 * @returns `true` if node or array f nodes is valid React node
 */
export const isValidReactNode = (node: unknown): node is React.ReactNode | React.ReactNode[] =>
  Array.isArray(node)
    ? node.every((n) => isValidReactNode(n))
    : isValidElement(node) ||
      node === undefined ||
      node === null ||
      typeof node === "boolean" ||
      typeof node === "string" ||
      typeof node === "number";

/**
 * Checks if unknown value is an element of the provided array
 * @param arr Array to check in
 * @param value Value to check in array
 * @returns `true` if value presented in the array
 */
export const isElementOfArray = <A extends readonly unknown[]>(
  arr: A,
  value: A[number] | unknown
): value is A[number] => arr.indexOf(value) > -1;

export const isKeyOf =
  <O extends Record<PropertyKey, unknown>>(obj: O) =>
  (key: PropertyKey): key is keyof O =>
    hasProperty(obj, key);

export const typedKeys = <O extends Record<PropertyKey, unknown>>(childrenSpec: O): (keyof O)[] =>
  Object.keys(childrenSpec).filter(isKeyOf(childrenSpec));

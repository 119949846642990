import * as React from "react";

interface GTMComponentContextProps {
    component?: string;
    customProps?: { [key: string]: object | number | string };
}

const GTMComponentContext = React.createContext<GTMComponentContextProps>({});

export { GTMComponentContext };

export const spacingMap = {
  least: "--ld-c-stack-gap-scaled-least",
  "even-less": "--ld-c-stack-gap-scaled-even-less",
  less: "--ld-c-stack-gap-scaled-less",
  default: "--ld-c-stack-gap-scaled-default",
  more: "--ld-c-stack-gap-scaled-more",
  "even-more": "--ld-c-stack-gap-scaled-even-more",
  most: "--ld-c-stack-gap-scaled-most",
  "5xl": "--ld-c-stack-gap-static-5xl",
  "4xl": "--ld-c-stack-gap-static-4xl",
  "3xl": "--ld-c-stack-gap-static-3xl",
  "2xl": "--ld-c-stack-gap-static-2xl",
  xl: "--ld-c-stack-gap-static-xl",
  l: "--ld-c-stack-gap-static-l",
  m: "--ld-c-stack-gap-static-m",
  s: "--ld-c-stack-gap-static-s",
  xs: "--ld-c-stack-gap-static-xs",
  "2xs": "--ld-c-stack-gap-static-2xs",
  "3xs": "--ld-c-stack-gap-static-3xs",
  "4xs": "--ld-c-stack-gap-static-4xs",
  none: "--ld-c-stack-gap-static-none",
};

export type SpacingScale = keyof typeof spacingMap;

export const mapSpacingToCSSVar = (spacing: SpacingScale): string => {
  return spacingMap[spacing];
};

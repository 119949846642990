import { Dispatch, SetStateAction, useEffect, useState } from "react";

const minPasswordLength = 8;
const maxPasswordLength = 16;

export class PasswordValidator {
    private _password: string;

    public constructor(password: string) {
        this._password = password;
    }

    public get password() {
        return this._password;
    }

    public get isLengthOk() {
        return this._password.length >= minPasswordLength && this._password.length <= maxPasswordLength;
    }

    public get isUppercaseOk() {
        return /[A-Z]/.test(this._password);
    }

    public get isLowercaseOk() {
        return /[a-z]/.test(this._password);
    }

    public get isAlphaNumericOk() {
        return /[0-9]/.test(this._password);
    }

    public get isSpecialCharacterOk() {
        return /[!~`@#\$%\^\&*\)\({}[\]\/|:',?<>+=._-]/.test(this._password);
    }

    public get isNoForbiddenCharacterOk() {
        return !/[\\"]/.test(this._password);
    }

    public get isPasswordValid() {
        return (
            this.isLengthOk &&
            this.isUppercaseOk &&
            this.isLowercaseOk &&
            this.isAlphaNumericOk &&
            this.isSpecialCharacterOk &&
            this.isNoForbiddenCharacterOk
        );
    }
}

export type UsePasswordCheck = (s: string) => [PasswordValidator, Dispatch<SetStateAction<string>>];

export const usePasswordCheck: UsePasswordCheck = (initialPassword = "") => {
    const [password, setPassword] = useState(initialPassword);

    const [inputDetail, setInputDetail] = useState(() => new PasswordValidator(password));
    useEffect(() => {
        setInputDetail(new PasswordValidator(password));
    }, [password]);

    return [inputDetail, setPassword];
};

// prettier-ignore
// eslint-disable-next-line max-len
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const maxEmailLength = 128;

export const isEmailValid = (email: string) =>
    emailRegex.test(String(email.trim()).toLowerCase()) && email.trim().length <= maxEmailLength;

export const phoneNumberPattern = RegExp(/^04[0-9]{8}$/);

const maxNameLength = 40;
const validNameRegex = RegExp(/^[a-z'’‘\s-]{1,40}$/i);

export const isNameLengthOk = (name: string) => name.length <= maxNameLength;
export const isNameCharactersOk = (name: string) => !/[^a-z'’‘\s-]/i.test(name);
export const isNameValid = (name: string) => validNameRegex.test(String(name));

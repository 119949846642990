import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGTwitter: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M8.2918 20.125C15.8371 20.125 19.9652 13.8723 19.9652 8.45155C19.9652 8.27577 19.9613 8.09608 19.9535 7.9203C20.7566 7.33955 21.4496 6.62021 22 5.79608C21.2521 6.12883 20.458 6.34615 19.6449 6.44061C20.5011 5.92742 21.1421 5.12123 21.4492 4.17147C20.6438 4.6488 19.763 4.98551 18.8445 5.16718C18.2257 4.50963 17.4075 4.07426 16.5164 3.92838C15.6253 3.78249 14.711 3.93421 13.9148 4.36008C13.1186 4.78595 12.4848 5.46225 12.1115 6.28443C11.7382 7.1066 11.6462 8.02885 11.8496 8.90858C10.2187 8.82674 8.62328 8.40309 7.16665 7.66508C5.71002 6.92708 4.42474 5.8912 3.39414 4.6246C2.87033 5.5277 2.71005 6.59637 2.94586 7.61341C3.18167 8.63045 3.79589 9.51954 4.66367 10.1C4.01219 10.0793 3.37498 9.9039 2.80469 9.58827V9.63905C2.8041 10.5868 3.13175 11.5055 3.73192 12.239C4.3321 12.9725 5.16777 13.4755 6.09687 13.6625C5.49338 13.8276 4.85999 13.8517 4.2457 13.7328C4.50788 14.5479 5.01798 15.2607 5.70481 15.7719C6.39164 16.2831 7.22093 16.5672 8.07695 16.5844C6.62369 17.7259 4.82848 18.3451 2.98047 18.3422C2.65274 18.3417 2.32533 18.3216 2 18.282C3.87738 19.4865 6.06128 20.1262 8.2918 20.125Z"
            fill={color}
        />
    </svg>
);
SVGTwitter = React.memo(SVGTwitter);

export { SVGTwitter };

import { MutableRefObject } from "react";

export enum ScrollDirection {
  up = "up",
  down = "down",
}

export enum WheelScrollConstants {
  threshold = 50,
  eventLapseTimeInMs = 225,
}

export type ActiveSlideIndex = number;
export type CurrentScrollDirection = MutableRefObject<ScrollDirection>;
export type HandleScrollToNextSlide = () => void;
export type IsMouseWheelMoving = boolean;
export type IsProgressPastLastSlide = boolean;
export type IsScrollAnimating = boolean;
export type MouseWheelTimer = NodeJS.Timeout | null;
export type RegisterInteraction = DataAnalyticsRegisterInteractionAction;
export type SetActiveSlideIndex = (index: ActiveSlideIndex) => void;
export type SetIsMouseWheelMoving = (moving: IsMouseWheelMoving) => void;
export type SetIsProgressPastLastSlide = (isPastLastSlide: IsProgressPastLastSlide) => void;
export type SetIsScrollAnimating = (animating: IsScrollAnimating) => void;
export type SetMouseWheelTimer = (timer: MouseWheelTimer) => void;
export type SlideNavItems = number[];
export type SlideRefs = MutableRefObject<HTMLLIElement[]>;

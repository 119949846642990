export enum SVGAnimationEvents {
  NONE = 0,
  CLICK = 1,
  HOVER = 2,
}

export enum SVGIconSizes {
  DEFAULT = 32,
  SMALL = 16,
  LARGE = 48,
}

export enum SVGEncoreLogoSizes {
  DEFAULT_WIDTH = 245,
  DEFAULT_HEIGHT = 60,
}

export interface SVGBaseProps {
  height?: number;
  width?: number;
  className?: string;
  color?: string;
}

export interface SVGAnimatedBaseProps {
  hasBackground?: boolean;
  active?: boolean;
  activeClassName?: string;
  containerRef?: React.RefObject<
    HTMLSpanElement | HTMLDivElement | HTMLButtonElement | SVGSVGElement
  >;
  animationEvent?: SVGAnimationEvents;
}

export type SVGStaticProps = SVGBaseProps & React.SVGProps<SVGSVGElement>;

export type SVGAnimatedProps = SVGBaseProps & SVGAnimatedBaseProps;

import * as React from "react";
import "./ModelsMenuDesktop.scss";
import { SVGIconButton, SVGIconButtonIconPositions } from "../../../SVGIcons/SVGIconButton";
import { Theme } from "../../../shared/Theme";
import { ModelsMenuDesktopBodyTypes } from "./ModelsMenuDesktopBodyTypes";
import { ModelsMenuDesktopLinks } from "./ModelsMenuDesktopLinks";
import { PrimaryNavContext } from "../../PrimaryNav";
import { ModelsMenuVehiclesFilter } from "../ModelsMenuVehiclesFilter";
import { GTMComponentContext } from "../../../GTM/GTMComponentContext";
import { SVGCross } from "../../../SVGIcons/icons/static/SVGCross";
import { SVGIcon } from "../../../SVGIcons/SVGIcon";
import { lxColor } from "../../../../colors/variables";
import { useSelectedTags } from "../shared";

const ModelsMenuDesktop: React.FC = () => {
    const { handleModelClose } = React.useContext(PrimaryNavContext);
    const [selectedTagIds, onTagSelected] = useSelectedTags();

    return (
        <GTMComponentContext.Provider value={{ component: "ModelsMenuDesktop" }}>
            <div className="lxs-grid-row lxs-models-menu-desktop">
                <div className="lxs-grid lxs-grid--with-margin lxs-models-menu-desktop__layout-top">
                    <div className="lxs-models-menu-desktop__header">
                        <div className="lxs-models-menu-desktop__close-button">
                            <SVGIconButton
                                title="Close"
                                iconPosition={SVGIconButtonIconPositions.LEFT}
                                stackOnMobile={true}
                                onClick={handleModelClose}
                                theme={Theme.LIGHT}
                                data-gtm-target={"CloseButton"}
                            >
                                <SVGIcon hasBackground={true}>
                                    <SVGCross height={20} width={20} color={lxColor("black", "absolute")} />
                                </SVGIcon>
                            </SVGIconButton>
                        </div>
                        <div className="lxs-models-menu-desktop__filter">
                            <ModelsMenuVehiclesFilter selectedTagIds={selectedTagIds} onSelect={onTagSelected} />
                        </div>
                    </div>
                </div>
                <div className="lxs-grid lxs-grid--with-margin">
                    <div className="lxs-models-menu-desktop__layout-left">
                        <div className="lxs-models-menu-desktop__body">
                            <ModelsMenuDesktopBodyTypes selectedTagIds={selectedTagIds} />
                        </div>
                    </div>
                    <div className="lxs-models-menu-desktop__layout-right">
                        <ModelsMenuDesktopLinks />
                    </div>
                </div>
            </div>
        </GTMComponentContext.Provider>
    );
};

export { ModelsMenuDesktop };

import { useState } from "react";

import { MessageWithType, useWindowMessage } from "@/hooks/useWindowMessage";

import { clearIdToken, storeIdToken } from "../utils";

export const useRefreshingToken = (autoLogout = true) => {
  type SuccessRefresh = MessageWithType<"refresh-success">;

  const [isRefreshingToken, setIsRefreshingToken] = useState(true);
  const handleRefreshSuccess = (message?: SuccessRefresh) => {
    if (!message?.message) {
      return;
    }

    storeIdToken(message.message);
    setIsRefreshingToken(false);
  };

  const handleRefreshFailure = () => {
    if (autoLogout) {
      clearIdToken();
    }

    setIsRefreshingToken(false);
  };

  useWindowMessage("refresh-success", handleRefreshSuccess);
  useWindowMessage("refresh-failure", handleRefreshFailure);

  return isRefreshingToken;
};

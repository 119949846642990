import { WheelScrollConstants, VerticalScrollDirection } from "./Constants";

const delayScroll = (
    deltaY: number,
    scroll: LXS.WheelScrollRefType,
    isScrolling: boolean,
    scrollHandler: (direction: VerticalScrollDirection, shouldScroll: boolean) => void,
) => {
    const absDeltaY = Math.abs(deltaY);
    if (absDeltaY - scroll.lastDeltaY > WheelScrollConstants.threshold && !isScrolling) {
        scroll.scrolling = false;
    }
    scroll.lastDeltaY = absDeltaY;
    scroll.timeout && clearTimeout(scroll.timeout);
    scroll.timeout = setTimeout(() => {
        scroll.scrolling = false;
        scroll.lastDeltaY = 0;
    }, WheelScrollConstants.eventLapseTimeInMs);
    if (!scroll.scrolling) {
        scroll.scrolling = true;
        scrollHandler(deltaY > 0 ? VerticalScrollDirection.DOWN : VerticalScrollDirection.UP, true);
    } else {
        scrollHandler(VerticalScrollDirection.DOWN, false);
    }
};

export { delayScroll };

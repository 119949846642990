import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";

import { RouteSection } from "Helpers/routes";
import Spinner from "../../components/Spinner/Spinner";
import { routeString } from "Helpers/routes";
import SilentSignOut from "Pages/SignOut/SilentSignOut";
import { getLatestVerifiedVehicle } from "Helpers/vehicle";
import { useVehicles } from "Hooks/useVehicles";
import { encoreTierSelector, setDashboardBannerStatesAction, setIsMembershipEnabledAction } from "ReduxSlices/user";
import { useThunkDispatch } from "Hooks/thunk";
import { useSettingsPromise } from "Hooks/usePromiseState";
import { BreakpointContext } from "Context/BreakpointContext";
import PlatinumBenefitsExpiring from "Pages/PlatinumBenefitsExpiring/PlatinumBenefitsExpiring";
import { mapEncorePackageToBannerContent } from "Pages/Dashboard/DashboardBanner/utils";
import { useSelector } from "react-redux";
import { UserEncoreStatus, UserEncoreTiers } from "Helpers/users";
import { IS_SHOWN_BANNER, IS_SHOWN_EXTRA_BANNER } from "../../constants";

const Refresh = lazy(() => import("../../pages/Refresh/Refresh"));
const DashboardRoute = lazy(() => import("../../pages/Dashboard/Dashboard"));
const Encore = lazy(() => import("../../pages/Encore/Encore"));
const Vehicle = lazy(() => import("../../pages/Vehicle/Vehicle"));
const AccountSettings = lazy(() => import("../../pages/AccountSettings/AccountSettings"));
const SignOut = lazy(() => import("../../pages/SignOut/SignOut"));
const LinkExpiredErrorPage = lazy(() => import("../../pages/LinkExpiredErrorPage/LinkExpiredErrorPage"));

const PageRoutes = (): JSX.Element => {
    const vehicle = getLatestVerifiedVehicle(useVehicles(true) || []);
    const isMobile = useContext(BreakpointContext);
    const encoreTier = useSelector(encoreTierSelector) as UserEncoreTiers;
    const dispatch = useThunkDispatch();
    dispatch(setIsMembershipEnabledAction(!!useSettingsPromise(settings => settings.general.isMembershipEnabled)));
    const isShownBanner = sessionStorage.getItem(IS_SHOWN_BANNER);
    const isShownExtraBanner = sessionStorage.getItem(IS_SHOWN_EXTRA_BANNER);
    const { bannerState, extraBannerState } = mapEncorePackageToBannerContent(encoreTier, vehicle) || {};
    useEffect(() => {
        if (bannerState) {
            isShownBanner === null && sessionStorage.setItem(IS_SHOWN_BANNER, JSON.stringify(bannerState.showBanner));
            dispatch(
                setDashboardBannerStatesAction({
                    membershipStatus: bannerState.status,
                    expiryDate: bannerState.packageExpiryDate,
                    paymentLink: bannerState.packagePurchasePaylink,
                    ctaDisabled: bannerState.disableCta,
                    hideCta: bannerState.hideCta,
                    showBanner: isShownBanner !== null ? JSON.parse(isShownBanner) : bannerState.showBanner,
                })
            );
        }

        if (extraBannerState) {
            const bannerStatus = bannerState ? bannerState.status : "";
            const isBannerShowing = bannerState ? bannerState.showBanner : false;
            const showingMainBanner = isShownBanner !== null ? JSON.parse(isShownBanner) : isBannerShowing;
            const isMainBannerSame: boolean = bannerStatus === extraBannerState.status && showingMainBanner;
            isShownExtraBanner === null &&
                sessionStorage.setItem(IS_SHOWN_EXTRA_BANNER, JSON.stringify(!isMainBannerSame));
            dispatch(
                setDashboardBannerStatesAction({
                    extraBannerContent: {
                        showExtraBanner:
                            isShownExtraBanner !== null && !isMainBannerSame
                                ? JSON.parse(isShownExtraBanner)
                                : !isMainBannerSame,
                        membershipStatus: extraBannerState.status,
                    },
                })
            );
        }
    }, []);

    return (
        <>
            {!isMobile && <PlatinumBenefitsExpiring />}
            <Suspense fallback={<Spinner />}>
                <Switch>
                    <Route path={routeString(RouteSection.Refresh)} component={Refresh} />
                    <Route path={routeString(RouteSection.SilentSignOut)} component={SilentSignOut} />
                    <Route path={routeString(RouteSection.Dashboard)} component={DashboardRoute} />
                    <Route
                        path={routeString(RouteSection.PlatinumBenefitsExpiring)}
                        component={PlatinumBenefitsExpiring}
                    />
                    {!(bannerState?.status === UserEncoreStatus.EXPIRED) && (
                        <Route path={routeString(RouteSection.Encore)} component={Encore} />
                    )}
                    <Route path={routeString(RouteSection.Vehicle)} component={Vehicle} />
                    <Route path={routeString(RouteSection.AccountSettings)} component={AccountSettings} />
                    <Route path={routeString(RouteSection.SignOut)} component={SignOut} />
                    <Route path={routeString(RouteSection.LinkExpiredError)} component={LinkExpiredErrorPage} />
                    <Redirect to={routeString(RouteSection.Dashboard)} />
                </Switch>
            </Suspense>
        </>
    );
};
export default PageRoutes;

import "./Toast.scss";

import React, { FunctionComponent, MouseEventHandler } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { SVGCross } from "lexus-style-guide/Components/SVGIcons/icons/static/SVGCross";
import classnames from "classnames";
import { closeToastUrl } from "../../helpers/routes";
import { createBemFn } from "lexus-style-guide/utilities/bem";
import { lxColor } from "lexus-style-guide/colors/variables";

const bem = createBemFn("toast");

type SideEffectFunction = () => void;

interface Props extends RouteComponentProps {
    close?: SideEffectFunction | boolean;
}

const Toast: FunctionComponent<Props> = ({ close: closeProp = false, history, children, location }) => {
    const hideCloseButton = closeProp === false;
    const close: MouseEventHandler<SVGElement> = () => {
        if (closeProp === true) {
            history.push(closeToastUrl(location.pathname));
        } else if (typeof closeProp === "function") {
            closeProp();
        }
    };

    return (
        <div className={classnames(bem(), { [bem(undefined, "hide-close-button")]: hideCloseButton })}>
            <div className={bem("content")}>
                <SVGCross
                    className={bem("close")}
                    height={14}
                    width={14}
                    onClick={close}
                    color={lxColor("black", "absolute")}
                />
                {children}
            </div>
        </div>
    );
};

export default withRouter(Toast);

import classnames from "classnames";
import "./PasswordValidationBlock.scss";
import * as React from "react";
import { PasswordValidator } from "../../../utilities/validations";
import { createBemFn } from "../../../utilities/bem";

interface Props {
    validator: PasswordValidator;
}

const bem = createBemFn("lxs-password-validations");

export const PasswordValidationBlock: React.FC<Props> = ({ validator }) => (
    <div className={bem()}>
        <div className={bem("block")}>
            <span
                className={classnames(
                    bem("icon-checkmark"),
                    "icon-checkmark",
                    validator.isLengthOk && "icon-checkmark--valid",
                )}
            />
            Use between 8 and 16 characters
        </div>
        <div className={bem("block")}>
            <span
                className={classnames(
                    bem("icon-checkmark"),
                    "icon-checkmark",
                    validator.isUppercaseOk && "icon-checkmark--valid",
                )}
            />
            Use at least 1 uppercase letter
        </div>
        <div className={bem("block")}>
            <span
                className={classnames(
                    bem("icon-checkmark"),
                    "icon-checkmark",
                    validator.isLowercaseOk && "icon-checkmark--valid",
                )}
            />
            Use at least 1 lowercase letter
        </div>
        <div className={bem("block")}>
            <span
                className={classnames(
                    bem("icon-checkmark"),
                    "icon-checkmark",
                    validator.isAlphaNumericOk && "icon-checkmark--valid",
                )}
            />
            Use at least 1 number
        </div>
        <div className={bem("block")}>
            <span
                className={classnames(
                    bem("icon-checkmark"),
                    "icon-checkmark",
                    validator.isSpecialCharacterOk && "icon-checkmark--valid",
                )}
            />
            Use at least 1 special character
        </div>
    </div>
);

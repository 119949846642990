import cn from "classnames";
import jump from "jump.js";
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ScrollIndicator } from "@/components/ScrollIndicator/ScrollIndicator";
import { useAnalytics } from "@/utils/DataAnalytics";

import { ScrollDirection } from "./types";
import {
  useScrollToNextSlide,
  useMouseWheelScroll,
  useKeyDown,
  useScroll,
  useBodyOverflowHidden,
} from "./utils";
import styles from "./VerticalScroll.module.scss";

export type VerticalScrollProps = React.PropsWithChildren<{
  isDisabled?: boolean;
}>;

export const VerticalScroll: React.FC<VerticalScrollProps> = ({ children, isDisabled = false }) => {
  const { registerInteraction } = useAnalytics();

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isMouseWheelMoving, setIsMouseWheelMoving] = useState(false);
  const [isProgressPastLastSlide, setIsProgressPastLastSlide] = useState(false);
  const [isScrollAnimating, setIsScrollAnimating] = useState(false);
  const [mouseWheelTimer, setMouseWheelTimer] = useState<NodeJS.Timeout | null>(null);

  const slideRefs = useRef<HTMLLIElement[]>([]);
  const slideNavItems = Array.from({ length: Children.count(children) }, (_, i) => i);
  const scrollDirection = useRef<ScrollDirection>(ScrollDirection.down);

  const isBodyOverflowHidden = useBodyOverflowHidden();

  const disableInteractions = isDisabled || isBodyOverflowHidden || slideNavItems.length < 2;

  const handleScrollToNextSlide = useScrollToNextSlide({
    activeSlideIndex,
    isProgressPastLastSlide,
    setIsProgressPastLastSlide,
    registerInteraction,
    scrollDirection,
    setIsScrollAnimating,
    slideNavItems,
    slideRefs,
  });

  const handleMouseWheelScroll = useMouseWheelScroll({
    isMouseWheelMoving,
    isScrollAnimating,
    mouseWheelTimer,
    handleScrollToNextSlide,
    scrollDirection,
    setIsMouseWheelMoving,
    setMouseWheelTimer,
  });

  const handleKeyDown = useKeyDown({
    isMouseWheelMoving,
    isScrollAnimating,
    handleScrollToNextSlide,
    scrollDirection,
  });

  const handleScroll = useScroll({
    setActiveSlideIndex,
    slideRefs,
  });

  useEffect(() => {
    if (disableInteractions) return;

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("wheel", handleMouseWheelScroll, { passive: false });
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("wheel", handleMouseWheelScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [disableInteractions, handleMouseWheelScroll, handleKeyDown, handleScroll]);

  // Clone children and wrap them in a list item
  const clonedChildren = useMemo(
    () =>
      Children.map(children, (child, index) =>
        isValidElement(child) ? (
          <li
            className={styles.listItem}
            ref={(el: HTMLLIElement) => {
              slideRefs.current[index] = el;
            }}
            key={index}
            data-testid="lk-vertical-scroll-slide"
          >
            {cloneElement(child)}
          </li>
        ) : null
      ),
    [children]
  );

  const handleScrollToSlide = (index: number) => () => {
    scrollDirection.current = index > activeSlideIndex ? ScrollDirection.down : ScrollDirection.up;

    setIsScrollAnimating(true);

    jump(slideRefs.current[index], {
      callback: () => setIsScrollAnimating(false),
    });
  };

  return (
    <div className={styles.wrapper} data-testid="lk-vertical-scroll">
      {slideNavItems.length > 1 && (
        <nav
          className={cn(styles.nav, {
            [styles.navHidden]: isProgressPastLastSlide,
            [styles.navAnimateDown]: scrollDirection.current === ScrollDirection.down,
            [styles.navAnimateUp]: scrollDirection.current === ScrollDirection.up,
          })}
          aria-label="Vertical scroll navigation"
          data-testid="lk-vertical-scroll-nav"
        >
          <ol className={styles.navList}>
            {slideNavItems.map((key) => (
              <li key={key} data-testid="lk-vertical-scroll-nav-item">
                <button
                  type="button"
                  className={cn(styles.navItemButton, {
                    [styles.navItemButtonActive]: key === activeSlideIndex,
                  })}
                  onClick={handleScrollToSlide(key)}
                  aria-label={`Scroll to slide ${key + 1}`}
                >
                  <span className={styles.navItemBar} />
                </button>
              </li>
            ))}
          </ol>
        </nav>
      )}

      <ul>{clonedChildren}</ul>

      <span
        className={cn(styles.scrollIndicator, {
          [styles.scrollIndicatorHidden]: activeSlideIndex > 0,
        })}
        data-testid="lk-vertical-scroll-indicator"
      >
        <ScrollIndicator onClick={handleScrollToSlide(1)} />
      </span>
    </div>
  );
};

VerticalScroll.displayName = "VerticalScroll";

export default VerticalScroll;

import * as React from "react";
import { Button } from "../../../buttons/Button";
import { createBemFn } from "../../../utilities/bem";
import { Spinner } from "../../Spinner/Spinner";
import { GenericError } from "./GenericError";
import "./SetPasswordSuccess.scss";

const bem = createBemFn("lxs-set-password-success");

interface SetPasswordSuccessProps {
    onContinue: () => Promise<void>;
}

const SetPasswordSuccess: React.FC<SetPasswordSuccessProps> = ({ onContinue }) => {
    const [processing, setProcessing] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    const handleContinue = () => {
        setProcessing(true);
        onContinue()
            .then(() => setError(undefined))
            .catch((err) => {
                setError(err instanceof Error ? err : new Error());
                setProcessing(false);
            });
    };

    const handleToastClose = React.useCallback(() => setError(undefined), []);

    return (
        <div className={bem()}>
            <h2 className={bem("title", "no-senkei")}>Reset Password</h2>
            <p className={bem("header-text")}>Your password has been successfully reset.</p>
            <Button disabled={processing} className={bem("button")} type="button" onClick={handleContinue}>
                Continue
            </Button>
            {error && <GenericError isOpen={!!error} error={error} onClose={handleToastClose} />}
            {processing && <Spinner />}
        </div>
    );
};

const SetPasswordSuccessMemoized = React.memo(SetPasswordSuccess);

export { SetPasswordSuccessMemoized as SetPasswordSuccess };

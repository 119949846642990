import * as React from "react";
import "./AccountToast.scss";
import { Toast } from "../../Toast/Toast";
import { Button } from "../../..";
import { LinkButtonWithIcon } from "../../../buttons/IconButton";
import { SVGPhone } from "../../SVGIcons/icons/static/SVGPhone";
import { ButtonShade, ButtonStyle } from "../../../buttons/shared";
import { createBemFn } from "../../../utilities/bem";
import { lxColor } from "../../../colors/variables";

const bem = createBemFn("lxs-account-toast");

interface AccountToastProps {
    title: string;
    message: React.ReactNode;
    isOpen: boolean;
    onClose?: () => void;
    secondMessage?: string;
    buttonText?: string;
    email?: string;
    phoneNumber?: string;
    caseNumber?: string;
}

const darkIcon = {
    Component: SVGPhone,
    props: {
        color: lxColor("deep-blue"),
    },
};

const cleanPhoneNumber = (phone: string) => phone.replace(/[^0-9+]/g, "");

const AccountToast: React.FC<AccountToastProps> = ({
    title,
    message,
    isOpen,
    onClose,
    buttonText,
    secondMessage,
    phoneNumber,
    caseNumber,
}) => (
    <Toast title={title} isOpen={isOpen} hideClose={true} shouldCloseOnOverlayClick={false} contentClass={bem()}>
        <div>{message}</div>
        <Button
            buttonStyle={ButtonStyle.Primary}
            shade={ButtonShade.Dynamic}
            onClick={onClose}
            className={bem("button")}
        >
            {buttonText}
        </Button>
        {caseNumber && (
            <div>
                CASE NUMBER: <strong>{caseNumber}</strong>
            </div>
        )}
        {secondMessage && <div>{secondMessage}</div>}
        {phoneNumber && (
            <LinkButtonWithIcon
                buttonStyle={ButtonStyle.Secondary}
                shade={ButtonShade.Light}
                icon={darkIcon}
                className={bem("button")}
                href={`tel:${cleanPhoneNumber(phoneNumber)}`}
            >
                {phoneNumber}
            </LinkButtonWithIcon>
        )}
    </Toast>
);

const AccountToastMemoised = React.memo(AccountToast);

export { AccountToastMemoised as AccountToast };

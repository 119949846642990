const globalButtonMouseMove = (event: MouseEvent) => {
    const target = event.target as HTMLElement | null;
    if (!target || !target.style || !target.classList || !target.classList.contains("lxs-button")) {
        return;
    }
    const rect = target.getBoundingClientRect();
    const posX = `calc( ${event.clientX - rect.left}px - (${rect.width}px - 100%) / 2 )`;
    const posY = `calc( ${event.clientY - rect.top}px - (${rect.height}px - 100%) / 2 )`;
    target.style.backgroundPosition = `${posX} ${posY}`;
};

typeof document !== "undefined" && document.addEventListener("mousemove", globalButtonMouseMove, { passive: true });

import * as React from "react";
import "./DesktopDealerContact.scss";
import { PrimaryNavContext } from "../PrimaryNav";
import classNames from "classnames";
import { LinkButton } from "../../../buttons";
import { SVGDropPin } from "../../SVGIcons/icons/static/SVGDropPin";
import { lxColor } from "../../../colors/variables";
import { useGTM } from "../../GTM/useGTM";
import { GTMComponentContext } from "../../GTM/GTMComponentContext";
import { ButtonStyle, ButtonShade } from "../../../buttons/shared";
import { createBemFn } from "../../../utilities/bem";
//https://github.com/frenic/csstype/issues/63
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from "csstype";

declare module "csstype" {
    export interface Properties {
        "--lxs-primary-nav-dealer-contact__count"?: number;
    }
}

interface DesktopDealerContactProps {
    isOpen: boolean;
}

const COMPONENT_NAME = "GetInTouchMenu";
const bem = createBemFn("lxs-primary-nav-dealer-contact");

const ContactPhone: React.FC<{ header: string; phone: string }> = ({ header: text, phone }) => {
    const { gtmClick } = useGTM({ component: COMPONENT_NAME });

    return text && phone ? (
        <div className={bem("info")}>
            <span className={bem("text")}>{text}</span>
            <a
                className={bem("link")}
                href={`tel:${phone}`}
                onClick={gtmClick}
                data-gtm-target={text}
                data-gtm-action={phone}
            >
                {phone}
            </a>
        </div>
    ) : null;
};

const DesktopDealerContact: React.FC<DesktopDealerContactProps> = ({ isOpen }) => {
    const { dealerContact } = React.useContext(PrimaryNavContext);
    if (!dealerContact) {
        return null;
    }

    return (
        <GTMComponentContext.Provider value={{ component: COMPONENT_NAME }}>
            <div
                className={classNames(bem(), {
                    [bem(undefined, "active")]: isOpen,
                })}
                style={{ "--lxs-primary-nav-dealer-contact__count": dealerContact.locations.length }}
            >
                <div className={classNames("lxs-grid-row", bem("wrapper"))}>
                    <div
                        className={classNames(
                            bem("container"),
                            { [bem("container", "active")]: isOpen },
                            "lxs-grid lxs-grid--with-margin",
                        )}
                    >
                        {dealerContact.locations.map((location) => (
                            <React.Fragment key={location.name}>
                                {dealerContact.locations.length > 1 && (
                                    <div className={bem("info-header")}>{location.name}</div>
                                )}
                                <div className={bem("info-container")}>
                                    <ContactPhone header="SALES ENQUIRIES" phone={location.salesPhone} />
                                    <ContactPhone header="SERVICE CENTRE" phone={location.servicePhone} />
                                </div>
                            </React.Fragment>
                        ))}
                        {dealerContact.findUsText && dealerContact.findUsPage && (
                            <div className={bem("cta")}>
                                <LinkButton
                                    buttonStyle={ButtonStyle.Primary}
                                    shade={ButtonShade.Dark}
                                    href={dealerContact.findUsPage}
                                    data-gtm-target={dealerContact.findUsText}
                                    data-gtm-action={dealerContact.findUsPage}
                                >
                                    <SVGDropPin width={16} height={16} color={lxColor("deep-blue")} />
                                    {dealerContact.findUsText}
                                </LinkButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </GTMComponentContext.Provider>
    );
};

export { DesktopDealerContact };

import { Typography } from "../Typography/Typography";

type Variant = "h2" | "h5" | "s1" | "b1" | "b2" | "l1";

export type FormTypographyProps = {
  type: "title" | "subtitle" | "header" | "label" | "sub" | "subLabel";
  text: string;
};

const variantMap: Record<FormTypographyProps["type"], Variant> = {
  title: "h2",
  subtitle: "h5",
  header: "s1",
  sub: "b2",
  label: "b1",
  subLabel: "l1",
};

/**
 * FormTypography component allows you to put a content copy in form with predefined styles
 * only (@see {@link FormTextInfoProps.type}). You should avoid using Typography component directly in forms
 * as it may add inconsistent use cases to forms.
 * This component should only be used for `form elements` and should be used in conjunction with the `FormContainer`
 * component.
 */
export const FormTypography: React.FC<FormTypographyProps> = ({ type, text }) => {
  const variant = variantMap[type];

  return (
    <Typography component="p" variant={variant}>
      {text}
    </Typography>
  );
};

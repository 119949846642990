import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGAdd: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 18V32H18V18H32V14H18V0H14V14H0V18H14Z" fill={color} />
    </svg>
);
SVGAdd = React.memo(SVGAdd);

export { SVGAdd };

import { createContext } from "react";

/**
 * Props of DataAnalytics Context
 */
interface DataAnalyticsContextProps {
  /**
   * Component Name
   */
  component?: string;
  /**
   * Custom properties to be sent to DataAnalytics.
   */
  customProps?: { [key: string]: Record<string, unknown> | number | string };
}

/**
 * DataAnalytics Context to use inside components
 * @type {React.Context<DataAnalyticsContextProps>}
 * */
const DataAnalyticsComponentContext = createContext<DataAnalyticsContextProps>({});

export { DataAnalyticsComponentContext };

import { LexusMapProps } from "./LexusMap";

export const LexusMapChromaticMock: React.FC<LexusMapProps> = ({ className }) => (
  <div className={className} data-testid="lk-map">
    <div
      style={{
        backgroundColor: "silver",
        display: "grid",
        inset: 0,
        placeItems: "center",
        position: "absolute",
      }}
    >
      LexusMap is not rendered on Chromatic due to its randomized appearance on each rendering
    </div>
  </div>
);

import * as React from "react";
import { GTMComponentContext } from "./GTMComponentContext";
import { GTMContext, DataLayer } from "./GTM";

const gtmDataAttributesPrefix = "data-gtm-";

interface GTMmethods {
    gtmInteraction: (target: string, action: string, customProps?: CustomProps) => void;
    gtmClick: <T extends Element>(event: React.MouseEvent<T, MouseEvent>) => void;
    gtmClickProxy: <T extends Element>(handler?: React.MouseEventHandler<T>) => (event: React.MouseEvent<T>) => void;
    gtmEvent: (type: string, customProps: CustomProps) => void;
}

interface CustomProps {
    [key: string]: object | number | string;
}

interface AttributesObject {
    [key: string]: string;
}

const extractDataGtmAttributes = (element: Element) =>
    Array.from(element.attributes)
        .filter(
            (attr) =>
                attr &&
                attr.name &&
                attr.value &&
                attr.name.substr(0, gtmDataAttributesPrefix.length).toLowerCase() === gtmDataAttributesPrefix,
        )
        .reduce<AttributesObject>((result, attr) => {
            attr.value && (result[attr.name.substr(gtmDataAttributesPrefix.length)] = attr.value);
            return result;
        }, {});

const useGTM: (hookCustomProps?: CustomProps) => GTMmethods = (hookCustomProps) => {
    let { dataLayer } = React.useContext(GTMContext);
    const { component, customProps: componentCustomProps } = React.useContext(GTMComponentContext);

    if (!dataLayer) {
        const globalDataLayer: DataLayer = (global as any).dataLayer;
        if (globalDataLayer && typeof globalDataLayer.push === "function") {
            dataLayer = globalDataLayer;
        }
    }

    return React.useMemo(() => {
        const gtmInteraction = (target: string, action: string, customProps?: CustomProps) => {
            dataLayer &&
                dataLayer.push({
                    event: "Interaction",
                    target,
                    action,
                    component,
                    ...componentCustomProps,
                    ...hookCustomProps,
                    ...customProps,
                });
        };

        const gtmClick = <T extends Element>(event: React.MouseEvent<T, MouseEvent>) => {
            const gtmAttributes = event && event.currentTarget && extractDataGtmAttributes(event.currentTarget);
            dataLayer &&
                dataLayer.push({
                    event: "Click",
                    component,
                    ...componentCustomProps,
                    ...hookCustomProps,
                    ...gtmAttributes,
                });
        };
        const gtmClickProxy =
            <T extends Element>(handler?: React.MouseEventHandler<T>) =>
            (event: React.MouseEvent<T>) => {
                gtmClick(event);
                handler && handler(event);
            };
        const gtmEvent = (type: string, customProps: CustomProps) => {
            dataLayer &&
                dataLayer.push({
                    event: type,
                    component,
                    ...componentCustomProps,
                    ...hookCustomProps,
                    ...customProps,
                });
        };

        return { gtmInteraction, gtmClick, gtmClickProxy, gtmEvent };
    }, [dataLayer, component, componentCustomProps, hookCustomProps]);
};

export { useGTM };

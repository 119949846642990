import cn from "classnames";
import { useEffect, useRef, useState } from "react";

import { SVGChevronDown } from "@/components/SVGIcon/static/SVGChevronDown";

import styles from "./StickyNavBar.module.scss";

interface NavItems {
  anchorId: string;
  title: string;
}

interface StickyNavBarProps {
  navItems: NavItems[];
  intersectId?: string;
}

const STICKY_NAV_HEIGHT = 58;
const STICKY_NAV_BOTTOM_INITIAL = 48;
const STICKY_NAV_TOP = 40;
const STICKY_NAV_OFFSET = 40;

const scrollToTarget = (targetId: string, duration: number, offset: string) => {
  const target = document.getElementById(targetId);

  if (target) {
    const startY = window.scrollY;
    const distance = target.offsetTop - parseInt(offset) - startY;
    const startTime = new Date().getTime();

    const animateScroll = () => {
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - startTime;
      const progress = timeElapsed / (duration * 1000);

      if (progress < 1) {
        const easedProgress = 0.5 - Math.cos(progress * Math.PI) / 2;
        window.scrollTo(0, startY + distance * easedProgress);
        requestAnimationFrame(animateScroll);
      } else {
        window.scrollTo(0, startY + distance);
      }
    };
    requestAnimationFrame(animateScroll);
  }
};

const StickyNavBar: React.FC<StickyNavBarProps> = ({ navItems, intersectId }) => {
  const [activeAnchor, setActiveAnchor] = useState<string>("");
  const [intersecting, setIntersecting] = useState(false);
  const stickyNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialBottom = stickyNavRef.current
      ? parseInt(getComputedStyle(stickyNavRef.current).bottom, 10)
      : STICKY_NAV_BOTTOM_INITIAL;
    const maxUpwardMovement = STICKY_NAV_HEIGHT + STICKY_NAV_TOP;

    const handleScroll = () => {
      if (stickyNavRef.current) {
        const windowHeight = window.innerHeight;
        const scrollPosition = window.pageYOffset;
        let newBottom = initialBottom + scrollPosition;

        if (newBottom > windowHeight - maxUpwardMovement) {
          newBottom = windowHeight - maxUpwardMovement;
        }

        stickyNavRef.current.style.bottom = `${newBottom}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (intersectId && document.querySelector(`.${intersectId}`)) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIntersecting(true);
          } else {
            setIntersecting(false);
          }
        },
        { threshold: 0 }
      );
      observer.observe(document.querySelector(`.${intersectId}`) as Element);

      return () => {
        observer.unobserve(document.querySelector(`.${intersectId}`) as Element);
      };
    }
  }, [intersectId]);

  const handleAnchorClick = (anchorId: string) => {
    scrollToTarget(anchorId, 1, String(STICKY_NAV_OFFSET + STICKY_NAV_HEIGHT + STICKY_NAV_TOP));
    setActiveAnchor(anchorId);
    setShowPageContent(false);
    history.pushState(null, "", window.location.pathname + window.location.search + `#${anchorId}`);
  };

  const [showPageContent, setShowPageContent] = useState(false);

  const handlePageContentClick = () => {
    setShowPageContent(!showPageContent);
  };

  return (
    <section className={cn(styles.stickyNavWrapper, { [styles.stickyNavInvisible]: intersecting })}>
      <nav className={styles.stickyNav} ref={stickyNavRef} data-expanded={showPageContent}>
        <div className={styles.stickyNavItems}>
          <div className={cn(styles.stickyNavItem, styles.stickyNavMobileLeft)}>
            {navItems.map((item) => (
              <button
                key={item.anchorId}
                className={cn(styles.stickyNavAnchor, {
                  [styles.stickyNavAnchorActive]: activeAnchor === item.anchorId,
                  [styles.stickyNavAnchorMobileActive]: activeAnchor
                    ? activeAnchor === item.anchorId
                    : navItems[0].anchorId === item.anchorId,
                })}
                onClick={() => {
                  handleAnchorClick(item.anchorId);
                }}
              >
                {item.title}
              </button>
            ))}
          </div>
          <button className={styles.stickyNavMobileRight} onClick={handlePageContentClick}>
            PAGE CONTENTS
            <SVGChevronDown
              width={12}
              height={12}
              aria-expanded={showPageContent}
              className={styles.stickyNavSVG}
            />
          </button>
        </div>
        <div className={styles.stickyNavMobileAccordion} aria-expanded={showPageContent}>
          {navItems.map((item) => (
            <button
              key={item.anchorId}
              className={cn(styles.stickyNavAnchor, styles.stickyNavAnchorMobile, {
                [styles.stickyNavAnchorActive]: activeAnchor === item.anchorId,
              })}
              onClick={() => {
                handleAnchorClick(item.anchorId);
              }}
            >
              {item.title}
            </button>
          ))}
        </div>
      </nav>
    </section>
  );
};

export { StickyNavBar };

import * as React from "react";
import { ButtonStyle, buttonClassName, ButtonShade } from "./shared";
import "./global.ts";

const Button: React.FC<LXS.Button.ButtonProps> = ({
    children,
    buttonStyle = ButtonStyle.Primary,
    shade = ButtonShade.Dynamic,
    ...rest
}) => {
    if (!children) {
        return null;
    }

    const passOnProps = {
        ...rest,
        className: buttonClassName({ ...rest, buttonStyle, shade }),
    };

    return (
        <button {...passOnProps} onMouseDown={(e) => e.preventDefault()}>
            {children}
        </button>
    );
};

export { Button };

import { Label as LabelPrimitive, LabelProps as LabelPrimitiveProps } from "react-aria-components";

import { Typography } from "@/components/Typography/Typography";

import styles from "./InputLabel.module.scss";

export type InputLabelProps = LabelPrimitiveProps;

export const InputLabel: React.FC<InputLabelProps> = ({ children, ...rest }) => {
  return (
    <LabelPrimitive {...rest} className={styles.inputLabel}>
      <Typography variant="l1">{children}</Typography>
    </LabelPrimitive>
  );
};

InputLabel.displayName = "InputLabel";

import * as React from "react";
import { SVGSearch } from "../../SVGIcons/icons/static/SVGSearch";
import { lxColor } from "../../../colors/variables";

const doSearch = (
    e: React.FormEvent,
    searchInput: React.RefObject<HTMLInputElement>,
    searchConfig: LXS.SearchConfig | undefined,
) => {
    e.preventDefault();
    if (typeof document === "undefined" || !searchConfig || (searchInput.current && !searchInput.current.value)) {
        return;
    }
    document.location.href = `${searchConfig.searchResultPage}?q=${
        (searchInput.current && encodeURIComponent(searchInput.current.value)) || ""
    }`;
};

const searchIcon = React.createElement(SVGSearch, { height: 18, width: 18, color: lxColor("smoke") });

export { doSearch, searchIcon };

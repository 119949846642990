import * as React from "react";
import "./MainMenuMobile.scss";
import { MainMenuMobileHeader } from "./MainMenuMobileHeader";
import { MainMenuMobileNav } from "./MainMenuMobileNav";
import { MainMenuMobileSearch } from "./MainMenuMobileSearch";
import { MainMenuFooter } from "../MainMenuFooter";
import { GTMComponentContext } from "../../../GTM/GTMComponentContext";

const MainMenuMobile: React.FC = () => (
    <GTMComponentContext.Provider value={{ component: "MainMenuMobile" }}>
        <div className="lxs-main-menu-mobile">
            <MainMenuMobileHeader />
            <MainMenuMobileSearch />
            <MainMenuMobileNav />
            <div className="lxs-main-menu-mobile__footer">
                <MainMenuFooter />
            </div>
        </div>
    </GTMComponentContext.Provider>
);

export { MainMenuMobile };

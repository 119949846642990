import { createContext } from "react";

/**
 * DataAnalytics type which must be used by all implementations.
 * e.g Google Tag Manager, Salesforce, etc.
 */
interface DataAnalyticsPlugin {
  push: (data: Record<string, unknown>) => void;
}

/**
 * DataAnalytics Context for useDataAnalytics Props
 */
interface DataAnalyticsContextProps {
  plugins?: Array<DataAnalyticsPlugin>;
}

/**
 * DataAnalytics Context for useDataAnalytics
 */
const DataAnalyticsContext = createContext<DataAnalyticsContextProps>({});

export { DataAnalyticsContext };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGWarning: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15.9998C1 11.9938 2.561 8.22684 5.394 5.39384C5.784 5.00284 6.417 5.00284 6.808 5.39384C7.199 5.78484 7.199 6.41684 6.808 6.80784C4.353 9.26284 3 12.5278 3 15.9998C3 19.4718 4.353 22.7368 6.808 25.1918C7.199 25.5828 7.199 26.2148 6.808 26.6058C6.613 26.8008 6.357 26.8988 6.101 26.8988C5.845 26.8988 5.589 26.8008 5.394 26.6058C2.561 23.7728 1 20.0058 1 15.9998ZM25.192 5.39384C25.583 5.00284 26.215 5.00284 26.606 5.39384C29.439 8.22684 31 11.9938 31 15.9998C31 20.0058 29.439 23.7728 26.606 26.6058C26.411 26.8008 26.155 26.8988 25.899 26.8988C25.643 26.8988 25.387 26.8008 25.192 26.6058C24.801 26.2148 24.801 25.5828 25.192 25.1918C27.647 22.7368 29 19.4718 29 15.9998C29 12.5278 27.647 9.26284 25.192 6.80784C24.801 6.41684 24.801 5.78484 25.192 5.39384ZM16 8.99984C15.447 8.99984 15 9.44784 15 9.99984V17.9998C15 18.5518 15.447 18.9998 16 18.9998C16.553 18.9998 17 18.5518 17 17.9998V9.99984C17 9.44784 16.553 8.99984 16 8.99984ZM15 20.9998C15 20.4478 15.447 19.9998 16 19.9998C16.553 19.9998 17 20.4478 17 20.9998V21.9998C17 22.5518 16.553 22.9998 16 22.9998C15.447 22.9998 15 22.5518 15 21.9998V20.9998Z"
        />
    </svg>
    /* eslint-enable max-len */
);
SVGWarning = React.memo(SVGWarning);

export { SVGWarning };

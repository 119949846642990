import cn from "classnames";

import styles from "./Divider.module.scss";

export const DIVIDER_VARIANTS = ["default", "invisible"] as const;

export type DividerVariant = (typeof DIVIDER_VARIANTS)[number];
export type DividerProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Default is showing a line, invisible sets the opacity to 0%.
   */
  variant?: DividerVariant;
};

const Divider: React.FC<DividerProps> = ({ className, variant, ...rest }) => {
  return (
    <div
      className={cn(styles.divider, className, {
        [styles.invisible]: variant === "invisible",
      })}
      role="separator"
      {...rest}
    />
  );
};

export { Divider };

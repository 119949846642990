import * as React from "react";
import { loadDynamicScript } from "../script-loader";

export enum Status {
    LOADING = "loading",
    LOADED = "loaded",
    ERROR = "error",
}

/**
 * 'useScript' hook can be used to load any dynamic script.
 * It will trigger the render cycle of a react component on script load or error.
 */
export const useScript = (src: string, uniqueId: string) => {
    const [status, setStatus] = React.useState(Status.LOADING);

    React.useEffect(
        () =>
            loadDynamicScript({
                url: src,
                uniqueId: uniqueId,
                onLoad: () => setStatus(Status.LOADED),
                onError: () => setStatus(Status.ERROR),
            }),
        [src, uniqueId],
    );
    return status;
};

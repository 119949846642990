import * as React from "react";
import { SalesforceError } from "../Errors/SalesforceError";
import { SessionTimeoutError } from "../Errors/SessionTimeoutError";
import { AccountToast } from "./AccountToast";

interface GenericErrorProps {
    error: Error;
    isOpen: boolean;
    onClose?: () => void;
}

const strings = {
    genericErrorTitle: "Something went wrong",
    genericErrorMessage: "We can't contact our servers right now. Please try signing in later.",
    buttonText: "Okay",
    lcacErrorInstructions: "If this error continues please call the Lexus Customer Assistance Centre.",
    lcacErrorPhone: "1800 023 009",
    timeout: {
        title: "SESSION EXPIRED",
        message: "our session has timed out. For your security, press okay to continue back to the first step.",
    },
} as const;

const GenericError: React.FC<GenericErrorProps> = ({ error, isOpen, onClose }) => {
    const salesforceError = error instanceof SalesforceError ? error : undefined;
    const sessionTimeoutError = error instanceof SessionTimeoutError ? error : undefined;
    return sessionTimeoutError ? (
        <AccountToast
            title={sessionTimeoutError.title ?? strings.timeout.title}
            message={sessionTimeoutError.messageDisplayed ?? strings.timeout.message}
            isOpen={isOpen}
            onClose={sessionTimeoutError.closeHandler ?? onClose}
            buttonText={strings.buttonText}
        />
    ) : (
        <AccountToast
            title={strings.genericErrorTitle}
            message={strings.genericErrorMessage}
            isOpen={isOpen}
            onClose={onClose}
            buttonText={strings.buttonText}
            secondMessage={salesforceError && strings.lcacErrorInstructions}
            phoneNumber={salesforceError && strings.lcacErrorPhone}
            caseNumber={salesforceError && salesforceError.caseNumber}
        />
    );
};

const GenericErrorMemoized = React.memo(GenericError);

export { GenericErrorMemoized as GenericError };

import * as React from "react";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import "./MainMenuFooter.scss";
import classnames from "classnames";
import { PrimaryNavContext } from "../PrimaryNav";
import { useGTM } from "../../GTM/useGTM";
import { MainMenuDesktopContext } from "./desktop/MainMenuDesktop";

const MainMenuFooterInternal: React.FC<{ desktop?: boolean }> = ({ desktop }) => {
    const { menuData } = React.useContext(PrimaryNavContext);
    const { gtmClick } = useGTM();
    const footers = menuData && menuData.menuFooterItems;
    const { setMainMenuNavListItemActive } = React.useContext(MainMenuDesktopContext);
    const [active, setActive] = React.useState(false);

    const activate = () => {
        setActive(true);
        setMainMenuNavListItemActive && setMainMenuNavListItemActive(true);
    };

    const deactivate = () => {
        setActive(false);
        setMainMenuNavListItemActive && setMainMenuNavListItemActive(false);
    };

    return (
        <>
            {footers &&
                footers.map((footer, key) => {
                    const text = desktop ? footer.dealerLinkDesktopText : footer.dealerLinkMobileText;
                    return (
                        <div
                            key={`${key}-menu-footer`}
                            className={classnames("lxs-main-menu-footer", { "lxs-main-menu-footer--active": active })}
                        >
                            <span
                                className={classnames(
                                    "lxs-main-menu-footer__icon",
                                    footer.dealerLinkIconClass ? footer.dealerLinkIconClass : "icon-drop-pin",
                                )}
                            />
                            <Link
                                field={{
                                    href: footer.dealerLink,
                                    text: text,
                                }}
                                onClick={gtmClick}
                                data-gtm-action={footer.dealerLink}
                                data-gtm-target={text}
                                className={classnames("lxs-main-menu-footer__link", {
                                    "lxs-main-menu-footer__link--active": active,
                                })}
                                onMouseEnter={activate}
                                onMouseLeave={deactivate}
                                data-gtm-linktype="FooterLink"
                            />
                        </div>
                    );
                })}
        </>
    );
};
const MainMenuFooter = React.memo(MainMenuFooterInternal);

export { MainMenuFooter };

import { SitecoreServer } from "./SitecoreServer";
import * as jssReact from "@sitecore-jss/sitecore-jss-react";

export const isSitecoreEditable = (props: SCJSS.TextProps | SCJSS.LinkProps | LXS.FocalpointImageProps): boolean =>
    Boolean(props && props.field && ("editable" in props.field || "editableFirstPart" in props.field));

export const isExperienceEditorActive = () =>
    jssReact.isExperienceEditorActive() ||
    SitecoreServer.isExperienceEditorEditing ||
    isExperienceEditorActiveOnParent();

const isExperienceEditorActiveOnParent = () => {
    try {
        if (!(typeof window !== "undefined" && window.top)) {
            return false;
        }

        const sc = (window.top as any).Sitecore;
        return Boolean(sc && sc.PageModes && sc.PageModes.ChromeManager);
    } catch {
        return false;
    }
};

export const getSitecoreFieldValue = <TIn extends { field?: { value?: TOut } | TOut }, TOut>(
    props: TIn,
): TOut | undefined =>
    !props || !props.field
        ? undefined
        : typeof props.field === "object" && "value" in props.field
        ? props.field.value
        : (props.field as TOut);

export const getImageSource = (image: SCJSS.ImageField | SCJSS.ImageFieldValue) =>
    (image && image.value && image.value.src) ||
    (image && "src" in image && (image as SCJSS.ImageFieldValue).src) ||
    "";

import "./SecondaryNavigationLink.scss";

import * as React from "react";

import { NavLink, NavLinkProps } from "react-router-dom";

import classnames from "classnames";
import { createBemFn } from "lexus-style-guide/utilities/bem";

const bem = createBemFn("secondary-navigation-link");
interface SecondaryNavigationLinkProps extends NavLinkProps {
    disabled?: boolean;
}

const SecondaryNavigationLink: React.FunctionComponent<SecondaryNavigationLinkProps> = ({
    children,
    className,
    activeClassName,
    ...rest
}) => (
    <NavLink
        className={classnames(bem(), "link-uppercase", className)}
        activeClassName={classnames(bem(undefined, rest.disabled ? "disabled" : "active"))}
        {...rest}
    >
        {children}
    </NavLink>
);
export default SecondaryNavigationLink;

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGChevronUp: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M25 23.3286L27.8284 20.5002L16 8.67176L4.17157 20.5002L7 23.3286L16 14.3286L25 23.3286Z" />
  </svg>
  /* eslint-enable max-len */
);
SVGChevronUp = memo(SVGChevronUp);

export { SVGChevronUp };

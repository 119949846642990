export enum ToolTipLocation {
  TOP_RIGHT = "topRight",
  BOTTOM_RIGHT = "bottomRight",
}

export interface VideoSource {
  src: string;
  type?: string;
  width?: number;
  height?: number;
}

export interface ContainerCustomProps {
  /**
   * Use @param minHeightDesktop when parent container doesn't define height
   */
  minHeightDesktop?: number;

  /**
   * Use @param minHeightMobile when parent container doesn't define height
   */
  minHeightMobile?: number;

  /**
   * Aspect ration is mandatory, use 0 to cover all available space,
   * this require parent to define both width and height dimensions.
   */
  aspectRatio: number;
}

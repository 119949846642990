import {
    FetchAction,
    PromiseThunk,
    authorizedFetch,
    createFetchThunk,
    fetchActionPayloadToContainer,
    getFetchContainerError,
    getFetchContainerValue,
    initialFetchContainer,
    json,
    status,
} from "Helpers/fetch";
import {
    FetchGuestInfoFetchActionPayload,
    FetchMagicLinkBasicUserInfoFetchActionPayload,
    GuestInfoFetchContainer,
    MagicLinkBasicUserInfoFetchContainer,
    DashboardBannerState,
    PartialCommPrefFetchActionPayload,
    PartialDashboardBannerActionPayload,
    SetAuthSectionPayload,
    SetMagicLinkSalesforceParametersPayload,
    SetStripeCustomerDetailsPayload,
    User,
    VerifyVehicleFetchActionPayload,
    VerifyVehicleFetchContainer,
    VerifyVehiclePayload,
} from "./userInterfaces";
import { ReducerMapValue, createAction, handleActions } from "redux-actions";
import { RootLevelAction, reset } from "./rootLevelAction";
import { commPrefUrl, getGuestDetailsUrl, updateGuestDetailsUrl, verifyVehicleUrl } from "../apiHref";
import { postLogoutFailureMessage, postLogoutSuccessMessage } from "Helpers/routes";
import { pushToGTMAccountCreation, pushToGTMFormSignInSuccess, pushToGTMVehicleAssociation } from "Helpers/gtm";

import { AccountManager } from "lexus-style-guide/Components/Account/Providers/AccountManager";
import { ErrorConstants } from "Helpers/ErrorConstants";
import { GuestAccountType } from "lexus-style-guide/Components/Account/enums";
import { LoadStatus } from "Types/loadStatus";
import { SalesforceError } from "lexus-style-guide/Components/Account/Errors/SalesforceError";
import { StateSelector } from "Types/general";
import { UserEncoreStatus, UserEncoreTiers } from "Helpers/users";
import { createSelector } from "reselect";
import { settingsPromise } from "../settings";
import { IS_SHOWN_BANNER, IS_SHOWN_EXTRA_BANNER } from "../constants";

const termsAndPrivacyVersions = async () => ({ ...(await settingsPromise()).general });
export enum AuthSection {
    Verifying = "Verifying",
    Account = "Account",
    App = "App",
    SalesforceError = "SalesforceError",
    BFFError = "BFFError",
    Error = "Error",
}

//#region actions
export enum UserAction {
    GuestDetails = "GuestDetails",
    GuestError = "GuestError",
    VerifyVehicle = "VerifyVehicle",
    FetchMagicLinkBasicUserInfo = "FetchMagicLinkBasicUserInfo",
    SetMagicLinkSalesforceParameters = "SetMagicLinkSalesforceParameters",
    ResetMagicLinkDetails = "ResetMagicLinkDetails",
    SaveCommPref = "SaveCommPref",
    SetAuthSection = "SetAuthSection",
    SetStripeCustomerDetails = "SetStripeCustomerDetails",
    UpdateGuestDetails = "UpdateGuestDetails",
    DashboardBannerStates = "DashboardBannerStates",
    IsMembershipEnabled = "IsMembershipEnabled",
    ToggleDashboardBanner = "ToggleDashboardBanner",
    ToggleExtraDashboardBanner = "ToggleExtraDashboardBanner",
}

const guestDetailsAction = createAction<LXS.GuestDetails>(UserAction.GuestDetails);

const registerGuestErrorAction = createAction<Error>(UserAction.GuestError);

const verifyVehicleAction = createAction<VerifyVehicleFetchActionPayload>(UserAction.VerifyVehicle);

const fetchMagicLinkBasicUserInfoAction = createAction<FetchMagicLinkBasicUserInfoFetchActionPayload>(
    UserAction.FetchMagicLinkBasicUserInfo
);

export const setMagicLinkSalesforceParametersAction = createAction<SetMagicLinkSalesforceParametersPayload>(
    UserAction.SetMagicLinkSalesforceParameters
);

export const resetMagicLinkDetailsAction = createAction(UserAction.ResetMagicLinkDetails);

const updateGuestDetails = createAction<Partial<LXS.GuestDetails>>(UserAction.UpdateGuestDetails);

export const saveCommPrefAction = createAction<PartialCommPrefFetchActionPayload>(UserAction.SaveCommPref);

export const setAuthSectionAction = createAction<SetAuthSectionPayload>(UserAction.SetAuthSection);

export const setStripCustomerDetailsAction = createAction<SetStripeCustomerDetailsPayload>(
    UserAction.SetStripeCustomerDetails
);

export const setDashboardBannerStatesAction = createAction<PartialDashboardBannerActionPayload>(
    UserAction.DashboardBannerStates
);
export const setIsMembershipEnabledAction = createAction<boolean>(UserAction.IsMembershipEnabled);

export const setToggleDashboardBannerAction = createAction<boolean>(UserAction.ToggleDashboardBanner);

export const setToggleExtraDashboardBannerAction = createAction<boolean>(UserAction.ToggleExtraDashboardBanner);

//#endregion

//#region state
export const initialUserState: User = {
    guestDetails: undefined,
    verifyVehicle: initialFetchContainer,
    magicLinkBasicUserInfo: initialFetchContainer,
    guestInfo: initialFetchContainer,
    authSection: AuthSection.Verifying,
    isMembershipEnabled: false,
    dashboardBannerStates: {
        showBanner: false,
        extraBannerContent: {
            showExtraBanner: false,
            membershipStatus: UserEncoreStatus.ACTIVE,
        },
        membershipStatus: UserEncoreStatus.ACTIVE,
        expiryDate: undefined,
        ctaDisabled: false,
    },
};
//#endregion

//#region thunks
const doLoginThunk =
    (isAutoLogin: boolean): PromiseThunk<void> =>
    async dispatch => {
        const guest = await AccountManager.current.getGuestAsync(false);

        if (!guest) {
            if (isAutoLogin) {
                dispatch(setAuthSectionAction(AuthSection.Account));
            }
            return;
        }
        try {
            try {
                const guestDetails = await guest.getOrUpdateDetailsAsync();
                dispatch(guestDetailsAction(guestDetails));
            } catch (e) {
                dispatch(registerGuestErrorAction(e instanceof Error ? e : new Error(String(e))));
                throw e;
            }

            // manual login will do transition before being in AuthSection.App
            if (isAutoLogin) {
                dispatch(setAuthSectionAction(AuthSection.App));
            }
        } catch (e) {
            if (e?.message?.includes(ErrorConstants.BFFDownError)) {
                dispatch(setAuthSectionAction(AuthSection.BFFError));
            } else {
                dispatch(setAuthSectionAction(AuthSection.Error));
            }
        }
    };

export const attemptAutoLoginThunk: PromiseThunk<void> = async dispatch => dispatch(doLoginThunk(true));
export const attemptLoginThunk: PromiseThunk<void> = async (dispatch, getState) => {
    await dispatch(doLoginThunk(false));
    const state = getState();
    const userInfo = guestDetailsSelector(state);
    if (userInfo?.accountId) {
        pushToGTMFormSignInSuccess(userInfo.encoreTier || UserEncoreTiers.NONE, userInfo.accountId ?? "");
    }
    return;
};

export const termsAcceptedThunk: PromiseThunk<void> = async (dispatch, getState) => {
    try {
        const state = getState();
        const basicUser = magicLinkBasicUserInfoSelector(state);
        //check if user is registered via deferred link
        const source = basicUser?.accountId && basicUser?.vehicleOwnershipId ? "deferred-link" : "normal";
        const { termsAndConditionsVersion, privacyPolicyVersion } = await termsAndPrivacyVersions();

        const updateGuestDetails: Partial<LXS.GuestDetails> = {
            termsAndConditionsVersion,
            privacyPolicyVersion,
        };
        const guestDetails = await AccountManager.current.updateGuestDetailsAsync(updateGuestDetails);
        dispatch(guestDetailsAction(guestDetails));
        const accountTier = guestDetails.encoreTier || UserEncoreTiers.NONE;

        pushToGTMAccountCreation(source, accountTier, guestDetails.accountId);
        await dispatch(verifyVehicleThunk);
    } catch (e) {
        // TODO: fully logged in but register guest failed
    }
};

export const verifyVehicleThunk: PromiseThunk<VerifyVehiclePayload | undefined> = async (dispatch, getState) => {
    const state = getState();
    const basicUser = magicLinkBasicUserInfoSelector(state);

    const salesforceId = basicUser && basicUser.accountId ? basicUser.accountId : null;

    const vehicleOwnershipId = basicUser && basicUser.vehicleOwnershipId ? basicUser.vehicleOwnershipId : null;

    if (salesforceId === null || vehicleOwnershipId === null) {
        return;
    }

    const url = await verifyVehicleUrl();

    const payload = await dispatch(
        createFetchThunk(url, verifyVehicleAction, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({
                sfId: salesforceId,
                voId: vehicleOwnershipId,
            }),
        })
    );

    vehicleOwnershipId &&
        pushToGTMVehicleAssociation(vehicleOwnershipId, payload.isSuccess, UserEncoreTiers.NONE, salesforceId ?? "");
    if (AccountManager.current.guest) {
        const guestDetails = await AccountManager.current.guest?.getOrUpdateDetailsAsync({});
        dispatch(guestDetailsAction(guestDetails));
    }
    return payload;
};

export const fetchMagicLinkBasicUserInfoThunk =
    (token: string): PromiseThunk<LXS.BasicGuestDetails> =>
    async dispatch => {
        const url = new URL(await getGuestDetailsUrl(token));
        return dispatch(createFetchThunk(url.href, fetchMagicLinkBasicUserInfoAction, undefined, false));
    };

export const saveCommPrefThunk = (pref: Partial<LXS.GuestCommunicationPreferences>): PromiseThunk<void> => {
    return async dispatch => {
        dispatch(saveCommPrefAction({ action: FetchAction.Fetch }));

        // FZ: This REST api doesn't return a body when a POST is successful, so
        // have to use authorizedFetch to include the payload.
        return authorizedFetch(await commPrefUrl(), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(pref),
        })
            .then(status)
            .then(() => {
                dispatch(saveCommPrefAction({ action: FetchAction.Success, value: pref }));
            })
            .catch(error => {
                dispatch(saveCommPrefAction({ action: FetchAction.Failure, value: error }));
            });
    };
};

export const logoutThunk: PromiseThunk<boolean> = (dispatch, getState) =>
    new Promise(resolve => {
        try {
            const authSection = authSectionSelector(getState());
            const isLoggedInToAccount = authSection === AuthSection.App || authSection === AuthSection.SalesforceError;
            if (isLoggedInToAccount) {
                AccountManager.current.logOut();
                sessionStorage.removeItem(IS_SHOWN_BANNER);
                sessionStorage.removeItem(IS_SHOWN_EXTRA_BANNER);

                dispatch(reset());
                resolve(true);
            } else {
                resolve(false);
            }

            postLogoutSuccessMessage();
        } catch (err) {
            postLogoutFailureMessage();
            throw err;
        }
    });

export const updateGuestDetailsThunk =
    (userDetail: Partial<LXS.GuestDetails>): PromiseThunk<boolean> =>
    async (dispatch, getState) => {
        const url = await updateGuestDetailsUrl();
        const value = guestDetailsSelector(getState());
        const updatedGuestDetail = {
            ...value,
            ...userDetail,
            postalSameAsHome: false,
        };
        await authorizedFetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedGuestDetail),
        })
            .then(status)
            .then(json)
            .then(res => dispatch(updateGuestDetails(res)))
            .catch(() => Promise.reject(false));
        return Promise.resolve(true);
    };

//#endregion

//#region selectors
export const isMembershipEnabledSelector: StateSelector<boolean> = state => state.user.isMembershipEnabled;

export const guestDetailsSelector: StateSelector<LXS.GuestDetails | undefined> = state => state.user.guestDetails;

export const guestErrorSelector: StateSelector<Error | undefined> = state => state.user.guestError;

export const dashboardBannerStatesSelector: StateSelector<DashboardBannerState> = state =>
    state.user.dashboardBannerStates;

export const salesforceAccountIdSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.accountId || undefined;

export const firstNameSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.firstname || undefined;

export const lastNameSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.lastname || undefined;

export const encoreTierSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.encoreTier || undefined;

export const emailSelector: StateSelector<string | undefined> = state => guestDetailsSelector(state)?.email;

export const mobileNumberSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.mobileNumber || undefined;

export const addressSelector: StateSelector<LXS.Address | undefined> = state =>
    guestDetailsSelector(state)?.address || undefined;

export const postalAddressSelector: StateSelector<LXS.Address | undefined> = state =>
    guestDetailsSelector(state)?.postalAddress || undefined;

export const interestsSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.interests || undefined;

export const stripeCustomerIdSelector: StateSelector<string | undefined> = state =>
    guestDetailsSelector(state)?.stripeCustomerId || undefined;

export const basicUserInfoSelector: StateSelector<LXS.BasicGuestDetails | undefined> = state => {
    const value = guestDetailsSelector(state);
    return value !== undefined
        ? {
              email: value.email,
              firstname: value.firstname,
              lastname: value.lastname,
              mobileNumber: value.mobileNumber,
              accountId: value.accountId,
              vehicleOwnershipId: value.vehicleOwnershipId,
              encoreTier: value.encoreTier,
          }
        : undefined;
};

const guestInfoContainerSelector: StateSelector<GuestInfoFetchContainer> = state => state.user.guestInfo;

export const guestInfoValueSelector: StateSelector<LXS.Guest | undefined> = createSelector(
    guestInfoContainerSelector,
    getFetchContainerValue
);

export const salesforceParametersSelector: StateSelector<SetMagicLinkSalesforceParametersPayload | undefined> = state =>
    state.user.magicLinkSalesforceParameters;

export const isSocialAccountSelector: StateSelector<boolean> = state => {
    const guestInfo = guestInfoValueSelector(state);
    return guestInfo?.type === GuestAccountType.Social;
};

export const isRegisterGuestErrorSelector: StateSelector<boolean> = state => !!guestErrorSelector(state);

export const registerGuestErrorCodeSelector: StateSelector<number> = state => {
    const error = guestErrorSelector(state);
    const errorCode = Number(error instanceof SalesforceError ? error.errorCode : undefined);
    return !isNaN(errorCode) ? errorCode : 0;
};

export const registerGuestErrorCaseNumberSelector: StateSelector<string> = state => {
    const error = guestErrorSelector(state);
    return error instanceof SalesforceError ? error.caseNumber || "" : "";
};

export const verifyVehicleContainerSelector: StateSelector<VerifyVehicleFetchContainer> = state =>
    state.user.verifyVehicle;

export const verifyVehicleStatusSelector: StateSelector<LoadStatus> = state =>
    verifyVehicleContainerSelector(state).status;

export const magicLinkBasicUserInfoContainerSelector: StateSelector<MagicLinkBasicUserInfoFetchContainer> = state =>
    state.user.magicLinkBasicUserInfo;

export const magicLinkBasicUserInfoSelector: StateSelector<LXS.BasicGuestDetails | undefined> = createSelector(
    magicLinkBasicUserInfoContainerSelector,
    getFetchContainerValue
);

export const magicLinkBasicUserInfoErrorSelector: StateSelector<Error | undefined> = createSelector(
    magicLinkBasicUserInfoContainerSelector,
    getFetchContainerError
);

export const authSectionSelector: StateSelector<AuthSection> = state => state?.user?.authSection;

const defaultCommunicationPreferences = {
    doNotContact: false,
    doNotCall: false,
    productInformation: false,
    exclusiveEvents: false,
};
export const communicationPreferencesSelector: StateSelector<LXS.GuestCommunicationPreferences> = state =>
    state.user.guestDetails?.communicationPreferences || defaultCommunicationPreferences;

export const preferredDealerSelector: StateSelector<string> = state => state.user.guestDetails?.preferredDealer || "";
//#endregion

//#region reducers
type UserReducerPayload =
    | undefined
    | Error
    | VerifyVehicleFetchActionPayload
    | FetchMagicLinkBasicUserInfoFetchActionPayload
    | FetchGuestInfoFetchActionPayload
    | SetMagicLinkSalesforceParametersPayload
    | PartialCommPrefFetchActionPayload
    | SetAuthSectionPayload
    | SetStripeCustomerDetailsPayload
    | LXS.GuestDetails
    | PartialDashboardBannerActionPayload
    | boolean;

type UserReducer<T = undefined> = ReducerMapValue<User, T>;

const guestDetailsReducer: UserReducer<LXS.GuestDetails> = (state, action) => ({
    ...state,
    guestDetails: action.payload,
});

const guestErrorReducer: UserReducer<Error> = (state, action) => ({
    ...state,
    guestError: action.payload,
});

const verifyVehicleReducer: UserReducer<VerifyVehicleFetchActionPayload> = (state, action) => ({
    ...state,
    verifyVehicle: fetchActionPayloadToContainer(action.payload),
});

const fetchMagicLinkBasicUserInfoReducer: UserReducer<FetchMagicLinkBasicUserInfoFetchActionPayload> = (
    state,
    action
) => ({
    ...state,
    magicLinkBasicUserInfo: fetchActionPayloadToContainer(action.payload),
});

const setMagicLinkSalesforceParametersReducer: UserReducer<SetMagicLinkSalesforceParametersPayload> = (
    state,
    action
) => ({
    ...state,
    magicLinkSalesforceParameters: action.payload,
});

const resetMagicLinkDetailsReducer: UserReducer = state => ({
    ...state,
    magicLinkBasicUserInfo: initialFetchContainer,
    magicLinkSalesforceParameters: undefined,
});

const saveCommPrefReducer: UserReducer<PartialCommPrefFetchActionPayload> = (state, action) =>
    action.payload.action === FetchAction.Success && state.guestDetails?.communicationPreferences
        ? {
              ...state,
              guestDetails: {
                  ...state.guestDetails,
                  communicationPreferences: {
                      ...state.guestDetails.communicationPreferences,
                      ...action.payload.value,
                  },
              },
          }
        : state;

const setAuthSectionReducer: UserReducer<AuthSection> = (state, action) => ({
    ...state,
    authSection: action.payload,
});

const setStripCustomerDetailsReducer: UserReducer<SetStripeCustomerDetailsPayload> = (state, action) =>
    state.guestDetails
        ? {
              ...state,
              guestDetails: {
                  ...state.guestDetails,
                  stripeCustomerId: action.payload.stripeCustomerId,
              },
          }
        : state;

const setDashboardBannerStatesReducer: UserReducer<PartialDashboardBannerActionPayload> = (state, action) => ({
    ...state,
    dashboardBannerStates: {
        ...state.dashboardBannerStates,
        ...action.payload,
    },
});
const resetReducer: UserReducer<undefined> = () => ({
    ...initialUserState,
    authSection: AuthSection.Account,
});

const updateGuestDetailsReducer: UserReducer<LXS.GuestDetails> = (state, action) => ({
    ...state,
    guestDetails: { ...state.guestDetails, ...action.payload },
});

const setIsMembershipEnabledReducer: UserReducer<boolean> = (state, action) => ({
    ...state,
    isMembershipEnabled: action.payload,
});

const setToggleDashboardBannerReducer: UserReducer<boolean> = state => ({
    ...state,
    dashboardBannerStates: {
        ...state.dashboardBannerStates,
        showBanner: !state.dashboardBannerStates.showBanner,
    },
});

const setToggleExtraDashboardBannerReducer: UserReducer<boolean> = state => ({
    ...state,
    dashboardBannerStates: {
        ...state.dashboardBannerStates,
        extraBannerContent: {
            ...state.dashboardBannerStates.extraBannerContent,
            showExtraBanner: !state.dashboardBannerStates.extraBannerContent.showExtraBanner,
        },
    },
});
export const userReducer = handleActions<User, UserReducerPayload>(
    {
        [UserAction.GuestDetails]: guestDetailsReducer,
        [UserAction.GuestError]: guestErrorReducer,
        [UserAction.VerifyVehicle]: verifyVehicleReducer,
        [UserAction.FetchMagicLinkBasicUserInfo]: fetchMagicLinkBasicUserInfoReducer,
        [UserAction.SetMagicLinkSalesforceParameters]: setMagicLinkSalesforceParametersReducer,
        [UserAction.ResetMagicLinkDetails]: resetMagicLinkDetailsReducer,
        [UserAction.SaveCommPref]: saveCommPrefReducer,
        [UserAction.SetAuthSection]: setAuthSectionReducer,
        [UserAction.SetStripeCustomerDetails]: setStripCustomerDetailsReducer,
        [UserAction.UpdateGuestDetails]: updateGuestDetailsReducer,
        [UserAction.DashboardBannerStates]: setDashboardBannerStatesReducer,
        [UserAction.IsMembershipEnabled]: setIsMembershipEnabledReducer,
        [UserAction.ToggleDashboardBanner]: setToggleDashboardBannerReducer,
        [UserAction.ToggleExtraDashboardBanner]: setToggleExtraDashboardBannerReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialUserState
);
//#endregion

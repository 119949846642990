import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";
let SVGTelephone: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M14.982 7.498a.5.5 0 0 1-.45-.545c.152-1.607-.327-3.055-1.349-4.077-1.022-1.022-2.472-1.502-4.076-1.348a.5.5 0 1 1-.095-.996c1.91-.18 3.642.4 4.878 1.637 1.236 1.236 1.818 2.969 1.637 4.878a.5.5 0 0 1-.545.451Zm-1.718-.471a.5.5 0 0 1-.5-.492c-.03-1.757-1.482-3.21-3.24-3.24a.5.5 0 0 1 .009-1h.008c2.29.038 4.185 1.932 4.223 4.223a.5.5 0 0 1-.491.509h-.009Zm-2.013-.275a.5.5 0 0 1-.45-.545c.027-.29-.054-.545-.23-.72-.174-.174-.431-.255-.718-.228a.5.5 0 1 1-.094-.996c.595-.055 1.13.13 1.52.518.389.39.572.929.517 1.52a.5.5 0 0 1-.545.45Zm.067 8.503C6.34 13.125 2.818 9.606.844 4.795.477 3.897.464 3.03.805 2.284 1.16 1.51 1.864.93 2.841.602a1.803 1.803 0 0 1 1.215.005c.888.325 2.823 2.946 2.875 3.896.05.892-.63 1.35-.957 1.57-.374.252-.533.526-.241 1.178.62 1.384 1.661 2.39 3.185 3.077.16.072.302.109.42.109s.274-.032.437-.278c.776-1.166 1.87-1.354 3.134-.472l.235.163c.382.264.776.538 1.15.837.069.055.14.108.21.162.545.414 1.369 1.04.935 2.257-.403 1.132-1.81 2.393-3.18 2.393l-.148-.017c-.036-.01-.08-.02-.13-.03-.178-.04-.422-.094-.663-.198ZM1.715 2.701c-.224.487-.205 1.08.055 1.714 1.87 4.56 5.214 7.898 9.94 9.921.156.067.34.108.486.14l.104.024c.814-.029 1.89-.87 2.196-1.729.174-.487-.003-.674-.597-1.125-.077-.058-.154-.117-.23-.177a19.911 19.911 0 0 0-1.094-.795l-.238-.165c-.35-.245-.656-.37-.907-.37-.109 0-.44 0-.821.575-.398.599-.91.724-1.27.724-.261 0-.541-.066-.831-.197-1.733-.78-2.973-1.985-3.688-3.58-.572-1.279.008-2.02.595-2.416.41-.277.532-.437.519-.686-.033-.546-1.713-2.826-2.22-3.01a.742.742 0 0 0-.262-.049.914.914 0 0 0-.292.052c-.71.237-1.21.634-1.445 1.149Z"
      fill="currentColor"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGTelephone = memo(SVGTelephone);
export { SVGTelephone };

/* eslint-disable @typescript-eslint/no-floating-promises */
import polyline from "google-polyline";

import { isDefinedObject } from "@/utils/typeGuards";

import GoogleWrappers from "./GoogleWrappers";
import { Directions, DirectionsRequest, LexusMapCoordinates, Marker, MarkerIcon } from "./types";

export const googleToLexusCoordinates = (coordinates: google.maps.LatLng): LexusMapCoordinates => ({
  lat: coordinates.lat(),
  lng: coordinates.lng(),
});

export const lexusToGoogleCoordinates = (
  ...coordinates: [LexusMapCoordinates] | [number, number]
) =>
  typeof coordinates[0] === "object"
    ? new GoogleWrappers.MapLatLng(coordinates[0])
    : typeof coordinates[0] === "number" && typeof coordinates[1] === "number"
    ? new GoogleWrappers.MapLatLng(coordinates[0], coordinates[1])
    : new GoogleWrappers.MapLatLng(0, 0); // Should not be possible, just fallback for TS

export const decodeWaypoints = (polylineString: string): google.maps.LatLng[] =>
  polyline.decode(polylineString).map(([lat, lng]) => new GoogleWrappers.MapLatLng(lat, lng));

export const isPOIAroundCoords = (
  pointsCoords: google.maps.LatLng[],
  pointOfInterest: Marker,
  distanceLimit: number
): boolean => {
  if (!GoogleWrappers.areMapsLoaded) {
    throw new Error(GoogleWrappers.NO_GMAPS_ERROR_MESSAGE);
  }

  const pointOfInterestCoords = lexusToGoogleCoordinates(pointOfInterest);

  return pointsCoords.some((pointCoords) => {
    const distanceBetweenPoints = GoogleWrappers.ComputeDistanceBetween(
      pointOfInterestCoords,
      pointCoords
    );

    return distanceBetweenPoints <= distanceLimit;
  });
};

export const geoCodeAddressAsync = (address: string): Promise<google.maps.LatLng> =>
  new Promise((resolve, reject) => {
    GoogleWrappers.geoCodeService.geocode({ address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results?.[0].geometry.location) {
        resolve(results?.[0].geometry.location);
      } else {
        reject(new Error(`Couldn't find the location ${address}`));
      }
    });
  });

export const getDirectionsAsync = (request: DirectionsRequest): Promise<Directions> =>
  new Promise((resolve, reject) => {
    GoogleWrappers.directionsService.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK && result) {
        resolve(result);
      } else {
        reject(new Error(`Couldn't calculate the journey ${status}`));
      }
    });
  });

export const getMarkersBounds = (positionMarkers: google.maps.Marker[]) => {
  const bounds = new GoogleWrappers.MapsLatLngBounds();

  positionMarkers
    .map((marker) => marker.getPosition())
    .filter(isDefinedObject)
    .forEach((pos) => bounds.extend(pos));

  return bounds;
};

export const markerIcon = (type: "default" | "clicked", icon?: MarkerIcon) =>
  !icon || !icon.default
    ? undefined
    : {
        url: icon[type] || icon.default || "",
        scaledSize:
          icon.iconWidth && icon.iconHeight
            ? new GoogleWrappers.MapsSize(icon.iconWidth, icon.iconHeight)
            : undefined,
        origin:
          typeof icon.iconOriginX === "number" && typeof icon.iconOriginY === "number"
            ? new GoogleWrappers.MapsPoint(icon.iconOriginX, icon.iconOriginY)
            : undefined,
        anchor:
          typeof icon.iconAnchorX === "number" && typeof icon.iconAnchorY === "number"
            ? new GoogleWrappers.MapsPoint(icon.iconAnchorX, icon.iconAnchorY)
            : undefined,
      };

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGCalendar: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M16 8C15.4 8 15 8.4 15 9V12H12C10.9 12 10 12.9 10 14V38C10 39.1 10.9 40 12 40H36C37.1 40 38 39.1 38 38V14C38 12.9 37.1 12 36 12H33V9C33 8.4 32.6 8 32 8C31.4 8 31 8.4 31 9V12H17V9C17 8.4 16.6 8 16 8ZM31 14V15C31 15.6 31.4 16 32 16C32.6 16 33 15.6 33 15V14H36V38H12V14H15V15C15 15.6 15.4 16 16 16C16.6 16 17 15.6 17 15V14H31Z"
            fill={color}
        />
        <path
            d="M15 19.5V22.5C15 22.8 15.2 23 15.5 23H18.5C18.8 23 19 22.8 19 22.5V19.5C19 19.2 18.8 19 18.5 19H15.5C15.2 19 15 19.2 15 19.5ZM16 20H18V22H16V20Z"
            fill={color}
        />
        <path
            d="M29.5 23H32.5C32.8 23 33 22.8 33 22.5V19.5C33 19.2 32.8 19 32.5 19H29.5C29.2 19 29 19.2 29 19.5V22.5C29 22.8 29.2 23 29.5 23ZM30 20H32V22H30V20Z"
            fill={color}
        />
        <path
            d="M22.5 23H25.5C25.8 23 26 22.8 26 22.5V19.5C26 19.2 25.8 19 25.5 19H22.5C22.2 19 22 19.2 22 19.5V22.5C22 22.8 22.2 23 22.5 23ZM23 20H25V22H23V20Z"
            fill={color}
        />
        <path
            d="M18.5 25H15.5C15.2 25 15 25.2 15 25.5V28.5C15 28.8 15.2 29 15.5 29H18.5C18.8 29 19 28.8 19 28.5V25.5C19 25.2 18.8 25 18.5 25ZM18 28H16V26H18V28Z"
            fill={color}
        />
        <path
            d="M29.5 29H32.5C32.8 29 33 28.8 33 28.5V25.5C33 25.2 32.8 25 32.5 25H29.5C29.2 25 29 25.2 29 25.5V28.5C29 28.8 29.2 29 29.5 29ZM30 26H32V28H30V26Z"
            fill={color}
        />
        <path
            d="M22.5 29H25.5C25.8 29 26 28.8 26 28.5V25.5C26 25.2 25.8 25 25.5 25H22.5C22.2 25 22 25.2 22 25.5V28.5C22 28.8 22.2 29 22.5 29ZM23 26H25V28H23V26Z"
            fill={color}
        />
        <path
            d="M18.5 31H15.5C15.2 31 15 31.2 15 31.5V34.5C15 34.8 15.2 35 15.5 35H18.5C18.8 35 19 34.8 19 34.5V31.5C19 31.2 18.8 31 18.5 31ZM18 34H16V32H18V34Z"
            fill={color}
        />
        <path
            d="M29.5 35H32.5C32.8 35 33 34.8 33 34.5V31.5C33 31.2 32.8 31 32.5 31H29.5C29.2 31 29 31.2 29 31.5V34.5C29 34.8 29.2 35 29.5 35ZM30 32H32V34H30V32Z"
            fill={color}
        />
        <path
            d="M22.5 35H25.5C25.8 35 26 34.8 26 34.5V31.5C26 31.2 25.8 31 25.5 31H22.5C22.2 31 22 31.2 22 31.5V34.5C22 34.8 22.2 35 22.5 35ZM23 32H25V34H23V32Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGCalendar = React.memo(SVGCalendar);

export { SVGCalendar };

import * as React from "react";
import { debounce } from "lodash";

export enum Orientation {
    LANDSCAPE = "landscape",
    PORTRAIT = "portrait",
}

const resizeProcessingDelay = 100;
const getOrientation = () =>
    typeof window === "undefined" || window.innerWidth >= window.innerHeight
        ? Orientation.LANDSCAPE
        : Orientation.PORTRAIT;

export const useOrientation = () => {
    const [orientation, setOrientation] = React.useState(getOrientation());

    React.useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }

        let prevValue: Orientation;
        const handleOrientation = debounce(() => {
            const newValue = getOrientation();
            //Condition looks crazy but we need it here as React rerenders component multiple times withot it
            prevValue !== newValue && setOrientation((prevValue = newValue));
        }, resizeProcessingDelay);

        window.addEventListener("resize", handleOrientation);

        return () => {
            window.removeEventListener("resize", handleOrientation);
        };
    }, []);

    return orientation;
};

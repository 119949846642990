import * as React from "react";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import { useGTM } from "../../../GTM/useGTM";
import { hasLink, isMenuItemWithSubItems } from "../../shared";

const MainMenuDesktopNavItem: React.FC<{ item: LXS.MenuItemLink }> = ({ item }) => {
    const { gtmClick } = useGTM();
    const linkValue = item.link && item.link.value;
    if (!hasLink(item) || !linkValue) {
        return null;
    }
    return isMenuItemWithSubItems(item) ? (
        <span className="lxs-main-menu-desktop-nav__item">{linkValue.text}</span>
    ) : (
        <Link
            className="lxs-main-menu-desktop-nav__item lxs-main-menu-desktop-nav__item--link"
            field={item.link}
            onClick={gtmClick}
            data-gtm-target={linkValue && linkValue.text}
            data-gtm-action={linkValue && linkValue.href}
            data-gtm-linktype="PrimaryLink"
        />
    );
};

export { MainMenuDesktopNavItem };

import { logoutThunk } from "ReduxSlices/user";
import { FunctionComponent, useEffect } from "react";

import { useThunkDispatch } from "Hooks/thunk";

const SilentSignOut: FunctionComponent = () => {
    const dispatch = useThunkDispatch();

    useEffect(() => {
        dispatch(logoutThunk);
    }, []);

    return null;
};

export default SilentSignOut;

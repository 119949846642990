import * as React from "react";
import "./ModelsMenuMobileBodyTypes.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { SVGIconLink, SVGIconLinkIconPosition } from "../../../SVGIconLink/SVGIconLink";
import { ModelsMenuMobileVehicleModels, VehicleModelsDisplayType } from "./ModelsMenuMobileVehicleModels";
import classNames from "classnames";
import { SVGChevronRight } from "../../../SVGIcons/icons/static/SVGChevronRight";
import { lxColor } from "../../../../colors/variables";
import { createBemFn } from "../../../../utilities/bem";
import { doesModelHaveAllTags, hasAnyMatchingModel } from "../shared";

interface ModelsMenuMobileBodyTypeProps {
    selectedTagIds: string[];
    bodyType: LXS.BodyType;
}

interface ModelsMenuMobileBodyTypesProps {
    selectedTagIds: string[];
}

const bem = createBemFn("lxs-models-menu-mobile-body-type");

const ModelsMenuMobileBodyType: React.FunctionComponent<ModelsMenuMobileBodyTypeProps> = ({
    bodyType,
    selectedTagIds,
}) => {
    const hasMatchingTags = hasAnyMatchingModel(bodyType, selectedTagIds);
    const isCarousel = selectedTagIds.length === 0;
    const models = bodyType.vehicleModels.filter((vm) => doesModelHaveAllTags(selectedTagIds, vm));

    return (
        <div
            className={classNames(
                bem(),
                { [bem(undefined, "with-margin")]: isCarousel },
                { [bem(undefined, "hidden")]: !hasMatchingTags },
            )}
        >
            <div className={bem("header")}>
                <span className={bem("title")}>{bodyType.title}</span>
                <SVGIconLink
                    field={{ href: bodyType.linkUrl, text: bodyType.linkTextMobile }}
                    textClass={bem("link-text")}
                    iconPosition={SVGIconLinkIconPosition.RIGHT}
                    iconClass={bem("link-icon")}
                    data-gtm-target={"BodyTypeLink"}
                    data-gtm-action={bodyType.title}
                >
                    <SVGChevronRight height={11} width={11} color={lxColor("black")} />
                </SVGIconLink>
            </div>
            <ModelsMenuMobileVehicleModels
                vehicleModels={models}
                displayType={VehicleModelsDisplayType.CAROUSEL}
                isActive={isCarousel}
            />
            <ModelsMenuMobileVehicleModels
                vehicleModels={models}
                displayType={VehicleModelsDisplayType.BLOCK}
                isActive={!isCarousel}
            />
        </div>
    );
};

const ModelsMenuMobileBodyTypes: React.FunctionComponent<ModelsMenuMobileBodyTypesProps> = ({ selectedTagIds }) => {
    const contextData = React.useContext(PrimaryNavContext);
    const bodyTypes = contextData?.modelsData?.bodyTypes;

    return (
        <>
            {bodyTypes?.map((bodyType) => (
                <ModelsMenuMobileBodyType selectedTagIds={selectedTagIds} bodyType={bodyType} key={bodyType.title} />
            ))}
        </>
    );
};

export { ModelsMenuMobileBodyTypes };

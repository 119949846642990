import * as React from "react";
import "./ModelsMenuDesktopBodyTypes.scss";
import { PrimaryNavContext } from "../../PrimaryNav";
import { SVGIconLink, SVGIconLinkIconPosition } from "../../../SVGIconLink/SVGIconLink";
import { ModelsMenuDesktopModelGrid } from "./ModelsMenuDesktopModelGrid";
import classNames from "classnames";
import { lxColor } from "../../../../colors/variables";
import { SVGChevronRight } from "../../../SVGIcons/icons/static/SVGChevronRight";
import { createBemFn } from "../../../../utilities/bem";
import { doesModelHaveAllTags, hasAnyMatchingModel } from "../shared";

interface ModelsMenuDesktopBodyTypeProps {
    selectedTagIds: string[];
    bodyType: LXS.BodyType;
}

interface ModelsMenuDesktopBodyTypesProps {
    selectedTagIds: string[];
}

const bem = createBemFn("lxs-models-menu-desktop-body-type");

const ModelsMenuDesktopBodyType: React.FC<ModelsMenuDesktopBodyTypeProps> = ({ selectedTagIds, bodyType }) => {
    const hasMatchingTags = hasAnyMatchingModel(bodyType, selectedTagIds);
    const models = React.useMemo(
        () => bodyType.vehicleModels.filter((vm) => doesModelHaveAllTags(selectedTagIds, vm)),
        [bodyType.vehicleModels, selectedTagIds],
    );

    return (
        <div className={classNames(bem(), { [bem(undefined, "hidden")]: !hasMatchingTags })}>
            <div className={bem("header")}>
                <span className={bem("header-title")}>{bodyType.title}</span>
                <SVGIconLink
                    field={{ href: bodyType.linkUrl, text: bodyType.linkText }}
                    textClass={bem("header-link-text")}
                    iconPosition={SVGIconLinkIconPosition.RIGHT}
                    iconClass={bem("header-link-icon")}
                    data-gtm-target={"BodyTypeLink"}
                    data-gtm-action={bodyType.title}
                >
                    <SVGChevronRight height={16} width={16} color={lxColor("black")} />
                </SVGIconLink>
            </div>
            <div className={bem("vehicles")}>
                <ModelsMenuDesktopModelGrid vehicleModels={models} />
            </div>
        </div>
    );
};

const ModelsMenuDesktopBodyTypes: React.FC<ModelsMenuDesktopBodyTypesProps> = ({ selectedTagIds }) => {
    const contextData = React.useContext(PrimaryNavContext);
    const bodyTypes = contextData?.modelsData?.bodyTypes;

    return (
        <>
            {bodyTypes?.map((bodyType) => (
                <ModelsMenuDesktopBodyType key={bodyType.title} bodyType={bodyType} selectedTagIds={selectedTagIds} />
            ))}
        </>
    );
};

export { ModelsMenuDesktopBodyTypes };

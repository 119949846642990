import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGTime: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fillRule="evenodd"
      d="M8.059 15a7 7 0 1 1 0-14 7 7 0 0 1 0 14Zm0-1a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm.5-6.5h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5V3.5a.5.5 0 0 1 1 0v4Z"
      clipRule="evenodd"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGTime = memo(SVGTime);

export { SVGTime };

export enum BookVehicleStatus {
    Available = "available",
    Unavailable = "unavailable",
}

export enum VehicleFeatureId {
    AirConditioning = "7",
    BoatLicence = "9",
    AppleCarPlay = "12",
    AndroidAuto = "13",
    WirelessPhoneCharger = "23",
    AppleCarPlayOrAndroidAuto = "28",
}

export enum OnDemandAction {
    GetAccountInfo = "GetAccountInfo",
    SetSelectedLocation = "SetSelectedLocation",
    FetchLocations = "FetchLocations",
    FetchPublicHolidays = "FetchPublicHolidays",
    UpdateUser = "UpdateUser",
    MakeBooking = "MakeBooking",
    FetchOnDemandVehicles = "FetchOnDemandVehicles",
    SetBookingInProgressPickUpAndDropOffDateTimes = "SetBookingInProgressPickUpAndDropOffDateTimes",
    ResetBookingInProgressPickUpAndDropOffDateTimes = "ResetBookingInProgressPickUpAndDropOffDateTimes",
    SetBookingInProgressFlightNumbers = "SetBookingInProgressFlightNumbers",
    ResetBookingInProgressFlightNumbers = "ResetBookingInProgressFlightNumbers",
    SetSelectedVehicle = "SetSelectedVehicle",
    ResetSelectedVehicle = "ResetSelectedVehicle",
    ResetBookVehicle = "ResetBookVehicle",
    GetBookingHistory = "GetBookingHistory",
    ResetCancelBooking = "ResetCancelBooking",
    CancelBooking = "CancelBooking",
    ResetBookingHistory = "ResetBookingHistory",
    InitializeBookingInProgress = "InitializeBookingInProgress",
    SetCurrentBooking = "SetCurrentBooking",
    ResetCurrentBooking = "ResetCurrentBooking",
    UpdateBooking = "UpdateBooking",
}

export const defaultMaxBookingDurationInDays = 8;

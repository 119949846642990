import * as React from "react";
import "./MainMenuDesktopSearch.scss";
import { doSearch, searchIcon } from "../utilities";
import { PrimaryNavContext } from "../../PrimaryNav";
import { useGTM } from "../../../GTM/useGTM";
import classNames from "classnames";

const MainMenuDesktopSearch: React.FC = () => {
    const { searchConfig } = React.useContext(PrimaryNavContext);
    const searchInput = React.createRef<HTMLInputElement>();
    const searchContainer = React.createRef<HTMLFormElement>();
    const { gtmInteraction } = useGTM();
    const [isFocused, setIsFocused] = React.useState(false);
    const onClick = () => searchInput.current && searchInput.current.focus();
    const onFocus = () => {
        gtmInteraction("SearchInput", "Focus");
        setIsFocused(true);
    };
    const onBlur = () => {
        gtmInteraction("SearchInput", "Blur");
        setIsFocused(false);
    };

    const onSubmit = (e: React.FormEvent) => {
        gtmInteraction("SearchForm", "Submit", { query: (searchInput.current && searchInput.current.value) || "" });
        doSearch(e, searchInput, searchConfig);
    };

    return (
        <form
            ref={searchContainer}
            className={classNames("lxs-main-menu-desktop-search", {
                "lxs-main-menu-desktop-search--focused": isFocused,
            })}
            onSubmit={onSubmit}
        >
            <button className="lxs-main-menu-desktop-search__button" type="submit">
                {searchIcon}
            </button>
            <div className="lxs-main-menu-desktop-search__icon">{searchIcon}</div>
            <div className="lxs-main-menu-desktop-search__prompt" onClick={onClick}>
                {searchConfig && searchConfig.searchPrompt}
            </div>
            <input
                onBlur={onBlur}
                onFocus={onFocus}
                className="lxs-main-menu-desktop-search__input"
                type="text"
                placeholder={searchConfig && searchConfig.searchPrompt}
                ref={searchInput}
            />
        </form>
    );
};

export { MainMenuDesktopSearch };

import * as React from "react";
import Toast from "../../components/Toast/Toast";
import DiscardUpdateBookingCard from "../../components/errorCards/DiscardUpdateBookingCard/DiscardUpdateBookingCard";

const DiscardUpdateBookingToast: React.FC = () => (
    <Toast>
        <DiscardUpdateBookingCard />
    </Toast>
);

export default DiscardUpdateBookingToast;

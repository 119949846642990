import * as React from "react";
import "./MainMenuMobileSearch.scss";
import { doSearch, searchIcon } from "../utilities";
import { PrimaryNavContext } from "../../PrimaryNav";
import { useGTM } from "../../../GTM/useGTM";

const MainMenuMobileSearch: React.FC = () => {
    const { searchConfig } = React.useContext(PrimaryNavContext);
    const searchInput = React.createRef<HTMLInputElement>();
    const { gtmInteraction } = useGTM();

    const onSubmit = (e: React.FormEvent) => {
        gtmInteraction("SearchForm", "Submit", { query: (searchInput.current && searchInput.current.value) || "" });
        doSearch(e, searchInput, searchConfig);
    };

    const onFocus = () => {
        gtmInteraction("SearchInput", "Focus");
    };

    const onBlur = () => {
        gtmInteraction("SearchInput", "Blur");
    };

    return (
        <form className="lxs-main-menu-mobile-search" onSubmit={onSubmit}>
            <input
                className="lxs-main-menu-mobile-search__input"
                type="text"
                placeholder={searchConfig && searchConfig.searchPrompt}
                ref={searchInput}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            <button className="lxs-main-menu-mobile-search__button" type="submit">
                {searchIcon}
            </button>
        </form>
    );
};

export { MainMenuMobileSearch };

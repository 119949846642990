import classnames from "classnames";
import * as React from "react";
import { isMenuItemWithSubItems } from "../../shared";
import { MainMenuDesktopContext } from "./MainMenuDesktop";
import { MainMenuDesktopNavItem } from "./MainMenuDesktopNavItem";
import { MainMenuDesktopSecondaryNav } from "./MainMenuDesktopSecondaryNav";

const MainMenuDesktopNavListItem: React.FunctionComponent<{ item: LXS.MenuItemLink }> = ({ item }) => {
    const { setMainMenuNavListItemActive } = React.useContext(MainMenuDesktopContext);
    const [active, setActive] = React.useState(false);

    const activate = () => {
        setActive(true);
        setMainMenuNavListItemActive(true);
    };

    const deactivate = () => {
        setActive(false);
        setMainMenuNavListItemActive(false);
    };

    return (
        <li
            className={classnames("lxs-main-menu-desktop-nav__list-item", {
                "lxs-main-menu-desktop-nav__list-item--active": active,
            })}
            onMouseEnter={activate}
            onMouseLeave={deactivate}
        >
            <MainMenuDesktopNavItem item={item} />
            {isMenuItemWithSubItems(item) && (
                <MainMenuDesktopSecondaryNav
                    items={item.menuItems}
                    active={active}
                    image={item.image && item.image.value}
                />
            )}
        </li>
    );
};

export { MainMenuDesktopNavListItem };

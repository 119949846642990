import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGLock: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.81607 9.09997C9.15 9.09985 9.15 9.09938 9.15 9.0989V9.09765L9.15001 9.09472L9.15006 9.0871L9.15033 9.06499C9.15061 9.04725 9.15114 9.02355 9.15212 8.99427C9.15407 8.93574 9.15781 8.85476 9.16497 8.75449C9.17927 8.55431 9.20736 8.27519 9.26272 7.94301C9.37262 7.28361 9.59438 6.38838 10.0484 5.48042C10.5044 4.56836 11.2049 3.62436 12.2754 2.91072C13.3524 2.1927 14.7413 1.75 16.5 1.75C18.2587 1.75 19.6476 2.1927 20.7246 2.91072C21.7951 3.62436 22.4956 4.56836 22.9516 5.48042C23.4056 6.38838 23.6274 7.28361 23.7373 7.94301C23.7926 8.27519 23.8207 8.55431 23.835 8.75449C23.8422 8.85476 23.8459 8.93574 23.8479 8.99427C23.8489 9.02355 23.8494 9.04725 23.8497 9.06499L23.8499 9.0871L23.85 9.09472L23.85 9.09765V9.0989C23.85 9.09946 23.85 9.1 22.8 9.1H23.85V13.3H21.75V9.10309L21.7499 9.09886L21.7497 9.08553L21.749 9.06423C21.7479 9.03047 21.7455 8.9761 21.7404 8.90411C21.7301 8.75975 21.7089 8.54668 21.6658 8.28824C21.5789 7.76639 21.4069 7.08662 21.0734 6.41957C20.7419 5.75664 20.2612 5.12564 19.5598 4.65803C18.8649 4.1948 17.8913 3.85 16.5 3.85C15.1087 3.85 14.1351 4.1948 13.4402 4.65803C12.7388 5.12564 12.2581 5.75664 11.9266 6.41957C11.5931 7.08662 11.4211 7.76639 11.3342 8.28824C11.2911 8.54668 11.2699 8.75975 11.2596 8.90411C11.2545 8.9761 11.2521 9.03047 11.251 9.06423C11.2504 9.08109 11.2502 9.09276 11.2501 9.09886L11.25 9.10268V13.3H9.15V9.09997H9.81607ZM9.15 13.3001H23.85C25.5897 13.3001 27 14.7104 27 16.4501V26.9501C27 28.6898 25.5897 30.1001 23.85 30.1001H9.15C7.4103 30.1001 6 28.6898 6 26.9501V16.4501C6 14.7104 7.4103 13.3001 9.15 13.3001ZM9.15 15.4001C8.5701 15.4001 8.1 15.8702 8.1 16.4501V26.9501C8.1 27.53 8.5701 28.0001 9.15 28.0001H23.85C24.4299 28.0001 24.9 27.53 24.9 26.9501V16.4501C24.9 15.8702 24.4299 15.4001 23.85 15.4001H9.15ZM16.5 24C17.8807 24 19 22.8807 19 21.5C19 20.1193 17.8807 19 16.5 19C15.1193 19 14 20.1193 14 21.5C14 22.8807 15.1193 24 16.5 24Z"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGLock = memo(SVGLock);

export { SVGLock };

import classnames from "classnames";
import * as React from "react";
import { lxColor } from "../../../colors/variables";
import "../../../icons/index.scss";
import "../../../icons/load-icons.scss";
import { FieldBelow } from "../FieldBelow/FieldBelow";
import NotchBorderAndLabel from "../NotchBorderAndLabel/NotchBorderAndLabel";
import Select from "react-select";
import "./SelectField.scss";
import { ValueType, ActionMeta } from "react-select/lib/types";
import { SVGCheckMark } from "../../SVGIcons/icons/static/SVGCheckMark";
import { Props } from "react-select/lib/Select";

const defaultMaxMenuHeight = 600;

interface SelectFieldProps extends Omit<Props<LXS.SelectFieldOption>, "onChange"> {
    label?: string;
    onChange?: (value: LXS.SelectFieldOption) => void;
    isValid?: boolean;
    validText?: string;
    showSuccessTick?: boolean;
    isError?: boolean;
    errorText?: string;
}

const SelectFieldInternal = (
    {
        id,
        label,
        value,
        onChange,
        isValid,
        validText,
        showSuccessTick,
        isError,
        errorText,
        className,
        onMenuOpen,
        onMenuClose,
        options = [],
        maxMenuHeight = defaultMaxMenuHeight,
        menuPlacement = "auto",
        placeholder = "",
        ...passThroughSelectProps
    }: SelectFieldProps,
    ref: React.Ref<HTMLDivElement>,
) => {
    // tickSize matches $tick-size in ./SelectField.scss
    const tickSize = 16;

    const [isOpen, setIsOpen] = React.useState(false);

    const setValue = React.useCallback(
        (value: ValueType<LXS.SelectFieldOption>, action: ActionMeta) => {
            if (
                onChange &&
                action.action === "select-option" &&
                value !== null &&
                value !== undefined &&
                !Array.isArray(value)
            ) {
                onChange(value);
            }
        },
        [onChange],
    );

    const onClose = React.useCallback(() => {
        setIsOpen(false);
        if (onMenuClose !== undefined) {
            onMenuClose();
        }
    }, [onMenuClose]);

    const onOpen = React.useCallback(() => {
        setIsOpen(true);
        if (onMenuOpen !== undefined) {
            onMenuOpen();
        }
    }, [onMenuOpen]);

    const isActive = value !== undefined || isOpen;
    return (
        <div
            ref={ref}
            className={classnames(
                "lxs-select-field",
                { "lxs-select-field--open": isOpen },
                { "lxs-select-field--valid": isValid },
                { "lxs-select-field--show-success-tick": showSuccessTick },
                className,
            )}
        >
            <div className="lxs-select-field__input" id={id}>
                <NotchBorderAndLabel {...{ label, isFocused: isOpen, isActive, isValid, isError }} />
                <SVGCheckMark
                    width={tickSize}
                    height={tickSize}
                    color={lxColor("success")}
                    className="lxs-select-field__tick"
                />
                <Select
                    {...passThroughSelectProps}
                    placeholder={placeholder}
                    options={options}
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={setValue}
                    maxMenuHeight={maxMenuHeight}
                    onMenuClose={onClose}
                    onMenuOpen={onOpen}
                    menuPlacement={menuPlacement}
                    value={value}
                />
            </div>
            <FieldBelow text={{ validText, isValid, errorText, isError }} />
        </div>
    );
};

const SelectField = React.forwardRef(SelectFieldInternal);

export { SelectField };

import * as React from "react";
import { Route, Switch } from "react-router-dom";
import GuestRegistrationErrorToast from "../../toast/GuestRegistrationErrorToast/GuestRegistrationErrorToast";
import { RouteSection, toastPath } from "../../helpers/routes";
import OnDemandErrorToast from "../../toast/OnDemandErrorToast/OnDemandErrorToast";
import OnDemandVehicleAlreadyBookedErrorToast from "../../toast/OnDemandVehicleAlreadyBookedErrorToast/OnDemandVehicleAlreadyBookedErrorToast";
import NonRecoverableErrorToast from "../../toast/NonRecoverableErrorToast/NonRecoverableErrorToast";
import ValetRedemptionBalanceErrorToast from "../../toast/ValetRedemptionBalanceErrorToast/ValetRedemptionBalanceErrorToast";
import OnDemandRedemptionBalanceErrorToast from "../../toast/OnDemandRedemptionBalanceErrorToast/OnDemandRedemptionBalanceErrorToast";
import CancelBookingErrorToast from "../../toast/CancelBookingErrorToast/CancelBookingErrorToast";
import BookVehicleClearProgressToast from "../../toast/BookVehicleClearProgressToast/BookVehicleClearProgressToast";
import MagicLinkErrorToast from "../../toast/MagicLinkErrorToast/MagicLinkErrorToast";
import VehicleUnavailableToast from "../../toast/VehicleUnavailableToast/VehicleUnavailableToast";
import DiscardUpdateBookingToast from "../../toast/DiscardUpdateBookingToast/DiscardUpdateBookingToast";

const ToastRoutes: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path={toastPath(RouteSection.NonRecoverableError)} component={NonRecoverableErrorToast} />
            <Route path={toastPath(RouteSection.MagicLinkError)} component={MagicLinkErrorToast} />
            <Route path={toastPath(RouteSection.GuestRegistrationError)} component={GuestRegistrationErrorToast} />
            <Route
                path={toastPath(RouteSection.ValetRedemptionBalanceError)}
                component={ValetRedemptionBalanceErrorToast}
            />
            <Route
                path={toastPath(RouteSection.OnDemandRedemptionBalanceError)}
                component={OnDemandRedemptionBalanceErrorToast}
            />
            <Route path={toastPath(RouteSection.OnDemandError)} component={OnDemandErrorToast} />
            <Route
                path={toastPath(
                    RouteSection.OnDemandVehicleAlreadyBookedError,
                    RouteSection.Encore,
                    RouteSection.OnDemand,
                    RouteSection.Bookings
                )}
                component={OnDemandVehicleAlreadyBookedErrorToast}
            />
            <Route path={toastPath(RouteSection.CancelBookingError)} component={CancelBookingErrorToast} />
            <Route
                exact={true}
                path={`${toastPath(RouteSection.VehicleUnavailable)}/${RouteSection.ToastParam}`}
                component={VehicleUnavailableToast}
            />
            <Route path={toastPath(RouteSection.VehicleUnavailable)} component={VehicleUnavailableToast} />
            <Route
                path={`${toastPath(RouteSection.BookVehicleClearProgressError)}/${RouteSection.ToastParam}`}
                component={BookVehicleClearProgressToast}
            />
            <Route path={toastPath(RouteSection.DiscardUpdateBooking)} component={DiscardUpdateBookingToast} />
        </Switch>
    );
};

export default ToastRoutes;

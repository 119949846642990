import classnames from "classnames";
import * as React from "react";
import Modal from "react-modal/lib/components/Modal";
import { createBemFn } from "../../utilities/bem";
import { SVGCross } from "../SVGIcons/icons/static/SVGCross";
import { SVGIconButton } from "../SVGIcons/SVGIconButton";
import "./Toast.scss";

const bem = createBemFn("lxs-toast");

interface ToastProps {
    title?: string;
    isOpen: boolean;
    onClose?: () => void;
    hideClose?: boolean;
    contentClass?: string;
    shouldCloseOnOverlayClick?: boolean;
}

interface ToastContextProps {
    appElement?: HTMLElement;
}

const ToastContext = React.createContext<ToastContextProps>({ appElement: undefined });

const Toast: React.FC<ToastProps> = ({
    title,
    isOpen,
    onClose,
    hideClose,
    contentClass,
    shouldCloseOnOverlayClick,
    children,
}) => (
    <Modal
        isOpen={isOpen}
        appElement={React.useContext(ToastContext).appElement}
        onRequestClose={onClose}
        className={bem("wrapper")}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        overlayClassName={{
            base: bem("overlay"),
            afterOpen: bem("overlay", "after-open"),
            beforeClose: bem("overlay", "before-close"),
        }}
        closeTimeoutMS={500}
    >
        <div className={bem()}>
            {title && <span className={bem("title")}>{title}</span>}
            <span className={classnames(bem("content"), contentClass)}>{children}</span>
            {!hideClose && (
                <SVGIconButton className={bem("close-button")} onClick={onClose}>
                    <SVGCross width={16} height={16} />
                </SVGIconButton>
            )}
        </div>
    </Modal>
);

export { Toast, ToastContext };

import cn from "classnames";

import { AvailableIcons, iconRenderer } from "@/components/SVGIcon/static/SVGSelector";

import styles from "./DealerService.module.scss";
import { DEALER_BRANCH_INFO_ICON_SIZE } from "../../shared";

type DealerServiceLineProps = {
  icon?: AvailableIcons | string;
  content: React.ReactNode;
  linkReference?: string;
  className?: string;
  iconClassName?: string;
};

const DealerServiceLine: React.FC<DealerServiceLineProps> = ({
  icon,
  content,
  linkReference,
  className,
  iconClassName,
}) => {
  return !content ? null : (
    <div className={cn(styles.dealerService, className)}>
      <span className={cn(styles.dealerServiceIconContainer, iconClassName)}>
        {iconRenderer(icon || "", {
          width: DEALER_BRANCH_INFO_ICON_SIZE,
          height: DEALER_BRANCH_INFO_ICON_SIZE,
          className: styles.dealerServiceIcon,
        })}
      </span>
      <a href={linkReference}>{content}</a>
    </div>
  );
};

export { DealerServiceLine };

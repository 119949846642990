import * as React from "react";
import ReactIframeResizer from "react-iframe-resizer-super";

interface ResizableIframeProps {
    url: string;
    iframeResizerOptions?: unknown;
}

export const ResizableIframe: React.FC<ResizableIframeProps> = ({ url, iframeResizerOptions }) => (
    <ReactIframeResizer iframeResizerOptions={iframeResizerOptions} iframeResizerUrl={false} src={url} />
);

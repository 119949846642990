import typographyStyles from "../common/typography.module.scss";

type DisclaimerReferenceProps = React.PropsWithChildren<{
  reference: string;
}>;

export const DisclaimerReference: React.FC<DisclaimerReferenceProps> = ({ reference }) => {
  return (
    <sup data-testid="lk-tg-sup" className={typographyStyles.superscript}>
      {reference}
    </sup>
  );
};

import { useEffect, useState } from "react";

import { useWindowMessage } from "@/hooks/useWindowMessage";

import { AuthenticationConfig } from "../authentication.types";
import { ENCORE_LOGOUT_EVENT } from "../utils";

export const useLoggingOut = (onLogout?: () => void, config?: AuthenticationConfig) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    if (config?.isAuthenticationHost) {
      setIsLoggingOut(false);
      return;
    }

    const handleLogoutBegin = () => {
      setIsLoggingOut(true);
    };

    window.addEventListener(ENCORE_LOGOUT_EVENT, handleLogoutBegin);

    return () => {
      window.removeEventListener(ENCORE_LOGOUT_EVENT, handleLogoutBegin);
    };
  }, [config?.isAuthenticationHost]);

  useWindowMessage("logout-success", () => {
    setIsLoggingOut(false);
    onLogout?.();
  });

  useWindowMessage("logout-failure", () => {
    setIsLoggingOut(false);
  });

  return isLoggingOut;
};

export enum BrandColours {
  blackAbsolute = "#000000",
  deepBlueDark = "#161825",
  deepblueDefault = "#232635",
  deepBlueLight = "#313649",
  smokeDark = "#d7d3c6",
  smokeDefault = "#e4e1d7",
  smokeLight = "#f0eee7",
  inari = "#ca4b19",
}

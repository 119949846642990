import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGFacebook: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M17.462.75v4.285h-2.143c-.739 0-1.07.867-1.07 1.606V9.32h3.213v4.285h-3.214v8.859H9.963v-8.86H6.75V9.32h3.213V5.035A4.283 4.283 0 0 1 14.248.75h3.214Z" />
  </svg>

  /* eslint-enable max-len */
);
SVGFacebook = memo(SVGFacebook);

export { SVGFacebook };

import { Responsive } from "@/components/Responsive/Responsive";

import { PrimaryNavBackButton } from "./PrimaryNavBackButton";
import { FilterProvider } from "./PrimaryNavModalModels.context";
import { PrimaryNavDesktopLayout } from "../PrimaryNavDesktopLayout";
import { PrimaryNavMobilePage } from "../PrimaryNavMobilePage";
import { PrimaryNavModalContainer } from "../PrimaryNavModalContainer";
import { PrimaryNavModalModelsResult } from "../PrimaryNavModalModelsResult";
import { PrimaryNavStaticLinks } from "../PrimaryNavStaticLinks/PrimaryNavStaticLinks";
import { MenuItemWithIconData, BodyTypeData } from "../types/types";

type PrimaryNavTabModelsProps = {
  closeButton: React.ReactNode;
  bodyTypes: BodyTypeData[];
  staticLinks: MenuItemWithIconData[];
  backLinkText: string;
  popActiveModal: React.MouseEventHandler;
};

const PrimaryNavModalModels: React.FC<PrimaryNavTabModelsProps> = ({
  closeButton,
  bodyTypes,
  staticLinks,
  backLinkText,
  popActiveModal,
}) => {
  return (
    <>
      <FilterProvider>
        <Responsive breakpoint="small">
          <PrimaryNavMobilePage
            closeButton={closeButton}
            variant="level2"
            headerChildren={
              <PrimaryNavModalContainer>
                <PrimaryNavBackButton onClick={popActiveModal}>{backLinkText}</PrimaryNavBackButton>
              </PrimaryNavModalContainer>
            }
            footerChildren={<PrimaryNavStaticLinks items={staticLinks} />}
          >
            <PrimaryNavModalContainer>
              <PrimaryNavModalModelsResult bodyTypes={bodyTypes} />
            </PrimaryNavModalContainer>
          </PrimaryNavMobilePage>
        </Responsive>

        <Responsive breakpoint="large">
          <PrimaryNavModalContainer parentIsFlexbox={true} fillParentHeight={true}>
            <PrimaryNavDesktopLayout
              closeButton={closeButton}
              isLevel2LayoutAvailable={false}
              level1LayoutChildren={<PrimaryNavModalModelsResult bodyTypes={bodyTypes} />}
              level1LayoutFooterChildren={
                <PrimaryNavStaticLinks items={staticLinks} layout="horizontal" />
              }
            />
          </PrimaryNavModalContainer>
        </Responsive>
      </FilterProvider>
    </>
  );
};

export { PrimaryNavModalModels };

import { Children } from "react";

import styles from "./LabelAndValues.module.scss";
import { Stack } from "../Stack";
import { Typography } from "../Typography/Typography";

export interface LabelAndValuesProps {
  /**
   * The label of the section content, usually describe type of content
   */
  label: string;
  children: React.ReactNode[];
  /**
   * Display content in one row or in multiple rows
   */
  layout?: "vertical" | "horizontal";
  /**
   * Spacing can be normal or minimal between children elements
   */
  spacing?: "normal" | "minimal";
  /**
   * Content alignment can be at the left of container or center aligned
   * NOTE: `left` is valid only for `ltr` direction, if you use `rtl` it will align with valid `start` of container
   */
  alignment?: "left" | "center";
  /**
   * Extra styling for component
   */
  className?: string;
}
/**
 * `LabelAndValues` component is used to display content inside a container that follows some pattern. E.g. label follwing
 * by multiple children content that can be displayed horizontally or vertically
 *
 * `LabelAndValues` supports different alignments, spacing and also allow className for custom styling
 */
export const LabelAndValues: React.FC<React.PropsWithChildren<LabelAndValuesProps>> = ({
  label,
  children,
  className,
  layout = "vertical",
  alignment = "left",
  spacing = "normal",
}: LabelAndValuesProps) => (
  <Stack
    spacing={spacing === "minimal" ? "4xs" : "2xs"}
    direction="column"
    flexWrap="wrap"
    alignItems={alignment === "center" ? "center" : "flex-start"}
    className={className}
  >
    <Typography className={styles.label} variant="l2">
      {label}
    </Typography>
    <Stack
      spacing={spacing === "minimal" ? "4xs" : "2xs"}
      alignItems={alignment === "center" ? "center" : "flex-start"}
      direction={layout === "vertical" ? "column" : "row"}
    >
      {Children.map(children, (lineText) => (
        <Typography variant="b1">{lineText}</Typography>
      ))}
    </Stack>
  </Stack>
);

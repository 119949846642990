import { Interval, JSDayNumber, KeazDayNumber, Minutes } from "../types/dateTime";
import moment, { Moment } from "moment";

import { padStart } from "lodash";

export const daysInWeek = 7;
export const hoursInDay = 24;
export const hoursInHalfDay = hoursInDay / 2;
export const milliSecondsInSecond = 1000;
export const secondsInMinute = 60;
export const minutesInHour = 60;
export const minutesInHalfHour = minutesInHour / 2;
export const secondsInHour = secondsInMinute * minutesInHour;
const jsDayToKeazDayIncrement = 6;

export const timeString24ToMinutes = (time: string): Minutes | undefined => {
    const split = time.split(":");
    if (split.length !== 2) {
        return;
    }

    const hours = parseInt(split[0]);
    const minutes = parseInt(split[1]);
    if (isNaN(hours) || isNaN(minutes)) {
        return;
    }

    return (hours % hoursInDay) * minutesInHour + (minutes % minutesInHour);
};

export const intervalToTimes = (interval: Interval, gap: Minutes = minutesInHalfHour): Minutes[] => {
    const result = [interval.start];
    const first = Math.floor(interval.start / gap + 1) * gap;

    for (let t = first; t < interval.end; t += gap) {
        result.push(t);
    }

    if (interval.start < interval.end) {
        result.push(interval.end);
    }

    return result;
};

export const timeTo12HourString = (time: Minutes, spaceBetweenAmPm = true): string => {
    const time24 = time % (hoursInDay * minutesInHour);
    const time12 = time24 % (hoursInHalfDay * minutesInHour);
    const amPmString = time12 === time24 ? "am" : "pm";
    const hours = Math.floor(time12 / minutesInHour);
    const hoursStr = padStart(hours === 0 ? "12" : hours.toString(), 2, "0");
    const minutes = time12 - hours * minutesInHour;
    const minutesStr = padStart(minutes.toString(), 2, "0");
    return `${hoursStr}:${minutesStr}${spaceBetweenAmPm ? " " : ""}${amPmString}`;
};

export const jSDayNumberToKeazDayNumber = (js: JSDayNumber): KeazDayNumber =>
    (js + jsDayToKeazDayIncrement) % daysInWeek;

export const dateWithTime = (date: Date, time: Minutes): Date => {
    const result = new Date(date);
    const hours = Math.floor(time / minutesInHour);
    const minutes = time - hours * minutesInHour;
    result.setHours(hours % hoursInDay, minutes % minutesInHour);
    return result;
};

// Converts a Date object to a string in the format of yyyy-mm-dd
// The month is offset by one because month in js uses zero based index, but our
// API and keaz use one based.
export const dateToYYYYMMDD = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

// Converts a Date object to a string in the format of HH:MM, MM means minutes here
export const dateToHHMM = (date: Date): string => {
    return `${date.getHours()}:${date.getMinutes()}`;
};

export const epochAndOffsetToDate = (epoch: number, offset: number): moment.Moment => {
    const bookingTime = epochToUtcDate(epoch)
        .utcOffset(-offset / secondsInHour)
        .clone();
    return bookingTime;
};

export const dateWithOffset = (epoch: number, offset: number): Date =>
    new Date(epochAndOffsetToDate(epoch, offset).format("MMM DD, YYYY HH:mm"));

export const epochToUtcDate = (epoch: number): moment.Moment => moment.unix(epoch).clone().utc();

export const getDate = (day: number, month: number, year: number): Date | undefined => {
    const date = new Date(year, month - 1, day);
    if (!isNaN(date.valueOf())) {
        return date;
    }
    return;
};

export const isFutureDate = (expiry?: Date): boolean => {
    if (expiry === undefined) {
        return false;
    }

    const expiryValue = expiry.valueOf();
    const nowValue = new Date().valueOf();
    return !isNaN(expiryValue) && !isNaN(nowValue) && expiryValue > nowValue;
};

export const isTimeWithinInterval = (time?: number, interval?: Interval): boolean =>
    time !== undefined && interval !== undefined && time >= interval.start && time <= interval.end;

const isWeekend = (date: Moment) => date.day() === JSDayNumber.Saturday || date.day() === JSDayNumber.Sunday;

export const differenceInBusinessDays = (start: Moment, end: Moment): number => {
    const startCloned = start.clone();
    let nBusinessDays = 0;

    if (startCloned.isSame(end)) {
        return nBusinessDays;
    }

    while (startCloned < end) {
        if (!isWeekend(startCloned)) {
            nBusinessDays++;
        }
        startCloned.add(1, "d");
    }

    return nBusinessDays;
};

interface DateTime {
    start: Moment;
    end: Moment;
}
export const isDateTimeOutsideOriginalDateTime = (param: DateTime, original: DateTime): boolean =>
    param.end.isBefore(original.start) || param.start.isAfter(original.end);

export const numDaysAgoToUnix = (numDays: number): number => {
    const thousand = 1000;
    const d = new Date();
    d.setDate(d.getDate() - numDays);
    return d.getTime() / thousand;
};

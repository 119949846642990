import * as React from "react";
import { Theme } from "../../../shared/Theme";
import { SVGIconButton } from "../../../SVGIcons/SVGIconButton";
import "./MainMenuMobileHeader.scss";
import { SocialMedia } from "../../SocialMedia/SocialMedia";
import { PrimaryNavContext } from "../../PrimaryNav";
import { SVGCross } from "../../../SVGIcons/icons/static/SVGCross";
import { lxColor } from "../../../../colors/variables";

const MainMenuMobileHeader: React.FunctionComponent = () => {
    const { handleMenuClose } = React.useContext(PrimaryNavContext);
    return (
        <div className="lxs-main-menu-mobile-header lxs-grid-row">
            <div className="lxs-grid lxs-grid--with-margin">
                <div className="lxs-main-menu-mobile-header__social">
                    <SocialMedia />
                </div>
                <div className="lxs-main-menu-mobile-header__close-button">
                    <SVGIconButton
                        title="Close"
                        stackOnMobile={true}
                        onClick={handleMenuClose}
                        theme={Theme.DARK}
                        data-gtm-target={"CloseButton"}
                    >
                        <SVGCross height={20} width={20} color={lxColor("black", "absolute")} />
                    </SVGIconButton>
                </div>
            </div>
        </div>
    );
};

export { MainMenuMobileHeader };

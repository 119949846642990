import classnames from "classnames";
import * as React from "react";
import { lxColor } from "../../../colors/variables";
import "../../../icons/index.scss";
import "../../../icons/load-icons.scss";
import { FieldBelow } from "../FieldBelow/FieldBelow";
import NotchBorderAndLabel from "../NotchBorderAndLabel/NotchBorderAndLabel";
import { Async } from "react-select";
import "./AsyncSelectField.scss";
import { ValueType, ActionMeta } from "react-select/lib/types";
import { SVGCheckMark } from "../../SVGIcons/icons/static/SVGCheckMark";
import { AsyncProps } from "react-select/lib/Async";
import { Props } from "react-select/lib/Select";
import { SVGSearch } from "../../SVGIcons/icons/static/SVGSearch";

const defaultMaxMenuHeight = 600;

interface AsyncSelectFieldProps<T>
    extends Omit<Props<LXS.SelectFieldOption<T> & AsyncProps<LXS.SelectFieldOption<T>>>, "onChange"> {
    label?: string;
    onChange?: (selectedOption?: LXS.SelectFieldOption<T>) => void;
    isValid?: boolean;
    validText?: string;
    showSuccessTick?: boolean;
    isError?: boolean;
    errorText?: string;
}

type AsyncSelectFieldComponent = <T>(props: AsyncSelectFieldProps<T>) => React.ReactElement | null;

const AsyncSelectFieldInternal: AsyncSelectFieldComponent = <T,>({
    label,
    value,
    onChange,
    isValid,
    validText,
    showSuccessTick,
    isError,
    errorText,
    className,
    onMenuOpen,
    onMenuClose,
    maxMenuHeight = defaultMaxMenuHeight,
    menuPlacement = "auto",
    loadOptions,
    ...passThroughSelectProps
}: AsyncSelectFieldProps<T>) => {
    // tickSize matches $tick-size in ./AsyncSelectField.scss
    const tickSize = 16;

    const [isOpen, setIsOpen] = React.useState(false);

    const setValue = React.useCallback(
        (value: ValueType<LXS.SelectFieldOption<T>>, action: ActionMeta) => {
            if (onChange && (action.action === "select-option" || action.action === "clear") && !Array.isArray(value)) {
                onChange(value ?? undefined);
            }
        },
        [onChange],
    );

    const onClose = React.useCallback(() => {
        setIsOpen(false);
        if (onMenuClose !== undefined) {
            onMenuClose();
        }
    }, [onMenuClose]);

    const onOpen = React.useCallback(() => {
        setIsOpen(true);
        if (onMenuOpen !== undefined) {
            onMenuOpen();
        }
    }, [onMenuOpen]);

    const isActive = value !== undefined || isOpen;

    const DropdownIndicator = React.useMemo(
        () => (props: any) => {
            return props.hasValue ? null : (
                <div className="lxs-async-select-field__search-icon">
                    <SVGSearch width={17} height={17} color={lxColor("smoke")} />
                </div>
            );
        },
        [],
    );

    const IndicatorSeparator = React.useMemo(() => () => null, []);

    return (
        <div
            className={classnames(
                "lxs-async-select-field",
                { "lxs-async-select-field--open": isOpen },
                { "lxs-async-select-field--valid": isValid },
                { "lxs-async-select-field--show-success-tick": showSuccessTick },
                className,
            )}
        >
            <div className="lxs-async-select-field__input">
                <NotchBorderAndLabel {...{ label, isFocused: isOpen, isActive, isValid, isError }} />
                <SVGCheckMark
                    width={tickSize}
                    height={tickSize}
                    color={lxColor("success")}
                    className="lxs-async-select-field__tick"
                />
                <Async
                    {...passThroughSelectProps}
                    placeholder=""
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={setValue}
                    maxMenuHeight={maxMenuHeight}
                    onMenuClose={onClose}
                    onMenuOpen={onOpen}
                    menuPlacement={menuPlacement}
                    value={value}
                    loadOptions={loadOptions}
                    isClearable={true}
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    noOptionsMessage={() => null}
                />
            </div>
            <FieldBelow text={{ validText, isValid, errorText, isError }} />
        </div>
    );
};

const AsyncSelectField = React.memo(AsyncSelectFieldInternal) as React.MemoExoticComponent<AsyncSelectFieldComponent> &
    AsyncSelectFieldComponent;
export { AsyncSelectField };

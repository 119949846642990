import cn from "classnames";

import styles from "./FormSection.module.scss";
import Box from "../Box/Box";

type FormSectionProps = React.PropsWithChildren<{
  /**
   * Class name(s) to customise component if required.
   */
  className?: string;
}>;

/**
 * FormSection component allows you to wrap `form elements` in a section with predefined styles.
 * This component should only be used for `form elements` and should be used in conjunction with the `FormContainer`
 * component.
 */
export const FormSection: React.FC<FormSectionProps> = ({ className, children }) => {
  return (
    <Box p="default" className={cn(styles.formSection, className)}>
      {children}
    </Box>
  );
};

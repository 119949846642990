import { typedFetch } from "../../../utilities/fetch-extensions";
import { dateFromTotalSeconds, MILLISECONDS_PER_MINUTE } from "../../../utilities/time";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { GuestAccountType } from "../enums";
import { Guest } from "../Guest";
import { SalesforceError } from "../Errors/SalesforceError";
import base64url from "base64url";
import { isDefinedUnknownObject } from "../../../utilities/object";

/**
 * Amount of minutes when it is better to request a new token using refresh token for UX
 */
const ALMOST_INVALID_TOKEN_MINUTES = 3;

interface IdentityProviderProps {
    guestServiceUrl: string;
}

interface ErrorResponse {
    error: string;
    error_description?: string;
}

interface SalesforceErrorResponse {
    errorCode: string;
    caseNumber?: string;
}

interface LoginResponse {
    id_token: string;
    refresh_token: string;
    expires_in: string | number;
}

interface AuthIdResponse {
    authId: string;
}

interface MessageResponse extends Partial<AuthIdResponse> {
    messages: AuthenticationErrorType[];
}

enum ForgerockTokenType {
    JWTToken = "JWTToken",
    Bearer = "Bearer",
}

enum ForgerockTokenName {
    RefreshToken = "refresh_token",
    IdToken = "id_token",
}

enum OtpType {
    Registration = 0,
    Other = 1,
}

type ForgerockScopes = "openid" | "profile" | "write" | string;

interface TokenBase {
    sub: string;
    sid: string;
    iss: string;
    aud: string;
    realm: string;
    auth_time: number;
    exp: number;
    iat: number;
    acr?: string;
    auditTrackingId?: string;
    subname?: string;
}

interface ForgerockIdToken extends TokenBase {
    "tokenType": ForgerockTokenType.JWTToken;
    "tokenName": ForgerockTokenName.IdToken;
    "name": string;
    "given_name": string;
    "family_name": string;
    "email": string;
    "phone_number": string;
    "azp": string;
    "email_verified"?: boolean;
    "phone_number_verified"?: boolean;
    "roles"?: string[];
    "c_hash"?: string;
    "at_hash"?: string;
    "s_hash"?: string;
    "org.forgerock.openidconnect.ops"?: string;
}

interface ForgerockRefreshToken extends TokenBase {
    token_type: ForgerockTokenType.Bearer;
    tokenName: ForgerockTokenName.RefreshToken;
    cts: "OAUTH2_STATELESS_GRANT" | string;
    auth_level: number;
    authGrantId: string;
    grant_type: "authorization_code" | string;
    scope: ForgerockScopes[];
    expires_in: number;
    nbf: number;
    jti?: string;
    ops?: string;
}

const isErrorResponse = (value: unknown): value is ErrorResponse =>
    isDefinedUnknownObject<ErrorResponse>(value) && "error" in value && typeof value.error === "string";

const isLoginResponse = (value: unknown): value is LoginResponse =>
    isDefinedUnknownObject<LoginResponse>(value) &&
    "id_token" in value &&
    typeof value.id_token === "string" &&
    "refresh_token" in value &&
    typeof value.refresh_token === "string" &&
    "expires_in" in value &&
    (typeof value.expires_in === "string" || typeof value.expires_in === "number");

const isMessageResponse = (value: unknown): value is MessageResponse =>
    isDefinedUnknownObject<MessageResponse>(value) &&
    "messages" in value &&
    Array.isArray(value.messages) &&
    value.messages.every((message) => typeof message === "string" && AuthenticationErrorType[message] !== undefined) &&
    (!("authId" in value) || typeof value.authId === "string");

const isAuthIdResponse = (value: unknown): value is AuthIdResponse =>
    isDefinedUnknownObject<AuthIdResponse>(value) && "authId" in value && typeof value.authId === "string";

const isGuestDetailsResponse = (value: unknown): value is LXS.GuestDetails =>
    isDefinedUnknownObject<LXS.GuestDetails>(value) && "accountId" in value && typeof value.accountId === "string";

const loginResponseValidator = (value: unknown): value is LoginResponse | MessageResponse | ErrorResponse =>
    isLoginResponse(value) || isMessageResponse(value) || isErrorResponse(value);

type AnyOtpResponse = LoginResponse | AuthIdResponse | MessageResponse | ErrorResponse;
const otpResponseValidator = (value: unknown): value is AnyOtpResponse =>
    isLoginResponse(value) || isAuthIdResponse(value) || isMessageResponse(value) || isErrorResponse(value);

const registrationResponseValidator = (value: unknown): value is MessageResponse | ErrorResponse =>
    isMessageResponse(value) || isErrorResponse(value);

const resendOtpResponseValidator = (value: unknown): value is AuthIdResponse | MessageResponse | ErrorResponse =>
    isAuthIdResponse(value) || isMessageResponse(value) || isErrorResponse(value);

const resetResponseValidator = (value: unknown): value is MessageResponse | ErrorResponse =>
    isMessageResponse(value) || isErrorResponse(value);

const isSalesforceErrorResponse = (value: unknown): value is SalesforceErrorResponse =>
    isDefinedUnknownObject<SalesforceErrorResponse>(value) &&
    "errorCode" in value &&
    typeof value.errorCode === "string" &&
    (!("caseNumber" in value) || typeof value.caseNumber === "string");

const registerGuestResponseValidator = (
    value: unknown,
): value is LXS.GuestDetails | SalesforceErrorResponse | ErrorResponse =>
    isGuestDetailsResponse(value) || isSalesforceErrorResponse(value) || isErrorResponse(value);

const isValidIdTokenValue = (value: unknown): value is ForgerockIdToken =>
    isDefinedUnknownObject<ForgerockIdToken>(value) &&
    "tokenName" in value &&
    value.tokenName === ForgerockTokenName.IdToken &&
    "name" in value &&
    typeof value.name === "string" &&
    "given_name" in value &&
    typeof value.given_name === "string" &&
    "family_name" in value &&
    typeof value.family_name === "string" &&
    "email" in value &&
    typeof value.email === "string" &&
    "phone_number" in value &&
    typeof value.phone_number === "string" &&
    "exp" in value &&
    typeof value.exp === "number";

const isValidRefreshTokenValue = (value: unknown): value is ForgerockRefreshToken =>
    isDefinedUnknownObject<ForgerockRefreshToken>(value) &&
    "tokenName" in value &&
    value.tokenName === ForgerockTokenName.RefreshToken &&
    "exp" in value &&
    typeof value.exp === "number";

const JWT_PARTS_COUNT = 3;

/**
 * Contains functions required for guests authentication.
 * This class is designed to be used as singleton (@see IdentityProvider.current.<...>) unless you need to
 * have many instances with different @see IdentityProviderProps
 */
class IdentityProvider {
    private _props?: IdentityProviderProps;
    private _propsAwaitingQueue = new Set<(props: IdentityProviderProps) => void>();

    public static get current() {
        return currentIdentityProvider;
    }

    public init(props: IdentityProviderProps) {
        this._props = { ...props };

        const resolvers = Array.from(this._propsAwaitingQueue);
        for (const resolver of resolvers) {
            resolver(this._props);
        }
        this._propsAwaitingQueue.clear();
    }

    private async postRequestAsync<TBody extends Record<string, unknown>, TResponse>(
        url: string,
        validator: (value: TResponse) => value is TResponse,
        body: TBody,
        idToken?: string,
    ) {
        const response = await typedFetch(url, validator, {
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
            },
            method: "POST",
            body: JSON.stringify(body),
        });
        return response.json();
    }

    public async loginAsync(
        keepSignedIn: boolean,
        email: string,
        password: string,
        newPhoneNumber?: string,
        newPassword?: string,
    ): Promise<LXS.Guest> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/login`;

        const result = await this.postRequestAsync(url, loginResponseValidator, {
            username: email,
            password,
            newPhoneNumber,
            newPassword,
        });
        if (isLoginResponse(result)) {
            return this.getGuestFromLoginResponse(keepSignedIn, result);
        }

        if (isMessageResponse(result)) {
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    public async requestOtpAsync(authId: string): Promise<string> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/resend-otp`;

        const result = await this.postRequestAsync(url, resendOtpResponseValidator, { authId });

        if (isAuthIdResponse(result)) {
            return result.authId;
        }

        if (isMessageResponse(result)) {
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    /**
     * Returns AuthId or error.
     */
    public async resetPasswordAsync(email: string): Promise<string> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/reset-password`;

        const result = await this.postRequestAsync(url, resetResponseValidator, { username: email });

        if (isMessageResponse(result)) {
            if (
                result.messages.length === 1 &&
                result.messages[0] === AuthenticationErrorType.OtpCodeRequired &&
                result.authId
            ) {
                return result.authId;
            }
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    public async registerAsync(
        email: string,
        password: string,
        mobileNumber: string,
        firstName: string,
        lastName: string,
    ): Promise<void> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/register`;

        const result = await this.postRequestAsync(url, registrationResponseValidator, {
            email,
            password,
            mobileNumber,
            firstName,
            lastName,
        });

        if (isMessageResponse(result)) {
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    public async submitOtpAsync(
        keepSignedIn: boolean,
        authId: string,
        code: string,
        otpType: OtpType.Other,
    ): Promise<string>;
    public async submitOtpAsync(
        keepSignedIn: boolean,
        authId: string,
        code: string,
        otpType?: OtpType.Registration,
    ): Promise<LXS.Guest>;
    public async submitOtpAsync(
        keepSignedIn: boolean,
        authId: string,
        code: string,
        otpType: OtpType = OtpType.Registration,
    ): Promise<LXS.Guest | string> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/validate-otp`;

        const result = await this.postRequestAsync(url, otpResponseValidator, { authId, code, otpType });

        if (isLoginResponse(result)) {
            return this.getGuestFromLoginResponse(keepSignedIn, result);
        }

        if (isAuthIdResponse(result)) {
            return result.authId;
        }

        if (isMessageResponse(result)) {
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    public async setNewPasswordAsync(authId: string, password: string, keepSignedIn: boolean): Promise<LXS.Guest> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/submit-password`;

        const result = await this.postRequestAsync(url, loginResponseValidator, { authId, password });

        if (isLoginResponse(result)) {
            return this.getGuestFromLoginResponse(keepSignedIn, result);
        }

        if (isMessageResponse(result)) {
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    /**
     * Should be used only for migration flow.
     * This method only throws specific result and you need to handle
     * messages from @see AuthenticationError
     */
    public async saveNewPasswordAsync(email: string, password: string, mobileNumber?: string): Promise<void> {
        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/save-password`;

        const result = await this.postRequestAsync(url, registrationResponseValidator, {
            email,
            password,
            mobileNumber,
        });

        if (isMessageResponse(result)) {
            throw new AuthenticationError(new Set(result.messages), result.authId || undefined);
        }

        throw this.getGenericError(result);
    }

    public async getGuestFromTokenAsync(
        keepSignedIn: boolean,
        refreshToken?: string,
        idToken?: string,
    ): Promise<LXS.Guest | undefined> {
        const token = IdentityProvider.getInfoFromToken(idToken);
        if (token?.tokenName === ForgerockTokenName.IdToken && idToken) {
            if (!IdentityProvider.isTokenExpired(token)) {
                // Just using existing tokens
                try {
                    return new Guest(
                        token.email,
                        idToken,
                        refreshToken,
                        GuestAccountType.Default,
                        keepSignedIn,
                        this.updateGuestDetailsAsync,
                    );
                } catch {
                    return undefined;
                }
            }
        }

        if (!refreshToken) {
            return undefined;
        }

        const props = await this.getPropsAsync();
        const url = `${props.guestServiceUrl}/refresh-token`;

        const result = await this.postRequestAsync(url, loginResponseValidator, { refreshToken });

        if (isLoginResponse(result)) {
            return this.getGuestFromLoginResponse(keepSignedIn, result);
        }

        return undefined;
    }

    private getGuestFromLoginResponse(keepSignedIn: boolean, result: LoginResponse): LXS.Guest {
        const token = IdentityProvider.getInfoFromToken(result.id_token);
        const email = token?.tokenName === ForgerockTokenName.IdToken ? token.email : undefined;
        if (!email) {
            throw new Error("Invalid ID token");
        }
        return new Guest(
            email,
            result.id_token,
            result.refresh_token,
            GuestAccountType.Default,
            keepSignedIn,
            this.updateGuestDetailsAsync,
        );
    }

    private updateGuestDetailsAsync = async (
        idToken: string,
        guestDetailsToUpdate: Partial<LXS.GuestDetails>,
    ): Promise<LXS.GuestDetails> => {
        const props = await this.getPropsAsync();

        const result = await this.postRequestAsync(
            props.guestServiceUrl,
            registerGuestResponseValidator,
            guestDetailsToUpdate,
            idToken,
        );

        if (isGuestDetailsResponse(result)) {
            return result;
        }

        if (isSalesforceErrorResponse(result)) {
            throw new SalesforceError(result.errorCode, result.caseNumber);
        }

        throw this.getGenericError(result);
    };

    private getGenericError(result: unknown) {
        return new Error(isErrorResponse(result) ? result.error || result.error_description : undefined);
    }

    private getPropsAsync(): Promise<IdentityProviderProps> {
        return this._props
            ? Promise.resolve(this._props)
            : new Promise((resolve) => {
                  this._propsAwaitingQueue.add(resolve);
              });
    }

    public static getInfoFromToken(jwToken?: string) {
        const parts = jwToken?.split(".");
        if (parts?.length !== JWT_PARTS_COUNT || !parts[1].length) {
            return undefined;
        }
        const values = JSON.parse(base64url.decode(parts[1]));
        return isValidIdTokenValue(values) || isValidRefreshTokenValue(values) ? values : undefined;
    }

    public static isTokenExpired(token?: TokenBase) {
        if (!token?.exp) {
            return true;
        }
        const expireOn = dateFromTotalSeconds(token.exp);
        return (expireOn.getTime() - new Date().getTime()) / MILLISECONDS_PER_MINUTE < ALMOST_INVALID_TOKEN_MINUTES;
    }
}

const currentIdentityProvider = new IdentityProvider();

export { IdentityProvider, ForgerockTokenName, OtpType };

import { Box } from "../Box";
import { Stack } from "../Stack";

export const CardWithHeaderFooter: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box pb="l" px="s">
      <Stack direction="column" spacing="2xs" justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </Box>
  );
};

import { Text } from "@sitecore-jss/sitecore-jss-react";
import { TextProps } from "@sitecore-jss/sitecore-jss-react/types/components/Text";
import classnames from "classnames";
import * as React from "react";
import { Button } from "./Button";

const RadioButtonGroup = ({
    buttons,
    selectedButtonIndex,
    handleButtonClick,
    className = "",
}: LXS.Button.RadioButtonGroupProps) => {
    const formattedButtons: TextProps[] =
        buttons && buttons.length > 0 && typeof buttons[0] === "string"
            ? (buttons as string[]).map((button) => ({ field: { value: button } }))
            : (buttons as TextProps[]);

    return (
        <div className={classnames("lxs-radio-button-group", className)}>
            {formattedButtons &&
                formattedButtons.map((button, i) => (
                    <Button
                        key={i}
                        onClick={() => handleButtonClick(i)}
                        selected={i === selectedButtonIndex}
                        type="button"
                    >
                        <Text {...button} />
                    </Button>
                ))}
        </div>
    );
};

export { RadioButtonGroup };

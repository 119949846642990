import * as React from "react";
import { LinkButton } from "../../../buttons/LinkButton";
import { createBemFn } from "../../../utilities/bem";
import "./EmailNotFound.scss";

const bem = createBemFn("lxs-email-not-found");

interface EmailNotFoundProps {
    email: string;
    signInHref?: string;
    onSignInRequired?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const EmailNotFound: React.FC<EmailNotFoundProps> = ({ email, signInHref, onSignInRequired }) => {
    return (
        <div className={bem()}>
            <h2 className={bem("title", "no-senkei")}>Forgot Password</h2>
            <p className={bem("text")}>
                The email <strong>{email}</strong> doesn't exist.
            </p>
            {(signInHref || onSignInRequired) && (
                <LinkButton className={bem("button")} href={signInHref} onClick={onSignInRequired}>
                    Back to Sign In
                </LinkButton>
            )}
            <p className={bem("text")}>
                If you have any questions about your account, please contact the Lexus Customer Assistance Centre on
                1800 023 009 or email enquiries@lexusenquiries.com.au.
            </p>
        </div>
    );
};

export { EmailNotFound };

import cn from "classnames";
import { useContext } from "react";

import { iconRenderer } from "@/components/SVGIcon/static/SVGSelector";
import { OpeningHours } from "@/types/dealer";
import { DealerContext } from "@/utils/DealerContext";

import styles from "./DealerOpeningHours.module.scss";
import { openingHoursDisplayString } from "./DealerOpeningHours.utils";
import { DEALER_BRANCH_INFO_ICON_SIZE } from "../../shared";
import stylesDealerService from "../DealerService/DealerService.module.scss";

type DealerOpeningHoursTitleProps = {
  todayOpeningHours: OpeningHours | undefined;
  showOpeningHoursDetails: boolean;
  toggleOpeningHoursDetails: () => void;
};

const DealerOpeningHoursTitle: React.FC<DealerOpeningHoursTitleProps> = ({
  todayOpeningHours,
  toggleOpeningHoursDetails,
  showOpeningHoursDetails,
}) => {
  const { dealerConfig } = useContext(DealerContext);

  return todayOpeningHours === undefined ? null : (
    <div className={styles.dealerOpeningHoursItems}>
      <span className={stylesDealerService.dealerService}>
        {iconRenderer(dealerConfig?.openingHoursIcon?.value || "", {
          width: DEALER_BRANCH_INFO_ICON_SIZE,
          height: DEALER_BRANCH_INFO_ICON_SIZE,
          className: styles.dealerOpeningHoursIcon,
        })}
        <span className={stylesDealerService.dealerServiceLabel}>
          {dealerConfig?.openingHoursLabel?.value || "Opening Hours:"}
        </span>
        <span className={stylesDealerService.dealerServiceHours}>
          {openingHoursDisplayString(todayOpeningHours)}
        </span>
      </span>
      <span onClick={toggleOpeningHoursDetails} className={styles.dealerOpeningHoursArrow}>
        {iconRenderer("chevronDown", {
          width: DEALER_BRANCH_INFO_ICON_SIZE,
          height: DEALER_BRANCH_INFO_ICON_SIZE,
          className: cn(styles.dealerOpeningHoursIcon, styles.dealerOpeningHoursIconArrowDown, {
            [styles.dealerOpeningHoursIconArrowDownExpanded]: showOpeningHoursDetails,
          }),
        })}
      </span>
    </div>
  );
};

export { DealerOpeningHoursTitle };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGThreeSixty: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <circle cx="10" cy="8.1" r="2.5" stroke={color} strokeWidth="0.8" fill="none" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
            d="M8.82 2.71c-.58 0-1.11.33-1.38.86a.72.72 0 01-.64.4H5.63a2.29 2.29 0 00-2.3 2.28v3.64c-.78.3-1.4.67-1.81 1.08-.45.42-.68.9-.68 1.4 0 .5.25.97.67 1.39.43.42 1.05.8 1.8 1.12 1.47.62 3.48 1.04 5.72 1.14l-.6.6a.4.4 0 000 .58c.03.03.07.05.1.06a.58.58 0 00.18.03h.27L10.26 16a.4.4 0 000-.58L9 14.18a.4.4 0 00-.58 0 .4.4 0 000 .58l.43.42a16.1 16.1 0 01-5.19-1.02 5.48 5.48 0 01-1.52-.88c-.36-.31-.54-.62-.55-.9 0-.3.18-.61.54-.91.3-.25.7-.48 1.2-.71a2.29 2.29 0 002.29 2.15h8.75c1.19 0 2.17-.91 2.27-2.08.48.23.87.48 1.15.73.36.32.54.64.54.94 0 .28-.14.56-.43.84-.28.29-.7.56-1.23.8a14 14 0 01-4.25 1.07h-.02l-.02.01a.52.52 0 00-.3.47c0 .2.18.35.41.35h.01a14 14 0 004.8-1.29c1.15-.6 1.85-1.38 1.85-2.25 0-.5-.25-1-.7-1.44a5.5 5.5 0 00-1.8-1.1V6.25a2.29 2.29 0 00-2.28-2.29H13.2a.72.72 0 01-.64-.4c-.26-.52-.8-.85-1.38-.85H8.82zm-.64 1.22c.12-.24.37-.4.64-.4h2.36c.27 0 .52.16.64.4.26.53.8.86 1.38.86h1.18c.8 0 1.46.65 1.46 1.46v4.38c0 .8-.66 1.46-1.46 1.46H5.63c-.81 0-1.47-.66-1.47-1.46V6.25c0-.8.66-1.46 1.47-1.46H6.8c.59 0 1.12-.33 1.38-.86z"
        />
    </svg>
    /* eslint-enable max-len */
);
SVGThreeSixty = React.memo(SVGThreeSixty);

export { SVGThreeSixty };

import * as React from "react";
import classnames from "classnames";
import "./ModelsMenuMobileVehicleModels.scss";
import { VehicleCardMemoised } from "./ModelsMenuMobileVehicleCard";
import { useGTM } from "../../../GTM/useGTM";
import { createBemFn } from "../../../../utilities/bem";

export enum VehicleModelsDisplayType {
    CAROUSEL,
    BLOCK,
}

interface ModelsMenuMobileVehicleModelProps {
    vehicleModel: LXS.VehicleModel;
}

interface ModelsMenuMobileVehicleModelsProps {
    displayType: VehicleModelsDisplayType;
    vehicleModels: LXS.VehicleModel[];
    isActive: boolean;
}

const modelBem = createBemFn("lxs-models-menu-mobile-vehicle-container");
const bem = createBemFn("lxs-models-menu-mobile-vehicles");

const ModelsMenuMobileVehicleModel: React.FC<ModelsMenuMobileVehicleModelProps> = ({ vehicleModel }) => {
    const { gtmClick } = useGTM({ component: "ModelsMenuMobile" });

    return (
        <div className={modelBem()}>
            <a
                href={vehicleModel.path ? vehicleModel.path.value : undefined}
                className={modelBem("element")}
                onClick={gtmClick}
                data-gtm-target={"ModelCard"}
                data-gtm-action={vehicleModel.path && vehicleModel.path.value}
                data-gtm-model={vehicleModel.modelCode && vehicleModel.modelCode.value}
            >
                <VehicleCardMemoised {...vehicleModel} />
            </a>
        </div>
    );
};

const ModelsMenuMobileVehicleModels: React.FC<ModelsMenuMobileVehicleModelsProps> = ({
    vehicleModels,
    displayType,
    isActive,
}) => (
    <div
        className={classnames(
            bem(),
            { [bem(undefined, "hidden")]: isActive === false },
            { [bem(undefined, "carousel")]: displayType === VehicleModelsDisplayType.CAROUSEL },
            { [bem(undefined, "block")]: displayType === VehicleModelsDisplayType.BLOCK },
        )}
    >
        {vehicleModels.map((vm) => (
            <ModelsMenuMobileVehicleModel key={vm.modelCode.value} vehicleModel={vm} />
        ))}
    </div>
);

export { ModelsMenuMobileVehicleModels };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGArrowDown: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M16 24.2c-.1 0-.3 0-.4-.1L3.8 12.2c-.2-.2-.2-.5 0-.7s.5-.2.7 0L16 23l11.5-11.5c.2-.2.5-.2.7 0s.2.5 0 .7L16.4 24.1c-.1.1-.3.1-.4.1z" />
  </svg>

  /* eslint-enable max-len */
);
SVGArrowDown = memo(SVGArrowDown);

export { SVGArrowDown };

import * as React from "react";
import { lxColor } from "../../../../colors/variables";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import { useGTM } from "../../../GTM/useGTM";
import { SVGChevronRight } from "../../../SVGIcons/icons/static/SVGChevronRight";
import { hasLink, isGetInTouchItem, isMenuItemWithSubItems } from "../../shared";

const MainMenuMobileNavItem: React.FC<{ item: LXS.MenuItemLink; onClick: () => void }> = ({ item, onClick }) => {
    const { gtmClick } = useGTM();
    const linkValue = item.link && item.link.value;
    if (!hasLink(item) || !linkValue) {
        return null;
    }
    return isGetInTouchItem(item) || isMenuItemWithSubItems(item) ? (
        <div onClick={onClick}>
            <span className="lxs-main-menu-mobile-nav__item">{linkValue.text}</span>
            <SVGChevronRight height={16} width={16} color={lxColor("smoke")} />
        </div>
    ) : (
        <Link
            className="lxs-main-menu-mobile-nav__item lxs-main-menu-mobile-nav__item--link"
            field={item.link}
            onClick={gtmClick}
            data-gtm-target={linkValue && linkValue.text}
            data-gtm-action={linkValue && linkValue.href}
            data-gtm-linktype="PrimaryLink"
        />
    );
};

export { MainMenuMobileNavItem };

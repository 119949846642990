import classnames from "classnames";
import * as React from "react";
import "./index.scss";

const isNumber = (x: any) => typeof x === "number";

type ColProps = {
    baseWidth?: number;
    wideScreenWidth?: number;
    desktopWidth?: number;
    tabletWidth?: number;
    mobileWidth?: number;
    baseOffset?: number;
    wideScreenOffset?: number;
    desktopOffset?: number;
    tabletOffset?: number;
    children?: React.ReactNode;
    className?: string;
    alignCenter?: boolean;
    canOverflow?: boolean;
    stackOnTablet?: boolean;
} & React.Props<HTMLDivElement>;

export const Col = ({
    baseWidth,
    wideScreenWidth,
    desktopWidth,
    tabletWidth,
    mobileWidth,
    baseOffset,
    wideScreenOffset,
    desktopOffset,
    tabletOffset,
    alignCenter,
    canOverflow,
    stackOnTablet,
    children,
    className,
    ...rest
}: ColProps) => (
    <div
        {...rest}
        className={classnames(
            className,
            "lx-grid-col",
            isNumber(baseWidth) ? `lx-gs-base-col-${baseWidth}` : null,
            isNumber(wideScreenWidth) ? `lx-gs-wide-screen-col-${wideScreenWidth}` : null,
            isNumber(desktopWidth) ? `lx-gs-col-${desktopWidth}` : null,
            isNumber(tabletWidth) ? `lx-gs-tablet-col-${tabletWidth}` : null,
            isNumber(mobileWidth) ? `lx-gs-mobile-col-${mobileWidth}` : null,
            isNumber(baseOffset) ? `is-base-offset-${baseOffset}` : null,
            isNumber(wideScreenOffset) ? `is-wide-screen-offset-${wideScreenOffset}` : null,
            isNumber(desktopOffset) ? `is-offset-${desktopOffset}` : null,
            isNumber(tabletOffset) ? `is-tablet-offset-${tabletOffset}` : null,
            { "is-aligned-center": !!alignCenter },
            { "can-overflow": !!canOverflow },
            { "stack-on-tablet": !!stackOnTablet },
        )}
    >
        {children}
    </div>
);

export const wrapInCols = (props: ColProps) => (element: React.ReactNode, index: number) =>
    (
        <Col {...props} key={index}>
            {element}
        </Col>
    );

type RowProps = {
    children?: React.ReactNode;
    className?: string;
    isFluid?: boolean;
    isChildRow?: boolean;
    isPaddedTop?: boolean;
    isPaddedBottom?: boolean;
    isPhoneHidden?: boolean;
    isPhoneSix?: boolean;
} & React.Props<HTMLDivElement>;

export const Row = ({
    children,
    className,
    isFluid,
    isChildRow,
    isPaddedTop,
    isPaddedBottom,
    isPhoneHidden,
    isPhoneSix,
    ...rest
}: RowProps) => (
    <div
        {...rest}
        className={classnames(
            className,
            "lx-gs-row",
            { "is-fluid": !!isFluid },
            { "is-child-row": !!isChildRow },
            { "is-padded-top": !!isPaddedTop },
            { "is-padded-bottom": !!isPaddedBottom },
            { "is-phone-hidden": !!isPhoneHidden },
            { "is-phone-6": !!isPhoneSix },
        )}
    >
        {children}
    </div>
);

export const wrapInRows = (element: React.ReactNode, index: number) => <Row key={index}>{element}</Row>;

import Spinner from "Components/Spinner/Spinner";
import { defaultLoggedInRoute, isIFrameRequest, RouteSection, toastPath, toastUrlFromPath } from "Helpers/routes";
import { allAccountRoutes, defaultLoggedOutRoute } from "Pages/Account/accountScreens";
import Refresh from "Pages/Refresh/Refresh";
import SilentSignOut from "Pages/SignOut/SilentSignOut";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { AuthSection, authSectionSelector } from "ReduxSlices/user";

const AuthenticationGuard: React.FunctionComponent<RouteComponentProps> = ({ children, location }) => {
    const authSection = useSelector(authSectionSelector);
    const disableLoggedInRedirect = isIFrameRequest(location.search);

    const accountRoutes = (
        <Switch>
            <Route path={`/${RouteSection.Refresh}`} render={() => <Refresh />} />
            <Route path={`/${RouteSection.SilentSignOut}`} render={() => <SilentSignOut />} />
            <Route path={allAccountRoutes} render={() => children} />
            <Redirect to={defaultLoggedOutRoute} />
        </Switch>
    );
    const sfErrorRoute = (
        <Switch>
            <Route path={toastPath(RouteSection.GuestRegistrationError)} render={() => children} />
            <Redirect to={toastUrlFromPath(RouteSection.GuestRegistrationError, location.pathname)} />
        </Switch>
    );
    const appRoutes = (
        <Switch>
            <Route
                path={allAccountRoutes}
                render={() => (disableLoggedInRedirect ? null : <Redirect to={defaultLoggedInRoute} />)}
            />
            <Route render={() => children} />
        </Switch>
    );

    const bffErrorRoute = (
        <Switch>
            <Route path={toastPath(RouteSection.Error)} render={() => children} />
            <Redirect to={toastUrlFromPath(RouteSection.Error, location.pathname)} />
        </Switch>
    );

    const errorRoute = (
        <Switch>
            <Route path={toastPath(RouteSection.NonRecoverableError)} render={() => children} />
            <Redirect to={toastUrlFromPath(RouteSection.NonRecoverableError, location.pathname)} />
        </Switch>
    );

    switch (authSection) {
        case AuthSection.Verifying:
            return <Spinner />;
        case AuthSection.Account:
            return accountRoutes;
        case AuthSection.SalesforceError:
            return sfErrorRoute;
        case AuthSection.App:
            return appRoutes;
        case AuthSection.BFFError:
            return bffErrorRoute;
        case AuthSection.Error:
            return errorRoute;
        default:
            return accountRoutes;
    }
};

export default withRouter(AuthenticationGuard);

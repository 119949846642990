import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGPlugType2: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5312 4.84123C17.5379 5.10903 17.4904 5.37546 17.3914 5.6247C17.2925 5.87394 17.1441 6.10093 16.9552 6.29222C16.7662 6.4835 16.5405 6.63519 16.2913 6.73828C16.0422 6.84138 15.7748 6.89378 15.5048 6.89239C14.9695 6.88169 14.4597 6.66375 14.084 6.28509C13.7084 5.90643 13.4967 5.39704 13.4943 4.86565C13.4943 3.76192 14.4174 2.83597 15.5078 2.84574C15.7729 2.8442 16.0356 2.89466 16.281 2.99424C16.5263 3.09382 16.7494 3.24055 16.9375 3.42599C17.1255 3.61142 17.2747 3.83192 17.3766 4.0748C17.4785 4.31768 17.531 4.57814 17.5312 4.84123ZM15.5295 5.76111C15.408 5.769 15.2862 5.7525 15.1713 5.7126C15.0564 5.6727 14.9508 5.61021 14.8608 5.52887C14.7708 5.44754 14.6982 5.34903 14.6475 5.23923C14.5967 5.12943 14.5687 5.0106 14.5653 4.88983C14.5619 4.76907 14.583 4.64886 14.6274 4.53639C14.6719 4.42391 14.7387 4.32149 14.8239 4.23523C14.9091 4.14898 15.011 4.08067 15.1235 4.03438C15.2359 3.9881 15.3566 3.96479 15.4783 3.96585C16.0343 3.94925 16.4221 4.3038 16.4329 4.83808C16.4437 5.37236 16.0865 5.75329 15.5295 5.76111Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.05842 6.8758C6.60644 6.88264 7.71554 8.01078 7.70964 9.56185C7.69881 11.0924 6.55428 12.1932 4.98854 12.1795C3.46709 12.1649 2.35799 11.0406 2.36192 9.51692C2.36586 7.9932 3.4976 6.86897 5.05842 6.8758ZM5.04458 8.33507C5.74626 8.33507 6.25309 8.8293 6.25997 9.50716C6.26588 10.2124 5.7502 10.7183 5.02786 10.7154C4.28288 10.7154 3.82132 10.2397 3.83313 9.49056C3.84396 8.77851 4.31043 8.33214 5.04458 8.33507Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0457 6.8758C13.5937 6.88264 14.7028 8.01078 14.6969 9.56185C14.6861 11.0924 13.5416 12.1932 11.9758 12.1795C10.4544 12.1649 9.34529 11.0406 9.34923 9.51692C9.35317 7.9932 10.4849 6.86897 12.0457 6.8758ZM12.0319 8.33507C12.7336 8.33507 13.2404 8.8293 13.2473 9.50716C13.2532 10.2124 12.7375 10.7183 12.0152 10.7154C11.2702 10.7154 10.8086 10.2397 10.8204 9.49056C10.8313 8.77851 11.2977 8.33214 12.0319 8.33507Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6841 9.56185C21.69 8.01078 20.5809 6.88264 19.0329 6.8758C17.4721 6.86897 16.3403 7.9932 16.3364 9.51692C16.3325 11.0406 17.4416 12.1649 18.963 12.1795C20.5287 12.1932 21.6733 11.0924 21.6841 9.56185ZM20.2344 9.50716C20.2276 8.8293 19.7207 8.33507 19.019 8.33507C18.2849 8.33214 17.8184 8.77851 17.8076 9.49056C17.7958 10.2397 18.2573 10.7154 19.0023 10.7154C19.7247 10.7183 20.2403 10.2124 20.2344 9.50716Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2053 14.7532C18.1964 16.2652 17.0243 17.4061 15.4822 17.3953C14.7684 17.3883 14.0866 17.1002 13.5868 16.5942C13.087 16.0883 12.8102 15.4061 12.8172 14.6976C12.8243 13.9891 13.1146 13.3124 13.6244 12.8163C14.1341 12.3203 14.8215 12.0455 15.5354 12.0525C17.0519 12.0633 18.2141 13.2393 18.2053 14.7532ZM16.735 14.7064C16.7419 15.4273 16.238 15.942 15.5284 15.941C15.3648 15.945 15.2021 15.9163 15.0498 15.8567C14.8976 15.7971 14.7589 15.7078 14.642 15.5941C14.5252 15.4804 14.4324 15.3446 14.3694 15.1947C14.3063 15.0448 14.2741 14.8839 14.2748 14.7214C14.2754 14.559 14.3089 14.3984 14.3732 14.249C14.4375 14.0996 14.5313 13.9645 14.6491 13.8518C14.7669 13.739 14.9063 13.6508 15.059 13.5924C15.2117 13.5341 15.3747 13.5067 15.5383 13.5119C16.2291 13.5138 16.7291 14.013 16.735 14.7064Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.47148 17.3953C10.0136 17.4061 11.1857 16.2652 11.1945 14.7532C11.2034 13.2393 10.0412 12.0633 8.52462 12.0525C7.81077 12.0455 7.12336 12.3203 6.61361 12.8163C6.10386 13.3124 5.81353 13.9891 5.80648 14.6976C5.79943 15.4061 6.07625 16.0883 6.57603 16.5942C7.07582 17.1002 7.75763 17.3883 8.47148 17.3953ZM8.51769 15.941C9.22724 15.942 9.73112 15.4273 9.72423 14.7064C9.71832 14.013 9.21839 13.5138 8.52754 13.5119C8.36396 13.5067 8.20099 13.5341 8.04826 13.5924C7.89553 13.6508 7.75615 13.739 7.63835 13.8518C7.52056 13.9645 7.42674 14.0996 7.36244 14.249C7.29815 14.3984 7.26468 14.559 7.26402 14.7214C7.26337 14.8839 7.29553 15.0448 7.35861 15.1947C7.4217 15.3446 7.51442 15.4804 7.6313 15.5941C7.74818 15.7078 7.88684 15.7971 8.03909 15.8567C8.19134 15.9163 8.35408 15.945 8.51769 15.941Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3924 5.6247C10.4913 5.37546 10.5388 5.10903 10.5321 4.84123C10.532 4.57814 10.4795 4.31768 10.3776 4.0748C10.2757 3.83192 10.1265 3.61142 9.93844 3.42599C9.75041 3.24055 9.52731 3.09382 9.28196 2.99424C9.03661 2.89466 8.77384 2.8442 8.50878 2.84574C7.41837 2.83597 6.49526 3.76192 6.49526 4.86565C6.49772 5.39704 6.70936 5.90643 7.085 6.28509C7.46064 6.66375 7.97052 6.88169 8.50582 6.89239C8.77573 6.89378 9.04318 6.84138 9.2923 6.73828C9.54143 6.63519 9.76716 6.4835 9.95613 6.29222C10.1451 6.10093 10.2934 5.87394 10.3924 5.6247ZM8.17212 5.7126C8.28702 5.7525 8.40884 5.769 8.5303 5.76111C9.08731 5.75329 9.44455 5.37236 9.43372 4.83808C9.4229 4.3038 9.03515 3.94925 8.47913 3.96585C8.35741 3.96479 8.23672 3.9881 8.12429 4.03438C8.01185 4.08067 7.90997 4.14898 7.82474 4.23523C7.73952 4.32149 7.67269 4.42391 7.62825 4.53639C7.58382 4.64886 7.56269 4.76907 7.56613 4.88983C7.56958 5.0106 7.59752 5.12943 7.64829 5.23923C7.69906 5.34903 7.77162 5.44754 7.86162 5.52887C7.95163 5.61021 8.05723 5.6727 8.17212 5.7126Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.903 21.4928C12.903 21.4928 12.3579 21.5 12.0075 21.5C11.6571 21.5 11.1119 21.4928 11.1119 21.4928C7.83876 21.2604 5.12948 19.8861 2.96244 17.4687C1.36522 15.7103 0.362752 13.4997 0.0956965 11.1472C-0.310746 7.7354 0.575948 4.67135 2.7853 2.00289C3.13972 1.57967 3.52137 1.17968 3.92786 0.805399C4.0318 0.704803 4.15506 0.625984 4.29031 0.573631C4.42557 0.521277 4.57005 0.496458 4.71516 0.500652C9.58 0.508466 14.4445 0.508466 19.3087 0.500652C19.4465 0.495763 19.5839 0.518405 19.7127 0.567231C19.8415 0.616057 19.9591 0.690073 20.0586 0.784885C22.3427 2.88879 23.6556 5.47326 23.9626 8.55098L24 9.42517V9.79242L23.9616 10.6988C23.44 16.6892 18.4968 21.1627 12.903 21.4928ZM13.7829 1.47824C15.4379 1.48068 17.093 1.48312 18.7477 1.46577C18.9575 1.45759 19.1666 1.49656 19.3591 1.57977C19.5517 1.66299 19.7228 1.78829 19.8598 1.94633C22.074 4.27782 23.1467 7.0254 23.0089 10.2418C22.7954 15.2095 18.9278 19.5541 13.9225 20.4C7.66747 21.4607 1.85427 17.2255 1.06992 10.949C0.664465 7.70033 1.61906 4.81503 3.79692 2.32336C4.34508 1.69531 4.91587 1.42475 5.77009 1.45112C7.27397 1.50109 8.7799 1.49224 10.286 1.48339C10.8703 1.47995 11.4547 1.47652 12.0389 1.47652C12.6202 1.47652 13.2016 1.47738 13.7829 1.47824Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGPlugType2 = React.memo(SVGPlugType2);

export { SVGPlugType2 };

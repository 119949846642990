import { useCallback } from "react";

import { MessageWithType, useWindowMessage } from "@/hooks/useWindowMessage";

import { storeIdToken } from "../utils";

type SuccessLogin = MessageWithType<"login-success">;

export const useLoggedIn = (onLogin?: (idToken: string) => void) => {
  const handleLogin = useCallback(
    (message?: SuccessLogin) => {
      if (!message?.message) {
        return;
      }
      storeIdToken(message.message);
      if (onLogin) {
        onLogin(message.message);
      }
    },
    [onLogin]
  );

  useWindowMessage("login-success", handleLogin);
};

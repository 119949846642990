import "./HeroTile.scss";

import * as React from "react";

import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import { Orientation, useOrientation } from "../../utilities/CustomHooks/useOrientation";
import ToolTip, { ToolTipPosition } from "../ToolTip/ToolTip";
import { getSitecoreFieldValue, isExperienceEditorActive } from "../../utilities/Sitecore/SitecoreUtilities";

import { ButtonStyle } from "../../buttons/shared";
import { FocalpointPicture } from "../FocalpointPicture/FocalpointPicture";
import { LinkButton } from "../../buttons";
import classNames from "classnames";
import { toSitecoreText } from "../../utilities/Sitecore/SitecoreMappers";

const PLAY_THRESHOLD = 0.2;
const ANIMATE_THRESHOLD = 0.85;
const MAX_FAKE_IMAGE_HEIGHT = 128;
const LOAD_FAIL_DELAY = 3000;

interface HeroTileContentWrapperProps {
    showShadingGradient?: boolean;
}

interface VideoSource {
    src: string;
    width?: number;
    height?: number;
}

interface HeroTileProps {
    imageLandscapeProps?: LXS.FocalpointImageProps;
    imagePortraitProps?: LXS.FocalpointImageProps;
    videoLandscape?: string | VideoSource[];
    videoPortrait?: string | VideoSource[];
    buttonProps?: LXS.Button.LinkButtonProps;
    button2Props?: LXS.Button.LinkButtonProps;
    headerLines?: (SCJSS.TextProps | string)[];
    senkeiLines?: number[];
    indentedLines?: number[];
    secondaryText?: SCJSS.TextProps | string;
    toolTipText?: SCJSS.TextProps | string;
    showShadingGradient?: boolean;
    toolTipShadow?: boolean;
    onImageLoaded?: (e: React.SyntheticEvent<HTMLImageElement>) => void;
    videoClassName?: string;
    useHeaderImage?: boolean;
    headerImage?: SCJSS.ImageFieldValue;
    viewHeight?: number;
    sfLoaded?: boolean;
    onPersonalisedClickedCallback?: () => void;
}

const ensureTextProps = (p?: SCJSS.TextProps | string | number[]): SCJSS.TextProps =>
    (p instanceof Array
        ? toSitecoreText(p.join())
        : typeof p === "string"
        ? toSitecoreText(p)
        : p && (p as SCJSS.TextProps).field
        ? p
        : toSitecoreText("")) || toSitecoreText("");

const isNotEmptyTextField = (textProps: SCJSS.TextProps): boolean =>
    Boolean(
        textProps &&
            textProps.field &&
            (textProps.field.value || (textProps.field.editable && isExperienceEditorActive())),
    );

const getMatchingVideoSrc = (videos: VideoSource[]) => {
    const sortedVideos = [...videos].sort((v1, v2) => Number(v1.width || 0) - Number(v2.width || 0));
    const matchingVideo = sortedVideos.find(
        (video, index) =>
            (typeof window !== "undefined" && (video.width || 0) > window.innerWidth) ||
            index === sortedVideos.length - 1,
    );
    return matchingVideo && matchingVideo.src;
};

const imageToSource = (imageValue: LXS.FocalpointPictureImage, media: string): LXS.FocalpointPictureSource => {
    const imageValueCopy = {
        ...imageValue,
        "className": classNames(imageValue.class, imageValue.classNames),
        "srcSet": imageValue.srcSet || imageValue.src,
        "data-srcset": imageValue["data-srcset"] || imageValue["data-src"],
        "media": media,
    };
    delete (imageValueCopy as unknown as { class?: string }).class;

    return imageValueCopy;
};

const playVideo = (videoElement: HTMLVideoElement) => {
    const promise = videoElement.play();
    promise && typeof promise.catch === "function" && promise.catch();
};

const HeroTile: React.FC<HeroTileProps> = ({
    buttonProps,
    button2Props,
    showShadingGradient,
    toolTipShadow = true,
    useHeaderImage = false,
    viewHeight,
    sfLoaded,
    onPersonalisedClickedCallback,
    ...props
}) => {
    const videoComponent = React.useRef<HTMLVideoElement>(null);
    const heroComponent = React.useRef<HTMLDivElement>(null);
    const videoCanPlay = React.useRef(false);
    const [canAnimate, setCanAnimate] = React.useState(false);
    const linesProps = (props.headerLines || []).map(ensureTextProps);
    const secondaryTextProps = ensureTextProps(props.secondaryText);
    const toolTipTextProps = ensureTextProps(props.toolTipText);
    const orientation = useOrientation();
    const imageLandscapeValue =
        props.imageLandscapeProps &&
        getSitecoreFieldValue<LXS.FocalpointImageProps, LXS.FocalpointPictureImage>(props.imageLandscapeProps);
    const imagePortraitValue =
        props.imagePortraitProps &&
        getSitecoreFieldValue<LXS.FocalpointImageProps, LXS.FocalpointPictureImage>(props.imagePortraitProps);
    const [imageLoaded, setImageLoaded] = React.useState(!imageLandscapeValue);

    const imageSources = React.useMemo(() => {
        const sources =
            (imagePortraitValue &&
                imagePortraitValue.src && [imageToSource(imagePortraitValue, "all and (orientation:portrait)")]) ||
            [];

        imageLandscapeValue && imageLandscapeValue.src && sources.push(imageToSource(imageLandscapeValue, "all"));
        return sources;
    }, [imagePortraitValue, imageLandscapeValue]);

    const video =
        orientation === Orientation.LANDSCAPE
            ? props.videoLandscape
            : props.videoPortrait || (imagePortraitValue && imagePortraitValue.src)
            ? props.videoPortrait
            : props.videoLandscape;

    const videoSrc = video instanceof Array ? getMatchingVideoSrc(video) : video;

    const observer = React.useMemo(
        () =>
            (typeof IntersectionObserver !== "undefined" &&
                new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (!canAnimate && entry.isIntersecting && entry.intersectionRatio >= ANIMATE_THRESHOLD) {
                                setCanAnimate(true);
                            }

                            const videoElement = videoComponent.current;
                            if (
                                !videoElement ||
                                typeof videoElement.pause !== "function" ||
                                typeof videoElement.play !== "function"
                            ) {
                                return;
                            }
                            if (entry.isIntersecting && entry.intersectionRatio >= PLAY_THRESHOLD) {
                                videoCanPlay.current = true;
                                if (videoElement.paused && videoElement.src) {
                                    playVideo(videoElement);
                                }
                            } else {
                                videoCanPlay.current = false;
                                !videoElement.paused && videoElement.pause();
                            }
                        });
                    },
                    {
                        rootMargin: "0px",
                        threshold: [PLAY_THRESHOLD, ANIMATE_THRESHOLD],
                    },
                )) ||
            undefined,
        [videoCanPlay],
    );

    React.useEffect(() => {
        const heroElement = heroComponent.current;
        if (!heroElement || !observer) {
            !observer && setCanAnimate(true);
            return;
        }

        observer.observe(heroElement);

        //Sometimes image loads faster than React event attaches, so we need to set the state artificially
        const failSafeTimeout = !imageLoaded ? setTimeout(() => setImageLoaded(true), LOAD_FAIL_DELAY) : undefined;

        return () => {
            heroElement && observer.unobserve(heroElement);
            failSafeTimeout && clearTimeout(failSafeTimeout);
        };
    }, [heroComponent]);

    React.useEffect(() => {
        if (!videoComponent.current || !observer) {
            return;
        }

        if (!videoComponent.current.src) {
            videoComponent.current.load();
        } else if (videoCanPlay.current) {
            playVideo(videoComponent.current);
        }
    }, [videoSrc]);

    const getHeaderLineClass = (i: number): string | undefined =>
        classNames("lxs-herotile__header-line", {
            "lxs-herotile__header-line--indented": props.indentedLines && props.indentedLines.includes(i),
        });

    const handleImageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (e.currentTarget.naturalHeight > MAX_FAKE_IMAGE_HEIGHT && sfLoaded) {
            //Wait for a real image
            setImageLoaded(true);
        }

        props.onImageLoaded?.(e);
    };

    return (
        <div
            className={classNames("lxs-herotile", { "lxs-herotile--loaded": imageLoaded && canAnimate })}
            style={viewHeight ? { height: `${viewHeight}vh` } : {}}
            ref={heroComponent}
        >
            {imageLandscapeValue && (
                <FocalpointPicture
                    defaultImage={imageLandscapeValue}
                    sources={imageSources}
                    cover={imageLandscapeValue && imageLandscapeValue.cover}
                    onImageLoaded={handleImageLoaded}
                    className={classNames(
                        props.imageLandscapeProps && props.imageLandscapeProps.className,
                        "lxs-herotile__image",
                        { "lxs-herotile__image--loaded": imageLoaded },
                    )}
                />
            )}
            <video
                className={classNames("lxs-herotile__video", props.videoClassName)}
                muted
                loop
                suppressHydrationWarning={true}
                preload="none"
                data-preload="metadata"
                playsInline
                ref={videoComponent}
                src={videoSrc}
            />
            <div className="lxs-herotile__bottom-section">
                <ContentWrapper showShadingGradient={showShadingGradient}>
                    {useHeaderImage ? (
                        <Image className="lxs-herotile__header-image" field={props.headerImage} />
                    ) : (
                        <h1 className="lxs-herotile__header lxs-herotile__header--no-senkei">
                            {linesProps.map((lProps, i: number) => (
                                <span className={getHeaderLineClass(i)} key={i}>
                                    {props.senkeiLines && props.senkeiLines.includes(i) && (
                                        <span className="lxs-herotile__header-line-senkei" />
                                    )}
                                    <Text {...lProps} />
                                </span>
                            ))}
                        </h1>
                    )}
                    <SecondaryTextBlock {...secondaryTextProps} />
                    <div className="lxs-herotile__button-wrapper">
                        <ButtonBlock
                            {...buttonProps}
                            buttonStyle={ButtonStyle.Primary}
                            onClick={onPersonalisedClickedCallback}
                        />
                        <ButtonBlock {...button2Props} buttonStyle={ButtonStyle.Secondary} />
                        <div className="lxs-herotile__button-placeholder" />
                    </div>
                </ContentWrapper>
                {isNotEmptyTextField(toolTipTextProps) && (
                    <div className="lxs-grid-row lxs-herotile__tooltip-row">
                        <div className="lxs-grid--with-margin lxs-herotile__tooltip-container">
                            <ToolTip
                                className="lxs-herotile__tooltip"
                                position={ToolTipPosition.TOP_LEFT}
                                addShadow={toolTipShadow}
                            >
                                <p className="lxs-herotile__tooltip-paragraph">
                                    <Text {...toolTipTextProps} encode={false} />
                                </p>
                            </ToolTip>
                        </div>
                    </div>
                )}
                {props.children}
            </div>
        </div>
    );
};

const ContentWrapper: React.FC<HeroTileContentWrapperProps> = ({ children, showShadingGradient }) => {
    const className = classNames("lxs-herotile__content-wrapper", {
        "lxs-herotile__content-wrapper--has-shading-gradient": showShadingGradient,
    });

    return (
        <div className={className}>
            <div className="lxs-grid-row">
                <div className="lxs-grid lxs-grid--with-margin">
                    <div className="lxs-herotile__content-block">{children}</div>
                </div>
            </div>
        </div>
    );
};

const SecondaryTextBlock = (secondaryTextProps: SCJSS.TextProps) =>
    (isNotEmptyTextField(secondaryTextProps) && (
        <div className="lxs-herotile__secondary-text">
            <Text {...secondaryTextProps} />
        </div>
    )) ||
    null;

const ButtonBlock = (buttonProps: LXS.Button.LinkButtonProps) => {
    if (!buttonProps || !buttonProps.field) {
        return null;
    }
    const fld = buttonProps.field;
    const fldValue = getSitecoreFieldValue<LXS.Button.LinkButtonProps, SCJSS.LinkFieldValue>(buttonProps);

    return (
        fldValue &&
        (fldValue.text ||
            (fldValue.href && buttonProps.children) ||
            (isExperienceEditorActive() && fld.editableFirstPart)) && (
            <LinkButton
                className={classNames(
                    "lxs-herotile__button",
                    { "lxs-herotile__button--primary": buttonProps.buttonStyle === ButtonStyle.Primary },
                    { "lxs-herotile__button--secondary": buttonProps.buttonStyle === ButtonStyle.Secondary },
                )}
                data-gtm-target={fldValue.text}
                data-gtm-linktype="HeroTileCTA"
                {...buttonProps}
            >
                {fldValue.text ? fldValue.text : buttonProps.children}
            </LinkButton>
        )
    );
};

export { HeroTile };

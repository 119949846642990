import { Text } from "@sitecore-jss/sitecore-jss-react";
import classnames from "classnames";
import * as React from "react";
import { Theme } from "../shared/Theme";
import "./SVGIconButton.scss";
import { useGTM } from "../GTM/useGTM";

export enum SVGIconButtonIconPositions {
    LEFT = "lxs-svg-icon-button--icon-left",
    RIGHT = "lxs-svg-icon-button--icon-right",
}

type SVGIconButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
    title?: string;
    iconPosition?: SVGIconButtonIconPositions;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void | undefined;
    stackOnMobile?: boolean;
    className?: string;
    theme?: Theme;
};

const SVGIconButton = ({
    title,
    iconPosition = SVGIconButtonIconPositions.LEFT,
    onClick,
    stackOnMobile = false,
    className = "",
    theme = Theme.LIGHT,
    children,
    ...rest
}: SVGIconButtonProps) => {
    const buttonRef = React.createRef<HTMLButtonElement>();
    const classes = classnames("lxs-svg-icon-button", className, {
        [`${iconPosition}`]: true,
        "lxs-svg-icon-button--stack-on-mobile": stackOnMobile,
        "lxs-svg-icon-button--theme-dark": theme === Theme.DARK,
        "lxs-svg-icon-button--theme-light": theme === Theme.LIGHT,
    });
    const { gtmClickProxy } = useGTM();

    return (
        <button
            ref={buttonRef}
            className={classes}
            title={title}
            onClick={gtmClickProxy(onClick)}
            data-gtm-action={title}
            {...rest}
        >
            {title && (
                <span className="lxs-svg-icon-button__title">
                    <Text field={{ value: title }} />
                </span>
            )}
            {children}
        </button>
    );
};

export { SVGIconButton };

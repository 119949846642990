import React, { useCallback, useState } from "react";
import { Button } from "../../../buttons/Button";
import { Spinner } from "../../Spinner/Spinner";
import { createBemFn } from "../../../utilities/bem";
import { PasswordRequired } from "./PasswordRequired";
import "./SetPasswordForm.scss";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { GenericError } from "./GenericError";

const bem = createBemFn("lxs-set-password-form");

interface SetPasswordFormProps {
    onSubmit: (password: string) => Promise<void>;
}

const SetPasswordForm: React.FC<SetPasswordFormProps> = ({ onSubmit }) => {
    const [password, setPassword] = useState<string>();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<Error>();
    const [blockedPasswords, setBlockedPasswords] = useState<string[]>([]);
    const submitDisabled = !password || processing;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (password) {
            setProcessing(true);
            onSubmit(password).catch((err) => {
                setProcessing(false);
                if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.NewPasswordInvalid)) {
                    setBlockedPasswords((old) => [...old, password]);
                } else {
                    setError(err);
                }
            });
        }
    };

    const handleToastClose = useCallback(() => {
        setError(undefined);
    }, []);

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>Reset Password</h2>
            <p className={bem("header-text")}>Please enter a new password:</p>
            <PasswordRequired setPassword={setPassword} blockedPasswords={blockedPasswords} />
            {error && <GenericError isOpen={!!error} error={error} onClose={handleToastClose} />}
            <Button className={bem("button")} type="submit" disabled={submitDisabled}>
                Continue
            </Button>
            {processing && <Spinner />}
        </form>
    );
};

export { SetPasswordForm };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGSubtract: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0 14L32 14L32 18L-1.74846e-07 18L0 14Z" />
  </svg>
);
SVGSubtract = memo(SVGSubtract);

export { SVGSubtract };

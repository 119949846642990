import * as React from "react";
import Modal from "react-modal/lib/components/Modal";
import "./MainMenu.scss";
import { MainMenuDesktop } from "./desktop/MainMenuDesktop";
import { MainMenuMobile } from "./mobile/MainMenuMobile";
import { PrimaryNavContext } from "../PrimaryNav";

const MainMenu: React.FC<LXS.MenuProps> = ({ isOpen }) => {
    const { handleMenuClose, handleModalAfterOpen } = React.useContext(PrimaryNavContext);
    if (typeof document !== "undefined") {
        const element = (document.getElementsByTagName("main")[0] || document.getElementById("root")) as HTMLElement;
        Modal.setAppElement(element);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleMenuClose}
            onAfterOpen={handleModalAfterOpen}
            className="lxs-main-menu-content"
            overlayClassName={{
                base: "lxs-main-menu-overlay",
                afterOpen: "lxs-menu-overlay--after-open",
                beforeClose: "lxs-menu-overlay--before-close",
            }}
            closeTimeoutMS={500}
        >
            <MainMenuMobile />
            <MainMenuDesktop />
        </Modal>
    );
};

export { MainMenu };

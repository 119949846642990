import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGSteeringWheel: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      d="M24.945 30.483a12.519 12.519 0 0 0 5.154-6.51l-5.42.677c-.135.33-.275.652-.42.959-.47 1.002-1 1.911-1.532 2.587a5.67 5.67 0 0 1-.597.656v1.63h-1.92v-.96h-3.841v.96h-1.92v-1.63a5.67 5.67 0 0 1-.598-.656c-.532-.676-1.063-1.585-1.532-2.587a20.304 20.304 0 0 1-.42-.959l-5.42-.677a12.519 12.519 0 0 0 5.155 6.51H8.497a14.364 14.364 0 0 1-4.61-10.563c0-7.954 6.448-14.402 14.402-14.402 7.954 0 14.403 6.448 14.403 14.402 0 4.173-1.775 7.932-4.61 10.563h-3.137Zm-13.749-7.856c-.327-1.132-.546-2.29-.583-3.355l-4.786 1.367c.026.452.075.898.148 1.335l5.221.653Zm-.313-5.43c.132-.374.31-.715.534-1.023.535-.733 1.27-1.19 2.046-1.475 1.465-.54 3.288-.54 4.734-.54h.184c1.446 0 3.269 0 4.735.54.776.286 1.51.742 2.045 1.475.224.308.402.649.534 1.023l5.01 1.432C30.06 12.342 24.748 7.438 18.29 7.438S6.52 12.342 5.873 18.628l5.01-1.43Zm15.082 2.075c-.037 1.064-.256 2.223-.583 3.355l5.221-.653c.073-.437.123-.883.148-1.335l-4.786-1.367Zm-13.437-.312c0-.801.19-1.311.44-1.654.254-.348.633-.611 1.159-.806 1.109-.408 2.6-.42 4.162-.42 1.563 0 3.053.012 4.162.42.527.195.905.458 1.159.806.25.343.44.853.44 1.654 0 1.726-.661 3.982-1.53 5.834-.43.918-.889 1.69-1.302 2.214a3.1 3.1 0 0 1-.511.535.767.767 0 0 1-.087.059h-4.662a.767.767 0 0 1-.087-.059 3.1 3.1 0 0 1-.51-.535c-.414-.525-.873-1.296-1.304-2.214-.868-1.852-1.53-4.108-1.53-5.834Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M20.353 21.333c-.028.013-.08.016-.08.016h-2.224s-.369-.02 0-.446l1.077-1.542s.043-.04.074-.094c.042-.072.06-.166-.081-.215a3.693 3.693 0 0 0-.831-.092c-1.558 0-2.554.862-2.56 1.87-.004.967.87 1.825 2.56 1.823.889 0 1.607-.276 2.056-.707h-.44c-.386.307-.944.497-1.616.497-1.449 0-2.195-.72-2.195-1.613 0-.891.861-1.671 2.195-1.672.058-.001.112.002.166.004.036.023.037.087-.12.26 0 0-.315.382-.734.955-.427.587-.479.715-.479.715s-.458.68.629.716h2.724c.24-.283.376-.617.376-.978 0-.732-.524-1.383-1.413-1.687l-.098.166c.804.27 1.174.865 1.174 1.52 0 .159-.02.286-.101.414 0 0-.03.077-.06.09Z"
      clipRule="evenodd"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGSteeringWheel = memo(SVGSteeringWheel);

export { SVGSteeringWheel };

import cn from "classnames";

import styles from "./TopBanner.module.scss";
import { Box } from "../Box";
import { Typography } from "../Typography/Typography";

export type TopBannerProps = React.HTMLAttributes<HTMLDivElement>;

const TopBanner: React.FC<TopBannerProps> = ({ children, className, ...rest }) => (
  <Box p="even-less" className={cn(styles.topBanner, className)} {...rest}>
    <Typography variant="b1">{children}</Typography>
  </Box>
);

export { TopBanner };

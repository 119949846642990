import { AddVehicleFlowState, addVehicleFlowReducer, initialAddVehicleFlowState } from "./addVehicleFlow";
import { GeneralState, generalReducer, initialGeneralState } from "./general";
import { PaymentsState, initialPaymentsState, paymentsReducer } from "./payments";
import { RewardState, initialRewardState, rewardReducer } from "./reward";
import { ValetState, initialValetState, valetReducer } from "./valet";
import { VehicleState, initialVehicleState, vehicleReducer } from "./vehicle";
import { initialOnDemandState, onDemandReducer } from "./onDemand/onDemandReducers";
import { initialUserState, userReducer } from "./user";

import { OnDemand } from "./onDemand/onDemandInterfaces";
import { User } from "./userInterfaces";
import { combineReducers } from "redux";

export interface AppState {
    user: User;
    valet: ValetState;
    addVehicleFlow: AddVehicleFlowState;
    reward: RewardState;
    apiData: unknown;
    onDemand: OnDemand;
    general: GeneralState;
    vehicle: VehicleState;
    payments: PaymentsState;
}

export const initialState: AppState = {
    user: initialUserState,
    valet: initialValetState,
    addVehicleFlow: initialAddVehicleFlowState,
    reward: initialRewardState,
    apiData: {},
    onDemand: initialOnDemandState,
    general: initialGeneralState,
    vehicle: initialVehicleState,
    payments: initialPaymentsState,
};

export const rootReducer = combineReducers({
    user: userReducer,
    valet: valetReducer,
    addVehicleFlow: addVehicleFlowReducer,
    reward: rewardReducer,
    apiData: state => {
        return state || null;
    },
    onDemand: onDemandReducer,
    general: generalReducer,
    vehicle: vehicleReducer,
    payments: paymentsReducer,
});

import { mergeRefs } from "@react-aria/utils";
import { ForwardedRef, forwardRef, useRef, useState } from "react";
import {
  TextFieldProps as TextFieldPrimitiveProps,
  TextField as TextFieldPrimitive,
} from "react-aria-components";

import { FormHelperText } from "@/components/FormHelperText/FormHelperText";
import { InputLabel } from "@/components/InputLabel/InputLabel";
import { TextInput } from "@/components/TextInput/TextInput";

import styles from "./TextField.module.scss";

export interface TextFieldProps extends TextFieldPrimitiveProps {
  /**
   * Whether the value is invalid.
   */
  isInvalid?: TextFieldPrimitiveProps["isInvalid"];
  /**
   * Whether the input is disabled.
   */
  isDisabled?: TextFieldPrimitiveProps["isDisabled"];
  /**
   * Whether user input is required on the input before form submission.
   */
  isRequired?: TextFieldPrimitiveProps["isRequired"];
  /**
   * Whether the input can be selected but not changed by the user.
   */
  isReadOnly?: TextFieldPrimitiveProps["isReadOnly"];
  /**
   * Whether the element should receive focus on render.
   */
  autoFocus?: TextFieldPrimitiveProps["autoFocus"];
  /**
   * The current value (controlled).
   */
  value?: TextFieldPrimitiveProps["value"];
  /**
   * The default value (uncontrolled).
   */
  defaultValue?: TextFieldPrimitiveProps["defaultValue"];
  /**
   * Describes the type of autocomplete functionality the input should provide if any. See MDN.
   */
  autoComplete?: TextFieldPrimitiveProps["autoComplete"];
  /**
   * The maximum number of characters supported by the input. See MDN.
   */
  maxLength?: TextFieldPrimitiveProps["maxLength"];
  /**
   * The minimum number of characters required by the input. See MDN.
   */
  minLength?: TextFieldPrimitiveProps["minLength"];
  /**
   * Regex pattern that the value of the input must match to be valid. See MDN.
   */
  pattern?: TextFieldPrimitiveProps["pattern"];
  /**
   * The type of input to render. See MDN.
   */
  type?: TextFieldPrimitiveProps["type"];
  /**
   * Hints at the type of data that might be entered by the user while editing the element or its contents. See MDN.
   */
  inputMode?: TextFieldPrimitiveProps["inputMode"];
  /**
   * The name of the input element, used when submitting an HTML form. See MDN.
   */
  name?: TextFieldPrimitiveProps["name"];
  /**
   * The children of the component. A function may be provided to alter the children based on component state.
   */
  children?: TextFieldPrimitiveProps["children"];
  /**
   * The content to display as the label.
   */
  label?: React.ReactNode;
  /**
   * A description for the field. Provides a hint such as specific requirements for what to choose.
   */
  description?: React.ReactNode;
  /**
   * An error message for the field.
   */
  errorMessage?: React.ReactNode;
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * Handler that is called when the value changes.
   */
  onChange?: TextFieldPrimitiveProps["onChange"];
  /**
   * `ref object` to reference input element.
   * */
  inputRef?: ForwardedRef<HTMLInputElement>;
  /**
   * Whether clear icon is visible
   * */
  isClearIconVisible?: boolean;
}

/**
 * A text field allows a user to enter a plain text value with a keyboard. It must be wrapped in a `<FormContainer />` component.
 *
 * ## State
 * ### Uncontrolled
 * A TextField's `value` is empty by default, but an initial, uncontrolled, value can be provided using the `defaultValue` prop.
 * ```
 * <TextField label="Greeting" defaultValue="Hello" />
 * ```
 *
 * ### Controlled
 * The `value` prop can be used to make the `value` controlled. The `onChange` event is fired when the user edits the text, and receives the new `value`.
 * ```
 * const [value, setValue] = React.useState("")
 * <TextField label="Greeting" value={value} onChange={setValue} />
 * ```
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      description,
      errorMessage,
      placeholder,
      isInvalid,
      onChange,
      isClearIconVisible = false,
      ...props
    },
    ref
  ) => {
    let { inputRef } = props;
    const defaultRef = useRef(null);
    inputRef = inputRef ?? defaultRef;
    const mergedRef = mergeRefs(ref, inputRef, useRef(null));

    const [isInputPopulated, setIsInputPopulated] = useState(false);
    const hasDescription = Boolean(description);
    const hasErrorMessage = Boolean(errorMessage);
    const showErrorMessage = isInvalid && hasErrorMessage;
    const showDescription = hasDescription && !showErrorMessage;
    const clearable = isInputPopulated && isClearIconVisible;

    const handleChange = (value: string) => {
      if (onChange) {
        onChange(value);
      }

      const inputValue =
        inputRef && "current" in inputRef && inputRef.current ? inputRef.current.value : "";
      setIsInputPopulated(inputValue.length > 0);
    };

    const handleClearInput = () => {
      if (!inputRef || !("current" in inputRef) || !inputRef.current) {
        return;
      }

      inputRef.current.value = "";
      handleChange("");
    };

    return (
      <TextFieldPrimitive
        className={styles.textField}
        isInvalid={isInvalid}
        onChange={handleChange}
        {...props}
      >
        {label && <InputLabel>{label}</InputLabel>}

        <TextInput
          onClearInput={handleClearInput}
          placeholder={placeholder}
          isInvalid={isInvalid}
          ref={mergedRef}
          clearable={clearable}
        />

        {showDescription && <FormHelperText>{description}</FormHelperText>}
        {showErrorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </TextFieldPrimitive>
    );
  }
);

TextField.displayName = "TextField";

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGMapOrigin: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <circle cx="16" cy="16" r="12" stroke={color} strokeWidth="8" />
    </svg>
    /* eslint-enable max-len */
);
SVGMapOrigin = React.memo(SVGMapOrigin);

export { SVGMapOrigin };

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGCross: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1716 15.9999L3.27209 25.8994L6.10052 28.7278L16 18.8283L25.8995 28.7278L28.7279 25.8994L18.8284 15.9999L28.7279 6.1004L25.8995 3.27197L16 13.1715L6.10052 3.27197L3.27209 6.1004L13.1716 15.9999Z"
    />
  </svg>
);
SVGCross = memo(SVGCross);

export { SVGCross };

import { MarkerClusterer } from "@googlemaps/markerclusterer";

class GoogleWrappers {
    public get TravelMode() {
        return google.maps.TravelMode;
    }

    public get areMapsLoaded() {
        return typeof google === "object" && typeof google.maps === "object";
    }

    public get MapLatLng() {
        return google.maps.LatLng;
    }

    public get MapsPoint() {
        return google.maps.Point;
    }

    public get MapsSize() {
        return google.maps.Size;
    }

    public get MapsLatLngBounds() {
        return google.maps.LatLngBounds;
    }

    public get MapsInfoWindow() {
        return google.maps.InfoWindow;
    }

    public get MarkerClusterer() {
        return MarkerClusterer;
    }

    public readonly NO_GMAPS_ERROR_MESSAGE = "Google Maps scripts are not loaded yet!";

    public get ComputeDistanceBetween() {
        return google.maps.geometry.spherical.computeDistanceBetween;
    }

    public get geoCodeService() {
        if (!this.areMapsLoaded) {
            throw new Error(this.NO_GMAPS_ERROR_MESSAGE);
        }
        return new google.maps.Geocoder();
    }

    public get directionsService() {
        if (!this.areMapsLoaded) {
            throw new Error(this.NO_GMAPS_ERROR_MESSAGE);
        }
        return new google.maps.DirectionsService();
    }
}

const googleWrappers = new GoogleWrappers();

export default googleWrappers;

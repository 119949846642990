import { useEffect, useState } from "react";

import { CurrentUser } from "@/types/auth";

import { useRefreshingToken } from "./useRefreshingToken";
import { AuthenticationConfig } from "../authentication.types";

export const useRefreshTokenRequired = (
  user?: CurrentUser,
  config?: AuthenticationConfig
): boolean => {
  const isRefreshTokenRequiredInitial = config?.isAuthenticationHost !== true;
  const [isRefreshTokenRequired, setIsRefreshTokenRequired] = useState(
    isRefreshTokenRequiredInitial
  );
  const isRefreshingToken = useRefreshingToken(config?.autoLogout);

  useEffect(() => {
    const isAuthenticated = !!user;

    setIsRefreshTokenRequired(
      !config?.isAuthenticationHost && (isRefreshingToken || !isAuthenticated)
    );
  }, [user, isRefreshingToken, config?.isAuthenticationHost]);

  return isRefreshTokenRequired;
};

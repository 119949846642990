import { CalendarDate } from "@internationalized/date";
import cn from "classnames";
import { useContext, useMemo } from "react";
import {
  DatePickerProps as DatePickerPrimitiveProps,
  DateValue,
  I18nProvider,
} from "react-aria-components";
import {
  DatePicker as DatePickerPrimitive,
  Group,
  DateInput,
  Button,
  Popover,
  Dialog,
  Calendar,
  DateSegment,
  Heading,
  CalendarGrid,
  CalendarCell,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarGridBody,
} from "react-aria-components";

import { ThemeContext } from "@/theming/ThemeContext";

import styles from "./DatePicker.module.scss";
import { Box } from "../Box";
import { FormHelperText } from "../FormHelperText/FormHelperText";
import { GlobalStylesScope } from "../GlobalStylesScope/GlobalStylesScope";
import { InputLabel } from "../InputLabel/InputLabel";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Stack from "../Stack/Stack";
import { SVGCalendar } from "../SVGIcon/static/SVGCalendar";
import { SVGChevronLeft } from "../SVGIcon/static/SVGChevronLeft";
import { SVGChevronRight } from "../SVGIcon/static/SVGChevronRight";
import { Typography } from "../Typography/Typography";

export interface DatePickerProps<T extends DateValue> extends DatePickerPrimitiveProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isCalendarDisabled?: boolean;
  isLoading?: boolean;
  onCalendarFocusChange?: (date: CalendarDate) => void;
  /* Display date for for focused month */
  onlyShowFocusedMonthDates?: boolean;
  locale?: string;
}

const isCurrentDay = (date: CalendarDate) => {
  const today = new Date();
  return (
    date.year === today.getFullYear() &&
    date.month === today.getMonth() + 1 &&
    date.day === today.getDate()
  );
};

/**
 * When using DatePicker component, you need to use the [@internationalized/date](https://react-spectrum.adobe.com/internationalized/date/) library.
 */
export const DatePicker = <T extends DateValue>({
  label,
  isInvalid,
  errorMessage,
  isDisabled,
  isLoading,
  onCalendarFocusChange,
  isCalendarDisabled,
  onlyShowFocusedMonthDates,
  locale = "en-AU",
  ...props
}: DatePickerProps<T>) => {
  const formatDay = useMemo(() => {
    return (date: string) => date.substring(0, 2);
  }, []);

  const showErrorMessage = !!errorMessage;
  const themeDefinition = useContext(ThemeContext);

  return (
    <I18nProvider locale={locale}>
      <DatePickerPrimitive
        {...props}
        className={styles.datePicker}
        isInvalid={isInvalid || showErrorMessage}
        isDisabled={isDisabled}
      >
        <Stack direction="column" spacing="3xs">
          {label && <InputLabel>{label}</InputLabel>}
          <Group className={styles.dateGroup}>
            <DateInput className={styles.dateInput}>
              {(segment) => <DateSegment segment={segment} className={styles.dateSegment} />}
            </DateInput>
            {isLoading && <LoadingSpinner className={styles.loadingSpinner} />}
            <Button className={styles.calendarTriggerButton}>
              <SVGCalendar />
            </Button>
          </Group>
          {showErrorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Stack>
        <Popover className={styles.popover}>
          <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
            <Dialog>
              <Calendar
                className={styles.calendar}
                onFocusChange={onCalendarFocusChange}
                isDisabled={isCalendarDisabled}
              >
                <Box p="default" className={styles.datePickerContainer}>
                  <header className={styles.header}>
                    <Button slot="previous" className={styles.iconButtonContainer}>
                      <SVGChevronLeft />
                    </Button>
                    <Heading className={styles.heading} />
                    <Button slot="next" className={styles.iconButtonContainer}>
                      <SVGChevronRight />
                    </Button>
                  </header>
                  <CalendarGrid weekdayStyle="short">
                    <CalendarGridHeader>
                      {(day) => (
                        <CalendarHeaderCell>
                          <Box py="s">
                            <Typography variant="h6" className={styles.headerText}>
                              {formatDay(day)}
                            </Typography>
                          </Box>
                        </CalendarHeaderCell>
                      )}
                    </CalendarGridHeader>

                    <CalendarGridBody>
                      {(date) => (
                        <CalendarCell
                          className={cn(styles.calendarCell, {
                            [styles.calendarCellToday]: isCurrentDay(date),
                            [styles.datesFromCurrentMonthOnly]: onlyShowFocusedMonthDates,
                          })}
                          date={date}
                        />
                      )}
                    </CalendarGridBody>
                  </CalendarGrid>
                  {isLoading && (
                    <div className={styles.datePickerSpinnerContainer}>
                      <LoadingSpinner />
                    </div>
                  )}
                </Box>
              </Calendar>
            </Dialog>
          </GlobalStylesScope>
        </Popover>
      </DatePickerPrimitive>
    </I18nProvider>
  );
};

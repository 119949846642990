import classNames from "classnames";
import { ChangeEvent, SelectHTMLAttributes, forwardRef, useCallback, useState } from "react";
import { useId } from "react-aria";

import { SVGChevronDown } from "@/components/SVGIcon/static/SVGChevronDown";

import styles from "./Select.module.scss";
import { FormHelperText } from "../FormHelperText/FormHelperText";
import { FormInputBorder } from "../FormInputBorder/FormInputBorder";
import { InputLabel } from "../InputLabel/InputLabel";

interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  /** An array of options to be displayed in the select dropdown */
  options: SelectOption[];
  /** Placeholder text to be displayed when no option is selected */
  placeholder?: string;
  /** Whether the select dropdown is disabled or not */
  disabled?: boolean;
  /** Error message to be displayed when there's an error */
  errorMessage?: string;
  /** A description to provides a hint */
  description?: string;
}

/**
 * Select component renders a native `Select` element. It accepts all the props that a native Select element accepts.
 * It uses `forwardRef` to pass the ref to the underlying `Select` element. The ref can be used to access the `Select` element.
 * It uses `FormInputBorder` and `FormHelperText` components to render the border and error message respectively.
 * This component should only be used for `form elements` and should be used in conjunction with the `FormContainer`
 * component.
 */
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      options,
      placeholder,
      disabled,
      errorMessage,
      "aria-label": ariaLabel,
      onChange,
      description,
      ...rest
    },
    ref
  ) => {
    const hasError = !!errorMessage;
    const selectElementId = useId();

    const [isOpaque, setIsOpaque] = useState(true);
    const showDescription = Boolean(description?.trim()) && !hasError;

    const handleSelectChange = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        setIsOpaque(!e.target?.value);
        onChange?.(e);
      },
      [onChange]
    );

    return (
      <div
        className={classNames(styles.customSelect, {
          [styles.disabled]: disabled,
          [styles.error]: hasError,
        })}
      >
        {label && (
          <InputLabel htmlFor={selectElementId} className={styles.selectLabel}>
            {label}
          </InputLabel>
        )}
        <FormInputBorder aria-invalid={hasError}>
          <select
            className={isOpaque ? "" : styles.hasValue}
            id={selectElementId}
            aria-disabled={disabled}
            aria-label={ariaLabel ?? placeholder}
            aria-invalid={hasError}
            aria-describedby={hasError ? "error-message" : undefined}
            ref={ref}
            onChange={handleSelectChange}
            {...rest}
          >
            {placeholder && <option value="">{placeholder}</option>}

            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className={styles.svgContainer}>
            <SVGChevronDown aria-hidden="true" />
          </div>
        </FormInputBorder>

        {showDescription && <FormHelperText>{description}</FormHelperText>}
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </div>
    );
  }
);

Select.displayName = "Select";

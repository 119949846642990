import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGMapDestination: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 14.9688C32 6.7144 24.8218 0 16 0C7.17818 0 0 6.7144 0 14.9688C0 22.7164 13.328 39.5598 14.848 41.454C15.1229 41.7984 15.5491 42 16 42C16.4509 42 16.8771 41.7984 17.152 41.4554C18.672 39.5598 32 22.7178 32 14.9688ZM16 21C20.0166 21 23.2727 18.0619 23.2727 14.4375C23.2727 10.8131 20.0166 7.875 16 7.875C11.9834 7.875 8.72727 10.8131 8.72727 14.4375C8.72727 18.0619 11.9834 21 16 21Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGMapDestination = React.memo(SVGMapDestination);

export { SVGMapDestination };

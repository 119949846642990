import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGCrossCircle: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    fill="currentColor"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm-4.773-8.555 3.712-3.695-3.712-3.694L8.287 7 12 10.695 15.712 7l1.061 1.056-3.712 3.694 3.712 3.695-1.06 1.055L12 12.806 8.288 16.5l-1.061-1.055Z"
      clipRule="evenodd"
    />
  </svg>
);
SVGCrossCircle = memo(SVGCrossCircle);

export { SVGCrossCircle };

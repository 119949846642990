import * as React from "react";
import "./MainNavContainer.scss";
import classNames from "classnames";
import { noop } from "lodash";
import { usePrimaryNavContainerCssCustomProps } from "./usePrimaryNavContainerCssCustomProps";

interface MainNavContainerContextType {
    applyDarkBackgroundStyle: (isDark: boolean) => void;
}

interface MainNavContainerProps {
    children: React.ReactNode;
}

const MainNavContainerContext = React.createContext<MainNavContainerContextType>({
    applyDarkBackgroundStyle: noop,
});

const MainNavContainer = (props: MainNavContainerProps) => {
    const [isDarkBackground, setIsDarkBackground] = React.useState(false);

    const containerRef = usePrimaryNavContainerCssCustomProps();

    return (
        <MainNavContainerContext.Provider value={{ applyDarkBackgroundStyle: setIsDarkBackground }}>
            <nav
                ref={containerRef}
                className={classNames("lxs-main-nav", { "lxs-main-nav--dark": isDarkBackground })}
                data-primary-nav-modal-open-scrollto-target=""
            >
                {props.children}
            </nav>
        </MainNavContainerContext.Provider>
    );
};

export { MainNavContainer, MainNavContainerContext };

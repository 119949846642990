import * as React from "react";
import { Theme } from "../shared/Theme";
import { SVGAnimationEvents } from "../SVGIcons/SVGIcon";
import { SVGIconButton, SVGIconButtonIconPositions } from "../SVGIcons/SVGIconButton";
import { MainMenu } from "./MainMenu/MainMenu";
import { ModelsMenu } from "./ModelsMenu/ModelsMenu";
import "./PrimaryNav.scss";
import { Logo } from "../Logo/Logo";
import { useGTM } from "../GTM/useGTM";
import { GTMComponentContext } from "../GTM/GTMComponentContext";
import { SVGModelsMenuAnimated } from "../SVGIcons/icons/animated/SVGModelsMenuAnimated";
import { lxColor } from "../../colors/variables";
import { SVGMainMenuAnimated } from "../SVGIcons/icons/animated/SVGMainMenuAnimated";
import { SVGChevronDown } from "../SVGIcons/icons/static/SVGChevronDown";
import { SVGChevronUp } from "../SVGIcons/icons/static/SVGChevronUp";
import { DesktopDealerContact } from "./DealerContact/DesktopDealerContact";

const PrimaryNavContext = React.createContext<LXS.PrimaryNavProps>({});
const MODAL_CLASS_AFTER_OPEN = "lxs-modal-after-open";
const MODAL_CLASS_OPEN = "lxs-modal-open";

const PrimaryNavGrid: React.FC = ({ children }) => (
    <div className="lxs-grid-row">
        <div className="lxs-primary-nav__container lxs-grid lxs-grid--with-margin">{children}</div>
    </div>
);

const PrimaryNav: React.FC<LXS.PrimaryNavProps> = ({
    logo,
    menuTitle = "Menu",
    modelsTitle = "Models",
    menuData = {},
    modelsData = {},
    socialMediaLinks = [],
    searchConfig,
    children,
    dealerContact,
}) => {
    const [showMenu, setShowMenu] = React.useState(false);
    const [showModels, setShowModels] = React.useState(false);
    const [showDealerContact, setShowDealerContact] = React.useState(false);
    const windowLastScrollPosition = React.useRef(0);
    const modalOpen = React.useRef(false);
    const { gtmInteraction } = useGTM({ component: "PrimaryNav" });

    const delay = 500;
    const handleModalAfterOpen = () =>
        setTimeout(
            () =>
                modalOpen.current &&
                typeof document !== "undefined" &&
                document.body.classList.add(MODAL_CLASS_AFTER_OPEN),
            delay,
        );

    const toggleModalClass = (show: boolean) => {
        if (typeof document !== "undefined") {
            show && document.body.classList.remove(MODAL_CLASS_AFTER_OPEN);
            document.body.classList.toggle(MODAL_CLASS_OPEN, !show);
        }
    };

    const backgroundScrollHandler = (isModalOpening: boolean) => {
        const delay = 100;
        if (typeof window !== "undefined") {
            isModalOpening
                ? (windowLastScrollPosition.current = window.pageYOffset)
                : setTimeout(() => window.scrollTo(0, windowLastScrollPosition.current), delay);
        }
    };

    const handleClose = (show: boolean, gtmTarget: string) => {
        toggleModalClass(show);
        backgroundScrollHandler(!show);
        gtmInteraction(gtmTarget, !show ? "Open" : "Close");
    };

    const handleMenuClose = () => {
        handleClose(showMenu, "MainMenu");
        setShowMenu((currentShowMenu) => {
            modalOpen.current = !currentShowMenu;
            return !currentShowMenu;
        });
    };

    const handleModelClose = () => {
        handleClose(showModels, "ModelsMenu");
        setShowModels((currentShowModels) => {
            modalOpen.current = !currentShowModels;
            return !currentShowModels;
        });
    };

    const handleGetInTouchClick = () => {
        setShowDealerContact((currentShowDealerContact) => !currentShowDealerContact);
        gtmInteraction("Get In Touch", !showDealerContact ? "Open" : "Close");
    };

    const renderGetInTouch = () => (
        <div className="lxs-primary-nav-contact" onClick={handleGetInTouchClick}>
            <span className="lxs-primary-nav-contact__text">Get In Touch</span>
            {showDealerContact ? (
                <SVGChevronUp width={12} height={12} color={lxColor("white")} />
            ) : (
                <SVGChevronDown width={12} height={12} color={lxColor("white")} />
            )}
        </div>
    );

    return (
        <GTMComponentContext.Provider value={{ component: "PrimaryNav" }}>
            <PrimaryNavContext.Provider
                value={{
                    menuData,
                    modelsData,
                    socialMediaLinks,
                    searchConfig,
                    handleModelClose,
                    handleMenuClose,
                    handleModalAfterOpen,
                    dealerContact,
                }}
            >
                <div className="lxs-primary-nav__row">
                    {dealerContact && <DesktopDealerContact isOpen={showDealerContact} />}
                    <PrimaryNavGrid>
                        <div className="lxs-primary-nav__container-left">
                            <SVGIconButton
                                title={modelsTitle}
                                stackOnMobile={true}
                                onClick={handleModelClose}
                                theme={Theme.DARK}
                                data-gtm-target={"ModelsMenuButton"}
                                data-gtm-action={"Open"}
                            >
                                <SVGModelsMenuAnimated
                                    animationEvent={SVGAnimationEvents.HOVER}
                                    hasBackground={true}
                                    width={25}
                                    height={25}
                                    color={lxColor("black")}
                                />
                            </SVGIconButton>
                        </div>
                        <div>
                            <Logo {...logo} />
                        </div>
                        <div className="lxs-primary-nav__container-right">
                            {dealerContact && renderGetInTouch()}
                            <SVGIconButton
                                title={menuTitle}
                                iconPosition={SVGIconButtonIconPositions.RIGHT}
                                stackOnMobile={true}
                                onClick={handleMenuClose}
                                theme={Theme.DARK}
                                data-gtm-target={"MainMenuButton"}
                                data-gtm-action={"Open"}
                            >
                                <SVGMainMenuAnimated
                                    animationEvent={SVGAnimationEvents.HOVER}
                                    hasBackground={true}
                                    width={25}
                                    height={25}
                                    color={lxColor("black")}
                                />
                            </SVGIconButton>
                        </div>
                    </PrimaryNavGrid>
                </div>
                {children}
                <ModelsMenu isOpen={showModels} />
                <MainMenu isOpen={showMenu} />
            </PrimaryNavContext.Provider>
        </GTMComponentContext.Provider>
    );
};

export { PrimaryNav, PrimaryNavGrid, PrimaryNavContext };

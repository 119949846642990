import { LoadStatus } from "../types/loadStatus";
import { VinOrRegistrationType, VehicleUnitPayload } from "../types/vehicle";
import { StateOrTerritory } from "../types/stateAndTerritory";
import { createAction, ReducerMapValue, handleActions } from "redux-actions";
import { vehicleUnitUrl, vehicleUnitByVinUrl, associateVehicleUrl } from "../apiHref";
import {
    FetchActionPayload,
    createFetchThunk,
    PromiseThunk,
    fetchActionToLoadStatus,
    FetchAction,
    FetchContainer,
    initialFetchContainer,
    getFetchContainerValue,
    fetchActionPayloadToContainer,
} from "../helpers/fetch";
import { StateSelector } from "../types/general";
import { fetchVehiclesThunk } from "./vehicle";
import { RootLevelAction } from "./rootLevelAction";
import { createSelector } from "reselect";

export interface RegistrationData {
    registration: string;
    stateOrTerritory?: StateOrTerritory;
}

export interface VinData {
    vin: string;
}

type SetVehicleAssociationUnitPayload = VinOrRegistrationType;
type SetAddTypePayload = VinOrRegistrationType | undefined;
type SetRegistrationDataPayload = RegistrationData;
type SetVinDataPayload = VinData;

type RegistrationVehicleFetchActionPayload = FetchActionPayload<VehicleUnitPayload>;
type VinVehicleFetchActionPayload = FetchActionPayload<VehicleUnitPayload>;
type AssociateVehicleFetchActionPayload = FetchActionPayload<undefined>;

type RegistrationVehicleFetchContainer = FetchContainer<VehicleUnitPayload>;
type VinVehicleFetchContainer = FetchContainer<VehicleUnitPayload>;

//#region actions
export enum AddVehicleFlowAction {
    FetchRegistrationVehicle = "FetchRegistrationVehicle",
    FetchVinVehicle = "FetchVinVehicle",
    ClearAddVehicleFlow = "ClearAddVehicleFlow",
    AssociateVehicle = "AssociateVehicle",
    SetVehicleAssociationUnit = "SetVehicleAssociationUnit",
    SetAddType = "SetAddType",
    SetRegistrationData = "SetRegistrationData",
    ResetRegistrationData = "ResetRegistrationData",
    SetVinData = "SetVinData",
    SetupVehicleAdd = "SetupVehicleAdd",
}

const fetchRegistrationVehicleAction = createAction<RegistrationVehicleFetchActionPayload>(
    AddVehicleFlowAction.FetchRegistrationVehicle
);

const fetchVinVehicleAction = createAction<VinVehicleFetchActionPayload>(AddVehicleFlowAction.FetchVinVehicle);

export const setAddTypeAction = createAction<SetAddTypePayload>(AddVehicleFlowAction.SetAddType);

export const setRegistrationDataAction = createAction<SetRegistrationDataPayload>(
    AddVehicleFlowAction.SetRegistrationData
);

export const resetRegistrationDataAction = createAction(AddVehicleFlowAction.ResetRegistrationData);

export const setVinDataAction = createAction<SetVinDataPayload>(AddVehicleFlowAction.SetVinData);

const associateVehicleAction = createAction<AssociateVehicleFetchActionPayload>(AddVehicleFlowAction.AssociateVehicle);

export const clearAddVehicleFlowAction = createAction(AddVehicleFlowAction.ClearAddVehicleFlow);

export const setVehicleAssociationUnitAction = createAction<SetVehicleAssociationUnitPayload>(
    AddVehicleFlowAction.SetVehicleAssociationUnit
);

export const setupVehicleAddAction = createAction(AddVehicleFlowAction.SetupVehicleAdd);
//#endregion

//#region state
export interface AddVehicleFlowState {
    registrationVehicle: RegistrationVehicleFetchContainer;
    vinVehicle: VinVehicleFetchContainer;
    vehicleAssociation: {
        unit?: VehicleUnitPayload;
        status: LoadStatus;
        by?: VinOrRegistrationType;
        error?: Error;
    };
    addType?: VinOrRegistrationType;
    registrationData: RegistrationData;
    vinData: VinData;
}

export const initialAddVehicleFlowState: AddVehicleFlowState = {
    registrationVehicle: initialFetchContainer,
    vinVehicle: initialFetchContainer,
    vehicleAssociation: {
        status: LoadStatus.NotStarted,
    },
    registrationData: { registration: "" },
    vinData: { vin: "" },
};
//#endregion

//#region thunks
export const fetchRegistrationVehicleThunk =
    (registration: string, state: StateOrTerritory): PromiseThunk<VehicleUnitPayload> =>
    async dispatch => {
        const uri = (await vehicleUnitUrl()).replace("{rego}", registration).replace("{state}", state);
        const url = new URL(uri);
        return dispatch(createFetchThunk(url.href, fetchRegistrationVehicleAction));
    };

export const fetchVinVehicleThunk =
    (vin: string): PromiseThunk<VehicleUnitPayload> =>
    async dispatch => {
        const url = new URL(`${await vehicleUnitByVinUrl()}/${vin}`);
        return dispatch(createFetchThunk(url.href, fetchVinVehicleAction));
    };

export const associateVehicleThunk =
    (SfId: string, vin: string): PromiseThunk<void> =>
    async dispatch => {
        const url = await associateVehicleUrl();
        const thunk = createFetchThunk(url, associateVehicleAction, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({ vin, SfId }),
        });
        dispatch(thunk).finally(() => {
            dispatch(fetchVehiclesThunk);
            //dispatch(registerGuestThunk(false)); //TODO: find out if we need this
        });
    };
//#endregion

//#region selectors
const registrationVehicleContainerSelector: StateSelector<RegistrationVehicleFetchContainer> = state =>
    state.addVehicleFlow.registrationVehicle;

export const registrationVehicleLoadStatusSelector: StateSelector<LoadStatus> = state =>
    registrationVehicleContainerSelector(state).status;

export const registrationVehicleUnitSelector: StateSelector<VehicleUnitPayload | undefined> = createSelector(
    registrationVehicleContainerSelector,
    getFetchContainerValue
);

const vinVehicleContainerSelector: StateSelector<VinVehicleFetchContainer> = state => state.addVehicleFlow.vinVehicle;

export const vinVehicleLoadStatusSelector: StateSelector<LoadStatus> = state =>
    vinVehicleContainerSelector(state).status;

export const vinVehicleUnitSelector: StateSelector<VehicleUnitPayload | undefined> = createSelector(
    vinVehicleContainerSelector,
    getFetchContainerValue
);

export const vehicleAssociationUnitSelector: StateSelector<VehicleUnitPayload | undefined> = state =>
    state.addVehicleFlow.vehicleAssociation.unit;

export const vehicleAssociationStatusSelector: StateSelector<LoadStatus> = state =>
    state.addVehicleFlow.vehicleAssociation.status;

export const vehicleAssociationBySelector: StateSelector<VinOrRegistrationType | undefined> = state =>
    state.addVehicleFlow.vehicleAssociation.by;

export const addTypeSelector: StateSelector<VinOrRegistrationType | undefined> = state => state.addVehicleFlow.addType;

export const registrationDataSelector: StateSelector<RegistrationData> = state => state.addVehicleFlow.registrationData;

export const vinDataSelector: StateSelector<VinData> = state => state.addVehicleFlow.vinData;

export const vehicleErrorSelector: StateSelector<Error | undefined> = state =>
    state.addVehicleFlow.vehicleAssociation.error;
//#endregion

//#region reducers
type AddVehicleFlowReducerPayload =
    | SetVehicleAssociationUnitPayload
    | SetAddTypePayload
    | SetRegistrationDataPayload
    | SetVinDataPayload
    | RegistrationVehicleFetchActionPayload
    | VinVehicleFetchActionPayload
    | AssociateVehicleFetchActionPayload
    | undefined
    | Error;

type AddVehicleFlowReducer<T> = ReducerMapValue<AddVehicleFlowState, T>;

const fetchRegistrationVehicleReducer: AddVehicleFlowReducer<RegistrationVehicleFetchActionPayload> = (
    state,
    action
) => ({
    ...state,
    registrationVehicle: fetchActionPayloadToContainer(action.payload),
});

const fetchVinVehicleReducer: AddVehicleFlowReducer<VinVehicleFetchActionPayload> = (state, action) => ({
    ...state,
    vinVehicle: fetchActionPayloadToContainer(action.payload),
});

const associateVehicleReducer: AddVehicleFlowReducer<AssociateVehicleFetchActionPayload> = (state, action) => ({
    ...state,
    vehicleAssociation: {
        ...state.vehicleAssociation,
        status: fetchActionToLoadStatus(action.payload.action),
        error: action.payload.action === FetchAction.Failure ? action.payload.value : undefined,
    },
});

const clearAddVehicleFlowReducer: AddVehicleFlowReducer<undefined> = () => initialAddVehicleFlowState;

const setVehicleAssociationUnitReducer: AddVehicleFlowReducer<SetVehicleAssociationUnitPayload> = (state, action) => {
    const unit =
        action.payload === VinOrRegistrationType.VIN
            ? getFetchContainerValue(state.vinVehicle)
            : action.payload === VinOrRegistrationType.REGISTRATION
            ? getFetchContainerValue(state.registrationVehicle)
            : undefined;

    return {
        ...state,
        vehicleAssociation: {
            ...state.vehicleAssociation,
            status: LoadStatus.NotStarted,
            unit,
            by: action.payload,
        },
    };
};

const setAddTypeReducer: AddVehicleFlowReducer<SetAddTypePayload> = (state, action) => ({
    ...state,
    addType: action.payload,
});

const setRegistrationDataReducer: AddVehicleFlowReducer<SetRegistrationDataPayload> = (state, action) => ({
    ...state,
    registrationData: action.payload,
});

const resetRegistrationDataReducer: AddVehicleFlowReducer<undefined> = state => ({
    ...state,
    registrationData: initialAddVehicleFlowState.registrationData,
    registrationVehicle: { ...initialAddVehicleFlowState.registrationVehicle },
});

const setVinDataReducer: AddVehicleFlowReducer<SetVinDataPayload> = (state, action) => ({
    ...state,
    vinData: action.payload,
});

const setupVehicleAddReducer: AddVehicleFlowReducer<undefined> = state => ({
    ...state,
    registrationVehicle: initialAddVehicleFlowState.registrationVehicle,
});

const resetReducer: AddVehicleFlowReducer<undefined> = () => initialAddVehicleFlowState;

export const addVehicleFlowReducer = handleActions<AddVehicleFlowState, AddVehicleFlowReducerPayload>(
    {
        [AddVehicleFlowAction.FetchRegistrationVehicle]: fetchRegistrationVehicleReducer,
        [AddVehicleFlowAction.FetchVinVehicle]: fetchVinVehicleReducer,
        [AddVehicleFlowAction.ClearAddVehicleFlow]: clearAddVehicleFlowReducer,
        [AddVehicleFlowAction.AssociateVehicle]: associateVehicleReducer,
        [AddVehicleFlowAction.SetVehicleAssociationUnit]: setVehicleAssociationUnitReducer,
        [AddVehicleFlowAction.SetAddType]: setAddTypeReducer,
        [AddVehicleFlowAction.SetRegistrationData]: setRegistrationDataReducer,
        [AddVehicleFlowAction.ResetRegistrationData]: resetRegistrationDataReducer,
        [AddVehicleFlowAction.SetVinData]: setVinDataReducer,
        [AddVehicleFlowAction.SetupVehicleAdd]: setupVehicleAddReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialAddVehicleFlowState
);
//#endregion

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGDropPinLexusLogo: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width} height={height} fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 11.4C4 5.1 9.4 0 16 0s12 5.1 12 11.4c0 5.9-10 18.7-11.1 20.2-.2.2-.6.4-.9.4-.3 0-.7-.2-.9-.4C14 30.1 4 17.3 4 11.4zm19.6.6s.2 0 .3-.1c.1 0 .2-.3.2-.3.3-.5.3-.9.3-1.5 0-2.4-1.3-4.6-4.2-5.6l.3-.6c3.3 1.1 5.2 3.5 5.2 6.2 0 1.3-.5 2.5-1.4 3.5h-9.9c-4-.1-2.3-2.6-2.3-2.6s.2-.4 1.7-2.6c1.5-2.1 2.7-3.5 2.7-3.5.6-.6.6-.8.4-.9h-.6c-4.8 0-8 2.8-8 6.1 0 3.2 2.7 5.9 8 5.9 2.5 0 4.5-.7 5.9-1.9h1.6c-1.6 1.6-4.2 2.6-7.5 2.6-6.1 0-9.3-3.1-9.3-6.6 0-3.7 3.7-6.8 9.3-6.8 1.1 0 2.1.1 3.1.3.5.2.4.5.3.8l-.3.3-3.9 5.6c-1.4 1.6 0 1.7 0 1.7h8.1z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGDropPinLexusLogo = React.memo(SVGDropPinLexusLogo);

export { SVGDropPinLexusLogo };

import * as React from "react";

import { defaultIconSize } from "../constants";
import { lxColor } from "../../../../colors/variables";

let SVGCheckMarkCircle: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = lxColor("white"),
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
            fill={lxColor("success")}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.92397 16.0519C9.7672 16.2025 9.7672 16.4433 9.92397 16.594L13.596 20.1225C13.7573 20.2774 14.022 20.2774 14.1832 20.1225L22.1393 12.4774C22.2961 12.3268 22.2961 12.086 22.1393 11.9354C21.978 11.7804 21.7133 11.7804 21.5521 11.9354L13.8896 19.2982L10.5112 16.0519C10.3499 15.8969 10.0853 15.8969 9.92397 16.0519Z"
            fill={color}
            stroke={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGCheckMarkCircle = React.memo(SVGCheckMarkCircle);

export { SVGCheckMarkCircle };

import { useEffect, useState } from "react";

import { Status, useScript } from "@/hooks/useScript";

import GoogleWrappers from "./GoogleWrappers";
import {
  geoCodeAddressAsync,
  getDirectionsAsync,
  googleToLexusCoordinates,
} from "./LexusMapHelpers";
import { LexusMapDirectionsWithIcons, MarkerIcon } from "./types";

declare global {
  interface Window {
    initMap: () => void;
  }
}

/**
 * Use this hook to ensure that Google Maps scripts are loaded using Lexus Account API Key.
 * @param apiKey Google Maps Api Key
 * @returns true if script was loaded, false otherwise
 */
export const useLexusGoogleMapScriptsReady = (apiKey: string) => {
  // Dispatch event when Google Maps scripts are loaded, callback is required by Google Maps API
  if (typeof window !== "undefined") {
    window.initMap = () => document.dispatchEvent(new CustomEvent("googleMapScriptsReady"));
  }

  const googleMapUrl = `//maps.google.com/maps/api/js?key=${apiKey}&libraries=geometry,places&callback=initMap`;
  const scriptStatus = useScript(googleMapUrl, "LexusGoogleMapScript");

  return scriptStatus === Status.LOADED;
};

interface UseAddressDirectionsProps {
  originAddress?: string;
  destinationAddress?: string;
  originIcon?: MarkerIcon;
  destinationIcon?: MarkerIcon;
}

/**
 * Allows to calculate origin location or directions from origin to destination (if set)
 * This hook guaranties that return object will be refreshed only if one of parameters changes
 * and does not require additional caching
 * @param apiKey Google Maps API key
 * @param props.originAddress: Origin address. Mandatory.
 * @param props.destinationAddress: Destination address. Optional.
 * @param props.originIcon: Origin icon. Optional.
 * @param props.destinationIcon: Destination icon. Optional.
 * @returns memoized LexusMapDirectionsWithIcons object
 */
export const useLexusMapDirections = (
  apiKey: string,
  { originAddress, destinationAddress, originIcon, destinationIcon }: UseAddressDirectionsProps
) => {
  const scriptsReady = useLexusGoogleMapScriptsReady(apiKey);
  const [addressDirections, setAddressDirections] = useState<LexusMapDirectionsWithIcons>();

  useEffect(() => {
    const calculateRoute = async () => {
      if (!originAddress) {
        setAddressDirections(undefined);
        return;
      }

      try {
        const [origin, destination] = await Promise.all([
          geoCodeAddressAsync(originAddress),
          destinationAddress ? geoCodeAddressAsync(destinationAddress) : Promise.resolve(undefined),
        ] as const);

        const directions = destination
          ? await getDirectionsAsync({
              origin,
              destination,
              travelMode: GoogleWrappers.TravelMode.DRIVING,
            })
          : undefined;

        setAddressDirections({
          directions,
          origin: googleToLexusCoordinates(origin),
          destination: destination ? googleToLexusCoordinates(destination) : undefined,
          originIcon,
          destinationIcon,
        });
      } catch {
        setAddressDirections(undefined);
      }
    };

    if (scriptsReady) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      calculateRoute();
    }
  }, [scriptsReady, originAddress, destinationAddress, originIcon, destinationIcon]);

  return addressDirections;
};

import * as React from "react";

import { LexusMapDirectionsWithIcons, MarkerIcon } from "./types";
import { Status, useScript } from "../../utilities/CustomHooks/useScript";
import { geoCodeAddressAsync, getDirectionsAsync, googleToLexusCoordinates } from "./LexusMapHelpers";

import GoogleWrappers from "./GoogleWrappers";

/**
 * Use this hook to ensure that Google Maps scripts are loaded using Lexus Account API Key.
 * @param apiKey Google Maps Api Key
 * @returns true if script was loaded, false otherwise
 */
export const useLexusGoogleMapScriptsReady = (apiKey: string) => {
    const googleMapUrl = `//maps.google.com/maps/api/js?key=${apiKey}&libraries=geometry,places`;
    const scriptStatus = useScript(googleMapUrl, "LexusGoogleMapScript");
    return scriptStatus === Status.LOADED;
};

interface UseAddressDirectionsProps {
    originAddress?: string;
    destinationAddress?: string;
    originIcon?: MarkerIcon;
    destinationIcon?: MarkerIcon;
}

/**
 * Allows to calculate origin location or directions from origin to destination (if set)
 * This hook guaranties that return object will be refreshed only if one of parameters changes
 * and does not require additional caching
 * @param apiKey Google Maps API key
 * @param props.originAddress: Origin address. Mandatory.
 * @param props.destinationAddress: Destination address. Optional.
 * @param props.originIcon: Origin icon. Optional.
 * @param props.destinationIcon: Destination icon. Optional.
 * @returns memoized LexusMapDirectionsWithIcons object
 */
export const useLexusMapDirections = (
    apiKey: string,
    { originAddress, destinationAddress, originIcon, destinationIcon }: UseAddressDirectionsProps,
) => {
    const scriptsReady = useLexusGoogleMapScriptsReady(apiKey);
    const [addressDirections, setAddressDirections] = React.useState<LexusMapDirectionsWithIcons>();

    React.useEffect(() => {
        const calculateRoute = async () => {
            if (!originAddress) {
                setAddressDirections(undefined);
                return;
            }

            try {
                const [origin, destination] = await Promise.all([
                    geoCodeAddressAsync(originAddress),
                    destinationAddress ? geoCodeAddressAsync(destinationAddress) : Promise.resolve(undefined),
                ] as const);

                const directions = destination
                    ? await getDirectionsAsync({
                          origin,
                          destination,
                          travelMode: GoogleWrappers.TravelMode.DRIVING,
                      })
                    : undefined;
                setAddressDirections({
                    directions,
                    origin: googleToLexusCoordinates(origin),
                    destination: destination ? googleToLexusCoordinates(destination) : undefined,
                    originIcon,
                    destinationIcon,
                });
            } catch {
                setAddressDirections(undefined);
            }
        };
        scriptsReady && calculateRoute();
    }, [scriptsReady, originAddress, destinationAddress, originIcon, destinationIcon]);

    return addressDirections;
};

import cn from "classnames";
import { useEffect, useRef, useState } from "react";

import styles from "./SVGIconWrapper.module.scss";
import { SVGAnimatedBaseProps, SVGAnimationEvents } from "./types";

type SVGIconElementProps = React.HTMLAttributes<HTMLSpanElement> & SVGAnimatedBaseProps;

const SVGIconWrapper = ({
  className,
  hasBackground = false,
  containerRef,
  animationEvent = SVGAnimationEvents.CLICK,
  active = false,
  activeClassName = "active",
  ...rest
}: SVGIconElementProps) => {
  const iconWrapperRef = useRef<HTMLSpanElement>(null);
  const [isActive, setIsActive] = useState(active);
  const classes = cn(
    styles.iconWrapper,
    {
      [`${styles.withBackground}`]: hasBackground,
      [`${activeClassName}`]: isActive,
    },
    className
  );

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  useEffect(() => {
    const element =
      containerRef && containerRef.current ? containerRef.current : iconWrapperRef.current;

    if (!element) {
      return;
    }

    const toggleActive = () => {
      setIsActive((currentIsActive) => !currentIsActive);
    };

    switch (animationEvent) {
      case SVGAnimationEvents.HOVER:
        element.addEventListener("mouseenter", toggleActive);
        element.addEventListener("mouseleave", toggleActive);
        break;
      case SVGAnimationEvents.CLICK:
        element.addEventListener("click", toggleActive);
        break;
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseenter", toggleActive);
        element.removeEventListener("mouseleave", toggleActive);
        element.removeEventListener("click", toggleActive);
      }
    };
  }, [isActive, containerRef, animationEvent]);

  return <span ref={iconWrapperRef} className={classes} {...rest} />;
};

export { SVGIconWrapper };

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGLink: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M5.96023 18.0398C4.68517 16.7647 4.68517 14.6918 5.96023 13.4167L8.94283 10.4341L7.5261 9.0174L4.5435 12C2.4855 14.058 2.4855 17.3985 4.5435 19.4565C6.60149 21.5145 9.942 21.5145 12 19.4565L14.9826 16.4739L13.5659 15.0572L10.5833 18.0398C9.3082 19.3148 7.23529 19.3148 5.96023 18.0398ZM9.76305 15.7283L15.7283 9.76305L14.237 8.27175L8.27175 14.237L9.76305 15.7283ZM12 4.5435L9.0174 7.5261L10.4341 8.94283L13.4167 5.96023C14.6918 4.68517 16.7647 4.68517 18.0398 5.96023C19.3148 7.23529 19.3148 9.3082 18.0398 10.5833L15.0572 13.5659L16.4739 14.9826L19.4565 12C21.5145 9.942 21.5145 6.60149 19.4565 4.5435C17.3985 2.4855 14.058 2.4855 12 4.5435Z"
            fill={color}
        />
    </svg>
);
SVGLink = React.memo(SVGLink);

export { SVGLink };

import React, { FunctionComponent } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { routeAccess } from "../../routeAccess";
import { routeString, RouteSection } from "../../helpers/routes";
import { VehicleStatus } from "../../types/vehicle";
import { useVehicles } from "../../hooks/useVehicles";
import { getLatestVerifiedVehicle } from "../../helpers/vehicle";
import Spinner from "../../components/Spinner/Spinner";
import { vehiclesFetchStatusSelector } from "../../reduxSlices/vehicle";
import { useSelector } from "react-redux";
import { LoadStatus } from "../../types/loadStatus";
import Refresh from "Pages/Refresh/Refresh";
import SilentSignOut from "Pages/SignOut/SilentSignOut";

const AccountStatusGuard: FunctionComponent = ({ children }) => {
    //#region hooks
    const vehicles = useVehicles(true);
    const vehicleStatus = useSelector(vehiclesFetchStatusSelector);
    //#endregion

    const vehiclesResponded = vehicleStatus === LoadStatus.Success || vehicleStatus === LoadStatus.Failure;
    const latestVehicle = getLatestVerifiedVehicle(vehicles || []);
    const allowedRoutes = routeAccess[latestVehicle ? latestVehicle.status : VehicleStatus.Unverified].map(
        (r: string) => routeString(r as RouteSection)
    );

    return !vehiclesResponded ? (
        <Spinner />
    ) : (
        <Switch>
            <Route path={`/${RouteSection.Refresh}`} render={() => <Refresh />} />
            <Route path={`/${RouteSection.SilentSignOut}`} render={() => <SilentSignOut />} />
            <Route path={allowedRoutes} render={() => children} />
            <Redirect to="/" />
        </Switch>
    );
};

export default AccountStatusGuard;

import cn from "classnames";
import { CSSProperties } from "react";

import styles from "./Box.module.scss";
import { SpacingScale, mapSpacingToCSSVar } from "./utils";

export interface BoxProps<T> {
  children: React.ReactNode;
  component?: string | React.ComponentType<T>;
  p?: SpacingScale;
  px?: SpacingScale;
  py?: SpacingScale;
  pt?: SpacingScale;
  pb?: SpacingScale;
  pl?: SpacingScale;
  pr?: SpacingScale;
  className?: string;
}

/**
 * Box is a layout component that helps create components quickly. Box is a container that sets the amount of padding around elements inside (Usually a Stack).
 * Spacing scale has both a static and dynamic scales:
 *
 * Static: least, even-less, less, etc
 *
 * Dynamic: T-shirt sizing (xs, sm, md, etc)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Box: React.FC<BoxProps<any>> = ({
  children,
  component: Component = "div",
  className,
  p,
  px,
  py,
  pt,
  pb,
  pl,
  pr,
  ...rest
}) => {
  const boxStyles: CSSProperties = {};

  boxStyles.padding = `var(${mapSpacingToCSSVar(pt ?? py ?? p ?? "none")}) 
  var(${mapSpacingToCSSVar(pr ?? px ?? p ?? "none")})
 var(${mapSpacingToCSSVar(pb ?? py ?? p ?? "none")}) var(${mapSpacingToCSSVar(
    pl ?? px ?? p ?? "none"
  )})`;

  return (
    <Component style={boxStyles} {...rest} className={cn(styles.box, className)}>
      {children}
    </Component>
  );
};

export default Box;

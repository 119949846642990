import React, { memo, useState } from "react";
import { Button } from "../../../buttons/Button";
import { createBemFn } from "../../../utilities/bem";
import { isEmailValid } from "../../../utilities/validations";
import { Spinner } from "../../Spinner/Spinner";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { AccountToast } from "../SharedComponents/AccountToast";
import "./ChangePasswordGuide.scss";

interface ChangePasswordGuideProps {
    onContinue: () => Promise<void>;
    email: string;
}

const bem = createBemFn("lxs-change-password-guide");

const strings = {
    requiredField: "This field is required",
    improperEmail: "Please use a valid email address",
    genericErrorTitle: "Something went wrong",
    genericErrorMessage: "We can't contact our servers right now. Please try signing in later.",
    lcacErrorToast: "There is a problem with your account. We apologise for any inconvenience.",
    lcacErrorInstructions: "Please call the Lexus Customer Assistance Centre for more information.",
    lcacErrorPhone: "1800 023 009",
    buttonText: "Okay",
} as const;

const ChangePasswordGuide: React.FC<ChangePasswordGuideProps> = ({ onContinue, email }) => {
    const [error, setError] = useState<Error>();
    const [processing, setProcessing] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true);
        onContinue().catch((err) => {
            setError(err);
            setProcessing(false);
        });
    };

    const handleClose = () => setError(undefined);
    const isUserNotFoundError =
        error instanceof AuthenticationError && error.hasType(AuthenticationErrorType.UserNotFound);

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>BEFORE WE CONTINUE</h2>
            <p>
                For extra security to change your password, we require to verify your identity by sending a one time
                code <strong>{email}</strong>.
            </p>
            <p>Please press continue to verify your identity and change your password.</p>
            {error ? (
                isUserNotFoundError ? (
                    <AccountToast
                        title={strings.genericErrorTitle}
                        message={strings.lcacErrorToast}
                        isOpen={true}
                        buttonText={strings.buttonText}
                        onClose={handleClose}
                        secondMessage={strings.lcacErrorInstructions}
                        phoneNumber={strings.lcacErrorPhone}
                    />
                ) : (
                    <AccountToast
                        title={strings.genericErrorTitle}
                        message={strings.genericErrorMessage}
                        isOpen={!!error}
                        onClose={handleClose}
                        buttonText={strings.buttonText}
                    />
                )
            ) : null}
            <Button className={bem("button")} disabled={processing || !isEmailValid(email)} type="submit">
                Continue
            </Button>
            {processing && <Spinner />}
        </form>
    );
};

const ChangePasswordGuideMemoised = memo(ChangePasswordGuide);

export { ChangePasswordGuideMemoised as ChangePasswordGuide };

import cn from "classnames";
import {
  RadioProps as RadioPropsPrimitiveProps,
  Radio as RadioPrimitive,
} from "react-aria-components";

import styles from "./Radio.module.scss";
import { Stack } from "../Stack";

export interface RadioProps extends RadioPropsPrimitiveProps {
  /**
   * The value of the radio button, used when submitting an HTML form. See MDN.
   */
  value: RadioPropsPrimitiveProps["value"];
  /**
   * Whether the radio button is disabled or not.
   * Shows that a selection exists, but is not available in that circumstance.
   */
  isDisabled?: RadioPropsPrimitiveProps["isDisabled"];
  /** Whether the element should receive focus on render. */
  autoFocus?: RadioPropsPrimitiveProps["autoFocus"];
  children: React.ReactNode;
}

const Radio: React.FC<RadioProps> = ({ children, className, ...props }) => (
  <RadioPrimitive {...props} className={cn(styles.radio, className?.toString())}>
    <Stack direction="row" spacing="xs" alignItems="center" className={styles.stack}>
      <div className={styles.iconContainer}>
        <div className={styles.radioDotContainer}>
          <div className={styles.radioDot}></div>
        </div>
      </div>
      {children}
    </Stack>
  </RadioPrimitive>
);

export { Radio };

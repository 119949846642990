import cn from "classnames";

import { Stack } from "@/lib/index";

import styles from "./HeadingAndText.module.scss";
import { RichText } from "../RichText/RichText";
import { Typography } from "../Typography/Typography";

const TEXT_ALIGNMENT = ["center", "left"] as const;
const STYLE_VARIANTS = ["default", "alt", "static"] as const;
const VARIANTS = ["default", "informational"] as const;

type HeadingAndTextProps = React.PropsWithChildren<{
  textAlignment?: (typeof TEXT_ALIGNMENT)[number];
  Themevariant?: (typeof STYLE_VARIANTS)[number];
  /* Variants available are:
   * default center H2 heading
   * informational left s1 heading
   */
  variant?: (typeof VARIANTS)[number];
  heading?: React.ReactNode;
  html?: React.ReactNode;
}>;

/**
 * Consumers can easily create Heading and Text block by passing heading and text value to `<HeadingAndText>` component.
 * `<HeadingAndText>` is themeable and there are three theme variants available which are primary, alt and static. Text colour will differ for each theme variant passed by consumers.
 */
export const HeadingAndText: React.FC<HeadingAndTextProps> = ({
  variant = "default",
  textAlignment = "left",
  Themevariant = "default",
  heading,
  html,
}) => {
  return (
    <Stack
      direction="column"
      spacing="xs"
      data-testid={`lk-ht-${Themevariant}`}
      alignItems={textAlignment === "left" ? "flex-start" : "center"}
      className={cn(styles.headingAndText, {
        [styles[Themevariant]]: !!Themevariant,
        [styles[textAlignment]]: !!textAlignment,
      })}
    >
      {!!heading && (
        <Typography variant={variant === "informational" ? "s1" : "h2"}>{heading}</Typography>
      )}
      {!!html && (typeof html === "string" ? <RichText html={html} /> : html)}
    </Stack>
  );
};

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGPrinter: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1 11.5C0.7235 11.5 0.5 11.276 0.5 11V5C0.5 4.724 0.7235 4.5 1 4.5H3.5V1C3.5 0.724 3.7235 0.5 4 0.5H10.5625C10.6985 0.5 10.829 0.5555 10.9235 0.654L12.361 2.154C12.45 2.247 12.5 2.371 12.5 2.5V4.5H15C15.2765 4.5 15.5 4.724 15.5 5V11C15.5 11.276 15.2765 11.5 15 11.5H12.5V15C12.5 15.276 12.2765 15.5 12 15.5H4C3.7235 15.5 3.5 15.276 3.5 15V11.5H1ZM11.5 14.5V10H4.5V14.5H11.5ZM4.5 1.5V4.5H11.5V2.701L10.349 1.5H4.5ZM1.5 5.5V10.5H3.5V9.5C3.5 9.224 3.7235 9 4 9H12C12.2765 9 12.5 9.224 12.5 9.5V10.5H14.5V5.5H1.5ZM4 7C4 7.5515 3.5515 8 3 8C2.4485 8 2 7.5515 2 7C2 6.4485 2.4485 6 3 6C3.5515 6 4 6.4485 4 7ZM3 7.5C3.276 7.5 3.5 7.2755 3.5 7C3.5 6.7245 3.276 6.5 3 6.5C2.724 6.5 2.5 6.7245 2.5 7C2.5 7.2755 2.724 7.5 3 7.5ZM5.75 13.5C5.612 13.5 5.5 13.388 5.5 13.25C5.5 13.112 5.612 13 5.75 13H10.25C10.388 13 10.5 13.112 10.5 13.25C10.5 13.388 10.388 13.5 10.25 13.5H5.75ZM5.75 12.5C5.612 12.5 5.5 12.388 5.5 12.25C5.5 12.112 5.612 12 5.75 12H10.25C10.388 12 10.5 12.112 10.5 12.25C10.5 12.388 10.388 12.5 10.25 12.5H5.75ZM5.75 11.5C5.612 11.5 5.5 11.388 5.5 11.25C5.5 11.112 5.612 11 5.75 11H10.25C10.388 11 10.5 11.112 10.5 11.25C10.5 11.388 10.388 11.5 10.25 11.5H5.75Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGPrinter = React.memo(SVGPrinter);

export { SVGPrinter };

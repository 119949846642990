export class SessionTimeoutError extends Error {
    private _closeHandler?: () => void;
    private _messageDisplayed?: string;
    private _title?: string;

    public constructor(closeHandler?: () => void, messageDisplayed?: string, title?: string) {
        super();
        this._closeHandler = closeHandler;
        this._messageDisplayed = messageDisplayed;
        this._title = title;

        // Set the prototype explicitly.
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, SessionTimeoutError.prototype);
    }

    public get closeHandler() {
        return this._closeHandler;
    }

    public get messageDisplayed() {
        return this._messageDisplayed;
    }

    public get title() {
        return this._title;
    }
}

type MenuItemWithLink = Omit<LXS.AnyMenuItem, "link"> & Required<Pick<LXS.AnyMenuItem, "link">>;

export const hasLink = (item?: LXS.AnyMenuItem): item is MenuItemWithLink => !!item?.link;

export const isMenuItemWithSubItems = (item?: LXS.AnyMenuItem): item is LXS.MenuItemWithSubItems =>
    Boolean(item && "menuItems" in item && item.menuItems?.length);

export const isGetInTouchItem = (item?: LXS.AnyMenuItem): item is LXS.GetInTouchMenuItem =>
    item?.link?.value.querystring?.toLowerCase() === "getintouch";

export const isNotGetInTouchItem = (item?: LXS.AnyMenuItem): item is Exclude<LXS.AnyMenuItem, LXS.GetInTouchMenuItem> =>
    !isGetInTouchItem(item);

import * as React from "react";
import { createBemFn } from "../../../utilities/bem";
import { SVGChevronLeft } from "../../SVGIcons/icons/static/SVGChevronLeft";
import "./BackButton.scss";

export const bem = createBemFn("lxs-account-back");

const BackButton: React.FC<{ onBack?: () => void }> = ({ onBack }) => (
    <div className={bem()}>
        {onBack && (
            <button onClick={onBack} className={bem("button")}>
                <SVGChevronLeft className={bem("chevron")} />
            </button>
        )}
    </div>
);

export { BackButton };

import * as React from "react";
import "./Logo.scss";
import { useGTM } from "../GTM/useGTM";

const Logo: React.FC<LXS.LogoProps> = ({ src, description = "", url = "/" }) => {
    const { gtmClick } = useGTM({ target: "Logo", action: url });
    return (
        <a className="lxs-logo" href={url} title={description} onClick={gtmClick}>
            {src && <img className="lxs-logo__image" src={src} alt={description} />}
        </a>
    );
};

export { Logo };

import * as React from "react";
import "./ModelsMenuDesktopModelGrid.scss";
import classnames from "classnames";
import { ImageCardMemoised } from "../../../ImageCard/ImageCard";
import { createBemFn } from "../../../../utilities/bem";

interface ModelsMenuDesktopModelProps {
    vehicleModel: LXS.VehicleModel;
    index: number;
}

interface ModelsMenuDesktopModelGridProps {
    vehicleModels: LXS.VehicleModel[];
}

const bem = createBemFn("lxs-models-menu-desktop-model-grid");

const ModelsMenuDesktopModel: React.FC<ModelsMenuDesktopModelProps> = ({ vehicleModel, index }) => {
    const row = Math.floor(index / 2) + 1;
    const gridStyle = {
        gridRow: row,
        msGridRow: row,
    };

    return (
        <div
            className={classnames({ [bem("item-first")]: index % 2 === 0 }, { [bem("item-second")]: index % 2 !== 0 })}
            style={gridStyle}
        >
            <ImageCardMemoised
                title={vehicleModel.modelCode}
                subtitle={vehicleModel.modelName}
                image={vehicleModel.menuImage}
                hoverImage={vehicleModel.menuHoverImage}
                ctaLink1={vehicleModel.ctaLink1}
                ctaLink2={vehicleModel.ctaLink2}
                path={vehicleModel.path}
                stickerTags={vehicleModel.stickerTags}
                promotionSticker={vehicleModel.hasOnOffer ? vehicleModel.onOfferTitle : undefined}
            />
        </div>
    );
};

const ModelsMenuDesktopModelGrid: React.FC<ModelsMenuDesktopModelGridProps> = ({ vehicleModels }) => (
    <div className={bem()}>
        {vehicleModels.map((vm, index) => (
            <ModelsMenuDesktopModel key={vm.modelCode.value} index={index} vehicleModel={vm} />
        ))}
    </div>
);

export { ModelsMenuDesktopModelGrid };

import { GenericData, GraphqlResultsWrapper, IconData } from "./graphqlResponse";

export const ServiceType = {
  Main: "main",
  Sales: "sales",
  Service: "service",
} as const;

export type ServiceTypes = (typeof ServiceType)[keyof typeof ServiceType];

export type DealerConfig = {
  dealerCode?: GenericData;
  addressIcon?: IconData;
  phoneIcon?: IconData;
  emailIcon?: IconData;
  openingHoursIcon?: IconData;
  openingHoursLabel?: GenericData;
  publicHolidayHoursLabel?: GenericData;
  salesLabel?: GenericData;
  bookTestDriveLabel?: GenericData;
  bookTestDriveUrl?: GenericData;
  serviceLabel?: GenericData;
  bookServiceLabel?: GenericData;
  bookServiceUrl?: GenericData;
  gtmId?: GenericData;
  gmApiKey?: GenericData;
  branches?: GraphqlResultsWrapper<BranchConfig>;
};

export type Service = {
  openingHours: OpeningHours[];
  name: ServiceTypes;
};

export type OpeningHours = {
  isClosed: boolean;
  day: string;
  openingTime: string;
  closingTime: string;
};

export type Address = {
  postCode: string;
  state: string;
  streetAddress: string;
  suburb: string;
};

export type Email = {
  [key in ServiceTypes]: string;
};

export type Phone = {
  [key in ServiceTypes]: string;
};

export type DealerLocation = {
  dealerId: string;
  branchCode: string;
  serviceTypes: Service[];
  address: Address;
  email: Email;
  locationCode: string;
  locationName: string;
  phone: Phone;
  sapCode: string;
  lat: number;
  lng: number;
};

export type Dealer = {
  dealerCode: string;
  dealerName: string;
  sapDealerCode: string;
  locations: DealerLocation[];
};

export type BranchConfig = {
  branchCode?: GenericData;
  salesPublicHolidayDetails?: GenericData;
  servicePublicHolidayDetails?: GenericData;
};

import * as React from "react";
import { createBemFn } from "../../utilities/bem";
import classnames from "classnames";
import "./Collapse.scss";
import { Spinner } from "../Spinner/Spinner";

const bem = createBemFn("collapse");
const transitionTimeDefault = 1000;

interface CollapseProps {
    header: React.ReactNode;
    body: React.ReactNode;
    open?: boolean;
    transitionTime?: number;
    className?: string;
    isBodyIframe?: boolean;
    onIframeLoad?: boolean;
}

interface BodyStyle {
    height: number;
    overflow: "hidden" | "visible";
}

const Collapse: React.FunctionComponent<CollapseProps> = ({
    header,
    body,
    open = false,
    transitionTime = transitionTimeDefault,
    className,
    isBodyIframe,
    onIframeLoad,
}) => {
    const [style, setStyle] = React.useState<BodyStyle>({
        height: 0,
        overflow: "hidden",
    });
    const bodyInnerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        let timeoutHandle = 0;
        let height;

        if (open && !isBodyIframe) {
            height = bodyInnerRef.current ? bodyInnerRef.current.clientHeight : 0;

            setStyle({ height, overflow: "hidden" });
            timeoutHandle = window.setTimeout(() => {
                setStyle((currentStyle) => ({ ...currentStyle, overflow: "visible" }));
            }, transitionTime);
        } else if (open && isBodyIframe && onIframeLoad) {
            height = bodyInnerRef.current ? bodyInnerRef.current.clientHeight : 0;
            setStyle({ height, overflow: "visible" });
        } else {
            setStyle({ height: 0, overflow: "hidden" });
        }
        return () => window.clearTimeout(timeoutHandle);
    }, [open, onIframeLoad, isBodyIframe]);

    return (
        <div className={classnames(bem(), { [bem(undefined, "open")]: open }, className)}>
            <div className={bem("header")}>
                {header}
                {open && !onIframeLoad && <Spinner />}
            </div>
            <div
                className={classnames(bem("body"), { [bem("body", "open")]: open })}
                style={{ ...style, transition: `height ${transitionTime}ms` }}
            >
                <div className={bem("body-inner")} ref={bodyInnerRef}>
                    {body}
                </div>
            </div>
        </div>
    );
};

export { Collapse };

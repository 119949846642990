import {
    FetchAction,
    fetchActionPayloadToContainer,
    fetchActionPayloadToFetchStatusAndError,
    initialFetchContainer,
    initialFetchStatusAndError,
} from "../../helpers/fetch";
import { handleActions } from "redux-actions";
import { RootLevelAction } from "../rootLevelAction";
import * as I from "./onDemandInterfaces";
import { OnDemandAction } from "./onDemandConstants";

export const getVehicleFeaturesText = (vehicle: I.OnDemandVehicle): string => {
    const defaultValue = "";
    if (vehicle) {
        try {
            return vehicle.features
                .map(f => f.name)
                .reduce((currentFeature, nextFeature) => `${currentFeature}, ${nextFeature}`);
        } catch {
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
};

const keazUserPayloadToKeazUser = (user: I.KeazUserPayload): I.KeazUser => {
    const licenceExpiryDay = parseInt(user.licenceExpiryDay);
    const licenceExpiryMonth = parseInt(user.licenceExpiryMonth);
    const licenceExpiryYear = parseInt(user.licenceExpiryYear);
    return {
        isNew: user.isNew,
        id: user.id,
        name: user.name,
        licenceNumber: user.licenceNumber,
        licenceState: user.licenceState,
        licenceExpiryDay: !isNaN(licenceExpiryDay) ? licenceExpiryDay : undefined,
        licenceExpiryMonth: !isNaN(licenceExpiryMonth) ? licenceExpiryMonth : undefined,
        licenceExpiryYear: !isNaN(licenceExpiryYear) ? licenceExpiryYear : undefined,
    };
};

export const findLocationById = (
    id: string | undefined,
    locations: I.Location[] | undefined
): I.Location | undefined => {
    if (id && locations) {
        return locations.find(location => {
            return location.id === id;
        });
    } else {
        return undefined;
    }
};

//#region state
export const initialOnDemandState: I.OnDemand = {
    locations: initialFetchContainer,
    publicHolidays: initialFetchContainer,
    updateUser: initialFetchStatusAndError,
    getAccountInfo: initialFetchStatusAndError,
    bookingInProgress: {
        selectedLocation: undefined,
        selectedVehicle: undefined,
        pickUpDateTime: undefined,
        dropOffDateTime: undefined,
        returnFlightNumber: undefined,
        departureFlightNumber: undefined,
    },
    makeBooking: initialFetchContainer,
    vehicles: initialFetchContainer,
    bookingHistory: initialFetchContainer,
    processingUpdateUser: false,
    cancelBooking: initialFetchContainer,
    updateBooking: initialFetchContainer,
};
//#endregion

//#region reducers
const getAccountInfoReducer: I.OnDemandReducer<I.GetAccountInfoFetchActionPayload> = (state, action) => ({
    ...state,
    getAccountInfo: fetchActionPayloadToFetchStatusAndError(action.payload),
    user:
        action.payload.action === FetchAction.Success
            ? keazUserPayloadToKeazUser(action.payload.value.user)
            : state.user,
    accountInfo:
        action.payload.action === FetchAction.Success
            ? {
                  redemptionsRemaining: action.payload.value.redemptionsRemaining,
                  offerId: action.payload.value.offerId,
                  token: action.payload.value.token,
              }
            : state.accountInfo,
});

const setSelectedLocationReducer: I.OnDemandReducer<string> = (state, action) => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        selectedLocation: action.payload,
    },
    vehicles: initialOnDemandState.vehicles,
});

const fetchLocationsReducer: I.OnDemandReducer<I.FetchLocationsPayload> = (state, action) => ({
    ...state,
    locations: fetchActionPayloadToContainer(action.payload),
});

const fetchPublicHolidaysReducer: I.OnDemandReducer<I.FetchPublicHolidayPayload> = (state, action) => ({
    ...state,
    publicHolidays: fetchActionPayloadToContainer(action.payload),
});

const updateUserReducer: I.OnDemandReducer<I.UpdateUserFetchActionPayload> = (state, action) => {
    const newState = {
        ...state,
        updateUser: fetchActionPayloadToFetchStatusAndError(action.payload),
    };
    if (action.payload.action === FetchAction.Success) {
        newState.user = keazUserPayloadToKeazUser(action.payload.value);
    }

    return newState;
};

const makeBookingReducer: I.OnDemandReducer<I.MakeBookingFetchActionPayload> = (state, action) => ({
    ...state,
    makeBooking: fetchActionPayloadToContainer(action.payload),
});

const setBookingInProgressFlightNumbersReducer: I.OnDemandReducer<I.SetBookingInProgressFlightNumbersPayload> = (
    state,
    action
) => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        returnFlightNumber: action.payload.returnFlightNumber,
        departureFlightNumber: action.payload.departureFlightNumber,
    },
});

const resetBookingInProgressFlightNumbersReducer: I.OnDemandReducer = state => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        returnFlightNumber: undefined,
        departureFlightNumber: undefined,
    },
});

const setBookingInProgressPickUpAndDropOffDateTimesReducer: I.OnDemandReducer<
    I.SetBookingInProgressPickUpAndDropOffDateTimesPayload
> = (state, action) => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        pickUpDateTime: action.payload.pickUpDateTime,
        dropOffDateTime: action.payload.dropOffDateTime,
    },
    vehicles: initialOnDemandState.vehicles,
});

const resetBookingInProgressPickUpAndDropOffDateTimesReducer: I.OnDemandReducer = state => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        pickUpDateTime: undefined,
        dropOffDateTime: undefined,
        selectedVehicle: undefined,
    },
});

const fetchOnDemandVehiclesReducer: I.OnDemandReducer<I.OnDemandVehiclesFetchActionPayload> = (state, action) => ({
    ...state,
    vehicles: fetchActionPayloadToContainer(action.payload),
});

const setSelectedVehicleReducer: I.OnDemandReducer<string> = (state, action) => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        selectedVehicle: action.payload,
    },
});

const resetSelectedVehicleReducer: I.OnDemandReducer = state => ({
    ...state,
    bookingInProgress: {
        ...state.bookingInProgress,
        selectedVehicle: undefined,
    },
});

const resetBookVehicleReducer: I.OnDemandReducer = state => ({
    ...state,
    bookingInProgress: initialOnDemandState.bookingInProgress,
    makeBooking: initialOnDemandState.makeBooking,
    vehicles: initialOnDemandState.vehicles,
});

const getBookingHistoryReducer: I.OnDemandReducer<I.GetBookingHistoryFetchActionPayload> = (state, action) => ({
    ...state,
    bookingHistory: fetchActionPayloadToContainer(action.payload),
});

const resetCancelBookingReducer: I.OnDemandReducer = state => ({
    ...state,
    cancelBooking: initialOnDemandState.cancelBooking,
});

const cancelBookingReducer: I.OnDemandReducer<I.CancelBookingFetchActionPayload> = (state, action) => ({
    ...state,
    cancelBooking: fetchActionPayloadToContainer(action.payload),
});

const resetBookingHistoryReducer: I.OnDemandReducer = state => ({
    ...state,
    bookingHistory: initialOnDemandState.bookingHistory,
});

const setCurrentBookingReducer: I.OnDemandReducer<I.CurrentBooking> = (state, action) => ({
    ...state,
    currentBooking: action.payload,
});

const resetCurrentBookingReducer: I.OnDemandReducer = state => ({
    ...state,
    currentBooking: undefined,
});

const initializeBookingInProgress: I.OnDemandReducer<I.InitializeBookingInProgressActionPayload> = (state, action) => {
    const {
        selectedLocation,
        selectedVehicle,
        pickUpDateTime,
        dropOffDateTime,
        returnFlightNumber,
        departureFlightNumber,
    } = action.payload;
    return {
        ...state,
        bookingInProgress: {
            ...initialOnDemandState.bookingInProgress,
            selectedVehicle,
            selectedLocation,
            dropOffDateTime,
            pickUpDateTime,
            returnFlightNumber,
            departureFlightNumber,
        },
    };
};

const updateBookingReducer: I.OnDemandReducer<I.UpdateBookingFetchActionPayload> = (state, action) => ({
    ...state,
    updateBooking: fetchActionPayloadToContainer(action.payload),
});

const resetReducer: I.OnDemandReducer<undefined> = () => initialOnDemandState;

export const onDemandReducer = handleActions<I.OnDemand, I.ReducerPayload>(
    {
        [OnDemandAction.GetAccountInfo]: getAccountInfoReducer,
        [OnDemandAction.FetchLocations]: fetchLocationsReducer,
        [OnDemandAction.UpdateUser]: updateUserReducer,
        [OnDemandAction.FetchPublicHolidays]: fetchPublicHolidaysReducer,
        [OnDemandAction.SetSelectedLocation]: setSelectedLocationReducer,
        [OnDemandAction.SetBookingInProgressPickUpAndDropOffDateTimes]:
            setBookingInProgressPickUpAndDropOffDateTimesReducer,
        [OnDemandAction.ResetBookingInProgressPickUpAndDropOffDateTimes]:
            resetBookingInProgressPickUpAndDropOffDateTimesReducer,
        [OnDemandAction.SetBookingInProgressFlightNumbers]: setBookingInProgressFlightNumbersReducer,
        [OnDemandAction.ResetBookingInProgressFlightNumbers]: resetBookingInProgressFlightNumbersReducer,
        [OnDemandAction.MakeBooking]: makeBookingReducer,
        [OnDemandAction.FetchOnDemandVehicles]: fetchOnDemandVehiclesReducer,
        [OnDemandAction.SetSelectedVehicle]: setSelectedVehicleReducer,
        [OnDemandAction.ResetSelectedVehicle]: resetSelectedVehicleReducer,
        [OnDemandAction.ResetBookVehicle]: resetBookVehicleReducer,
        [OnDemandAction.GetBookingHistory]: getBookingHistoryReducer,
        [OnDemandAction.ResetCancelBooking]: resetCancelBookingReducer,
        [OnDemandAction.CancelBooking]: cancelBookingReducer,
        [OnDemandAction.ResetBookingHistory]: resetBookingHistoryReducer,
        [OnDemandAction.InitializeBookingInProgress]: initializeBookingInProgress,
        [OnDemandAction.SetCurrentBooking]: setCurrentBookingReducer,
        [OnDemandAction.ResetCurrentBooking]: resetCurrentBookingReducer,
        [OnDemandAction.UpdateBooking]: updateBookingReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialOnDemandState
);
//#endregion

import { Renderer } from "@googlemaps/markerclusterer";

export type ClusterStyles = Renderer;
export type DirectionsRequest = google.maps.DirectionsRequest;
export type Directions = google.maps.DirectionsResult;

export type MarkerContentFactory = () => React.ReactElement;

export interface LexusMapDirections {
  directions?: Directions;
  origin: LexusMapCoordinates;
  destination?: LexusMapCoordinates;
}
export interface LexusMapDirectionsWithIcons extends LexusMapDirections {
  originIcon?: MarkerIcon;
  destinationIcon?: MarkerIcon;
}

export interface Evses {
  availability?: string;
  connectors?: Connectors[];
}
export interface Connectors {
  plugType?: string;
  supportedLexusModels?: string[];
}

export interface MarkerIcon {
  default?: string;
  clicked?: string;
  iconWidth?: number;
  iconHeight?: number;
  iconOriginX?: number;
  iconOriginY?: number;
  iconAnchorX?: number;
  iconAnchorY?: number;
  iconTitle?: string;
}

export interface Marker {
  name?: string;
  lat: number;
  lng: number;
  icon?: MarkerIcon;
  label?: string | MarkerLabel;
  address?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  evses?: Evses[];
  clickHandler?: () => void;
  getMarkerContent?: MarkerContentFactory;
  lastUpdated?: string;
  neverClustered?: boolean;
  ignoreWhenCalcResizeBounds?: boolean;
}

export type MarkerLabel = google.maps.MarkerLabel;

export interface LexusMapCoordinates {
  lat: number;
  lng: number;
}

export interface MapDetails {
  center?: LexusMapCoordinates;
  zoom?: number;
  mapTypeId?: string;
  mapTypeControl?: boolean;
}

export enum LexusMapStyle {
  Default = "google",
  Lexus = "lexus",
}

export interface LexusMapParams {
  shouldResize?: boolean;
  markers: Marker[];
  mapDetails?: MapDetails;
  directions?: LexusMapDirectionsWithIcons;
  mapStyle?: LexusMapStyle;
  clusterStyles?: Renderer;
  markerCallback?: (value: boolean) => void;
}

export enum DefaultDistanceLimits {
  MAIN = 5000,
  FALLBACK = 20000,
}

export enum MapTheme {
  DARK = "lk-theme-dark",
  LIGHT = "lk-theme-light",
}

export interface MapState {
  map: google.maps.Map;
  directionRenderer: google.maps.DirectionsRenderer;
}

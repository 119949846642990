import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGOpenInNew: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33333 3.33333V12.6667H12.6667V8H14V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H8V3.33333H3.33333ZM9.33341 3.33333V2H14.0001V6.66667H12.6667V4.27333L6.11341 10.8267L5.17341 9.88667L11.7267 3.33333H9.33341Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGOpenInNew = React.memo(SVGOpenInNew);

export { SVGOpenInNew };

import { PromiseThunk, Thunk } from "../../helpers/fetch";
import { createAction } from "redux-actions";
import { parseKeazTimes } from "../../helpers/keaz";
import {
    differenceInBusinessDays,
    epochAndOffsetToDate,
    isTimeWithinInterval,
    jSDayNumberToKeazDayNumber,
    minutesInHour,
    dateWithOffset,
} from "../../helpers/dateTime";
import { AppState } from "../index";
import moment from "moment";
import { settingsPromise } from "../../settings";
import { findLocationById } from "./onDemandReducers";
import { BookVehicleStatus, OnDemandAction } from "./onDemandConstants";
import { fetchOnDemandVehiclesThunk } from "./onDemandThunks";
import * as I from "./onDemandInterfaces";
import * as S from "./onDemandSelectors";

export const getAccountInfoAction = createAction<I.GetAccountInfoFetchActionPayload>(OnDemandAction.GetAccountInfo);

export const validateSelectedVehicleThunk: PromiseThunk<boolean> = async (dispatch, getState) => {
    const selectedVehicleId = S.bookingInProgressSelectedVehicleIdSelector(getState());
    if (selectedVehicleId) {
        await dispatch(fetchOnDemandVehiclesThunk);
        const selectedVehicle = S.selectedVehicleSelector(getState());
        return selectedVehicle !== undefined && selectedVehicle.status !== BookVehicleStatus.Unavailable;
    }
    return true;
};
const isTimeValidForLocation = (location: I.Location, time: Date): boolean => {
    const dayToInterval = parseKeazTimes(location.businessHours);
    const dayInterval = dayToInterval[jSDayNumberToKeazDayNumber(time.getDay())];
    const timeInMinutes = time.getHours() * minutesInHour + time.getMinutes();
    return isTimeWithinInterval(timeInMinutes, dayInterval);
};
const isSelectedPickupAndDropOffTimesValidForLocation = (location: I.Location, state: AppState): boolean => {
    const pickupTime = S.bookingInProgressPickUpDateTimeSelector(state);
    const dropOffTime = S.bookingInProgressDropOffDateTimeSelector(state);
    if (pickupTime && dropOffTime) {
        const isValidPickupTime = isTimeValidForLocation(location, pickupTime);
        const isValidDropOffTime = isTimeValidForLocation(location, dropOffTime);
        return isValidPickupTime && isValidDropOffTime;
    }
    return false;
};

export const setSelectedLocationAction = createAction<string>(OnDemandAction.SetSelectedLocation);
export const setSelectedLocationThunk: (locationId: string) => PromiseThunk<void> = locationId => {
    return async (dispatch, getState) => {
        dispatch(setSelectedLocationAction(locationId));

        const locations = S.onDemandLocationsSelector(getState());
        const location = findLocationById(locationId, locations);
        if (location) {
            if (isSelectedPickupAndDropOffTimesValidForLocation(location, getState())) {
                await dispatch(validateSelectedVehicleThunk);
            } else {
                dispatch(resetBookingInProgressPickUpAndDropOffDateTimesAction());
            }
        }
    };
};

export const fetchLocationsAction = createAction<I.FetchLocationsPayload>(OnDemandAction.FetchLocations);
export const fetchOnDemandVehiclesAction = createAction<I.OnDemandVehiclesFetchActionPayload>(
    OnDemandAction.FetchOnDemandVehicles
);
export const fetchPublicHolidaysAction = createAction<I.FetchPublicHolidayPayload>(OnDemandAction.FetchPublicHolidays);

export const setSelectedVehicleAction = createAction<string>(OnDemandAction.SetSelectedVehicle);
export const resetSelectedVehicleAction = createAction(OnDemandAction.ResetSelectedVehicle);
export const updateUserAction = createAction<I.UpdateUserFetchActionPayload>(OnDemandAction.UpdateUser);

export const setBookingInProgressPickUpAndDropOffDateTimesAction =
    createAction<I.SetBookingInProgressPickUpAndDropOffDateTimesPayload>(
        OnDemandAction.SetBookingInProgressPickUpAndDropOffDateTimes
    );

export const setBookingInProgressPickUpAndDropOffDateTimesThunk: (
    payload: I.SetBookingInProgressPickUpAndDropOffDateTimesPayload
) => PromiseThunk<boolean> = payload => {
    return dispatch => {
        dispatch(setBookingInProgressPickUpAndDropOffDateTimesAction(payload));
        return dispatch(validateSelectedVehicleThunk);
    };
};

export const resetBookingInProgressPickUpAndDropOffDateTimesAction = createAction(
    OnDemandAction.ResetBookingInProgressPickUpAndDropOffDateTimes
);

export const setBookingInProgressFlightNumbersAction = createAction<I.SetBookingInProgressFlightNumbersPayload>(
    OnDemandAction.SetBookingInProgressFlightNumbers
);

export const resetBookingInProgressFlightNumbersAction = createAction(
    OnDemandAction.ResetBookingInProgressFlightNumbers
);

export const makeBookingAction = createAction<I.MakeBookingFetchActionPayload>(OnDemandAction.MakeBooking);

export const resetBookVehicleAction = createAction(OnDemandAction.ResetBookVehicle);

export const getBookingHistoryAction = createAction<I.GetBookingHistoryFetchActionPayload>(
    OnDemandAction.GetBookingHistory
);

export const resetCancelBookingAction = createAction(OnDemandAction.ResetCancelBooking);
export const cancelBookingAction = createAction<I.CancelBookingFetchActionPayload>(OnDemandAction.CancelBooking);
export const updateBookingAction = createAction<I.UpdateBookingFetchActionPayload>(OnDemandAction.UpdateBooking);

export const resetBookingHistoryAction = createAction(OnDemandAction.ResetBookingHistory);
const setCurrentBookingAction = createAction<I.CurrentBooking>(OnDemandAction.SetCurrentBooking);

export const resetCurrentBookingAction = createAction(OnDemandAction.ResetCurrentBooking);
const initializeBookingInProgressAction = createAction<I.InitializeBookingInProgressActionPayload>(
    OnDemandAction.InitializeBookingInProgress
);

export const initializeUpdateBookingThunk: (booking: I.Booking) => Thunk<void> = booking => async dispatch => {
    const settings = await settingsPromise();
    const currentBooking = booking as I.CurrentBooking;

    const pickUpDate = epochAndOffsetToDate(currentBooking.start, currentBooking.startOffset);
    const currentTime = moment();
    const difference = differenceInBusinessDays(currentTime, pickUpDate);
    currentBooking.isWithinMinimumDays = difference <= settings.onDemand.minTimeBeforeBookingInDays;

    dispatch(setCurrentBookingAction(currentBooking));
    dispatch(
        initializeBookingInProgressAction({
            selectedVehicle: booking.vehicle.id,
            selectedLocation: booking.location.id,
            pickUpDateTime: dateWithOffset(booking.start, booking.startOffset),
            dropOffDateTime: dateWithOffset(booking.end, booking.endOffset),
            returnFlightNumber: booking.returnFlightNumber ?? "",
            departureFlightNumber: booking.departureFlightNumber ?? "",
        })
    );
};
//#endregion

import { Children } from "react";
import {
  withGroupedChildren,
  type WithGroupedChildrenComponent,
  type GroupedChildrenProps,
} from "react-grouped-children";

import styles from "./Oriented.module.scss";

type OrientedContainerProps = React.PropsWithChildren<{
  /**
   * The specified default orientation will be shown by default and hidden only
   * with when the opposite container works. Since not all mobile browsers support
   * container queries prefer mobile-like orientation
   */
  defaultOrientation?: "landscape" | "portrait";

  /**
   * Use this property to display all children and ignore orientation rules.
   * Helpful when you need to display component in editable mode
   */
  showAll?: boolean;
}>;

const OrientedContainer: React.FC<
  OrientedContainerProps & GroupedChildrenProps<typeof childrenSpec>
> = ({ landscape, portrait, defaultOrientation, showAll }) => {
  const landscapeChildren = Children.toArray(landscape);
  const portraitChildren = Children.toArray(portrait);

  const getClass = (type: "landscape" | "portrait"): string => {
    return showAll
      ? styles.all
      : defaultOrientation === type
      ? styles[`${type}Default`]
      : styles[type];
  };

  return (
    <>
      {!!landscapeChildren.length && (
        <div className={getClass("landscape")}>{landscapeChildren}</div>
      )}
      {!!portraitChildren.length && <div className={getClass("portrait")}>{portraitChildren}</div>}
    </>
  );
};

const childrenSpec = {
  Landscape: null,
  Portrait: null,
} as const;

const childrenMapperAndValidator = (children: React.ReactNode | React.ReactNode[]) => {
  const uniqueSet = new Set();
  const filtered = Children.toArray(children).filter((child) => {
    if (
      typeof child === "object" &&
      "type" in child &&
      (child.type === Oriented.Landscape || child.type === Oriented.Portrait) &&
      !uniqueSet.has(child.type)
    ) {
      uniqueSet.add(child.type);
      return true;
    } else {
      console.error(
        `Oriented component just accepts single instance of Oriented.Landscape or Oriented.Portrait as children! Unexpected:`,
        child
      );
      return false;
    }
  });
  return filtered;
};

const Oriented: WithGroupedChildrenComponent<typeof childrenSpec, OrientedContainerProps> =
  withGroupedChildren({
    childrenSpec,
    childrenToArray: childrenMapperAndValidator,
  })(OrientedContainer);

export { Oriented };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGAccordion: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 32V0H18V32H14Z" fill={color}></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.74846e-07 14L32 14V18L0 18L1.74846e-07 14Z"
            fill={color}
        ></path>
    </svg>
    /* eslint-enable max-len */
);
SVGAccordion = React.memo(SVGAccordion);

export { SVGAccordion };

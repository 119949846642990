import cn from "classnames";

import { Responsive } from "@/components/Responsive/Responsive";

import styles from "./PrimaryNavNavbarItemSpecial.module.scss";
import { Level1MenuItemData } from "./types/types";

interface PrimaryNavItemEncoreProps {
  icon?: React.ReactNode;
  menuItem: Level1MenuItemData;
  isMenuItemActive?: boolean;
  onClick: (level1MenuItem: Level1MenuItemData) => void;
}

const PrimaryNavNavbarItemSpecial: React.FC<PrimaryNavItemEncoreProps> = ({
  menuItem,
  icon,
  onClick,
  isMenuItemActive,
}) => {
  return (
    <button
      className={cn(styles.navbarItemSpecial, { [styles.isActive]: isMenuItemActive })}
      onClick={() => onClick(menuItem)}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>
        <Responsive breakpoint="large">{menuItem.label}</Responsive>
        <Responsive breakpoint="small">{menuItem.mobileLabel || menuItem.label}</Responsive>
      </span>
    </button>
  );
};

export { PrimaryNavNavbarItemSpecial };

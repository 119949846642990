import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGLexus: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M34.8617 8.6285L33.8403 10.1776C40.7148 13.0374 43.4646 18.2009 43.4646 23.7617C43.4646 26.5421 42.7182 29.0444 41.2647 29.0444C36.5115 29.0444 26.298 29.0444 23.2733 29.0444C20.8377 29.0444 20.0914 28.1706 21.1913 25.9463C23.1554 22.014 29.0478 14.507 32.7011 9.97897C33.2511 9.30374 33.6832 8.27103 31.7583 7.75467C29.7942 7.23832 26.6516 7 23.9804 7C10.4278 7.03972 1 14.4276 1 24C1 32.778 9.1708 41 23.9411 41C30.462 41 38.4757 39.1332 42.9932 33.9299H39.1042C34.6652 37.9813 28.93 38.8949 23.9804 38.8949C12.0777 38.8949 4.53544 32.6192 4.53544 24C4.53544 16.0164 13.0598 8.86682 23.8232 8.86682C25.1196 8.86682 25.5517 9.14486 24.1768 10.8528C21.427 14.3084 16.5167 21.1799 14.199 25.1916C11.7242 29.4813 14.2383 32.9766 19.8164 32.9766C23.7447 32.9766 37.8471 32.9766 42.3646 32.9766C43.7395 32.9766 44.5645 32.1822 45.3501 30.7126C46.0179 29.5607 47 27.1776 47 24C47 17.5257 42.2075 11.5678 34.8617 8.6285Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGLexus = React.memo(SVGLexus);

export { SVGLexus };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGChevronDown: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M25 8.7L27.8 11.5 16 23.3 4.2 11.5 7 8.7 16 17.7 25 8.7Z" fill={color} />
    </svg>
);
SVGChevronDown = React.memo(SVGChevronDown);

export { SVGChevronDown };

import "lexus-style-guide/icons/load-icons.scss";
import "lexus-style-guide/typography/load-fonts.scss";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { initialState, rootReducer } from "./reduxSlices";

const composeEnhancers =
    (typeof process !== "undefined" &&
        process.env?.ENVIRONMENT !== "production" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

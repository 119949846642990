import * as React from "react";
import "./PrimaryNav.scss";
import { Logo } from "../Logo/Logo";
import { GTMComponentContext } from "../GTM/GTMComponentContext";
import { PrimaryNavGrid } from "./PrimaryNav";

const SimpleHeader: React.FC<LXS.SimpleHeaderProps> = ({ logo, children }) => (
    <GTMComponentContext.Provider value={{ component: "SimpleHeader" }}>
        <div className="lxs-primary-nav__row">
            <PrimaryNavGrid>
                <div />
                <div>
                    <Logo {...logo} />
                </div>
                <div />
            </PrimaryNavGrid>
        </div>
        {children}
    </GTMComponentContext.Provider>
);

const SimpleHeaderMemoized = React.memo(SimpleHeader);

export { SimpleHeaderMemoized as SimpleHeader };

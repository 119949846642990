import { createContext, useMemo } from "react";

import { AuthenticationConfig, AuthenticationContextData } from "./authentication.types";
import { useCurrentUser } from "./hooks/useCurrentUser";
import { useLoggedIn } from "./hooks/useLoggedIn";
import { useLoggingOut } from "./hooks/useLoggingOut";
import { useLoginButtonVisible } from "./hooks/useLoginButtonVisible";
import { useRefreshTokenRequired } from "./hooks/useRefreshTokenRequired";
import { doLogout } from "./utils";

export type AuthenticationContextProps = React.PropsWithChildren<{
  /**
   * You must provide a memoized function
   */
  onLogout?: () => void;
  /**
   * You must provide a memoized function
   */
  onLogin?: (idToken: string) => void;
  config?: AuthenticationConfig;
}>;

export const AuthenticationContext = createContext<AuthenticationContextData>({
  doLogout,
  current: {
    isLoggingOut: false,
    isRefreshTokenRequired: true,
    user: undefined,
    isLoginButtonVisible: true,
  },
});

export const AuthenticationContextProvider: React.FC<AuthenticationContextProps> = ({
  onLogout,
  onLogin,
  config,
  children,
}) => {
  const user = useCurrentUser();
  const isLoggingOut = useLoggingOut(onLogout, config);
  const isRefreshTokenRequired = useRefreshTokenRequired(user, config);
  const isLoginButtonVisible = useLoginButtonVisible(user, config);
  const providerValue: AuthenticationContextData = useMemo(
    () => ({
      config,
      current: {
        isLoggingOut,
        isRefreshTokenRequired,
        isLoginButtonVisible,
        user,
      },
      doLogout: () => doLogout(onLogout, config),
    }),
    [config, isLoggingOut, isRefreshTokenRequired, isLoginButtonVisible, onLogout, user]
  );

  useLoggedIn(onLogin);

  return (
    <AuthenticationContext.Provider value={providerValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const toSitecoreFocalpointImage = (obj: LXS.FocalpointImageFieldValue): LXS.FocalpointImageProps => {
    return { field: { value: obj } };
};

export const toSitecoreImage = (obj: SCJSS.ImageFieldValue): SCJSS.ImageProps => {
    return toSitecoreFocalpointImage(obj);
};

export const toSitecoreText = (val: string): SCJSS.TextProps => {
    return { field: { value: val } };
};

export const toSitecoreLinkButton = (val: SCJSS.LinkFieldValue): LXS.Button.LinkButtonProps => {
    return { field: { value: val } };
};

export const toSitecoreLinkField = (val: string, text: string, linktype = "external", target = ""): SCJSS.LinkField => {
    return { value: { href: val, anchor: val, linktype, target, text, url: val } };
};

import * as React from "react";
import "./mobile/ModelsMenuMobileVehicleModels.scss";
import "./ModelsMenuVehiclesFilter.scss";
import { PrimaryNavContext } from "../PrimaryNav";
import classNames from "classnames";
import { useGTM } from "../../GTM/useGTM";
import { createBemFn } from "../../../utilities/bem";
import { doesModelHaveAllTags } from "./shared";

interface ModelsMenuVehiclesFilterProps {
    selectedTagIds: string[];
    onSelect?: (tag: string) => void;
}

const bem = createBemFn("lxs-models-menu-vehicles-filter");

interface TagFilter extends LXS.ContentTag {
    enabled: boolean;
}

const AllTag: TagFilter = { id: "", name: "ALL", enabled: true };

const getFilterTags = (selectedTagIds: string[], modelsData?: LXS.ModelsData): TagFilter[] => {
    if (!modelsData?.primaryTags) {
        return [];
    }
    const availableTagIds = new Set<string>();
    modelsData.bodyTypes?.forEach((bodyType) => {
        bodyType.vehicleModels
            .filter((model) => doesModelHaveAllTags(selectedTagIds, model))
            .forEach((model) => model.tags?.forEach((tag) => availableTagIds.add(tag.id)));
    });

    return modelsData.primaryTags.map<TagFilter>((tag) => ({
        id: tag.id,
        name: tag.name,
        enabled: availableTagIds.has(tag.id),
    }));
};

const ModelsMenuVehiclesFilter: React.FC<ModelsMenuVehiclesFilterProps> = ({ selectedTagIds, onSelect }) => {
    const contextData = React.useContext(PrimaryNavContext);
    const tags = [AllTag, ...getFilterTags(selectedTagIds, contextData?.modelsData)];
    const { gtmClickProxy } = useGTM();

    return (
        <div className={bem()}>
            {tags?.map((tag) => (
                <button
                    key={tag.id}
                    className={classNames(bem("button"), {
                        [bem("button", "is-active")]:
                            selectedTagIds.includes(tag.id) || (selectedTagIds.length === 0 && tag.id === ""),
                    })}
                    onClick={tag.enabled ? gtmClickProxy(() => onSelect?.(tag.id)) : undefined}
                    data-gtm-action={tag}
                    data-gtm-target="FilterActive"
                    disabled={!tag.enabled}
                >
                    {tag.name}
                </button>
            ))}
        </div>
    );
};

export { ModelsMenuVehiclesFilter };

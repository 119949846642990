import { GenericData, ImageData, ImageDataDetailed } from "@/types/graphqlResponse";

import {
  BodyTypeRawData,
  PrimaryNavRawData,
  MainMenuRawItem,
  StaticLinksRawItem,
  MenuItemWithIconRawData,
  MenuRawItemTyped,
  ModalRawData,
  ModelRawData,
  ModelsModalRawData,
  ModalGenericRawData,
  ModalEncoreRawData,
  ModalDealerRawData,
} from "../types/rawTypes";
import {
  BodyTypeData,
  Level1MenuItemData,
  MenuItemTyped,
  MenuItemData,
  MenuItemType,
  MenuItemWithIconData,
  PrimaryNavData,
  MainMenuType,
  ModalDataType,
  ModalData,
  ModelData,
  MainListItem,
  MainMenuItem,
  StaticLinksItem,
  ModelsModalData,
  ModalGenericData,
  ModalEncoreData,
  ModalDealerData,
} from "../types/types";

export const menuItemDataToMenuItemTyped = (menuItem: MenuItemData): MenuItemTyped => ({
  ...menuItem,
  type: MenuItemType.LEAF,
});

const normaliseMediaPath = (p?: string): string | undefined => (p?.startsWith("-/") ? "/" + p : p);

export const imageDataDetailedToImageData = (rawImageData: ImageDataDetailed): ImageData => ({
  src: normaliseMediaPath(rawImageData?.jsonValue?.value?.src) ?? rawImageData?.src,
  alt: rawImageData?.alt,
});

const getMainMenuRawData = (data: PrimaryNavRawData) => {
  return data.details.children.results.find(
    (r) => r.menuType === MainMenuType.MAINMENU
  ) as MainMenuRawItem;
};

const toMenuItemTyped = (raw: MenuRawItemTyped): MenuItemTyped => {
  switch (raw.type) {
    case MenuItemType.WITHCHILDREN:
      return {
        ...raw,
        menuItems: raw.menuItems?.results,
      };
    case MenuItemType.LEAF:
      return {
        ...raw,
      };
  }
};

const toString = (raw: GenericData): string => raw.value || "";

export const toModelData = (raw: ModelRawData): ModelData => ({
  ...raw,
  menuImage: imageDataDetailedToImageData(raw.menuImage),
  menuHoverImage: imageDataDetailedToImageData(raw.menuHoverImage),
  bodyType: raw.bodyType?.value || "",
  modelName: raw.modelName?.value,
  fuelTypes: raw.fuelTypes.targetItems.map(toString),
  contentPath: raw.contentPath?.value,
  hideFromMenu: raw.hideFromMenu?.value,
});

const toBodyTypeData = (raw: BodyTypeRawData): BodyTypeData => ({
  ...raw,
  models: raw.models.results.map(toModelData),
});

const toModelsModalData = (raw: ModelsModalRawData): ModelsModalData => ({
  ...raw,
  label: raw.displayName,
  mobileLabel: raw.linkTextMobile?.value,
  filterButtonText: raw.filterButtonText?.value,
  clearCtaText: raw.clearCtaText?.value,
  showCtaText: raw.showCtaText?.value,
  scrollIndicatorDescription: raw.scrollIndicatorDescription?.value,
  availableBodyTypes: raw.availableBodyTypes.targetItems?.map(toBodyTypeData),
});

const toModalGenericData = (raw: ModalGenericRawData): ModalGenericData => ({
  ...raw,
  label: raw.displayName,
  mobileLabel: raw.linkTextMobile?.value,
  menuItems: raw.menuItems.results.map(toMenuItemTyped),
});

const toModalEncoreData = (raw: ModalEncoreRawData): ModalEncoreData => ({
  ...raw,
  label: raw.displayName,
  mobileLabel: raw.linkTextMobile?.value,
  menuItems: raw.menuItems.results,
  logoutLabel: raw.logoutLabel?.value || "",
  encoreAdvert: {
    title: raw.heroTitle?.value || "",
    description: raw.heroDescription?.value || "",
    googlePlayImageUrl:
      (normaliseMediaPath(raw.googlePlayImage?.jsonValue?.value?.src) ??
        raw.googlePlayImage?.src) ||
      undefined,
    googlePlayImageAlt: raw.googlePlayImage?.alt,
    googlePlayLinkUrl: raw.googlePlayLink?.url,
    googlePlayLinkTarget: raw.googlePlayLink?.target || "_self",
    appStoreImageUrl:
      (normaliseMediaPath(raw.appStoreImage?.jsonValue?.value?.src) ?? raw.appStoreImage?.src) ||
      undefined,
    appStoreImageAlt: raw.appStoreImage?.alt,
    appStoreLinkUrl: raw.appStoreLink?.url,
    appStoreLinkTarget: raw.appStoreLink?.target || "_self",
    heroImageUrl:
      (normaliseMediaPath(raw.heroImage?.jsonValue?.value?.src) ?? raw.heroImage?.src) || undefined,
    heroImageAlt: raw.heroImage?.alt,
    ctaLabel: raw.heroCta?.label || undefined,
    ctaTarget: raw.heroCta?.target || "_self",
    ctaUrl: raw.heroCta?.url,
  },
});

const toModalDealerData = (raw: ModalDealerRawData): ModalDealerData => ({
  ...raw,
  label: raw.displayName,
  mobileLabel: raw.linkTextMobile?.value,
  menuItems: raw.menuItems.results,
});

export const toModalData = (raw: ModalRawData): ModalData => {
  switch (raw.type) {
    case ModalDataType.MODELS:
      return toModelsModalData(raw);
    case ModalDataType.GENERIC:
      return toModalGenericData(raw);
    case ModalDataType.ENCORE:
      return toModalEncoreData(raw);
    case ModalDataType.DEALER:
      return toModalDealerData(raw);
  }
};

export const convertToMainMenuItem = (rawMenuItem: MainMenuRawItem): MainMenuItem => {
  return {
    menuType: MainMenuType.MAINMENU,
    logo: imageDataDetailedToImageData(rawMenuItem.logo),
    logoMobile: imageDataDetailedToImageData(rawMenuItem.logoMobile),
    menuTextMobile: rawMenuItem.menuTextMobile?.value,
    closeText: rawMenuItem.closeText?.value,
    modalData: rawMenuItem.modalData.results.map(toModalData),
  };
};

const toStaticLink = (raw: MenuItemWithIconRawData): MenuItemWithIconData => ({
  link: raw.link,
  icon: raw.icon?.value,
});

export const convertToStaticLinksItem = (rawStaticLinks: StaticLinksRawItem): StaticLinksItem => {
  return {
    menuType: MainMenuType.STATICLINKS,
    usedByEncore: rawStaticLinks.usedByEncore?.value || false,
    staticLinks: rawStaticLinks.staticLinks.results.map(toStaticLink),
  };
};

export const convertToPrimaryNavData = (rawData: PrimaryNavRawData): PrimaryNavData => {
  const level1MenuItems: Level1MenuItemData[] = [];
  const menuDetails: MainListItem[] = [];

  const mainMenu = getMainMenuRawData(rawData);
  if (mainMenu) {
    mainMenu.modalData.results.forEach((result) => {
      level1MenuItems.push({
        label: result.displayName,
        mobileLabel: result.linkTextMobile?.value,
        modalId: result.modalId,
      });
    });
  }

  rawData.details.children.results.forEach((result) => {
    switch (result.menuType) {
      case MainMenuType.MAINMENU:
        menuDetails.push(convertToMainMenuItem(result));
        break;
      case MainMenuType.STATICLINKS:
        menuDetails.push(convertToStaticLinksItem(result));
        break;
    }
  });

  return {
    level1MenuItems: level1MenuItems,
    menuDetails: menuDetails,
  };
};

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGFilterTick: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 9.18374L10.2694 16L6 12.2284L7.18592 11.0458L10.2705 13.7707L16.8153 8L18 9.18374Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGFilterTick = React.memo(SVGFilterTick);

export { SVGFilterTick };

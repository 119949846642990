/* eslint-disable comma-dangle */

import { ApiResponse, Dispatch, StateSelector } from "../types/general";
import {
    FetchAction,
    FetchActionPayload,
    FetchContainer,
    PromiseThunk,
    createFetchThunk,
    fetchActionPayloadToContainer,
    getFetchContainerValue,
    initialFetchContainer,
} from "../helpers/fetch";
import { ReducerMapValue, createAction, handleActions } from "redux-actions";
import {
    addPaymentMethodUrl,
    getCacheDeleteCreditCardUrl,
    getCheckDeleteCreditCardUrl,
    getDeleteCreditCardUrl,
    getPaymentMethodsUrl,
} from "../apiHref";
import { createKeazFetchThunk, getAccountInfoThunk } from "./onDemand/onDemandThunks";
import {
    emailSelector,
    salesforceAccountIdSelector,
    setStripCustomerDetailsAction,
    stripeCustomerIdSelector,
} from "./user";

import { LoadStatus } from "../types/loadStatus";
import { PaymentMethod } from "../types/payment";
import { ReactStripeElements } from "react-stripe-elements";
import { RootLevelAction } from "./rootLevelAction";
import { confirmStripeCardSetup } from "../helpers/stripe";
import { createSelector } from "reselect";

interface AddStripePaymentMethodPayload {
    customerId: string;
}

interface GetPaymentMethodsPayload {
    paymentMethods: PaymentMethod[];
}

type AddStripePaymentMethodFetchContainer = FetchContainer<AddStripePaymentMethodPayload>;
type AddStripePaymentMethodFetchActionPayload = FetchActionPayload<AddStripePaymentMethodPayload>;
type GetPaymentMethodsFetchContainer = FetchContainer<GetPaymentMethodsPayload>;
type GetPaymentMethodsFetchActionPayload = FetchActionPayload<GetPaymentMethodsPayload>;
type CheckDeleteCreditCardFetchContainer = FetchContainer<ApiResponse<boolean>>;
type CheckDeleteCreditCardFetchActionPayload = FetchActionPayload<ApiResponse<boolean>>;
type DeleteCreditCardDeleteCacheActionPayload = FetchActionPayload<ApiResponse<boolean>>;
type DeleteCreditCardFetchContainer = FetchContainer<ApiResponse<PaymentMethod>>;
type DeleteCreditCardFetchActionPayload = FetchActionPayload<ApiResponse<PaymentMethod>>;

//#region state
export interface PaymentsState {
    addStripePaymentMethod: AddStripePaymentMethodFetchContainer;
    getPaymentMethods: GetPaymentMethodsFetchContainer;
    checkDeleteCreditCard: CheckDeleteCreditCardFetchContainer;
    deleteCreditCard: DeleteCreditCardFetchContainer;
}

export const initialPaymentsState: PaymentsState = {
    addStripePaymentMethod: initialFetchContainer,
    getPaymentMethods: initialFetchContainer,
    checkDeleteCreditCard: initialFetchContainer,
    deleteCreditCard: initialFetchContainer,
};
//#endregion

//#region actions
enum PaymentsAction {
    AddStripePaymentMethodAction = "AddStripePaymentMethodAction",
    GetPaymentMethodsAction = "GetPaymentMethodsAction",
    CheckDeleteCreditCardAction = "CheckDeleteCreditCardAction",
    DeleteCreditCardAction = "DeleteCreditCardAction",
    DeleteCreditCardDeleteCacheAction = "DeleteCreditCardDeleteCacheAction",
}

export const addStripePaymentMethodAction = createAction<AddStripePaymentMethodFetchActionPayload>(
    PaymentsAction.AddStripePaymentMethodAction
);

export const getPaymentMethodsAction = createAction<GetPaymentMethodsFetchActionPayload>(
    PaymentsAction.GetPaymentMethodsAction
);

export const checkDeleteCreditCardAction = createAction<CheckDeleteCreditCardFetchActionPayload>(
    PaymentsAction.CheckDeleteCreditCardAction
);

export const deleteCreditCardDeleteCacheAction = createAction<DeleteCreditCardDeleteCacheActionPayload>(
    PaymentsAction.DeleteCreditCardDeleteCacheAction
);

export const deleteCreditCardAction = createAction<DeleteCreditCardFetchActionPayload>(
    PaymentsAction.DeleteCreditCardAction
);
//#endregion

//#region thunks
const handleAddPaymentMethodError = (dispatch: Dispatch) => (error: Error) => {
    dispatch(
        addStripePaymentMethodAction({
            action: FetchAction.Failure,
            value: error,
        })
    );
    throw error;
};

const handleAPIError =
    <E = Record<string, unknown>>(dispatch: Dispatch<E>) =>
    (response: ApiResponse<any>) => {
        if (response.error) {
            dispatch(
                addStripePaymentMethodAction({
                    action: FetchAction.Failure,
                    value: {
                        name: response.error.code,
                        message: response.error.description,
                    },
                })
            );
        }
        throw response.error;
    };

export const addStripePaymentMethodThunk =
    (stripe: ReactStripeElements.StripeProps, cardholderName: string): PromiseThunk<AddStripePaymentMethodPayload> =>
    async (dispatch: Dispatch, getState) => {
        dispatch(
            addStripePaymentMethodAction({
                action: FetchAction.Fetch,
            })
        );

        return confirmStripeCardSetup(stripe, cardholderName)
            .then(async ({ paymentMethodId }) => {
                const state = getState();
                const accountId = salesforceAccountIdSelector(state);
                const customerId = stripeCustomerIdSelector(state);
                const email = emailSelector(state);
                return dispatch(
                    createFetchThunk(await addPaymentMethodUrl(), addStripePaymentMethodAction, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            accountId,
                            customerId,
                            paymentMethodId,
                            cardholderName,
                            email,
                        }),
                    })
                )
                    .then(payload => {
                        dispatch(
                            setStripCustomerDetailsAction({
                                stripeCustomerId: payload.customerId,
                            })
                        );
                        return payload;
                    })
                    .catch(handleAddPaymentMethodError(dispatch));
            })
            .catch(handleAddPaymentMethodError(dispatch));
    };

export const getPaymentMethodsThunk =
    (customerId: string): PromiseThunk<GetPaymentMethodsPayload> =>
    async dispatch => {
        const url = await getPaymentMethodsUrl(customerId);
        return dispatch(createFetchThunk(url, getPaymentMethodsAction));
    };

export const checkDeleteCreditCardThunk =
    (keazId: string | undefined): PromiseThunk<ApiResponse<boolean>> =>
    async dispatch => {
        const keazCustomerId = keazId === undefined ? (await dispatch(getAccountInfoThunk)).user.id : keazId;
        const url = await getCheckDeleteCreditCardUrl(keazCustomerId ? keazCustomerId : "");
        return dispatch(createKeazFetchThunk(url, checkDeleteCreditCardAction)).catch(error => {
            throw error;
        });
    };
export const deleteCreditCardDeleteCacheThunk =
    (keazId?: string): PromiseThunk<ApiResponse<boolean>> =>
    async dispatch => {
        const keazCustomerId = keazId || (await dispatch(getAccountInfoThunk)).user.id;
        const url = await getCacheDeleteCreditCardUrl(keazCustomerId || "");
        return dispatch(
            createKeazFetchThunk(url, deleteCreditCardDeleteCacheAction, {
                method: "DELETE",
            })
        );
    };

export const deleteCreditCardThunk =
    (keazId: string, paymentMethodId: string): PromiseThunk<ApiResponse<PaymentMethod>> =>
    async (dispatch: Dispatch<number>) => {
        const url = await getDeleteCreditCardUrl(keazId, paymentMethodId);
        return dispatch(
            createKeazFetchThunk(url, deleteCreditCardAction, {
                method: "DELETE",
            })
        ).catch(handleAPIError(dispatch));
    };
//#endregion

//#region selectors
const addStripePaymentMethodContainerSelector: StateSelector<AddStripePaymentMethodFetchContainer> = state =>
    state.payments.addStripePaymentMethod;

export const addStripePaymentMethodStatusSelector: StateSelector<LoadStatus> = state =>
    addStripePaymentMethodContainerSelector(state).status;

const getPaymentMethodsContainerSelector: StateSelector<GetPaymentMethodsFetchContainer> = state =>
    state.payments.getPaymentMethods;

export const getPaymentMethodsStatusSelector: StateSelector<LoadStatus> = state =>
    getPaymentMethodsContainerSelector(state).status;

const getPaymentMethodsValueSelector: StateSelector<GetPaymentMethodsPayload | undefined> = createSelector(
    getPaymentMethodsContainerSelector,
    getFetchContainerValue
);

export const getDefaultPaymentMethodSelector: StateSelector<PaymentMethod | undefined> = state => {
    const value = getPaymentMethodsValueSelector(state);
    return value ? value.paymentMethods.find(paymentMethod => paymentMethod.isDefault) : undefined;
};

export const getDefaultPaymentMethodFromStripeResponse = (
    value: GetPaymentMethodsPayload
): PaymentMethod | undefined => {
    return value?.paymentMethods?.find(paymentMethod => paymentMethod.isDefault);
};
//#endregion

//#region reducers
type PaymentsReducerPayload =
    | AddStripePaymentMethodFetchActionPayload
    | GetPaymentMethodsFetchActionPayload
    | undefined
    | Error;

type PaymentsReducer<Payload = undefined> = ReducerMapValue<PaymentsState, Payload>;

const addStripePaymentMethodReducer: PaymentsReducer<AddStripePaymentMethodFetchActionPayload> = (state, action) => ({
    ...state,
    addStripePaymentMethod: fetchActionPayloadToContainer(action.payload),
});

const getPaymentMethodsReducer: PaymentsReducer<GetPaymentMethodsFetchActionPayload> = (state, action) => ({
    ...state,
    getPaymentMethods: fetchActionPayloadToContainer(action.payload),
});

const resetReducer: PaymentsReducer = () => initialPaymentsState;

export const paymentsReducer = handleActions<PaymentsState, PaymentsReducerPayload>(
    {
        [PaymentsAction.AddStripePaymentMethodAction]: addStripePaymentMethodReducer,
        [PaymentsAction.GetPaymentMethodsAction]: getPaymentMethodsReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialPaymentsState
);
//#endregion

import "./PasswordRequired.scss";

import React, { memo, useEffect, useState } from "react";

import { PasswordValidationBlock } from "./PasswordValidationBlock";
import { TextField } from "../../Form";
import { createBemFn } from "../../../utilities/bem";
import { usePasswordCheck } from "../../../utilities/validations";

const bem = createBemFn("lxs-account-password");

const strings = {
    notMatching: "Passwords are not matching",
    invalidPassword: "Invalid password. Please try another one.",
    // eslint-disable-next-line quotes
    improperPassword: 'Your password must not include special characters \\ or "',
} as const;

interface PasswordRequiredProps {
    setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
    blockedPasswords: string[];
}

const PasswordRequired: React.FC<PasswordRequiredProps> = ({ setPassword, blockedPasswords }) => {
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
    const [pwdErrorMsg, setPwdErrorMsg] = useState<string>();
    const [newPassword, setNewPassword] = usePasswordCheck("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const newPasswordRepeatMatches = newPassword.password === newPasswordRepeat;
    const isNewPasswordRepeatOk =
        newPassword.isPasswordValid && newPasswordRepeatMatches && !blockedPasswords.includes(newPasswordRepeat);

    useEffect(() => {
        setPassword(isNewPasswordRepeatOk ? newPasswordRepeat : undefined);
    }, [isNewPasswordRepeatOk, newPasswordRepeat]);

    const isNewPasswordOk = newPassword.isPasswordValid;
    const invalidCharsError = !newPassword.isNoForbiddenCharacterOk ? strings.improperPassword : undefined;
    const isError = !!(!isNewPasswordValid || invalidCharsError);

    const newPasswordFocus = () => {
        if (!isNewPasswordOk) {
            setPwdErrorMsg(undefined);
            setIsNewPasswordValid(true);
        }
    };

    const newPasswordRepeatFocus = () => (pwdErrorMsg || !isNewPasswordOk ? setPwdErrorMsg(undefined) : null);
    const newPasswordRepeatBlur = () => {
        if (!newPasswordRepeatMatches) {
            setPwdErrorMsg(strings.notMatching);
        } else if (blockedPasswords.includes(newPasswordRepeat)) {
            setPwdErrorMsg(strings.invalidPassword);
        } else {
            setPwdErrorMsg(undefined);
        }
    };
    const newPasswordBlur = () => {
        if (!isNewPasswordOk) {
            setIsNewPasswordValid(false);
        }
        if (newPasswordRepeat) {
            newPasswordRepeatBlur(); // Revalidate repeat if it has value
        }
    };

    useEffect(() => {
        newPasswordRepeatBlur();
    }, [blockedPasswords]);

    return (
        <>
            <TextField
                label="set new password*"
                type="password"
                value={newPassword.password}
                onChange={setNewPassword}
                isValid={isNewPasswordOk}
                isError={isError}
                errorText={invalidCharsError}
                onFocus={newPasswordFocus}
                onBlur={newPasswordBlur}
                showSuccessTick={true}
                className={bem("text-field")}
                maxLength={16}
            />
            <PasswordValidationBlock validator={newPassword} />
            <TextField
                label="confirm password*"
                type="password"
                value={newPasswordRepeat}
                onChange={setNewPasswordRepeat}
                isValid={!pwdErrorMsg && isNewPasswordRepeatOk}
                isError={!!pwdErrorMsg}
                errorText={pwdErrorMsg}
                onFocus={newPasswordRepeatFocus}
                onBlur={newPasswordRepeatBlur}
                showSuccessTick={true}
                className={bem("text-field")}
            />
        </>
    );
};

const PasswordRequiredMemoized = memo(PasswordRequired);

export { PasswordRequiredMemoized as PasswordRequired };

import cn from "classnames";
import {
  withGroupedChildren,
  type WithGroupedChildrenComponent,
  type GroupedChildrenProps,
} from "react-grouped-children";

import styles from "./CardWithHeader.module.scss";
import { CardWithHeaderContent } from "./CardWithHeaderContent";
import { CardWithHeaderFooter } from "./CardWithHeaderFooter";
import { CardWithHeaderTop } from "./CardWithHeaderTop";
import { Stack } from "../Stack";

const childrenSpec = {
  /**
   * Use CardWithHeaderContent.Top to render top portion of the card
   */
  Top: CardWithHeaderTop,
  /**
   * Use CardWithHeaderContent.Footer to render footer portion of the card
   */
  Footer: CardWithHeaderFooter,
} as const;

const CardWithHeaderContainer: React.FC<
  React.PropsWithChildren & GroupedChildrenProps<typeof childrenSpec>
> = ({ top, children, footer }) => {
  return (
    <Stack
      direction="column"
      spacing="none"
      alignItems="center"
      className={cn(styles.cardWithHeader)}
    >
      {top}
      {children && <CardWithHeaderContent>{children}</CardWithHeaderContent>}
      {footer}
    </Stack>
  );
};

/**
 * The `<CardWithHeader>` component is intended to be used within a CardTable component to display card content.
 *
 * `CardWithHeader` should be used in conjunction with `CardWithHeader.Top` and `CardWithHeader.Footer` to render
 * top and footer content. `children` can be a JSX Element or text
 */
const CardWithHeader: WithGroupedChildrenComponent<typeof childrenSpec, React.PropsWithChildren> =
  withGroupedChildren({
    childrenSpec,
  })(CardWithHeaderContainer);

export { CardWithHeader };

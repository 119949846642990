import cn from "classnames";
import { useEffect, useState, useRef } from "react";

import styles from "./AnimateInView.module.scss";

type AnimateInViewProps = React.PropsWithChildren<{
  animateOnce?: boolean;
  classname?: string;
  component?: "span" | "div";
  delay?: number;
  offset?: number;
  variant: "fade" | "fadeSlideInLeft" | "fadeSlideInRight";
}>;

export const AnimateInView: React.FC<AnimateInViewProps> = ({
  animateOnce,
  children,
  classname,
  component: Component = "span",
  delay = 0,
  offset = 0,
  variant: animationClass,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    let timer: NodeJS.Timeout;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          clearTimeout(timer);

          if (entry.isIntersecting) {
            timer = setTimeout(() => {
              setIsVisible(true);
            }, delay);

            if (animateOnce) {
              observer.unobserve(entry.target);
            }
          }

          if (!animateOnce && !entry.isIntersecting) {
            setIsVisible(false);
          }
        });
      },
      {
        rootMargin: `0px 0px ${offset}px 0px`,
      }
    );

    observer.observe(wrapperRef.current);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [animateOnce, offset, delay]);

  return (
    <Component
      className={cn(
        styles.wrapper,
        styles[animationClass],
        {
          [styles[`${animationClass}Visible`]]: isVisible,
        },
        classname
      )}
      ref={wrapperRef}
    >
      {children}
    </Component>
  );
};

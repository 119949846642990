const defaultComparator = <T>(elementA: T, elementB: T) => elementA === elementB;

/**
 * Toggles an element in array. If element exists it will be removed, otherwise added.
 * This method will not mutate the source @param array
 * @param array Source array
 * @param element Element to add or remove (toggle)
 * @param comparator (Optional) A function to compare an element of @param array and @param element
 * @returns New instance of array with removed or added to the end element
 */
export const toggleArrayElement = <T>(
    array: T[],
    element: T,
    comparator: (elementA: T, elementB: T) => boolean = defaultComparator,
): T[] =>
    array.findIndex((e) => comparator(e, element)) > -1
        ? array.filter((e) => !comparator(e, element))
        : [...array, element];

import "./LexusMap.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";

import {
    LexusMapCoordinates,
    LexusMapDirectionsWithIcons,
    LexusMapParams,
    LexusMapStyle,
    MapDetails,
    Marker,
} from "./types";
import { MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";
import { getMarkersBounds, lexusToGoogleCoordinates, markerIcon } from "./LexusMapHelpers";

import GoogleWrappers from "./GoogleWrappers";
import { Theme } from "../shared/Theme";
import classNames from "classnames";
import { difference } from "lodash";
import { isDefinedObject } from "../../utilities/object";
import { mapStyles as lexusMapStyle } from "./mapStyles";
import { lxColor } from "../../colors/variables";
import { useLexusGoogleMapScriptsReady } from "./LexusMapHooks";

interface MapState {
    map: google.maps.Map;
    directionRenderer: google.maps.DirectionsRenderer;
}

type LexusMapContainerProps = LexusMapParams & {
    className?: string;
    theme?: Theme;
};

type LexusMapProps = LexusMapContainerProps & {
    apiKey: string;
};

const DEFAULT_ZOOM_LEVEL = 15;

const LEXUS_POLYLINE_STYLE = {
    strokeColor: lxColor("inari"),
    strokeOpacity: 0.75,
    strokeWeight: 5,
};

const getDirectionRendererOptions = (
    map: google.maps.Map,
    mapStyle?: LexusMapStyle,
): google.maps.DirectionsRendererOptions => ({
    map,
    suppressMarkers: true,
    polylineOptions: mapStyle === "lexus" ? LEXUS_POLYLINE_STYLE : undefined,
});

const useCreateGoogleMap = (
    containerRef: React.RefObject<HTMLDivElement>,
    setMapState: React.Dispatch<React.SetStateAction<MapState | undefined>>,
    mapDetails?: MapDetails,
    mapStyle?: LexusMapStyle,
) => {
    React.useEffect(() => {
        if (containerRef.current) {
            const map = new google.maps.Map(containerRef.current, {
                center: mapDetails?.center,
                zoom: mapDetails?.zoom || DEFAULT_ZOOM_LEVEL,
            });
            const directionRenderer = new google.maps.DirectionsRenderer(getDirectionRendererOptions(map, mapStyle));
            setMapState({ map, directionRenderer });
        }
    }, []);
};

const useMarkersWithDirectionPoints = (markers: Marker[], directions?: LexusMapDirectionsWithIcons) => {
    const originMarker = React.useMemo(
        () =>
            directions?.origin
                ? {
                      lat: directions.origin.lat,
                      lng: directions.origin.lng,
                      icon: directions.originIcon,
                      neverClustered: true,
                  }
                : undefined,
        [directions?.origin, directions?.originIcon],
    );

    const destinationMarker = React.useMemo(
        () =>
            directions?.destination
                ? {
                      lat: directions.destination.lat,
                      lng: directions.destination.lng,
                      icon: directions.destinationIcon,
                      neverClustered: true,
                  }
                : undefined,
        [directions?.destination, directions?.destinationIcon],
    );

    const allMarkers = React.useMemo(() => {
        const locations = [...markers];
        if (originMarker) {
            locations.push(originMarker);
        }
        if (destinationMarker) {
            locations.push(destinationMarker);
        }

        return locations;
    }, [markers, originMarker, destinationMarker]);
    return allMarkers;
};

const useSyncMapCenter = (
    hasMapRef: React.MutableRefObject<boolean>,
    mapState: MapState | undefined,
    center?: LexusMapCoordinates,
) => {
    React.useEffect(() => {
        if (hasMapRef.current) {
            center && mapState?.map.setCenter(center);
        }
    }, [mapState, center]);
};

const useSyncMapZoom = (
    hasMapRef: React.MutableRefObject<boolean>,
    mapState: MapState | undefined,
    zoom: number | undefined,
) => {
    React.useEffect(() => {
        if (hasMapRef.current) {
            mapState?.map.setZoom(zoom || DEFAULT_ZOOM_LEVEL);
        }
    }, [mapState, zoom]);
};

const useSyncMapStyle = (
    mapState: MapState | undefined,
    mapStyle: LexusMapStyle | undefined,
    hasMapRef: React.MutableRefObject<boolean>,
    theme?: Theme,
) => {
    React.useEffect(() => {
        if (!mapState?.map) {
            return;
        }

        mapState.map.setOptions({
            styles: mapStyle !== "lexus" ? undefined : lexusMapStyle(theme),
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        });
        if (hasMapRef.current) {
            mapState?.directionRenderer.setOptions(getDirectionRendererOptions(mapState.map, mapStyle));
        }
    }, [mapState, mapStyle]);
};

const useSyncClusterStyle = (
    mapState: MapState | undefined,
    setClusterState: React.Dispatch<React.SetStateAction<MarkerClusterer | undefined>>,
    clusterStyles?: Renderer,
) => {
    React.useEffect(() => {
        if (!mapState?.map || !clusterStyles) {
            return;
        }

        const clusterer = new GoogleWrappers.MarkerClusterer({
            map: mapState.map,
            renderer: clusterStyles,
        });

        setClusterState(clusterer);
    }, [mapState, clusterStyles]);
};

const useSyncDirections = (
    mapState: MapState | undefined,
    directions: google.maps.DirectionsResult | undefined,
    hasMapRef: React.MutableRefObject<boolean>,
) => {
    React.useEffect(() => {
        if (!mapState?.map) {
            return;
        }
        if (directions) {
            mapState.directionRenderer.setDirections(directions);
            mapState.directionRenderer.setMap(mapState?.map);
        } else if (hasMapRef.current) {
            mapState.directionRenderer.setMap(null);
        }
    }, [mapState, directions]);
};

const removeOldMarkers = (
    markerMap: Map<Marker, google.maps.Marker>,
    newMarkers: Marker[],
    clusterer?: MarkerClusterer,
) => {
    const markersToRemove = difference(Array.from(markerMap.keys()), newMarkers);
    const gMarkers = markersToRemove.map((marker) => markerMap.get(marker)).filter(isDefinedObject);
    gMarkers.forEach((gMarker) => gMarker.setMap(null));
    clusterer?.removeMarkers(gMarkers);
    markersToRemove.forEach((marker) => markerMap.delete(marker));
};

const createGoogleMarker = (position: Marker, map: google.maps.Map) =>
    new google.maps.Marker({
        position: lexusToGoogleCoordinates(position),
        title: position.icon?.iconTitle || "",
        map,
        icon: position.icon?.default || markerIcon("default", position.icon),
        optimized: false,
        label: position.label,
    });

const useSyncMarkers = (
    map: google.maps.Map | undefined,
    clusterer: MarkerClusterer | undefined,
    allMarkers: Marker[],
    markerCallback: ((value: boolean) => void) | undefined,
    shouldResize?: boolean,
) => {
    const markerMap = React.useRef(new Map<Marker, google.maps.Marker>());
    const infoWindowElement = React.useRef(typeof document !== "undefined" && document.createElement("div"));

    React.useEffect(() => {
        if (!map) {
            return;
        }
        const infoWindow = new google.maps.InfoWindow();

        infoWindow.addListener("closeclick", () => {
            infoWindowElement.current && ReactDOM.unmountComponentAtNode(infoWindowElement.current);
            markerCallback?.(false);
        });

        removeOldMarkers(markerMap.current, allMarkers, clusterer);

        const googleMarkers: google.maps.Marker[] = [];

        const syncToGoogleMarker = (position: Marker) => {
            const existingGoogleMarker = markerMap.current.get(position);
            if (existingGoogleMarker) {
                return existingGoogleMarker;
            }

            const icon = position.icon;

            const googleMarker = createGoogleMarker(position, map);

            googleMarker.addListener("click", () => {
                const pos = googleMarker?.getPosition();
                if (position.getMarkerContent && infoWindowElement.current) {
                    ReactDOM.render(position.getMarkerContent(), infoWindowElement.current);
                    infoWindow.setContent(infoWindowElement.current);
                    infoWindow.open(map, googleMarker);
                    markerCallback?.(true);
                } else if (pos) {
                    map.panTo(pos);
                }

                googleMarker.setIcon(icon?.default || markerIcon("clicked", icon) || null);
                position.clickHandler?.();
            });

            markerMap.current.set(position, googleMarker);

            if (!position.neverClustered) {
                googleMarkers.push(googleMarker);
            }

            return googleMarker;
        };
        allMarkers.forEach(syncToGoogleMarker);
        clusterer?.addMarkers(googleMarkers);

        const boundsMarkers = allMarkers
            .filter((marker) => !marker.ignoreWhenCalcResizeBounds)
            .map((marker) => markerMap.current.get(marker))
            .filter(isDefinedObject);

        if (shouldResize) {
            const bounds = getMarkersBounds(boundsMarkers);
            // auto-zoom
            map.fitBounds(bounds);
            // auto-center
            map.panToBounds(bounds);
        }
    }, [map, allMarkers, markerCallback, shouldResize]);
};

const LexusMapContainer: React.FC<LexusMapContainerProps> = ({
    className,
    mapDetails,
    markers,
    directions,
    clusterStyles,
    shouldResize,
    markerCallback,
    theme,
    ...mapParams
}) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const hasMapRef = React.useRef(false);
    const [state, setState] = React.useState<MapState>();
    const [clusterState, setClusterState] = React.useState<MarkerClusterer>();
    const allMarkers = useMarkersWithDirectionPoints(markers, directions);

    useCreateGoogleMap(containerRef, setState, mapDetails, mapParams.mapStyle);

    useSyncMapCenter(hasMapRef, state, mapDetails?.center);
    useSyncMapZoom(hasMapRef, state, mapDetails?.zoom);
    useSyncMapStyle(state, mapParams.mapStyle, hasMapRef, theme);
    useSyncClusterStyle(state, setClusterState, clusterStyles);
    useSyncDirections(state, directions?.directions, hasMapRef);
    useSyncMarkers(state?.map, clusterState, allMarkers, markerCallback, shouldResize);

    React.useEffect(() => {
        state && google.maps.event.trigger(state, "resize");
        hasMapRef.current = !!state; // Set after the first iteration of useEffects passed
    }, [!state]);

    return <div className={classNames("lxs-lexus-map", className)} ref={containerRef} />;
};

const LexusMap: React.FunctionComponent<LexusMapProps> = ({ apiKey, ...mapContainerProps }) =>
    useLexusGoogleMapScriptsReady(apiKey) ? <LexusMapContainer {...mapContainerProps} /> : null; // We can do loading state here later once required

export { LexusMap };

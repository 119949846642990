import { Image } from "@sitecore-jss/sitecore-jss-react";
import { toPercentage } from "../helpers/number";
import classnames from "classnames";
import * as React from "react";
import "./FocalpointImage.scss";
import { getSitecoreFieldValue } from "../../utilities/Sitecore/SitecoreUtilities";

const FocalpointImage: React.FC<LXS.FocalpointImageProps> = ({ className, ...props }) => {
    const field = getSitecoreFieldValue<LXS.FocalpointImageProps, LXS.FocalpointImageFieldValue>(props) || {};

    const classes = classnames("lxs-focalpoint-image", { "lxs-focalpoint-image--cover": field.cover }, className);

    if ("cover" in field) {
        delete field.cover;
    }

    const cropX = toPercentage(field.cropx, 0);
    const cropY = toPercentage(field.cropy, 0);
    const style = {
        objectPosition: `${cropX}% ${cropY}%`,
        left: `${cropX}%`,
        top: `${cropY}%`,
        transform: `translate(-${cropX}%, -${cropY}%)`,
    };

    return <Image className={classes} style={style} {...props} />;
};

export { FocalpointImage };

import * as React from "react";
import { defaultIconColor } from "../constants";

const defaultWidth = 10;
const defaultHeight = 16;

let SVGFuel: React.FC<LXS.SVGStaticProps> = ({
    width = defaultWidth,
    height = defaultHeight,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M9.43551 1.24563C9.52282 1.09082 9.52139 0.901297 9.43175 0.747824C9.34211 0.594351 9.17773 0.5 9 0.5H3.90909C3.71062 0.5 3.53094 0.617388 3.45121 0.79914L0.542119 7.43072C0.474298 7.58532 0.489076 7.76371 0.581413 7.90505C0.67375 8.04638 0.831175 8.13158 1 8.13158H2.51231L0.520576 14.858C0.454311 15.0818 0.551685 15.3217 0.755188 15.436C0.95869 15.5502 1.21418 15.5085 1.37077 15.3355L9.37077 6.49335C9.5034 6.34675 9.53721 6.13577 9.45702 5.95507C9.37682 5.77438 9.19769 5.65789 9 5.65789H6.94696L9.43551 1.24563Z"
            stroke={color}
            strokeLinejoin="round"
        />
    </svg>
    /* eslint-enable max-len */
);
SVGFuel = React.memo(SVGFuel);

export { SVGFuel };

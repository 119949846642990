import * as React from "react";
import { SVGIcon } from "../../SVGIcon";
import "./SVGInfoCircleAnimated.scss";
import { defaultIconSize, defaultIconColor } from "../constants";
import { SVGInfoCircle } from "../static/SVGInfoCircle";

const SVGInfoCircleAnimated: React.FC<LXS.SVGAnimatedProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <SVGIcon {...rest}>
        <SVGInfoCircle width={width} height={height} color={color} />
    </SVGIcon>
    /* eslint-enable max-len */
);

export { SVGInfoCircleAnimated };

import "./SVGLoadingSpinner.scss";

import * as React from "react";

import { defaultSpinnerColor, defaultSpinnerSize } from "../constants";

import classnames from "classnames";

let SVGLoadingSpinner: React.FC<LXS.SVGStaticProps> = ({
    width = defaultSpinnerSize,
    height = defaultSpinnerSize,
    color = defaultSpinnerColor,
    className,
    ...rest
}) => {
    return (
        /* eslint-disable max-len */
        <svg
            width={width}
            height={height}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classnames("lxs-svg-loading-spinner", className)}
            {...rest}
        >
            <circle className="path" cx="25" cy="25" r="12" fill="none" strokeWidth="3" stroke={color} />
        </svg>
    );
};

SVGLoadingSpinner = React.memo(SVGLoadingSpinner);

export { SVGLoadingSpinner };

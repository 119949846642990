import cn from "classnames";

import styles from "./SectionContentContainer.module.scss";

export type SectionContentContainerWidth = "full" | "4col" | "6col" | "8col" | "12col";

type SectionContentContainerProps = React.PropsWithChildren<{
  /**
   * Width limitation for inner content.
   */
  width?: SectionContentContainerWidth;

  /**
   * Class name(s) to customise component if required.
   */
  className?: string;
}>;

/**
 * Section Content Container is a component which designed to position and apply certain
 * constraints on inner section content. It limits maximum content width and ensures that
 * content always fit to the screen size without horisontal scrolling effects.
 */
export const SectionContentContainer: React.FC<SectionContentContainerProps> = ({
  className,
  width = "12col",
  children,
}) => <div className={cn(styles.container, styles[`width${width}`], className)}>{children}</div>;

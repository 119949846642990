import { isValidElement } from "react";

import { hasProperty, isValidReactNode } from "./typeGuards";

export const getValidReactChildrenOrDefault = <D>(node: unknown, defaultValue: D) =>
  isValidElement(node) &&
  hasProperty(node.props, "children") &&
  isValidReactNode(node.props.children)
    ? node.props.children
    : defaultValue;

import { createContext } from "react";

// Do not use @/ alias, Rollup can't resolve it for zero output .ts files
import type { Dealer, DealerConfig } from "../types/dealer";

export type DealerContextData = {
  dealerConfig?: DealerConfig;
  dealer?: Dealer;
};

export const DealerContext = createContext<DealerContextData>({});

import { memo } from "react";

import { SVGEncoreLogoSizes, SVGStaticProps } from "../types";

let SVGEncore: React.FC<SVGStaticProps> = ({
  width = SVGEncoreLogoSizes.DEFAULT_WIDTH,
  height = SVGEncoreLogoSizes.DEFAULT_HEIGHT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 245 60"
    fill="currentColor"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M191.595 21.7872L191.226 21.1925H191.926C196.184 21.1925 203.326 20.2933 203.326 14.2704C203.326 7.45365 199.015 5.03418 195.323 5.03418H184.355V41.7547H188.348V22.5532L199.646 41.7534H204.245C201.981 38.2 193.865 25.4395 191.595 21.7872ZM188.347 8.64031H194.024C196.863 8.64031 199.089 10.4203 199.089 13.2591C199.089 16.4314 197.141 18.4355 194.024 18.4355H188.347V8.64031Z"
      fill="currentColor"
    />
    <path
      id="Vector_2"
      d="M23.4237 18.1578H9.73225V8.64082H24.4811V5.22852H5.25195V21.3658H23.4237V18.1578Z"
      fill="currentColor"
    />
    <path
      id="Vector_3"
      d="M4.47381 38.1939V4.45058H24.4809V1.12793H0.828125V41.7552H25.3155V38.1939H4.47381Z"
      fill="currentColor"
    />
    <path
      id="Vector_4"
      d="M104.319 8.25049C108.103 8.25049 111.22 9.58614 113.948 12.3128L116.604 9.21564L116.559 9.18004C113.236 6.53642 110.611 4.44922 104.301 4.44922C97.9626 4.44922 88.791 10.2269 88.791 20.6735C88.791 30.6441 95.5181 38.4536 104.106 38.4536C110.611 38.4536 115.504 34.6708 117.347 33.0029L114.726 30.0112C112.11 32.4597 109.606 34.6299 104.485 34.6299C96.9724 34.6299 92.9087 29.5655 92.9087 21.218C92.91 13.7605 97.3627 8.25049 104.319 8.25049Z"
      fill="currentColor"
    />
    <path
      id="Vector_5"
      d="M41.3325 4.33753L67.679 36.3299V1.12695H63.6035V24.1679C63.6035 25.17 63.7156 26.1721 63.7156 26.2275C63.6589 26.06 63.2699 25.2808 62.7135 24.5582L44.7936 1.12695H37.1133V41.7542H41.3325V4.33753Z"
      fill="currentColor"
    />
    <path
      id="Vector_6"
      d="M243.494 8.64082V5.22852H224.309V21.3658H242.437V18.1578H228.745V8.64082H243.494Z"
      fill="currentColor"
    />
    <path
      id="Vector_7"
      d="M244.329 34.2412H228.745V25.6696H242.437V22.1426H224.309V37.4149H244.329V34.2412Z"
      fill="currentColor"
    />
    <path
      id="Vector_8"
      d="M25.3157 37.4159V34.2422H9.73225V25.6706H23.4237V22.1436H5.25195V37.4159H25.3157Z"
      fill="currentColor"
    />
    <path
      id="Vector_9"
      d="M147.388 4.70898C136.136 4.70898 131.1 12.4012 131.1 20.0235V20.3478V20.4125V20.7368C131.1 29.2689 136.197 38.4523 147.388 38.4523C154.92 38.4523 163.027 33.6595 163.027 23.1378V22.8135V22.7489V22.4258C163.027 9.34882 154.602 4.70898 147.388 4.70898ZM147.243 34.6313C140.899 34.6313 135.556 28.7323 135.556 21.2748C135.556 14.4844 140.398 8.25182 147.356 8.25182C154.423 8.25182 159.043 14.1508 159.043 21.2748C159.043 28.1191 154.59 34.6313 147.243 34.6313Z"
      fill="currentColor"
    />
    <path
      id="Vector_10"
      d="M147.356 0.458984C134.779 0.458984 126.375 9.58704 126.375 21.2743C126.375 32.8508 134.667 42.4232 147.246 42.4232C159.824 42.4232 168.227 33.017 168.227 21.2743C168.226 9.19676 160.044 0.458984 147.356 0.458984ZM163.806 22.7497V22.8143V23.1387C163.806 27.1865 162.397 31.1434 159.94 33.9953C156.988 37.4208 152.649 39.2324 147.389 39.2324C141.881 39.2324 137.341 37.0925 134.264 33.0434C131.758 29.7497 130.323 25.2641 130.323 20.7377V20.4133V20.3487V20.0244C130.323 12.0131 135.599 3.93062 147.389 3.93062C152.872 3.93062 157.302 6.0732 160.197 10.1289C163.338 14.5262 163.806 19.7198 163.806 22.4254V22.7497Z"
      fill="currentColor"
    />
    <path
      id="Vector_11"
      d="M68.4578 38.4988L42.1113 6.5078V41.7542H46.0181V18.4904C46.0181 17.5437 45.9073 16.543 45.9073 16.4876C45.9627 16.5984 46.4084 17.3776 46.9094 18.1015L64.8293 41.7542H72.5096V1.12695H68.4578V38.4988Z"
      fill="currentColor"
    />
    <path
      id="Vector_12"
      d="M207.994 13.2589C207.994 6.24707 202.54 1.12598 194.025 1.12598H179.443V41.7546H183.577V4.2548H195.322C199.373 4.2548 204.103 6.87732 204.103 14.2689C204.103 19.0867 200.035 21.8042 192.622 21.9624C195.414 26.4295 203.453 39.0648 205.165 41.7533H209.716L198.92 24.7787C204.264 23.3323 207.994 19.3254 207.994 13.2589Z"
      fill="currentColor"
    />
    <path
      id="Vector_13"
      d="M223.528 38.1929V4.4496H243.493V1.12695H219.84V41.7542H244.327V38.1929H223.528Z"
      fill="currentColor"
    />
    <path
      id="Vector_14"
      d="M104.105 39.2324C95.0803 39.2324 88.0117 31.0801 88.0117 20.6731C88.0117 10.6524 96.5952 3.67088 104.299 3.67088C110.883 3.67088 113.601 5.83324 117.043 8.57047L117.11 8.62321L118.956 6.47008C114.783 2.18492 110.273 0.458984 104.319 0.458984C92.91 0.458984 83.7266 9.02931 83.7266 21.2176C83.7266 34.2406 92.1307 42.4219 104.485 42.4219C111.554 42.4219 116.062 39.9181 119.846 35.8544L117.865 33.5945C115.94 35.356 110.931 39.2324 104.105 39.2324Z"
      fill="currentColor"
    />
  </svg>
);
SVGEncore = memo(SVGEncore);

export { SVGEncore };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGDialog: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M7.99984 23.0003H7.58562L7.29273 23.2932L3.6665 26.9194V6.00033C3.6665 4.71166 4.71117 3.66699 5.99984 3.66699H25.9998C27.2885 3.66699 28.3332 4.71166 28.3332 6.00033V20.667C28.3332 21.9557 27.2885 23.0003 25.9998 23.0003H7.99984Z"
            stroke={color}
            strokeWidth="2"
        />
        <line x1="8.5" y1="11.5" x2="23.5" y2="11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <line x1="8.5" y1="15.5" x2="23.5" y2="15.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    /* eslint-enable max-len */
);
SVGDialog = React.memo(SVGDialog);

export { SVGDialog };

import { Interval, KeazDayNumber } from "../types/dateTime";
import { daysInWeek, hoursInDay, minutesInHour, timeString24ToMinutes } from "./dateTime";

import { dayShorthandLength } from "./string";

export interface KeazTimes {
    ["0"]?: string;
    ["1"]?: string;
    ["2"]?: string;
    ["3"]?: string;
    ["4"]?: string;
    ["5"]?: string;
    ["6"]?: string;
}

type OptionalInterval = Interval | undefined;
export type DayToInterval = [
    OptionalInterval,
    OptionalInterval,
    OptionalInterval,
    OptionalInterval,
    OptionalInterval,
    OptionalInterval,
    OptionalInterval
];

export const parseKeazTimes = (times: KeazTimes): DayToInterval => {
    const result: DayToInterval = new Array(daysInWeek) as DayToInterval;
    for (let i = 0; i < result.length; ++i) {
        const intervalString = times[i.toString()];
        if (typeof intervalString === "string") {
            const timings = intervalString.split("-");
            if (timings.length === 2) {
                const startMinutes = timeString24ToMinutes(timings[0]);
                const endMinutes = timeString24ToMinutes(timings[1]);
                if (startMinutes !== undefined && endMinutes !== undefined) {
                    const difference = endMinutes - startMinutes;
                    result[i] = {
                        start: startMinutes,
                        end: difference >= 0 ? endMinutes : hoursInDay * minutesInHour + endMinutes,
                    };
                }
            }
        }
    }
    return result;
};

export const keazDayNumberToFullString = (day: KeazDayNumber): string => {
    switch (day) {
        case KeazDayNumber.Sunday:
            return "Sunday";
        case KeazDayNumber.Monday:
            return "Monday";
        case KeazDayNumber.Tuesday:
            return "Tuesday";
        case KeazDayNumber.Wednesday:
            return "Wednesday";
        case KeazDayNumber.Thursday:
            return "Thursday";
        case KeazDayNumber.Friday:
            return "Friday";
        case KeazDayNumber.Saturday:
            return "Saturday";
    }
};

export const keazDayNumberToShortString = (day: KeazDayNumber): string =>
    keazDayNumberToFullString(day).slice(0, dayShorthandLength);

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGBrochure: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M31.79 11.275a1 1 0 0 0-.34-.8 1 1 0 0 0-.81-.25 9.2 9.2 0 0 1-3.19-.45c-.07 0-6.07-2.48-9.15-.35-3.07-2.11-9.08.32-9.12.34a9 9 0 0 1-2.95.44h-.22a1 1 0 0 0-.85.24 1 1 0 0 0-.37.78v15.06a1.06 1.06 0 0 0 .67 1 8.86 8.86 0 0 0 4.51-.3c1.62-.76 6.23-2 7.5-.41a1.09 1.09 0 0 0 1.63 0c1.28-1.6 5.85-.36 7.49.41.838.325 1.73.491 2.63.49h.81c.373.01.746-.047 1.1-.17a1.05 1.05 0 0 0 .66-1v-15.03ZM30 25.725h-.37a5.31 5.31 0 0 1-2.38-.35 13.75 13.75 0 0 0-5.61-1.34 5.58 5.58 0 0 0-3.36.92c-2-1.53-6-1-9 .44a7.54 7.54 0 0 1-2.75.31v-13.81c.694 0 1.387-.068 2.07-.2a8.385 8.385 0 0 0 1.25-.35c.13-.06.58-.22 1.08-.38a18.18 18.18 0 0 1 2.26-.49c2-.33 3.52-.14 4.3.53l.81.7.81-.7c.78-.68 2.27-.86 4.3-.53.77.132 1.531.312 2.28.54.5.15.94.32 1.06.37.408.155.83.276 1.26.36.678.133 1.368.2 2.06.2v13.83l-.07-.05Z" />
    <path d="M12.209 15.655c-1.66 0-3 1-3 2.25s1.37 2.24 3 2.23c1.63-.01 3-1 3-2.25s-1.33-2.24-3-2.23Zm0 4.16c-1.48 0-2.68-.85-2.69-1.91-.01-1.06 1.2-1.92 2.68-1.92h.1c-.39.5-1.5 1.93-1.61 2.19a.48.48 0 0 0 0 .49c.15.21.52.29 1.3.29h2.46a3.09 3.09 0 0 1-2.22.86h-.02Zm2.53-1.29h-3s0-.15.12-.26c.07-.14 1-1.28 1.55-2.06a2 2 0 0 1 1.47 1.7c.003.213-.038.424-.12.62h-.02ZM18.29 13.185a.84.84 0 0 0-.8.87v7.69a.81.81 0 1 0 1.61 0v-7.69a.84.84 0 0 0-.81-.87Z" />
  </svg>

  /* eslint-enable max-len */
);
SVGBrochure = memo(SVGBrochure);

export { SVGBrochure };

import * as React from "react";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import ErrorCard from "../ErrorCard/ErrorCard";
import { RouteComponentProps } from "react-router";
import { closeToastUrl } from "../../../helpers/routes";

const CancelBookingErrorCard: React.FC<RouteComponentProps> = ({ location, history }) => {
    return (
        <ErrorCard>
            <ErrorCardFinePrint>
                We're sorry. We can't contact our servers right now. Please come back and try again later.
            </ErrorCardFinePrint>
            <ErrorCardButton onClick={() => history.push(closeToastUrl(location.pathname))}>okay</ErrorCardButton>
        </ErrorCard>
    );
};

export default CancelBookingErrorCard;

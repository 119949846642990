import { Fragment, useState, useRef, useMemo } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import {
  FilterActionTypes,
  useFilter,
  useFilterDispatch,
} from "./modals/PrimaryNavModalModels.context";
import { PrimaryNavCloseButton } from "./PrimaryNavCloseButton";
import { PrimaryNavModalModelsFilters } from "./PrimaryNavModalModelsFilters";
import styles from "./PrimaryNavModalModelsResult.module.scss";
import { BodyTypeData } from "./types/types";
import { Button } from "../Button/Button";

type PrimaryNavModalModelsResult = {
  bodyTypes: BodyTypeData[];
};
const FILTER_TRANSITION_DURATION_MS = 200;

const PrimaryNavModalModelsResult: React.FC<PrimaryNavModalModelsResult> = ({ bodyTypes }) => {
  const filterContext = useFilter();

  const filterClassNames = {
    enter: styles.filterModalEnter,
    enterActive: styles.filterModalEnterActive,
    enterDone: styles.filterModalEnterDone,
    exit: styles.filterModalExit,
    exitActive: styles.filterModalExitActive,
    exitDone: styles.filterModalExitDone,
    appear: styles.filterModalEnterDone,
    appearActive: styles.filterModalEnterDone,
    appearDone: styles.filterModalEnterDone,
  };
  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const nodeRef = useRef(null);

  const filterDispatch = useFilterDispatch();

  const handleButtonReset = () => {
    filterDispatch({
      type: FilterActionTypes.RESET_FILTERS,
    });
  };

  const handleButtonApply = () => {
    setShowMessage(false);
  };

  const getAnimationKey = useMemo(
    () =>
      filterContext.modelCardResults
        .flatMap((item) => item.models.flatMap((m) => m.displayName))
        .join(" "),
    [filterContext.modelCardResults]
  );

  return (
    <>
      <section className={styles.filterContainerMobile}>
        <div className={styles.filterMobile}>
          {showButton && (
            <Button
              icon={""}
              variant="primary"
              themeVariant="alt"
              onClick={() => setShowMessage(true)}
            >
              FILTER
            </Button>
          )}

          <CSSTransition
            in={showMessage}
            classNames={filterClassNames}
            nodeRef={nodeRef}
            timeout={FILTER_TRANSITION_DURATION_MS}
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onEntering={() => setShowButton(true)}
          >
            <div ref={nodeRef} className={styles.filterModal}>
              <PrimaryNavCloseButton onClick={() => setShowMessage(false)} />

              <PrimaryNavModalModelsFilters bodyTypes={bodyTypes} />

              <div className={styles.filterModalButtons}>
                <Button variant="secondary" themeVariant="static" onClick={handleButtonReset}>
                  Reset
                </Button>
                <Button variant="primary" themeVariant="static" onClick={handleButtonApply}>
                  Apply
                </Button>
              </div>
            </div>
          </CSSTransition>
        </div>
      </section>
      <section className={styles.filterDesktop}>
        <PrimaryNavModalModelsFilters bodyTypes={bodyTypes} />
      </section>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          classNames={{
            enter: styles.vehicleEnter,
            enterActive: styles.vehicleEnterActive,
            enterDone: styles.vehicleEnterDone,
            exit: styles.vehicleExit,
            exitDone: styles.vehicleExitDone,
            exitActive: styles.vehicleExitActive,
          }}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          key={getAnimationKey}
          timeout={FILTER_TRANSITION_DURATION_MS}
        >
          <section className={styles.modelCardContainer}>
            {filterContext.modelCardResults.map((item) => (
              <Fragment key={item.displayName}>
                <div className={styles.modelCardGroupTitle}>{item.displayName}</div>
                <ul className={styles.modelCardGroup}>
                  {item.models.map((m) => (
                    <a className={styles.modelCard} key={m.displayName} href={m.contentPath || ""}>
                      <div className={styles.modelName}>{m.displayName}</div>
                      <div className={styles.modelBodyType}>{m.modelName}</div>
                      <img
                        className={styles.modelImage}
                        src={m.menuImage.src ?? ""}
                        alt={m.menuImage.alt}
                      />
                    </a>
                  ))}
                </ul>
              </Fragment>
            ))}
          </section>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export { PrimaryNavModalModelsResult };

import * as React from "react";
import "./ModelsMenuMobileVehicleCard.scss";
import { getImageSource } from "../../../../utilities/Sitecore/SitecoreUtilities";
import { Sticker, StickerType } from "../../../Sticker/Sticker";
import { splitHalf } from "../../../../utilities/string-helpers";
import classNames from "classnames";
import { createBemFn } from "../../../../utilities/bem";

const bem = createBemFn("lxs-models-menu-mobile-vehicle-card");

const ModelsMenuMobileVehicleCard: React.FC<LXS.VehicleModel> = ({
    modelCode,
    modelName,
    menuImage,
    onOfferTitle,
    hasOnOffer,
    stickerTags,
}) => {
    const onOfferLines = splitHalf((onOfferTitle && onOfferTitle.value) || "");

    const showOnOffer = hasOnOffer && onOfferLines.line1.length > 0 && (!stickerTags || stickerTags.length < 2);

    return (
        <div className={bem()}>
            <span className={bem("name")}>{modelCode && modelCode.value}</span>
            <div className={bem("container")}>
                <img className={bem("container-img")} src={getImageSource(menuImage)} />
            </div>
            <div className={bem("footer")}>
                <span className={bem("subtitle")}>{modelName && modelName.value}</span>
            </div>
            {showOnOffer && (
                <div className={bem("sticker")}>
                    <Sticker line1={onOfferLines.line1} line2={onOfferLines.line2} style={StickerType.ROUND1} />
                </div>
            )}
            {stickerTags && stickerTags.length > 0 && (
                <div
                    className={classNames(bem("sticker-tags"), {
                        [bem("sticker-tags", "no-offer-sticker")]: !showOnOffer,
                    })}
                >
                    {stickerTags.slice(0, 2).map((stickerTag) => (
                        <Sticker
                            key={stickerTag.id}
                            line1={stickerTag.titleMobile || splitHalf(stickerTag.name).line1}
                            line2={stickerTag.subtitleMobile || splitHalf(stickerTag.name).line2}
                            style={StickerType.DEFAULT}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const VehicleCardMemoised = React.memo(ModelsMenuMobileVehicleCard);
export { VehicleCardMemoised };

import * as React from "react";
import "./MainMenuMobileSecondaryNav.scss";
import { createBemFn } from "../../../../utilities/bem";

interface MainMenuMobileSecondaryNavProps {
    items: LXS.SecondaryMenuItem[];
}

const bem = createBemFn("lxs-main-menu-mobile-secondary-nav");

const MainMenuMobileSecondaryNav: React.FC<MainMenuMobileSecondaryNavProps> = ({ items }) => (
    <div className={bem()}>
        <ul>
            {items.map((item, index) => (
                <li key={`${item.title}-${index}`} className={bem("list-item")}>
                    <a className={bem("item")} href={item.href} data-gtm-linktype="SecondaryLink">
                        {item.title}
                    </a>
                </li>
            ))}
        </ul>
    </div>
);
export { MainMenuMobileSecondaryNav };

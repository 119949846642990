import { useContext } from "react";

import { DealerMap } from "@/components/DealerContactTile/components/DealerMap/DealerMap";
import { Marker } from "@/components/LexusMap/types";
import { DealerLocation as DealerLocationType } from "@/types/dealer";
import { DealerContext } from "@/utils/DealerContext";

import { DealerLocation } from "./components/DealerLocation/DealerLocation";
import styles from "./DealerContactTile.module.scss";

const getMarkers = (locations: DealerLocationType[]): Marker[] =>
  locations.map(({ lat, lng }) => ({
    lat,
    lng,
  }));

const DealerContactTile: React.FC = () => {
  const { dealer, dealerConfig } = useContext(DealerContext);
  const apiKey = dealerConfig?.gmApiKey?.value || process.env.GOOGLE_MAP_KEY;

  return !dealer || !apiKey ? null : (
    <>
      <DealerMap apiKey={apiKey} markers={getMarkers(dealer.locations)} />
      <div className={styles.dealerContactTile}>
        {/* <div className={styles.dealerContactTileTitle}>{dealer.dealerName}</div> */}
        <DealerLocation dealerLocations={dealer.locations} />
      </div>
    </>
  );
};

export { DealerContactTile };

/* eslint-disable */
const NEW_RELIC_NAME = "NREUM";
const typeQs = new URLSearchParams(window.location.search).get("type");
if (process.env.NEW_RELIC_APP_ID && process.env.NEW_RELIC_APP_ID !== "false" && typeQs !== "iframe") {
    let NREUM = {};
    NREUM.init = {
        distributed_tracing: { enabled: true },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ["bam.nr-data.net"] },
    };

    NREUM.loader_config = {
        accountID: "955740",
        trustKey: "955740",
        agentID: process.env.NEW_RELIC_APP_ID,
        licenseKey: "4b01e197f4",
        applicationID: process.env.NEW_RELIC_APP_ID,
    };
    NREUM.info = {
        beacon: "bam.nr-data.net",
        errorBeacon: "bam.nr-data.net",
        licenseKey: "4b01e197f4",
        applicationID: process.env.NEW_RELIC_APP_ID,
        sa: 1,
    };
    window[NEW_RELIC_NAME] = NREUM;
    (() => {
        let e,
            t,
            r = {
                9071: (e, t, r) => {
                    "use strict";
                    r.d(t, { I: () => n });
                    var n = 0,
                        i = navigator.userAgent.match(/Firefox[\/\s](\d+\.\d+)/);
                    i && (n = +i[1]);
                },
                6562: (e, t, r) => {
                    "use strict";
                    r.d(t, {
                        P_: () => p,
                        Mt: () => v,
                        C5: () => d,
                        DL: () => y,
                        OP: () => k,
                        lF: () => H,
                        Yu: () => E,
                        Dg: () => g,
                        CX: () => f,
                        GE: () => w,
                        sU: () => L,
                    });
                    let n = {};
                    r.r(n), r.d(n, { agent: () => T, match: () => _, version: () => O });
                    let i = r(6797),
                        o = r(909),
                        a = r(8610);
                    class s {
                        constructor(e, t) {
                            try {
                                if (!e || "object" !== typeof e)
                                    return (0, a.Z)("New setting a Configurable requires an object as input");
                                if (!t || "object" !== typeof t)
                                    return (0, a.Z)(
                                        "Setting a Configurable requires a model to set its initial properties"
                                    );
                                Object.assign(this, t),
                                    Object.entries(e).forEach(e => {
                                        let [t, r] = e;
                                        const n = (0, o.q)(t);
                                        n.length &&
                                            r &&
                                            "object" === typeof r &&
                                            n.forEach(e => {
                                                e in r &&
                                                    ((0, a.Z)(
                                                        '"'
                                                            .concat(
                                                                e,
                                                                '" is a protected attribute and can not be changed in feature '
                                                            )
                                                            .concat(t, ".  It will have no effect.")
                                                    ),
                                                    delete r[e]);
                                            }),
                                            (this[t] = r);
                                    });
                            } catch (e) {
                                (0, a.Z)("An error occured while setting a Configurable", e);
                            }
                        }
                    }
                    const c = {
                            beacon: i.ce.beacon,
                            errorBeacon: i.ce.errorBeacon,
                            licenseKey: void 0,
                            applicationID: void 0,
                            sa: void 0,
                            queueTime: void 0,
                            applicationTime: void 0,
                            ttGuid: void 0,
                            user: void 0,
                            account: void 0,
                            product: void 0,
                            extra: void 0,
                            jsAttributes: {},
                            userAttributes: void 0,
                            atts: void 0,
                            transactionName: void 0,
                            tNamePlain: void 0,
                        },
                        u = {};
                    function d(e) {
                        if (!e) throw new Error("All info objects require an agent identifier!");
                        if (!u[e]) throw new Error("Info for ".concat(e, " was never set"));
                        return u[e];
                    }
                    function f(e, t) {
                        if (!e) throw new Error("All info objects require an agent identifier!");
                        (u[e] = new s(t, c)), (0, i.Qy)(e, u[e], "info");
                    }
                    const l = {
                            allow_bfcache: !1,
                            privacy: { cookies_enabled: !0 },
                            ajax: { deny_list: void 0, enabled: !0, harvestTimeSeconds: 10 },
                            distributed_tracing: {
                                enabled: void 0,
                                exclude_newrelic_header: void 0,
                                cors_use_newrelic_header: void 0,
                                cors_use_tracecontext_headers: void 0,
                                allowed_origins: void 0,
                            },
                            ssl: void 0,
                            obfuscate: void 0,
                            jserrors: { enabled: !0, harvestTimeSeconds: 10 },
                            metrics: { enabled: !0, harvestTimeSeconds: 10 },
                            page_action: { enabled: !0, harvestTimeSeconds: 30 },
                            page_view_event: { enabled: !0 },
                            page_view_timing: { enabled: !0, harvestTimeSeconds: 30 },
                            session_trace: { enabled: !0, harvestTimeSeconds: 10 },
                            spa: { enabled: !0, harvestTimeSeconds: 10 },
                        },
                        h = {};
                    function p(e) {
                        if (!e) throw new Error("All configuration objects require an agent identifier!");
                        if (!h[e]) throw new Error("Configuration for ".concat(e, " was never set"));
                        return h[e];
                    }
                    function g(e, t) {
                        if (!e) throw new Error("All configuration objects require an agent identifier!");
                        (h[e] = new s(t, l)), (0, i.Qy)(e, h[e], "config");
                    }
                    function v(e, t) {
                        if (!e) throw new Error("All configuration objects require an agent identifier!");
                        let r = p(e);
                        if (r) {
                            for (var n = t.split("."), i = 0; i < n.length - 1; i++)
                                if ("object" !== typeof (r = r[n[i]])) return;
                            r = r[n[n.length - 1]];
                        }
                        return r;
                    }
                    const m = {
                            accountID: void 0,
                            trustKey: void 0,
                            agentID: void 0,
                            licenseKey: void 0,
                            applicationID: void 0,
                            xpid: void 0,
                        },
                        b = {};
                    function y(e) {
                        if (!e) throw new Error("All loader-config objects require an agent identifier!");
                        if (!b[e]) throw new Error("LoaderConfig for ".concat(e, " was never set"));
                        return b[e];
                    }
                    function w(e, t) {
                        if (!e) throw new Error("All loader-config objects require an agent identifier!");
                        (b[e] = new s(t, m)), (0, i.Qy)(e, b[e], "loader_config");
                    }
                    const E = (0, i.mF)().o;
                    var A = r(2053),
                        T = null,
                        O = null;
                    if (navigator.userAgent) {
                        let x = navigator.userAgent,
                            S = x.match(/Version\/(\S+)\s+Safari/);
                        S && -1 === x.indexOf("Chrome") && -1 === x.indexOf("Chromium") && ((T = "Safari"), (O = S[1]));
                    }
                    function _(e, t) {
                        if (!T) return !1;
                        if (e !== T) return !1;
                        if (!t) return !0;
                        if (!O) return !1;
                        for (let r = O.split("."), n = t.split("."), i = 0; i < n.length; i++)
                            if (n[i] !== r[i]) return !1;
                        return !0;
                    }
                    let I = r(5526),
                        j = r(2374);
                    const N = "NRBA_SESSION_ID";
                    function P() {
                        if (!j.il) return null;
                        try {
                            let e;
                            return (
                                null === (e = window.sessionStorage.getItem(N)) &&
                                    ((e = (0, I.ky)(16)), window.sessionStorage.setItem(N, e)),
                                e
                            );
                        } catch (e) {
                            return null;
                        }
                    }
                    let R = r(8226);
                    const D = e => ({
                            customTransaction: void 0,
                            disabled: !1,
                            isolatedBacklog: !1,
                            loaderType: void 0,
                            maxBytes: 3e4,
                            offset: (0, A.yf)(),
                            onerror: void 0,
                            origin: "" + j._A.location,
                            ptid: void 0,
                            releaseIds: {},
                            sessionId: 1 == v(e, "privacy.cookies_enabled") ? P() : null,
                            xhrWrappable: "function" === typeof j._A.XMLHttpRequest?.prototype?.addEventListener,
                            userAgent: n,
                            version: R.q,
                        }),
                        C = {};
                    function k(e) {
                        if (!e) throw new Error("All runtime objects require an agent identifier!");
                        if (!C[e]) throw new Error("Runtime for ".concat(e, " was never set"));
                        return C[e];
                    }
                    function L(e, t) {
                        if (!e) throw new Error("All runtime objects require an agent identifier!");
                        (C[e] = new s(t, D(e))), (0, i.Qy)(e, C[e], "runtime");
                    }
                    function H(e) {
                        return (function (e) {
                            try {
                                const t = d(e);
                                return !!t.licenseKey && !!t.errorBeacon && !!t.applicationID;
                            } catch (e) {
                                return !1;
                            }
                        })(e);
                    }
                },
                8226: (e, t, r) => {
                    "use strict";
                    r.d(t, { q: () => n });
                    const n = "1225.PROD";
                },
                9557: (e, t, r) => {
                    "use strict";
                    r.d(t, { w: () => o });
                    let n = r(8610);
                    const i = { agentIdentifier: "" };
                    class o {
                        constructor(e) {
                            try {
                                if ("object" !== typeof e)
                                    return (0, n.Z)("shared context requires an object as input");
                                (this.sharedContext = {}),
                                    Object.assign(this.sharedContext, i),
                                    Object.entries(e).forEach(e => {
                                        let [t, r] = e;
                                        Object.keys(i).includes(t) && (this.sharedContext[t] = r);
                                    });
                            } catch (e) {
                                (0, n.Z)("An error occured while setting SharedContext", e);
                            }
                        }
                    }
                },
                4329: (e, t, r) => {
                    "use strict";
                    r.d(t, { L: () => d, R: () => c });
                    let n = r(3752),
                        i = r(7022),
                        o = r(4045),
                        a = r(2325);
                    const s = {};
                    function c(e, t) {
                        const r = { staged: !1, priority: a.p[t] || 0 };
                        u(e), s[e].get(t) || s[e].set(t, r);
                    }
                    function u(e) {
                        e && (s[e] || (s[e] = new Map()));
                    }
                    function d() {
                        let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
                            t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "feature";
                        if ((u(e), !e || !s[e].get(t))) return a(t);
                        s[e].get(t).staged = !0;
                        const r = Array.from(s[e]);
                        function a(t) {
                            const r = e ? n.ee.get(e) : n.ee,
                                a = o.X.handlers;
                            if (r.backlog && a) {
                                let s = r.backlog[t],
                                    c = a[t];
                                if (c) {
                                    for (let u = 0; s && u < s.length; ++u) f(s[u], c);
                                    (0, i.D)(c, (e, t) => {
                                        (0, i.D)(t, (t, r) => {
                                            r[0].on(e, r[1]);
                                        });
                                    });
                                }
                                delete a[t], (r.backlog[t] = null), r.emit("drain-" + t, []);
                            }
                        }
                        r.every(e => {
                            let [t, r] = e;
                            return r.staged;
                        }) &&
                            (r.sort((e, t) => e[1].priority - t[1].priority),
                            r.forEach(e => {
                                let [t] = e;
                                a(t);
                            }));
                    }
                    function f(e, t) {
                        let r = e[1];
                        (0, i.D)(t[r], (t, r) => {
                            let n = e[0];
                            if (r[0] === n) {
                                let i = r[1],
                                    o = e[3],
                                    a = e[2];
                                i.apply(o, a);
                            }
                        });
                    }
                },
                3752: (e, t, r) => {
                    "use strict";
                    r.d(t, { c: () => f, ee: () => u });
                    let n = r(6797),
                        i = r(3916),
                        o = r(7022),
                        a = r(6562),
                        s = "nr@context";
                    let c = (0, n.fP)();
                    let u;
                    function d() {}
                    function f(e) {
                        return (0, i.X)(e, s, l);
                    }
                    function l() {
                        return new d();
                    }
                    function h() {
                        (u.backlog.api || u.backlog.feature) && ((u.aborted = !0), (u.backlog = {}));
                    }
                    c.ee
                        ? (u = c.ee)
                        : ((u = (function e(t, r) {
                              let n = {},
                                  c = {},
                                  f = {},
                                  p = !1;
                              try {
                                  p = 16 === r.length && (0, a.OP)(r).isolatedBacklog;
                              } catch (e) {}
                              let g = {
                                  on: b,
                                  addEventListener: b,
                                  removeEventListener: y,
                                  emit: m,
                                  get: E,
                                  listeners: w,
                                  context: v,
                                  buffer: A,
                                  abort: h,
                                  aborted: !1,
                                  isBuffering: T,
                                  debugId: r,
                                  backlog: p ? {} : t && "object" === typeof t.backlog ? t.backlog : {},
                              };
                              return g;
                              function v(e) {
                                  return e && e instanceof d ? e : e ? (0, i.X)(e, s, l) : l();
                              }
                              function m(e, r, n, i, o) {
                                  if ((!1 !== o && (o = !0), !u.aborted || i)) {
                                      t && o && t.emit(e, r, n);
                                      for (var a = v(n), s = w(e), d = s.length, f = 0; f < d; f++) s[f].apply(a, r);
                                      let l = O()[c[e]];
                                      return l && l.push([g, e, r, a]), a;
                                  }
                              }
                              function b(e, t) {
                                  n[e] = w(e).concat(t);
                              }
                              function y(e, t) {
                                  let r = n[e];
                                  if (r) for (let i = 0; i < r.length; i++) r[i] === t && r.splice(i, 1);
                              }
                              function w(e) {
                                  return n[e] || [];
                              }
                              function E(t) {
                                  return (f[t] = f[t] || e(g, t));
                              }
                              function A(e, t) {
                                  let r = O();
                                  g.aborted ||
                                      (0, o.D)(e, (e, n) => {
                                          (t = t || "feature"), (c[n] = t), t in r || (r[t] = []);
                                      });
                              }
                              function T(e) {
                                  return !!O()[c[e]];
                              }
                              function O() {
                                  return g.backlog;
                              }
                          })(void 0, "globalEE")),
                          (c.ee = u));
                },
                9252: (e, t, r) => {
                    "use strict";
                    r.d(t, { E: () => n, p: () => i });
                    var n = r(3752).ee.get("handle");
                    function i(e, t, r, i, o) {
                        o ? (o.buffer([e], i), o.emit(e, t, r)) : (n.buffer([e], i), n.emit(e, t, r));
                    }
                },
                4045: (e, t, r) => {
                    "use strict";
                    r.d(t, { X: () => o });
                    let n = r(9252);
                    o.on = a;
                    let i = (o.handlers = {});
                    function o(e, t, r, o) {
                        a(o || n.E, i, e, t, r);
                    }
                    function a(e, t, r, i, o) {
                        o || (o = "feature"), e || (e = n.E);
                        let a = (t[o] = t[o] || {});
                        (a[r] = a[r] || []).push([e, i]);
                    }
                },
                8544: (e, t, r) => {
                    "use strict";
                    r.d(t, { bP: () => s, iz: () => c, m$: () => a });
                    let n = r(2374);
                    let i = !1,
                        o = !1;
                    try {
                        const e = {
                            get passive() {
                                return (i = !0), !1;
                            },
                            get signal() {
                                return (o = !0), !1;
                            },
                        };
                        n._A.addEventListener("test", null, e), n._A.removeEventListener("test", null, e);
                    } catch (e) {}
                    function a(e, t) {
                        return i || o ? { capture: !!e, passive: i, signal: t } : !!e;
                    }
                    function s(e, t) {
                        let r = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
                        window.addEventListener(e, t, a(r));
                    }
                    function c(e, t) {
                        let r = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
                        document.addEventListener(e, t, a(r));
                    }
                },
                5526: (e, t, r) => {
                    "use strict";
                    r.d(t, { Ht: () => a, M: () => o, Rl: () => i, ky: () => s });
                    let n = r(2374);
                    function i() {
                        let e = null,
                            t = 0,
                            r = n._A?.crypto || n._A?.msCrypto;
                        function i() {
                            return e ? 15 & e[t++] : (16 * Math.random()) | 0;
                        }
                        r && r.getRandomValues && (e = r.getRandomValues(new Uint8Array(31)));
                        for (var o, a = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx", s = "", c = 0; c < a.length; c++)
                            s +=
                                "x" === (o = a[c])
                                    ? i().toString(16)
                                    : "y" === o
                                    ? (o = (3 & i()) | 8).toString(16)
                                    : o;
                        return s;
                    }
                    function o() {
                        return s(16);
                    }
                    function a() {
                        return s(32);
                    }
                    function s(e) {
                        let t = null,
                            r = 0,
                            n = self.crypto || self.msCrypto;
                        n && n.getRandomValues && Uint8Array && (t = n.getRandomValues(new Uint8Array(31)));
                        for (var i = [], o = 0; o < e; o++) i.push(a().toString(16));
                        return i.join("");
                        function a() {
                            return t ? 15 & t[r++] : (16 * Math.random()) | 0;
                        }
                    }
                },
                2053: (e, t, r) => {
                    "use strict";
                    r.d(t, { nb: () => c, os: () => u, yf: () => s, zO: () => a });
                    let n = r(7145),
                        i = new Date().getTime(),
                        o = i;
                    function a() {
                        return n.G && performance.now
                            ? Math.round(performance.now())
                            : (i = Math.max(new Date().getTime(), i)) - o;
                    }
                    function s() {
                        return i;
                    }
                    function c(e) {
                        o = e;
                    }
                    function u() {
                        return o;
                    }
                },
                7145: (e, t, r) => {
                    "use strict";
                    r.d(t, { G: () => n });
                    const n = void 0 !== r(2374)._A?.performance?.timing?.navigationStart;
                },
                6625: (e, t, r) => {
                    "use strict";
                    r.d(t, { s: () => c, v: () => u });
                    let n = r(8283),
                        i = r(9071),
                        o = r(2053),
                        a = r(7145),
                        s = r(2374);
                    let c = !0;
                    function u(e) {
                        let t = (function () {
                            if (i.I && i.I < 9) return;
                            if (a.G) return (c = !1), s._A?.performance?.timing?.navigationStart;
                        })();
                        t && ((0, n.B)(e, "starttime", t), (0, o.nb)(t));
                    }
                },
                8283: (e, t, r) => {
                    "use strict";
                    r.d(t, { B: () => o, L: () => a });
                    let n = r(2053),
                        i = {};
                    function o(e, t, r) {
                        void 0 === r && (r = (0, n.zO)() + (0, n.os)()), (i[e] = i[e] || {}), (i[e][t] = r);
                    }
                    function a(e, t, r, n) {
                        const o = e.sharedContext.agentIdentifier;
                        let a = i[o]?.[r],
                            s = i[o]?.[n];
                        void 0 !== a && void 0 !== s && e.store("measures", t, { value: s - a });
                    }
                },
                6368: (e, t, r) => {
                    "use strict";
                    r.d(t, { e: () => o });
                    let n = r(2374),
                        i = {};
                    function o(e) {
                        if (e in i) return i[e];
                        if (0 === (e || "").indexOf("data:")) return { protocol: "data" };
                        let t;
                        let r = n._A?.location,
                            o = {};
                        if (n.il) (t = document.createElement("a")), (t.href = e);
                        else
                            try {
                                t = new URL(e, r.href);
                            } catch (e) {
                                return o;
                            }
                        o.port = t.port;
                        let a = t.href.split("://");
                        !o.port && a[1] && (o.port = a[1].split("/")[0].split("@").pop().split(":")[1]),
                            (o.port && "0" !== o.port) || (o.port = "https" === a[0] ? "443" : "80"),
                            (o.hostname = t.hostname || r.hostname),
                            (o.pathname = t.pathname),
                            (o.protocol = a[0]),
                            "/" !== o.pathname.charAt(0) && (o.pathname = "/" + o.pathname);
                        let s = !t.protocol || ":" === t.protocol || t.protocol === r.protocol,
                            c = t.hostname === r.hostname && t.port === r.port;
                        return (o.sameOrigin = s && (!t.hostname || c)), "/" === o.pathname && (i[e] = o), o;
                    }
                },
                9548: (e, t, r) => {
                    "use strict";
                    r.d(t, { T: () => i });
                    let n = r(2374);
                    const i = {
                        isFileProtocol: function () {
                            let e = Boolean("file:" === (0, n.lW)()?.location?.protocol);
                            e && (i.supportabilityMetricSent = !0);
                            return e;
                        },
                        supportabilityMetricSent: !1,
                    };
                },
                8610: (e, t, r) => {
                    "use strict";
                    function n(e, t) {
                        console &&
                            console.warn &&
                            "function" === typeof console.warn &&
                            (console.warn("New Relic: ".concat(e)), t && console.warn(t));
                    }
                    r.d(t, { Z: () => n });
                },
                3916: (e, t, r) => {
                    "use strict";
                    r.d(t, { X: () => i });
                    let n = Object.prototype.hasOwnProperty;
                    function i(e, t, r) {
                        if (n.call(e, t)) return e[t];
                        let i = r();
                        if (Object.defineProperty && Object.keys)
                            try {
                                return Object.defineProperty(e, t, { value: i, writable: !0, enumerable: !1 }), i;
                            } catch (e) {}
                        return (e[t] = i), i;
                    }
                },
                2374: (e, t, r) => {
                    "use strict";
                    r.d(t, { _A: () => o, il: () => n, lW: () => a, v6: () => i });
                    const n = Boolean("undefined" !== typeof window && window.document),
                        i = Boolean(
                            "undefined" !== typeof WorkerGlobalScope && self.navigator instanceof WorkerNavigator
                        );
                    let o = (() => {
                        if (n) return window;
                        if (i) {
                            if ("undefined" !== typeof globalThis && globalThis instanceof WorkerGlobalScope)
                                return globalThis;
                            if (self instanceof WorkerGlobalScope) return self;
                        }
                        throw new Error(
                            'New Relic browser agent shutting down due to error: Unable to locate global scope. This is possibly due to code redefining browser global variables like "self" and "window".'
                        );
                    })();
                    function a() {
                        return o;
                    }
                },
                7022: (e, t, r) => {
                    "use strict";
                    r.d(t, { D: () => i });
                    let n = Object.prototype.hasOwnProperty;
                    function i(e, t) {
                        let r = [],
                            i = "",
                            o = 0;
                        for (i in e) n.call(e, i) && ((r[o] = t(i, e[i])), (o += 1));
                        return r;
                    }
                },
                9226: (e, t, r) => {
                    "use strict";
                    r.d(t, { $c: () => u, Ng: () => d, RR: () => c });
                    let n = r(6562),
                        i = r(9557),
                        o = r(9548),
                        a = r(8610),
                        s = { regex: /^file:\/\/(.*)/, replacement: "file://OBFUSCATED" };
                    class c extends i.w {
                        constructor(e) {
                            super(e);
                        }
                        shouldObfuscate() {
                            return u(this.sharedContext.agentIdentifier).length > 0;
                        }
                        obfuscateString(e) {
                            if (!e || "string" !== typeof e) return e;
                            for (var t = u(this.sharedContext.agentIdentifier), r = e, n = 0; n < t.length; n++) {
                                let i = t[n].regex,
                                    o = t[n].replacement || "*";
                                r = r.replace(i, o);
                            }
                            return r;
                        }
                    }
                    function u(e) {
                        let t = [],
                            r = (0, n.Mt)(e, "obfuscate") || [];
                        return (t = t.concat(r)), o.T.isFileProtocol() && t.push(s), t;
                    }
                    function d(e) {
                        for (var t = !1, r = !1, n = 0; n < e.length; n++) {
                            "regex" in e[n]
                                ? "string" !== typeof e[n].regex &&
                                  e[n].regex.constructor !== RegExp &&
                                  ((0, a.Z)(
                                      'An obfuscation replacement rule contains a "regex" value with an invalid type (must be a string or RegExp)'
                                  ),
                                  (r = !0))
                                : ((0, a.Z)('An obfuscation replacement rule was detected missing a "regex" value.'),
                                  (r = !0));
                            let i = e[n].replacement;
                            i &&
                                "string" !== typeof i &&
                                ((0, a.Z)(
                                    'An obfuscation replacement rule contains a "replacement" value with an invalid type (must be a string)'
                                ),
                                (t = !0));
                        }
                        return !t && !r;
                    }
                },
                2650: (e, t, r) => {
                    "use strict";
                    r.d(t, { K: () => a, b: () => o });
                    let n = r(8544);
                    function i() {
                        return "undefined" === typeof document || "complete" === document.readyState;
                    }
                    function o(e, t) {
                        if (i()) return e();
                        (0, n.bP)("load", e, t);
                    }
                    function a(e) {
                        if (i()) return e();
                        (0, n.iz)("DOMContentLoaded", e);
                    }
                },
                6797: (e, t, r) => {
                    "use strict";
                    r.d(t, { EZ: () => u, Qy: () => c, ce: () => o, fP: () => a, gG: () => d, mF: () => s });
                    let n = r(2053),
                        i = r(2374);
                    const o = { beacon: "bam.nr-data.net", errorBeacon: "bam.nr-data.net" };
                    function a() {
                        return (
                            i._A.NREUM || (i._A.NREUM = {}),
                            void 0 === i._A.newrelic && (i._A.newrelic = i._A.NREUM),
                            i._A.NREUM
                        );
                    }
                    function s() {
                        let e = a();
                        return (
                            e.o ||
                                (e.o = {
                                    ST: i._A.setTimeout,
                                    SI: i._A.setImmediate,
                                    CT: i._A.clearTimeout,
                                    XHR: i._A.XMLHttpRequest,
                                    REQ: i._A.Request,
                                    EV: i._A.Event,
                                    PR: i._A.Promise,
                                    MO: i._A.MutationObserver,
                                    FETCH: i._A.fetch,
                                }),
                            e
                        );
                    }
                    function c(e, t, r) {
                        let i = a();
                        const o = i.initializedAgents || {},
                            s = o[e] || {};
                        return (
                            Object.keys(s).length || (s.initializedAt = { ms: (0, n.zO)(), date: new Date() }),
                            (i.initializedAgents = { ...o, [e]: { ...s, [r]: t } }),
                            i
                        );
                    }
                    function u(e, t) {
                        a()[e] = t;
                    }
                    function d() {
                        return (
                            (function () {
                                let e = a();
                                const t = e.info || {};
                                e.info = { beacon: o.beacon, errorBeacon: o.errorBeacon, ...t };
                            })(),
                            (function () {
                                let e = a();
                                const t = e.init || {};
                                e.init = { ...t };
                            })(),
                            s(),
                            (function () {
                                let e = a();
                                const t = e.loader_config || {};
                                e.loader_config = { ...t };
                            })(),
                            a()
                        );
                    }
                },
                6998: (e, t, r) => {
                    "use strict";
                    r.d(t, { N: () => i, e: () => o });
                    let n = r(8544);
                    function i(e) {
                        let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
                        return void (0, n.iz)("visibilitychange", () => {
                            if (t) {
                                if ("hidden" != document.visibilityState) return;
                                e();
                            }
                            e(document.visibilityState);
                        });
                    }
                    function o() {
                        return "hidden" === document.visibilityState ? -1 : 1 / 0;
                    }
                },
                6408: (e, t, r) => {
                    "use strict";
                    r.d(t, { W: () => i });
                    let n = r(2374);
                    function i() {
                        return "function" === typeof n._A?.PerformanceObserver;
                    }
                },
                7264: (e, t, r) => {
                    "use strict";
                    r.d(t, {
                        hF: () => pe,
                        Fp: () => O,
                        lx: () => V,
                        ow: () => Y,
                        ao: () => te,
                        G8: () => oe,
                        XV: () => B,
                        Zw: () => C,
                        ig: () => we,
                        em: () => le,
                        u5: () => T,
                        QU: () => G,
                        _L: () => $,
                        Gm: () => ee,
                        Lg: () => ie,
                        gy: () => M,
                        BV: () => D,
                        Kf: () => ye,
                    });
                    let n = r(3752),
                        i = r(8683),
                        o = r.n(i),
                        a = r(2374);
                    const s = "nr@original";
                    let c = Object.prototype.hasOwnProperty,
                        u = !1;
                    function d(e, t) {
                        return (
                            e || (e = n.ee),
                            (r.inPlace = function (e, t, n, i, o) {
                                n || (n = "");
                                let a,
                                    s,
                                    c,
                                    u = "-" === n.charAt(0);
                                for (c = 0; c < t.length; c++)
                                    h((a = e[(s = t[c])])) || (e[s] = r(a, u ? s + n : n, i, s, o));
                            }),
                            (r.flag = s),
                            r
                        );
                        function r(t, r, n, a, c) {
                            return h(t) ? t : (r || (r = ""), (nrWrapper[s] = t), l(t, nrWrapper, e), nrWrapper);
                            function nrWrapper() {
                                let s, u, d, l;
                                try {
                                    (u = this), (s = o()(arguments)), (d = "function" === typeof n ? n(s, u) : n || {});
                                } catch (t) {
                                    f([t, "", [s, u, a], d], e);
                                }
                                i(r + "start", [s, u, a], d, c);
                                try {
                                    return (l = t.apply(u, s));
                                } catch (e) {
                                    throw (i(r + "err", [s, u, e], d, c), e);
                                } finally {
                                    i(r + "end", [s, u, l], d, c);
                                }
                            }
                        }
                        function i(r, n, i, o) {
                            if (!u || t) {
                                let a = u;
                                u = !0;
                                try {
                                    e.emit(r, n, i, t, o);
                                } catch (t) {
                                    f([t, r, n, i], e);
                                }
                                u = a;
                            }
                        }
                    }
                    function f(e, t) {
                        t || (t = n.ee);
                        try {
                            t.emit("internal-error", e);
                        } catch (e) {}
                    }
                    function l(e, t, r) {
                        if (Object.defineProperty && Object.keys)
                            try {
                                return (
                                    Object.keys(e).forEach(r => {
                                        Object.defineProperty(t, r, {
                                            get: function () {
                                                return e[r];
                                            },
                                            set: function (t) {
                                                return (e[r] = t), t;
                                            },
                                        });
                                    }),
                                    t
                                );
                            } catch (e) {
                                f([e], r);
                            }
                        for (let n in e) c.call(e, n) && (t[n] = e[n]);
                        return t;
                    }
                    function h(e) {
                        return !(e && e instanceof Function && e.apply && !e[s]);
                    }
                    function p(e, t) {
                        e?.[t]?.[s] && (e[t] = e[t][s]);
                    }
                    let g = "fetch-",
                        v = g + "body-",
                        m = ["arrayBuffer", "blob", "json", "text", "formData"],
                        b = a._A.Request,
                        y = a._A.Response,
                        w = "prototype",
                        E = "nr@context";
                    const A = {};
                    function T(e) {
                        const t = x(e);
                        if (!(b && y && a._A.fetch)) return t;
                        if (A[t.debugId]++) return t;
                        function r(e, r, n) {
                            let i = e[r];
                            "function" === typeof i &&
                                ((e[r] = function () {
                                    let e,
                                        r = o()(arguments),
                                        s = {};
                                    t.emit(n + "before-start", [r], s), s[E] && s[E].dt && (e = s[E].dt);
                                    let c = i.apply(this, r);
                                    return (
                                        t.emit(n + "start", [r, e], c),
                                        a._A.Promise.resolve(c).then(
                                            e => {
                                                return t.emit(n + "end", [null, e], c), e;
                                            },
                                            e => {
                                                throw (t.emit(n + "end", [e], c), e);
                                            }
                                        )
                                    );
                                }),
                                (e[r][s] = i));
                        }
                        return (
                            (A[t.debugId] = 1),
                            m.forEach(e => {
                                r(b[w], e, v), r(y[w], e, v);
                            }),
                            r(a._A, "fetch", g),
                            t.on(g + "end", function (e, r) {
                                let n = this;
                                if (r) {
                                    let i = r.headers.get("content-length");
                                    null !== i && (n.rxSize = i), t.emit(g + "done", [null, r], n);
                                } else t.emit(g + "done", [e], n);
                            }),
                            t
                        );
                    }
                    function O(e) {
                        const t = x(e);
                        1 == A[t.debugId]
                            ? (m.forEach(e => {
                                  p(b[w], e), p(y[w], e);
                              }),
                              p(a._A, "fetch"),
                              (A[t.debugId] = 1 / 0))
                            : A[t.debugId]--;
                    }
                    function x(e) {
                        return (e || n.ee).get("fetch");
                    }
                    const S = {},
                        _ = "setTimeout",
                        I = "setInterval",
                        j = "clearTimeout",
                        N = "-start",
                        P = "-",
                        R = [_, "setImmediate", I, j, "clearImmediate"];
                    function D(e) {
                        const t = k(e);
                        if (S[t.debugId]++) return t;
                        S[t.debugId] = 1;
                        let r = d(t);
                        return (
                            r.inPlace(a._A, R.slice(0, 2), _ + P),
                            r.inPlace(a._A, R.slice(2, 3), I + P),
                            r.inPlace(a._A, R.slice(3), j + P),
                            t.on(I + N, (e, t, n) => {
                                e[0] = r(e[0], "fn-", null, n);
                            }),
                            t.on(_ + N, function (e, t, n) {
                                (this.method = n),
                                    (this.timerDuration = isNaN(e[1]) ? 0 : +e[1]),
                                    (e[0] = r(e[0], "fn-", this, n));
                            }),
                            t
                        );
                    }
                    function C(e) {
                        const t = k(e);
                        1 == S[t.debugId] ? (R.forEach(e => p(a._A, e)), (S[t.debugId] = 1 / 0)) : S[t.debugId]--;
                    }
                    function k(e) {
                        return (e || n.ee).get("timer");
                    }
                    const L = {},
                        H = "requestAnimationFrame";
                    function M(e) {
                        const t = z(e);
                        if (!a.il || L[t.debugId]++) return t;
                        L[t.debugId] = 1;
                        let r = d(t);
                        return (
                            r.inPlace(window, [H], "raf-"),
                            t.on("raf-start", e => {
                                e[0] = r(e[0], "fn-");
                            }),
                            t
                        );
                    }
                    function B(e) {
                        const t = z(e);
                        1 == L[t.debugId] ? (p(window, H), (L[t.debugId] = 1 / 0)) : L[t.debugId]--;
                    }
                    function z(e) {
                        return (e || n.ee).get("raf");
                    }
                    const F = {},
                        U = ["pushState", "replaceState"];
                    function G(e) {
                        const t = q(e);
                        return !a.il || F[t.debugId]++ || ((F[t.debugId] = 1), d(t).inPlace(window.history, U, "-")), t;
                    }
                    function V(e) {
                        const t = q(e);
                        1 == F[t.debugId]
                            ? (U.forEach(e => p(window.history, e)), (F[t.debugId] = 1 / 0))
                            : F[t.debugId]--;
                    }
                    function q(e) {
                        return (e || n.ee).get("history");
                    }
                    let W = r(8544);
                    const X = {},
                        Z = ["appendChild", "insertBefore", "replaceChild"];
                    function $(e) {
                        const t = Q(e);
                        if (!a.il || X[t.debugId]) return t;
                        X[t.debugId] = !0;
                        let r = d(t),
                            n = /[?&](?:callback|cb)=([^&#]+)/,
                            i = /(.*)\.([^.]+)/,
                            o = /^(\w+)(\.|$)(.*)$/;
                        function s(e, t) {
                            let r = e.match(o),
                                n = r[1],
                                i = r[3];
                            return i ? s(i, t[n]) : t[n];
                        }
                        return (
                            r.inPlace(Node.prototype, Z, "dom-"),
                            t.on("dom-start", e => {
                                !(function (e) {
                                    if (!e || "string" !== typeof e.nodeName || "script" !== e.nodeName.toLowerCase())
                                        return;
                                    if ("function" !== typeof e.addEventListener) return;
                                    let o = ((a = e.src), (c = a.match(n)), c ? c[1] : null);
                                    let a, c;
                                    if (!o) return;
                                    let u = (function (e) {
                                        let t = e.match(i);
                                        if (t && t.length >= 3) return { key: t[2], parent: s(t[1], window) };
                                        return { key: e, parent: window };
                                    })(o);
                                    if ("function" !== typeof u.parent[u.key]) return;
                                    let d = {};
                                    function f() {
                                        t.emit("jsonp-end", [], d),
                                            e.removeEventListener("load", f, (0, W.m$)(!1)),
                                            e.removeEventListener("error", l, (0, W.m$)(!1));
                                    }
                                    function l() {
                                        t.emit("jsonp-error", [], d),
                                            t.emit("jsonp-end", [], d),
                                            e.removeEventListener("load", f, (0, W.m$)(!1)),
                                            e.removeEventListener("error", l, (0, W.m$)(!1));
                                    }
                                    r.inPlace(u.parent, [u.key], "cb-", d),
                                        e.addEventListener("load", f, (0, W.m$)(!1)),
                                        e.addEventListener("error", l, (0, W.m$)(!1)),
                                        t.emit("new-jsonp", [e.src], d);
                                })(e[0]);
                            }),
                            t
                        );
                    }
                    function Y(e) {
                        const t = Q(e);
                        !0 === X[t.debugId] && (Z.forEach(e => p(Node.prototype, e)), (X[t.debugId] = "unwrapped"));
                    }
                    function Q(e) {
                        return (e || n.ee).get("jsonp");
                    }
                    let J = r(6562);
                    const K = {};
                    function ee(e) {
                        const t = re(e);
                        if (!a.il || K[t.debugId]) return t;
                        K[t.debugId] = !0;
                        let r = d(t),
                            n = J.Yu.MO;
                        return (
                            n &&
                                ((window.MutationObserver = function (e) {
                                    return this instanceof n ? new n(r(e, "fn-")) : n.apply(this, arguments);
                                }),
                                (MutationObserver.prototype = n.prototype)),
                            t
                        );
                    }
                    function te(e) {
                        const t = re(e);
                        !0 === K[t.debugId] && ((window.MutationObserver = J.Yu.MO), (K[t.debugId] = "unwrapped"));
                    }
                    function re(e) {
                        return (e || n.ee).get("mutation");
                    }
                    const ne = {};
                    function ie(e) {
                        const t = ae(e);
                        if (ne[t.debugId]) return t;
                        ne[t.debugId] = !0;
                        let r = n.c,
                            i = d(t),
                            o = J.Yu.PR;
                        return (
                            o &&
                                (function () {
                                    function e(r) {
                                        let n = t.context(),
                                            a = i(r, "executor-", n, null, !1);
                                        const s = Reflect.construct(o, [a], e);
                                        return (
                                            (t.context(s).getCtx = function () {
                                                return n;
                                            }),
                                            s
                                        );
                                    }
                                    (a._A.Promise = e),
                                        Object.defineProperty(e, "name", { value: "Promise" }),
                                        (e.toString = function () {
                                            return o.toString();
                                        }),
                                        Object.setPrototypeOf(e, o),
                                        ["all", "race"].forEach(r => {
                                            const n = o[r];
                                            e[r] = function (e) {
                                                let i = !1;
                                                e?.forEach(e => {
                                                    this.resolve(e).then(a("all" === r), a(!1));
                                                });
                                                const o = n.apply(this, arguments);
                                                return o;
                                                function a(e) {
                                                    return function () {
                                                        t.emit("propagate", [null, !i], o, !1, !1), (i = i || !e);
                                                    };
                                                }
                                            };
                                        }),
                                        ["resolve", "reject"].forEach(r => {
                                            const n = o[r];
                                            e[r] = function (e) {
                                                const r = n.apply(this, arguments);
                                                return e !== r && t.emit("propagate", [e, !0], r, !1, !1), r;
                                            };
                                        }),
                                        (e.prototype = Object.create(o.prototype)),
                                        (e.prototype.constructor = e),
                                        (e.prototype.then = function () {
                                            let e = this,
                                                n = r(e);
                                            n.promise = e;
                                            for (var a = arguments.length, s = new Array(a), c = 0; c < a; c++)
                                                s[c] = arguments[c];
                                            (s[0] = i(s[0], "cb-", n, null, !1)), (s[1] = i(s[1], "cb-", n, null, !1));
                                            const u = o.prototype.then.apply(this, s);
                                            return (n.nextPromise = u), t.emit("propagate", [e, !0], u, !1, !1), u;
                                        }),
                                        t.on("executor-start", function (e) {
                                            (e[0] = i(e[0], "resolve-", this, null, !1)),
                                                (e[1] = i(e[1], "resolve-", this, null, !1));
                                        }),
                                        t.on("executor-err", (e, t, r) => {
                                            e[1](r);
                                        }),
                                        t.on("cb-end", function (e, r, n) {
                                            t.emit("propagate", [n, !0], this.nextPromise, !1, !1);
                                        }),
                                        t.on("propagate", function (e, r, n) {
                                            (this.getCtx && !r) ||
                                                (this.getCtx = function () {
                                                    if (e instanceof Promise) var r = t.context(e);
                                                    return r && r.getCtx ? r.getCtx() : this;
                                                });
                                        });
                                })(),
                            t
                        );
                    }
                    function oe(e) {
                        const t = ae(e);
                        !0 === ne[t.debugId] && ((a._A.Promise = J.Yu.PR), (ne[t.debugId] = "unwrapped"));
                    }
                    function ae(e) {
                        return (e || n.ee).get("promise");
                    }
                    let se = r(3916);
                    const ce = {},
                        ue = XMLHttpRequest,
                        de = "addEventListener",
                        fe = "removeEventListener";
                    function le(e) {
                        let t = ge(e);
                        if (ce[t.debugId]++) return t;
                        ce[t.debugId] = 1;
                        let r = d(t, !0);
                        function n(e) {
                            r.inPlace(e, [de, fe], "-", i);
                        }
                        function i(e, t) {
                            return e[1];
                        }
                        return (
                            "getPrototypeOf" in Object && (a.il && he(document, n), he(a._A, n), he(ue.prototype, n)),
                            t.on(de + "-start", function (e, t) {
                                let n = e[1];
                                if (null !== n && ("function" === typeof n || "object" === typeof n)) {
                                    let i = (0, se.X)(n, "nr@wrapped", () => {
                                        let e = {
                                            object: function () {
                                                if ("function" !== typeof n.handleEvent) return;
                                                return n.handleEvent.apply(n, arguments);
                                            },
                                            function: n,
                                        }[typeof n];
                                        return e ? r(e, "fn-", null, e.name || "anonymous") : n;
                                    });
                                    this.wrapped = e[1] = i;
                                }
                            }),
                            t.on(fe + "-start", function (e) {
                                e[1] = this.wrapped || e[1];
                            }),
                            t
                        );
                    }
                    function he(e, t) {
                        let r = e;
                        for (; "object" === typeof r && !Object.prototype.hasOwnProperty.call(r, de); )
                            r = Object.getPrototypeOf(r);
                        for (var n = arguments.length, i = new Array(n > 2 ? n - 2 : 0), o = 2; o < n; o++)
                            i[o - 2] = arguments[o];
                        r && t(r, ...i);
                    }
                    function pe(e) {
                        const t = ge(e);
                        1 == ce[t.debugId]
                            ? ([de, fe].forEach(e => {
                                  "object" === typeof document && he(document, p, e),
                                      he(a._A, p, e),
                                      he(ue.prototype, p, e);
                              }),
                              (ce[t.debugId] = 1 / 0))
                            : ce[t.debugId]--;
                    }
                    function ge(e) {
                        return (e || n.ee).get("events");
                    }
                    let ve = r(8610);
                    const me = {},
                        be = ["open", "send"];
                    function ye(e) {
                        let t = e || n.ee;
                        const r = Ee(t);
                        if (me[r.debugId]++) return r;
                        (me[r.debugId] = 1), le(t);
                        let i = d(r),
                            o = J.Yu.XHR,
                            s = J.Yu.MO,
                            c = J.Yu.PR,
                            u = J.Yu.SI,
                            f = "readystatechange",
                            l = ["onload", "onerror", "onabort", "onloadstart", "onloadend", "onprogress", "ontimeout"],
                            h = [],
                            p = a._A.XMLHttpRequest.listeners,
                            g = (a._A.XMLHttpRequest = function (e) {
                                let t = new o(e);
                                function n() {
                                    try {
                                        r.emit("new-xhr", [t], t), t.addEventListener(f, m, (0, W.m$)(!1));
                                    } catch (e) {
                                        (0, ve.Z)("An error occured while intercepting XHR", e);
                                        try {
                                            r.emit("internal-error", [e]);
                                        } catch (e) {}
                                    }
                                }
                                return (this.listeners = p ? [...p, n] : [n]), this.listeners.forEach(e => e()), t;
                            });
                        function v(e, t) {
                            i.inPlace(t, ["onreadystatechange"], "fn-", A);
                        }
                        function m() {
                            let e = this,
                                t = r.context(e);
                            e.readyState > 3 && !t.resolved && ((t.resolved = !0), r.emit("xhr-resolved", [], e)),
                                i.inPlace(e, l, "fn-", A);
                        }
                        if (
                            ((function (e, t) {
                                for (let r in e) t[r] = e[r];
                            })(o, g),
                            (g.prototype = o.prototype),
                            i.inPlace(g.prototype, be, "-xhr-", A),
                            r.on("send-xhr-start", (e, t) => {
                                v(e, t),
                                    (function (e) {
                                        h.push(e), s && (b ? b.then(E) : u ? u(E) : ((y = -y), (w.data = y)));
                                    })(t);
                            }),
                            r.on("open-xhr-start", v),
                            s)
                        ) {
                            var b = c && c.resolve();
                            if (!u && !c) {
                                var y = 1,
                                    w = document.createTextNode(y);
                                new s(E).observe(w, { characterData: !0 });
                            }
                        } else
                            t.on("fn-end", e => {
                                (e[0] && e[0].type === f) || E();
                            });
                        function E() {
                            for (let e = 0; e < h.length; e++) v(0, h[e]);
                            h.length && (h = []);
                        }
                        function A(e, t) {
                            return t;
                        }
                        return r;
                    }
                    function we(e) {
                        const t = Ee(e);
                        1 == me[t.debugId]
                            ? (pe(t),
                              (a._A.XMLHttpRequest = J.Yu.XHR),
                              be.forEach(e => {
                                  p(a._A.XMLHttpRequest.prototype, e);
                              }),
                              (me[t.debugId] = 1 / 0))
                            : me[t.debugId]--;
                    }
                    function Ee(e) {
                        return (e || n.ee).get("xhr");
                    }
                },
                8675: (e, t, r) => {
                    "use strict";
                    r.d(t, { t: () => n });
                    const n = r(2325).D.ajax;
                },
                948: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => N });
                    let n = r(6562),
                        i = r(9252),
                        o = r(3916),
                        a = r(2374),
                        s = 1,
                        c = "nr@id";
                    function u(e) {
                        let t = typeof e;
                        return !e || ("object" !== t && "function" !== t)
                            ? -1
                            : e === a._A
                            ? 0
                            : (0, o.X)(e, c, () => {
                                  return s++;
                              });
                    }
                    let d = r(9071);
                    function f(e) {
                        if ("string" === typeof e && e.length) return e.length;
                        if ("object" === typeof e) {
                            if ("undefined" !== typeof ArrayBuffer && e instanceof ArrayBuffer && e.byteLength)
                                return e.byteLength;
                            if ("undefined" !== typeof Blob && e instanceof Blob && e.size) return e.size;
                            if (!("undefined" !== typeof FormData && e instanceof FormData))
                                try {
                                    return JSON.stringify(e).length;
                                } catch (e) {
                                    return;
                                }
                        }
                    }
                    let l = r(8544),
                        h = r(2053),
                        p = r(7264),
                        g = r(6368),
                        v = r(5526);
                    class m {
                        constructor(e) {
                            (this.agentIdentifier = e),
                                (this.generateTracePayload = this.generateTracePayload.bind(this)),
                                (this.shouldGenerateTrace = this.shouldGenerateTrace.bind(this));
                        }
                        generateTracePayload(e) {
                            if (!this.shouldGenerateTrace(e)) return null;
                            let t = (0, n.DL)(this.agentIdentifier);
                            if (!t) return null;
                            let r = (t.accountID || "").toString() || null,
                                i = (t.agentID || "").toString() || null,
                                o = (t.trustKey || "").toString() || null;
                            if (!r || !i) return null;
                            let a = (0, v.M)(),
                                s = (0, v.Ht)(),
                                c = Date.now(),
                                u = { spanId: a, traceId: s, timestamp: c };
                            return (
                                (e.sameOrigin || (this.isAllowedOrigin(e) && this.useTraceContextHeadersForCors())) &&
                                    ((u.traceContextParentHeader = this.generateTraceContextParentHeader(a, s)),
                                    (u.traceContextStateHeader = this.generateTraceContextStateHeader(a, c, r, i, o))),
                                ((e.sameOrigin && !this.excludeNewrelicHeader()) ||
                                    (!e.sameOrigin && this.isAllowedOrigin(e) && this.useNewrelicHeaderForCors())) &&
                                    (u.newrelicHeader = this.generateTraceHeader(a, s, c, r, i, o)),
                                u
                            );
                        }
                        generateTraceContextParentHeader(e, t) {
                            return "00-" + t + "-" + e + "-01";
                        }
                        generateTraceContextStateHeader(e, t, r, n, i) {
                            return i + "@nr=0-1-" + r + "-" + n + "-" + e + "----" + t;
                        }
                        generateTraceHeader(e, t, r, n, i, o) {
                            if (!("function" === typeof a._A?.btoa)) return null;
                            let s = { v: [0, 1], d: { ty: "Browser", ac: n, ap: i, id: e, tr: t, ti: r } };
                            return o && n !== o && (s.d.tk = o), btoa(JSON.stringify(s));
                        }
                        shouldGenerateTrace(e) {
                            return this.isDtEnabled() && this.isAllowedOrigin(e);
                        }
                        isAllowedOrigin(e) {
                            let t = !1,
                                r = {};
                            if (
                                ((0, n.Mt)(this.agentIdentifier, "distributed_tracing") &&
                                    (r = (0, n.P_)(this.agentIdentifier).distributed_tracing),
                                e.sameOrigin)
                            )
                                t = !0;
                            else if (r.allowed_origins instanceof Array)
                                for (let i = 0; i < r.allowed_origins.length; i++) {
                                    let o = (0, g.e)(r.allowed_origins[i]);
                                    if (e.hostname === o.hostname && e.protocol === o.protocol && e.port === o.port) {
                                        t = !0;
                                        break;
                                    }
                                }
                            return t;
                        }
                        isDtEnabled() {
                            let e = (0, n.Mt)(this.agentIdentifier, "distributed_tracing");
                            return !!e && !!e.enabled;
                        }
                        excludeNewrelicHeader() {
                            let e = (0, n.Mt)(this.agentIdentifier, "distributed_tracing");
                            return !!e && !!e.exclude_newrelic_header;
                        }
                        useNewrelicHeaderForCors() {
                            let e = (0, n.Mt)(this.agentIdentifier, "distributed_tracing");
                            return !!e && !1 !== e.cors_use_newrelic_header;
                        }
                        useTraceContextHeadersForCors() {
                            let e = (0, n.Mt)(this.agentIdentifier, "distributed_tracing");
                            return !!e && !!e.cors_use_tracecontext_headers;
                        }
                    }
                    let b = r(6114),
                        y = r(8675),
                        w = r(2325);
                    function E(e, t) {
                        !(function (e, t) {
                            if (t.has(e))
                                throw new TypeError("Cannot initialize the same private elements twice on an object");
                        })(e, t),
                            t.add(e);
                    }
                    let A,
                        T,
                        O,
                        x = ["load", "error", "abort", "timeout"],
                        S = x.length,
                        _ = n.Yu.REQ,
                        I = a._A.XMLHttpRequest,
                        j = new WeakSet();
                    class N extends b.S {
                        constructor(e, t) {
                            let r = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
                            super(e, t, y.t, r),
                                E(this, j),
                                (0, n.OP)(e).xhrWrappable &&
                                    ((this.dt = new m(e)),
                                    (this.handler = (e, t, r, n) => (0, i.p)(e, t, r, n, this.ee)),
                                    (0, p.u5)(this.ee),
                                    (0, p.Kf)(this.ee),
                                    (function (e, t, r, i) {
                                        function o(e) {
                                            let t = this;
                                            (t.totalCbs = 0),
                                                (t.called = 0),
                                                (t.cbTime = 0),
                                                (t.end = R),
                                                (t.ended = !1),
                                                (t.xhrGuids = {}),
                                                (t.lastSize = null),
                                                (t.loadCaptureCalled = !1),
                                                (t.params = this.params || {}),
                                                (t.metrics = this.metrics || {}),
                                                e.addEventListener(
                                                    "load",
                                                    r => {
                                                        C(t, e);
                                                    },
                                                    (0, l.m$)(!1)
                                                ),
                                                d.I ||
                                                    e.addEventListener(
                                                        "progress",
                                                        e => {
                                                            t.lastSize = e.loaded;
                                                        },
                                                        (0, l.m$)(!1)
                                                    );
                                        }
                                        function s(e) {
                                            (this.params = { method: e[0] }), D(this, e[1]), (this.metrics = {});
                                        }
                                        function c(t, r) {
                                            let o = (0, n.DL)(e);
                                            "xpid" in o &&
                                                this.sameOrigin &&
                                                r.setRequestHeader("X-NewRelic-ID", o.xpid);
                                            let a = i.generateTracePayload(this.parsedOrigin);
                                            if (a) {
                                                let s = !1;
                                                a.newrelicHeader &&
                                                    (r.setRequestHeader("newrelic", a.newrelicHeader), (s = !0)),
                                                    a.traceContextParentHeader &&
                                                        (r.setRequestHeader("traceparent", a.traceContextParentHeader),
                                                        a.traceContextStateHeader &&
                                                            r.setRequestHeader("tracestate", a.traceContextStateHeader),
                                                        (s = !0)),
                                                    s && (this.dt = a);
                                            }
                                        }
                                        function p(e, r) {
                                            let n = this.metrics,
                                                i = e[0],
                                                o = this;
                                            if (n && i) {
                                                let a = f(i);
                                                a && (n.txSize = a);
                                            }
                                            (this.startTime = (0, h.zO)()),
                                                (this.listener = function (e) {
                                                    try {
                                                        "abort" !== e.type ||
                                                            o.loadCaptureCalled ||
                                                            (o.params.aborted = !0),
                                                            ("load" !== e.type ||
                                                                (o.called === o.totalCbs &&
                                                                    (o.onloadCalled ||
                                                                        "function" !== typeof r.onload) &&
                                                                    "function" === typeof o.end)) &&
                                                                o.end(r);
                                                    } catch (e) {
                                                        try {
                                                            t.emit("internal-error", [e]);
                                                        } catch (e) {}
                                                    }
                                                });
                                            for (let s = 0; s < S; s++)
                                                r.addEventListener(x[s], this.listener, (0, l.m$)(!1));
                                        }
                                        function v(e, t, r) {
                                            (this.cbTime += e),
                                                t ? (this.onloadCalled = !0) : (this.called += 1),
                                                this.called !== this.totalCbs ||
                                                    (!this.onloadCalled && "function" === typeof r.onload) ||
                                                    "function" !== typeof this.end ||
                                                    this.end(r);
                                        }
                                        function m(e, t) {
                                            let r = "" + u(e) + !!t;
                                            this.xhrGuids &&
                                                !this.xhrGuids[r] &&
                                                ((this.xhrGuids[r] = !0), (this.totalCbs += 1));
                                        }
                                        function b(e, t) {
                                            let r = "" + u(e) + !!t;
                                            this.xhrGuids &&
                                                this.xhrGuids[r] &&
                                                (delete this.xhrGuids[r], (this.totalCbs -= 1));
                                        }
                                        function y() {
                                            this.endTime = (0, h.zO)();
                                        }
                                        function E(e, r) {
                                            r instanceof I &&
                                                "load" === e[0] &&
                                                t.emit("xhr-load-added", [e[1], e[2]], r);
                                        }
                                        function A(e, r) {
                                            r instanceof I &&
                                                "load" === e[0] &&
                                                t.emit("xhr-load-removed", [e[1], e[2]], r);
                                        }
                                        function T(e, t, r) {
                                            t instanceof I &&
                                                ("onload" === r && (this.onload = !0),
                                                ("load" === (e[0] && e[0].type) || this.onload) &&
                                                    (this.xhrCbStart = (0, h.zO)()));
                                        }
                                        function O(e, r) {
                                            this.xhrCbStart &&
                                                t.emit(
                                                    "xhr-cb-time",
                                                    [(0, h.zO)() - this.xhrCbStart, this.onload, r],
                                                    r
                                                );
                                        }
                                        function j(e) {
                                            let t,
                                                r = e[1] || {};
                                            "string" === typeof e[0]
                                                ? (t = e[0])
                                                : e[0] && e[0].url
                                                ? (t = e[0].url)
                                                : a._A?.URL && e[0] && e[0] instanceof URL && (t = e[0].href),
                                                t &&
                                                    ((this.parsedOrigin = (0, g.e)(t)),
                                                    (this.sameOrigin = this.parsedOrigin.sameOrigin));
                                            let n = i.generateTracePayload(this.parsedOrigin);
                                            if (n && (n.newrelicHeader || n.traceContextParentHeader))
                                                if (
                                                    "string" === typeof e[0] ||
                                                    (a._A?.URL && e[0] && e[0] instanceof URL)
                                                ) {
                                                    let o = {};
                                                    for (let s in r) o[s] = r[s];
                                                    (o.headers = new Headers(r.headers || {})),
                                                        c(o.headers, n) && (this.dt = n),
                                                        e.length > 1 ? (e[1] = o) : e.push(o);
                                                } else e[0] && e[0].headers && c(e[0].headers, n) && (this.dt = n);
                                            function c(e, t) {
                                                let r = !1;
                                                return (
                                                    t.newrelicHeader && (e.set("newrelic", t.newrelicHeader), (r = !0)),
                                                    t.traceContextParentHeader &&
                                                        (e.set("traceparent", t.traceContextParentHeader),
                                                        t.traceContextStateHeader &&
                                                            e.set("tracestate", t.traceContextStateHeader),
                                                        (r = !0)),
                                                    r
                                                );
                                            }
                                        }
                                        function N(e, t) {
                                            (this.params = {}),
                                                (this.metrics = {}),
                                                (this.startTime = (0, h.zO)()),
                                                (this.dt = t),
                                                e.length >= 1 && (this.target = e[0]),
                                                e.length >= 2 && (this.opts = e[1]);
                                            let r,
                                                n = this.opts || {},
                                                i = this.target;
                                            "string" === typeof i
                                                ? (r = i)
                                                : "object" === typeof i && i instanceof _
                                                ? (r = i.url)
                                                : a._A?.URL &&
                                                  "object" === typeof i &&
                                                  i instanceof URL &&
                                                  (r = i.href),
                                                D(this, r);
                                            let o = (
                                                "" + ((i && i instanceof _ && i.method) || n.method || "GET")
                                            ).toUpperCase();
                                            (this.params.method = o), (this.txSize = f(n.body) || 0);
                                        }
                                        function P(e, t) {
                                            let n;
                                            (this.endTime = (0, h.zO)()),
                                                this.params || (this.params = {}),
                                                (this.params.status = t ? t.status : 0),
                                                "string" === typeof this.rxSize &&
                                                    this.rxSize.length > 0 &&
                                                    (n = +this.rxSize);
                                            let i = {
                                                txSize: this.txSize,
                                                rxSize: n,
                                                duration: (0, h.zO)() - this.startTime,
                                            };
                                            r(
                                                "xhr",
                                                [this.params, i, this.startTime, this.endTime, "fetch"],
                                                this,
                                                w.D.ajax
                                            );
                                        }
                                        function R(e) {
                                            let t = this.params,
                                                n = this.metrics;
                                            if (!this.ended) {
                                                this.ended = !0;
                                                for (let i = 0; i < S; i++)
                                                    e.removeEventListener(x[i], this.listener, !1);
                                                t.aborted ||
                                                    ((n.duration = (0, h.zO)() - this.startTime),
                                                    this.loadCaptureCalled || 4 !== e.readyState
                                                        ? null == t.status && (t.status = 0)
                                                        : C(this, e),
                                                    (n.cbTime = this.cbTime),
                                                    r(
                                                        "xhr",
                                                        [t, n, this.startTime, this.endTime, "xhr"],
                                                        this,
                                                        w.D.ajax
                                                    ));
                                            }
                                        }
                                        function D(e, t) {
                                            let r = (0, g.e)(t),
                                                n = e.params;
                                            (n.hostname = r.hostname),
                                                (n.port = r.port),
                                                (n.protocol = r.protocol),
                                                (n.host = r.hostname + ":" + r.port),
                                                (n.pathname = r.pathname),
                                                (e.parsedOrigin = r),
                                                (e.sameOrigin = r.sameOrigin);
                                        }
                                        function C(e, t) {
                                            e.params.status = t.status;
                                            let r = (function (e, t) {
                                                let r = e.responseType;
                                                return "json" === r && null !== t
                                                    ? t
                                                    : "arraybuffer" === r || "blob" === r || "json" === r
                                                    ? f(e.response)
                                                    : "text" === r || "" === r || void 0 === r
                                                    ? f(e.responseText)
                                                    : void 0;
                                            })(t, e.lastSize);
                                            if ((r && (e.metrics.rxSize = r), e.sameOrigin)) {
                                                let n = t.getResponseHeader("X-NewRelic-App-Data");
                                                n && (e.params.cat = n.split(", ").pop());
                                            }
                                            e.loadCaptureCalled = !0;
                                        }
                                        t.on("new-xhr", o),
                                            t.on("open-xhr-start", s),
                                            t.on("open-xhr-end", c),
                                            t.on("send-xhr-start", p),
                                            t.on("xhr-cb-time", v),
                                            t.on("xhr-load-added", m),
                                            t.on("xhr-load-removed", b),
                                            t.on("xhr-resolved", y),
                                            t.on("addEventListener-end", E),
                                            t.on("removeEventListener-end", A),
                                            t.on("fn-end", O),
                                            t.on("fetch-before-start", j),
                                            t.on("fetch-start", N),
                                            t.on("fn-start", T),
                                            t.on("fetch-done", P);
                                    })(e, this.ee, this.handler, this.dt),
                                    (this.abortHandler = (function (e, t, r) {
                                        if (!t.has(e))
                                            throw new TypeError("attempted to get private field on non-instance");
                                        return r;
                                    })(this, j, P)),
                                    this.importAggregator());
                        }
                    }
                    function P() {
                        (0, p.Fp)(this.ee), (0, p.ig)(this.ee), (this.abortHandler = void 0);
                    }
                    (A = N),
                        (T = "featureName"),
                        (O = y.t),
                        (T = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })(T)) in A
                            ? Object.defineProperty(A, T, { value: O, enumerable: !0, configurable: !0, writable: !0 })
                            : (A[T] = O);
                },
                8322: (e, t, r) => {
                    "use strict";
                    r.d(t, { A: () => i, t: () => n });
                    const n = r(2325).D.jserrors,
                        i = "nr@seenError";
                },
                9715: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => x });
                    let n,
                        i = r(9252),
                        o = r(2053),
                        a = r(3916),
                        s = r(7264),
                        c = r(8683),
                        u = r.n(c),
                        d = r(3752),
                        f = r(7022),
                        l = {};
                    try {
                        (n = localStorage.getItem("__nr_flags").split(",")),
                            console &&
                                "function" === typeof console.log &&
                                ((l.console = !0),
                                -1 !== n.indexOf("dev") && (l.dev = !0),
                                -1 !== n.indexOf("nr_dev") && (l.nrDev = !0));
                    } catch (e) {}
                    function h(e) {
                        try {
                            l.console && h(e);
                        } catch (e) {}
                    }
                    l.nrDev &&
                        d.ee.on("internal-error", e => {
                            h(e.stack);
                        }),
                        l.dev &&
                            d.ee.on("fn-err", (e, t, r) => {
                                h(r.stack);
                            }),
                        l.dev &&
                            (h("NR AGENT IN DEVELOPMENT MODE"),
                            h(
                                "flags: " +
                                    (0, f.D)(l, (e, t) => {
                                        return e;
                                    }).join(", ")
                            ));
                    let p = r(6114),
                        g = r(8322),
                        v = r(2325),
                        m = r(2374),
                        b = r(8544),
                        y = r(6562);
                    function w(e, t) {
                        !(function (e, t) {
                            if (t.has(e))
                                throw new TypeError("Cannot initialize the same private elements twice on an object");
                        })(e, t),
                            t.add(e);
                    }
                    let E,
                        A,
                        T,
                        O = new WeakSet();
                    class x extends p.S {
                        constructor(e, t) {
                            let r;
                            let n = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
                            super(e, t, g.t, n),
                                (r = this),
                                w(this, O),
                                (this.skipNext = 0),
                                (this.origOnerror = m._A.onerror);
                            try {
                                this.removeOnAbort = new AbortController();
                            } catch (e) {}
                            const c = this;
                            c.ee.on("fn-start", (e, t, r) => {
                                c.abortHandler && (c.skipNext += 1);
                            }),
                                c.ee.on("fn-err", function (e, t, r) {
                                    c.abortHandler &&
                                        !r[g.A] &&
                                        ((0, a.X)(r, g.A, () => {
                                            return !0;
                                        }),
                                        (this.thrown = !0),
                                        I(r, void 0, c.ee));
                                }),
                                c.ee.on("fn-end", function () {
                                    c.abortHandler && !this.thrown && c.skipNext > 0 && (c.skipNext -= 1);
                                }),
                                c.ee.on("internal-error", e => {
                                    (0, i.p)("ierr", [e, (0, o.zO)(), !0], void 0, v.D.jserrors, c.ee);
                                }),
                                (m._A.onerror = function () {
                                    return (
                                        r.origOnerror && r.origOnerror(...arguments), r.onerrorHandler(...arguments), !1
                                    );
                                }),
                                m._A.addEventListener(
                                    "unhandledrejection",
                                    e => {
                                        const t = (function (e) {
                                            let t = "Unhandled Promise Rejection: ";
                                            if (e instanceof Error) return (e.message = t + e.message), e;
                                            if (void 0 === e) return new Error(t);
                                            try {
                                                return new Error(t + JSON.stringify(e));
                                            } catch (e) {
                                                return new Error(t);
                                            }
                                        })(e.reason);
                                        (0, i.p)(
                                            "err",
                                            [t, (0, o.zO)(), !1, { unhandledPromiseRejection: 1 }],
                                            void 0,
                                            v.D.jserrors,
                                            this.ee
                                        );
                                    },
                                    (0, b.m$)(!1, this.removeOnAbort?.signal)
                                ),
                                (0, s.gy)(this.ee),
                                (0, s.BV)(this.ee),
                                (0, s.em)(this.ee),
                                (0, y.OP)(e).xhrWrappable && (0, s.Kf)(this.ee),
                                (this.abortHandler = (function (e, t, r) {
                                    if (!t.has(e))
                                        throw new TypeError("attempted to get private field on non-instance");
                                    return r;
                                })(this, O, S)),
                                this.importAggregator();
                        }
                        onerrorHandler(e, t, r, n, a) {
                            try {
                                this.skipNext ? (this.skipNext -= 1) : I(a || new _(e, t, r), !0, this.ee);
                            } catch (e) {
                                try {
                                    (0, i.p)("ierr", [e, (0, o.zO)(), !0], void 0, v.D.jserrors, this.ee);
                                } catch (e) {}
                            }
                            return (
                                "function" === typeof this.origOnerror && this.origOnerror.apply(this, u()(arguments))
                            );
                        }
                    }
                    function S() {
                        (m._A.onerror = this.origOnerror),
                            this.removeOnAbort?.abort(),
                            (0, s.XV)(this.ee),
                            (0, s.Zw)(this.ee),
                            (0, s.hF)(this.ee),
                            (0, y.OP)(this.agentIdentifier).xhrWrappable && (0, s.ig)(this.ee),
                            (this.abortHandler = void 0);
                    }
                    function _(e, t, r) {
                        (this.message = e || "Uncaught error with no additional information"),
                            (this.sourceURL = t),
                            (this.line = r);
                    }
                    function I(e, t, r) {
                        let n = t ? null : (0, o.zO)();
                        (0, i.p)("err", [e, n], void 0, v.D.jserrors, r);
                    }
                    (E = x),
                        (A = "featureName"),
                        (T = g.t),
                        (A = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })(A)) in E
                            ? Object.defineProperty(E, A, { value: T, enumerable: !0, configurable: !0, writable: !0 })
                            : (E[A] = T);
                },
                6034: (e, t, r) => {
                    "use strict";
                    r.d(t, { t: () => n });
                    const n = r(2325).D.metrics;
                },
                600: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => O, constants: () => x });
                    let n = r(9252),
                        i = r(4045),
                        o = r(6114),
                        a = r(2374),
                        s = {
                            REACT: "React",
                            ANGULAR: "Angular",
                            ANGULARJS: "AngularJS",
                            BACKBONE: "Backbone",
                            EMBER: "Ember",
                            VUE: "Vue",
                            METEOR: "Meteor",
                            ZEPTO: "Zepto",
                            JQUERY: "Jquery",
                        };
                    function c() {
                        if (!a.il) return [];
                        let e = [];
                        try {
                            (function () {
                                try {
                                    if (window.React || window.ReactDOM || window.ReactRedux) return !0;
                                    if (document.querySelector("[data-reactroot], [data-reactid]")) return !0;
                                    for (let e = document.querySelectorAll("body > div"), t = 0; t < e.length; t++)
                                        if (Object.keys(e[t]).indexOf("_reactRootContainer") >= 0) return !0;
                                    return !1;
                                } catch (e) {
                                    return !1;
                                }
                            })() && e.push(s.REACT),
                                (function () {
                                    try {
                                        return (
                                            !!window.angular ||
                                            !!document.querySelector(
                                                ".ng-binding, [ng-app], [data-ng-app], [ng-controller], [data-ng-controller], [ng-repeat], [data-ng-repeat]"
                                            ) ||
                                            !!document.querySelector(
                                                'script[src*="angular.js"], script[src*="angular.min.js"]'
                                            )
                                        );
                                    } catch (e) {
                                        return !1;
                                    }
                                })() && e.push(s.ANGULARJS),
                                (function () {
                                    try {
                                        return (
                                            !!(
                                                window.hasOwnProperty("ng") &&
                                                window.ng.hasOwnProperty("coreTokens") &&
                                                window.ng.coreTokens.hasOwnProperty("NgZone")
                                            ) || !!document.querySelectorAll("[ng-version]").length
                                        );
                                    } catch (e) {
                                        return !1;
                                    }
                                })() && e.push(s.ANGULAR),
                                window.Backbone && e.push(s.BACKBONE),
                                window.Ember && e.push(s.EMBER),
                                window.Vue && e.push(s.VUE),
                                window.Meteor && e.push(s.METEOR),
                                window.Zepto && e.push(s.ZEPTO),
                                window.jQuery && e.push(s.JQUERY);
                        } catch (e) {}
                        return e;
                    }
                    let u = r(9548),
                        d = r(9226),
                        f = r(8226),
                        l = r(2650),
                        h = r(8544),
                        p = r(6562),
                        g = r(8610);
                    const v = {
                        dedicated: Boolean(a._A?.Worker),
                        shared: Boolean(a._A?.SharedWorker),
                        service: Boolean(a._A?.navigator?.serviceWorker),
                    };
                    let m, b, y;
                    let w = r(6034);
                    let E, A, T;
                    class O extends o.S {
                        constructor(e, t) {
                            let r;
                            let n = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
                            super(e, t, w.t, n),
                                (r = this),
                                this.singleChecks(),
                                this.eachSessionChecks(),
                                (0, i.X)(
                                    "record-supportability",
                                    function () {
                                        return r.recordSupportability(...arguments);
                                    },
                                    this.featureName,
                                    this.ee
                                ),
                                (0, i.X)(
                                    "record-custom",
                                    function () {
                                        return r.recordCustom(...arguments);
                                    },
                                    this.featureName,
                                    this.ee
                                ),
                                this.importAggregator();
                        }
                        recordSupportability(e, t) {
                            let r = ["sm", e, { name: e }, t];
                            return (0, n.p)("storeMetric", r, null, this.featureName, this.ee), r;
                        }
                        recordCustom(e, t) {
                            let r = ["cm", e, { name: e }, t];
                            return (0, n.p)("storeEventMetrics", r, null, this.featureName, this.ee), r;
                        }
                        singleChecks() {
                            this.recordSupportability("Generic/Version/".concat(f.q, "/Detected"));
                            const { loaderType: e } = (0, p.OP)(this.agentIdentifier);
                            e && this.recordSupportability("Generic/LoaderType/".concat(e, "/Detected")),
                                a.il &&
                                    (0, l.K)(() => {
                                        c().forEach(e => {
                                            this.recordSupportability("Framework/" + e + "/Detected");
                                        });
                                    }),
                                u.T.isFileProtocol() &&
                                    (this.recordSupportability("Generic/FileProtocol/Detected"),
                                    (u.T.supportabilityMetricSent = !0));
                            const t = (0, d.$c)(this.agentIdentifier);
                            t.length > 0 && this.recordSupportability("Generic/Obfuscate/Detected"),
                                t.length > 0 && !(0, d.Ng)(t) && this.recordSupportability("Generic/Obfuscate/Invalid"),
                                (function (e) {
                                    if (!m) {
                                        if (v.dedicated) {
                                            let t;
                                            m = Worker;
                                            try {
                                                a._A.Worker = r(m, "Dedicated");
                                            } catch (e) {
                                                o(e, "Dedicated");
                                            }
                                            if (v.shared) {
                                                b = SharedWorker;
                                                try {
                                                    a._A.SharedWorker = r(b, "Shared");
                                                } catch (e) {
                                                    o(e, "Shared");
                                                }
                                            } else n("Shared");
                                            if (v.service) {
                                                y = navigator.serviceWorker.register;
                                                try {
                                                    a._A.navigator.serviceWorker.register =
                                                        ((t = y),
                                                        function () {
                                                            for (
                                                                var e = arguments.length, r = new Array(e), n = 0;
                                                                n < e;
                                                                n++
                                                            )
                                                                r[n] = arguments[n];
                                                            return (
                                                                i("Service", r[1]?.type),
                                                                t.apply(navigator.serviceWorker, r)
                                                            );
                                                        });
                                                } catch (e) {
                                                    o(e, "Service");
                                                }
                                            } else n("Service");
                                            return;
                                        }
                                        n("All");
                                    }
                                    function r(e, t) {
                                        return "undefined" === typeof Proxy
                                            ? e
                                            : new Proxy(e, { construct: (e, r) => (i(t, r[1]?.type), new e(...r)) });
                                    }
                                    function n(t) {
                                        a.v6 || e("Workers/".concat(t, "/Unavailable"));
                                    }
                                    function i(t, r) {
                                        e("Workers/".concat(t, "module" === r ? "/Module" : "/Classic"));
                                    }
                                    function o(t, r) {
                                        e("Workers/".concat(r, "/SM/Unsupported")),
                                            (0, g.Z)("NR Agent: Unable to capture ".concat(r, " workers."), t);
                                    }
                                })(this.recordSupportability.bind(this));
                        }
                        eachSessionChecks() {
                            a.il &&
                                (0, h.bP)("pageshow", e => {
                                    e.persisted && this.recordSupportability("Generic/BFCache/PageRestored");
                                });
                        }
                    }
                    (E = O),
                        (A = "featureName"),
                        (T = w.t),
                        (A = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })(A)) in E
                            ? Object.defineProperty(E, A, { value: T, enumerable: !0, configurable: !0, writable: !0 })
                            : (E[A] = T);
                    var x = { SUPPORTABILITY_METRIC: "sm", CUSTOM_METRIC: "cm" };
                },
                6486: (e, t, r) => {
                    "use strict";
                    r.d(t, { t: () => n });
                    const n = r(2325).D.pageAction;
                },
                488: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => c });
                    let n,
                        i,
                        o,
                        a = r(6114),
                        s = r(6486);
                    class c extends a.S {
                        constructor(e, t) {
                            let r = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
                            super(e, t, s.t, r), this.importAggregator();
                        }
                    }
                    (n = c),
                        (i = "featureName"),
                        (o = s.t),
                        (i = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })(i)) in n
                            ? Object.defineProperty(n, i, { value: o, enumerable: !0, configurable: !0, writable: !0 })
                            : (n[i] = o);
                },
                2484: (e, t, r) => {
                    "use strict";
                    r.d(t, { t: () => n });
                    const n = r(2325).D.pageViewEvent;
                },
                5637: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => g });
                    let n,
                        i,
                        o,
                        a = r(9252),
                        s = r(2053),
                        c = r(8283),
                        u = r(6625),
                        d = r(6114),
                        f = r(2650),
                        l = r(2484),
                        h = r(2325),
                        p = r(2374);
                    class g extends d.S {
                        constructor(e, t) {
                            let r = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
                            super(e, t, l.t, r),
                                p.il &&
                                    ((0, u.v)(e),
                                    (0, c.B)(e, "firstbyte", (0, s.yf)()),
                                    (0, f.K)(() => this.measureDomContentLoaded()),
                                    (0, f.b)(() => this.measureWindowLoaded(), !0),
                                    this.importAggregator());
                        }
                        measureWindowLoaded() {
                            let e = (0, s.zO)();
                            (0, c.B)(this.agentIdentifier, "onload", e + (0, s.os)()),
                                (0, a.p)("timing", ["load", e], void 0, h.D.pageViewTiming, this.ee);
                        }
                        measureDomContentLoaded() {
                            (0, c.B)(this.agentIdentifier, "domContent", (0, s.zO)() + (0, s.os)());
                        }
                    }
                    (n = g),
                        (i = "featureName"),
                        (o = l.t),
                        (i = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })(i)) in n
                            ? Object.defineProperty(n, i, { value: o, enumerable: !0, configurable: !0, writable: !0 })
                            : (n[i] = o);
                },
                6382: (e, t, r) => {
                    "use strict";
                    r.d(t, { t: () => n });
                    const n = r(2325).D.pageViewTiming;
                },
                7817: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => g });
                    let n,
                        i,
                        o,
                        a = r(9252),
                        s = r(6998),
                        c = r(8544),
                        u = r(2053),
                        d = r(6562),
                        f = r(6114),
                        l = r(6382),
                        h = r(2325),
                        p = r(2374);
                    class g extends f.S {
                        constructor(e, t) {
                            let r;
                            let n = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
                            if ((super(e, t, l.t, n), (r = this), p.il)) {
                                if (
                                    ((this.pageHiddenTime = (0, s.e)()),
                                    this.performanceObserver,
                                    this.lcpPerformanceObserver,
                                    this.clsPerformanceObserver,
                                    (this.fiRecorded = !1),
                                    "PerformanceObserver" in window && "function" === typeof window.PerformanceObserver)
                                ) {
                                    this.performanceObserver = new PerformanceObserver(function () {
                                        return r.perfObserver(...arguments);
                                    });
                                    try {
                                        this.performanceObserver.observe({ entryTypes: ["paint"] });
                                    } catch (e) {}
                                    this.lcpPerformanceObserver = new PerformanceObserver(function () {
                                        return r.lcpObserver(...arguments);
                                    });
                                    try {
                                        this.lcpPerformanceObserver.observe({
                                            entryTypes: ["largest-contentful-paint"],
                                        });
                                    } catch (e) {}
                                    this.clsPerformanceObserver = new PerformanceObserver(function () {
                                        return r.clsObserver(...arguments);
                                    });
                                    try {
                                        this.clsPerformanceObserver.observe({ type: "layout-shift", buffered: !0 });
                                    } catch (e) {}
                                }
                                this.fiRecorded = !1;
                                ["click", "keydown", "mousedown", "pointerdown", "touchstart"].forEach(e => {
                                    (0, c.iz)(e, function () {
                                        return r.captureInteraction(...arguments);
                                    });
                                }),
                                    (0, s.N)(() => {
                                        (this.pageHiddenTime = (0, u.zO)()),
                                            (0, a.p)(
                                                "docHidden",
                                                [this.pageHiddenTime],
                                                void 0,
                                                h.D.pageViewTiming,
                                                this.ee
                                            );
                                    }, !0),
                                    (0, c.bP)("pagehide", () =>
                                        (0, a.p)("winPagehide", [(0, u.zO)()], void 0, h.D.pageViewTiming, this.ee)
                                    ),
                                    this.importAggregator();
                            }
                        }
                        perfObserver(e, t) {
                            e.getEntries().forEach(e => {
                                "first-paint" === e.name
                                    ? (0, a.p)(
                                          "timing",
                                          ["fp", Math.floor(e.startTime)],
                                          void 0,
                                          h.D.pageViewTiming,
                                          this.ee
                                      )
                                    : "first-contentful-paint" === e.name &&
                                      (0, a.p)(
                                          "timing",
                                          ["fcp", Math.floor(e.startTime)],
                                          void 0,
                                          h.D.pageViewTiming,
                                          this.ee
                                      );
                            });
                        }
                        lcpObserver(e, t) {
                            let r = e.getEntries();
                            if (r.length > 0) {
                                let n = r[r.length - 1];
                                if (this.pageHiddenTime < n.startTime) return;
                                let i = [n],
                                    o = this.addConnectionAttributes({});
                                o && i.push(o), (0, a.p)("lcp", i, void 0, h.D.pageViewTiming, this.ee);
                            }
                        }
                        clsObserver(e) {
                            e.getEntries().forEach(e => {
                                e.hadRecentInput || (0, a.p)("cls", [e], void 0, h.D.pageViewTiming, this.ee);
                            });
                        }
                        addConnectionAttributes(e) {
                            let t = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
                            if (t)
                                return (
                                    t.type && (e["net-type"] = t.type),
                                    t.effectiveType && (e["net-etype"] = t.effectiveType),
                                    t.rtt && (e["net-rtt"] = t.rtt),
                                    t.downlink && (e["net-dlink"] = t.downlink),
                                    e
                                );
                        }
                        captureInteraction(e) {
                            if (e instanceof d.Yu.EV && !this.fiRecorded) {
                                let t = Math.round(e.timeStamp),
                                    r = { type: e.type };
                                this.addConnectionAttributes(r),
                                    t <= (0, u.zO)()
                                        ? (r.fid = (0, u.zO)() - t)
                                        : t > (0, u.os)() && t <= Date.now()
                                        ? ((t -= (0, u.os)()), (r.fid = (0, u.zO)() - t))
                                        : (t = (0, u.zO)()),
                                    (this.fiRecorded = !0),
                                    (0, a.p)("timing", ["fi", t, r], void 0, h.D.pageViewTiming, this.ee);
                            }
                        }
                    }
                    (n = g),
                        (i = "featureName"),
                        (o = l.t),
                        (i = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })(i)) in n
                            ? Object.defineProperty(n, i, { value: o, enumerable: !0, configurable: !0, writable: !0 })
                            : (n[i] = o);
                },
                2628: (e, t, r) => {
                    "use strict";
                    r.r(t),
                        r.d(t, {
                            ADD_EVENT_LISTENER: () => g,
                            BST_RESOURCE: () => a,
                            BST_TIMER: () => l,
                            END: () => u,
                            FEATURE_NAME: () => i,
                            FN_END: () => f,
                            FN_START: () => d,
                            ORIG_EVENT: () => p,
                            PUSH_STATE: () => h,
                            RESOURCE: () => s,
                            RESOURCE_TIMING_BUFFER_FULL: () => o,
                            START: () => c,
                        });
                    let n = r(6562);
                    const i = r(2325).D.sessionTrace,
                        o = "resourcetimingbufferfull",
                        a = "bstResource",
                        s = "resource",
                        c = "-start",
                        u = "-end",
                        d = "fn" + c,
                        f = "fn" + u,
                        l = "bstTimer",
                        h = "pushState",
                        p = n.Yu.EV,
                        g = "addEventListener";
                },
                6649: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => N });
                    let n = r(9252),
                        i = r(7264),
                        o = r(6408),
                        a = r(8544),
                        s = r(2053),
                        c = r(6114),
                        u = r(2628),
                        d = r(2325),
                        f = r(2374);
                    function l(e, t) {
                        !(function (e, t) {
                            if (t.has(e))
                                throw new TypeError("Cannot initialize the same private elements twice on an object");
                        })(e, t),
                            t.add(e);
                    }
                    const {
                            BST_RESOURCE: h,
                            BST_TIMER: p,
                            END: g,
                            FEATURE_NAME: v,
                            FN_END: m,
                            FN_START: b,
                            ADD_EVENT_LISTENER: y,
                            PUSH_STATE: w,
                            RESOURCE: E,
                            RESOURCE_TIMING_BUFFER_FULL: A,
                            START: T,
                            ORIG_EVENT: O,
                        } = u,
                        x = "clearResourceTimings";
                    let S,
                        _,
                        I,
                        j = new WeakSet();
                    class N extends c.S {
                        constructor(e, t) {
                            if (
                                (super(e, t, v, !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2]),
                                l(this, j),
                                !f.il)
                            )
                                return;
                            const r = this.ee;
                            (this.timerEE = (0, i.BV)(r)),
                                (this.rafEE = (0, i.gy)(r)),
                                (0, i.QU)(r),
                                (0, i.em)(r),
                                r.on(b, function (e, t) {
                                    e[0] instanceof O && (this.bstStart = (0, s.zO)());
                                }),
                                r.on(m, function (e, t) {
                                    let i = e[0];
                                    i instanceof O &&
                                        (0, n.p)(
                                            "bst",
                                            [i, t, this.bstStart, (0, s.zO)()],
                                            void 0,
                                            d.D.sessionTrace,
                                            r
                                        );
                                }),
                                this.timerEE.on(b, function (e, t, r) {
                                    (this.bstStart = (0, s.zO)()), (this.bstType = r);
                                }),
                                this.timerEE.on(m, function (e, t) {
                                    (0,
                                    n.p)(p, [t, this.bstStart, (0, s.zO)(), this.bstType], void 0, d.D.sessionTrace, r);
                                }),
                                this.rafEE.on(b, function () {
                                    this.bstStart = (0, s.zO)();
                                }),
                                this.rafEE.on(m, function (e, t) {
                                    (0,
                                    n.p)(p, [t, this.bstStart, (0, s.zO)(), "requestAnimationFrame"], void 0, d.D.sessionTrace, r);
                                }),
                                r.on(w + T, function (e) {
                                    (this.time = (0, s.zO)()), (this.startPath = location.pathname + location.hash);
                                }),
                                r.on(w + g, function (e) {
                                    (0,
                                    n.p)("bstHist", [location.pathname + location.hash, this.startPath, this.time], void 0, d.D.sessionTrace, r);
                                }),
                                (0, o.W)()
                                    ? ((0, n.p)(
                                          h,
                                          [window.performance.getEntriesByType("resource")],
                                          void 0,
                                          d.D.sessionTrace,
                                          r
                                      ),
                                      (function () {
                                          let e = new PerformanceObserver((e, t) => {
                                              let i = e.getEntries();
                                              (0, n.p)(h, [i], void 0, d.D.sessionTrace, r);
                                          });
                                          try {
                                              e.observe({ entryTypes: ["resource"] });
                                          } catch (e) {}
                                      })())
                                    : window.performance[x] &&
                                      window.performance[y] &&
                                      window.performance.addEventListener(
                                          A,
                                          this.onResourceTimingBufferFull,
                                          (0, a.m$)(!1)
                                      ),
                                document.addEventListener("scroll", this.noOp, (0, a.m$)(!1)),
                                document.addEventListener("keypress", this.noOp, (0, a.m$)(!1)),
                                document.addEventListener("click", this.noOp, (0, a.m$)(!1)),
                                (this.abortHandler = (function (e, t, r) {
                                    if (!t.has(e))
                                        throw new TypeError("attempted to get private field on non-instance");
                                    return r;
                                })(this, j, P)),
                                this.importAggregator();
                        }
                        noOp(e) {}
                        onResourceTimingBufferFull(e) {
                            if (
                                ((0, n.p)(
                                    h,
                                    [window.performance.getEntriesByType(E)],
                                    void 0,
                                    d.D.sessionTrace,
                                    this.ee
                                ),
                                window.performance[x])
                            )
                                try {
                                    window.performance.removeEventListener(A, this.onResourceTimingBufferFull, !1);
                                } catch (e) {}
                        }
                    }
                    function P() {
                        window.performance.removeEventListener(A, this.onResourceTimingBufferFull, !1),
                            (0, i.Zw)(this.ee),
                            (0, i.XV)(this.ee),
                            (0, i.lx)(this.ee),
                            (0, i.hF)(this.ee),
                            (this.abortHandler = void 0);
                    }
                    (S = N),
                        (I = v),
                        (_ = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })((_ = "featureName"))) in S
                            ? Object.defineProperty(S, _, { value: I, enumerable: !0, configurable: !0, writable: !0 })
                            : (S[_] = I);
                },
                755: (e, t, r) => {
                    "use strict";
                    r.r(t),
                        r.d(t, {
                            BODY: () => A,
                            CB_END: () => T,
                            CB_START: () => u,
                            END: () => E,
                            FEATURE_NAME: () => i,
                            FETCH: () => x,
                            FETCH_BODY: () => m,
                            FETCH_DONE: () => v,
                            FETCH_START: () => g,
                            FN_END: () => c,
                            FN_START: () => s,
                            INTERACTION: () => l,
                            INTERACTION_API: () => d,
                            INTERACTION_EVENTS: () => o,
                            JSONP_END: () => b,
                            JSONP_NODE: () => p,
                            JS_TIME: () => O,
                            MAX_TIMER_BUDGET: () => a,
                            REMAINING: () => f,
                            SPA_NODE: () => h,
                            START: () => w,
                            originalSetTimeout: () => y,
                        });
                    let n = r(6562);
                    r(2374);
                    const i = r(2325).D.spa,
                        o = ["click", "submit", "keypress", "keydown", "keyup", "change"],
                        a = 999,
                        s = "fn-start",
                        c = "fn-end",
                        u = "cb-start",
                        d = "api-ixn-",
                        f = "remaining",
                        l = "interaction",
                        h = "spaNode",
                        p = "jsonpNode",
                        g = "fetch-start",
                        v = "fetch-done",
                        m = "fetch-body-",
                        b = "jsonp-end",
                        y = n.Yu.ST,
                        w = "-start",
                        E = "-end",
                        A = "-body",
                        T = "cb" + E,
                        O = "jsTime",
                        x = "fetch";
                },
                227: (e, t, r) => {
                    "use strict";
                    r.r(t), r.d(t, { Instrument: () => x });
                    let n = r(7264),
                        i = r(8544),
                        o = r(6114),
                        a = r(6562),
                        s = r(2053),
                        c = r(755),
                        u = r(2374);
                    function d(e, t) {
                        !(function (e, t) {
                            if (t.has(e))
                                throw new TypeError("Cannot initialize the same private elements twice on an object");
                        })(e, t),
                            t.add(e);
                    }
                    const {
                        FEATURE_NAME: f,
                        START: l,
                        END: h,
                        BODY: p,
                        CB_END: g,
                        JS_TIME: v,
                        FETCH: m,
                        FN_START: b,
                        CB_START: y,
                        FN_END: w,
                    } = c;
                    let E,
                        A,
                        T,
                        O = new WeakSet();
                    class x extends o.S {
                        constructor(e, t) {
                            if (
                                (super(e, t, f, !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2]),
                                d(this, O),
                                !u.il)
                            )
                                return;
                            if (!(0, a.OP)(e).xhrWrappable) return;
                            try {
                                this.removeOnAbort = new AbortController();
                            } catch (e) {}
                            let r,
                                o = 0;
                            const c = this.ee.get("tracer"),
                                E = (0, n._L)(this.ee),
                                A = (0, n.Lg)(this.ee),
                                T = (0, n.BV)(this.ee),
                                x = (0, n.Kf)(this.ee),
                                _ = this.ee.get("events"),
                                I = (0, n.u5)(this.ee),
                                j = (0, n.QU)(this.ee),
                                N = (0, n.Gm)(this.ee);
                            function P(e, t) {
                                j.emit("newURL", ["" + window.location, t]);
                            }
                            function R() {
                                o++, (r = window.location.hash), (this[b] = (0, s.zO)());
                            }
                            function D() {
                                o--, window.location.hash !== r && P(0, !0);
                                let e = (0, s.zO)();
                                (this[v] = ~~this[v] + e - this[b]), (this[w] = e);
                            }
                            function C(e, t) {
                                e.on(t, function () {
                                    this[t] = (0, s.zO)();
                                });
                            }
                            this.ee.on(b, R),
                                A.on(y, R),
                                E.on(y, R),
                                this.ee.on(w, D),
                                A.on(g, D),
                                E.on(g, D),
                                this.ee.buffer([b, w, "xhr-resolved"], this.featureName),
                                _.buffer([b], this.featureName),
                                T.buffer(["setTimeout" + h, "clearTimeout" + l, b], this.featureName),
                                x.buffer([b, "new-xhr", "send-xhr" + l], this.featureName),
                                I.buffer([m + l, m + "-done", m + p + l, m + p + h], this.featureName),
                                j.buffer(["newURL"], this.featureName),
                                N.buffer([b], this.featureName),
                                A.buffer(["propagate", y, g, "executor-err", "resolve" + l], this.featureName),
                                c.buffer([b, "no-" + b], this.featureName),
                                E.buffer(["new-jsonp", "cb-start", "jsonp-error", "jsonp-end"], this.featureName),
                                C(I, m + l),
                                C(I, m + "-done"),
                                C(E, "new-jsonp"),
                                C(E, "jsonp-end"),
                                C(E, "cb-start"),
                                j.on("pushState-end", P),
                                j.on("replaceState-end", P),
                                window.addEventListener("hashchange", P, (0, i.m$)(!0, this.removeOnAbort?.signal)),
                                window.addEventListener("load", P, (0, i.m$)(!0, this.removeOnAbort?.signal)),
                                window.addEventListener(
                                    "popstate",
                                    () => {
                                        P(0, o > 1);
                                    },
                                    (0, i.m$)(!0, this.removeOnAbort?.signal)
                                ),
                                (this.abortHandler = (function (e, t, r) {
                                    if (!t.has(e))
                                        throw new TypeError("attempted to get private field on non-instance");
                                    return r;
                                })(this, O, S)),
                                this.importAggregator();
                        }
                    }
                    function S() {
                        this.removeOnAbort?.abort(),
                            (0, n.ow)(this.ee),
                            (0, n.G8)(this.ee),
                            (0, n.Zw)(this.ee),
                            (0, n.ig)(this.ee),
                            (0, n.Fp)(this.ee),
                            (0, n.lx)(this.ee),
                            (0, n.ao)(this.ee),
                            (this.abortHandler = void 0);
                    }
                    (E = x),
                        (T = f),
                        (A = (function (e) {
                            let t = (function (e, t) {
                                if ("object" !== typeof e || null === e) return e;
                                let r = e[Symbol.toPrimitive];
                                if (void 0 !== r) {
                                    let n = r.call(e, t || "default");
                                    if ("object" !== typeof n) return n;
                                    throw new TypeError("@@toPrimitive must return a primitive value.");
                                }
                                return ("string" === t ? String : Number)(e);
                            })(e, "string");
                            return "symbol" === typeof t ? t : String(t);
                        })((A = "featureName"))) in E
                            ? Object.defineProperty(E, A, { value: T, enumerable: !0, configurable: !0, writable: !0 })
                            : (E[A] = T);
                },
                1509: (e, t, r) => {
                    "use strict";
                    r.d(t, { W: () => s });
                    let n = r(6562),
                        i = r(3752),
                        o = r(2384),
                        a = r(6797);
                    class s {
                        constructor(e, t, r) {
                            (this.agentIdentifier = e),
                                (this.aggregator = t),
                                (this.ee = i.ee.get(e, (0, n.OP)(this.agentIdentifier).isolatedBacklog)),
                                (this.featureName = r),
                                (this.blocked = !1),
                                this.checkConfiguration();
                        }
                        checkConfiguration() {
                            if (!(0, n.lF)(this.agentIdentifier)) {
                                let e = { ...(0, a.gG)().info?.jsAttributes };
                                try {
                                    e = { ...e, ...(0, n.C5)(this.agentIdentifier)?.jsAttributes };
                                } catch (e) {}
                                (0, o.j)(this.agentIdentifier, {
                                    ...(0, a.gG)(),
                                    info: { ...(0, a.gG)().info, jsAttributes: e },
                                });
                            }
                        }
                    }
                },
                6114: (e, t, r) => {
                    "use strict";
                    r.d(t, { S: () => c });
                    let n = r(4329),
                        i = r(1509),
                        o = r(2650),
                        a = r(2374),
                        s = r(8610);
                    class c extends i.W {
                        constructor(e, t, r) {
                            let i = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3];
                            super(e, t, r),
                                (this.hasAggregator = !1),
                                (this.auto = i),
                                this.abortHandler,
                                i && (0, n.R)(e, r);
                        }
                        importAggregator() {
                            if (this.hasAggregator || !this.auto) return;
                            this.hasAggregator = !0;
                            const e = async () => {
                                try {
                                    const { lazyLoader: e } = await r.e(729).then(r.bind(r, 8110)),
                                        { Aggregate: t } = await e(this.featureName, "aggregate");
                                    new t(this.agentIdentifier, this.aggregator);
                                } catch (e) {
                                    (0, s.Z)("Downloading ".concat(this.featureName, " failed...")),
                                        this.abortHandler?.();
                                }
                            };
                            a.v6 ? e() : (0, o.b)(() => e(), !0);
                        }
                    }
                },
                2384: (e, t, r) => {
                    "use strict";
                    r.d(t, { j: () => y });
                    let n = r(8683),
                        i = r.n(n),
                        o = r(2325),
                        a = r(6562),
                        s = r(9252),
                        c = r(7022),
                        u = r(3752),
                        d = r(2053),
                        f = r(4329),
                        l = r(2650),
                        h = r(2374),
                        p = r(8610);
                    function g(e) {
                        [
                            "setErrorHandler",
                            "finished",
                            "addToTrace",
                            "inlineHit",
                            "addRelease",
                            "addPageAction",
                            "setCurrentRouteName",
                            "setPageViewName",
                            "setCustomAttribute",
                            "interaction",
                            "noticeError",
                        ].forEach(t => {
                            e[t] = function () {
                                for (var r = arguments.length, n = new Array(r), i = 0; i < r; i++) n[i] = arguments[i];
                                return (function (t) {
                                    for (var r = arguments.length, n = new Array(r > 1 ? r - 1 : 0), i = 1; i < r; i++)
                                        n[i - 1] = arguments[i];
                                    Object.values(e.initializedAgents).forEach(e => {
                                        e.exposed && e.api[t] && e.api[t](...n);
                                    });
                                })(t, ...n);
                            };
                        });
                    }
                    let v = r(6797);
                    const m = { stn: o.D.sessionTrace, err: o.D.jserrors, ins: o.D.pageAction, spa: o.D.spa };
                    const b = {};
                    function y(e) {
                        let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                            n = arguments.length > 2 ? arguments[2] : void 0,
                            y = arguments.length > 3 ? arguments[3] : void 0,
                            { init: w, info: E, loader_config: A, runtime: T = { loaderType: n }, exposed: O = !0 } = t;
                        const x = (0, v.gG)();
                        let S = {};
                        return (
                            E || ((w = x.init), (E = x.info), (A = x.loader_config), (S = x)),
                            h.v6 && (E.jsAttributes = { ...E.jsAttributes, isWorker: !0 }),
                            (0, a.CX)(e, E),
                            (0, a.Dg)(e, w || {}),
                            (0, a.GE)(e, A || {}),
                            (0, a.sU)(e, T),
                            (function (e, t, n) {
                                n || (0, f.R)(e, "api"), g(t);
                                let v = u.ee.get(e),
                                    m = v.get("tracer"),
                                    b = "api-",
                                    y = b + "ixn-";
                                function w() {}
                                (0, c.D)(
                                    ["setErrorHandler", "finished", "addToTrace", "inlineHit", "addRelease"],
                                    (e, r) => {
                                        t[r] = A(b, r, !0, "api");
                                    }
                                ),
                                    (t.addPageAction = A(b, "addPageAction", !0, o.D.pageAction)),
                                    (t.setCurrentRouteName = A(b, "routeName", !0, o.D.spa)),
                                    (t.setPageViewName = function (t, r) {
                                        if ("string" === typeof t)
                                            return (
                                                "/" !== t.charAt(0) && (t = "/" + t),
                                                ((0, a.OP)(e).customTransaction =
                                                    (r || "http://custom.transaction") + t),
                                                A(b, "setPageViewName", !0, "api")()
                                            );
                                    }),
                                    (t.setCustomAttribute = function (t, r) {
                                        const n = (0, a.C5)(e);
                                        return (
                                            (0, a.CX)(e, { ...n, jsAttributes: { ...n.jsAttributes, [t]: r } }),
                                            A(b, "setCustomAttribute", !0, "api")()
                                        );
                                    }),
                                    (t.interaction = function () {
                                        return new w().get();
                                    });
                                let E = (w.prototype = {
                                    createTracer: function (e, t) {
                                        let r = {},
                                            n = this,
                                            i = "function" === typeof t;
                                        return (
                                            (0, s.p)(y + "tracer", [(0, d.zO)(), e, r], n, o.D.spa, v),
                                            function () {
                                                if ((m.emit((i ? "" : "no-") + "fn-start", [(0, d.zO)(), n, i], r), i))
                                                    try {
                                                        return t.apply(this, arguments);
                                                    } catch (e) {
                                                        throw (
                                                            (m.emit(
                                                                "fn-err",
                                                                [
                                                                    arguments,
                                                                    this,
                                                                    "string" === typeof e ? new Error(e) : e,
                                                                ],
                                                                r
                                                            ),
                                                            e)
                                                        );
                                                    } finally {
                                                        m.emit("fn-end", [(0, d.zO)()], r);
                                                    }
                                            }
                                        );
                                    },
                                });
                                function A(e, t, r, n) {
                                    return function () {
                                        return (
                                            (0, s.p)(
                                                "record-supportability",
                                                ["API/" + t + "/called"],
                                                void 0,
                                                o.D.metrics,
                                                v
                                            ),
                                            (0, s.p)(
                                                e + t,
                                                [(0, d.zO)()].concat(i()(arguments)),
                                                r ? null : this,
                                                n,
                                                v
                                            ),
                                            r ? void 0 : this
                                        );
                                    };
                                }
                                function T() {
                                    r.e(439)
                                        .then(r.bind(r, 5692))
                                        .then(t => {
                                            let { setAPI: r } = t;
                                            r(e), (0, f.L)(e, "api");
                                        })
                                        .catch(() => (0, p.Z)("Downloading runtime APIs failed..."));
                                }
                                (0, c.D)(
                                    "actionText,setName,setAttribute,save,ignore,onEnd,getContext,end,get".split(","),
                                    (e, t) => {
                                        E[t] = A(y, t, void 0, o.D.spa);
                                    }
                                ),
                                    (t.noticeError = function (e, t) {
                                        "string" === typeof e && (e = new Error(e)),
                                            (0, s.p)(
                                                "record-supportability",
                                                ["API/noticeError/called"],
                                                void 0,
                                                o.D.metrics,
                                                v
                                            ),
                                            (0, s.p)("err", [e, (0, d.zO)(), !1, t], void 0, o.D.jserrors, v);
                                    }),
                                    h.v6 ? T() : (0, l.b)(() => T(), !0);
                            })(e, S, y),
                            (0, v.Qy)(e, x, "api"),
                            (0, v.Qy)(e, O, "exposed"),
                            h.v6 ||
                                ((0, v.EZ)("activatedFeatures", b),
                                (0, v.EZ)("setToken", t =>
                                    (function (e, t) {
                                        let r = u.ee.get(t);
                                        e &&
                                            "object" === typeof e &&
                                            ((0, c.D)(e, (e, t) => {
                                                if (!t) return (0, s.p)("block-" + e, [], void 0, m[e], r);
                                                t && !b[e] && ((0, s.p)("feat-" + e, [], void 0, m[e], r), (b[e] = !0));
                                            }),
                                            (0, f.L)(t, o.D.pageViewEvent));
                                    })(t, e)
                                )),
                            S
                        );
                    }
                },
                909: (e, t, r) => {
                    "use strict";
                    r.d(t, { Z: () => i, q: () => o });
                    let n = r(2325);
                    function i(e) {
                        switch (e) {
                            case n.D.ajax:
                                return [n.D.jserrors];
                            case n.D.sessionTrace:
                                return [n.D.ajax, n.D.pageViewEvent];
                            case n.D.pageViewTiming:
                                return [n.D.pageViewEvent];
                            default:
                                return [];
                        }
                    }
                    function o(e) {
                        return e === n.D.jserrors ? [] : ["auto"];
                    }
                },
                2325: (e, t, r) => {
                    "use strict";
                    r.d(t, { D: () => n, p: () => i });
                    const n = {
                            ajax: "ajax",
                            jserrors: "jserrors",
                            metrics: "metrics",
                            pageAction: "page_action",
                            pageViewEvent: "page_view_event",
                            pageViewTiming: "page_view_timing",
                            sessionTrace: "session_trace",
                            spa: "spa",
                        },
                        i = {
                            [n.pageViewEvent]: 1,
                            [n.pageViewTiming]: 2,
                            [n.metrics]: 3,
                            [n.jserrors]: 4,
                            [n.ajax]: 5,
                            [n.sessionTrace]: 6,
                            [n.pageAction]: 7,
                            [n.spa]: 8,
                        };
                },
                8683: e => {
                    e.exports = function (e, t, r) {
                        t || (t = 0), void 0 === r && (r = e ? e.length : 0);
                        for (var n = -1, i = r - t || 0, o = Array(i < 0 ? 0 : i); ++n < i; ) o[n] = e[t + n];
                        return o;
                    };
                },
            },
            n = {};
        function i(e) {
            let t = n[e];
            if (void 0 !== t) return t.exports;
            let o = (n[e] = { exports: {} });
            return r[e](o, o.exports, i), o.exports;
        }
        (i.m = r),
            (i.n = e => {
                let t = e && e.__esModule ? () => e.default : () => e;
                return i.d(t, { a: t }), t;
            }),
            (i.d = (e, t) => {
                for (let r in t) i.o(t, r) && !i.o(e, r) && Object.defineProperty(e, r, { enumerable: !0, get: t[r] });
            }),
            (i.f = {}),
            (i.e = e => Promise.all(Object.keys(i.f).reduce((t, r) => (i.f[r](e, t), t), []))),
            (i.u = e =>
                (({
                    78: "page_action-aggregate",
                    147: "metrics-aggregate",
                    193: "session_trace-aggregate",
                    225: "ajax-instrument",
                    317: "jserrors-aggregate",
                    348: "page_view_timing-aggregate",
                    439: "async-api",
                    578: "jserrors-instrument",
                    729: "lazy-loader",
                    757: "session_trace-instrument",
                    786: "page_view_event-aggregate",
                    873: "spa-aggregate",
                    876: "spa-instrument",
                    898: "ajax-aggregate",
                    908: "page_action-instrument",
                }[e] || e) +
                "." +
                {
                    78: "92657d87",
                    118: "d37755e4",
                    147: "b4a54ed9",
                    193: "94c80cda",
                    225: "1e8aaf7f",
                    264: "bcaf68fc",
                    317: "ef250e1c",
                    348: "e791ce32",
                    439: "6bb277af",
                    578: "1eaebfa5",
                    729: "48127245",
                    757: "64f1d623",
                    786: "29613e65",
                    873: "6bec5056",
                    876: "f3685aa9",
                    898: "fc672923",
                    908: "64360627",
                }[e] +
                "-1225.min.js")),
            (i.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t)),
            (e = {}),
            (t = "NRBA:"),
            (i.l = (r, n, o, a) => {
                if (e[r]) e[r].push(n);
                else {
                    let s, c;
                    if (void 0 !== o)
                        for (let u = document.getElementsByTagName("script"), d = 0; d < u.length; d++) {
                            let f = u[d];
                            if (f.getAttribute("src") == r || f.getAttribute("data-webpack") == t + o) {
                                s = f;
                                break;
                            }
                        }
                    s ||
                        ((c = !0),
                        ((s = document.createElement("script")).charset = "utf-8"),
                        (s.timeout = 120),
                        i.nc && s.setAttribute("nonce", i.nc),
                        s.setAttribute("data-webpack", t + o),
                        (s.src = r)),
                        (e[r] = [n]);
                    var l = (t, n) => {
                            (s.onerror = s.onload = null), clearTimeout(h);
                            let i = e[r];
                            if (
                                (delete e[r], s.parentNode && s.parentNode.removeChild(s), i && i.forEach(e => e(n)), t)
                            )
                                return t(n);
                        },
                        h = setTimeout(l.bind(null, void 0, { type: "timeout", target: s }), 12e4);
                    (s.onerror = l.bind(null, s.onerror)),
                        (s.onload = l.bind(null, s.onload)),
                        c && document.head.appendChild(s);
                }
            }),
            (i.r = e => {
                "undefined" !== typeof Symbol &&
                    Symbol.toStringTag &&
                    Object.defineProperty(e, Symbol.toStringTag, { value: "Module" }),
                    Object.defineProperty(e, "__esModule", { value: !0 });
            }),
            (i.p = "https://js-agent.newrelic.com/"),
            (() => {
                let e = { 570: 0, 939: 0, 225: 0, 578: 0, 908: 0, 757: 0, 876: 0, 264: 0 };
                i.f.j = (t, r) => {
                    let n = i.o(e, t) ? e[t] : void 0;
                    if (0 !== n)
                        if (n) r.push(n[2]);
                        else {
                            let o = new Promise((r, i) => (n = e[t] = [r, i]));
                            r.push((n[2] = o));
                            let a = i.p + i.u(t),
                                s = new Error();
                            i.l(
                                a,
                                r => {
                                    if (i.o(e, t) && (0 !== (n = e[t]) && (e[t] = void 0), n)) {
                                        let o = r && ("load" === r.type ? "missing" : r.type),
                                            a = r && r.target && r.target.src;
                                        (s.message = "Loading chunk " + t + " failed.\n(" + o + ": " + a + ")"),
                                            (s.name = "ChunkLoadError"),
                                            (s.type = o),
                                            (s.request = a),
                                            n[1](s);
                                    }
                                },
                                "chunk-" + t,
                                t
                            );
                        }
                };
                let t = (t, r) => {
                        let n,
                            o,
                            [a, s, c] = r,
                            u = 0;
                        if (a.some(t => 0 !== e[t])) {
                            for (n in s) i.o(s, n) && (i.m[n] = s[n]);
                            if (c) c(i);
                        }
                        for (t && t(r); u < a.length; u++) (o = a[u]), i.o(e, o) && e[o] && e[o][0](), (e[o] = 0);
                    },
                    r = (window.webpackChunkNRBA = window.webpackChunkNRBA || []);
                r.forEach(t.bind(null, 0)), (r.push = t.bind(null, r.push.bind(r)));
            })();
        let o = {};
        (() => {
            "use strict";
            i.r(o);
            let e = i(2325),
                t = i(6562);
            const r = Object.values(e.D);
            function n(e) {
                const n = {};
                return (
                    r.forEach(r => {
                        n[r] = (function (e, r) {
                            return !1 !== (0, t.Mt)(r, "".concat(e, ".enabled"));
                        })(r, e);
                    }),
                    n
                );
            }
            let a = i(2384),
                s = i(909),
                c = i(9557),
                u = i(7022);
            class d extends c.w {
                constructor(e) {
                    super(e), (this.aggregatedData = {});
                }
                store(e, t, r, n, i) {
                    let o = this.getBucket(e, t, r, i);
                    return (
                        (o.metrics = (function (e, t) {
                            t || (t = { count: 0 });
                            return (
                                (t.count += 1),
                                (0, u.D)(e, (e, r) => {
                                    t[e] = f(r, t[e]);
                                }),
                                t
                            );
                        })(n, o.metrics)),
                        o
                    );
                }
                merge(e, t, r, n, i) {
                    let o = this.getBucket(e, t, n, i);
                    if (o.metrics) {
                        let a = o.metrics;
                        (a.count += r.count),
                            (0, u.D)(r, (e, t) => {
                                if ("count" !== e) {
                                    let n = a[e],
                                        i = r[e];
                                    i && !i.c
                                        ? (a[e] = f(i.t, n))
                                        : (a[e] = (function (e, t) {
                                              if (!t) return e;
                                              t.c || (t = l(t.t));
                                              return (
                                                  (t.min = Math.min(e.min, t.min)),
                                                  (t.max = Math.max(e.max, t.max)),
                                                  (t.t += e.t),
                                                  (t.sos += e.sos),
                                                  (t.c += e.c),
                                                  t
                                              );
                                          })(i, a[e]));
                                }
                            });
                    } else o.metrics = r;
                }
                storeMetric(e, t, r, n) {
                    let i = this.getBucket(e, t, r);
                    return (i.stats = f(n, i.stats)), i;
                }
                getBucket(e, t, r, n) {
                    this.aggregatedData[e] || (this.aggregatedData[e] = {});
                    let i = this.aggregatedData[e][t];
                    return i || ((i = this.aggregatedData[e][t] = { params: r || {} }), n && (i.custom = n)), i;
                }
                get(e, t) {
                    return t ? this.aggregatedData[e] && this.aggregatedData[e][t] : this.aggregatedData[e];
                }
                take(e) {
                    for (var t = {}, r = "", n = !1, i = 0; i < e.length; i++)
                        (t[(r = e[i])] = h(this.aggregatedData[r])),
                            t[r].length && (n = !0),
                            delete this.aggregatedData[r];
                    return n ? t : null;
                }
            }
            function f(e, t) {
                return null == e
                    ? (function (e) {
                          e ? e.c++ : (e = { c: 1 });
                          return e;
                      })(t)
                    : t
                    ? (t.c || (t = l(t.t)),
                      (t.c += 1),
                      (t.t += e),
                      (t.sos += e * e),
                      e > t.max && (t.max = e),
                      e < t.min && (t.min = e),
                      t)
                    : { t: e };
            }
            function l(e) {
                return { t: e, min: e, max: e, sos: e * e, c: 1 };
            }
            function h(e) {
                return "object" !== typeof e ? [] : (0, u.D)(e, p);
            }
            function p(e, t) {
                return t;
            }
            let g = i(6797),
                v = i(5526),
                m = i(8610);
            let b = i(5637),
                y = i(7817),
                w = i(600),
                E = i(9715),
                A = i(948),
                T = i(6649),
                O = i(227),
                x = i(488);
            new (class {
                constructor(t) {
                    let r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (0, v.ky)(16);
                    (this.agentIdentifier = r),
                        (this.sharedAggregator = new d({ agentIdentifier: this.agentIdentifier })),
                        (this.features = {}),
                        (this.desiredFeatures = t.features || []),
                        this.desiredFeatures.sort((t, r) => e.p[t.featureName] - e.p[r.featureName]),
                        Object.assign(this, (0, a.j)(this.agentIdentifier, t, t.loaderType || "agent")),
                        this.start();
                }
                get config() {
                    return {
                        info: (0, t.C5)(this.agentIdentifier),
                        init: (0, t.P_)(this.agentIdentifier),
                        loader_config: (0, t.DL)(this.agentIdentifier),
                        runtime: (0, t.OP)(this.agentIdentifier),
                    };
                }
                start() {
                    const e = "features";
                    try {
                        const t = n(this.agentIdentifier);
                        this.desiredFeatures.forEach(e => {
                            if (t[e.featureName]) {
                                const r = (0, s.Z)(e.featureName);
                                r.every(e => t[e]) ||
                                    (0, m.Z)(
                                        ""
                                            .concat(
                                                e.featureName,
                                                " is enabled but one or more dependent features has been disabled ("
                                            )
                                            .concat(
                                                JSON.stringify(r),
                                                "). This may cause unintended consequences or missing data..."
                                            )
                                    ),
                                    (this.features[e.featureName] = new e(this.agentIdentifier, this.sharedAggregator));
                            }
                        }),
                            (0, g.Qy)(this.agentIdentifier, this.features, e);
                    } catch (t) {
                        (0, m.Z)("Failed to initialize all enabled instrument classes (agent aborted) -", t);
                        for (const e in this.features) this.features[e].abortHandler?.();
                        const r = (0, g.fP)();
                        return (
                            delete r.initializedAgents[this.agentIdentifier]?.api,
                            delete r.initializedAgents[this.agentIdentifier]?.[e],
                            delete this.sharedAggregator,
                            delete r.ee?.get(this.agentIdentifier),
                            !1
                        );
                    }
                }
            })({
                features: [
                    A.Instrument,
                    b.Instrument,
                    y.Instrument,
                    T.Instrument,
                    w.Instrument,
                    x.Instrument,
                    E.Instrument,
                    O.Instrument,
                ],
                loaderType: "spa",
            });
        })(),
            (window.NRBA = o);
    })();
}
/* eslint-enable */

import * as React from "react";
import "./TermsAndConditionsForm.scss";
import { UserAgreementCollapse } from "./UserAgreementCollapse";
import { createBemFn } from "../../../utilities/bem";
import { Button } from "../../..";
import { ButtonShade, ButtonStyle } from "../../../buttons/shared";
import { Spinner } from "../../Spinner/Spinner";
import { GenericError } from "./GenericError";
import classnames from "classnames";
import { useIsInsideIframe } from "../../../utilities/CustomHooks/useIsInsideIframe";

const bem = createBemFn("lxs-account-terms-and-conditions-form");

interface TermsAndConditionsFormProps {
    onAccept: () => Promise<void>;
    buttonText: string;
    privacyUrl: string;
    termsUrl: string;
    privacyTitle: string;
    termsTitle: string;
}

const TermsAndConditionsForm: React.FC<TermsAndConditionsFormProps> = ({
    onAccept,
    buttonText,
    privacyUrl,
    termsUrl,
    privacyTitle,
    termsTitle,
}) => {
    const [processing, setProcessing] = React.useState(false);
    const [error, setError] = React.useState<Error>();
    const handleSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true);
        onAccept()
            .then(() => setError(undefined))
            .catch((err) => {
                setError(err instanceof Error ? err : new Error());
                setProcessing(false);
            });
    }, []);

    const handleToastClose = React.useCallback(() => setError(undefined), []);

    const isInsideIframe = useIsInsideIframe();

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>Terms And Conditions</h2>
            <UserAgreementCollapse title={privacyTitle} url={privacyUrl} />
            <UserAgreementCollapse title={termsTitle} url={termsUrl} openByDefault />
            {processing && <Spinner />}
            {error && <GenericError isOpen={!!error} error={error} onClose={handleToastClose} />}
            <div className={classnames(bem("bottom"), { [bem("bottom", "iframe")]: isInsideIframe })}>
                <div className={bem("button-wrapper")}>
                    <Button buttonStyle={ButtonStyle.Primary} shade={ButtonShade.Dynamic} disabled={processing}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export { TermsAndConditionsForm };

import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGMail: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M15 2.5H1C0.4485 2.5 0 2.9485 0 3.5V12.5C0 13.0515 0.4485 13.5 1 13.5H15C15.5515 13.5 16 13.0515 16 12.5V3.5C16 2.9485 15.5515 2.5 15 2.5ZM1 4.829L5.9435 8.344L1 12.3155V4.829ZM6.3665 8.6455L7.855 9.704C7.8985 9.7345 7.949 9.75 8 9.75C8.051 9.75 8.1015 9.7345 8.145 9.704L9.628 8.6495L14.47 12.5H1.5685L6.3665 8.6455ZM10.052 8.3475L15 4.829L15.0005 12.2835L10.052 8.3475ZM15 3.5V4.2155L8 9.193L1 4.2155V3.5H15Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGMail = React.memo(SVGMail);

export { SVGMail };

import * as React from "react";
import { RouteComponentProps } from "react-router";
import ErrorCard from "../ErrorCard/ErrorCard";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import { ButtonShade, ButtonStyle } from "lexus-style-guide/buttons/shared";
import { closeToastUrl, RouteSection, routeString } from "../../../helpers/routes";

interface Props extends RouteComponentProps {
    destinationRouteSection?: RouteSection;
}

const BookVehicleClearProgressErrorCard: React.FC<Props> = ({ location, history, destinationRouteSection }) => {
    const closeClicked = () => history.push(closeToastUrl(location.pathname));

    const continueClicked = () => {
        const baseRoute = [RouteSection.Encore, RouteSection.OnDemand, RouteSection.Bookings];
        destinationRouteSection
            ? history.push(routeString(...baseRoute, destinationRouteSection))
            : history.push(routeString(...baseRoute));
    };

    return (
        <ErrorCard title={"editing previous sections may clear progress"}>
            <ErrorCardFinePrint>
                Changing an earlier section may effect the availability of vehicles, dates and times. In those cases,
                progress will be cleared.
            </ErrorCardFinePrint>
            <ErrorCardButton shade={ButtonShade.Light} buttonStyle={ButtonStyle.Primary} onClick={continueClicked}>
                continue
            </ErrorCardButton>
            <ErrorCardButton shade={ButtonShade.Light} buttonStyle={ButtonStyle.Secondary} onClick={closeClicked}>
                close
            </ErrorCardButton>
        </ErrorCard>
    );
};

export default BookVehicleClearProgressErrorCard;

import styles from "./Responsive.module.scss";

type ResponsiveProps = React.PropsWithChildren<{
  breakpoint: "small" | "large";
}>;

const classNamesByBreakpoint = {
  small: styles.showForSmall,
  large: styles.showForLarge,
};

export const Responsive: React.FC<ResponsiveProps> = ({ breakpoint, children }) => {
  return <div className={classNamesByBreakpoint[breakpoint]}>{children}</div>;
};

import cn from "classnames";

import { iconRenderer } from "@/components/SVGIcon/static/SVGSelector";

import styles from "./PrimaryNavStaticLinks.module.scss";
import { MenuItemWithIconData } from "../types/types";

export type PrimaryNavStaticLinksProps = {
  /**
   * Items to be rendered
   */
  items: MenuItemWithIconData[];
  /**
   * Set how the items are rendered, either vertical or horizontal. Default: vertical.
   */
  layout?: "horizontal" | "vertical";
  /**
   * When true, locks the button in its hovered state. Should rarely be used outside VRT.
   */
  isPseudoHovered?: boolean;
  /**
   * When true, locks the button in its focused state. Should rarely be used outside VRT.
   */
  isPseudoFocused?: boolean;
  dataTestId?: string;
};

const PrimaryNavStaticLinks: React.FC<PrimaryNavStaticLinksProps> = ({
  items,
  layout = "vertical",
  isPseudoFocused,
  isPseudoHovered,
  dataTestId = "lk-pr-nav-st-lk-g",
}) => {
  return (
    <div
      className={cn(styles.primaryNavStatic, {
        [`${styles.primaryNavStaticHorizontal}`]: layout === "horizontal",
      })}
      data-testid={dataTestId}
    >
      {items.map((item, index) => {
        const ItemTag = (item.link?.url ? "a" : "button") as keyof JSX.IntrinsicElements;

        return (
          (item.link?.url || item.onClick) && (
            <div
              className={cn(styles.primaryNavStaticItem, {
                [`${styles.primaryNavStaticHorizontal}`]: layout === "horizontal",
              })}
              key={item.link?.label}
              data-testid={`lk-pr-nav-st-lk-i-${index}`}
            >
              <ItemTag
                href={item.link?.url}
                target={item.link?.url ? item.link?.target || "" : undefined}
                onClick={item.link?.url ? undefined : item.onClick}
                className={cn(styles.primaryNavItemText, {
                  [`${styles.primaryNavItemTextHovered}`]: isPseudoHovered,
                  [`${styles.primaryNavItemTextFocused}`]: isPseudoFocused,
                })}
              >
                <span
                  className={cn(styles.primaryNavIcon, {
                    [`${styles.primaryNavIconHorizontal}`]: layout === "horizontal",
                  })}
                >
                  {iconRenderer(item.icon, {
                    width: 36,
                    height: 36,
                    className: styles.primaryNavIcon,
                  })}
                </span>
                {item.link?.label}
              </ItemTag>
            </div>
          )
        );
      })}
    </div>
  );
};

export { PrimaryNavStaticLinks };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGDropPin: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width} height={height} fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0c6.6 0 12 5.1 12 11.4 0 5.9-10 18.7-11.1 20.2-.2.2-.6.4-.9.4-.3 0-.7-.2-.9-.4C14 30.1 4 17.3 4 11.4 4 5.1 9.4 0 16 0zm0 29.2c3.4-4.5 9.8-13.8 9.8-17.8 0-5.1-4.4-9.3-9.8-9.3s-9.8 4.2-9.8 9.3c0 4 6.4 13.3 9.8 17.8zm0-21.7c2.4 0 4.4 1.9 4.4 4.2 0 2.4-2 4.3-4.4 4.3-2.4 0-4.4-1.9-4.4-4.3 0-2.3 2-4.2 4.4-4.2zm0 6.4c1.2 0 2.2-1 2.2-2.2 0-1.1-1-2.1-2.2-2.1-1.2 0-2.2 1-2.2 2.1 0 1.2 1 2.2 2.2 2.2z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGDropPin = React.memo(SVGDropPin);

export { SVGDropPin };

import cn from "classnames";

import styles from "./Chip.module.scss";
import { Stack } from "../Stack";
import { Typography } from "../Typography/Typography";

export type ChipProps = React.HTMLAttributes<HTMLDivElement> & {
  icon?: React.ReactNode;
  fullWidth?: boolean;
  state?: "enabled" | "selected" | "success";
  type?: "solid" | "ghost";
  size?: "small" | "large";
  disabled?: boolean;
};

const Chip: React.FC<ChipProps> = (props) => {
  const {
    children,
    className,
    type = "solid",
    size = "large",
    icon,
    fullWidth,
    state = "enabled",
    disabled,
    ...rest
  } = props;

  return (
    <div
      className={cn(
        styles.chip,
        {
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled,
        },
        styles[type],
        styles[size],
        styles[state],
        className
      )}
      {...rest}
    >
      <Stack spacing="3xs" alignItems="center" className={styles.truncateText}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {children && (
          <Typography variant="l1" className={styles.truncateText}>
            {children}
          </Typography>
        )}
      </Stack>
    </div>
  );
};

export { Chip };

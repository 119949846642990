import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGModalOpen: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M7 12H11V10H7V12Z" fill={color} />
        <path d="M0 0V2H18V0H0Z" fill={color} />
        <path d="M3 7H15V5H3V7Z" fill={color} />
    </svg>
    /* eslint-enable max-len */
);
SVGModalOpen = React.memo(SVGModalOpen);

export { SVGModalOpen };

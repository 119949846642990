import styles from "./CardWithHeader.module.scss";
import { Box } from "../Box";
import { Stack } from "../Stack";

export const CardWithHeaderContent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box py="l" px="s" className={styles.cardWithHeaderContent}>
      <Stack direction="column" spacing="l" justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </Box>
  );
};

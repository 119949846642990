import { BarGraphItem } from "./BarGraph";

export const OPACITY_START = 1;
export const OPACITY_END = 0.15;

export const getTotalValue = (items: BarGraphItem[]): number =>
  items.reduce((accumulator, currentItem) => accumulator + currentItem.value, 0);

const calculateOpacity = (totalItems: number, itemIndex: number) => {
  const opacityRange = OPACITY_START - OPACITY_END;
  const step = opacityRange / (totalItems - 1);
  const opacity = OPACITY_START - step * itemIndex;
  return opacity;
};

export const getOpacityList = (items: BarGraphItem[]): number[] => {
  const opacityList = [];

  if (getTotalValue(items) === 0) {
    return Array.from({ length: items.length }, () => OPACITY_START);
  }

  for (let i = 0; i < items.length; i++) {
    if (i === 0) {
      opacityList.push(OPACITY_START);
    } else if (i === items.length - 1) {
      opacityList.push(OPACITY_END);
    } else {
      const opacity = calculateOpacity(items.length, i);
      opacity && opacityList.push(Number(opacity?.toFixed(2)));
    }
  }
  return opacityList;
};

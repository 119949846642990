import React, { FunctionComponent } from "react";
import { Switch, Route, Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RouteSection, baseRoutes } from "../../helpers/routes";
import { routeString } from "../../helpers/routes";
import { without } from "lodash";
import { isErrorCodeOfMagicLink } from "../../helpers/account";
import { isRegisterGuestErrorSelector, registerGuestErrorCodeSelector } from "../../reduxSlices/user";

const ErrorGuard: FunctionComponent<RouteComponentProps> = ({ children }) => {
    const isRegisterGuestError = useSelector(isRegisterGuestErrorSelector);
    const errorCode = useSelector(registerGuestErrorCodeSelector);
    const isMagicLinkError = isErrorCodeOfMagicLink(errorCode);

    if (isRegisterGuestError) {
        const errorRoute = isMagicLinkError
            ? routeString(RouteSection.LinkExpiredError)
            : `/${RouteSection.WildcardPath}/${RouteSection.Notify}`;

        const allowedRoutes = [errorRoute, routeString(RouteSection.AccountSettings)];

        return (
            <Switch>
                <Route path={allowedRoutes} render={() => children} />
                <Redirect to={errorRoute} />
            </Switch>
        );
    } else {
        const allowedRouteNames = without(baseRoutes, RouteSection.LinkExpiredError);
        const allowedRoutes = allowedRouteNames.map(r => routeString(r as RouteSection));

        return (
            <Switch>
                <Route path={allowedRoutes} render={() => children} />
                <Redirect to={routeString(RouteSection.Dashboard)} />
            </Switch>
        );
    }
};

export default withRouter(ErrorGuard);

import cn from "classnames";

import styles from "./ButtonGroup.module.scss";

type ButtonGroupProps = React.PropsWithChildren<{
  isSmall?: boolean;
}>;

/**
 * Consumers can place one or two buttons in `<ButtonGroup>` component.
 * The spacing and responsive behavior will be automatically handled for comsumers.
 */
export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, isSmall = false }) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.containerSmall]: isSmall,
      })}
    >
      <span className={styles.inner}>{children}</span>
    </div>
  );
};

import { Action, ReducerMapValue, createAction, handleActions } from "redux-actions";
import { Dispatch, StateSelector } from "../types/general";
import {
    FetchAction,
    FetchActionPayload,
    FetchContainer,
    PromiseThunk,
    authorizedFetch,
    createFetchThunk,
    fetchActionPayloadToContainer,
    getFetchContainerError,
    getFetchContainerValue,
    initialFetchContainer,
    json,
    status,
} from "../helpers/fetch";
import {
    getAllRewardsCategoriesUrl,
    getCaltexCouponUrl,
    getRewardCouponUrl,
    getRewardsOnCategoryUrl,
} from "../apiHref";

import { LoadStatus } from "../types/loadStatus";
import { RootLevelAction } from "./rootLevelAction";
import { createSelector } from "reselect";
import { settingsPromise } from "../settings";

export interface CaltexRewardCoupon {
    caltexId: string;
    dailyClaimedStatus: string;
    lastRedemptionDate: string | null;
    memberId: string;
    vin: string;
}

export interface Rewards {
    name: string;
    description: string;
    link: string;
    backgroundImage: string;
    logo: string;
    encoreTier: string;
    date: string;
    locations: string[];
    offset: number;
    subtitle: string;
    tags: string[];
    total: number;
    category: string;
}

export interface RewardsCategoriesResponse {
    id: string;
    title: string;
    explorePageText: string;
    tags: RewardsTag[];
}

export interface RewardsTag {
    id: string;
    name: string;
}

type FetchCaltexRewardCouponFetchContainer = FetchContainer<CaltexRewardCoupon>;
type FetchRewardsFetchContainer = FetchContainer<Rewards[]>;
type FetchAllRewardsCategoriesFetchContainer = FetchContainer<RewardsCategoriesResponse[]>;

type FetchCaltexRewardCouponFetchActionPayload = FetchActionPayload<CaltexRewardCoupon>;
type FetchRewardsFetchActionPayload = FetchActionPayload<Rewards[]>;
type FetchRewardsCategoriesFetchActionPayload = FetchActionPayload<RewardsCategoriesResponse[]>;

export interface FetchAllRewardsFetchActionPayload {
    [key: string]: FetchRewardsFetchContainer;
}

//#region state
export interface RewardState {
    caltexRewardCoupon: FetchCaltexRewardCouponFetchContainer;
    categories: FetchAllRewardsCategoriesFetchContainer;
    newRewards: FetchAllRewardsFetchActionPayload;
}

export const initialRewardState: RewardState = {
    caltexRewardCoupon: initialFetchContainer,
    categories: initialFetchContainer,
    newRewards: {},
};
//#endregion

//#region actions
export enum RewardAction {
    FetchCaltexRewardCoupon = "FetchCaltexRewardCoupon",
    FetchForYouRewards = "FetchForYouRewards",
    FetchExclusiveEventsRewards = "FetchExclusiveEventsRewards",
    FetchAllRewardsCategories = "FetchAllRewardsCategories",
    FetchNewRewards = "FetchNewRewards",
}

const fetchCaltexRewardCouponAction = createAction<FetchCaltexRewardCouponFetchActionPayload>(
    RewardAction.FetchCaltexRewardCoupon
);

const fetchNewRewardsAction = createAction<FetchRewardsFetchActionPayload>(RewardAction.FetchNewRewards);

const fetchAllRewardsCategoriesAction = createAction<FetchRewardsCategoriesFetchActionPayload>(
    RewardAction.FetchAllRewardsCategories
);

//#endregion

//#region thunks
export const fetchCaltexRewardCouponThunk =
    (vin: string): PromiseThunk<CaltexRewardCoupon> =>
    async dispatch => {
        return getRewardCouponUrl(vin)
            .then(authorizedFetch)
            .then(status)
            .then(json)
            .then(rewardCoupon => getCaltexCouponUrl(rewardCoupon.caltexId))
            .catch(error => {
                dispatch(
                    fetchCaltexRewardCouponAction({
                        action: FetchAction.Failure,
                        value: error,
                    })
                );
                return Promise.reject(error);
            })
            .then(caltexCouponUrl => dispatch(createFetchThunk(caltexCouponUrl, fetchCaltexRewardCouponAction)));
    };

export const fetchRewardsOnCategory =
    (category: string) =>
    async (dispatch: Dispatch): Promise<Action<FetchRewardsFetchActionPayload> | void> => {
        const isRewardsEnabled = (await settingsPromise())?.reward?.isRewardsEnabled;
        if (isRewardsEnabled) {
            return getRewardsOnCategoryUrl(category)
                .then(authorizedFetch)
                .then(status)
                .then(json)
                .catch(error => {
                    dispatch(
                        fetchNewRewardsAction({
                            action: FetchAction.Failure,
                            value: error,
                        })
                    );
                    return Promise.reject(error);
                })
                .then(results => {
                    dispatch(fetchNewRewardsAction({ action: FetchAction.Success, value: results as Rewards[] }));
                });
        } else {
            return Promise.resolve(
                dispatch(fetchNewRewardsAction({ action: FetchAction.Success, value: [] as Rewards[] }))
            );
        }
    };

export const fetchAllRewardsCategories =
    () =>
    async (dispatch: Dispatch): Promise<Action<FetchRewardsCategoriesFetchActionPayload> | void> => {
        const isRewardsEnabled = (await settingsPromise())?.reward?.isRewardsEnabled;
        if (isRewardsEnabled) {
            return getAllRewardsCategoriesUrl()
                .then(fetch)
                .then(status)
                .then(json)
                .catch(error => {
                    dispatch(
                        fetchAllRewardsCategoriesAction({
                            action: FetchAction.Failure,
                            value: error,
                        })
                    );
                    return Promise.reject(error);
                })
                .then(results => {
                    dispatch(
                        fetchAllRewardsCategoriesAction({
                            action: FetchAction.Success,
                            value: results as RewardsCategoriesResponse[],
                        })
                    );
                });
        } else {
            return Promise.resolve(
                dispatch(
                    fetchAllRewardsCategoriesAction({
                        action: FetchAction.Success,
                        value: [] as RewardsCategoriesResponse[],
                    })
                )
            );
        }
    };
//#endregion

//#region selectors
const caltexRewardCouponContainerSelector: StateSelector<FetchCaltexRewardCouponFetchContainer> = state =>
    state.reward.caltexRewardCoupon;

export const newRewardsSelector: StateSelector<FetchAllRewardsFetchActionPayload> = state => state.reward.newRewards;

const allRewardsCategoriesContainerSelector: StateSelector<FetchAllRewardsCategoriesFetchContainer> = state =>
    state.reward.categories;

export const caltexRewardCouponStatusSelector: StateSelector<LoadStatus> = state =>
    caltexRewardCouponContainerSelector(state).status;

export const caltexRewardCouponSelector: StateSelector<CaltexRewardCoupon | undefined> = createSelector(
    caltexRewardCouponContainerSelector,
    getFetchContainerValue
);

export const caltexRewardCouponErrorSelector: StateSelector<Error | undefined> = createSelector(
    caltexRewardCouponContainerSelector,
    getFetchContainerError
);

export const allRewardsCategoriesSelector: StateSelector<RewardsCategoriesResponse[] | undefined> = createSelector(
    allRewardsCategoriesContainerSelector,
    getFetchContainerValue
);

//#endregion

//#region reducers
type RewardReducerPayload =
    | FetchCaltexRewardCouponFetchActionPayload
    | FetchRewardsFetchActionPayload
    | FetchRewardsCategoriesFetchActionPayload
    | FetchAllRewardsFetchActionPayload
    | FetchRewardsFetchContainer
    | undefined
    | Error;

type RewardReducer<Payload = undefined> = ReducerMapValue<RewardState, Payload>;

const fetchCaltexRewardCouponReducer: RewardReducer<FetchCaltexRewardCouponFetchActionPayload> = (state, action) => ({
    ...state,
    caltexRewardCoupon: fetchActionPayloadToContainer(action.payload),
});

const fetchAllRewardsCategoriesReducer: RewardReducer<FetchRewardsCategoriesFetchActionPayload> = (state, action) => ({
    ...state,
    categories: fetchActionPayloadToContainer(action.payload),
});

const fetchNewRewardsReducer: RewardReducer<FetchRewardsFetchActionPayload & FetchRewardsFetchContainer> = (
    state,
    action
) => {
    if (action.payload.action === "Success" && action.payload.value.length > 0) {
        const category = action.payload.value[0].category;
        return {
            ...state,
            newRewards: { ...state.newRewards, [category]: action.payload },
        };
    } else {
        return { ...state };
    }
};

const resetReducer: RewardReducer = () => initialRewardState;

export const rewardReducer = handleActions<RewardState, RewardReducerPayload>(
    {
        [RewardAction.FetchCaltexRewardCoupon]: fetchCaltexRewardCouponReducer,
        [RewardAction.FetchAllRewardsCategories]: fetchAllRewardsCategoriesReducer,
        [RewardAction.FetchNewRewards]: fetchNewRewardsReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialRewardState
);
//#endregion

import styles from "./RadioItemDetail.module.scss";
import { Stack } from "../Stack";
import { Typography } from "../Typography/Typography";

export interface RadioItemDetail {
  icon: React.ReactNode;
  children: React.ReactNode;
}

export const RadioItemDetail: React.FC<RadioItemDetail> = ({ icon, children }) => {
  return (
    <Stack alignItems="center" spacing="3xs">
      <div className={styles.svgIconWrapper}>{icon}</div>
      <Typography variant="b2">{children}</Typography>
    </Stack>
  );
};

RadioItemDetail.displayName = "RadioItemDetail";

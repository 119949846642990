export enum UserEncoreTiers {
    ELEVATE = "Elevate",
    PLATINUM = "Platinum",
    BASIC = "Basic",
    NONE = "None",
    LEGACY = "Legacy",
}

export enum UserEncoreStatus {
    NONE = "None",
    ACTIVE = "Active",
    EXPIRING_SOON = "Expiring Soon",
    EXPIRED = "Expired",
    START_RENEWAL = "Start Renewal",
    START_UPGRADE = "Start Upgrade",
    RENEWAL_SUCCESSFUL = "Renewal Successful",
    PAYMENT_ERROR = "Payment Error",
    PAYMENT_UPGRADE_EXPIRED = "Payment Upgrade Expired",
    PAYMENT_RENEWAL_EXPIRED = "Payment Renewal Expired",
    PAYMENT_UPGRADE = "Payment Upgrade",
    SERVICE_DOWN = "Service Down",
}

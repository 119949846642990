import * as React from "react";
import { PrimaryNavContext } from "../../PrimaryNav";
import "./ModelsMenuMobileLinks.scss";
import { lxColor } from "../../../../colors/variables";
import { SVGIconLink, SVGIconLinkIconPosition } from "../../../SVGIconLink/SVGIconLink";
import { LinkButton } from "../../../../buttons";
import { SVGChevronRight } from "../../../SVGIcons/icons/static/SVGChevronRight";
import { ButtonStyle, stringToButtonShadeStyle, ButtonShade } from "../../../../buttons/shared";

const ModelsMenuMobileLinks: React.FC<unknown> = () => {
    const { modelsData } = React.useContext(PrimaryNavContext);
    const links = modelsData && modelsData.links;
    const headerText = (modelsData && modelsData.linksHeaderText && modelsData.linksHeaderText.value) || "";
    const ctaButton1 = modelsData && modelsData.ctaButton1;
    const ctaButton2 = modelsData && modelsData.ctaButton2;
    const [ctaButton1Shade, ctaButton1Style] = (modelsData &&
        modelsData.ctaButton1Style &&
        stringToButtonShadeStyle(modelsData.ctaButton1Style.value)) || [ButtonShade.Dynamic, ButtonStyle.Primary];
    const [ctaButton2Shade, ctaButton2Style] = (modelsData &&
        modelsData.ctaButton2Style &&
        stringToButtonShadeStyle(modelsData.ctaButton2Style.value)) || [ButtonShade.Dynamic, ButtonStyle.Primary];

    return (
        <>
            <div className="lxs-models-menu-mobile-discover__title">{headerText}</div>
            {links && (
                <ul className="lxs-models-menu-mobile-discover__links">
                    {links.map((link, index) => (
                        <li key={index}>
                            <SVGIconLink
                                textClass="lxs-models-menu-mobile-discover-link__text"
                                field={link.link}
                                iconPosition={SVGIconLinkIconPosition.LEFT}
                                iconClass="lxs-models-menu-desktop-discover-link__icon"
                                data-gtm-linktype="Discover"
                            >
                                <SVGChevronRight height={15} width={15} color={lxColor("smoke")} />
                            </SVGIconLink>
                        </li>
                    ))}
                </ul>
            )}
            <div className="lxs-models-menu-mobile-discover__buttons">
                {ctaButton1 && (
                    <LinkButton
                        buttonStyle={ctaButton1Style}
                        shade={ctaButton1Shade}
                        field={ctaButton1}
                        data-gtm-linktype="Discover: CTA1"
                    />
                )}
                {ctaButton2 && (
                    <LinkButton
                        buttonStyle={ctaButton2Style}
                        shade={ctaButton2Shade}
                        field={ctaButton2}
                        data-gtm-linktype="Discover: CTA2"
                    />
                )}
            </div>
        </>
    );
};

export { ModelsMenuMobileLinks };

import * as React from "react";
import "./SVGBentoAnimated.scss";
import { SVGIcon } from "../../SVGIcon";
import { defaultIconSize, defaultIconColor } from "../constants";
import { SVGBento } from "../static/SVGBento";

const SVGBentoAnimated: React.FC<LXS.SVGAnimatedProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <SVGIcon {...rest}>
        <SVGBento width={width} height={height} color={color} className="lxs-svg-bento-animated" />
    </SVGIcon>
    /* eslint-enable max-len */
);

export { SVGBentoAnimated };

import cn from "classnames";
import { useContext } from "react";

import { ThemeContext } from "@/theming/ThemeContext";
import { ThemeCanvas, ThemeVariant } from "@/theming/themingTypes";
import { ThemeVariantContext } from "@/theming/VariantContext";

import styles from "./Section.module.scss";

export type SectionPadding = "none" | "more" | "default" | "less" | "evenLess";

type SectionProps = React.PropsWithChildren<{
  /**
   * Section padding. This is automatically managed by DLS tokens
   * to switch between desktop and mobile.
   * Default: `default`.
   */
  p?: SectionPadding;

  /**
   * Theme variant which should be established as context.
   * Default: `default`.
   */
  variant?: ThemeVariant;

  /**
   * Canvas defines section background color.
   * Default: `default`.
   */
  canvas?: ThemeCanvas;

  /**
   * Class name(s) to customise component if required.
   */
  className?: string;
}>;

/**
 * Section component is a starting point for building page layout.
 * Section is designed to be used with Section Content Container, but
 * can be used without it.
 *
 * Section defines current contextual Variant and specifies background
 * color (canvas).
 */
export const Section: React.FC<SectionProps> = ({
  className,
  variant = "default",
  canvas = "default",
  p = "default",
  children,
}) => {
  const { getVariantClassNameOrDefault } = useContext(ThemeContext);

  return (
    <ThemeVariantContext.Provider value={variant}>
      <section
        className={cn(
          styles.section,
          styles[`${canvas}Canvas`],
          styles[`${p}Padding`],
          getVariantClassNameOrDefault(variant),
          className
        )}
      >
        {children}
      </section>
    </ThemeVariantContext.Provider>
  );
};

import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGMapAnchor: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 19.7456C6.75 9.1328 15.8349 0.5 27 0.5C38.1651 0.5 47.25 9.1328 47.25 19.7456C47.25 29.7086 30.3818 51.3626 28.458 53.7998C28.1101 54.2408 27.5707 54.5 27 54.5C26.4293 54.5 25.8899 54.2408 25.542 53.798C23.6182 51.3626 6.75 29.7068 6.75 19.7456ZM39.761 20.7062C39.761 20.7062 40.0819 20.6844 40.2547 20.6056C40.4302 20.5268 40.6194 20.0539 40.6194 20.0539C41.1158 19.2631 41.2392 18.4832 41.2392 17.5049C41.2392 13.4775 38.9657 9.81975 34.0211 8.15663L34.6244 7.13756C40.0874 9.0045 43.3125 13.0101 43.3125 17.5103C43.3125 19.7306 42.4815 21.7904 41.0033 23.5297H24.2442C17.5581 23.3123 20.3773 19.1246 20.3773 19.1246C20.3773 19.1246 20.6982 18.3338 23.32 14.7249C25.8979 11.2003 27.8341 8.8496 27.8341 8.8496C28.8049 7.78705 28.7994 7.39301 28.5745 7.25441C28.5023 7.25143 28.4297 7.24805 28.3568 7.24466C28.0976 7.23259 27.8332 7.22028 27.5571 7.22452C19.3489 7.23267 14.0532 12.0291 14.0532 17.5103C14.0532 22.9997 18.6441 27.4375 27.5571 27.4375C31.69 27.4375 35.1235 26.2635 37.4985 24.3748H40.1998C37.4409 27.0271 33.0201 28.7256 27.5571 28.7256C17.1604 28.7364 11.7825 23.4617 11.8126 17.5103C11.8428 11.3144 17.9722 6.00707 27.5543 6.0125C29.3863 6.0125 31.0976 6.21088 32.6635 6.57231C33.5302 6.87396 33.4205 7.45823 33.1654 7.90118C32.9762 8.22729 32.7074 8.4773 32.7074 8.4773L26.0816 17.9615C23.8164 20.5839 26.0816 20.7062 26.0816 20.7062H39.761Z"
      fill="#E4E1D7"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGMapAnchor = memo(SVGMapAnchor);

export { SVGMapAnchor };

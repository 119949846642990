import * as React from "react";
import { BackButton } from "../SharedComponents/BackButton";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { SessionTimeoutError } from "../Errors/SessionTimeoutError";
import { IdentityProvider, OtpType } from "../Providers/IdentityProvider";
import { VerificationForm } from "../SharedComponents/VerificationForm";
import { ForgotPassword } from "./ForgotPassword";
import { EmailNotFound } from "./EmailNotFound";
import { SetPasswordForm } from "../SharedComponents/SetPasswordForm";
import { TermsAndConditionsForm } from "../SharedComponents/TermsAndConditionsForm";
import { SetPasswordSuccess } from "../SharedComponents/SetPasswordSuccess";
import { AccountSecurityForm } from "../SharedComponents/AccountSecurityForm";
import { AccountSecurityFormType, mapErrorToSecurityFormType } from "../SharedComponents/AccountSecurityForm";

const strings = {
    timeoutMessage: "Your session has timed out.  Press okay to continue back to reset your password.",
} as const;

enum ForgotScreen {
    Main,
    Otp,
    OtpMigration,
    NotFound,
    New,
    NewMigration,
    AdditionalInformation,
    TermsAndConditions,
    Success,
    Previous,
}

interface BaseCredentialsState {
    email: string;
}

interface GenericScreenState {
    type: ForgotScreen.Main | ForgotScreen.Previous;
}

interface PostLoginScreenState {
    type: ForgotScreen.TermsAndConditions | ForgotScreen.Success;
    guest: LXS.Guest;
}

interface NotFoundScreenState extends BaseCredentialsState {
    type: ForgotScreen.NotFound;
}

interface NewMigrationScreenState extends BaseCredentialsState {
    type: ForgotScreen.NewMigration;
}

interface OtpMigrationScreenState extends BaseCredentialsState {
    type: ForgotScreen.OtpMigration;
    authId: string;
    newMobile?: string;
}

interface OtpScreenState extends BaseCredentialsState {
    type: ForgotScreen.Otp;
    authId: string;
}

interface NewScreenState extends BaseCredentialsState {
    type: ForgotScreen.New;
    authId: string;
}

interface AdditionalScreenState extends BaseCredentialsState {
    type: ForgotScreen.AdditionalInformation;
    password?: string;
    newPhone?: string;
    requiredInfo: AccountSecurityFormType;
}

type ForgotScreenState =
    | GenericScreenState
    | OtpScreenState
    | OtpMigrationScreenState
    | NewScreenState
    | NotFoundScreenState
    | NewMigrationScreenState
    | PostLoginScreenState
    | AdditionalScreenState;

type AllPossibleProps = Partial<
    Omit<OtpScreenState, "type"> &
        Omit<OtpMigrationScreenState, "type"> &
        Omit<NewScreenState, "type"> &
        Omit<NewMigrationScreenState, "type"> &
        Omit<NotFoundScreenState, "type"> &
        Omit<PostLoginScreenState, "type"> &
        Omit<AdditionalScreenState, "type">
>;

type ForgotState = AllPossibleProps & ForgotScreenState;

type SignInRequiredHandler = (e?: React.MouseEvent<HTMLAnchorElement>) => void;

interface ForgotProps {
    onSignInRequired?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
    signInHref: string;
    onLogin?: (guest: LXS.Guest) => void;
    termsAndPrivacyConfig: LXS.TermsAndPrivacyConfig;
    onTermsAccepted?: () => void;
    guestEmailRef?: React.MutableRefObject<string | undefined>;
}

const forgotReducer =
    (onSignInRequiredRef: React.MutableRefObject<SignInRequiredHandler | undefined>) =>
    (state: ForgotState, action: ForgotScreenState): ForgotState => {
        if (action.type === ForgotScreen.Previous) {
            if (state.type === ForgotScreen.Main) {
                onSignInRequiredRef.current?.();
            }
            return { ...state, type: ForgotScreen.Main };
        } else {
            return { ...state, ...action };
        }
    };

const useHandleForgot = (dispatch: React.Dispatch<ForgotScreenState>) =>
    React.useCallback(async (email: string): Promise<void> => {
        try {
            const authId = await IdentityProvider.current.resetPasswordAsync(email);
            dispatch({ type: ForgotScreen.Otp, authId, email });
        } catch (err) {
            if (err instanceof AuthenticationError) {
                if (err.hasType(AuthenticationErrorType.UserNotFound)) {
                    dispatch({ type: ForgotScreen.NotFound, email });
                    return;
                }
                if (err.hasType(AuthenticationErrorType.UserExists)) {
                    if (
                        err.hasAnyType([AuthenticationErrorType.PhoneMissing, AuthenticationErrorType.PhoneNotUnique])
                    ) {
                        const requiredInfo = mapErrorToSecurityFormType(err.types);
                        if (requiredInfo !== undefined) {
                            dispatch({
                                type: ForgotScreen.AdditionalInformation,
                                email,
                                requiredInfo: AccountSecurityFormType.PasswordAndMobile,
                            });
                            return;
                        }
                    } else {
                        dispatch({ type: ForgotScreen.NewMigration, email });
                        return;
                    }
                }
            }
            throw err;
        }
    }, []);

const useHandleOtp = (state: ForgotState, dispatch: React.Dispatch<ForgotScreenState>, handleRestart: () => void) =>
    React.useCallback(
        async (code: string): Promise<void> => {
            if (state.type === ForgotScreen.Otp) {
                try {
                    const authId = await IdentityProvider.current.submitOtpAsync(
                        false,
                        state.authId,
                        code,
                        OtpType.Other,
                    );
                    dispatch({ type: ForgotScreen.New, authId, email: state.email });
                } catch (err) {
                    if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                        throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
                    }
                    throw err;
                }
            } else if (state.type === ForgotScreen.OtpMigration) {
                try {
                    const guest = await IdentityProvider.current.submitOtpAsync(false, state.authId, code);
                    dispatch({ type: ForgotScreen.Success, guest });
                } catch (err) {
                    if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                        throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
                    }
                    throw err;
                }
            } else {
                throw new Error(
                    `Invalid state of component, expected ${ForgotScreen.Otp} or ${ForgotScreen.OtpMigration}, ` +
                        `got ${state.type}`,
                );
            }
        },
        [state.type, state.email, state.authId],
    );

const useHandleNewPassword = (
    state: ForgotState,
    dispatch: React.Dispatch<ForgotScreenState>,
    handleRestart: () => void,
) =>
    React.useCallback(
        async (password: string): Promise<void> => {
            if (state.type === ForgotScreen.New && state.authId) {
                try {
                    const guest = await IdentityProvider.current.setNewPasswordAsync(state.authId, password, false);
                    dispatch({ type: ForgotScreen.Success, guest });
                } catch (err) {
                    if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                        throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
                    }
                    throw err;
                }
            } else if (state.type === ForgotScreen.NewMigration) {
                try {
                    await IdentityProvider.current.saveNewPasswordAsync(state.email, password);
                } catch (err) {
                    if (err instanceof AuthenticationError) {
                        if (err.hasType(AuthenticationErrorType.OtpCodeRequired) && err.authId) {
                            dispatch({ type: ForgotScreen.OtpMigration, email: state.email, authId: err.authId });
                            return;
                        }
                        if (
                            err.hasAnyType([
                                AuthenticationErrorType.PhoneMissing,
                                AuthenticationErrorType.PhoneNotUnique,
                            ])
                        ) {
                            const requiredInfo = mapErrorToSecurityFormType(err.types);
                            if (requiredInfo !== undefined) {
                                dispatch({
                                    type: ForgotScreen.AdditionalInformation,
                                    email: state.email,
                                    requiredInfo,
                                    password,
                                });
                                return;
                            }
                        }
                    }
                    throw err;
                }
            } else {
                throw new Error(
                    `Invalid state of component, expected ${ForgotScreen.New} or ${ForgotScreen.NewMigration}, ` +
                        `got ${state.type} and authId ${!!state.authId}`,
                );
            }
        },
        [state.type, state.email, state.authId],
    );

const useHandleOtpResend = (
    state: ForgotState,
    dispatch: React.Dispatch<ForgotScreenState>,
    handleRestart: () => void,
) =>
    React.useCallback(async () => {
        if (state.type !== ForgotScreen.Otp && state.type !== ForgotScreen.OtpMigration) {
            throw new Error(
                `Invalid state of component, expected ${ForgotScreen.Otp} or ${ForgotScreen.OtpMigration}, ` +
                    `got ${state.type}`,
            );
        }
        try {
            const authId = await IdentityProvider.current.requestOtpAsync(state.authId);
            dispatch({ type: state.type, authId, email: state.email });
        } catch (err) {
            if (err instanceof AuthenticationError && err.hasType(AuthenticationErrorType.SessionTimeout)) {
                throw new SessionTimeoutError(handleRestart, strings.timeoutMessage);
            }
            throw err;
        }
    }, [state.type, state.email]);

const useHandleTnCs = (
    state: ForgotState,
    termsAndConditionsVersion: string,
    privacyPolicyVersion: string,
    onLogin?: (guest: LXS.Guest) => void,
    onTermsAccepted?: () => void,
) =>
    React.useCallback(async () => {
        if (state.type !== ForgotScreen.TermsAndConditions || !state.guest) {
            throw new Error(
                `Invalid state of component, expected ${ForgotScreen.TermsAndConditions}, ` +
                    `got ${state.type} and guest: ${!state.guest}`,
            );
        }

        const details = await state.guest.getOrUpdateDetailsAsync({
            termsAndConditionsVersion,
            privacyPolicyVersion,
            ...(state.newMobile ? { newMobile: state.newMobile } : {}),
        });

        if (
            details &&
            details.termsAndConditionsVersion === termsAndConditionsVersion &&
            details.privacyPolicyVersion === privacyPolicyVersion
        ) {
            onTermsAccepted?.();
            onLogin?.(state.guest);
        } else {
            throw new AuthenticationError(new Set([AuthenticationErrorType.TnCsInvalid]));
        }
    }, [
        state.type,
        state.guest,
        state.newMobile,
        termsAndConditionsVersion,
        privacyPolicyVersion,
        onLogin,
        onTermsAccepted,
    ]);

const useHandleContinue = (
    state: ForgotState,
    dispatch: React.Dispatch<ForgotScreenState>,
    termsVersion: string,
    privacyVersion: string,
    onLogin?: (guest: LXS.Guest) => void,
) =>
    React.useCallback(async () => {
        if (state.type !== ForgotScreen.Success || !state.guest) {
            throw new Error(
                `Invalid state of component, expected ${ForgotScreen.Success}, ` +
                    `got ${state.type} and guest: ${!state.guest}`,
            );
        }
        const details = await state.guest.getOrUpdateDetailsAsync();

        if (details.termsAndConditionsVersion !== termsVersion || details.privacyPolicyVersion !== privacyVersion) {
            dispatch({ type: ForgotScreen.TermsAndConditions, guest: state.guest });
        } else {
            onLogin?.(state.guest);
        }
        onLogin?.(state.guest);
    }, [state.type, state.guest, termsVersion, privacyVersion, onLogin]);

const useHandleMoreInfo = (state: ForgotState, dispatch: React.Dispatch<ForgotScreenState>) =>
    React.useCallback(
        async (newMobile?: string, newPassword?: string): Promise<void> => {
            const password = newPassword || state.password;
            if (state.type !== ForgotScreen.AdditionalInformation || !password) {
                throw new Error(
                    `Invalid state of component, expected ${ForgotScreen.AdditionalInformation}, ` +
                        `got ${state.type}`,
                );
            }
            try {
                await IdentityProvider.current.saveNewPasswordAsync(state.email, password, newMobile);
            } catch (err) {
                if (err instanceof AuthenticationError) {
                    if (err.hasType(AuthenticationErrorType.OtpCodeRequired) && err.authId) {
                        dispatch({
                            type: ForgotScreen.OtpMigration,
                            authId: err.authId,
                            email: state.email,
                            newMobile,
                        });
                        return;
                    }
                }
                throw err;
            }
        },
        [state.type, state.email, state.password],
    );

const Forgot: React.FC<ForgotProps> = ({
    onSignInRequired,
    signInHref,
    onLogin,
    onTermsAccepted,
    termsAndPrivacyConfig: { privacyPolicyUrl, privacyPolicyVersion, termsAndConditionsUrl, termsAndConditionsVersion },
    guestEmailRef,
}) => {
    const onSignInRequiredRef = React.useRef(onSignInRequired);
    const [state, dispatch] = React.useReducer(forgotReducer(onSignInRequiredRef), {
        type: ForgotScreen.Main,
        email: guestEmailRef?.current,
    });
    const handleBack = React.useCallback(() => dispatch({ type: ForgotScreen.Previous }), []);
    const handleRestart = React.useCallback(() => dispatch({ type: ForgotScreen.Main }), []);
    const handleOtp = useHandleOtp(state, dispatch, handleRestart);
    const handleForgot = useHandleForgot(dispatch);
    const handleOtpResend = useHandleOtpResend(state, dispatch, handleRestart);
    const handleNewPassword = useHandleNewPassword(state, dispatch, handleRestart);
    const handleTnCs = useHandleTnCs(state, termsAndConditionsVersion, privacyPolicyVersion, onLogin, onTermsAccepted);
    const handleContinue = useHandleContinue(state, dispatch, termsAndConditionsVersion, privacyPolicyVersion, onLogin);
    const handleMoreInfo = useHandleMoreInfo(state, dispatch);

    switch (state.type) {
        case ForgotScreen.Main:
            return (
                <>
                    <BackButton onBack={onSignInRequired && handleBack} />
                    <ForgotPassword onSubmit={handleForgot} guestEmailRef={guestEmailRef} />
                </>
            );
        case ForgotScreen.NotFound:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <EmailNotFound email={state.email} signInHref={signInHref} onSignInRequired={onSignInRequired} />
                </>
            );
        case ForgotScreen.New:
        case ForgotScreen.NewMigration:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <SetPasswordForm onSubmit={handleNewPassword} />
                </>
            );
        case ForgotScreen.Otp:
        case ForgotScreen.OtpMigration:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <VerificationForm email={state.email} onSubmit={handleOtp} onResendRequired={handleOtpResend} />
                </>
            );
        case ForgotScreen.TermsAndConditions:
            return (
                <>
                    <BackButton />
                    <TermsAndConditionsForm
                        onAccept={handleTnCs}
                        buttonText="accept"
                        privacyTitle="Privacy Policy"
                        termsTitle="Terms of Use"
                        privacyUrl={privacyPolicyUrl}
                        termsUrl={termsAndConditionsUrl}
                    />
                </>
            );
        case ForgotScreen.AdditionalInformation:
            return (
                <>
                    <BackButton onBack={handleBack} />
                    <AccountSecurityForm email={state.email} type={state.requiredInfo} onSubmit={handleMoreInfo} />
                </>
            );
        case ForgotScreen.Success:
            return (
                <>
                    <BackButton />
                    <SetPasswordSuccess onContinue={handleContinue} />
                </>
            );
        default:
            return <h2>Unknown error</h2>;
    }
};

export { Forgot };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGSteeringWheel: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1985 21C19.0611 19.8239 20.4977 18.0344 21.2247 15.9153L16.9908 16.4446C16.8857 16.7026 16.776 16.9532 16.6635 17.1933C16.2966 17.9759 15.8818 18.6862 15.4662 19.214C15.3191 19.4008 15.1632 19.576 15 19.7267V21H13.5V20.25H10.5V21H9V19.7267C8.83684 19.576 8.68088 19.4008 8.53382 19.214C8.11817 18.6862 7.70337 17.9759 7.33653 17.1933C7.22396 16.9532 7.11425 16.7026 7.00921 16.4446L2.7753 15.9153C3.50228 18.0344 4.93889 19.8239 6.80147 21H4.3514C2.13627 18.9454 0.75 16.0096 0.75 12.75C0.75 6.5368 5.7868 1.5 12 1.5C18.2132 1.5 23.25 6.5368 23.25 12.75C23.25 16.0096 21.8637 18.9454 19.6486 21H17.1985ZM6.45974 14.8642C6.20442 13.9796 6.03292 13.0748 6.00426 12.2432L2.26589 13.3113C2.28592 13.6643 2.32475 14.0124 2.38137 14.3544L6.45974 14.8642ZM6.21544 10.6228C6.31802 10.3304 6.45708 10.0639 6.6323 9.82368C7.04991 9.25112 7.62388 8.89476 8.23008 8.6713C9.375 8.25 10.7984 8.24982 11.9283 8.25001L12 8.25002L12.0717 8.25001C13.2016 8.24982 14.625 8.25 15.7699 8.6713C16.3761 8.89476 16.9501 9.25112 17.3677 9.82368C17.5429 10.0639 17.682 10.3304 17.7846 10.6228L21.6984 11.7411C21.1936 6.83035 17.0441 3 12 3C6.9559 3 2.80643 6.83035 2.30157 11.7411L6.21544 10.6228ZM17.9957 12.2432C17.9671 13.0748 17.7956 13.9796 17.5403 14.8642L21.6186 14.3544C21.6753 14.0124 21.7141 13.6643 21.7341 13.3113L17.9957 12.2432ZM7.5 12C7.5 11.3739 7.64881 10.9755 7.84419 10.7076C8.04199 10.4364 8.33776 10.2303 8.74888 10.0787C9.61511 9.75943 10.7796 9.75002 12 9.75002C13.2204 9.75002 14.3849 9.75943 15.2511 10.0787C15.6622 10.2303 15.958 10.4364 16.1558 10.7076C16.3512 10.9755 16.5 11.3739 16.5 12C16.5 13.3483 15.9834 15.11 15.3053 16.5567C14.969 17.2741 14.6104 17.8763 14.2877 18.286C14.1254 18.4921 13.9895 18.6272 13.8885 18.7042C13.8577 18.7277 13.8353 18.7419 13.821 18.75H10.179C10.1647 18.7419 10.1423 18.7277 10.1115 18.7042C10.0105 18.6272 9.87457 18.4921 9.71227 18.286C9.38964 17.8763 9.031 17.2741 8.69472 16.5567C8.01659 15.11 7.5 13.3483 7.5 12Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6117 13.8531C13.5898 13.8631 13.549 13.8659 13.549 13.8659H11.812C11.812 13.8659 11.5243 13.8503 11.812 13.5173L12.6533 12.313C12.6533 12.313 12.6875 12.2812 12.7115 12.2398C12.7439 12.1836 12.7578 12.1094 12.6478 12.0711C12.4489 12.0252 12.2316 12 11.999 12C10.7822 11.9993 10.0038 12.6733 10 13.46C9.99619 14.2158 10.6791 14.8856 11.9993 14.8842C12.693 14.8842 13.2544 14.6685 13.6047 14.3317H13.2617C12.9601 14.5716 12.5241 14.7206 11.9993 14.7206C10.8675 14.7206 10.2845 14.1571 10.2845 13.46C10.2845 12.764 10.957 12.1549 11.9993 12.1539C12.0442 12.1532 12.0867 12.156 12.1285 12.1577C12.1571 12.1753 12.1578 12.2253 12.0345 12.3603C12.0345 12.3603 11.7886 12.6588 11.4613 13.1063C11.1283 13.5646 11.0876 13.665 11.0876 13.665C11.0876 13.665 10.7296 14.1968 11.5786 14.2244H13.7068C13.8945 14.0035 14 13.742 14 13.46C14 12.8886 13.5905 12.3799 12.8967 12.1429L12.8201 12.2723C13.448 12.4835 13.7367 12.9479 13.7367 13.4594C13.7367 13.5836 13.7211 13.6826 13.658 13.783C13.658 13.783 13.634 13.8431 13.6117 13.8531Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGSteeringWheel = React.memo(SVGSteeringWheel);

export { SVGSteeringWheel };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGTime: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm0-2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12zm1-13h5a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1V7a1 1 0 1 1 2 0v8z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGTime = React.memo(SVGTime);

export { SVGTime };

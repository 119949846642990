import { BrandColours } from "@/types/colors";

import { MapTheme } from "./types";

export const mapStyles = (theme: MapTheme = MapTheme.DARK) => {
  const styles = [
    {
      featureType: "all",
      elementType: "geometry.fill",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueDark },
        { saturation: 0 },
      ],
    },
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeDark : BrandColours.deepBlueLight },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueDark },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.icon",
      stylers: [{ color: BrandColours.inari }],
    },
    {
      featureType: "administrative.country",
      elementType: "all",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueDark },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeDark : BrandColours.deepBlueDark },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeDark : BrandColours.deepblueDefault },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueDark },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry.fill",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueDark },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.icon",
      stylers: [{ saturation: -100 }, { invert_lightness: false }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueLight },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueLight },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color:
            theme === MapTheme.LIGHT ? BrandColours.deepblueDefault : BrandColours.smokeDefault,
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        { visibility: "off" },
        { color: theme === MapTheme.LIGHT ? BrandColours.smokeLight : BrandColours.deepBlueLight },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit.station.rail",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color:
            theme === MapTheme.LIGHT ? BrandColours.deepblueDefault : BrandColours.blackAbsolute,
        },
        { visibility: "on" },
      ],
    },
  ];

  return styles;
};

import { DealerLocation as DealerLocationType } from "@/types/dealer";

import styles from "./DealerLocation.module.scss";
import { DealerBranch } from "../DealerBranch/DealerBranch";
export type DealerLocationProps = {
  dealerLocations: DealerLocationType[];
};

const DealerLocation: React.FC<DealerLocationProps> = ({ dealerLocations }) => (
  <div className={styles.dealerLocation}>
    {dealerLocations.map((location, index) => (
      <div key={`${location.branchCode}`} className={styles.dealerLocationBranch}>
        <DealerBranch
          dealerLocation={location}
          branchNumber={dealerLocations.length > 1 ? index + 1 : undefined}
        />
      </div>
    ))}
  </div>
);

export { DealerLocation };

import * as React from "react";
import "./CollapseHeader.scss";
import { createBemFn } from "../../../utilities/bem";
import { lxColor } from "../../../colors/variables";
import classnames from "classnames";
import { SVGAccordionAnimated } from "../../../Components/SVGIcons/icons/animated/SVGAccordionAnimated";

const bem = createBemFn("collapse-header");

interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    className?: string;
}

const CollapseHeader: React.FunctionComponent<Props> = ({ setOpen, open, children, className }) => (
    <div className={classnames(bem(), className)} onClick={() => setOpen((current) => !current)}>
        <p className={bem("text")}>{children}</p>
        <SVGAccordionAnimated
            className={bem("icon")}
            active={open}
            color={lxColor("smoke", "dark")}
            width={16}
            height={16}
        />
    </div>
);

export { CollapseHeader };

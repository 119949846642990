import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGFacebook: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M22 11.9879C22 6.465 17.5229 1.98785 12 1.98785C6.47715 1.98785 2 6.465 2 11.9879C2 16.9791 5.65684 21.1162 10.4375 21.8664V14.8785H7.89844V11.9879H10.4375V9.78473C10.4375 7.27848 11.9305 5.8941 14.2146 5.8941C15.3084 5.8941 16.4531 6.08942 16.4531 6.08942V8.55035H15.1922C13.95 8.55035 13.5625 9.32125 13.5625 10.1129V11.9879H16.3359L15.8926 14.8785H13.5625V21.8664C18.3432 21.1162 22 16.9791 22 11.9879Z"
            fill={color}
        />
    </svg>
);
SVGFacebook = React.memo(SVGFacebook);

export { SVGFacebook };

import * as React from "react";
import "./UserAgreementCollapse.scss";
import { createBemFn } from "../../../utilities/bem";
import { Collapse } from "../../Collapse/Collapse";
import { CollapseHeader } from "../../Collapse/CollapseHeader/CollapseHeader";
import { ResizableIframe } from "../../ResizableIframe/ResizableIframe";
import { useIsInsideIframe } from "../../../utilities/CustomHooks/useIsInsideIframe";

const bem = createBemFn("user-agreement-collapse");

interface Props {
    url: string;
    title: string;
    openByDefault?: boolean;
}

const UserAgreementCollapse: React.FC<Props> = ({ url, title, openByDefault }) => {
    const [open, setOpen] = React.useState(openByDefault || false);
    const [onIframeLoad, setOnIframeLoad] = React.useState(false);

    const header = (
        <CollapseHeader className={bem("header")} open={open} setOpen={setOpen}>
            {title}
        </CollapseHeader>
    );

    const resizeOptions = {
        initCallback: () => {
            setOnIframeLoad(true);
        },
    };
    const body = <ResizableIframe url={url} iframeResizerOptions={resizeOptions} />;

    const isInsideIframe = useIsInsideIframe();

    return (
        <Collapse
            className={bem()}
            header={header}
            body={body}
            open={open}
            transitionTime={1500}
            onIframeLoad={onIframeLoad}
            isBodyIframe={isInsideIframe}
        />
    );
};

export { UserAgreementCollapse };

import { Dispatch, StateSelector } from "../types/general";
import {
    FetchAction,
    FetchActionPayload,
    FetchContainer,
    PromiseThunk,
    authorizedFetch,
    createCachedFetchThunk,
    fetchActionPayloadToContainer,
    getFetchContainerValue,
    initialFetchContainer,
    json,
    status,
} from "../helpers/fetch";
import {
    GuestVehicle,
    OnlineServiceBookingPayload,
    OnlineServiceBookingState,
    Vehicle,
    VehicleStatus,
} from "Types/index";
import { ReducerMapValue, createAction, handleActions } from "redux-actions";
import { RouteSection, routeString } from "../helpers/routes";
import {
    vehicleUnitByVinUrl as getVehicleUnitByVinUrl,
    vehicleUnitGetGuestVehiclesUrl as getVehicleUnitGetGuestVehiclesUrl,
} from "../apiHref";

import { LoadStatus } from "../types/loadStatus";
import { RootLevelAction } from "./rootLevelAction";
import { compose } from "redux";
import { createSelector } from "reselect";
import history from "../history";
import { logoutThunk } from "./user";

export type FetchVehiclesPayload = Vehicle[];
type FetchVehiclesFetchActionPayload = FetchActionPayload<FetchVehiclesPayload>;
type VehiclesFetchContainer = FetchContainer<FetchVehiclesPayload>;

//#region actions
export enum VehicleAction {
    FetchVehicles = "FetchVehicles",
    SetOnlineServiceBookingPayload = "SetOnlineServiceBookingPayload",
    SetOnlineServiceBookingState = "SetOnlineookingBookingServiceState",
    SetIsOSBEnabled = "SetIsOSBEnabled",
}

const fetchVehiclesAction = createAction<FetchVehiclesFetchActionPayload>(VehicleAction.FetchVehicles);

export const setOnlineServiceBookingPayloadAction = createAction<OnlineServiceBookingPayload>(
    VehicleAction.SetOnlineServiceBookingPayload
);

export const setOnlineServiceBookingStateAction = createAction<OnlineServiceBookingState>(
    VehicleAction.SetOnlineServiceBookingState
);

export const setIsOSBEnableAction = createAction<boolean>(VehicleAction.SetIsOSBEnabled);
//#endregion

//#region state
export interface VehicleState {
    vehicles: VehiclesFetchContainer;
    onlineServiceBookingPayload: OnlineServiceBookingPayload;
    onlineServiceBookingState: OnlineServiceBookingState;
    isSpaOSBEnabled?: boolean;
}

export interface ConfirmVehicleReponse {
    isSuccess: boolean;
    error: {
        code: string;
        description: string;
    } | null;
    confirmUserResponse: {
        message: string | null;
        paylink: string;
        status: string;
    } | null;
}

export const initialVehicleState: VehicleState = {
    vehicles: initialFetchContainer,
    onlineServiceBookingPayload: {},
    onlineServiceBookingState: OnlineServiceBookingState.BOOK_A_SERVICE_FIRST_LOADING,
};
//#endregion

//#region thunks
const handleFetchVehiclesError = async (dispatch: Dispatch, error: Error) => {
    await dispatch(logoutThunk);
    const url = routeString(RouteSection.SignIn, RouteSection.Notify, RouteSection.NonRecoverableError);
    history.push(url);
    throw error;
};

export const fetchVehiclesThunk: PromiseThunk<FetchVehiclesPayload> = async (dispatch: Dispatch) => {
    const dispatchAction = compose(dispatch, fetchVehiclesAction);
    dispatchAction({
        action: FetchAction.Fetch,
    });

    try {
        const vehicleUnitGetGuestVehiclesUrl = await getVehicleUnitGetGuestVehiclesUrl();
        const vehicleUnitByVinUrl = await getVehicleUnitByVinUrl();

        const guestVehicles = await authorizedFetch(vehicleUnitGetGuestVehiclesUrl).then(status).then(json);

        const vehicles: FetchVehiclesPayload = await Promise.all(
            guestVehicles.map(async (guestVehicle: GuestVehicle) => {
                if (guestVehicle.status === VehicleStatus.Verified) {
                    const vehicleUnit = await authorizedFetch(`${vehicleUnitByVinUrl}/${guestVehicle.vin}`)
                        .then(status)
                        .then(json)
                        .then(data => {
                            return {
                                ...data,
                                vehicleUnitLoadStatus: LoadStatus.Success,
                            };
                        })
                        .catch(() => ({ vehicleUnitLoadStatus: LoadStatus.Failure }));
                    return {
                        ...guestVehicle,
                        ...vehicleUnit,
                    };
                } else {
                    return guestVehicle;
                }
            })
        );

        dispatchAction({
            action: FetchAction.Success,
            value: vehicles,
        });

        return vehicles;
    } catch (error) {
        dispatchAction({
            action: FetchAction.Failure,
            error,
        });
        handleFetchVehiclesError(dispatch, error);
        throw error;
    }
};
//#endregion

//#region selectors
export const vehiclesContainerSelector: StateSelector<VehiclesFetchContainer> = state => state.vehicle.vehicles;

export const vehiclesFetchStatusSelector: StateSelector<LoadStatus> = state => vehiclesContainerSelector(state).status;

export const vehiclesSelector: StateSelector<FetchVehiclesPayload | undefined> = createSelector(
    vehiclesContainerSelector,
    getFetchContainerValue
);

export const onlineServiceBookingPayloadSelector: StateSelector<OnlineServiceBookingPayload> = state =>
    state.vehicle.onlineServiceBookingPayload;

export const onlineServiceBookingStateSelector: StateSelector<OnlineServiceBookingState> = state =>
    state.vehicle.onlineServiceBookingState;

export const fetchVehiclesThunkCached = createCachedFetchThunk(fetchVehiclesThunk, vehiclesFetchStatusSelector);

export const isOSBEnabledSelector: StateSelector<boolean | undefined> = state => state.vehicle.isSpaOSBEnabled;
//#endregion

//#region reducers
type VehicleReducerPayload =
    | undefined
    | Error
    | FetchVehiclesFetchActionPayload
    | OnlineServiceBookingPayload
    | OnlineServiceBookingState
    | boolean;

type VehicleReducer<T = undefined> = ReducerMapValue<VehicleState, T>;

const fetchVehiclesReducer: VehicleReducer<FetchVehiclesFetchActionPayload> = (state, action) => ({
    ...state,
    vehicles: fetchActionPayloadToContainer(action.payload),
});

const setOnlineServiceBookingPayloadReducer: VehicleReducer<OnlineServiceBookingPayload> = (state, action) => ({
    ...state,
    onlineServiceBookingPayload: action.payload,
});

const setOnlineServiceBookingStateReducer: VehicleReducer<OnlineServiceBookingState> = (state, action) => ({
    ...state,
    onlineServiceBookingState: action.payload,
});

const setIsOSBEnabledReducer: VehicleReducer<boolean> = (state, action) => ({
    ...state,
    isSpaOSBEnabled: action.payload,
});

const resetReducer: VehicleReducer = () => initialVehicleState;

export const vehicleReducer = handleActions<VehicleState, VehicleReducerPayload>(
    {
        [VehicleAction.FetchVehicles]: fetchVehiclesReducer,
        [VehicleAction.SetOnlineServiceBookingPayload]: setOnlineServiceBookingPayloadReducer,
        [VehicleAction.SetOnlineServiceBookingState]: setOnlineServiceBookingStateReducer,
        [VehicleAction.SetIsOSBEnabled]: setIsOSBEnabledReducer,
        [RootLevelAction.Reset]: resetReducer,
    },
    initialVehicleState
);
//#endregion

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGMainMenu: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    <svg viewBox="0 0 25 25" id="el_tz3LK_fgy" height={height} width={width} {...rest}>
        <title>Main Menu</title>
        <g id="el_7BavNDTjNi" fillRule="evenodd">
            <g id="el_sW7LZcG5sm_an_uuScbWO3F" data-animator-group="true" data-animator-type="2">
                <rect id="el_sW7LZcG5sm" fill={color} x="3" y="6" width="17" height="2" />
            </g>
            <g id="el_Xc_BMUk41G_an_ZY8ZlhzDL" data-animator-group="true" data-animator-type="2">
                <rect id="el_Xc_BMUk41G" fill={color} x="3" y="11" width="20" height="2" />
            </g>
            <g id="el_i97QXjNEbW_an_syzfBZXuo" data-animator-group="true" data-animator-type="2">
                <rect id="el_i97QXjNEbW" fill={color} x="3" y="16" width="13" height="2" />
            </g>
        </g>
    </svg>
);
SVGMainMenu = React.memo(SVGMainMenu);

export { SVGMainMenu };

import { VerticalScrollDirection, VerticalScrollPagerClasses } from "./Constants";

const changeActivePager = (
    pager: LXS.PagerRefType,
    index: number,
    lastScrollIndex: number,
    numberOfTiles: number,
    direction: VerticalScrollDirection,
) => {
    if (!pager.buttons) {
        return;
    }
    const current = pager.buttons[index];
    const last = pager.buttons[lastScrollIndex];

    Array.from(pager.buttons).forEach((button) => {
        const classList = [
            VerticalScrollPagerClasses.BUTTON_NOT_ACTIVE,
            VerticalScrollPagerClasses.BUTTON_NOT_ACTIVE_TRANSITION,
            VerticalScrollPagerClasses.BUTTON_NOT_ACTIVE_REVERSE_TRANSITION,
            VerticalScrollPagerClasses.BUTTON_ACTIVE,
            VerticalScrollPagerClasses.BUTTON_HIDE,
        ];
        for (let index = 0; index < classList.length; index++) {
            button.classList.remove(classList[index]);
        }
    });

    if (last && lastScrollIndex > -1 && lastScrollIndex < numberOfTiles) {
        last.classList.add(VerticalScrollPagerClasses.BUTTON_NOT_ACTIVE);
        last.classList.add(VerticalScrollPagerClasses.BUTTON_HIDE);

        const delay = 50;
        setTimeout(() => {
            pager.buttons &&
                pager.buttons[lastScrollIndex].classList.add(
                    direction === VerticalScrollDirection.DOWN
                        ? VerticalScrollPagerClasses.BUTTON_NOT_ACTIVE_TRANSITION
                        : VerticalScrollPagerClasses.BUTTON_NOT_ACTIVE_REVERSE_TRANSITION,
                );
        }, delay);
    }

    if (current) {
        current.classList.add(VerticalScrollPagerClasses.BUTTON_ACTIVE);
    }
};

const hidePager = (pagerElement: HTMLUListElement | null) => {
    pagerElement && pagerElement.classList.add(VerticalScrollPagerClasses.PAGER_HIDE);
};

const showPager = (pagerElement: HTMLUListElement | null) => {
    pagerElement && pagerElement.classList.remove(VerticalScrollPagerClasses.PAGER_HIDE);
};

export { changeActivePager, showPager, hidePager };

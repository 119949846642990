import cn from "classnames";

import styles from "./PrimaryNavNavbarLevel1Items.module.scss";
import { Level1MenuItemData } from "../types/types";

export interface PrimaryNavNavbarLevel1ItemsProps {
  /**
   * Items to be rendered
   */
  level1MenuItems: Level1MenuItemData[];
  /**
   * Set the active menu item. Must be a member of `level1MenuItems`.
   */
  activeLevel1MenuItem?: Level1MenuItemData;
  /**
   * Item click handler. Expected use case is to toggle modals open and closed in desktop navigation.
   */
  onItemClick: (level1MenuItemData: Level1MenuItemData) => void;
  /**
   * When true, locks the button in its hovered state. Should rarely be used outside VRT.
   */
  isPseudoHovered?: boolean;
  /**
   * When true, locks the button in its focused state. Should rarely be used outside VRT.
   */
  isPseudoFocused?: boolean;
}

const PrimaryNavNavbarLevel1Items: React.FC<PrimaryNavNavbarLevel1ItemsProps> = ({
  level1MenuItems,
  activeLevel1MenuItem,
  onItemClick,
  isPseudoHovered,
  isPseudoFocused,
}) => {
  return (
    <div className={styles.navbarItems} role="navigation" aria-label="Primary">
      {level1MenuItems.map((level1MenuItem) => (
        <button
          className={cn(styles.navbarItem, {
            [styles.navbarItemHovered]: isPseudoHovered,
            [styles.navbarItemFocused]: isPseudoFocused,
            [styles.isActive]: level1MenuItem === activeLevel1MenuItem,
          })}
          key={level1MenuItem.label}
          onClick={() => onItemClick(level1MenuItem)}
          aria-expanded={level1MenuItem === activeLevel1MenuItem}
          aria-controls={"modal_content_" + level1MenuItem.modalId}
        >
          <span className={styles.label}>{level1MenuItem.label}</span>
        </button>
      ))}
    </div>
  );
};

PrimaryNavNavbarLevel1Items.displayName = "PrimaryNavNavbarItems";

export { PrimaryNavNavbarLevel1Items };

import cn from "classnames";
import { memo, useRef, useState, Children, isValidElement } from "react";

import { SVGInfoCircleAnimated } from "@/components/SVGIcon/animated/SVGInfoCircleAnimated";
import { SVGAnimationEvents, SVGIconSizes } from "@/components/SVGIcon/types";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";

import styles from "./ToolTip.module.scss";

export enum ToolTipState {
  CLOSED,
  SOFT_OPENED,
  HARD_OPENED,
}

export enum TooltipType {
  DEFAULT = "default",
  ERROR = "error",
}

export enum ToolTipPosition {
  TOP = "Top",
  RIGHT = "Right",
  BOTTOM = "Bottom",
  LEFT = "Left",
  LEFT_TOP = "LeftTop",
  TOP_RIGHT = "TopRight",
  TOP_LEFT = "TopLeft",
  BOTTOM_RIGHT = "BottomRight",
  BOTTOM_LEFT = "BottomLeft",
  LEFT_BOTTOM = "LeftBottom",
}

type ToolTipProps = React.PropsWithChildren<{
  width?: number;
  position?: ToolTipPosition;
  className?: string;
  type?: TooltipType;
  addShadow?: boolean;
  initialState?: ToolTipState;
}>;

const defaultToolTipWidth = 242;

const shouldPopUpBeRendered = (children?: React.ReactNode | string | null) => {
  // Check if children is falsy or an empty string
  if (!children || children === "") {
    return false;
  }

  // Check if children is a string with only whitespace characters
  if (typeof children === "string" && children.trim().length === 0) {
    return false;
  }

  // Check if children is a React node with no children
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (isValidElement(children) && Children.count(children.props.children) === 0) {
    return false;
  }

  // // Check if children is just an empty array
  if (Array.isArray(children) && children.length === 0) {
    return false;
  }

  // Otherwise, return true to indicate that a pop-up should be rendered
  return true;
};

let ToolTip: React.FC<ToolTipProps> = ({
  children,
  position = ToolTipPosition.BOTTOM,
  width = defaultToolTipWidth,
  className,
  type = TooltipType.DEFAULT,
  addShadow,
  initialState = ToolTipState.CLOSED,
}) => {
  const [open, setOpen] = useState(initialState);
  const softOpen = () =>
    setOpen((isOpen) => (isOpen === ToolTipState.CLOSED ? ToolTipState.SOFT_OPENED : isOpen));
  const softClose = () =>
    setOpen((isOpen) => (isOpen === ToolTipState.SOFT_OPENED ? ToolTipState.CLOSED : isOpen));
  const hardToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen((isOpen) =>
      isOpen === ToolTipState.HARD_OPENED ? ToolTipState.CLOSED : ToolTipState.HARD_OPENED
    );
  };
  const hardClose = () => setOpen(ToolTipState.CLOSED);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const toolTipIconRef = useRef<HTMLButtonElement>(null);

  useOnClickOutside(wrapperRef, () => {
    if (open) hardClose();
  });

  return (
    <div
      data-testid={`lk-tooltip-${position}`}
      className={cn(
        styles.tooltip,
        styles[`open${position}`],
        styles[`${type}Type`],
        {
          [`${styles.softOpen}`]: open === ToolTipState.SOFT_OPENED,
          [`${styles.hardOpen}`]: open === ToolTipState.HARD_OPENED,
          [`${styles.shadow}`]: addShadow,
        },
        className // Should be last to override internal values
      )}
    >
      <div className={styles.wrapper} ref={wrapperRef} onMouseLeave={softClose}>
        <button
          type="button"
          onClick={hardToggle}
          onFocus={softOpen}
          onMouseEnter={softOpen}
          ref={toolTipIconRef}
          className={styles.iconContainer}
          aria-label="Toggle tooltip"
        >
          <SVGInfoCircleAnimated
            active={open === ToolTipState.HARD_OPENED}
            className={styles.toggleIcon}
            animationEvent={SVGAnimationEvents.CLICK}
            containerRef={toolTipIconRef}
            hasBackground={false}
            width={SVGIconSizes.SMALL}
            height={SVGIconSizes.SMALL}
          />
        </button>
        {shouldPopUpBeRendered(children) && (
          <div
            className={cn(styles.popup)}
            style={{ width }}
            data-testid={`lk-tooltip-popup-${position}`}
          >
            <div className={styles.popupContainer}>
              <svg className={cn(styles.popupTriangle)} viewBox="0 0 100 100">
                <polygon points="50, 50 100, 0 0, 0" />
              </svg>
              <div className={styles.popupContent} onBlur={hardClose}>
                {children}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ToolTip = memo(ToolTip);

export { ToolTip };

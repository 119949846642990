import cn from "classnames";
import { useRef } from "react";

import { getVideoUrls, useVideoAnalytics, useVideoObserver } from "./helpers";
import styles from "./VideoOrImage.module.scss";

export interface VideoOrImageProps {
  className?: string;

  image?: React.ReactNode;

  /**
   * Multiline text with list of alternative sources and codecs
   * Lines can be split using `\n`, `\r\n` or `<br />`
   *
   * Codec information is required for second and further sources.
   * Use `|` (pipe) after URL on each line to add it. This will be
   * split into `type` parameter of each video `<source />`
   */
  videoInformation: string | undefined;

  /**
   * Root container style
   */
  style?: React.CSSProperties;

  /*
   * Callback to be called when video is loaded
   */
  onVideoLoaded?: () => void;
}

export const VideoOrImage: React.FC<VideoOrImageProps> = ({
  className,
  image,
  onVideoLoaded,
  style,
  videoInformation,
}) => {
  const videoComponentRef = useRef<HTMLVideoElement>(null);
  const videoSources = getVideoUrls(videoInformation);

  const handleVideoOnLoadedData = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    if (e.currentTarget.currentSrc && onVideoLoaded) {
      onVideoLoaded();
    }
  };

  const videoCanPlay = useVideoObserver(videoComponentRef);

  useVideoAnalytics(videoComponentRef);

  return (
    <div style={style} className={cn(className, styles.videoOrImage)}>
      {!videoCanPlay && <div className={styles.imageContainer}>{image}</div>}
      {videoSources && (
        <video
          className={styles.video}
          muted={true}
          loop={true}
          suppressHydrationWarning={true}
          autoPlay={false}
          preload="none"
          data-preload="metadata"
          playsInline={true}
          ref={videoComponentRef}
          onLoadedData={handleVideoOnLoadedData}
        >
          {videoSources.map((v) => (
            <source src={v.src} type={v.type} key={`${v.src}${v.type || ""}`} />
          ))}
        </video>
      )}
    </div>
  );
};

import cn from "classnames";

import styles from "./PrimaryNavItems.module.scss";
import { MenuItemTyped, MenuItemType, MenuItemWithChildren } from "../../types/types";

type PrimaryNavItemsProps = {
  /**
   * items to be rendered
   */
  items: MenuItemTyped[];
  /**
   * When provided, the item under this index will be underlined.
   */
  itemSelected?: MenuItemTyped;
  /**
   * Click handler for nav items with children
   */
  onItemWithChildrenClick?: (item: MenuItemWithChildren) => void;
  /**
   * When true, locks the button in its hovered state. Should rarely be used outside VRT.
   */
  isPseudoHovered?: boolean;
  /**
   * When true, locks the button in its focused state. Should rarely be used outside VRT.
   */
  isPseudoFocused?: boolean;
  dataTestId?: string;
};

const itemRender = (
  item: MenuItemTyped,
  index: number,
  classNames: string,
  onItemWithChildrenClick?: (item: MenuItemWithChildren) => void
) => {
  switch (item.type) {
    case MenuItemType.LEAF:
      return (
        item.link && (
          <a
            href={item.link.url}
            key={item.link.label === null ? `primary_nav_item_${index}` : item.link.label}
            target={item.link.target || "_self"}
            className={classNames}
          >
            {item.link.label}
          </a>
        )
      );
    case MenuItemType.WITHCHILDREN:
      return (
        <button
          key={item.name}
          className={classNames}
          onClick={() => onItemWithChildrenClick?.(item)}
        >
          {item.name}
        </button>
      );
  }
};

const PrimaryNavItems: React.FC<PrimaryNavItemsProps> = ({
  items,
  isPseudoFocused,
  isPseudoHovered,
  itemSelected,
  dataTestId,
  onItemWithChildrenClick,
}) => {
  const classNames = (menuItem: MenuItemTyped) =>
    cn(styles.primaryNavItem, {
      [`${styles.primaryNavItemHovered}`]: isPseudoHovered,
      [`${styles.primaryNavItemFocused}`]: isPseudoFocused,
      [`${styles.primaryNavItemSelected}`]: itemSelected === menuItem,
    });

  return (
    <div
      data-testid={dataTestId}
      className={cn(styles.primaryNavItems, { [styles.isNavItemSelected]: itemSelected })}
    >
      {items.map((menuItem, index) =>
        itemRender(menuItem, index, classNames(menuItem), onItemWithChildrenClick)
      )}
    </div>
  );
};

export { PrimaryNavItems };

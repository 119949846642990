import jwt_decode from "jwt-decode";

import { AuthenticationConfig } from "./authentication.types";
import { CurrentUser, JwtToken } from "../../types/auth";

const MS_PER_SEC = 1000;
export const IDTOKEN_STORAGE_KEY = "id_token";
export const IDTOKEN_STORAGE_EVENT = "lexus-encore-idtoken-stored";
export const ENCORE_LOGOUT_EVENT = "lexus-encore-logout";

const getIdTokenFromSessionStorage = (): string | null => {
  if (typeof sessionStorage === "undefined") {
    return null;
  }

  return sessionStorage.getItem(IDTOKEN_STORAGE_KEY);
};

const getJwtToken = (): {
  decoded: JwtToken | null;
  rawToken: string | null;
} => {
  const idToken = getIdTokenFromSessionStorage();

  if (!idToken) {
    return { decoded: null, rawToken: null };
  }

  const decodedToken = jwt_decode<JwtToken>(idToken);

  return { decoded: decodedToken, rawToken: idToken };
};

export const getUser = (): CurrentUser | undefined => {
  const { decoded: token, rawToken: idToken } = getJwtToken();
  return token && idToken && (token.exp || 0) > new Date().getTime() / MS_PER_SEC
    ? {
        givenName: token.given_name,
        familyName: token.family_name,
        tokens: {
          id: {
            value: idToken,
            expiresAt: token.exp * MS_PER_SEC,
          },
        },
      }
    : undefined;
};

export const storeIdToken = (idToken: string) => {
  const jwtToken = jwt_decode<JwtToken>(idToken);
  if (!jwtToken || typeof sessionStorage === "undefined" || typeof window === "undefined") {
    return;
  }

  sessionStorage.setItem(IDTOKEN_STORAGE_KEY, idToken);

  window.dispatchEvent(new CustomEvent(IDTOKEN_STORAGE_EVENT));
};

export const clearIdToken = () => {
  if (typeof sessionStorage === "undefined" || typeof window === "undefined") {
    return;
  }
  sessionStorage.removeItem(IDTOKEN_STORAGE_KEY);

  window.dispatchEvent(new CustomEvent(IDTOKEN_STORAGE_EVENT));
};

export const doLogout = (onLogout?: () => void, config?: AuthenticationConfig) => {
  if (typeof window === "undefined") {
    return;
  }

  clearIdToken();

  if (config?.isAuthenticationHost) {
    onLogout?.();
    return;
  }

  window.dispatchEvent(new CustomEvent(ENCORE_LOGOUT_EVENT));
};

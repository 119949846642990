import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGLocation: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0c3.308 0 6 2.558 6 5.702 0 2.952-4.998 9.368-5.568 10.09a.55.55 0 01-.864 0C6.998 15.07 2 8.654 2 5.702 2 2.558 4.692 0 8 0zm0 14.582c1.688-2.223 4.91-6.876 4.91-8.88 0-2.556-2.203-4.635-4.91-4.635-2.707 0-4.91 2.08-4.91 4.636 0 2.003 3.222 6.657 4.91 8.88zM8 3.733c1.203 0 2.182.957 2.182 2.134C10.182 7.043 9.203 8 8 8c-1.203 0-2.182-.957-2.182-2.133 0-1.177.979-2.134 2.182-2.134zm0 3.2a1.08 1.08 0 001.09-1.066A1.08 1.08 0 008 4.8a1.08 1.08 0 00-1.09 1.067c0 .588.488 1.066 1.09 1.066z"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGLocation = memo(SVGLocation);

export { SVGLocation };

import * as React from "react";
import { defaultIconSize, defaultIconColor } from "../constants";

let SVGChevronLeft: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M23.3284 25.0003L20.5 27.8287L8.67157 16.0003L20.5 4.17188L23.3284 7.0003L14.3284 16.0003L23.3284 25.0003Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGChevronLeft = React.memo(SVGChevronLeft);

export { SVGChevronLeft };

import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { closeToastUrl, routeString, RouteSection } from "../../../helpers/routes";
import ErrorCard from "../ErrorCard/ErrorCard";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import { ButtonStyle, ButtonShade } from "lexus-style-guide/buttons/shared";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";

const DiscardUpdateBookingCard: React.FC<RouteComponentProps> = ({ history, location }) => (
    <ErrorCard title={"booking changes have not been saved"}>
        <ErrorCardFinePrint>Leaving this page will clear all the changes made to your booking.</ErrorCardFinePrint>
        <ErrorCardButton
            onClick={() => history.push(routeString(RouteSection.Encore, RouteSection.Bookings))}
            buttonStyle={ButtonStyle.Primary}
        >
            leave
        </ErrorCardButton>
        <ErrorCardButton
            onClick={() => history.push(closeToastUrl(location.pathname))}
            buttonStyle={ButtonStyle.Secondary}
            shade={ButtonShade.Light}
        >
            continue editing
        </ErrorCardButton>
    </ErrorCard>
);

export default withRouter(DiscardUpdateBookingCard);

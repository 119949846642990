import Layout from "Components/Layout/Layout";
import Spinner from "Components/Spinner/Spinner";
import { RouteSection, routeString } from "Helpers/routes";
import { useSettingsPromise } from "Hooks/usePromiseState";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, useLocation, withRouter } from "react-router";
import { emailSelector } from "ReduxSlices/user";
import { ChangePasswordWizard } from "./ChangePasswordWizard";

export const emailRequest = (search: string): string | undefined =>
    new URLSearchParams(search).get("email") || undefined;

const ChangePasswordPage: React.FC<RouteComponentProps> = ({ history }) => {
    const generalSettings = useSettingsPromise(settings => settings.general);
    const knownEmail = useSelector(emailSelector);
    const location = useLocation();
    const email = knownEmail || emailRequest(location.search);
    const handleExit = React.useCallback(() => history.push(routeString(RouteSection.AccountSettings)), []);

    return (
        <Layout>
            {!generalSettings && (
                <div className="account__spinner-container">
                    <Spinner />
                </div>
            )}
            <Switch>
                <Route
                    path={routeString(RouteSection.ChangePassword)}
                    render={() => (email ? <ChangePasswordWizard email={email} onExit={handleExit} /> : null)}
                />
                <Redirect to={routeString(RouteSection.AccountSettings)} />
            </Switch>
        </Layout>
    );
};

export default withRouter(ChangePasswordPage);

import cn from "classnames";

import { HEADING_VARIANTS, TypographyProps, VARIANTS } from "@/components/common/typographyTypes";

import typographyStyles from "../../common/typography.module.scss";

const isIntrinsicHeading = (
  variant: string
): variant is keyof JSX.IntrinsicElements & (typeof VARIANTS)[number] =>
  !!HEADING_VARIANTS.find((heading) => heading === variant);

export const childrenWrapper = (
  children: React.ReactNode,
  _: React.ComponentType<TypographyProps>,
  props: TypographyProps
) => {
  const {
    className,
    component,
    variant = "b1",
    children: initialChildren,
    href,
    target,
    rel,
    isSpecialTextDisabled = false,
    fontWeight,
    hasSenkeiLine = false,
    isIndented = false,
  } = props;

  const TypographyComponent = component ?? (isIntrinsicHeading(variant) ? variant : "span");
  return {
    stopProcessing: false,
    result: (
      <TypographyComponent
        data-testid={`lk-tg-${variant}`}
        className={cn(
          {
            [typographyStyles.indented]: isIndented,
          },
          variant && typographyStyles[variant],
          fontWeight && typographyStyles[fontWeight],
          className
        )}
        href={href ? href : undefined}
        target={target ? target : undefined}
        rel={rel ? rel : undefined}
      >
        {hasSenkeiLine && <span className={typographyStyles.senkeiLine} />}
        {isSpecialTextDisabled ? initialChildren : children}
      </TypographyComponent>
    ),
  };
};

import * as React from "react";
import Toast from "../../components/Toast/Toast";
import { valetProviderBalanceErrorSelector } from "../../reduxSlices/valet";
import RedirectErrorCard from "../../components/errorCards/RedirectErrorCard/RedirectErrorCard";
import { routeString, RouteSection } from "../../helpers/routes";

const ValetRedemptionBalanceErrorToast: React.FC = () => {
    return (
        <Toast close>
            <RedirectErrorCard
                errorSelector={valetProviderBalanceErrorSelector}
                destinationName="the dashboard"
                destinationRoute={routeString(RouteSection.Dashboard)}
            />
        </Toast>
    );
};

export default ValetRedemptionBalanceErrorToast;

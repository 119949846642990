import { memo } from "react";

import { SVGEncoreLogoSizes, SVGStaticProps } from "../types";

let SVGEncoreElectrified: React.FC<SVGStaticProps> = ({
  width = SVGEncoreLogoSizes.DEFAULT_WIDTH,
  height = SVGEncoreLogoSizes.DEFAULT_HEIGHT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 245 60"
    fill="currentColor"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M191.323 21.8935L190.953 21.3H191.652C195.899 21.3 203.034 20.4032 203.034 14.3892C203.034 7.57073 198.721 5.15723 195.042 5.15723H184.082V41.8346H188.039V22.6584L199.315 41.8346H203.904C201.649 38.2736 193.551 25.5335 191.283 21.8935M188.039 8.70494H193.696C194.338 8.64838 194.984 8.72554 195.594 8.93154C196.205 9.13754 196.766 9.4679 197.241 9.90173C197.717 10.3356 198.098 10.8634 198.36 11.452C198.621 12.0405 198.758 12.6769 198.761 13.3209C198.822 14.006 198.733 14.6961 198.501 15.3437C198.27 15.9913 197.901 16.5811 197.419 17.0724C196.938 17.5637 196.356 17.9449 195.713 18.1897C195.071 18.4346 194.383 18.5373 193.696 18.4908H188.039V8.70494Z"
      fill="currentColor"
    />
    <path
      id="Vector_2"
      d="M23.3935 18.2661H9.71701V8.77032H24.4486V5.35449H5.24609V21.4709H23.3935V18.2661Z"
      fill="currentColor"
    />
    <path
      id="Vector_3"
      d="M4.46816 38.2736V4.57692H24.4488V1.2666H0.828125V41.8345H25.2797V38.2736H4.46816Z"
      fill="currentColor"
    />
    <path
      id="Vector_4"
      d="M104.174 8.37494C105.969 8.35033 107.75 8.69732 109.404 9.39411C111.059 10.0909 112.551 11.1224 113.788 12.4238L116.426 9.33771C113.116 6.7 110.491 4.61621 104.187 4.61621C100.024 4.81184 96.094 6.59592 93.2064 9.60126C90.3189 12.6066 88.6932 16.6045 88.6641 20.7722C88.6641 30.7295 95.377 38.5371 103.95 38.5371C108.856 38.3576 113.535 36.425 117.138 33.0903L114.5 30.0965C111.863 32.5495 109.396 34.7125 104.279 34.7125C96.775 34.7125 92.7261 29.6612 92.7261 21.3261C92.7261 13.8746 97.1707 8.37494 104.121 8.37494"
      fill="currentColor"
    />
    <path
      id="Vector_5"
      d="M41.2638 4.4714L67.5749 36.414V1.31934H63.5129V24.2674C63.5129 25.2697 63.6184 26.272 63.6184 26.3248C63.3616 25.7292 63.0339 25.1669 62.6424 24.6499L44.7324 1.31934H37.0566V41.8345H41.2638V4.4714Z"
      fill="currentColor"
    />
    <path
      id="Vector_6"
      d="M243.155 8.7713V5.35547H223.992V21.4719H242.087V18.267H228.424V8.7713H243.155Z"
      fill="currentColor"
    />
    <path
      id="Vector_7"
      d="M243.986 34.3307H228.424V25.7713H242.1V22.25H223.992V37.4959H243.986V34.3307Z"
      fill="currentColor"
    />
    <path
      id="Vector_8"
      d="M25.2795 37.495V34.3297H9.71701V25.7704H23.3935V22.249H5.24609V37.495H25.2795Z"
      fill="currentColor"
    />
    <path
      id="Vector_9"
      d="M147.181 4.83984C135.944 4.83984 130.906 12.5156 130.906 20.1254V20.8375C130.906 29.3573 135.997 38.5366 147.181 38.5366C154.698 38.5366 162.796 33.7491 162.796 23.2379V22.5257C162.796 9.46902 154.382 4.83984 147.181 4.83984ZM147.049 34.7119C140.705 34.7119 135.377 28.8298 135.377 21.3783C135.377 14.5994 140.204 8.37437 147.155 8.37437C154.105 8.37437 158.826 14.2565 158.826 21.3783C158.826 28.2099 154.382 34.7119 147.049 34.7119Z"
      fill="currentColor"
    />
    <path
      id="Vector_10"
      d="M147.142 0.59375C134.587 0.59375 126.186 9.70703 126.186 21.3789C126.186 33.0507 134.468 42.4805 147.037 42.4805C159.605 42.4805 167.98 33.0903 167.98 21.3789C167.98 9.31137 159.816 0.59375 147.142 0.59375ZM163.575 22.856V23.2385C163.606 27.1947 162.237 31.0345 159.711 34.0794C158.14 35.8271 156.202 37.2061 154.036 38.118C151.87 39.03 149.53 39.4523 147.182 39.3549C144.662 39.4427 142.158 38.9272 139.877 37.8512C137.597 36.7753 135.607 35.1702 134.072 33.1694C131.47 29.6021 130.083 25.2931 130.116 20.8777V20.1655C130.116 12.1733 135.391 4.1019 147.169 4.1019C149.644 3.99455 152.108 4.50349 154.338 5.58307C156.569 6.66264 158.496 8.27907 159.948 10.2873C162.367 13.9205 163.627 18.2017 163.562 22.5659L163.575 22.856Z"
      fill="currentColor"
    />
    <path
      id="Vector_11"
      d="M68.3541 38.577L42.043 6.59475V41.8345H45.9995V18.5963C45.9995 17.6599 45.894 16.6576 45.894 16.5917C46.173 17.1483 46.4811 17.6897 46.8172 18.2139L64.7404 41.8345H72.403V1.31934H68.3541V38.577Z"
      fill="currentColor"
    />
    <path
      id="Vector_12"
      d="M207.717 13.3732C207.717 6.37008 202.27 1.25293 193.763 1.25293H179.256V41.8341H183.397V4.37861H195.122C199.171 4.37861 203.892 7.01632 203.892 14.3887C203.892 19.1893 199.83 21.9062 192.418 22.0644C195.214 26.5222 203.246 39.2095 204.947 41.8472H209.497L198.709 24.8868C203.984 23.4492 207.769 19.4399 207.769 13.3864"
      fill="currentColor"
    />
    <path
      id="Vector_13"
      d="M223.213 38.2736V4.57692H243.154V1.2666H219.533V41.8345H243.985V38.2736H223.213Z"
      fill="currentColor"
    />
    <path
      id="Vector_14"
      d="M103.949 39.3158C94.9408 39.3158 87.885 31.1652 87.885 20.7727C87.9038 16.3985 89.6015 12.1985 92.6275 9.03986C95.6536 5.88125 99.777 4.00518 104.146 3.79902C110.741 3.79902 113.378 5.96194 116.873 8.69197L118.72 6.54224C116.865 4.5421 114.597 2.96939 112.074 1.93319C109.551 0.896983 106.832 0.422092 104.107 0.541452C101.377 0.488743 98.665 0.990185 96.1346 2.01546C93.6042 3.04073 91.308 4.56853 89.3848 6.5065C87.4616 8.44446 85.9515 10.7523 84.9456 13.2905C83.9398 15.8287 83.4591 18.5446 83.5327 21.2738C83.5327 34.2777 91.9338 42.3755 104.265 42.3755C107.152 42.4894 110.027 41.9563 112.681 40.8154C115.335 39.6744 117.7 37.9544 119.603 35.7812L117.625 33.526C113.878 37.0064 108.995 39.0076 103.883 39.1575"
      fill="currentColor"
    />
    <path
      id="Vector_15"
      d="M4.61324 55.5898H2.41075V60.1267H0.828125V48.5999H4.29671C4.7829 48.5612 5.27181 48.6246 5.732 48.7862C6.19219 48.9478 6.61347 49.2039 6.96874 49.538C7.32402 49.8722 7.60542 50.277 7.79485 50.7264C7.98427 51.1759 8.07754 51.66 8.06863 52.1476C8.04514 53.0554 7.67314 53.9193 7.0298 54.5602C6.38645 55.2011 5.52111 55.5698 4.61324 55.5898ZM4.05932 50.1166H2.41075V54.0732H4.15164C4.43983 54.1071 4.73196 54.0789 5.00835 53.9905C5.28473 53.9021 5.53896 53.7555 5.75391 53.5605C5.96886 53.3656 6.13954 53.1268 6.25445 52.8603C6.36936 52.5939 6.42583 52.3059 6.42007 52.0157C6.42007 50.7892 5.52325 50.1166 4.05932 50.1166Z"
      fill="currentColor"
    />
    <path
      id="Vector_16"
      d="M13.8457 60.1264V48.5996H15.4283V58.6361H19.8069V60.1264H13.8457Z"
      fill="currentColor"
    />
    <path
      id="Vector_17"
      d="M33.1803 60.1264L31.7823 56.394H28.1027L26.7179 60.1264H25.043L29.4611 48.5996H30.4766L34.9476 60.1264H33.1803ZM30.0282 51.6857C30.0282 51.567 29.9359 51.3692 29.9359 51.3692L29.8568 51.6725L28.6566 54.9169H31.2943L30.0282 51.6857Z"
      fill="currentColor"
    />
    <path
      id="Vector_18"
      d="M42.8609 50.1163V60.166H41.2387V50.1163H38.9043V48.5996H45.2875V50.1163H42.8609Z"
      fill="currentColor"
    />
    <path
      id="Vector_19"
      d="M51.2617 60.1264V48.5996H52.8443V60.1264H51.2617Z"
      fill="currentColor"
    />
    <path
      id="Vector_20"
      d="M67.2202 60.1264L61.1799 51.7649L60.9688 51.4351C60.9688 51.4351 60.9688 51.6989 60.9688 51.8176V60.1132H59.4258V48.5996H60.7446L66.719 56.7897L66.9301 57.1194C66.9217 56.9965 66.9217 56.8731 66.9301 56.7501V48.5996H68.5127V60.1264H67.2202Z"
      fill="currentColor"
    />
    <path
      id="Vector_21"
      d="M79.4981 60.311C78.8927 60.3506 78.2859 60.2596 77.7187 60.0442C77.1516 59.8288 76.6373 59.4941 76.2108 59.0626C75.7844 58.6311 75.4556 58.113 75.2468 57.5434C75.0381 56.9737 74.9542 56.3659 75.0008 55.761V48.5996H76.5966V55.761C76.5473 56.1616 76.5869 56.5682 76.7127 56.9518C76.8386 57.3353 77.0475 57.6863 77.3246 57.9798C77.6018 58.2733 77.9403 58.5021 78.316 58.6497C78.6917 58.7973 79.0953 58.8602 79.4981 58.8339C79.9013 58.8572 80.3047 58.7925 80.6804 58.6443C81.0561 58.496 81.395 58.2678 81.6735 57.9754C81.9521 57.683 82.1637 57.3335 82.2937 56.9511C82.4236 56.5687 82.4687 56.1626 82.426 55.761V48.5996H83.9954V55.761C84.0231 56.3614 83.9261 56.961 83.7106 57.522C83.4951 58.0831 83.1657 58.5934 82.7432 59.0209C82.3207 59.4483 81.8142 59.7836 81.2557 60.0057C80.6972 60.2277 80.0988 60.3317 79.4981 60.311Z"
      fill="currentColor"
    />
    <path
      id="Vector_22"
      d="M100.243 60.1264V51.6462C100.243 51.4351 100.243 51.1978 100.243 51.1978C100.199 51.2946 100.146 51.3873 100.085 51.4747L96.471 56.3545H95.838L92.1979 51.5934C92.1391 51.5138 92.0862 51.4301 92.0397 51.3428C92.0397 51.3428 92.0397 51.567 92.0397 51.7649V60.1264H90.457V48.5996H91.7759L96.1149 54.2311L100.335 48.5996H101.746V60.1264H100.243Z"
      fill="currentColor"
    />
    <path
      id="Vector_23"
      d="M115.002 60.1264V48.5996H121.451V50.1163H116.585V53.7959H121.332V55.2862H116.585V58.6757H121.741V60.166L115.002 60.1264Z"
      fill="currentColor"
    />
    <path
      id="Vector_24"
      d="M127.953 60.1264V48.5996H129.536V58.6361H133.862V60.1264H127.953Z"
      fill="currentColor"
    />
    <path
      id="Vector_25"
      d="M139.742 60.1264V48.5996H146.191V50.1163H141.325V53.7959H146.073V55.2862H141.325V58.6757H146.495V60.166L139.742 60.1264Z"
      fill="currentColor"
    />
    <path
      id="Vector_26"
      d="M157.863 60.3112C156.386 60.1638 155.018 59.4707 154.025 58.3676C153.032 57.2644 152.486 55.8305 152.494 54.3463C152.502 52.8622 153.064 51.4344 154.069 50.3424C155.074 49.2503 156.451 48.5725 157.929 48.4415C158.731 48.3869 159.535 48.522 160.275 48.8359C161.015 49.1498 161.671 49.6337 162.189 50.2483L161.028 51.2902C160.442 50.6771 159.682 50.2577 158.851 50.0879C158.02 49.9182 157.156 50.0062 156.376 50.3403C155.596 50.6743 154.937 51.2385 154.486 51.9574C154.035 52.6763 153.815 53.5156 153.854 54.3632C153.822 54.9212 153.901 55.4799 154.088 56.0068C154.274 56.5337 154.564 57.0182 154.939 57.432C155.315 57.8457 155.77 58.1805 156.276 58.4167C156.783 58.6529 157.331 58.7858 157.89 58.8077C158.537 58.8063 159.176 58.6626 159.762 58.3868C160.348 58.1111 160.865 57.71 161.279 57.2119L162.413 58.1878C161.876 58.8751 161.183 59.4257 160.393 59.7947C159.602 60.1637 158.735 60.3407 157.863 60.3112Z"
      fill="currentColor"
    />
    <path
      id="Vector_27"
      d="M171.764 50.1163V60.166H170.182V50.1163H167.834V48.5996H174.217V50.1163H171.764Z"
      fill="currentColor"
    />
    <path
      id="Vector_28"
      d="M186.457 60.1263L181.801 54.7454V60.1263H180.219V48.5995H183.977C184.866 48.5628 185.733 48.8805 186.387 49.4828C187.041 50.085 187.429 50.9225 187.466 51.811C187.503 52.6994 187.185 53.566 186.583 54.2202C185.98 54.8744 185.143 55.2626 184.254 55.2993L188.514 60.1263H186.457ZM183.661 50.1162H181.801V54.0728H183.635C183.921 54.1198 184.215 54.1038 184.495 54.0261C184.775 53.9483 185.035 53.8105 185.257 53.6223C185.478 53.4341 185.656 53.2 185.778 52.9362C185.9 52.6724 185.963 52.3852 185.963 52.0945C185.963 51.8038 185.9 51.5166 185.778 51.2528C185.656 50.989 185.478 50.7549 185.257 50.5667C185.035 50.3785 184.775 50.2407 184.495 50.163C184.215 50.0852 183.921 50.0692 183.635 50.1162H183.661Z"
      fill="currentColor"
    />
    <path id="Vector_29" d="M194.08 60.1264V48.5996H195.663V60.1264H194.08Z" fill="currentColor" />
    <path
      id="Vector_30"
      d="M203.826 50.1163V53.7959H207.716V55.2862H203.852V60.166H202.27V48.5996H207.954V50.1163H203.826Z"
      fill="currentColor"
    />
    <path id="Vector_31" d="M214.1 60.1264V48.5996H215.629V60.1264H214.1Z" fill="currentColor" />
    <path
      id="Vector_32"
      d="M222.225 60.1264V48.5996H228.674V50.1163H223.807V53.7959H228.555V55.2862H223.807V58.6757H228.977V60.166L222.225 60.1264Z"
      fill="currentColor"
    />
    <path
      id="Vector_33"
      d="M238.05 60.1264H235.254V48.5996H238.195C242.152 48.5996 244.354 51.2373 244.354 54.3234C244.354 57.4096 242.006 60.1264 238.05 60.1264ZM238.169 50.0108H236.85V58.702H237.878C241.294 58.702 242.732 56.4995 242.732 54.3498C242.735 53.7566 242.616 53.169 242.382 52.6237C242.148 52.0784 241.805 51.587 241.373 51.1801C240.942 50.7731 240.431 50.4593 239.873 50.2581C239.315 50.057 238.721 49.9728 238.129 50.0108H238.169Z"
      fill="currentColor"
    />
  </svg>
);
SVGEncoreElectrified = memo(SVGEncoreElectrified);

export { SVGEncoreElectrified };

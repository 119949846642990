import "./ImageCard.scss";

import * as React from "react";

import { SVGIconLink, SVGIconLinkIconPosition } from "../SVGIconLink/SVGIconLink";
import { Sticker, StickerType } from "../Sticker/Sticker";

import { SVGChevronRight } from "../SVGIcons/icons/static/SVGChevronRight";
import { getImageSource } from "../../utilities/Sitecore/SitecoreUtilities";
import { lxColor } from "../../colors/variables";
import { splitHalf } from "../../utilities/string-helpers";
import { useGTM } from "../GTM/useGTM";
import { createBemFn } from "../../utilities/bem";

interface ImageCardProps {
    title: LXS.TextField;
    image: SCJSS.ImageField;
    hoverImage: SCJSS.ImageField;
    subtitle?: LXS.TextField;
    ctaLink1?: SCJSS.LinkField;
    ctaLink2?: SCJSS.LinkField;
    path?: LXS.TextField;
    stickerTags?: LXS.StickerTag[];
    promotionSticker?: LXS.TextField;
}

const bem = createBemFn("lxs-image-card");

const ImageCard: React.FC<ImageCardProps> = ({
    title,
    image,
    hoverImage,
    subtitle,
    ctaLink1,
    ctaLink2,
    path,
    stickerTags,
    promotionSticker,
}) => {
    const imageUrl = getImageSource(image);
    const imageHoverUrl = getImageSource(hoverImage);
    const linkContainer = React.useRef<HTMLDivElement>(null);
    const { gtmClick } = useGTM();
    const ctaLink = (ctaLink: SCJSS.LinkField, title?: string) =>
        ctaLink?.value.text && (
            <SVGIconLink
                textClass={bem("link-text")}
                iconClass={bem("link-icon")}
                field={ctaLink}
                iconPosition={SVGIconLinkIconPosition.LEFT_TO_RIGHT}
                data-gtm-model={title}
                data-gtm-linktype="ModelCardLink"
            >
                <SVGChevronRight height={11} width={11} color={lxColor("black")} />
            </SVGIconLink>
        );
    const promotionStickerLines = splitHalf((promotionSticker && promotionSticker.value) || "");
    const showPromoSticker = promotionStickerLines.line1.length > 0 && (!stickerTags || stickerTags.length < 2);

    return (
        <div className={bem()}>
            <a
                className={bem("top-link")}
                href={path ? path.value : undefined}
                onClick={gtmClick}
                data-gtm-target="ModelCard"
                data-gtm-action={path && path.value}
                data-gtm-model={title && title.value}
            >
                <span className={bem("container")}>
                    <span className={bem("name")}>{title.value}</span>
                    <div className={bem("img-container")}>
                        <img className={bem("img")} src={imageUrl} {...image} />
                        <img className={bem("img", "hover")} src={imageHoverUrl} {...hoverImage} />
                    </div>
                </span>
                <span className={bem("subtitle")}>
                    <span className={bem("subtitle-text")}>{subtitle ? subtitle.value : ""}</span>
                </span>
                {showPromoSticker && (
                    <div className={bem("promo-sticker")}>
                        <Sticker
                            line1={promotionStickerLines.line1}
                            line2={promotionStickerLines.line2}
                            style={StickerType.ROUND1}
                        />
                    </div>
                )}
                {stickerTags && stickerTags.length > 0 && (
                    <div className={bem("sticker-tags")}>
                        {stickerTags.slice(0, 2).map((stickerTag) => (
                            <Sticker
                                key={stickerTag.id}
                                line1={stickerTag.titleDesktop || splitHalf(stickerTag.name).line1}
                                line2={stickerTag.subtitleDesktop || splitHalf(stickerTag.name).line2}
                                style={StickerType.DEFAULT}
                            />
                        ))}
                    </div>
                )}
            </a>
            <div className={bem("footer")}>
                <div className={bem("footer-links")} ref={linkContainer}>
                    {ctaLink1 && ctaLink(ctaLink1, title && title.value)}
                    {ctaLink2 && ctaLink(ctaLink2, title && title.value)}
                </div>
            </div>
        </div>
    );
};
const ImageCardMemoised = React.memo(ImageCard);
export { ImageCardMemoised };

import React, { memo, useState } from "react";
import "./VerificationForm.scss";
import { TextField } from "../../Form";
import { createBemFn } from "../../../utilities/bem";
import { Button } from "../../../buttons/Button";
import { AccountToast } from "./AccountToast";
import { Spinner } from "../../Spinner/Spinner";
import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import { GenericError } from "./GenericError";

const bem = createBemFn("lxs-otp-verification");
const otpPattern = /^[0-9]{6,}$/; // Minimum length is 6 digits

const strings = {
    requiredField: "This field is required",
    otpError: "Invalid code. Please try again or resend a new code",
} as const;

const resendStrings = {
    title: "Verify Email Address",
    buttonText: "back to enter code",
    secondMessage:
        "If you have any questions about your account, " +
        "please contact the Lexus Customer Service Centre on 1800 023 009, or enquiries@lexusenquiries.com.au.",
} as const;

type OtpErrorMsgType = typeof strings[keyof typeof strings] | undefined;

interface VerificationFormProps {
    onSubmit: (code: string) => Promise<void>;
    onResendRequired: () => Promise<void>;
    email: string;
}

const VerificationForm: React.FC<VerificationFormProps> = ({ onSubmit, email, onResendRequired }) => {
    const [otpValue, setOtpValue] = useState("");
    const [otpErrorMsg, setOtpErrorMsg] = useState<OtpErrorMsgType>();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<Error>();
    const [resend, setResend] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true);
        onSubmit(otpValue)
            .then(() => setError(undefined))
            .catch((reason) => {
                setProcessing(false);
                if (reason instanceof AuthenticationError && reason.hasType(AuthenticationErrorType.OtpInvalid)) {
                    setOtpErrorMsg(strings.otpError);
                    return;
                }
                setError(reason instanceof Error ? reason : new Error());
            });
    };

    const handleToastClose = () => setError(undefined);
    const handleResendToastOnClick = () => setResend(false);
    const handleResendOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onResendRequired()
            .then(() => setResend(true))
            .catch((reason) => {
                setError(reason instanceof Error ? reason : new Error());
            });
    };

    const otpOnChange = (value: string) => setOtpValue(value);
    const otpOnFocus = () => setOtpErrorMsg(undefined);
    const otpOnBlur = () =>
        setOtpErrorMsg(!otpValue ? strings.requiredField : !otpPattern.test(otpValue) ? strings.otpError : undefined);

    const resendMessage = (
        <div>
            An email with your one time verification code has been sent to <strong>{email}</strong>. This code will
            expire in 5 minutes.
        </div>
    );

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>Verify Email Address</h2>
            <p className={bem("text")}>
                To keep your account secure we require you to verify your email.&nbsp; A one time verification code has
                been sent to <strong>{email}</strong>.&nbsp; This code will expire in 5 minutes.
            </p>
            <TextField
                label="one time code*"
                value={otpValue}
                isError={!!otpErrorMsg}
                errorText={otpErrorMsg}
                onChange={otpOnChange}
                className={bem("text-field")}
                onFocus={otpOnFocus}
                onBlur={otpOnBlur}
                disabled={processing}
            />
            {error && <GenericError isOpen={!!error} error={error} onClose={handleToastClose} />}
            <Button className={bem("button")} disabled={!otpPattern.test(otpValue) || processing} type="submit">
                Verify
            </Button>
            {processing && <Spinner />}
            <div>
                <p>
                    <a className={bem("link")} href="#" onClick={handleResendOnClick}>
                        <strong>Haven't received a code? Resend a new code</strong>
                    </a>
                </p>
            </div>
            {resend && (
                <AccountToast
                    title={resendStrings.title}
                    isOpen={resend}
                    message={resendMessage}
                    buttonText={resendStrings.buttonText}
                    secondMessage={resendStrings.secondMessage}
                    onClose={handleResendToastOnClick}
                />
            )}
        </form>
    );
};

const VerificationFormMemoised = memo(VerificationForm);

export { VerificationFormMemoised as VerificationForm };

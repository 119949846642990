import { createContext } from "react";

import { ThemeDefinition, ThemeVariant } from "./themingTypes";

interface ThemeContextProps {
  /**
   * Contains currently active theme definition
   */
  currentTheme: ThemeDefinition;

  /**
   * Gets matching variant class name or default class name
   * @param variant variant to map with class name
   * @returns Matched or default class name
   */
  getVariantClassNameOrDefault: (variant: ThemeVariant) => string;
}

/**
 * Contains current them definition
 * default value is always defined to `undefinedTheme` so we do not deal with `undefined`
 * it has no harm as non existing class names won't break things.
 */
export const ThemeContext = createContext<ThemeContextProps>({
  currentTheme: {
    rootClassName: "undefinedTheme",
    staticClassName: "undefinedTheme",
    variantClassNames: {
      default: "undefinedVariant",
      alt: "undefinedVariant",
    },
  },
  getVariantClassNameOrDefault: () => "undefinedVariant",
});

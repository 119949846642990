import {
  CheckboxProps as CheckboxPropsPrimitiveProps,
  Checkbox as CheckboxPrimitive,
} from "react-aria-components";

import styles from "./Checkbox.module.scss";
import { Stack } from "../Stack";
import { SVGCheckMark } from "../SVGIcon/static/SVGCheckMark";

export interface CheckboxProps extends CheckboxPropsPrimitiveProps {
  /**
   * The value of the checkbox button, used when submitting an HTML form (controlled). See MDN.
   */
  value?: CheckboxPropsPrimitiveProps["value"];
  /**
   * Whether the element should be selected (controlled).
   */
  isSelected?: CheckboxPropsPrimitiveProps["isSelected"];
  /**
   * Whether the element should be selected (uncontrolled).
   */
  defaultSelected?: CheckboxPropsPrimitiveProps["defaultSelected"];
  /**
   * Whether the checkbox button is disabled or not.
   * Shows that a selection exists, but is not available in that circumstance.
   */
  isDisabled?: CheckboxPropsPrimitiveProps["isDisabled"];
  /** Whether the element should receive focus on render. */
  autoFocus?: CheckboxPropsPrimitiveProps["autoFocus"];
  children: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ children, ...props }) => (
  <CheckboxPrimitive {...props} className={styles.checkbox}>
    <Stack direction="row" spacing="xs" alignItems="center" className={styles.stack}>
      <div className={styles.iconContainer}>
        <div className={styles.checkboxTickContainer}>
          <SVGCheckMark className={styles.checkboxTick} />
        </div>
      </div>
      {children}
    </Stack>
  </CheckboxPrimitive>
);

export { Checkbox };

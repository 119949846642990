import * as React from "react";
import classnames from "classnames";
import { defaultButtonIconPosition, ButtonIconPosition } from "./shared";
import { Button, LinkButton } from ".";

const createIconButton =
    <P extends React.PropsWithChildren<LXS.Button.BaseProps>>(
        Component: React.ComponentType<P>,
    ): React.FC<P & LXS.Button.IconProps> =>
    ({ icon, children, className, ...props }: P & LXS.Button.IconProps) => {
        const iconPosition = (icon && icon.position) || defaultButtonIconPosition;
        const isRight = iconPosition === ButtonIconPosition.Right || iconPosition === ButtonIconPosition.RightCenter;
        const passOnProps = {
            ...props,
            className: classnames(className, { [`lxs-button--icon-${iconPosition}`]: icon !== undefined }),
        };

        return (
            <Component {...(passOnProps as any as P)}>
                {isRight && children}
                <icon.Component
                    width={16}
                    height={16}
                    {...{ ...icon.props, className: classnames("lxs-button__icon", icon.props.className) }}
                />
                {!isRight && children}
            </Component>
        );
    };

const ButtonWithIcon = createIconButton(Button);
ButtonWithIcon.displayName = "ButtonWithIcon";
const LinkButtonWithIcon = createIconButton(LinkButton);
LinkButtonWithIcon.displayName = "LinkButtonWithIcon";

export { ButtonWithIcon, LinkButtonWithIcon };

import cn from "classnames";

import { Typography } from "@/components/Typography/Typography";
import { ThemeVariant } from "@/theming/themingTypes";
import { useThemeVariant } from "@/theming/VariantContext";
import { getEleInfo, setCustomVariablesForEle } from "@/utils/elementInfo";

import styles from "./Button.module.scss";

export const BUTTON_VARIANTS = [
  "primary",
  "secondary",
  "tertiary",
  "hero",
  "hero-secondary",
] as const;
export const BUTTON_CUSTOMIZATIONS = ["semiTransparent", "highlightCursor"] as const;

export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];
export type ButtonThemeVariant = ThemeVariant | "auto";
export type ButtonCustomization = {
  semiTransparent?: boolean;
  highLightCursor?: boolean;
};

export type ButtonProps = React.PropsWithChildren<{
  /**
   * The fill and outline composition. Primary is filled and outlined, secondary is only outlined, ghost has neither.
   */
  variant?: ButtonVariant;
  /**
   * Set the component to render as (defaults to <button>)
   */
  component?: "button" | "a" | "span";
  /**
   * Set the tabbing order. Set to 0 for natural, or -1 for not tabbable.
   */
  tabIndex?: number;
  /**
   * The `href` attribute of the `<a>` tag. This prop is ignored when `as` is not "a".
   */
  href?: string;
  /**
   * The `target` attribute of the `<a>` tag, e.g. "_blank" to force the link to open a new tab. This prop is ignored when `as` is not "a".
   */
  target?: string;
  /**
   * The `rel` attribute of the `<a>` tag, e.g. "noreferrer" to avoid security issues when `target` is set. This prop is ignored when `as` is not "a".
   */
  rel?: string;
  /**
   * Handle to fire when button is clicked or equivalent, such as Enter key pressed while focused.
   */
  onClick?: React.MouseEventHandler;
  /**
   * When true, disables this component while retaining tabbability for accessibility.
   */
  isDisabled?: boolean;
  /**
   * Set button size, default is large button.
   */
  isSmallButton?: boolean;
  /**
   * Support button customizations, eg. semi-transparent background
   */
  customStyle?: ButtonCustomization;
  /**
   * Set icon for buttons, default is search icon.
   */
  icon?: React.ReactNode;
  /**
   * Set theme variant for buttons, default theme is default.
   */
  themeVariant?: ButtonThemeVariant;
  /**
   * Set loading state for buttons, default is false.
   */
  loading?: boolean;
  /**
   * Set className for button, default is empty.
   */
  className?: string;
}>;

/**
 * The `<Button>` component is intended to be used whenever a form button or link is required.
 *
 * Setting `component` to `a` and `href` will output an `<a href="...">`, otherwise the a `<button>` will be output
 * as the default.
 *
 * `Button` has three variants for fills and outlines: `primary` (default), `secondary` and `tertiary`.
 *
 * `Button` is themeable and there are three theme variants available which are `primary`, `alt` and `static`.
 */
export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  component = "button",
  tabIndex = 0,
  href,
  target,
  rel,
  onClick,
  isDisabled,
  isSmallButton,
  customStyle,
  icon,
  children,
  loading = false,
  themeVariant = "auto",
  className,
}) => {
  const contextualThemeVariant = useThemeVariant();
  const isLink = !!href || component === "a";

  const clickHandler = (e: React.MouseEvent) => {
    if (isLink && isDisabled) {
      e.preventDefault();
    }

    if (!isDisabled && onClick) {
      onClick(e);
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    const [mouseX, mouseY] = getEleInfo(e);
    setCustomVariablesForEle("--mouseX", `${mouseX}`);
    setCustomVariablesForEle("--mouseY", `${mouseY}`);
  };

  const Component = component;
  if (themeVariant === "auto") {
    themeVariant = contextualThemeVariant;
  }

  return (
    <Component
      className={cn(
        styles.button,
        {
          [styles.highlightCursor]: customStyle?.highLightCursor,
          [styles.isSmallButton]: isSmallButton,
          [styles.semiTransparent]: customStyle?.semiTransparent,
          [styles[themeVariant]]: !!themeVariant,
          [styles[variant]]: variant,
        },
        className
      )}
      aria-disabled={isDisabled ? true : undefined}
      onClick={clickHandler}
      tabIndex={tabIndex}
      href={isLink ? href : undefined}
      target={isLink ? target : undefined}
      rel={isLink ? rel : undefined}
      data-testid="lk-button"
      onMouseMove={onMouseMove}
      disabled={component === "button" ? isDisabled || loading : undefined}
    >
      {loading && <span className={styles.loading} />}
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>
        <Typography variant="l1">{children}</Typography>
      </span>
    </Component>
  );
};

Button.displayName = "Button";

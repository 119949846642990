import * as React from "react";
import classnames from "classnames";
import "./MainMenuMobileSecondaryScreen.scss";
import { SVGIconButton } from "../../../SVGIcons/SVGIconButton";
import { Theme } from "../../../shared/Theme";
import { lxColor } from "../../../../colors/variables";
import { toPercentage } from "../../../helpers/number";
import { PrimaryNavContext } from "../../PrimaryNav";
import { SVGCross } from "../../../SVGIcons/icons/static/SVGCross";
import { SVGChevronLeft } from "../../../SVGIcons/icons/static/SVGChevronLeft";

interface MainMenuMobileSecondaryScreenProps {
    active: boolean;
    title?: string;
    headerImage?: LXS.FocalpointImageFieldValue;
    onBack: (event: React.MouseEvent) => void;
}

const MainMenuMobileSecondaryScreen: React.FC<MainMenuMobileSecondaryScreenProps> = ({
    active,
    title,
    headerImage,
    onBack,
    children,
}) => {
    const { handleMenuClose } = React.useContext(PrimaryNavContext);
    return (
        <div
            className={classnames("lxs-main-menu-mobile-secondary-screen", {
                "lxs-main-menu-mobile-secondary-screen--active": active,
            })}
        >
            <div
                className="lxs-main-menu-mobile-secondary-screen-header"
                style={
                    headerImage
                        ? {
                              backgroundImage: `url(${headerImage.src})`,
                              backgroundPosition: `${toPercentage(headerImage.cropx)}% ${toPercentage(
                                  headerImage.cropy,
                              )}%`,
                          }
                        : {}
                }
            >
                <div className="lxs-main-menu-mobile-secondary-screen-header-overlay" />
                {handleMenuClose && (
                    <div className="lxs-main-menu-mobile-secondary-screen-header-close">
                        <SVGIconButton
                            title="Close"
                            stackOnMobile={true}
                            onClick={handleMenuClose}
                            theme={Theme.DARK}
                            data-gtm-target={"SecondaryScreenCloseButton"}
                        >
                            <SVGCross height={20} width={20} color={lxColor("black", "absolute")} />
                        </SVGIconButton>
                    </div>
                )}
                <div className="lxs-main-menu-mobile-secondary-screen-header-back" onClick={onBack}>
                    <SVGChevronLeft height={18} width={18} color={lxColor("smoke")} />
                    <span className="lxs-main-menu-mobile-secondary-screen-header-back--title">{title}</span>
                </div>
            </div>
            <div className="lxs-main-menu-mobile-secondary-screen-body">{children}</div>
        </div>
    );
};

export { MainMenuMobileSecondaryScreen };

import "./OnDemandVehicleAlreadyBookedErrorCard.scss";

import * as React from "react";

import { ButtonShade, ButtonStyle } from "lexus-style-guide/buttons/shared";
import { RouteComponentProps, withRouter } from "react-router";

import ErrorCard from "../ErrorCard/ErrorCard";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import { closeToastUrl } from "../../../helpers/routes";
import { createBemFn } from "lexus-style-guide/utilities/bem";
import { resetSelectedVehicleAction } from "../../../reduxSlices/onDemand/onDemandActions";
import { useThunkDispatch } from "../../../hooks/thunk";

const bem = createBemFn("on-demand-vehicle-already-booked-error-card");
const title = "Unfortunately your selected vehicle has been booked";

const OnDemandVehicleAlreadyBookedErrorCard: React.FC<RouteComponentProps> = ({ history, location }) => {
    //#region hooks
    const dispatch = useThunkDispatch();
    //#endregion

    const close = () => history.push(closeToastUrl(location.pathname));
    const changeCar = () => {
        dispatch(resetSelectedVehicleAction());
        close();
    };

    return (
        <ErrorCard className={bem()} title={title}>
            <ErrorCardButton onClick={changeCar} buttonStyle={ButtonStyle.Primary}>
                change vehicle selection
            </ErrorCardButton>
            <ErrorCardButton onClick={close} buttonStyle={ButtonStyle.Secondary} shade={ButtonShade.Light}>
                close
            </ErrorCardButton>
        </ErrorCard>
    );
};

export default withRouter(OnDemandVehicleAlreadyBookedErrorCard);

import * as React from "react";

const defaultIconWidth = 16;
const defaultIconHeight = 16;

const SVGLock: React.FC<LXS.SVGStaticProps> = ({ width = defaultIconWidth, height = defaultIconHeight, ...rest }) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90803 4.54998C4.575 4.54992 4.575 4.54969 4.575 4.54945V4.54883L4.57501 4.54736L4.57503 4.54355L4.57516 4.5325C4.57531 4.52363 4.57557 4.51177 4.57606 4.49713C4.57704 4.46787 4.57891 4.42738 4.58249 4.37724C4.58964 4.27715 4.60368 4.13759 4.63136 3.9715C4.68631 3.64181 4.79719 3.19419 5.02418 2.74021C5.25219 2.28418 5.60246 1.81218 6.13769 1.45536C6.6762 1.09635 7.37063 0.875 8.25 0.875C9.12937 0.875 9.8238 1.09635 10.3623 1.45536C10.8975 1.81218 11.2478 2.28418 11.4758 2.74021C11.7028 3.19419 11.8137 3.64181 11.8686 3.9715C11.8963 4.13759 11.9104 4.27715 11.9175 4.37724C11.9211 4.42738 11.923 4.46787 11.9239 4.49713C11.9244 4.51177 11.9247 4.52363 11.9248 4.5325L11.925 4.54355L11.925 4.54736L11.925 4.54883V4.54945C11.925 4.54973 11.925 4.55 11.4 4.55H11.925V6.65H10.875V4.55154L10.875 4.54943L10.8748 4.54276L10.8745 4.53211C10.874 4.51523 10.8728 4.48805 10.8702 4.45205C10.865 4.37988 10.8545 4.27334 10.8329 4.14412C10.7894 3.88319 10.7034 3.54331 10.5367 3.20979C10.3709 2.87832 10.1306 2.56282 9.77988 2.32901C9.43245 2.0974 8.94563 1.925 8.25 1.925C7.55437 1.925 7.06754 2.0974 6.72012 2.32901C6.36942 2.56282 6.12906 2.87832 5.96332 3.20979C5.79656 3.54331 5.71056 3.88319 5.66708 4.14412C5.64554 4.27334 5.63497 4.37988 5.62982 4.45205C5.62725 4.48805 5.62604 4.51523 5.62548 4.53211L5.62503 4.54943L5.625 4.55136V6.64998H4.575V4.54998H4.90803ZM4.575 6.65005H11.925C12.7948 6.65005 13.5 7.3552 13.5 8.22505V13.475C13.5 14.3449 12.7948 15.05 11.925 15.05H4.575C3.70515 15.05 3 14.3449 3 13.475V8.22505C3 7.3552 3.70515 6.65005 4.575 6.65005ZM4.575 7.70005C4.28505 7.70005 4.05 7.9351 4.05 8.22505V13.475C4.05 13.765 4.28505 14 4.575 14H11.925C12.2149 14 12.45 13.765 12.45 13.475V8.22505C12.45 7.9351 12.2149 7.70005 11.925 7.70005H4.575ZM8.25 12C8.94036 12 9.5 11.4404 9.5 10.75C9.5 10.0596 8.94036 9.5 8.25 9.5C7.55964 9.5 7 10.0596 7 10.75C7 11.4404 7.55964 12 8.25 12Z"
            fill="#E4E1D7"
        />
    </svg>
    /* eslint-enable max-len */
);

export default SVGLock;

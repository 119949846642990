import * as React from "react";

import { defaultIconColor, defaultIconSize } from "../constants";

let SVGStar: React.FC<LXS.SVGStaticProps> = ({
    width = defaultIconSize,
    height = defaultIconSize,
    color = defaultIconColor,
    ...rest
}) => (
    /* eslint-disable max-len */
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.3728 18.7974L30.4358 17.0956L25.1025 6.48955C24.6904 5.67015 23.3486 5.67015 22.9364 6.48955L17.6031 17.0956L5.66614 18.7974C5.20675 18.8629 4.82614 19.1841 4.68311 19.6265C4.54129 20.0677 4.66372 20.5526 4.99947 20.8726L13.6225 29.1223L11.5874 40.7635C11.5086 41.218 11.6928 41.6762 12.0649 41.9489C12.437 42.2229 12.9304 42.2629 13.3389 42.0495L24.0189 36.5392L34.7001 42.0495C34.8746 42.1405 35.0661 42.1853 35.2564 42.1853C35.5098 42.1853 35.7619 42.1065 35.974 41.9501C36.3461 41.6774 36.5304 41.218 36.4516 40.7647L34.4164 29.1235L43.0395 20.8738C43.374 20.5526 43.4977 20.0689 43.3558 19.6277C43.2128 19.1841 42.8322 18.8629 42.3728 18.7974ZM32.2722 27.818C31.9801 28.0968 31.8455 28.5041 31.9158 28.9029L33.6419 38.7768L24.5764 34.0992C24.4007 34.0095 24.2104 33.9647 24.0201 33.9647C23.8298 33.9647 23.6395 34.0095 23.4637 34.0992L14.3983 38.7768L16.1243 28.9029C16.1946 28.5041 16.0601 28.0968 15.768 27.818L8.47826 20.8459L18.5728 19.4059C18.9643 19.3502 19.3049 19.1053 19.4843 18.7502L24.0189 9.73197L28.5546 18.7502C28.7328 19.1041 29.0746 19.3502 29.4661 19.4059L39.5607 20.8459L32.2722 27.818Z"
            fill={color}
        />
    </svg>
    /* eslint-enable max-len */
);
SVGStar = React.memo(SVGStar);

export { SVGStar };
